import React, { createContext, useContext } from 'react'
import { useAuth } from './AuthContext'
import useAxios from '../hooks/useAxios'

const APIContext = createContext([{}, () => {}])

const APIProvider = ({ children }) => {
  const {
    signOut,
    aro: { userToken },
  } = useAuth()
  const { makeHeaders, get, post, patch } = useAxios(userToken, signOut)

  const worktribeAPIContext = React.useMemo(
    () => ({
      become: async (userId, fcmDeviceToken) =>
        await patch(
          '/become/' + userId,
          { fcm_token: fcmDeviceToken },
          makeHeaders()
        ),
      getFeed: async params => await get('/feed', params, makeHeaders()),
      getMine: async params => await get('/mine', params, makeHeaders()),
      getUsers: async () => await get('/users', {}, makeHeaders()),
      createRecord: async (rClass, users, name) => {
        const bodyFormData = new FormData()
        users.forEach(user => bodyFormData.append('users[]', user))
        if (name) {
          bodyFormData.append('name', name)
        }
        return await post(
          `/record/${rClass}/create`,
          bodyFormData,
          makeHeaders()
        )
      },
      getRecordsOfRClass: async (rClass, params) =>
        await get('/record/' + rClass, params, makeHeaders()),
      getRecord: async (rClass, recordId) =>
        await get('/record/' + rClass + '/' + recordId, {}, makeHeaders()),
      getRClass: async rClass =>
        await get(`rclass/${rClass}`, {}, makeHeaders()),
      getUnseen: async (token = null) =>
        await get(
          '/unseen',
          {},
          token
            ? makeHeaders({ Authorization: 'Bearer ' + token })
            : makeHeaders()
        ),
      reactToComment: async (rClass, recordId, commentId, emoji) => {
        const url = `/record/${rClass}/${recordId}/comment/${commentId}/react`
        return await post(url, { emoji }, makeHeaders())
      },
      getRecordComments: async (rClass, id, params) => {
        const url = '/record/' + rClass + '/' + id + '/comments'
        return await get(url, params, makeHeaders())
      },
      lookAtRecord: async (rClass, id) => {
        const url = '/record/' + rClass + '/' + id + '/look-at'
        return await patch(url, {}, makeHeaders())
      },
      addCommentToRecord: async (rClass, recordId, message, uuid) => {
        const url = '/record/' + rClass + '/' + recordId + '/comment'
        return await post(url, { text: message, uuid: uuid }, makeHeaders())
      },
      updateConversationName: async (id, name) => {
        const url = '/record/conversation/' + id
        return await patch(url, { name: name }, makeHeaders())
      },
      unregisterDevice: async fcmDeviceToken => {
        const url = '/unregister-device-for-push-notifications'
        return await post(url, { fcm_token: fcmDeviceToken }, makeHeaders())
      },
      enablePushNotifications: async (rClass, id) =>
        await patch(
          `/record/${rClass}/${id}/enable-push-notifications`,
          {},
          makeHeaders()
        ),
      disablePushNotifications: async (rClass, id) =>
        await patch(
          `/record/${rClass}/${id}/disable-push-notifications`,
          {},
          makeHeaders()
        ),
    }),
    [userToken]
  )

  return (
    <APIContext.Provider value={worktribeAPIContext}>
      {children}
    </APIContext.Provider>
  )
}

export { APIContext, APIProvider }
