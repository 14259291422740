import React, { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { getInitials, radius, type pt } from './utils'

interface InitialsProps {
  name: string
  size: pt
}

const Initials: React.FC<InitialsProps> = ({ name, size }) => {
  const {
    typography: { Initial },
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: radius.get(size),
        backgroundColor: '#184F79',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Initial color="#FFF">{getInitials(name)}</Initial>
    </View>
  )
}

export default Initials
