import React, { useContext } from 'react'
import { View } from 'react-native'
import { WebView } from 'react-native-web-webview'
import BackButton from '../../components/landscape/BackButton'
import { AlignedRow } from '../../components/shared/Layout'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'

interface HomeScreenProps {}

const LogScreen: React.FC<HomeScreenProps> = () => {
  const {
    typography: { H3 },
  } = useContext(ThemeContext)

  return (
    <>
      <View style={{ paddingVertical: 20, paddingHorizontal: 40 }}>
        <AlignedRow gap={12}>
          <BackButton />
          <H3 primary>Worktribe logs</H3>
        </AlignedRow>
      </View>

      <WebView
        style={{ paddingHorizontal: 40, width: 'auto' }}
        source={{ uri: 'https://www.zubanubi.com/zadminz.php' }}
      />
    </>
  )
}

export default withLayout(LogScreen)
