import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  View,
  Switch,
  Animated,
} from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'
import { Col, Row, Grid } from 'react-native-easy-grid'
import HoverableOpacity from '../shared/HoverableOpacity'
import InlineCalendar from '../shared/InlineCalendar'
import { useNavigation } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import Autolink from 'react-native-autolink'
import { getRecordNamefromProperties } from '../../utils/getRecordNameFromProperties'
import { wt_date } from '../../utils/wt_date'
import useSocketMethods from '../../hooks/useSocketMethods'
import RecordSelect from './controls/RecordSelect'

import InlineText from './controls/InlineText'
import InlineSelect from './controls/InlineSelect'
import InlineDate from './controls/InlineDate'
import InlineFile from './controls/InlineFile'
import InlineSwitch from './controls/InlineSwitch'
import { StatusBadge } from './ContentPanel/ui/GridRow'
import InlineRichText from './controls/InlineRichText'

export const ChipCard = ({
  value,
  onPress,
  removeItem,
  coverid,
  borderless = false,
}) => {
  const {
    typography: { Body },
    themeColors: { accents },
  } = useContext(ThemeContext)

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingVertical: 6,
        paddingHorizontal: 8,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: borderless ? `transparent` : accents.separator,
        marginBottom: 8,
        marginRight: 8,
        flexShrink: 1,
      }}
    >
      <AlignedRow style={{ flex: 1 }}>
        <CardThumbnail
          source={{
            uri: `https://zubanubi.com/resources/covers/cover${coverid}_thumb@2x.jpg`,
            cache: 'force-cache',
          }}
        />

        <Body numberOfLines={2} style={{ marginLeft: 8 }}>
          {getRecordNamefromProperties(value)}
        </Body>
      </AlignedRow>

      <TouchableOpacity
        onPress={removeItem}
        style={{ marginLeft: 8, marginTop: 2 }}
      >
        {removeItem && (
          <WorktribeIcon name="remove-circle" size={18} color="#DADADA" />
        )}
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const PropertyRow = ({
  item,
  index,
  recordId,
  rClass,
  onRowSelect,
  editing,
}) => {
  const { property, value, editaction, required } = item

  const navigation = useNavigation()

  const formatValue = value => {
    if ('records' in value) {
      return value.records
    } else if (property.type === 'switch') {
      return value.value === 'Yes'
    }
    return value.value
  }

  const [fieldValue, onChangeValue] = useState(() => formatValue(value))
  const handleChange = useCallback(value => onChangeValue(value), [])

  const existingValue = React.useRef(fieldValue)

  useEffect(() => {
    let update = false
    let existing = existingValue.current
    let newValue = formatValue(value)

    if (property.type === 'record' || (Array.isArray(existing) && newValue)) {
      if (
        JSON.stringify(newValue?.map(val => val.recordid)) !==
        JSON.stringify(existing?.map(val => val.recordid))
      ) {
        update = true
      }
    } else {
      if (existing !== newValue) {
        update = true
      }
    }

    if (update) {
      handleChange(newValue)
      confirmSaveAnimation()

      existingValue.current = newValue
    }
  }, [value])

  const { record_action } = useSocketMethods()

  const animation = useRef(new Animated.Value(0)).current

  const confirmSaveAnimation = () => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 0,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(animation, {
        toValue: 0,
        duration: 850,
        useNativeDriver: false,
      }).start()
    })
  }

  const doPostRequest = useCallback(
    changes => {
      try {
        record_action({
          recordid: recordId,
          rclassname: rClass,
          actionname: editaction,
          changes: [changes],
        })
      } catch (e) {
        console.warn(e)
      }
    },
    [recordId, rClass, editaction]
  )

  const rowColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['transparent', 'rgba(242,206,106,0.2)'],
  })

  const saveChanges = useCallback(() => {
    try {
      doPostRequest({
        property: property.name,
        method: 'set',
        value: fieldValue,
      })
    } catch (e) {
      console.warn(e)
    } finally {
      onRowSelect(null)
    }
  }, [property.name, fieldValue])

  const rejectChanges = () => {
    handleChange(existingValue.current)
    onRowSelect(null)
  }

  const rejectDateChanges = useCallback(() => {
    try {
      doPostRequest({
        property: property.name,
        method: 'set',
        value: ``,
      })
    } catch (e) {
      console.warn(e)
    } finally {
      onRowSelect(null)
    }
  }, [property.name])

  const goToRecord = async recordid => {
    const savedStateString = await AsyncStorage.getItem('@selectedTab')
    const state = savedStateString ? JSON.parse(savedStateString) : undefined

    if (state) {
      delete state[recordid]
      AsyncStorage.setItem('@selectedTab', JSON.stringify(state))
    }

    navigation.push('Record', {
      rClass: property.rclasses.toLowerCase().replace(' ', '_'),
      recordId: recordid,
    })
  }

  const {
    typography: { H5, Body },
    themeColors: { bg, accents, fonts },
  } = useContext(ThemeContext)

  let control
  if (property.style === 'inline') {
    const { heading, textabove, textbelow, format } = property

    switch (property.type) {
      case 'title':
      case 'text':
      case 'number':
      case 'currency':
      case 'code': {
        if (format === 'rich') {
          control = (
            <InlineRichText
              item={item}
              value={{
                value: fieldValue,
              }}
              onChange={handleChange}
              onBlur={saveChanges}
            />
          )
        } else {
          control = (
            <InlineText
              item={item}
              value={{
                value: fieldValue,
              }}
              onChange={handleChange}
              onBlur={saveChanges}
            />
          )
        }

        break
      }
      case 'record':
      case 'select':
      case 'status': {
        useEffect(() => {
          if (
            JSON.stringify(existingValue.current) !== JSON.stringify(fieldValue)
          ) {
            let payload
            if (property.type === 'record') {
              payload = { recordids: fieldValue?.map(value => value.recordid) }
            } else if (property.type === 'select') {
              payload = { value: fieldValue?.map(value => value.name) }
            } else {
              payload = { value: fieldValue?.map(value => value.name)[0] }
            }

            doPostRequest({
              property: property.name,
              method: 'set',
              ...payload,
            })
          }
        }, [fieldValue])

        control = (
          <InlineSelect
            item={item}
            value={{
              value: fieldValue,
            }}
            onChange={handleChange}
            modal={false}
          />
        )

        break
      }
      case 'date': {
        control = (
          <InlineDate
            item={item}
            value={{
              value: fieldValue,
            }}
            onChange={handleChange}
            onSave={saveChanges}
            onReject={rejectDateChanges}
          />
        )

        break
      }
      case 'file': {
        control = <InlineFile />

        break
      }
      case 'switch': {
        useEffect(() => {
          if (fieldValue !== existingValue.current) {
            saveChanges()
          }
        }, [fieldValue])

        control = <InlineSwitch value={fieldValue} onChange={handleChange} />

        break
      }
      default: {
        control = null
        break
      }
    }

    return (
      <InlinePropertyWrapper
        heading={heading}
        textabove={textabove}
        textbelow={textbelow}
        required={required === 'true' && property.type !== 'switch'}
        fieldValue={fieldValue}
        index={index}
      >
        {control}
      </InlinePropertyWrapper>
    )
  }

  let rowContent
  let dropDownContent

  const { name, pick_conditions } = property
  switch (property.type) {
    case 'title':
    case 'number':
    case 'currency':
    case 'code':
    case 'text': {
      const [height, setHeight] = useState(32)

      const onKeyPress = ({ nativeEvent }) => {
        if (nativeEvent.key === 'Enter' && nativeEvent.shiftKey) {
          saveChanges()
        }
      }

      if (editaction) {
        rowContent = (
          <AlignedRow justifyContent="space-between">
            {editing ? (
              <EditInput
                autoFocus
                onSubmitEditing={saveChanges}
                multiline={['text'].includes(property.type)}
                style={[
                  { backgroundColor: bg.card, color: fonts.primary },
                  { height },
                  ['number', 'currency'].includes(property.type) && {
                    maxWidth: 100,
                  },
                ]}
                onChangeText={handleChange}
                value={fieldValue}
                onContentSizeChange={({
                  nativeEvent: {
                    contentSize: { height },
                  },
                }) => setHeight(height)}
                // onBlur={rejectChanges}
                onKeyPress={onKeyPress}
              />
            ) : (
              <>
                {fieldValue ? (
                  <>
                    {property.type === 'currency' ? (
                      <Body primary>
                        {new Intl.NumberFormat(`en-GB`, {
                          currency: `GBP`,
                          style: 'currency',
                        }).format(fieldValue)}
                      </Body>
                    ) : (
                      <Autolink
                        email={true}
                        selectable={true}
                        style={{ color: fonts.primary, flex: 1 }}
                        linkStyle={{ color: '#296DFF' }}
                        text={fieldValue}
                        component={Body}
                      />
                    )}
                  </>
                ) : (
                  <Body>Optional</Body>
                )}
              </>
            )}

            {editing ? (
              <AlignedRow>
                <TouchableOpacity
                  style={{ marginLeft: 16 }}
                  onPress={saveChanges}
                >
                  <WorktribeIcon
                    size={24}
                    color="#2AAD79"
                    name="check-circle-2"
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={{ marginLeft: 16 }}
                  onPress={rejectChanges}
                >
                  <WorktribeIcon
                    color="#757885"
                    size={24}
                    name="remove-circle"
                  />
                </TouchableOpacity>
              </AlignedRow>
            ) : (
              <WorktribeIcon size={20} color="#757885" name="pencil-1" />
            )}
          </AlignedRow>
        )
      } else {
        rowContent = <Body primary>{fieldValue}</Body>
      }

      break
    }
    case 'record': {
      rowContent = (
        <AlignedRow justifyContent="space-between">
          {fieldValue?.length ? (
            <AlignedRow
              style={{
                flexWrap: 'wrap',
                marginTop: -10,
                marginBottom: -20,
                flex: 1,
              }}
            >
              {fieldValue.map?.(record => {
                const { properties, recordid, coverid } = record

                const removeItem = async () => {
                  onChangeValue(records => {
                    const filtered = records.filter(
                      record => record.recordid !== recordid
                    )

                    try {
                      record_action({
                        recordid: recordId,
                        rclassname: rClass,
                        actionname: editaction,
                        changes: [
                          {
                            property: name,
                            method: 'set',
                            recordids: filtered.map(filter => filter.recordid),
                          },
                        ],
                      })
                    } catch (e) {
                      console.warn(e)
                    }

                    return filtered
                  })
                }

                return (
                  <ChipCard
                    key={recordid}
                    value={properties[0].value}
                    onPress={() => goToRecord(recordid)}
                    removeItem={!!editaction && removeItem}
                    coverid={coverid}
                  />
                )
              })}
            </AlignedRow>
          ) : (
            <>
              {editaction ? (
                <Body>{`Search ${name.toLowerCase()}`}</Body>
              ) : null}
            </>
          )}
          {!!editaction && (
            <WorktribeIcon name="chevron-down" color="#757885" />
          )}
        </AlignedRow>
      )

      if (editaction) {
        dropDownContent = (
          <RecordSelect
            name={name}
            handleClose={onRowSelect}
            doPostRequest={doPostRequest}
            rclass={property.rclasses}
            value={fieldValue}
            multiple={property.multiple}
            pick_conditions={pick_conditions}
            recordids={property.recordids}
            placeholder={`Search ${name.toLowerCase()}`}
          />
        )
      }

      break
    }
    case 'status': {
      const selectedStatus = property.options.find(
        status => status.name === fieldValue
      )

      rowContent = (
        <AlignedRow
          justifyContent="space-between"
          style={{ position: 'absolute', width: '100%' }}
        >
          <StatusBadge
            rowValue={fieldValue}
            selectedStatus={selectedStatus}
            absolute
          />

          {!!editaction && (
            <WorktribeIcon name="chevron-down" color="#757885" />
          )}
        </AlignedRow>
      )

      if (editaction) {
        dropDownContent = (
          <StatusSelect
            name={name}
            handleChange={onChangeValue}
            statuses={property.options}
            closeHandler={onRowSelect}
            doPostRequest={doPostRequest}
          />
        )
      }

      break
    }
    case 'date':
    case 'reminder': {
      const [calendarText, setCalendarText] = useState(() => {
        if (fieldValue) {
          return wt_date(new Date(fieldValue))
        }
        return ''
      })

      if (editaction) {
        rowContent = (
          <AlignedRow justifyContent="space-between">
            {editing ? (
              <EditInput
                autoFocus
                style={{
                  marginRight: 16,
                  backgroundColor: bg.card,
                  color: fonts.primary,
                }}
                multiline={false}
                onSubmitEditing={saveChanges}
                onChangeText={setCalendarText}
                value={calendarText}
              />
            ) : (
              <>
                {fieldValue ? (
                  <Body primary>
                    {wt_date(new Date(fieldValue), property.time)}
                  </Body>
                ) : (
                  <Body>Optional</Body>
                )}
              </>
            )}

            <WorktribeIcon
              size={20}
              color={editing ? '#2AAD79' : '#757885'}
              name="calendar"
            />
          </AlignedRow>
        )

        dropDownContent = (
          <CalendarSelect
            value={fieldValue}
            handleChange={handleChange}
            saveChanges={saveChanges}
            rejectChanges={rejectDateChanges}
            dismissChanges={rejectChanges}
            calendarText={calendarText}
            setCalendarText={setCalendarText}
            time={property.time}
          />
        )
      } else {
        if (fieldValue) {
          rowContent = <Body primary>{wt_date(new Date(fieldValue))}</Body>
        }
      }
      break
    }
    case 'select': {
      if (editaction) {
        rowContent = (
          <AlignedRow justifyContent="space-between">
            {fieldValue ? (
              <Body primary>{fieldValue}</Body>
            ) : (
              <Body>{`Select ${name.toLowerCase()}`}</Body>
            )}
            <WorktribeIcon name="chevron-down" color="#757885" />
          </AlignedRow>
        )

        dropDownContent = (
          <ItemSelect
            name={name}
            options={property.options}
            handleChange={onChangeValue}
            closeHandler={onRowSelect}
            doPostRequest={doPostRequest}
          />
        )
      } else {
        rowContent = <Body primary>{fieldValue}</Body>
      }
      break
    }
    case 'switch': {
      if (editaction) {
        rowContent = (
          <View
            style={{
              position: 'absolute',
              marginTop: -3,
              backgroundColor: fieldValue ? '#2AAD79' : '#A0A4B8',
              height: 28,
              width: 48,
              borderRadius: 15,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Switch
              activeThumbColor="#FFF"
              trackColor={{ 1: '#2AAD79', 0: '#2AAD79' }}
              onValueChange={value => {
                handleChange(value)

                doPostRequest({
                  property: name,
                  method: 'set',
                  value: value ? 'Yes' : 'No',
                })
              }}
              value={fieldValue}
            />
          </View>
        )
      } else {
        rowContent = <Body primary>{fieldValue ? 'Yes' : 'No'}</Body>
      }

      break
    }
    default: {
      rowContent = <Body primary>{fieldValue}</Body>
    }
  }

  let highlightRequired = false
  if (required === 'true') {
    if (!fieldValue || fieldValue.length === 0) {
      highlightRequired = true
    }
  }

  return (
    <>
      <Row
        style={[
          { minWidth: 400, zIndex: -index },
          highlightRequired && {
            backgroundColor:
              'linear-gradient(0deg, rgba(242, 163, 89, 0.08), rgba(242, 163, 89, 0.08)), #F4F5F9',
          },
        ]}
      >
        <Col
          style={[
            styles.col,
            {
              borderRightWidth: 1,
              borderRightColor: accents.separator,
              justifyContent: 'center',
            },
          ]}
        >
          <H5
            primary
            style={
              highlightRequired && {
                color: '#B15500',
                justifyContent: 'center',
              }
            }
          >
            {property.name}
          </H5>
        </Col>

        <Col style={{ justifyContent: 'center' }} size={2}>
          <Animated.View
            style={[
              {
                flex: 1,
                backgroundColor: rowColor,
                paddingVertical: 15,
                paddingHorizontal: 24,
              },
              editing &&
                ['title', 'number', 'text', 'date', 'reminder'].includes(
                  property.type
                ) && {
                  paddingVertical: 8,
                },
            ]}
          >
            <TouchableOpacity
              style={{ cursor: editaction ? 'pointer' : 'arrow' }}
              activeOpacity={1}
              onPress={() => {
                if (editaction) {
                  onRowSelect(property.name)
                }
              }}
            >
              {rowContent}
            </TouchableOpacity>
          </Animated.View>
        </Col>
      </Row>

      {editing && (
        <Row style={{ zIndex: 1 }}>
          <Col />
          <Col size={2}>{dropDownContent}</Col>
        </Row>
      )}
    </>
  )
}

export const InlinePropertyWrapper = ({
  children,
  heading,
  textabove,
  textbelow,
  required,
  fieldValue,
  index,
  wrapperStyle,
}) => {
  const {
    typography: { H3, Subtitle },
  } = useContext(ThemeContext)

  return (
    <View style={[{ padding: 24, zIndex: -index }, wrapperStyle]}>
      {heading && (
        <AlignedRow style={{ marginBottom: 16 }}>
          <H3 primary>{heading}</H3>

          {required && !fieldValue?.length && (
            <View
              style={{
                height: 10,
                width: 10,
                backgroundColor: '#e08938',
                borderRadius: 5,
                marginLeft: 6,
              }}
            />
          )}
        </AlignedRow>
      )}

      {textabove && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: 16,
          }}
        >
          <Subtitle>{textabove}</Subtitle>

          {required && !fieldValue?.length && !heading && (
            <View
              style={{
                height: 10,
                width: 10,
                backgroundColor: '#e08938',
                borderRadius: 5,
                marginLeft: 6,
                marginTop: 5,
              }}
            />
          )}
        </View>
      )}

      {children}

      {textbelow && <Subtitle style={{ marginTop: 16 }}>{textbelow}</Subtitle>}
    </View>
  )
}

const Properties = ({ block, blockIndex, recordId, rClass }) => {
  const {
    typography: { PropertyName },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  const [editingRow, setEditingRow] = useState(null)

  const selectRowForEditing = useCallback(name => {
    setEditingRow(name)
  }, [])

  // custom validation code until server can provide
  // correct response
  const isValid = block.columns.every(column =>
    column.items.every(item => {
      if (item.required === 'false') return true

      if (item.value.hasOwnProperty('records')) {
        return item.value.records.length
      }

      return item.value.value.length
    })
  )

  return (
    <View style={{ paddingHorizontal: 40, zIndex: -blockIndex }}>
      <BubbleRow>
        <Bubble
          style={[
            { backgroundColor: bg.card },
            !isValid && {
              shadowOffset: {
                width: -2,
                height: 0,
              },
              shadowColor: '#F19549',
            },
          ]}
        >
          {block?.showname && block.name ? (
            <AlignedRow style={{ paddingBottom: 10 }}>
              <PropertyName primary>{block.name}</PropertyName>
            </AlignedRow>
          ) : null}

          <GridRow>
            {block.columns.map((grid, gridIndex) => {
              const visibleItems = grid.items.filter(
                item => item.display === 'show'
              )

              return (
                <View
                  key={`${blockIndex}-${gridIndex}`}
                  style={{ flex: 1, zIndex: -gridIndex }}
                >
                  {grid.showname && grid.name ? (
                    <PropertyName
                      primary
                      style={{ marginLeft: 12, marginBottom: 12 }}
                    >
                      {grid.name}
                    </PropertyName>
                  ) : null}

                  <View>
                    <Grid
                      style={[
                        styles.grid,
                        {
                          backgroundColor: bg.primary,
                        },
                        Platform.OS === 'web' && { minWidth: 400 },
                      ]}
                    >
                      {visibleItems.length
                        ? visibleItems
                            .map((item, itemIndex) => {
                              return (
                                <PropertyRow
                                  key={item.property.name}
                                  item={item}
                                  index={itemIndex}
                                  recordId={recordId}
                                  rClass={rClass}
                                  onRowSelect={selectRowForEditing}
                                  editing={editingRow === item.property.name}
                                />
                              )
                            })
                            .reduce((prev, curr) => [
                              prev,
                              <View
                                key={uuidv4()}
                                style={{
                                  height: 1,
                                  zIndex: -99,
                                  backgroundColor: accents.separator,
                                }}
                              />,
                              curr,
                            ])
                        : null}
                    </Grid>
                  </View>
                </View>
              )
            })}
          </GridRow>
        </Bubble>
      </BubbleRow>
    </View>
  )
}

const StatusSelect = ({
  name,
  handleChange,
  statuses,
  closeHandler,
  doPostRequest,
}) => {
  const {
    typography: { Body },
    themeColors: {
      bg,
      hover,
      status: { light, dark, darker },
    },
    shadowStyles,
  } = useContext(ThemeContext)

  const outsideHandler = useCallback(closeHandler, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <View
      ref={wrapperRef}
      style={[
        {
          position: 'absolute',
          top: 6,
          left: 40,
          width: 350,
          maxHeight: 228,
          backgroundColor: bg.primary,
          borderRadius: 16,
          paddingVertical: 8,
        },
        shadowStyles,
      ]}
    >
      <ScrollView showsVerticalScrollIndicator={true} style={{ flex: 1 }}>
        {statuses.map(status => (
          <HoverableOpacity
            onPress={() => {
              handleChange(status.name)
              doPostRequest({
                property: name,
                method: 'set',
                value: status.name,
              })
              closeHandler()
            }}
            key={status.name}
            style={{
              justifyContent: 'center',
              minHeight: 46,
              paddingHorizontal: 16,
              paddingVertical: 11,
            }}
            hoverStyle={{ backgroundColor: hover }}
          >
            <AlignedRow>
              <View
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  marginRight: 12,
                  backgroundColor: darker[status.color],
                  marginBottom: 1,
                }}
              />
              <Body primary>{status.name}</Body>
            </AlignedRow>
          </HoverableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

const CalendarSelect = ({
  value,
  handleChange,
  rejectChanges,
  dismissChanges,
  saveChanges,
  calendarText,
  setCalendarText,
  top,
  time,
}) => {
  const {
    themeColors: { bg },
    shadowStyles,
  } = useContext(ThemeContext)
  const outsideHandler = useCallback(dismissChanges, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)
  return (
    <View
      ref={wrapperRef}
      style={[
        {
          position: 'absolute',
          backgroundColor: bg.primary,
          top: top ? top : 6,
          left: 40,
          borderRadius: 16,
          padding: 20,
          maxWidth: 364,
        },
        shadowStyles,
      ]}
    >
      <InlineCalendar
        value={value}
        valueHandler={handleChange}
        saveChanges={saveChanges}
        rejectChanges={rejectChanges}
        calendarText={calendarText}
        setCalendarText={setCalendarText}
        time={time}
      />
    </View>
  )
}

const ItemSelect = ({
  name,
  options,
  handleChange,
  closeHandler,
  doPostRequest,
}) => {
  const {
    typography: { Body },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  const outsideHandler = useCallback(closeHandler, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <View
      ref={wrapperRef}
      style={[
        {
          position: 'absolute',
          top: 6,
          left: 40,
          backgroundColor: bg.primary,
          borderRadius: 16,
          paddingVertical: 8,
          maxHeight: 345,
          width: '95%',
          maxWidth: 720,
        },
        shadowStyles,
      ]}
    >
      <ScrollView style={{ flex: 1 }}>
        {options?.map(option => (
          <HoverableOpacity
            onPress={() => {
              handleChange(option.name)

              doPostRequest({
                property: name,
                method: 'set',
                value: [option.name],
              })

              closeHandler()
            }}
            key={option.name}
            style={{
              justifyContent: 'center',
              minHeight: 46,
              paddingHorizontal: 16,
              paddingVertical: 11,
            }}
            hoverStyle={{ backgroundColor: hover }}
          >
            <Body primary>{option.name}</Body>
          </HoverableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

export default Properties

const styles = StyleSheet.create({
  grid: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 12,
    borderRadius: 16,
  },
  col: {
    paddingVertical: 15,
    paddingHorizontal: 24,
  },
})

const GridRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -12px 0 -12px;
`
const EditInput = styled.TextInput`
  margin-left: -12px;
  flex: 1;
  padding: 7px 12px;
  border-radius: 12px;
  font-family: proxima-nova;
  font-size: 15px;
  line-height: 22px;
`
const CardThumbnail = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
`
const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`
const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  padding: 24px;
  border-radius: 16px;
  min-width: 748px;
`
