import { useState, useMemo, useContext, useEffect } from 'react'
import { View, TextInput, Pressable, Platform } from 'react-native'
import WorktribeIcon from '../../shared/WorktribeIcon'

import type { IItem, IItemProperty } from '../../../interfaces/record'
import { ThemeContext } from '../../../contexts/ThemeContext'

import * as Property from '../../../components/landscape/QuillField'

import QuillEditor, { QuillToolbar } from 'react-native-cn-quill'
import { useQuill } from 'react-quilljs'
import '../../../config/quillImport'

interface InlineTextProps {
  item: IItem
  value: any
  onChange: Function
  onBlur: Function
  inputBackgroundColor: string
}

const InlineText: React.FC<InlineTextProps> = ({
  item,
  value,
  onChange,
  onBlur,
  inputBackgroundColor,
  first,
  formValid,
  modal
}) => {
  const [focused, setFocused] = useState<boolean>(false)
  const [text, onChangeText] = useState<string>(value.value)

  const { property, required }: { property: IItemProperty; required: string } =
    item

  const { size, name, format } = property

  useEffect(() => {
    onChange(text)
  }, [text])

  const clearText = () => {
    onChangeText('')
    if (onBlur) onBlur()
  }

  const minHeight = useMemo(() => {
    return size === 'medium' ? '102px' : size === 'large' ? '240px' : '60px'
  }, [size])

  const valid = useMemo(() => {
    if (required === 'true' && !text.length) {
      return false
    }
    return true
  }, [text, required])

  const { quill, quillRef } = useQuill({ theme: 'snow' })

  useEffect(() => {
    quill?.clipboard.dangerouslyPasteHTML(text)

    quill?.on('text-change', () => {
      onChangeText(quill.root.innerHTML)
    })
  }, [quill])

  const {
    typography: { Label },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  if (format === 'rich') {
    return (
      <div
        style={{
          borderRadius: 16,
        }}
      >
        <div
          onBlur={onBlur}
          ref={quillRef}
          style={{
            backgroundColor: bg.card,
            color: fonts.primary,
            minHeight,
          }}
        />
      </div>
    )
  }

  return (
    <View
      style={[
        {
          height: minHeight,
          paddingHorizontal: 16,
          backgroundColor: inputBackgroundColor ?? bg.card,
          justifyContent: !size ? 'center' : '',
          borderRadius: 16,
        },
        ['number', 'currency', 'code'].includes(property.type) && {
          width: 300,
        },
        !formValid &&
          modal &&
          !valid && {
            shadowOffset: {
              width: -2,
              height: 0,
            },
            shadowColor: '#F19549',
          },
      ]}
    >
      <View style={!!size && { flex: 1, marginTop: 18 }}>
        {!!text.length && (
          <Label color={focused ? '#2AAD79' : ''}>{name}</Label>
        )}
        <View
          style={{
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            autoFocus={first}
            multiline={!!size}
            value={text}
            onChangeText={onChangeText}
            placeholder={valid ? name : `${name} *`}
            placeholderTextColor={
              !formValid && !valid ? '#B15500' : fonts.secondary
            }
            style={[
              {
                flex: 1,
                minHeight: focused || text.length ? '' : 60,
                padding: 0,
                fontFamily: 'proxima-nova',
                color: fonts.primary,
                fontSize: 15,
              },
              Platform.OS === 'web' && { outline: 'none' },
            ]}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              if (onBlur) onBlur()
              setFocused(false)
            }}
          />

          {!!text.length && (
            <Pressable onPress={clearText}>
              <WorktribeIcon size={16} name="remove-circle" />
            </Pressable>
          )}
        </View>
      </View>
    </View>
  )
}

export default InlineText
