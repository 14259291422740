import React, { useEffect, useContext, useState } from 'react'
import { Keyboard, View, Platform, StyleSheet } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import QuillEditor, { QuillToolbar } from 'react-native-cn-quill'
import { useQuill } from 'react-quilljs'
import '../../config/quillImport'

export const QuillInput = ({
  field,
  value,
  required = false,
  multiline = false,
  setData,
  valid,
  theme,
}) => {
  const {
    typography: { Label },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const [text, onChangeText] = React.useState(value)
  const [inputFocused, setInputFocused] = useState(false)

  useEffect(() => {
    if (!inputFocused) {
      Keyboard.dismiss()
    }
  }, [inputFocused])

  const [placeholderText, setPlaceholderText] = useState(field)

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : `${field}${required ? '*' : ''}`)
  }, [inputFocused])

  // useEffect(() => {
  //   setData(data => {
  //     return {
  //       ...data,
  //       [field]: {
  //         value: text,
  //         valid: required ? !!text.length : true,
  //       },
  //     }
  //   })
  // }, [text, field])

  let inputContent
  switch (Platform.OS) {
    case 'web':
      theme = theme == 'bubble' ? 'bubble' : 'snow'

      const { quill, quillRef } = useQuill({ theme })

      React.useEffect(() => {
        if (quill) {
          quill.clipboard.dangerouslyPasteHTML(value.value)

          quill.on('text-change', () => {
            onChangeText(quill.root.innerHTML)
          })
        }
      }, [quill])

      inputContent = null

      if (theme == 'bubble') {
        inputContent = (
          <div
            style={{
              height: '100%',
              width: '75%',
              margin: '0 auto',
              backgroundColor: bg.card,
              color: fonts.primary,
              border: '1px solid lightgray',
            }}
          >
            <div ref={quillRef} />
          </div>
        )
      } else {
        inputContent = (
          <div style={{ width: '75%', height: '100%', margin: '0 auto 15px' }}>
            <div
              ref={quillRef}
              style={{
                backgroundColor: bg.card,
                color: fonts.primary,
              }}
            />
          </div>
        )
      }
      break
    default:
      const _editor = React.createRef()

      // style picker
      let quillConfig = {}
      let showNativeToolbar = false
      switch (theme) {
        case 'snow':
          quillConfig = { theme: 'snow' }
          break
        case 'bubble':
          quillConfig = { theme: 'bubble' }
          break
        case 'none':
          quillConfig = { modules: { toolbar: 'false' } }
          showNativeToolbar = true
          break
      }

      inputContent = (
        <>
          {showNativeToolbar && (
            <QuillToolbar editor={_editor} options="basic" theme="light" />
          )}
          <View
            style={{
              flex: 1,
              padding: 5,
              borderColor: 'gray',
              borderWidth: 1,
              marginHorizontal: 30,
              marginVertical: 5,
              backgroundColor: 'white',
            }}
          >
            <QuillEditor
              ref={_editor}
              quill={quillConfig}
              initialHtml={value.value}
              defaultFontFamily="proxima-nova-bold"
            />
          </View>
        </>
      )
  }

  return (
    <View
      style={[
        {
          marginVertical: 8,
          backgroundColor: bg.primary,
          borderRadius: 16,
          paddingHorizontal: 16,
          paddingVertical: 5,
          minHeight: 60,
          justifyContent: 'center',
        },
        multiline && { paddingTop: 15 },
        !valid && {
          backgroundColor: 'rgba(224,137,56, 0.1)',
        },
      ]}
    >
      <View style={{ height: 300 }}>
        {inputFocused || text?.length ? (
          <Label style={multiline && { marginTop: -10 }}>{`${field}${
            required ? '*' : ''
          }`}</Label>
        ) : null}
        {inputContent}
      </View>
    </View>
  )
}
