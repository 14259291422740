import React, { useState, useEffect } from 'react'
import { AppState, Platform, LogBox } from 'react-native'
import 'react-native-reanimated'
import * as Font from 'expo-font'
import './src/globals/global'
import { ErrorBoundary } from 'react-error-boundary'

import Compose from './src/contexts/Compose'
import env from './env'
import Navigation from './src/config/navigation'

// redux
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import recordsReducer from './src/store/reducers/records'
import layoutReducer from './src/store/reducers/layout'

// Providers
import { ThemeProvider } from './src/contexts/ThemeContext'
import { AuthProvider } from './src/contexts/AuthContext'
import { SocketProvider } from './src/contexts/SocketContext'
import { Client } from 'rollbar-react-native'

if (Platform.OS !== 'web') {
  LogBox.ignoreLogs([
    'Warning: Each child in a list should have a unique "key" prop.',
    'Encountered two children with the same key',
  ])
}

// Before rendering any navigation stack
import { enableScreens } from 'react-native-screens'
import ActivityIndicatorSplash from './src/components/shared/ActivityIndicatorSplash'
import LoginScreen from './src/screens/portrait/LoginScreen'
import SectionList from './src/components/organisms/section-list/SectionList'
enableScreens()

// rollbar-react-native does not support react-native-web
if (Platform.OS !== 'web') {
  new Client('00939c148a8a40cfbb1ce14852bf07d8')
}

const rootReducer = combineReducers({
  records: recordsReducer,
  layout: layoutReducer,
})

const store = createStore(rootReducer)

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  console.log(error)
  {
    componentStack && console.log(componentStack)
  }
  return (
    <div role="alert">
      <h3>💥 BANG!</h3>
      <p>
        Oops. An unrecoverable error occurred. Sorry about that. Here's what's
        happened (see devtools for more info):
      </p>
      <pre>{error.message}</pre>
      <p>
        Sorry for the inconvenience. We suggest you refresh the page to resolve
        the issue.
      </p>
    </div>
  )
}

export default function App() {
  const PushNotificationIOS =
    env.use_push_notifications === true
      ? require('@react-native-community/push-notification-ios').default
      : null

  const [assetsLoaded, setAssetsLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        await Font.loadAsync({
          'proxima-nova': require('./assets/fonts/Proxima-Nova.ttf'),
          'proxima-nova-black': require('./assets/fonts/Proxima-Nova-Black.ttf'),
          'proxima-nova-bold': require('./assets/fonts/Proxima-Nova-Bold.ttf'),
          'proxima-nova-extrabold': require('./assets/fonts/Proxima-Nova-Extrabold.ttf'),
          'proxima-nova-light': require('./assets/fonts/Proxima-Nova-Light.ttf'),
          'proxima-nova-medium': require('./assets/fonts/Proxima-Nova-Medium.ttf'),
          'proxima-nova-semibold': require('./assets/fonts/Proxima-Nova-Semibold.ttf'),
          'proxima-nova-thin': require('./assets/fonts/Proxima-Nova-Thin.ttf'),

          'lato-regular': require('./assets/fonts/Lato-Regular.ttf'),
          'lato-bold': require('./assets/fonts/Lato-Bold.ttf'),
          'lato-italic': require('./assets/fonts/Lato-Italic.ttf'),
          'lato-black': require('./assets/fonts/Lato-Black.ttf'),
          'lato-light': require('./assets/fonts/Lato-Light.ttf'),
        })

        AppState.addEventListener('change', nextAppState => {
          if (nextAppState === 'active' && PushNotificationIOS) {
            Platform.OS === 'ios' &&
              PushNotificationIOS.setApplicationIconBadgeNumber(0)
          }
        })
      } catch (e) {
        console.warn(e)
      } finally {
        setAssetsLoaded(true)
      }
    })()
  }, [])

  if (!assetsLoaded) {
    return <ActivityIndicatorSplash />
  }

  if (Platform.OS === 'web') {
    return (
      // Note: You need to include Providers in the order they should be loaded in - if one Provider relies on another, you must put the reliant one after it!
      // (e.g.: If SocketListenerProvider needs AuthContext, SocketListenerProvider should come AFTER AuthProvider)
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          window.location.reload(false)
        }}
      >
        <Provider store={store}>
          <Compose components={[ThemeProvider, AuthProvider, SocketProvider]}>
            <Navigation />
          </Compose>
        </Provider>
      </ErrorBoundary>
    )
  }

  return (
    // Note: You need to include Providers in the order they should be loaded in - if one Provider relies on another, you must put the reliant one after it!
    // (e.g.: If SocketListenerProvider needs AuthContext, SocketListenerProvider should come AFTER AuthProvider)
    <Provider store={store}>
      <Compose components={[ThemeProvider, AuthProvider, SocketProvider]}>
        <Navigation />
      </Compose>
    </Provider>
  )
}
