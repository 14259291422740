import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import * as RootNavigation from '../../services/RootNavigation'

import CardHeader from './CardHeader'
import CardBody from './CardBody'

const Card = ({ record, screen }) => (
  <TouchableOpacity
    style={styles.card}
    activeOpacity={0.6}
    onPress={() =>
      RootNavigation.navigate('Record', {
        recordId: record.id,
        rClass: record.rclass,
      })
    }
  >
    <CardHeader record={record} />
    <CardBody record={record} />
  </TouchableOpacity>
)

export default React.memo(Card)

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#FFFFFF',
    borderColor: '#DDE1ED',
    borderWidth: StyleSheet.hairlineWidth,
    marginVertical: 10,
    shadowColor: 'rgba(0, 0, 0, 0.06)',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0,
    shadowRadius: 32,
    borderRadius: 24,
  },
})
