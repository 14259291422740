import React, { Children } from 'react'
import { View, StyleSheet } from 'react-native'

export const AlignedRow: React.FC<{
  children: React.ReactNode
  style?: any
  justifyContent?: string
  gap?: number
}> = ({ children, style, justifyContent, gap = 0 }) => (
  <View
    style={[styles.alignedRow, style, justifyContent && { justifyContent }]}
  >
    {gap
      ? Children.toArray(children).reduce((prev, curr, index) => [
          prev,
          <View key={index} style={{ width: gap }} />,
          curr,
        ])
      : children}
  </View>
)

export const CenteredView: React.FC<{
  children: React.ReactNode
  style?: any
}> = ({ children, style }) => (
  <View style={[styles.centered, { ...style }]}>{children}</View>
)

export const RelativeContainer: React.FC<{
  children: React.ReactNode
  style?: any
}> = ({ children, style }) => (
  <View style={[styles.relative, { ...style }]}>{children}</View>
)

const styles = StyleSheet.create({
  alignedRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  centered: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  relative: {
    flex: 1,
    position: 'relative',
  },
})
