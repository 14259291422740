import React, { useState, useRef, useEffect } from 'react'
import {
  StyleSheet,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  Switch,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { BigButton } from '../../components/shared/Button'
import { AntDesign } from '@expo/vector-icons'
import styled from 'styled-components/native'
import * as Typography from '../../components/shared/Typography'

const Header = styled.View`
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`

const HeaderItem = styled.View`
  flex: 1;
  align-items: ${props => props.align};
`

const HeaderTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: #0e1012;
`

const ClearButton = styled.TouchableOpacity`
  padding: 5px 16px;
  border-radius: 30px;
  margin-left: 16px;
  border: 1px solid #2aad79;
`

const ClearButtonText = styled.Text`
  color: ${props => (props.primary ? '#FFFFFF' : '#2AAD79')};
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const Body = styled.ScrollView`
  flex: 1;
`

const Filters = styled.View`
  margin: 24px 16px;
  background-color: #ffffff;
  border-radius: 16px;
`

const FilterOptions = styled.View`
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-color: #dde1ed;
  padding: 10px 0;
`

const OptionWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  background-color: ${props =>
    props.optionSelected ? '#F2FCF8' : 'transparent'};
`

const Option = styled.View`
  flex-direction: row;
  align-items: center;
`

const OptionIcon = styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-right: 10px;
  background-color: ${props => props.color};
`

const OptionText = styled.Text`
  font-family: ${props =>
    props.optionSelected ? 'proxima-nova-semibold' : 'proxima-nova'};
  color: ${props => (props.optionSelected ? '#2AAD79' : '#000000')};
  font-size: 17px;
`

const OptionCount = styled.Text`
  font-family: ${props =>
    props.optionSelected ? 'proxima-nova-semibold' : 'proxima-nova'};
  color: ${props => (props.optionSelected ? '#2AAD79' : '#757885')};
  font-size: 17px;
`

const Footer = styled.View`
  background-color: #ebecf2;
  padding: 10px;
`

const ListItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0 18px 16px;
  border-bottom-width: 1px;
  border-color: #dde1ed;
`

const FilterName = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  color: #0e1012;
`

const FilterDetails = styled.View`
  flex-direction: row;
  align-items: center;
`

const FilterDescription = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  color: #757885;
  margin-right: 16px;
`

const FilterArrowImage = styled.Image`
  height: 14px;
  width: 14px;
`

const FilterBody = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const initialFilters = [
  {
    filterName: 'Status',
    type: 'selectone',
    options: [
      {
        title: 'In progress',
        selected: false,
        color: '#DAA847',
      },
      {
        title: 'In review',
        selected: false,
        color: '#F5BEBE',
      },
      {
        title: 'Discoverable',
        selected: false,
        color: '#8F40B5',
      },
      {
        title: 'Private',
        selected: false,
        color: '#A76D16',
      },
      {
        title: 'Withdrawn',
        selected: false,
        color: '#FF0000',
      },
      {
        title: 'Discarded',
        selected: false,
        color: '#34CA55',
      },
    ],
  },
  {
    filterName: 'Publication status',
    type: 'selectone',
    options: [
      {
        title: 'Published',
        selected: false,
      },
      {
        title: 'Unpublished',
        selected: false,
      },
      {
        title: 'Submitted',
        selected: false,
      },
    ],
  },
  {
    filterName: 'Type',
    type: 'selectone',
    options: [
      {
        title: 'Book',
        selected: false,
      },
      {
        title: 'Book chapter',
        selected: false,
      },
      {
        title: 'Conference proceeding',
        selected: false,
      },
      {
        title: 'Dataset',
        selected: false,
      },
      {
        title: 'Digital artefact',
        selected: false,
      },
      {
        title: 'Exhibition / Performance',
        selected: false,
      },
      {
        title: 'Journal article',
        selected: false,
      },
      {
        title: 'Other',
        selected: false,
      },
      {
        title: 'Physical artefact',
        selected: false,
      },
    ],
  },
  {
    filterName: 'Includes full text',
    type: 'boolean',
    selected: false,
  },
]

const FilterListItem = ({ filter, toggleSwitch, children }) => {
  const [open, setOpen] = useState(false)
  const animatedController = useRef(new Animated.Value(0)).current
  const [bodySectionHeight, setBodySectionHeight] = useState(null)

  let filterDescription = 'All'
  const optionsSelected = filter.options
    ?.filter(option => option.selected)
    .map(option => option.title)
  if (optionsSelected && optionsSelected.length) {
    filterDescription = optionsSelected.shift()
    if (optionsSelected.length > 0) {
      filterDescription += ` +${optionsSelected.length}`
    }
  }

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  })

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  })

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    }
    setOpen(!open)
  }

  return (
    <>
      <TouchableWithoutFeedback onPress={toggleListItem}>
        <ListItem>
          <FilterName>{filter.filterName}</FilterName>
          {filter.type === 'boolean' ? (
            <Switch
              style={{ marginRight: 16 }}
              trackColor={{ true: '#2AAD79' }}
              onValueChange={() => toggleSwitch(filter.filterName)}
              value={filter.selected}
            />
          ) : (
            <FilterDetails>
              <FilterDescription>{filterDescription}</FilterDescription>
              <Animated.View
                style={{
                  transform: [{ rotateZ: arrowAngle }],
                  backgroundColor: '#EBECF2',
                  height: 24,
                  width: 24,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 12,
                  marginRight: 10,
                }}
              >
                <FilterArrowImage
                  source={require('../../../assets/icons/arrow-down-1.png')}
                />
              </Animated.View>
            </FilterDetails>
          )}
        </ListItem>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <FilterBody
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
          {children}
        </FilterBody>
      </Animated.View>
    </>
  )
}

const FilterScreen = ({ navigation }) => {
  const [filters, setFilters] = useState(initialFilters)
  const [activeFilters, setActiveFilters] = useState([])
  console.log(activeFilters)

  useEffect(() => {
    let active = []
    filters.forEach(filter => {
      filter.options?.forEach(option => {
        if (option.selected) {
          active.push(filter.filterName)
        }
      })

      if (filter.selected) {
        active.push(filter.filterName)
      }
    })

    setActiveFilters(Array.from(new Set(active)))
  }, [filters])

  const toggleFilter = (filterName, optionName = null) => {
    const updatedFilters = [...filters]

    const item = updatedFilters.find(filter => filter.filterName === filterName)

    if (item.options) {
      const selectedOption = item.options.find(
        option => option.title === optionName
      )
      selectedOption.selected = !selectedOption.selected
    } else {
      item.selected = !item.selected
    }

    setFilters(updatedFilters)
  }

  const clearFilters = () => {
    const existingFilters = [...filters]
    existingFilters.map(filter => {
      if (filter.options) {
        filter.options.map(option => (option.selected = false))
      } else {
        filter.selected = false
      }
    })

    setFilters(existingFilters)
  }

  const saveFilters = () => {
    let filterCount = 0
    filters.forEach(filter => {
      switch (filter.type) {
        case 'selectone':
          filter.options.forEach(option => {
            if (option.selected) {
              filterCount = filterCount + 1
            }
          })
          break
        case 'boolean':
          if (filter.selected) {
            filterCount = filterCount + 1
          }
          break
      }
    })

    navigation.navigate('SearchRecordScreen', { filterCount })
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header>
        <HeaderItem align="flex-start">
          <AntDesign
            onPress={() => navigation.pop()}
            name="close"
            size={24}
            color="#0E1012"
          />
        </HeaderItem>
        <HeaderItem align="center">
          <HeaderTitle>
            Filter {activeFilters.length > 0 && `- ${activeFilters.length}`}
          </HeaderTitle>
        </HeaderItem>
        <HeaderItem align="flex-end">
          <ClearButton onPress={clearFilters} disabled={!activeFilters.length}>
            <ClearButtonText>Clear</ClearButtonText>
          </ClearButton>
        </HeaderItem>
      </Header>

      <Body>
        <Filters>
          {filters.map(filter => (
            <FilterListItem
              key={filter.filterName}
              filter={filter}
              toggleSwitch={toggleFilter}
            >
              {filter.options ? (
                <FilterOptions>
                  {filter.options &&
                    filter.options.map(option => (
                      <OptionWrapper
                        key={option.title}
                        optionSelected={option.selected}
                        onPress={() =>
                          toggleFilter(filter.filterName, option.title)
                        }
                      >
                        <Option>
                          {option.color && <OptionIcon color={option.color} />}
                          <OptionText optionSelected={option.selected}>
                            {option.title}
                          </OptionText>
                        </Option>
                        <OptionCount optionSelected={option.selected}>
                          10
                        </OptionCount>
                      </OptionWrapper>
                    ))}
                </FilterOptions>
              ) : null}
            </FilterListItem>
          ))}
        </Filters>
      </Body>

      <Footer>
        <BigButton primary onPress={saveFilters}>
          <Typography.Button color="#FFFFFF">View results</Typography.Button>
        </BigButton>
      </Footer>
    </SafeAreaView>
  )
}

export default FilterScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ebecf2',
  },
  bodyBackground: {
    backgroundColor: '#EFEFEF',
    overflow: 'hidden',
  },
})
