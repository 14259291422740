import React, { useContext } from 'react'
import {
  Image,
  Platform,
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import Constants from 'expo-constants'
import { AlignedRow } from './Layout'
import Avatar from './Avatar'
import WorktribeIcon from './WorktribeIcon'
import { AuthContext } from '../../contexts/AuthContext'

const ConversationHeader = props => {
  const { conversation, navigation } = props
  const { user } = useAuth()

  const participants = conversation.users_by_relationship.PARTICIPANT.filter(
    participant => {
      return participant.id !== user.userid
    }
  )

  const settings = () => {
    navigation.navigate('GroupSettings', { conversation })
  }

  return (
    <View
      style={
        Platform.OS === 'android' && { paddingTop: Constants.statusBarHeight }
      }
    >
      <AlignedRow justifyContent="space-between" style={styles.headerContent}>
        <AlignedRow style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.arrowTouch}
            onPress={() => navigation.pop()}
          >
            <Image
              style={styles.arrow}
              source={require('../../../assets/icons/streamline-icon-arrow-left.png')}
            />
          </TouchableOpacity>
          {participants.length > 0 && (
            <Avatar
              userId={participants[0].id}
              group={participants.length > 2}
              radius={10}
              size={32}
            />
          )}
          <View style={{ flex: 1, marginLeft: 9 }}>
            <Text style={styles.headerTitle}>
              {conversation.fields.conversation_name
                ? conversation.fields.conversation_name
                : participants.map(participant => participant.name).join(', ')}
            </Text>
            <Text style={styles.headerSubtitle}>
              Department of Microbiology
            </Text>
          </View>
        </AlignedRow>
        <View style={{ justifyContent: 'space-between' }}>
          <TouchableOpacity onPress={settings}>
            <WorktribeIcon
              name={'information-circle'}
              color={'#0E1012'}
              size={20}
            />
          </TouchableOpacity>
        </View>
      </AlignedRow>
    </View>
  )
}

export default ConversationHeader

const styles = StyleSheet.create({
  headerContent: {
    paddingTop: 4,
    paddingHorizontal: 20,
    paddingBottom: 9,
    borderBottomWidth: 1,
    borderColor: '#EBEBEB',
  },
  headerTitle: {
    fontFamily: 'proxima-nova-semibold',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 20,
    color: '#0E1012',
  },
  headerSubtitle: {
    fontFamily: 'proxima-nova-light',
    fontWeight: '400',
    fontSize: 14,
    color: '#757885',
  },
  arrowTouch: {
    paddingVertical: 10,
    marginLeft: -5,
    paddingLeft: 5,
  },
  arrow: {
    width: 18,
    height: 18,
    marginRight: 13,
  },
})
