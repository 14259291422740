import React, { useState } from 'react'
import { StyleSheet, Pressable, TouchableOpacity } from 'react-native'

const useButtonState = props => {
  const { disabled, primary, transparent, transparentHighlight } = props

  if (transparent) {
    return {
      restBackground: 'transparent',
      pressedBackground: 'transparent',
      restBorder: '#DDE1ED',
      pressedBorder: '#DDE1ED',
    }
  }

  if (transparentHighlight) {
    return {
      restBackground: 'transparent',
      pressedBackground: 'transparent',
      restBorder: '#DDE1ED',
      pressedBorder: '#DDE1ED',
    }
  }

  if (disabled) {
    return {
      restBackground: '#A0A4B8',
      pressedBackground: '#A0A4B8',
      restBorder: '#DDE1ED',
      pressedBorder: '#DDE1ED',
    }
  }

  if (primary) {
    return {
      restBackground: '#2AAD79',
      pressedBackground: '#2AAD79',
      restBorder: '#2aad79',
      pressedBorder: '#2aad79',
    }
  }

  return {
    restBackground: '#FFF',
    pressedBackground: '#FFF',
    restBorder: '#DDE1ED',
    pressedBorder: 'rgba(0,0,0,0.15)',
  }
}

export const BigButton = props => (
  <Button {...{ ...props, ...{ buttonPadding: { paddingVertical: 16 } } }} />
)

export const MediumButton = props => (
  <Button
    {...{
      ...props,
      ...{ buttonPadding: { paddingVertical: 10, paddingHorizontal: 36 } },
    }}
  />
)

export const SmallButton = props => (
  <Button
    {...{
      ...props,
      ...{ buttonPadding: { paddingVertical: 9, paddingHorizontal: 32 } },
    }}
  />
)

export const TinyButton = props => (
  <Button
    {...{
      ...props,
      ...{ buttonPadding: { paddingVertical: 4, paddingHorizontal: 16 } },
    }}
  />
)

export const TeenyTinyButton = props => (
  <Button
    {...{
      ...props,
      ...{ buttonPadding: { paddingVertical: 4, paddingHorizontal: 6 } },
    }}
  />
)

export const SplitButton = props => <Button {...props} />

const Button = props => {
  const { restBackground, pressedBackground, restBorder, pressedBorder } =
    useButtonState(props)
  const [hovered, setHovered] = useState(false)
  return (
    <Pressable
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={props.disabled}
      onPress={props.onPress}
      style={({ pressed }) => [
        {
          backgroundColor: pressed
            ? restBackground
            : hovered
            ? props.primary
              ? 'rgba(42,173,121, 0.85)'
              : props.transparentHighlight
              ? 'rgba(255,255,255,1)'
              : 'transparent'
            : pressedBackground,
          borderColor: pressed ? pressedBorder : restBorder,
        },
        { ...props.style },
        styles.wrapperCustom,
        props.buttonPadding,
      ]}
    >
      {props.children}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  wrapperCustom: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    borderWidth: 1,
  },
})
