import React, { useCallback, useContext, useRef, useState } from 'react'
import styled from 'styled-components/native'
import { Image, StatusBar, TouchableOpacity, Animated } from 'react-native'
import Constants from 'expo-constants'
import Ellipsis from '../../components/shared/Ellipsis'
import Avatar from '../../components/shared/Avatar'
import { FontAwesome } from '@expo/vector-icons'

import Overview from '../../components/shared/Overview'
import Sections from '../../components/shared/Sections'
import { useAuth } from '../../contexts/AuthContext'
import { useDispatch } from 'react-redux'
import { APIContext } from '../../contexts/APIContext'

// temporary job titles until data sorted
const JOB_TITLES = [
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
  'Rasputin Impersonator',
  'Car Behaviour Consultant',
  'Ex-Moonshiner',
  'Pork Rind Expert',
  'Bread Scientist',
  'Bear Biologist and Paperfolder',
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
]

const Container = styled.View`
  flex: 1;
  background-color: #1b283f;
`

const Header = styled.View``

const Back = styled.TouchableOpacity`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  opacity: 0.3;
`

const ToolBar = styled.View`
  margin-top: ${Constants.statusBarHeight}px;
  padding: 7px 16px;
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ToolBarItem = styled.View`
  flex: ${props => props.flex ?? 1};
  align-items: ${props => props.align};
`

const ProfileWrapper = styled.View`
  align-items: center;
`

const ProfileDetails = styled.View`
  margin: 15px 0;
  align-items: center;
`

const ProfileName = styled.Text`
  font-family: proxima-nova-bold;
  font-size: 26px;
  line-height; 32px;
  color: #FFFFFF;
`

const ProfileJobTitle = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  line-height; 24px;
  color: #FFFFFF;
`

const ButtonContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`

const Button = styled.TouchableOpacity`
  background: #2aad79;
  padding: 10px 32px;
  border-radius: 30px;
  margin: 0 10px;
  border: 1px solid #2aad79;
  flex-direction: row;
  align-items: center;
`

const ButtonIcon = styled.Image`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`

const ButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova;
  font-weight: 600;
  font-size: 15px;
`

const SocialContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  margin: 24px 0;
`

const SocialIcon = styled.View`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  margin: 0 16px;
`

const Tab = styled.TouchableOpacity`
  margin: 0 10px;
  align-items: center;
`

const TabTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  opacity: ${props => (props.active ? 1 : 0.7)};
`

const TabUnderline = styled.View`
  height: 4px;
  width: 40px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: ${props => (props.active ? '#FFFFFF' : 'transparent')};
`

const Body = styled.ScrollView`
  flex: 1;
  background-color: #ebecf2;
`

const ContextMenu = styled.View`
  padding: 6px 0;
  position: absolute;
  width: 220px;
  background-color: #ffffff;
  border-radius: 16px;
  top: 95px;
  right: 16px;
  z-index: 1;
  shadow-opacity: 0.08;
  shadow-radius: 20px;
  shadow-color: #000;
  shadow-offset: 0px 4px;
  elevation: 1;
`

const ContextMenuItem = styled.TouchableOpacity`
  padding: 10px 20px;
`

const ContextMenuText = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  color: ${props => (props.alert ? '#E94545' : '#0e1012')};
`

const Touchable = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1;
`

const contextMenuItems = [
  {
    title: 'Edit Profile',
    alert: false,
  },
  {
    title: 'Export',
    alert: false,
  },
  {
    title: 'Follow',
    alert: false,
  },
  {
    title: 'Archive',
    alert: false,
  },
  {
    title: 'Delete',
    alert: true,
  },
  {
    title: 'Logout',
    alert: true,
  },
  {
    title: 'Switch user',
    alert: true,
  },
]

const HEADER_EXPANDED_HEIGHT = 300

const ProfileScreen = ({ navigation, route }) => {
  const { profile } = route.params

  const [selectedTab, setSelectedTab] = useState('Overview')
  const [showContext, setShowContext] = useState(false)
  const [isMessaging, setIsMessaging] = useState(false)

  const { createConversation } = useContext(APIContext)
  const dispatch = useDispatch()

  const { user, signOut } = useAuth()
  const signOutButton = contextMenuItems.find(item => item.title === 'Logout')
  const switchUserButton = contextMenuItems.find(
    item => item.title === 'Switch user'
  )
  signOutButton.action = () => {
    signOut()
    setShowContext(false)
  }
  switchUserButton.action = () => {
    navigation.navigate('Switch')
    setShowContext(false)
  }

  const availableMenuItems = contextMenuItems.filter(item => {
    if (
      (item.title === 'Logout' || item.title === 'Switch user') &&
      user &&
      user.userid !== profile.id
    )
      return false
    return true
  })

  const createDirectMessage = useCallback(async () => {
    try {
      setIsMessaging(true)

      const response = await createConversation([profile.id])
      const { conversation } = response.data

      // dispatch(
      //   updateConversations({
      //     data: JSON.stringify(conversation),
      //   })
      // )

      navigation.navigate('Message', { conversationId: conversation.id })
    } catch (e) {
      console.log(e)
      setIsMessaging(false)
    }
  }, [profile])

  const scrollY = useRef(new Animated.Value(0)).current

  const height = scrollY.interpolate({
    inputRange: [0, HEADER_EXPANDED_HEIGHT],
    outputRange: [HEADER_EXPANDED_HEIGHT, 0],
    extrapolate: 'clamp',
  })

  const headerOpacity = scrollY.interpolate({
    inputRange: [0, HEADER_EXPANDED_HEIGHT],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  })

  const titleOpacity = scrollY.interpolate({
    inputRange: [HEADER_EXPANDED_HEIGHT - 50, HEADER_EXPANDED_HEIGHT],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  })

  return (
    <Container>
      <StatusBar animated={true} barStyle="light-content" />

      {showContext ? (
        <Touchable
          activeOpacity={1}
          disabled={!showContext}
          onPress={() => setShowContext(false)}
        >
          <ContextMenu>
            {availableMenuItems.map(menuItem => (
              <ContextMenuItem key={menuItem.title} onPress={menuItem.action}>
                <ContextMenuText alert={menuItem.alert}>
                  {menuItem.title}
                </ContextMenuText>
              </ContextMenuItem>
            ))}
          </ContextMenu>
        </Touchable>
      ) : null}

      <Header>
        <ToolBar>
          <ToolBarItem align="flex-start">
            <Back onPress={() => navigation.goBack()}>
              <Image
                style={{ height: 16, width: 8 }}
                source={require('../../../assets/icons/arrow-left-white.png')}
              />
            </Back>
          </ToolBarItem>
          <ToolBarItem flex={2} align="center">
            <Animated.Text
              style={{
                fontFamily: 'proxima-nova-semibold',
                fontSize: 17,
                color: '#FFFFFF',
                opacity: titleOpacity,
              }}
            >
              {profile.name}
            </Animated.Text>
          </ToolBarItem>
          <ToolBarItem align="flex-end">
            <TouchableOpacity onPress={() => setShowContext(prev => !prev)}>
              <Ellipsis color="#FFFFFF" />
            </TouchableOpacity>
          </ToolBarItem>
        </ToolBar>

        <ProfileWrapper>
          <Animated.View
            style={{ height, opacity: headerOpacity, alignItems: 'center' }}
          >
            <Avatar
              userId={profile.id}
              size={108}
              radius={30}
              isEditable={true}
            />
            <ProfileDetails>
              <ProfileName>{profile.name}</ProfileName>
              <ProfileJobTitle>{JOB_TITLES[profile.id - 1]}</ProfileJobTitle>
            </ProfileDetails>
            <ButtonContainer>
              <Button disabled={isMessaging} onPress={createDirectMessage}>
                <ButtonIcon
                  source={require('../../../assets/icons/messages-bubble-outline.png')}
                />
                <ButtonText>Message</ButtonText>
              </Button>
              <Button>
                <ButtonIcon
                  source={require('../../../assets/icons/phone-outline.png')}
                />
                <ButtonText>Call</ButtonText>
              </Button>
            </ButtonContainer>
            <SocialContainer>
              <TouchableOpacity>
                <SocialIcon>
                  <FontAwesome name="facebook-f" size={20} color="black" />
                </SocialIcon>
              </TouchableOpacity>

              <TouchableOpacity>
                <SocialIcon>
                  <FontAwesome name="twitter" size={20} color="black" />
                </SocialIcon>
              </TouchableOpacity>

              <TouchableOpacity>
                <SocialIcon>
                  <FontAwesome name="linkedin" size={20} color="black" />
                </SocialIcon>
              </TouchableOpacity>
            </SocialContainer>
          </Animated.View>

          <Animated.View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
              backgroundColor: '#1b283f',
              width: '100%',
            }}
          >
            <Tab onPress={() => setSelectedTab('Overview')}>
              <TabTitle active={selectedTab === 'Overview'}>Overview</TabTitle>
              <TabUnderline active={selectedTab === 'Overview'} />
            </Tab>
            <Tab onPress={() => setSelectedTab('Sections')}>
              <TabTitle active={selectedTab === 'Sections'}>Sections</TabTitle>
              <TabUnderline active={selectedTab === 'Sections'} />
            </Tab>
          </Animated.View>
        </ProfileWrapper>
      </Header>
      <Body
        style={{ flex: 1, backgroundColor: '#ebecf2' }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: false }
        )}
        scrollEventThrottle={32}
        bounces={false}
      >
        {
          {
            Overview: <Overview />,
            Sections: <Sections />,
          }[selectedTab]
        }
      </Body>
    </Container>
  )
}

export default ProfileScreen
