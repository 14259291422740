import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Pressable, TextInput, View, Platform } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import { SmallButton } from '../shared/Button'
import WorktribeIcon from '../shared/WorktribeIcon'

const Toolbar = ({
  selectedIds,
  selectAll,
  fields,
  onFieldSelect,
  onClose,
  handleDelete
}) => {
  const {
    typography: { Button },
  } = useContext(ThemeContext)

  const [editing, setEditing] = useState(false)
  const [fieldData, setFieldData] = useState({})

  const setFieldValue = useCallback((field, value) => {
    setFieldData(data => ({
      ...data,
      [field]: value,
    }))
  }, [])

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 80,
        backgroundColor: '#1D7A55',
        borderBottomLeftRadius: 30,
      }}
    >
      {editing && (
        <AlignedRow
          style={{
            flex: 1,
            paddingTop: 14,
            paddingHorizontal: 40,
            justifyContent: 'center',
          }}
        >
          {Object.entries(fields).map(([field, { type }]) => {
            switch (type) {
              default: {
                return <ToolbarInput field={field} onChange={setFieldValue} />
              }
              // default: {
              //   return null
              // }
            }
          })}
        </AlignedRow>
      )}

      <AlignedRow
        style={{
          flex: 1,
          justifyContent: 'space-between',
          paddingHorizontal: 40,
          minHeight: 64,
          justifyContent: 'center',
        }}
      >
        <AlignedRow style={{ flex: 1 }}>
          <Button color="#FFF">
            {selectedIds.length > 0 ? selectedIds.length : `1`} selected
          </Button>
          <SmallButton
            transparent
            style={{
              marginLeft: 16,
              borderColor: 'rgba(193, 200, 222, 0.6)',
            }}
          >
            <Button color="#FFF" onPress={selectAll}>
              Select all
            </Button>
          </SmallButton>
        </AlignedRow>

        <AlignedRow style={{ flex: 2 }} justifyContent='center'>
          <SmallButton
            onPress={() => setEditing(editing => !editing)}
            transparent
            style={{
              marginLeft: 16,
              borderColor: 'rgba(193, 200, 222, 0.6)',
              backgroundColor: editing ? '#1A523C' : 'transparent',
            }}
          >
            <AlignedRow>
              <WorktribeIcon name="task-list-edit" color="#FFF" />
              <Button style={{ marginLeft: 8 }} color="#FFF">
                Bulk edit
              </Button>
            </AlignedRow>
          </SmallButton>

          <SmallButton
            transparent
            style={{
              marginLeft: 16,
              borderColor: 'rgba(193, 200, 222, 0.6)',
            }}
          >
            <AlignedRow>
              <WorktribeIcon name="share-3" color="#FFF" />
              <Button style={{ marginLeft: 8 }} color="#FFF">
                Share
              </Button>
            </AlignedRow>
          </SmallButton>

          <SmallButton
            transparent
            style={{
              marginLeft: 16,
              borderColor: 'rgba(193, 200, 222, 0.6)',
            }}
          >
            <AlignedRow>
              <WorktribeIcon name="archive" color="#FFF" />
              <Button style={{ marginLeft: 8 }} color="#FFF">
                Archive
              </Button>
            </AlignedRow>
          </SmallButton>

          <SmallButton
            onPress={() => handleDelete(selectedIds)}
            transparent
            style={{
              marginLeft: 16,
              borderColor: 'rgba(193, 200, 222, 0.6)',
            }}
          >
            <AlignedRow>
              <WorktribeIcon name="bin" color="#FFF" />
              <Button style={{ marginLeft: 8 }} color="#FFF">
                Delete
              </Button>
            </AlignedRow>
          </SmallButton>
        </AlignedRow>

        <AlignedRow style={{ flex: 1, justifyContent: 'flex-end' }}>
          <Pressable onPress={onClose}>
            <WorktribeIcon name="remove" size={24} color="#FFFFFF" />
          </Pressable>
        </AlignedRow>
      </AlignedRow>
    </View>
  )
}

export default Toolbar

const ToolbarInput = ({ field, onChange }) => {
  const {
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)
  const [text, onChangeText] = useState(field)
  useEffect(() => {
    onChange(field, text)
  }, [text])
  return (
    <Pressable
      style={{
        borderRadius: 16,
        paddingVertical: 8,
        paddingHorizontal: 20,
        backgroundColor: bg.card,
        marginHorizontal: 6,
        maxWidth: 361,
        flex: 1,
      }}
    >
      <TextInput
        style={[
          {
            fontFamily: 'proxima-nova',
            fontSize: 15,
            color: fonts.primary,
            width: 600,
            paddingVertical: 0,
            minHeight: 24,
          },
          Platform.OS === 'web' && { outline: 'none' },
        ]}
        onChangeText={onChangeText}
        value={text}
      />
    </Pressable>
  )
}
