import React, { useCallback, useContext } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Badge } from 'react-native-elements'

import * as RootNavigation from '../../services/RootNavigation'

import { AlignedRow } from './Layout'
import Avatar from './Avatar'
import { AuthContext, useAuth } from '../../contexts/AuthContext'
import useTimeSince from '../../hooks/useTimeSince'
import { useSelector } from 'react-redux'
import WorktribeIcon from './WorktribeIcon'

const ConversationItem = ({ conversation, index, data, handleItemPress }) => {
  const { usersTyping } = useSelector(state => state.records)

  const isGroupConversation =
    conversation.users_by_relationship.PARTICIPANT.length > 2
  const { getFormattedShortDate } = useTimeSince()
  const { user } = useAuth()

  const participants = conversation?.users_by_relationship?.PARTICIPANT.filter(
    participant => {
      return participant.id !== user?.userid
    }
  )

  const getLatestMessage = useCallback(() => {
    if (!conversation.latest_comment) {
      return `No messages yet`
    }

    const {
      latest_comment: { user, is_change, text },
    } = conversation

    if (isGroupConversation && user && !is_change) {
      return `${user.name}: ${text}`
    }

    return text
  }, [conversation, isGroupConversation])

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={
        handleItemPress
          ? () => {
              handleItemPress({
                record: conversation.id,
              })
            }
          : () =>
              RootNavigation.navigate('Message', {
                conversationId: conversation.id,
              })
      }
    >
      <View
        style={[
          styles.container,
          index === 0 && { borderTopLeftRadius: 16, borderTopRightRadius: 16 },
          index === data.length - 1 && {
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          },
        ]}
      >
        <AlignedRow>
          {participants.length > 0 && (
            <Avatar
              size={48}
              radius={16}
              group={isGroupConversation}
              userId={participants[0].id}
            />
          )}
          <View style={styles.messageContainer}>
            <AlignedRow
              justifyContent="space-between"
              style={{ alignItems: 'flex-start' }}
            >
              <Text style={styles.authorText}>
                {conversation.fields.conversation_name
                  ? conversation.fields.conversation_name
                  : participants
                      .map(participant => participant.name)
                      .join(', ')}
              </Text>
              <Text
                style={[
                  styles.dateText,
                  { color: conversation.unseen_by_me ? '#0174F3' : '#A0A4B8' },
                ]}
              >
                {conversation.latest_comment
                  ? getFormattedShortDate(
                      new Date(conversation.latest_comment.created_at),
                      user?.preferred_lang
                    )
                  : getFormattedShortDate(
                      new Date(conversation.created_at),
                      user?.preferred_lang
                    )}
              </Text>
            </AlignedRow>
            <AlignedRow justifyContent="space-between">
              {usersTyping?.[conversation.id]?.length ? (
                <Text
                  numberOfLines={1}
                  style={[styles.messageText, { color: '#00C5A8' }]}
                >
                  {isGroupConversation
                    ? `${usersTyping[conversation.id].join(', ')} ${
                        usersTyping[conversation.id].length > 1
                          ? 'are typing'
                          : 'is typing'
                      }`
                    : 'Typing...'}
                </Text>
              ) : (
                <Text style={styles.messageText} numberOfLines={1}>
                  {getLatestMessage()}
                </Text>
              )}
              {conversation.unseen_by_me ? (
                <Badge
                  badgeStyle={styles.badge}
                  value={conversation.unseen_by_me}
                  status="primary"
                />
              ) : !conversation.push_notifications_enabled ? (
                <WorktribeIcon name="muted" />
              ) : null}
            </AlignedRow>
          </View>
        </AlignedRow>
      </View>
    </TouchableOpacity>
  )
}

export default React.memo(ConversationItem)

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  messageContainer: {
    flex: 1,
    marginLeft: 16,
  },
  messageText: {
    flex: 1,
    fontFamily: 'proxima-nova',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
    color: '#757885',
  },
  authorText: {
    fontFamily: 'proxima-nova-semibold',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 22,
    color: '#0E1012',
    flex: 1,
  },
  dateText: {
    fontFamily: 'proxima-nova',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 18,
    color: '#A0A4B8',
  },
  badge: {
    height: 20,
    minWidth: 20,
    borderRadius: 20,
    backgroundColor: '#296DFF',
  },
})
