import React, { useContext, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'
import { CenteredView } from '../../components/shared/Layout'
import Searchatron from '../../components/landscape/Searchatron'
import useRecordHandler from '../../hooks/useRecordHandler'

import Notification from '../../components/shared/Notification'
import RecordHeader from '../../components/landscape/RecordHeader'
import { useAuth } from '../../contexts/AuthContext'
import RecordView from '../../components/landscape/RecordView'

interface MessagesScreenProps {}

const MESSAGES_CLASS = 'Person'

const MessagesScreen: React.FC<MessagesScreenProps> = () => {
  const [showSearch, setShowSearch] = useState(false)

  const { user } = useAuth()

  const { record, notification, recordError, selectedTab, selectTab } =
    useRecordHandler(
      MESSAGES_CLASS,
      user!.userid,
      ['Conversations'],
      'Conversations',
      true
    )

  const {
    typography: { Subtitle },
  } = useContext(ThemeContext)

  if (!record) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      <RecordHeader
        setShowSearch={setShowSearch}
        record={record}
        selectedTab={selectedTab}
        setSelectedTab={selectTab}
        allowBackNavigation={false}
      />

      <Container style={{ alignItems: 'center' }}>
        {!!recordError && <Subtitle color="red">{recordError}</Subtitle>}

        <Content
          style={
            ['cover', 'widegrey'].includes(record?.header.style) && {
              maxWidth: 1120,
              paddingHorizontal: 0,
            }
          }
        >
          <RecordView
            recordId={user!.userid}
            rClass={MESSAGES_CLASS}
            section={record?.sections[0]}
          />
        </Content>
      </Container>

      {notification?.message && <Notification notification={notification} />}
    </>
  )
}

export default withLayout(MessagesScreen)

const Container = styled.View`
  flex: 1;
`

const Content = styled.View`
  flex: 1;
  width: 100%;
`
