import { useState, useMemo, useContext, useEffect } from 'react'
import { View, Platform, Keyboard, ActivityIndicator } from 'react-native'

import type { IItem, IItemProperty } from '../../../interfaces/record'
import { ThemeContext } from '../../../contexts/ThemeContext'

import QuillEditor, { QuillToolbar } from 'react-native-cn-quill'
import { useQuill } from 'react-quilljs'
import '../../../config/quillImport'
import { AlignedRow } from '../../shared/Layout'

interface InlineTextProps {
  item: IItem
  value: any
  onChange: Function
  onBlur: Function
  inputBackgroundColor: string
}

const InlineRichText: React.FC<InlineTextProps> = ({
  item,
  value,
  onChange,
  onBlur,
  inputBackgroundColor,
}) => {
  const { property, required }: { property: IItemProperty; required: string } =
    item

  const { size, name } = property

  const [text, onChangeText] = useState<string>(value.value)
  const [saving, setSaving] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  useEffect(() => {
    if (!inputFocused) {
      Keyboard.dismiss()
    }
  }, [inputFocused])

  const [placeholderText, setPlaceholderText] = useState(name)

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : `${name}${required ? '*' : ''}`)
  }, [inputFocused])

  useEffect(() => {
    if (text === value.value) return

    const handler = setTimeout(() => {
      onChange(text)
      setSaving(true)
    }, 2000)

    return () => clearTimeout(handler)
  }, [text, value])

  useEffect(() => {
    if (!saving) return
    const handler = setTimeout(() => {
      onBlur()
      setSaving(false)
    }, 1500)

    return () => clearTimeout(handler)
  }, [saving])

  const minHeight = useMemo(() => {
    return size === 'medium' ? '102px' : size === 'large' ? '240px' : '60px'
  }, [size])

  const valid = useMemo(() => {
    if (required === 'true' && !text.length) {
      return false
    }
    return true
  }, [text, required])

  const {
    typography: { Label, Caption },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  // style picker
  let quillConfig = { theme: 'snow' }

  let inputContent
  switch (Platform.OS) {
    case 'web':
      const { quill, quillRef } = useQuill(quillConfig)

      useEffect(() => {
        quill?.clipboard.dangerouslyPasteHTML(value.value)

        quill?.on('text-change', () => onChangeText(quill.root.innerHTML))
      }, [quill, value])

      inputContent = (
        <View
          ref={quillRef}
          style={{
            position: 'relative',
            height: minHeight,
            backgroundColor: inputBackgroundColor || bg.card,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          {saving && (
            <AlignedRow
              gap={4}
              style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                zIndex: 99,
              }}
            >
              <Caption>Autosaving</Caption>
              <ActivityIndicator size="small" />
            </AlignedRow>
          )}
        </View>
      )
      break
    default:
      const _editor = React.createRef()

      inputContent = (
        <>
          {showNativeToolbar && (
            <QuillToolbar editor={_editor} options="basic" theme="light" />
          )}
          <View
            style={{
              flex: 1,
              padding: 5,
              borderColor: 'gray',
              borderWidth: 1,
              marginHorizontal: 30,
              marginVertical: 5,
              backgroundColor: 'white',
            }}
          >
            <QuillEditor
              ref={_editor}
              quill={quillConfig}
              initialHtml={value.value}
              defaultFontFamily="proxima-nova-bold"
            />
          </View>
        </>
      )
  }

  return inputContent
}

export default InlineRichText
