import { Easing } from 'react-native-reanimated'
import type { Positions, Offset, Record } from './section-list'

export const HEADING_OFFSET = 60
export const ROW_HEIGHT = 80

export const animationConfig = {
  easing: Easing.inOut(Easing.ease),
  duration: 350,
}

export const getPosition = (rowIndex: number, offset: number) => {
  'worklet'

  return { y: offset + HEADING_OFFSET + rowIndex * (ROW_HEIGHT + 12) }
}

export const getPositions = (data: Record[]) => {
  'worklet'

  return data.reduce((acc, curr, sectionIndex) => {
    const { data } = curr

    acc = Object.assign(
      acc,
      ...data.map((child, index) => ({
        [child.id]: {
          rowIndex: index,
          sectionIndex,
        },
      }))
    )

    return acc
  }, {})
}

export const getLoc = (y: number, offsets: Offset[]) => {
  'worklet'

  // get vertical offset
  // work out which block we are inside
  // create range array from offsets
  const sectionRanges: [a: number, b: number][] = Object.values(offsets).reduce(
    (acc, curr, index, array) => {
      if (array[index + 1]) {
        acc.push([curr.yoffset, array[index + 1].yoffset])
      } else {
        acc.push([curr.yoffset, Number.MAX_VALUE])
      }
      return acc
    },
    []
  )

  const sectionIndex = sectionRanges.findIndex(
    ([start, end]) => y >= start && y <= end
  )

  let rowIndex = -1
  if (sectionIndex > -1) {
    // now find order in this section
    const section = Object.values(offsets)[sectionIndex]

    const rowRanges = Object.values(section).reduce(
      (acc, curr, index, array) => {
        if (curr === -1) return acc

        if (array[index + 1]) {
          acc.push([curr, array[index + 1]])
        } else {
          acc.push([curr, curr + ROW_HEIGHT + 12])
        }

        return acc
      },
      []
    )

    rowIndex = rowRanges.findIndex(
      ([start, end]) => y >= start + ROW_HEIGHT / 2 && y <= end + ROW_HEIGHT / 2
    )
  }

  return {
    sectionIndex,
    rowIndex,
  }
}

export const getOffsets = (positions: Positions, data: Record) => {
  'worklet'

  const groupedItemsByLength = Object.values(positions).reduce(
    (acc, curr) => {
      acc[curr.sectionIndex]++
      return acc
    },
    data.map(d => 0)
  )

  // work out offsets
  const { results } = groupedItemsByLength
    .map(count => Math.max(1, count))
    .reduce(
      (acc, length) => {
        acc.results.push(acc.prev)
        const height = HEADING_OFFSET + length * (ROW_HEIGHT + 12)
        acc.prev += height

        return acc
      },
      {
        prev: 0,
        results: [],
      }
    )

  return results.reduce((acc, curr, index) => {
    // get all rows for this section, then sort of by index
    const children = Object.entries(positions).filter(
      ([id, { sectionIndex }]) => sectionIndex === index
    )

    const sorted = children.sort(
      ([ida, a], [idb, b]) => a.rowIndex - b.rowIndex
    )

    acc.push({
      yoffset: curr,
      ...Object.assign(
        {},
        ...sorted.map(([id], index) => ({
          [id]: curr + HEADING_OFFSET + index * ROW_HEIGHT + index * 12,
        }))
      ),
    })

    return acc
  }, [])
}

export function getPositionString(
  positions: Positions[],
  position: Positions,
  data: any
): string {
  let order = 'start'
  if (position.rowIndex === 0) {
    const targetId = findId(positions, position, 1)

    if (targetId) {
      order = `before ${targetId.replace('_', '')}`
    }
  } else {
    const targetId = findId(positions, position, -1)

    if (targetId) {
      order = `after ${targetId.replace('_', '')}`
    }
  }

  return order
}

function findId(positions: Positions[], position: Positions, num: number) {
  const target = Object.entries(positions).find(
    ([_, pos]) =>
      pos.sectionIndex === position.sectionIndex &&
      pos.rowIndex === position.rowIndex + num
  )

  return target ? target[0] : 0
}
