import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import styled from 'styled-components/native'
import { useAuth } from '../../contexts/AuthContext'
import { TouchableOpacity, View, Platform, ScrollView } from 'react-native'
import Avatar from '../shared/Avatar'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow } from '../shared/Layout'
import { ThemeContext } from '../../contexts/ThemeContext'
import AddMenu from './AddMenu'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import Version from '../shared/Version'
import { useRoute, useNavigation } from '@react-navigation/native'
import * as RootNavigation from '../../services/RootNavigation'
import HoverableOpacity from '../shared/HoverableOpacity'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import useAppBadges from '../../hooks/useAppBadges'
import useZubanubi from '../../hooks/useZubanubi'
import { capitalize } from '../../utils/capitalize'

const isRunningInElectron =
  Platform.OS === 'web' &&
  'userAgent' in navigator &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

const MenuBar = styled.View`
  padding: 27px 8px 24px 8px;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`

const MenuOption = styled.TouchableOpacity`
  background-color: #ffffff;
  position: relative;
  border-radius: 20px;
  padding: 16px 3px;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
  height: 78px;
`

const MenuText = styled.Text`
  font-family: proxima-nova;
  font-size: 13px;
  line-height: 18px;
  color: ${props => (props.active ? '#0A406A' : '#FFFFFF')};
  margin-top: 4px;
`

const MenuBadge = styled.View`
  position: absolute;
  width: 20px
  height: 18px;
  align-items: center;
  justify-content: center;
  top: 15px;
  right: 10px;
  height: 18px;
  border-radius: 30px;
  background-color: #2aad79;
  border-width: 1.5px;
`

const PWALogo = styled.View`
  margin: 15px 0;
`

const BottomActions = styled.View`
  align-items: center;
`

const Tooltip = styled.View`
  position: absolute;
  top: 60px;
  left: 8px;
  padding: 3px 10px;
  background-color: #757885;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 200px;
  z-index: 10;
`

const Menu = () => {
  const [showCreate, setShowCreate] = useState(false)
  const [showProfileContext, setShowProfileContext] = useState(false)
  const [showVersion, setShowVersion] = useState(false)
  const [repo, setRepo] = useState('main')

  useEffect(() => {
    async function getRepo() {
      const value = await AsyncStorage.getItem('@zubanubiRepo')
      if (value !== null) {
        setRepo(value)
      }
    }

    getRepo()
  }, [])

  const { user } = useAuth()
  useAppBadges()

  const {
    typography: { H4, Overline, Label },
    themeColors: { menuBg, bg },
  } = useContext(ThemeContext)

  const navigation = useNavigation()

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setShowCreate(false)
      setShowProfileContext(false)
    })
    return unsubscribe
  }, [navigation])

  const outsideHandler = useCallback(() => setShowCreate(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <MenuBar style={{ backgroundColor: menuBg }}>
      <TouchableOpacity
        onPress={() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
          })
        }}
        onMouseOver={() => setShowVersion(true)}
        onMouseOut={() => setShowVersion(false)}
      >
        {!!user['App Badge'].length && (
          <View
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              justifyContent: 'center',
              alignItems: 'center',
              height: 20,
              width: 20,
              backgroundColor: 'red',
              borderRadius: 10,
              borderWidth: 1.5,
              borderColor: menuBg,
              zIndex: 99,
            }}
          >
            <Overline color="#fff">{user['App Badge']}</Overline>
          </View>
        )}

        {isRunningInElectron ? (
          <PWALogo>
            <View style={{ alignItems: 'center' }}>
              <WorktribeIcon name={repo !== 'main' ? 'test-logo' : 'logo'} />
              {repo !== 'main' && (
                <Label style={{ marginTop: 4 }} color="#F2CE6A">
                  {capitalize(repo)}
                </Label>
              )}
            </View>
          </PWALogo>
        ) : (
          <View style={{ alignItems: 'center' }}>
            <WorktribeIcon name={repo !== 'main' ? 'test-logo' : 'logo'} />
            {repo !== 'main' && (
              <Label style={{ marginTop: 4 }} color="#F2CE6A">
                {capitalize(repo)}
              </Label>
            )}
          </View>
        )}
      </TouchableOpacity>

      {showVersion && (
        <Tooltip>
          <Version />
        </Tooltip>
      )}

      <View ref={wrapperRef}>
        <MenuItem
          label="Home"
          name="bird-house"
          badgeCount={user['Home Badge']}
        />
        <MenuItem
          label="Mine"
          name="single-neutral-phone-book"
          badgeCount={user['Mine Badge']}
        />
        <MenuItem
          label="Messages"
          name="message-bubble"
          badgeCount={user['Messages Badge']}
        />
        <MenuItem
          label="Services"
          name="layout-content"
          badgeCount={user['Services Badge']}
        />
        <MenuItem
          highlight={showCreate}
          label="Add new"
          name="create-circle"
          handlePress={() => setShowCreate(create => !create)}
        />
        {showCreate && (
          <View
            style={{
              position: 'absolute',
              left: 80,
              width: 304,
              backgroundColor: bg.primary,
              borderRadius: 20,
              shadowColor: 'rgba(0, 0, 0, 0.12)',
              shadowOffset: {
                width: 0,
                height: 12,
              },
              shadowOpacity: 0.58,
              shadowRadius: 16.0,

              elevation: 24,
              padding: 20,
            }}
          >
            <AlignedRow justifyContent="space-between">
              <H4 primary>Add new</H4>
              <TouchableOpacity onPress={() => setShowCreate(false)}>
                <WorktribeIcon name="remove" />
              </TouchableOpacity>
            </AlignedRow>
            <AddMenu navigation={navigation} />
          </View>
        )}
      </View>

      <BottomActions>
        <View style={{ marginBottom: 16 }}>
          <TouchableOpacity
            onPress={() => navigation.navigate('Log')}
            style={{ marginVertical: 16 }}
          >
            <WorktribeIcon name="question-help-circle" />
          </TouchableOpacity>
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            onPress={() => setShowProfileContext(show => !show)}
            userId={user.userid}
            size={32}
            radius={64}
          />

          {showProfileContext && (
            <ProfileMenu
              navigation={navigation}
              onClose={() => setShowProfileContext(false)}
            />
          )}
        </View>
      </BottomActions>
    </MenuBar>
  )
}

export default Menu

const MenuItem = ({ label, name, badgeCount, handlePress, highlight }) => {
  const route = useRoute()
  const {
    typography: { Overline },
    themeColors: { menuBg },
  } = useContext(ThemeContext)
  const [hovered, setHovered] = useState(false)

  return (
    <MenuOption
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={[
        route.name !== label && { backgroundColor: menuBg },
        highlight && { backgroundColor: 'rgba(14,16,18, 0.2)' },
        hovered &&
          route.name !== label && { backgroundColor: 'rgba(14,16,18, 0.2)' },
      ]}
      onPress={() => {
        if (handlePress) {
          handlePress()
        } else {
          RootNavigation.reset({
            index: 0,
            routes: [{ name: label }],
          })
        }
      }}
      activeOpacity={1}
    >
      <WorktribeIcon
        name={name}
        color={route.name === label ? menuBg : '#FFF'}
      />
      <MenuText
        style={label === 'Add new' && { marginTop: 10 }}
        active={route.name === label}
      >
        {label}
      </MenuText>
      {badgeCount?.length ? (
        <MenuBadge
          style={{ borderColor: route.name === label ? '#FFF' : menuBg }}
        >
          <Overline color="#FFFFFF">{badgeCount}</Overline>
        </MenuBadge>
      ) : null}
    </MenuOption>
  )
}

const ProfileMenu = ({ navigation, onClose }) => {
  const [zubanubiRepo, setZubanubiRepo] = useState('main')
  const [showEnvironments, setShowEnvironments] = useState(false)

  const {
    typography: { Body, Label },
    themeColors: { bg, hover, fonts },
    toggleTheme,
    theme,
    shadowStyles,
  } = useContext(ThemeContext)

  const { user, signOut } = useAuth()

  const visitProfile = useCallback(() => {
    navigation.push('Record', {
      rClass: 'person',
      recordId: user.userid,
    })
  }, [navigation, user.userid])

  useEffect(() => {
    ;(async () => {
      try {
        const value = await AsyncStorage.getItem('@zubanubiRepo')
        if (value !== null) {
          setZubanubiRepo(value)
        }
      } catch (e) {
        setZubanubiRepo('main?')
      }
    })()
  }, [])

  const switchZubanubiServer = async serverName => {
    try {
      AsyncStorage.setItem('@zubanubiRepo', serverName)
    } catch (e) {
      console.log('Error when saving the serverName to local storage', e)
    } finally {
      setZubanubiRepo(serverName)

      if (Platform.OS === 'web') {
        window?.location.reload(false)
      }
    }
  }

  const outsideHandler = useCallback(onClose, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <View
      ref={wrapperRef}
      style={[
        {
          position: 'absolute',
          backgroundColor: bg.primary,
          width: 234,
          left: 40,
          bottom: -20,
          borderRadius: 20,
          justifyContent: 'center',
          paddingVertical: 15,
        },
        shadowStyles,
      ]}
    >
      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <Label primary>{`Current server: ${zubanubiRepo}`}</Label>
        </AlignedRow>
      </HoverableOpacity>
      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
        onPress={visitProfile}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <WorktribeIcon name="profile" color="#757885" />
          <Body primary style={{ marginLeft: 20 }}>
            My profile
          </Body>
        </AlignedRow>
      </HoverableOpacity>

      <View style={{ flex: 1 }}>
        <HoverableOpacity
          onMouseEnter={() => setShowEnvironments(true)}
          activeOpacity={1}
          hoverStyle={{ backgroundColor: hover }}
        >
          <AlignedRow
            justifyContent="space-between"
            style={{ marginVertical: 10, paddingHorizontal: 25 }}
          >
            <AlignedRow>
              <WorktribeIcon color="#757885" name="newspaper-fold" />
              <Body primary style={{ marginLeft: 20 }}>
                Environment
              </Body>
            </AlignedRow>

            <WorktribeIcon name="arrow-right" />
          </AlignedRow>
        </HoverableOpacity>
        {showEnvironments && (
          <EnvironmentList
            onSelect={switchZubanubiServer}
            onExit={() => setShowEnvironments(false)}
          />
        )}
      </View>

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
        onPress={() => navigation.navigate('Settings')}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <WorktribeIcon name="settings" />
          <Body primary style={{ marginLeft: 20 }}>
            Settings
          </Body>
        </AlignedRow>
      </HoverableOpacity>

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
        onPress={() => navigation.navigate('Timetable')}
      >
        <AlignedRow
          justifyContent="space-between"
          style={{ marginVertical: 10, paddingHorizontal: 25 }}
        >
          <AlignedRow>
            <WorktribeIcon color="#757885" name="newspaper-fold" />
            <Body primary style={{ marginLeft: 20 }}>
              What's new
            </Body>
          </AlignedRow>

          <View
            style={{
              backgroundColor: '#D13E3E',
              paddingVertical: 3,
              paddingHorizontal: 6,
              borderRadius: 40,
            }}
          >
            <Label color="#fff">New!</Label>
          </View>
        </AlignedRow>
      </HoverableOpacity>

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <WorktribeIcon name="alarm-bell" />
          <Body primary style={{ marginLeft: 20 }}>
            Notifications
          </Body>
        </AlignedRow>
      </HoverableOpacity>

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <WorktribeIcon name="synchronize-arrow-clock" />
          <Body primary style={{ marginLeft: 20 }}>
            Audit trail
          </Body>
        </AlignedRow>
      </HoverableOpacity>

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
        onPress={() => toggleTheme(theme)}
      >
        <AlignedRow
          gap={20}
          style={{ marginVertical: 10, paddingHorizontal: 25 }}
        >
          <WorktribeIcon name="translate" />
          <Body primary>Toggle theme</Body>
        </AlignedRow>
      </HoverableOpacity>

      {Platform.OS == 'web' && (
        <HoverableOpacity
          activeOpacity={1}
          hoverStyle={{ backgroundColor: hover }}
          onPress={() => {
            navigation.navigate('Apps')
          }}
        >
          <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
            <WorktribeIcon size={22} name="calendar" />
            <Body primary style={{ marginLeft: 20 }}>
              Download the apps
            </Body>
          </AlignedRow>
        </HoverableOpacity>
      )}

      <HoverableOpacity
        activeOpacity={1}
        hoverStyle={{ backgroundColor: hover }}
        onPress={signOut}
      >
        <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
          <WorktribeIcon size={22} name="profile" />
          <Body primary style={{ marginLeft: 20 }}>
            Logout
          </Body>
        </AlignedRow>
      </HoverableOpacity>
    </View>
  )
}

const zubanubiUsers = ['main', 'craig', 'sara', 'derek']
const EnvironmentList = ({ onSelect, onExit }) => {
  const {
    typography: { Body },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  return (
    <View
      onMouseLeave={onExit}
      style={[
        {
          position: 'absolute',
          left: 234,
          top: -15,
          backgroundColor: bg.primary,
          width: 234,
          maxHeight: 400,
          borderRadius: 20,
          justifyContent: 'center',
          paddingVertical: 15,
        },
        shadowStyles,
      ]}
    >
      <ScrollView style={{ flex: 1 }}>
        {zubanubiUsers.map(environment => (
          <HoverableOpacity
            key={environment}
            activeOpacity={1}
            hoverStyle={{ backgroundColor: hover }}
            onPress={() => onSelect(environment)}
          >
            <AlignedRow style={{ marginVertical: 10, paddingHorizontal: 25 }}>
              <WorktribeIcon name="profile" color="#757885" />
              <Body primary style={{ marginLeft: 20 }}>
                {environment}
              </Body>
            </AlignedRow>
          </HoverableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}
