import React, { useContext, useRef, useEffect, useState } from 'react'
import { View, Pressable, ScrollView, ActivityIndicator } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow, CenteredView } from '../../components/shared/Layout'
import { format } from 'date-fns'
import withLayout from '../../HOC/withLayout'

import Annotation from '../../components/landscape/Annotation'

interface TimetableScreenProps {}

interface Event {
  title: string
  location: string
  code: string
  start: Date
  end: Date
}

const EVENTS: Event[] = [
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 9, 0, 0, 0),
    end: new Date(2022, 4, 21, 17, 0, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 9, 0, 0, 0),
    end: new Date(2022, 4, 21, 10, 0, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 11, 0, 0, 0),
    end: new Date(2022, 4, 21, 14, 30, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 11, 0, 0, 0),
    end: new Date(2022, 4, 21, 12, 30, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 12, 0, 0, 0),
    end: new Date(2022, 4, 21, 13, 15, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 13, 0, 0, 0),
    end: new Date(2022, 4, 21, 14, 0, 0, 0),
  },
  {
    title: 'Introduction to Linear Algebra',
    location: 'Workshop',
    code: 'B14',
    start: new Date(2022, 4, 21, 14, 30, 0, 0),
    end: new Date(2022, 4, 21, 17, 0, 0, 0),
  },
]

const START_DATE = new Date(2022, 4, 21, 9, 0, 0, 0)

const TimetableScreen: React.FC<TimetableScreenProps> = () => {
  const {
    typography: { Label },
    themeColors: { accents },
  } = useContext(ThemeContext)

  return <CenteredView><Annotation /></CenteredView>


  const [timetable, setTimetable] = useState([])
  const [percentageElapsed, setPercentageElapsed] = useState(0)
  const [width, setWidth] = useState(0)
  const [events, setEvents] = useState([])

  const timer = useRef()

  const calculatePercent = () => {
    const minutesPassed = getTimeElapsed()

    const percent = (minutesPassed / 480) * 100
    setPercentageElapsed(percent)
  }

  useEffect(() => {
    calculatePercent()
  }, [])

  useEffect(() => {
    timer.current = setInterval(() => {
      calculatePercent()
    }, 60000)

    return () => clearInterval(timer.current)
  }, [])

  useEffect(() => {
    const matrix = createTimetableMatrix()

    setTimetable(matrix)
  }, [])

  useEffect(() => {
    const sortedEvents = EVENTS.reduce((acc, curr) => {
      const key = curr['start'].toUTCString()
      if (!acc[key]) {
        acc[key] = []
      }
      // Add object to list for given key's value
      acc[key].push(curr)
      return acc
    }, {})

    setEvents(sortedEvents)
  }, [EVENTS])

  if (!timetable.length) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  return (
    <ScrollView style={{ paddingTop: 20 }}>
      <View style={{ flexDirection: 'row', marginBottom: 5 }}>
        {[...Array(8)].map((_, idx) => (
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Label>
              {idx + 9} {idx > 2 ? 'PM' : 'AM'}
            </Label>
          </View>
        ))}
      </View>

      <View
        onLayout={({ nativeEvent: { layout } }) => {
          const { width } = layout
          setWidth(width)
        }}
        style={{ borderWidth: 1, borderColor: accents.separator }}
      >
        {timetable
          .map((days, dayIndex) => (
            <View
              key={dayIndex}
              style={{
                flexDirection: 'row',
                minHeight: 54,
                zIndex: -dayIndex,
              }}
            >
              {days
                .map((_, minuteIndex) => (
                  <View
                    key={`${dayIndex}-${minuteIndex}`}
                    style={{
                      paddingBottom: 6,
                      flex: 1,
                      zIndex: -minuteIndex,
                    }}
                  >
                    {minuteIndex === 0 ? (
                      <>
                        {Object.entries(events).map(([start, events]) => (
                          <Events
                            offsetDate={start}
                            events={events}
                            tableWidth={width}
                          />
                        ))}
                      </>
                    ) : null}
                  </View>
                ))
                .reduce((prev, curr, currentIndex) => [
                  prev,
                  <View
                    style={{
                      height: '100%',
                      width: 1,
                      backgroundColor: accents.separator,
                      zIndex: -currentIndex,
                    }}
                  />,
                  curr,
                ])}
            </View>
          ))
          .reduce((prev, curr, currentIndex) => [
            prev,
            <View
              style={{
                height: 1,
                width: '100%',
                backgroundColor: accents.separator,
                zIndex: -currentIndex,
              }}
            />,
            curr,
          ])}
        <CurrentTime percentageElapsed={percentageElapsed} />
      </View>
    </ScrollView>
  )
}

export default withLayout(TimetableScreen)

function createTimetableMatrix() {
  const matrix = []
  for (let i = 0; i < 7; i++) {
    matrix[i] = []
    for (let j = 0; j < 8; j++) {
      matrix[i][j] = []
    }
  }
  return matrix
}

function getTimeElapsed() {
  const start = new Date()
  start.setHours(9)
  start.setMinutes(0)
  start.setSeconds(0)

  const diffInMilliseconds = Math.abs(new Date() - start)
  const minutesPassed = Math.floor(diffInMilliseconds / 1000 / 60)

  return minutesPassed
}

interface EventsProps {
  offsetDate: any
  events: any
  tableWidth: number
}

const Events: React.FC<EventsProps> = ({ offsetDate, events, tableWidth }) => {
  const {
    typography: { Label, Caption },
  } = useContext(ThemeContext)

  const offsetDiffInMilliseconds = Math.abs(new Date(offsetDate) - START_DATE)
  const offsetDiffInMinutes = offsetDiffInMilliseconds / 1000 / 60
  const offsetPercentage = (offsetDiffInMinutes / 480) * 100

  return (
    <View
      style={{
        left: (parseFloat(offsetPercentage) / 100) * tableWidth,
        top: 0,
      }}
    >
      {events.map(event => {
        const { title, location, code, start, end } = event

        const widthDiffInMilliseconds = Math.abs(start - end)
        const widthDiffInMinutes = widthDiffInMilliseconds / 1000 / 60
        const widthPercentage = (widthDiffInMinutes / 480) * 100

        return (
          <Pressable
            style={{
              paddingVertical: 4,
              paddingHorizontal: 6,
              borderRadius: 6,
              backgroundColor:
                'linear-gradient(0deg, rgba(209, 62, 62, 0.2), rgba(209, 62, 62, 0.2)), #FFFFFF',
              borderLeftWidth: 3,
              borderLeftColor: '#D13E3E',
              width: (parseFloat(widthPercentage) / 100) * tableWidth,
              marginBottom: 12,
            }}
          >
            <Label primary numberOfLines={1}>
              {title}
            </Label>
            <AlignedRow justifyContent="space-between" style={{ marginTop: 2 }}>
              <Caption>
                {location} ({format(start, 'HH:mma')}-{format(end, 'HH:mma')})
              </Caption>
              <Label color="#D13E3E">{code}</Label>
            </AlignedRow>
          </Pressable>
        )
      })}
    </View>
  )
}

const CurrentTime = ({ percentageElapsed }) => {
  const {
    typography: { Label },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const now = new Date()

  return (
    <View
      style={{
        position: 'absolute',
        height: '100%',
        left: `${percentageElapsed}%`,
        width: 1,
        alignItems: 'center',
        backgroundColor: '#1D7A55',
      }}
    >
      <Label
        color="#1D7A55"
        style={{
          position: 'absolute',
          top: -22,
          backgroundColor: bg.primary,
          paddingHorizontal: 50,
        }}
      >
        {format(now, 'HH:mma')}
      </Label>
    </View>
  )
}
