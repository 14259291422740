import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useMemo,
} from 'react'
import { getClientInfo } from '../helpers/clientInfo'
import useFcm from '../hooks/useFcm'
import env from '../../env'

import {
  storeUserDetails,
  deleteUserDetails,
  getUserFromLocalStorage,
} from '../services/authService'
import Toolbar from '../components/landscape/Toolbar'

type ZubanubiUser = {
  Name: string
  userid: string
  wtenv: string
  ['App Badge']: string
  ['Family Name']: string
  ['Given Name']: string
  ['Home Badge']: string
  ['Messages Badge']: string
  ['Mine Badge']: string
  ['Services Badge']: string
} | null

type AuthProvider = 'google' | 'apple' | null

interface AuthState {
  authenticated: boolean
  user: ZubanubiUser
  loading: boolean
  provider: AuthProvider
  token: string | null
  error: null | string
  connected: boolean
}

type Action =
  | { type: 'ATTEMPT_LOGIN'; payload: { provider: string; token: string } }
  | { type: 'LOGIN'; payload: ZubanubiUser }
  | { type: 'POPULATE'; payload: ZubanubiUser }
  | { type: 'LOGOUT' }
  | { type: 'STOP_LOADING' }
  | { type: 'AUTHENTICATION_ERROR'; payload: { error: string } }
  | { type: 'CLEAR_ERROR' }
  | { type: 'CONNECTED'; payload: { connected: boolean } }

const StateContext = createContext<AuthState>({
  authenticated: false,
  user: null,
  loading: true,
  provider: null,
  token: null,
  error: null,
  connected: false,
})

const reducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case 'ATTEMPT_LOGIN':
      return {
        ...state,
        provider: action.payload.provider,
        token: action.payload.token,
      }
    case 'CONNECTED':
      return {
        ...state,
        connected: action.payload.connected,
        // authenticated: (action.payload.connected) ? true : state.authenticated,
        // loading: (action.payload.connected) ? false : state.loading
      }
    case 'LOGIN':
      const { user, provider, token } = action.payload

      return {
        ...state,
        authenticated: true,
        connected: true,
        loading: false,
        user,
        provider,
        token,
      }
    case 'LOGOUT':
      console.log('hmm')
      return {
        ...state,
        authenticated: false,
        connected: false,
        user: undefined,
        provider: null,
        token: null,
        loading: false,
      }
    case 'POPULATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false,
      }
    case 'AUTHENTICATION_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      }
    default:
      throw new Error('Unknown action type')
  }
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    authenticated: false,
    connected: false,
    loading: true,
    provider: null,
    token: null,
    error: null,
  })

  const {
    registerDeviceForUser,
    requestNotificationUserPermission,
    setPushNotificationListener,
  } = useFcm(state.user?.userid)

  const handlePushNotificationSetup = async () => {
    await requestNotificationUserPermission().then(() => {
      registerDeviceForUser()
      setPushNotificationListener()
    })
  }

  useEffect(() => {
    if (state.user?.userid) {
      handlePushNotificationSetup()
    }
  }, [state.user?.userid, env.use_push_notifications])

  const handleUserLogin = async (
    params: any,
    provider: string,
    token: string
  ) => {
    dispatch({
      type: 'ATTEMPT_LOGIN',
      payload: {
        provider,
        token,
      },
    })
  }

  useEffect(() => {
    async function loadUser() {
      try {
        const storedToken = await getUserFromLocalStorage()
        if (storedToken && storedToken.provider && storedToken.token) {
          const { provider, token } = storedToken

          // log the user in
          const { clientplatform, clientname, clientversion } =
            getClientInfo(true)

          handleUserLogin(
            {
              wtauth: true,
              clientplatform,
              clientname,
              clientversion,
              [`${provider}token`]: token,
              userlocale: 'en',
              triggered_from: 'socket',
              wtenv: 'main',
            },
            provider,
            token
          )
        } else {
          dispatch({ type: 'STOP_LOADING' })
        }
      } catch (e) {
        dispatch({ type: 'STOP_LOADING' })
      }
    }

    loadUser()
  }, [])

  const authMethods = useMemo(
    () => ({
      ssoSignIn: async (userInfo: {
        provider: string
        token: string
        usergivenname: string
        userfamilyname: string
      }) => {
        try {
          // log the user in
          const { clientplatform, clientname, clientversion } =
            getClientInfo(true)

          handleUserLogin(
            {
              wtauth: true,
              clientplatform,
              clientname,
              clientversion,
              [`${userInfo.provider}token`]: userInfo.token,
              userlocale: 'en',
              triggered_from: 'socket',
              wtenv: 'main',
            },
            userInfo.provider,
            userInfo.token
          )
        } catch (err) {
          console.warn(err)
          dispatch({ type: 'STOP_LOADING' })
        }
      },
      setIsConnected: async (connected: boolean) => {
        dispatch({ type: 'CONNECTED', payload: { connected } })
      },
      signIn: async (user: ZubanubiUser, provider: string, token: string) => {
        console.log('signing in user')
        if (provider && token) {
          await storeUserDetails({
            provider: provider,
            token: token,
          })

          await requestNotificationUserPermission().then(() => {
            registerDeviceForUser()
            setPushNotificationListener()
          })

          dispatch({
            type: 'LOGIN',
            payload: { user, provider, token },
          })
        }
      },
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      signOut: () => {
        deleteUserDetails()

        dispatch({ type: 'LOGOUT' })
      },
      throwError: (error: string) => {
        dispatch({
          type: 'AUTHENTICATION_ERROR',
          payload: { error },
        })
      },
      updateUser: (user: ZubanubiUser) => {
        dispatch({ type: 'POPULATE', payload: user })
      },
      dismissError: () => {
        dispatch({ type: 'CLEAR_ERROR' })
      },
    }),
    []
  )

  return (
    <StateContext.Provider value={{ ...state, ...authMethods }}>
      {children}
    </StateContext.Provider>
  )
}

export const useAuth = () => useContext(StateContext)
