import React, { useEffect, useContext, useState } from 'react'
import { useAuth } from './AuthContext'

const ActiveUserContext = React.createContext([{}, () => {}])

const ActiveUserProvider = props => {
  const [state, setState] = useState({
    active: [],
  })

  const {
    aro: { socket },
  } = useAuth()

  useEffect(() => {
    if (socket === null) {
      return
    }

    socket.on('active_users', async data => {
      const activeUserIds = JSON.parse(data).map(id => parseInt(id))

      setState(state => ({ ...state, active: activeUserIds }))
    })

    return () => {
      if (socket) {
        socket.off('active_users')
      }
    }
  }, [socket])

  return (
    <ActiveUserContext.Provider value={[state, setState]}>
      {props.children}
    </ActiveUserContext.Provider>
  )
}

export { ActiveUserContext, ActiveUserProvider }
