import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import WorktribeIcon from '../shared/WorktribeIcon'
import { SmallButton } from '../shared/Button'
import Ellipsis from '../shared/Ellipsis'
import { Portal, Host } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'
import HoverableOpacity from '../shared/HoverableOpacity'
import * as PropertyEdit from '../landscape/PropertyEdit'
import useSocketMethods from '../../hooks/useSocketMethods'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import { Tabs } from '../landscape/RecordHeader'
import BackButton from './BackButton'

interface WorkflowHeaderProps {
  refRecord: any
  record: any
  setShowSearch: (show: boolean) => void
  selectedTab: string
  setSelectedTab: (tab: string) => void
}

interface DialogProps {
  dialog?: any
  rclassname?: string
  recordid?: string
  name?: string
  validationmessage?: string
}

const WorkflowHeader: React.FC<WorkflowHeaderProps> = ({
  refRecord,
  record,
  setShowSearch,
  selectedTab,
  setSelectedTab,
}) => {
  const [dialogProps, setDialogProps] = useState<DialogProps | null>(null)
  const [showMenuItems, setShowMenuItems] = useState(false)

  const {
    typography: { H2, H4, Button, Body },
    themeColors: { hover },
    shadowStyles,
  } = useContext(ThemeContext)

  const { record_action } = useSocketMethods()

  const doAction = useCallback(
    ({
      dialog,
      name,
      validationmessage,
    }: {
      dialog: any
      name: string
      validationmessage: string
    }) => {
      const { rclassname, recordid }: { rclassname: string; recordid: string } =
        refRecord.header
      if (validationmessage) {
        setDialogProps({ validationmessage })
      } else if (dialog.length || dialog.items?.length) {
        setDialogProps({
          dialog,
          rclassname,
          recordid,
          name,
        })
      } else {
        record_action({
          rclassname,
          recordid,
          actionname: name,
        })
      }
    },
    [refRecord.header.rclassname, refRecord.header.recordid, record_action]
  )

  const hideMenuItems = useCallback(() => {
    setShowMenuItems(false)
  }, [])

  const outsideHandler = useCallback(hideMenuItems, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <>
      <AlignedRow
        style={{
          backgroundColor: '#E08938',
          height: 72,
          borderTopStartRadius: 30,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 40,
        }}
      >
        <AlignedRow style={{ alignItems: 'flex-start', flex: 1 }}>
          <AlignedRow gap={12}>
            <BackButton color="#FFF" />

            <H2 color="white">{refRecord.header.title}</H2>
          </AlignedRow>
        </AlignedRow>

        <View style={{ alignItems: 'center', flex: 3 }}>
          <H4 color="white">{refRecord.status}</H4>
        </View>

        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <AlignedRow>
            <SearchIcon onPress={() => setShowSearch(show => !show)}>
              <WorktribeIcon color="white" size={20} name="search-alternate" />
            </SearchIcon>

            {refRecord.buttons?.map(button => (
              <SmallButton
                key={button.name}
                transparent
                onPress={() => doAction(button)}
                style={{ marginLeft: 16 }}
              >
                <Button color="#FFF">{button.label}</Button>
              </SmallButton>
            ))}

            {refRecord.menuitems?.length ? (
              <View style={{ position: 'relative', flexDirection: 'column' }}>
                <SmallButton
                  onPress={() => setShowMenuItems(prev => !prev)}
                  transparent
                  style={{ marginLeft: 16, borderColor: 'white' }}
                >
                  <Ellipsis color="#1D7A55" />
                </SmallButton>

                {showMenuItems && (
                  <View
                    ref={wrapperRef}
                    style={[
                      {
                        position: 'absolute',
                        top: 45,
                        right: 0,
                        backgroundColor: bg.primary,
                        borderRadius: 16,
                        paddingVertical: 8,
                        maxHeight: 345,
                        width: 343,
                      },
                      shadowStyles,
                    ]}
                  >
                    <ScrollView style={{ flex: 1 }}>
                      {record?.menuitems.map(({ name, label }) => (
                        <HoverableOpacity
                          key={name}
                          onPress={() => doAction(name)}
                          style={{
                            justifyContent: 'center',
                            minHeight: 46,
                            paddingHorizontal: 16,
                            paddingVertical: 11,
                          }}
                          hoverStyle={{ backgroundColor: hover }}
                        >
                          <Body primary>{label}</Body>
                        </HoverableOpacity>
                      ))}
                    </ScrollView>
                  </View>
                )}
              </View>
            ) : null}
          </AlignedRow>
        </View>
      </AlignedRow>

      <View
        style={{
          paddingHorizontal: 40,
          marginTop: 16,
        }}
      >
        <Tabs
          record={record}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </View>

      <EditDialog dialogProps={dialogProps} setDialogProps={setDialogProps} />
    </>
  )
}

export default WorkflowHeader

interface EditDialogProps {
  dialogProps: null | any
  setDialogProps: Function
}

const EditDialog: React.FC<EditDialogProps> = ({
  dialogProps,
  setDialogProps,
}) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Portal>
      <WorktribeAlert
        useNativeDriver={true}
        contentContainerStyle={{
          backgroundColor: bg.card,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 20,
          shadowColor: 'rgba(0, 0, 0, 0.06)',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0,
          shadowRadius: 32,
        }}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        alertContainerStyle={{ padding: 0 }}
        show={!!dialogProps}
        customView={
          <EditDialogContent
            dialogProps={dialogProps}
            setDialogProps={setDialogProps}
          />
        }
        onDismiss={() => {
          setDialogProps(null)
        }}
      />
    </Portal>
  )
}

const EditDialogContent = ({
  dialogProps,
  setDialogProps,
}: {
  dialogProps: DialogProps
  setDialogProps: Function
}) => {
  if (!dialogProps) return null

  const { dialog, name, rclassname, recordid, validationmessage } = dialogProps

  if (validationmessage) {
    return (
      <View style={{ width: 500, maxHeight: 715 }}>
        <Subtitle primary>{validationmessage}</Subtitle>

        <AlignedRow justifyContent="flex-end">
          <SmallButton onPress={() => setDialogProps(null)} transparent>
            <Button primary>OK!</Button>
          </SmallButton>
        </AlignedRow>
      </View>
    )
  }

  const {
    typography: { Body, H3, Button, Subtitle },
  } = useContext(ThemeContext)

  const [fieldData, setFieldData] = useState({})
  const [formValid, setFormValid] = useState<boolean>(true)
  const [formMessage, setFormMessage] = useState<null | string>(null)
  const notifyBody = useRef()

  const { record_action } = useSocketMethods()

  const {
    items,
    settings: { dialogbuttonlabel },
  } = dialog

  useEffect(() => {
    // filter out notifybody
    const notify = Object.entries(fieldData).filter(
      ([name, _]) => name === 'notifybody'
    )[0]
    if (notify) {
      notifyBody.current = notify[1].value
    }

    // check valid
    setFormValid(() =>
      Object.entries(fieldData).every(([_, entry]) => entry.valid)
    )
  }, [fieldData])

  useEffect(() => {
    if (formValid) {
      setFormMessage(null)
    }
  }, [formValid])

  const save = async () => {
    if (formValid) {
      try {
        setDialogProps(null)
      } finally {
        setTimeout(() => {
          const changes = Object.entries(fieldData)
            .filter(([field, _]) => field !== 'notifybody')
            .map(([field, data]) => {
              if (Array.isArray(data.value)) {
                return {
                  property: field,
                  method: 'set',
                  recordids: data.value,
                }
              } else {
                return {
                  property: field,
                  method: 'set',
                  value: data.value,
                }
              }
            })

          record_action({
            recordid,
            rclassname,
            actionname: name,
            notifybody: notifyBody.current,
            changes,
          })
        }, 200)
      }
    } else {
      setFormMessage('Please complete required fields')
    }
  }

  return (
    <Host>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ width: 500, maxHeight: 715 }}
      >
        <AlignedRow style={{ marginBottom: 20 }}>
          <H3 primary style={{ flex: 1 }}>
            {name}
          </H3>

          <TouchableOpacity onPress={() => setDialogProps(null)}>
            <WorktribeIcon name="remove" />
          </TouchableOpacity>
        </AlignedRow>

        {items?.map(dialogitem => {
          switch (dialogitem.type) {
            case 'guide': {
              return (
                <View style={{ marginBottom: 16 }}>
                  <Body primary>{dialogitem.text}</Body>
                </View>
              )
            }
            case 'property': {
              if (!dialogitem.property) return null
              const {
                property: { name, type, rclasses, multiple },
                value,
                required,
              } = dialogitem

              switch (type) {
                case 'title':
                case 'text':
                case 'number': {
                  let valid = true
                  if (fieldData[name]) {
                    valid = fieldData[name]['valid']
                  }

                  return (
                    <View style={{ marginBottom: 16 }}>
                      <PropertyEdit.Input
                        required={required}
                        value={value.value}
                        field={name}
                        setData={setFieldData}
                        valid={valid}
                      />
                    </View>
                  )
                }
                case 'switch': {
                  return (
                    <View style={{ marginBottom: 16 }}>
                      <PropertyEdit.Radio
                        field={name}
                        value={value.value}
                        heading={name}
                        options={['Yes', 'No']}
                        setData={setFieldData}
                      />
                    </View>
                  )
                }
                case 'record': {
                  return (
                    <View style={{ marginBottom: 16 }}>
                      <PropertyEdit.InlineRecord
                        field={name}
                        value={value.value}
                        required={required}
                        rclasses={rclasses}
                        multiple={multiple}
                        setData={setFieldData}
                      />
                    </View>
                  )
                }
                default: {
                  return null
                }
              }
            }
            case 'notifybody': {
              return (
                <View style={{ marginBottom: 16 }}>
                  <PropertyEdit.Input
                    field={dialogitem.type}
                    value={dialogitem.value}
                    multiline
                    setData={setFieldData}
                    valid={true}
                  />
                </View>
              )
            }
            default: {
              return null
            }
          }
        })}

        <AlignedRow
          justifyContent="flex-end"
          style={{ marginTop: 10, marginBottom: 1 }}
        >
          <SmallButton onPress={() => setDialogProps(null)} transparent>
            <Button primary>Cancel</Button>
          </SmallButton>

          <SmallButton style={{ marginLeft: 16 }} onPress={save} primary>
            <Button color="#FFF">{dialogbuttonlabel}</Button>
          </SmallButton>
        </AlignedRow>

        {formMessage && <Body color="#E08938">{formMessage}</Body>}
      </ScrollView>
    </Host>
  )
}

const SearchIcon = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border-width: 1px;
  border-color: white;
`
