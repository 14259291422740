import { Easing } from 'react-native-reanimated'
import type { Positions, Position } from './kanban.d'

export const TILE_WIDTH = 280
export const TILE_HEIGHT = 130
export const TILE_MARGIN = 12
export const HEADING_OFFSET = 40

export const animationConfig = {
  easing: Easing.inOut(Easing.ease),
  duration: 150,
}

export function getPositions(data: any) {
  'worklet'

  const positions: Positions = {}

  Object.entries(data).forEach(([_, columnData], columnIndex) => {
    columnData.data.forEach((d, rowIndex) => {
      positions[d.recordid] = { columnIndex, rowIndex }
    })
  })

  return positions
}

export function getPosition(columnIndex: number, cardIndex: number) {
  'worklet'

  let x = columnIndex * TILE_WIDTH + columnIndex * (TILE_MARGIN * 2)
  let y = cardIndex * TILE_HEIGHT + cardIndex * TILE_MARGIN + HEADING_OFFSET

  return { x, y }
}

export function getColumnIndex(x: number, columnCount: any) {
  'worklet'

  const ranges = [...Array(columnCount)].map((_, index) => {
    const hitSlop = TILE_WIDTH / 2
    let start = index * TILE_WIDTH + index * (TILE_MARGIN * 2) + hitSlop
    let end = start + TILE_HEIGHT + TILE_MARGIN * 2 + hitSlop

    return { start, end }
  })

  return ranges.findIndex(
    ({ start, end }) => x >= start - TILE_WIDTH && x <= end - TILE_WIDTH
  )
}

export function getRowIndex(ty: number, max: number) {
  'worklet'

  const ranges = [...Array(max)].map((_, index) => {
    let start = index * TILE_HEIGHT + index * TILE_MARGIN - TILE_HEIGHT / 2
    let end = start + TILE_HEIGHT + TILE_MARGIN

    return { start, end }
  })

  return ranges.findIndex(({ start, end }) => ty >= start && ty <= end)
}

export function getPositionString(
  positions: Positions,
  position: Position
): string {
  let order = 'start'
  if (position.rowIndex === 0) {
    const targetId = findId(positions, position, 1)
    if (targetId) order = `before ${targetId}`
  } else {
    const targetId = findId(positions, position, -1)
    if (targetId) order = `after ${targetId}`
  }
  return order
}

function findId(positions: Positions, position: Position, num: number) {
  const target = Object.entries(positions).find(
    ([_, pos]) =>
      pos.columnIndex === position.columnIndex &&
      pos.rowIndex === position.rowIndex + num
  )

  return target ? target[0] : 0
}

let lastTap: number | null = null
const DOUBLE_PRESS_DELAY = 300
export const handleDoubleTap = callback => {
  const now = Date.now()
  if (lastTap && now - lastTap < DOUBLE_PRESS_DELAY) {
    callback()
  } else {
    lastTap = now
  }
}
