import React, { useState, useContext, useEffect } from 'react'
import { View, Pressable } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'

const VALUES = ['#78D8B1', '#2AAD79', '#FBBF24', '#E08938', '#DC2626']

const Score = ({ value, options, onSelect, editable }) => {
  const [scoreValue, setScoreValue] = useState(() => {
    if (Array.isArray(value)) {
      return value[0]
    }
    return value
  })

  useEffect(() => {
    const newValue = Array.isArray(value) ? value[0] : value

    setScoreValue(newValue)
  }, [value])

  const {
    typography: { Label },
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        maxWidth: 164,
        width: '100%',
        height: 24,
        backgroundColor: '#DDE1ED',
        borderRadius: 16,
        flexDirection: 'row',
      }}
    >
      {options
        ?.map((option, idx) => {
          const lastIndex = VALUES.length - 1

          let style
          if (idx === 0) {
            style = {
              borderBottomLeftRadius: 16,
              borderTopLeftRadius: 16,
            }
          } else if (idx === lastIndex) {
            style = {
              borderBottomRightRadius: 16,
              borderTopRightRadius: 16,
            }
          }

          return (
            <Pressable
              onPress={() => {
                if (editable) {
                  setScoreValue(option.name)
                  onSelect(option.name)
                }
              }}
              key={idx}
              style={[
                {
                  height: '100%',
                  flex: scoreValue == option.name ? 'auto' : 1,
                  backgroundColor: scoreValue == option.name ? VALUES[idx] : '',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                style,
                !editable &&
                  scoreValue == option.name && {
                    borderLeftWidth: idx === 0 ? 0 : 2,
                    borderRightWidth: idx === lastIndex ? 0 : 2,
                    borderColor: 'white',
                  },
              ]}
            >
              {scoreValue == option.name && (
                <Label color={idx === lastIndex ? 'white' : '#0E1012'}>
                  {option.name} - {option.description}
                </Label>
              )}
            </Pressable>
          )
        })
        .reduce((prev, curr) => [
          prev,
          <Separator editable={editable} />,
          curr,
        ])}
    </View>
  )
}

export default Score

const Separator = ({ editable }) =>
  editable ? (
    <View style={{ height: '100%', width: 2, backgroundColor: 'white' }} />
  ) : null
