import * as React from 'react'

export const navigationRef = React.createRef()

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params)
}

export function reset(state) {
  navigationRef.current?.reset(state)
}

export function goBack() {
  navigationRef.current?.goBack()
}
