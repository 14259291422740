import React from 'react'
import { View, StyleSheet } from 'react-native'
import Animated, {
  useAnimatedReaction,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
  withTiming,
  useAnimatedGestureHandler,
} from 'react-native-reanimated'
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler'
import type { Positions } from './section-list'
import { getPosition, getLoc, animationConfig, ROW_HEIGHT } from './utils'

interface SectionItemProps {
  item: any
  positions: Animated.SharedValue<any>
  onDragEnd: (positions: Positions) => void
  sectionIndex: number
  rowIndex: number
  offsets: Animated.SharedValue<any>
  renderItem: (item: any, index: number, recordids: string[]) => React.ReactNode
  editing: boolean
  recordids: string[]
}

const SectionItem: React.FC<SectionItemProps> = ({
  item,
  positions,
  onDragEnd,
  sectionIndex,
  rowIndex,
  offsets,
  renderItem,
  editing,
  recordids,
}) => {
  const isGestureActive = useSharedValue(false)

  // get position using offset
  const { y } = getPosition(rowIndex, offsets.value[sectionIndex].yoffset)

  const translateX = useSharedValue(0)
  const translateY = useSharedValue(y!)

  useAnimatedReaction(
    () => positions.value[item.id]!,
    ({ sectionIndex, rowIndex }) => {
      // work out position here
      // create function and ting
      const { y } = getPosition(rowIndex, offsets.value[sectionIndex].yoffset)
      translateY.value = withTiming(y, animationConfig)
    },
    [item]
  )

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { y: number; id: string; recordid: string }
  >({
    onStart: (_, ctx) => {
      if (editing) {
        ctx.y = translateY.value
        ctx.id = item.id
        ctx.recordid = item.recordid
        isGestureActive.value = true
      }
    },
    onActive: ({ translationY }, ctx) => {
      // dont allow drag if we're done editing
      if (editing) {
        translateY.value = ctx.y + translationY

        // o lord why
        // determine which section were in using offsets
        // and index within that section
        const { sectionIndex, rowIndex } = getLoc(
          translateY.value,
          offsets.value
        )

        if (sectionIndex > -1 && rowIndex > -1) {
          // get existing offsets
          const {
            sectionIndex: initialSectionIndex,
            rowIndex: initialRowIndex,
          } = positions.value[item.id]

          if (
            sectionIndex !== initialSectionIndex ||
            rowIndex !== initialRowIndex
          ) {
            // find existing row
            const existingRow = Object.entries(positions.value).find(
              ([_, position]) =>
                position.sectionIndex === sectionIndex &&
                position.rowIndex === rowIndex
            )

            const newPositions = { ...positions.value }

            if (existingRow) {
              const [idToSwap] = existingRow

              if (sectionIndex === initialSectionIndex) {
                // just swap
                newPositions[item.id] = {
                  sectionIndex,
                  rowIndex,
                }

                newPositions[idToSwap] = {
                  sectionIndex: initialSectionIndex,
                  rowIndex: initialRowIndex,
                }
              } else {
                Object.entries(newPositions)
                  .filter(
                    ([_, position]) =>
                      sectionIndex === position.sectionIndex &&
                      position.rowIndex >= newPositions[idToSwap].rowIndex
                  )
                  .forEach(([id, position]) => {
                    newPositions[id] = {
                      sectionIndex,
                      rowIndex: position.rowIndex + 1,
                    }
                  })
              }
            } else {
              newPositions[item.id] = {
                sectionIndex,
                rowIndex,
              }

              // handle initial column to make sure gaps are filled
              Object.entries(newPositions)
                .filter(
                  ([_, position]) =>
                    initialSectionIndex === position.sectionIndex &&
                    position.rowIndex > initialRowIndex
                )
                .forEach(([id, position]) => {
                  newPositions[id] = {
                    sectionIndex: initialSectionIndex,
                    rowIndex: position.rowIndex - 1,
                  }
                })
            }

            positions.value = newPositions
          }
        }
      }
    },
    onEnd: (_, ctx) => {
      const { sectionIndex, rowIndex } = positions.value[item.id]

      const { y } = getPosition(rowIndex, offsets.value[sectionIndex].yoffset)

      translateY.value = withTiming(y, animationConfig, () => {
        isGestureActive.value = false
        runOnJS(onDragEnd)({
          positions: positions.value,
          position: {
            id: ctx.id,
            recordid: ctx.recordid,
            ...positions.value[ctx.id],
          },
        })
      })
    },
  })
  const style = useAnimatedStyle(() => {
    const zIndex = isGestureActive.value ? 100 : -translateY.value
    return {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: ROW_HEIGHT,
      zIndex,
      transform: [
        { translateX: translateX.value },
        { translateY: translateY.value },
      ],
    }
  })
  return (
    <Animated.View style={style}>
      <PanGestureHandler onGestureEvent={onGestureEvent}>
        <Animated.View style={StyleSheet.absoluteFill}>
          <View
            key={item}
            style={{
              width: '100%',
              height: ROW_HEIGHT,
            }}
          >
            {renderItem(item, rowIndex, recordids)}
          </View>
        </Animated.View>
      </PanGestureHandler>
    </Animated.View>
  )
}

export default SectionItem
