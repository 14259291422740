import {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import { View, Pressable } from 'react-native'
import WorktribeIcon from '../../shared/WorktribeIcon'

import type { IItem, IItemProperty } from '../../../interfaces/record'
import { ThemeContext } from '../../../contexts/ThemeContext'
import InlineCalendar from '../../shared/InlineCalendar'

import { wt_date } from '../../../utils/wt_date'

import useModalOutsideAlerter from '../../../hooks/useModalOutsideAlerter'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter'
import { AlignedRow } from '../../shared/Layout'

interface InlineTextProps {
  item: IItem
  value: any
  onChange: Function
  onSave?: Function
  onReject?: Function
  modal?: boolean
  inputBackgroundColor?: string
}

const InlineDate: React.FC<InlineTextProps> = ({
  item,
  value,
  onChange,
  onSave,
  onReject,
  modal = false,
  inputBackgroundColor,
  formValid,
}) => {
  const { property, required }: { property: IItemProperty; required: string } =
    item

  const { name, time } = property

  const [selectedDate, setSelectedDate] = useState(() => {
    if (value.value) {
      return new Date(value.value)
    }
    return null
  })

  const previousDate = useRef(selectedDate)

  const [focused, setFocused] = useState<boolean>(false)

  useEffect(() => {
    onChange(selectedDate)
  }, [selectedDate])

  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const closeCalendar = useCallback(() => {
    setShowCalendar(false)
  }, [])

  const clearDate = useCallback(() => {
    setSelectedDate(null)
    previousDate.current = null
    closeCalendar()
  }, [])

  const saveChanges = useCallback(async () => {
    setSelectedDate(selectedDate)
    previousDate.current = selectedDate
    closeCalendar()
  }, [selectedDate])

  const rejectDateChanges = useCallback(() => {
    closeCalendar()
    setSelectedDate(previousDate.current)
  }, [])

  const valid = useMemo(() => {
    if (required === 'true' && selectedDate !== null) {
      return false
    }
    return true
  }, [selectedDate, required])

  const {
    typography: { Body, Label },
    themeColors: { bg, fonts },
    shadowStyles,
  } = useContext(ThemeContext)

  const wrapperRef = useRef(null)
  modal
    ? useModalOutsideAlerter(wrapperRef, rejectDateChanges)
    : useOutsideAlerter(wrapperRef, rejectDateChanges)

  return (
    <View ref={wrapperRef} style={{ position: 'relative' }}>
      <Pressable
        onPress={() => setShowCalendar(show => !show)}
        style={[
          {
            height: 60,
            paddingHorizontal: 16,
            backgroundColor: inputBackgroundColor ?? bg.card,
            justifyContent: 'center',
            borderRadius: 16,
          },
          !formValid &&
            !valid &&
            modal && {
              shadowOffset: {
                width: -2,
                height: 0,
              },
              shadowColor: '#F19549',
            },
        ]}
      >
        <View>
          {!!selectedDate && (
            <Label color={focused ? '#2AAD79' : ''}>{name}</Label>
          )}
          <View
            style={{
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
            }}
          >
            {selectedDate ? (
              <Body primary>{wt_date(selectedDate, time)}</Body>
            ) : (
              <AlignedRow style={{ flex: 1 }} justifyContent="space-between">
                <Body
                  color={!formValid && !valid ? '#B15500' : fonts.secondary}
                >
                  {valid ? name : `${name} *`}
                </Body>

                <WorktribeIcon name="calendar" />
              </AlignedRow>
            )}

            {!!selectedDate && (
              <Pressable onPress={clearDate}>
                <WorktribeIcon size={16} name="remove-circle" />
              </Pressable>
            )}
          </View>
        </View>
      </Pressable>

      {showCalendar && (
        <View
          style={[
            {
              backgroundColor: bg.primary,
              position: 'absolute',
              top: 65,
              borderRadius: 16,
              padding: 20,
              maxWidth: 364,
            },
            shadowStyles,
          ]}
        >
          <InlineCalendar
            value={selectedDate}
            valueHandler={setSelectedDate}
            saveChanges={() => {
              if (onSave) {
                onSave()
                closeCalendar()

                return
              }
              saveChanges()
            }}
            rejectChanges={() => {
              if (onReject) {
                onReject()
                setSelectedDate(null)
                closeCalendar()

                return
              }

              rejectDateChanges()
            }}
            time={time}
          />
        </View>
      )}
    </View>
  )
}

export default InlineDate
