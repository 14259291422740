import {
  UPDATE_RECORDS,
  APPEND_RECORDS,
  UPDATE_USERS_TYPING,
  CLEAR_USERS_TYPING,
  SET_SELECTED_RECORD,
  TOGGLE_PUSH,
  ADD_TO_HISTORY,
} from '../actions/records'

const data = {
  records: [],
  sectioned: [],
}

const initialState = {
  live: {
    home: data,
    mine: data,
    messages: data,
  },
  usersTyping: null,
  selectedRecord: null,
  recordHistory: [],
}

const recordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPEND_RECORDS: {
      let sorted
      if (action.payload.isRefresh) {
        sorted = reorder(action.payload.records)
      } else {
        const oldRecords = [...state.live[action.payload.screen].records]
        let newRecords = []

        action.payload.records.forEach(record => {
          const indexInOldRecords = oldRecords.findIndex(
            oldRecord => oldRecord.id === record.id
          )
          indexInOldRecords === -1
            ? newRecords.push(record)
            : (oldRecords[indexInOldRecords] = record)
        })

        sorted = reorder([...oldRecords, ...newRecords])
      }

      const live = { ...state.live }
      live[action.payload.screen] = sorted

      return { ...state, live }
    }
    case UPDATE_RECORDS: {
      const live = { ...state.live }
      Object.entries(state.live).forEach(([screen, data]) => {
        const { records } = data

        const existingIndex = records.findIndex(
          record => record.id === action.record.id
        )

        let updatedRecords
        if (existingIndex >= 0) {
          updatedRecords = [...live[screen].records]
          updatedRecords[existingIndex] = action.record
        } else {
          switch (action.record.rclass) {
            case 'conversation': {
              if (screen === 'messages') {
                updatedRecords = [...live[screen].records, action.record]
              }
              break
            }
            default: {
              if (['home', 'mine'].includes(screen)) {
                updatedRecords = [...live[screen].records, action.record]
              }
              break
            }
          }
        }

        if (updatedRecords) {
          live[screen] = reorder(updatedRecords)
        }
      })

      let selectedRecord = { ...state.selectedRecord }

      if (action.record.id === selectedRecord?.id) {
        selectedRecord = {
          ...selectedRecord,
          ...action.record,
        }
      }

      return { ...state, live, selectedRecord }
    }
    case UPDATE_USERS_TYPING: {
      const usersTyping = { ...state.usersTyping }
      Object.entries(state.live).forEach(([, data]) => {
        const { records } = data

        action.records.forEach(r => {
          const recordIndex = records.findIndex(record => record.id === r.id)

          if (recordIndex >= 0) {
            usersTyping[r.id] = r.users_typing.map(typing => typing.name)
          }
        })
      })
      return { ...state, usersTyping }
    }
    case CLEAR_USERS_TYPING: {
      return { ...state, usersTyping: null }
    }
    case TOGGLE_PUSH: {
      const live = { ...state.live }
      Object.entries(state.live).forEach(([screen, data]) => {
        const { records } = data
        const { record_id, push_notifications_enabled } = action.data

        const existingIndex = records.findIndex(
          record => record.id === record_id
        )

        if (existingIndex >= 0) {
          const updatedRecords = [...live[screen].records]

          updatedRecords[existingIndex] = {
            ...live[screen]['records'][existingIndex],
            push_notifications_enabled,
          }

          live[screen] = reorder(updatedRecords)
        } else {
          return state
        }
      })
      return { ...state, live }
    }
    case SET_SELECTED_RECORD: {
      return { ...state, selectedRecord: action.record }
    }
    case ADD_TO_HISTORY: {
      const existingHistory = [...state.recordHistory].filter(
        record => record.id !== action.item.id
      )

      return {
        ...state,
        recordHistory: [action.item, ...existingHistory].filter(
          (i, index) => index < 10
        ),
      }
    }
  }

  return state
}

export default recordsReducer

const reorder = records => {
  // Badged items first
  const badged = records.filter(record => record.unseen_by_me > 0)

  // Non-badged second
  const nonbadged = records.filter(record => record.unseen_by_me === 0)

  badged.sort(function (a, b) {
    return a.latest_comment?.id > b.latest_comment?.id ? -1 : 1
  })

  nonbadged.sort(function (a, b) {
    return a.latest_comment?.id > b.latest_comment?.id ? -1 : 1
  })

  return {
    records: [...badged, ...nonbadged],
    sectioned: [
      {
        title: 'New',
        data: [...badged],
      },
      {
        title: 'For later',
        data: [...nonbadged],
      },
    ],
  }
}
