import { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import useZubanubi from './useZubanubi'
import axios from 'axios'

interface Config {
  [key: string]: any
}

const useRecordAutoComplete = (
  rclass: string,
  search?: string,
  config: Config = {}
) => {
  const [data, setData] = useState<[]>([])

  const { user } = useAuth()
  const getZubanubiRepo = useZubanubi()

  useEffect(() => {
    let cancel: Function
    ;(async function () {
      const zubanubiRepo = await getZubanubiRepo()

      axios
        .get(`https://www.zubanubi.com/zapi.php`, {
          params: {
            method: `record_find`,
            wtauth: true,
            debugtoken: `${user?.userid}.quirkafleeg`,
            wtenv: zubanubiRepo,
            rclassname: rclass,
            quicksearch: search,
            includecards: 'true',
            deldig: 'true',
            ...Object.entries(config).reduce(
              (a, [k, v]) => (v?.length ? ((a[k] = v), a) : a),
              {}
            ),
          },
          cancelToken: new axios.CancelToken(c => (cancel = c)),
        })
        .then(({ data: { data } }) => {
          const { records, properties } = data

          const recordsWithProperties = Object.values(records).map(record => {
            return {
              ...record,
              properties: Object.values(record.properties).map(prop => {
                return {
                  ...prop,
                  ...properties[prop.name],
                }
              }),
              product: rclass,
            }
          })

          setData(recordsWithProperties)
        })
        .catch(e => {
          if (axios.isCancel(e)) return
        })
    })()

    return () => cancel()
  }, [rclass, search])

  return data
}

export default useRecordAutoComplete
