import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import env from '../../env'
import { APIContext } from './APIContext'
import { useAuth } from './AuthContext'
import { AppState, Platform, NativeModules } from 'react-native'

const BadgeContext = createContext([{}, () => {}])

/*
const PushNotificationIOS =
  env.use_push_notifications === true && Platform.OS === 'ios'
    ? require('@react-native-community/push-notification-ios').default
    : null
*/

const WebBadging =
  Platform.OS === 'web' &&
  ('setExperimentalAppBadge' in navigator ||
    'ExperimentalBadge' in window ||
    'setAppBadge' in navigator)
    ? true
    : false

// Set if running in Electron
const RunningInElectron =
  Platform.OS === 'web' &&
  'userAgent' in navigator &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

const ipcRenderer = RunningInElectron
  ? window.require('electron').ipcRenderer
  : null

const BadgeProvider = ({ children }) => {
  const [unseen, setUnseen] = useState({
    home: 0,
    mine: 0,
    conversations: 0,
  })
  const { getUnseen } = useContext(APIContext)
  const {
    aro: { authenticatedUser, userToken },
  } = useAuth()

  useEffect(() => {
    AppState.addEventListener('change', _reloadBadgesOnAppReload)
    return () =>
      AppState.removeEventListener('change', _reloadBadgesOnAppReload)
  }, [userToken])

  const _reloadBadgesOnAppReload = async nextAppState => {
    if (nextAppState === 'active' && userToken) {
      loadUnseen(userToken)
    }
  }

  const handleUnseenUpdate = unseenData => {
    const { home, mine, conversations, badge } = unseenData

    setUnseen({
      home,
      mine,
      conversations,
    })

    setAppIconBadge(badge ?? 0)
  }

  const loadUnseen = async (token = null) => {
    try {
      const response = await getUnseen(token)
      const unseenData = response.data.unseen
      handleUnseenUpdate(unseenData)
    } catch (e) {
      console.warn(e)
    }
  }

  // Initial set of badge number on establishing a new authenticatedUser
  useEffect(() => {
    if (authenticatedUser) {
      loadUnseen()
    }
  }, [authenticatedUser, getUnseen])

  const setAppIconBadge = useCallback(value => {
    /*if (PushNotificationIOS) {
      PushNotificationIOS.setApplicationIconBadgeNumber(value)
    */
    if (Platform.OS == 'ios') {
      NativeModules.Device.setBadgeCount(Number(value))
    } else if (WebBadging) {
      if (RunningInElectron) {
        // This count feeds directly into the 'direct count' notification, which in WT2 parlance is numbered badgeStyle
        // If we wanted to update the 'non-numbered' badge count, we'd send to 'new-general-notify' instead
        if (value > 0) {
          // update direct notifications count for MacOS
          ipcRenderer.send('new-direct-notify', value, true)
          // update direct notifications count for Windows
          // ipcRenderer.sendSync('update-badge', value)
        } else {
          // if we have neither a totalbadgecount or a generalCount, then clear notifications in the app
          // MacOS
          ipcRenderer.send('clear-notify')
          // Windows
          // ipcRenderer.sendSync('update-badge', null)
        }
      } else {
        if (value > 0) {
          if (navigator.setAppBadge) {
            navigator.setAppBadge(value)
          } else if (navigator.setExperimentalAppBadge) {
            navigator.setExperimentalAppBadge(value)
          } else if (window.ExperimentalBadge) {
            window.ExperimentalBadge.set(value)
          }
        } else {
          if (navigator.clearAppBadge) {
            navigator.clearAppBadge()
          } else if (navigator.clearExperimentalAppBadge) {
            navigator.clearExperimentalAppBadge()
          } else if (window.ExperimentalBadge) {
            window.ExperimentalBadge.clear()
          }
        }
      }
    }
  }, [])

  const badgeContext = React.useMemo(
    () => ({
      reduceMessagesBadgeNumber: () => {
        const unseenConversationsCount = unseen.conversations
        handleUnseenUpdate({
          ...unseen,
          conversations:
            unseenConversationsCount > 0 ? unseenConversationsCount - 1 : 0,
        })
      },
      reduceRecordsBadgeNumber: () => {
        const unseenRecordsCount = unseen.records
        handleUnseenUpdate({
          ...unseen,
          records: unseenRecordsCount > 0 ? unseenRecordsCount - 1 : 0,
        })
      },
      handleUnseenUpdate,
    }),
    [authenticatedUser, unseen]
  )

  return (
    <BadgeContext.Provider value={[unseen, badgeContext]}>
      {children}
    </BadgeContext.Provider>
  )
}

export { BadgeContext, BadgeProvider }
