import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { TouchableOpacity, View } from 'react-native'
import { SmallButton } from '../shared/Button'

import Proportion from '../shared/Proportion'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const SUBJECTS = [
  {
    subject: 'Examination description',
    type: '',
    hecosSubject: '100003: Ceramics',
    proportion: [
      {
        percentage: '25',
        color: '#3A2449',
      },
      {
        percentage: '60',
      },
      {
        percentage: '15',
      },
    ],
    department: '',
  },
  {
    subject: 'Examination description',
    type: 'JECS Subject',
    hecosSubject: '100003: Ceramics',
    proportion: [
      {
        percentage: '25',
      },
      {
        percentage: '60',
        color: '#5F44C4',
      },
      {
        percentage: '15',
      },
    ],
    department: '',
  },
  {
    subject: 'Examination description',
    type: 'JECS Subject',
    hecosSubject: '100003: Ceramics',
    proportion: [
      {
        percentage: '25',
      },
      {
        percentage: '60',
      },
      {
        percentage: '15',
        color: '#46C6D1',
      },
    ],
    department: 'Department of Chemistry',
  },
]

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
`

const SubjectRow = ({ subject }) => {
  const {
    typography: { Body, H5 },
  } = useContext(ThemeContext)
  return (
    <Row
      style={{
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        marginBottom: 12,
        alignItems: 'center',
      }}
    >
      <Col size={2}>
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 24,
            }}
            activeOpacity={1}
          >
            <Thumbnail
              source={{
                uri: `https://source.unsplash.com/PEDWYwNfUoI/50x50`,
                cache: 'force-cache',
              }}
            />
          </TouchableOpacity>

          <View style={{ flex: 1 }}>
            <H5 color="#0E1012">{subject.subject}</H5>
            {subject.type ? <Body color="#757885">{subject.type}</Body> : null}
          </View>
        </AlignedRow>
      </Col>

      <Col>
        <Body color="#757885">{subject.hecosSubject}</Body>
      </Col>

      <Col>
        <Proportion proportion={subject.proportion} />
      </Col>

      <Col>
        <Body color="#757885">{subject.department}</Body>
      </Col>
    </Row>
  )
}

const Subjects = ({ record }) => {
  const {
    typography: { H3, H5, Button },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        <AlignedRow justifyContent="space-between">
          <AlignedRow>
            <H3>Subjects</H3>
          </AlignedRow>

          <SmallButton>
            <Button color="#1D7A55">Add</Button>
          </SmallButton>
        </AlignedRow>
        <Grid
          style={{
            marginTop: 20,
            marginBottom: 36,
            flexDirection: 'column',
          }}
        >
          <Row
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
            }}
          >
            <Col size={2}>
              <H5 style={{ marginLeft: 72 }} color="#0E1012">
                Subject
              </H5>
            </Col>
            <Col>
              <H5 color="#0E1012">HECoS Subject</H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Proportion</H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Department</H5>
            </Col>
          </Row>
          {SUBJECTS.map((subject, i) => (
            <SubjectRow key={i} subject={subject} />
          ))}
        </Grid>
      </Content>
    </Container>
  )
}

export default Subjects

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
