import React, { useContext, useState } from 'react'
import { TouchableOpacity, ActivityIndicator, Image } from 'react-native'
import styled from 'styled-components/native'
import withLayout from '../../HOC/withLayout'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow, CenteredView } from '../../components/shared/Layout'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import Searchatron from '../../components/landscape/Searchatron'
import RecordHeader from '../../components/landscape/RecordHeader'
import Notification from '../../components/shared/Notification'
import WorkflowHeader from '../../components/landscape/WorkflowHeader'
import useRecordHandler from '../../hooks/useRecordHandler'
import RecordView from '../../components/landscape/RecordView'

interface RecordScreenProps {}

const RecordScreen: React.FC<RecordScreenProps> = ({ navigation, route }) => {
  const { rClass, recordId } = route.params

  const [showSearch, setShowSearch] = useState(false)

  const {
    record,
    referenceRecord,
    selectedTab,
    selectTab,
    notification,
    recordError,
  } = useRecordHandler(rClass, recordId)

  const {
    typography: { Subtitle },
  } = useContext(ThemeContext)

  if (!record && recordError) {
    return (
      <CenteredView>
        <Image
          style={{ height: 200, width: 200 }}
          source={require('../../../assets/icons/robot.png')}
        />
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 12,
              borderWidth: 1,
              borderColor: '#DDE1ED',
              borderRadius: 70,
              paddingVertical: 13,
              paddingHorizontal: 15,
            }}
            onPress={() => navigation.goBack()}
          >
            <WorktribeIcon name="keyboard-arrow-right" />
          </TouchableOpacity>
          <Subtitle color="#757885" style={{ marginVertical: 20 }}>
            {recordError}
          </Subtitle>
        </AlignedRow>
      </CenteredView>
    )
  }

  if (!record?.sections || !selectedTab) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  // Switch to a specific header style
  // COMMENTED OUT - mostly for debugging new header style
  // record.header['style'] = 'greyheader'

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      {referenceRecord ? (
        <WorkflowHeader
          setShowSearch={setShowSearch}
          refRecord={referenceRecord}
          record={record}
          selectedTab={selectedTab}
          setSelectedTab={selectTab}
        />
      ) : (
        <RecordHeader
          setShowSearch={setShowSearch}
          record={record}
          selectedTab={selectedTab}
          setSelectedTab={selectTab}
        />
      )}

      <Container style={{ alignItems: 'center' }}>
        {!!recordError && <Subtitle color="red">{recordError}</Subtitle>}

        <Content
          contentContainerStyle={[
            { flexGrow: 1 },
            ['cover', 'widegrey'].includes(record?.header.style) && {
              maxWidth: 1120,
              paddingHorizontal: 0,
            },
          ]}
        >
          <RecordView
            recordId={referenceRecord ? record.header.recordid : recordId}
            rClass={referenceRecord ? record.header.rclassname : rClass}
            section={record.sections.find(
              section => section.name === selectedTab
            )}
            selectedTab={selectedTab}
          />
        </Content>

        {!!notification?.message.length && (
          <Notification notification={notification} />
        )}
      </Container>
    </>
  )
}

export default withLayout(RecordScreen)

const Container = styled.View`
  flex: 1;
`

const Content = styled.ScrollView`
  flex: 1;
  width: 100%;
`
