import React, { useState, useCallback, useContext, useEffect } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../contexts/ThemeContext'
import { AlignedRow } from '../components/shared/Layout'
import { MediumButton } from '../components/shared/Button'
import { Portal } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'

interface IDialogAction {
  name: string
  primary: boolean
  action: Function
}

interface IDialogProps {
  message: string
  onMount: Function
  onUnmount: Function
  actions: IDialogAction[]
}

export const useDialog = () => {
  const [dialogProps, setDialogProps] = useState<IDialogProps | null>(null)

  const createDialog = useCallback((props: IDialogProps | null) => {
    setDialogProps(props)
  }, [])

  const closeDialog = useCallback(() => {
    setDialogProps(null)
  }, [])

  return { dialogProps, createDialog, closeDialog }
}

const DIALOG_WIDTH = 500
const DIALOG_MAX_HEIGHT = 715

export const Dialog: React.FC<{
  dialogProps: IDialogProps
  close: () => void
}> = ({ dialogProps, close }) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Portal>
      <WorktribeAlert
        useNativeDriver={true}
        contentContainerStyle={{
          backgroundColor: bg.card,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 20,
          shadowColor: 'rgba(0, 0, 0, 0.06)',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0,
          shadowRadius: 32,
        }}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        alertContainerStyle={{ padding: 0 }}
        show={!!dialogProps}
        customView={<DialogContent dialogProps={dialogProps} />}
        onDismiss={close}
      />
    </Portal>
  )
}

interface IDialogContentProps {
  dialogProps: IDialogProps
}

const DialogContent: React.FC<IDialogContentProps> = ({ dialogProps }) => {
  const {
    typography: { Button, Subtitle },
  } = useContext(ThemeContext)

  useEffect(() => {
    dialogProps?.onMount?.()
    return () => dialogProps?.onUnmount?.()
  }, [dialogProps])

  if (!dialogProps) return null

  return (
    <View style={{ width: DIALOG_WIDTH, maxHeight: DIALOG_MAX_HEIGHT }}>
      <Subtitle primary>{dialogProps.message}</Subtitle>

      <AlignedRow justifyContent="flex-end" style={{ marginTop: 20 }}>
        {dialogProps.actions?.map(({ name, action, primary }) => (
          <MediumButton
            primary={primary}
            transparent={!primary}
            onPress={action}
            style={{ marginLeft: 16 }}
          >
            <Button color={primary ? 'white' : '#2AAD79'}>{name}</Button>
          </MediumButton>
        ))}
      </AlignedRow>
    </View>
  )
}
