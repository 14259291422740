const INITIALS_LENGTH = 2
export const getInitials = (name: string) =>
  name
    .split(' ')
    .slice(0, INITIALS_LENGTH)
    .map(([first]: string) => first)

export type pt = 16 | 24 | 32 | 36 | 40 | 48 | 56

export const radius = new Map<pt, number>([
  [16, 8],
  [24, 12],
  [32, 16],
  [36, 12],
  [40, 12],
  [48, 16],
  [56, 18],
])
