import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  ScrollView,
  Pressable,
  FlatList,
} from 'react-native'
import { AlignedRow, CenteredView } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'
import { SmallButton } from '../shared/Button'
import HoverableOpacity from '../shared/HoverableOpacity'
import useCalendar from '../../hooks/useCalendar'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import uuid from 'uuid/v4'
import { useNavigation } from '@react-navigation/native'
import Selection from './Selection'
import GridFilters from './ContentPanel/ui/GridFilters'
import useSocketMethods from '../../hooks/useSocketMethods'

import { format } from 'date-fns'

const Container = styled.View`
  flex: 1;
`

const WeekRow = styled.View`
  flex-direction: row;
  height: 200px;
`

const DayHeading = styled.View`
  flex: 1;
  align-items: center;
  padding: 3px 6px;
`

const Day = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
  padding: 3px 6px;
`

const SelectedDay = styled.View`
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  background-color: #2aad79;
  border-radius: 11px;
`

const WeekDivider = styled.View`
  width: 100%;
  height: 1px;
`

const DayDivider = styled.View`
  height: 100%;
  width: 1px;
`

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 40px;
  margin-bottom: 20px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  padding: 14px;
  border-radius: 16px;
  min-width: 748px;
`

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.12)',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 50,
  elevation: 24,
}

const alerts = [
  {
    background: 'rgba(224, 137, 56, 0.2)',
    border: '#E08938',
  },
  {
    background: 'rgba(95, 68, 196, 0.2)',
    border: '#5F44C4',
  },
  {
    background: 'rgba(42, 173, 121, 0.2)',
    border: '#2AAD79',
  },
  {
    background: 'rgba(209, 62, 62, 0.2)',
    border: '#D13E3E',
  },
]

const CalendarHeader = ({
  name,
  selectView,
  setSelectView,
  setShowAddEvent,
  addAction,
  filters,
  data,
  showAddEvent,
  changeWeek,
  changeMonth,
  changeYear,
  activeDate,
  setToday,
  months,
  filtersActive,
  previousFilter,
  setFilterOptions,
}) => {
  const {
    typography: { PropertyName, Button, Body },
    themeColors: { bg, hover },
  } = useContext(ThemeContext)

  const [showViewSwitcher, setShowViewSwitcher] = useState(false)

  const outsideHandler = useCallback(() => setShowViewSwitcher(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  const showViewOptions = [
    {
      name: 'Month View',
      icon: 'calendar',
      color: '#757885',
      action: () => setSelectView('month'),
    },
    {
      name: 'Week View',
      icon: 'calendar',
      color: '#757885',
      action: () => setSelectView('week'),
    },
    {
      name: 'List View',
      icon: 'task-list-edit',
      color: '#757885',
      action: () => {
        setShowAddEvent(false)
        setSelectView('list')
      },
    },
  ]

  useEffect(() => {
    // Switch views
    // If you enable year, month, day and list, make sure to add all the options here!
    switch (selectView) {
      case 'month':
        setSelectedViewOption(showViewOptions[0])
        break
      case 'week':
        setSelectedViewOption(showViewOptions[1])
        break
      case 'list':
        setSelectedViewOption(showViewOptions[2])
        break
    }
    setShowViewSwitcher(false)
  }, [selectView])

  const [selectedViewOption, setSelectedViewOption] = useState(
    showViewOptions[0]
  )

  // Whether filters are currently visible
  const showFilters =
    data?.length > 0 ||
    Object.keys(filtersActive).length > 0 ||
    previousFilter.current

  const handleAddEvent = () => {
    if (selectView === 'list') {
      setShowAddEvent(!showAddEvent)
    } else {
      setSelectView('list')
      setShowAddEvent(true)
    }
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0,
          zIndex: 100,
          margin: 10,
        }}
      >
        <PropertyName primary>{name}</PropertyName>

        <AlignedRow>
          {selectView !== 'list' && (
            <>
              <CalendarTitle
                months={months}
                activeDate={activeDate}
                changeYear={changeYear}
                showDay={selectView == 'week'}
              />

              <AlignedRow style={{ marginLeft: 16 }}>
                <TouchableOpacity
                  onPress={() => {
                    selectView == 'month' ? changeMonth(-1) : changeWeek(-1)
                  }}
                  style={{ marginRight: 5 }}
                >
                  <WorktribeIcon color="#757885" name="arrow-up-left" />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    selectView == 'month' ? changeMonth(+1) : changeWeek(+1)
                  }}
                  style={{ marginLeft: 5 }}
                >
                  <WorktribeIcon color="#757885" name="arrow-up-right" />
                </TouchableOpacity>
              </AlignedRow>
            </>
          )}

          {selectView !== 'list' && (
            <SmallButton
              transparentHighlight
              secondary
              style={{ marginLeft: 16 }}
              onPress={setToday}
            >
              <Button color="#1D7A55">Today</Button>
            </SmallButton>
          )}

          <AlignedRow>
            <SmallButton
              transparentHighlight
              secondary
              style={{ marginLeft: 16, maxHeight: 38 }}
              onPress={() => {
                setShowViewSwitcher(!showViewSwitcher)
              }}
            >
              <WorktribeIcon
                size={16}
                color="#1D7A55"
                name={selectedViewOption.icon}
              />
              <Button style={{ marginLeft: 8 }} color="#1D7A55">
                {selectedViewOption.name}
              </Button>
              <WorktribeIcon name="chevron-down" />
            </SmallButton>
            {showViewSwitcher && (
              <View
                ref={wrapperRef}
                style={[
                  {
                    position: 'absolute',
                    top: 40,
                    left: 15,
                    backgroundColor: bg.primary,
                    borderRadius: 16,
                    paddingVertical: 8,
                    maxHeight: 345,
                    width: 160,
                    zIndex: 1,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView style={{ flex: 1 }}>
                  {showViewOptions.map(({ name, icon, color, action }) => (
                    <HoverableOpacity
                      key={name}
                      onPress={action}
                      style={{
                        justifyContent: 'center',
                        minHeight: 46,
                        paddingHorizontal: 16,
                        paddingVertical: 11,
                      }}
                      hoverStyle={{ backgroundColor: hover }}
                    >
                      <AlignedRow>
                        <WorktribeIcon size={16} name={icon} color={color} />
                        <Body style={{ marginLeft: 12 }} primary>
                          {name}
                        </Body>
                      </AlignedRow>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
            {!!addAction && (
              <>
                <SmallButton
                  transparentHighlight
                  style={{ marginLeft: 12 }}
                  secondary
                  onPress={() => handleAddEvent()}
                >
                  <Button color="#1D7A55">{addAction?.buttonlabel}</Button>
                </SmallButton>
              </>
            )}
          </AlignedRow>
        </AlignedRow>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0,
          zIndex: 99,
          margin: 10,
        }}
      >
        {!!name && !!filters && showFilters && (
          <FilterView filters={filters} setFilterOptions={setFilterOptions} />
        )}
      </View>
    </>
  )
}

const FilterView = ({ filters, setFilterOptions }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        zIndex: 999,
      }}
    >
      <GridFilters filterHandler={setFilterOptions} filters={filters} />
    </View>
  )
}

const CalendarTitle = ({ months, activeDate, changeYear, showDay }) => {
  const {
    typography: { H4, Overline },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [showYears, setShowYears] = useState(false)

  const [start, setStart] = useState(activeDate.getFullYear())
  const [end, setEnd] = useState(activeDate.getFullYear() + 24)

  const setRange = n => {
    setStart(start => start + n)
    setEnd(end => end + n)
  }

  const outsideHandler = useCallback(() => setShowYears(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  const dateStr = showDay
    ? `${activeDate.getDate()} ${
        months[activeDate.getMonth()]
      } ${activeDate.getFullYear()}`
    : `${months[activeDate.getMonth()]} ${activeDate.getFullYear()}`

  return (
    <AlignedRow>
      <TouchableOpacity onPress={() => setShowYears(show => !show)}>
        <AlignedRow>
          <H4 primary>{dateStr}</H4>

          <WorktribeIcon name="chevron-down" />
        </AlignedRow>
      </TouchableOpacity>

      {showYears && (
        <View
          ref={wrapperRef}
          style={[
            {
              position: 'absolute',
              zIndex: 10,
              backgroundColor: bg.card,
              right: 0,
              top: 25,
              borderRadius: 16,
              padding: 16,
            },
            shadowStyles,
          ]}
        >
          <AlignedRow style={{ marginBottom: 20 }}>
            <TouchableOpacity
              onPress={() => setRange(-24)}
              style={{ alignItems: 'flex-start', flex: 1 }}
            >
              <WorktribeIcon color="#757885" name="arrow-up-left" />
            </TouchableOpacity>
            <View style={{ alignItems: 'center', flex: 3 }}>
              <Overline primary>{`${start} - ${end}`}</Overline>
            </View>
            <TouchableOpacity
              onPress={() => setRange(24)}
              style={{ alignItems: 'flex-end', flex: 1 }}
            >
              <WorktribeIcon color="#757885" name="arrow-up-right" />
            </TouchableOpacity>
          </AlignedRow>

          <AlignedRow
            justifyContent="space-between"
            style={{ flexWrap: 'wrap' }}
          >
            {range(start, end).map((row, idx) => (
              <AlignedRow key={idx}>
                {row.map(year => {
                  const isCurrentYear = activeDate.getFullYear() === year
                  return (
                    <TouchableOpacity
                      key={year}
                      onPress={() => changeYear(year)}
                      style={[
                        {
                          borderRadius: 10,
                          width: 54,
                          height: 34,
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 3,
                        },
                        isCurrentYear && {
                          backgroundColor: '#2AAD79',
                        },
                      ]}
                    >
                      <Overline primary={!isCurrentYear} color="#FFF">
                        {year}
                      </Overline>
                    </TouchableOpacity>
                  )
                })}
              </AlignedRow>
            ))}
          </AlignedRow>
        </View>
      )}
    </AlignedRow>
  )
}

function range(start, end) {
  const perChunk = 5

  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
}

const CalendarView = ({
  columnproperty,
  rclasses,
  value,
  parentRecordId,
  parentRClass,
  editAction,
  addAction,
  filters,
  rclass,
  selectedTab,
  name,
  required,
}) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [calendarRecords, setCalendarRecords] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectView, setSelectView] = useState('month')

  const {
    matrix,
    activeDate,
    selectDate,
    weekDays,
    changeWeek,
    changeMonth,
    changeYear,
    setToday,
    months,
  } = useCalendar()

  const [showAddEvent, setShowAddEvent] = useState(false)

  const previousFilter = useRef()

  const [filterOptions, setFilterOptions] = useState({})

  const { record_get } = useSocketMethods()

  const filtersActive = Object.entries(filterOptions)
    .map(([property, value]) => {
      if (Array.isArray(value.value)) {
        return value.value.map(v => {
          return {
            property,
            method: 'matches',
            value: v.recordid ?? v.name,
            type: value.type,
          }
        })
      }

      return {
        property,
        method: 'matches',
        value: value.value,
        type: value.type,
      }
    })
    .filter(item => {
      if (Array.isArray(item)) {
        return item.every(i => i.value) && item.length
      }
      return item.value
    })
    .map(item => {
      if (Array.isArray(item) && item.length > 0) {
        return item
      } else if (item.value.recordid) {
        item.value = item.value.recordid
      } else if (item.type === 'date') {
        item.value = new Date(
          item.value.getTime() - item.value.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[0]
        return item
      }

      return item
    })

  useEffect(() => {
    async function filterRecords(filters) {
      const filtersWithoutType = filters
        .map(filter => {
          delete filter.type
          return filter
        })
        .flat()

      try {
        record_get({
          rclassname: parentRClass,
          recordid: parentRecordId,
          includecards: true,
          includeactions: true,
          returnsections: [selectedTab],
          filters: {
            [name]: filtersWithoutType,
          },
        })
      } catch (e) {
        console.warn(e)
      } finally {
        previousFilter.current = filtersWithoutType
      }
    }

    if (previousFilter.current || Object.keys(filtersActive).length > 0) {
      filterRecords(filtersActive)
    }
  }, [filterOptions, parentRClass, parentRecordId, name, selectedTab])

  useEffect(() => {
    async function getCalendarRecords() {
      try {
        setIsLoading(true)

        const { records } = value

        setCalendarRecords(
          records.map(record => {
            return record.properties.reduce((acc, curr) => {
              if (curr.name === columnproperty) {
                acc['date'] = new Date(curr.value).toLocaleDateString('en-GB')
                acc['hour'] = new Date(curr.value).getHours()
                acc[curr.name] = curr.value
              } else {
                acc[curr.name] = curr.value
              }
              acc['recordId'] = record.recordid
              acc['rClass'] = rclasses.toLowerCase().replaceAll(' ', '_')
              acc['styles'] = alerts[Math.floor(Math.random() * 4)]

              return acc
            }, {})
          })
        )
      } catch (e) {
        console.warn(e)
      } finally {
        setIsLoading(false)
      }
    }

    getCalendarRecords()
  }, [value, columnproperty, rclasses])

  if (isLoading) {
    return (
      <CenteredView>
        <ActivityIndicator size="large" />
      </CenteredView>
    )
  }

  return (
    <Container style={{ overflow: 'hidden' }}>
      <BubbleRow style={{ flex: 1 }}>
        <Bubble
          style={[
            { backgroundColor: bg.card },
            required && {
              shadowOffset: {
                width: -2,
                height: 0,
              },
              shadowColor: '#F19549',
            },
          ]}
        >
          {selectView === 'month' && (
            <>
              <CalendarHeader
                name={name}
                selectView={selectView}
                setSelectView={setSelectView}
                setShowAddEvent={setShowAddEvent}
                showAddEvent={showAddEvent}
                addAction={addAction}
                filters={filters}
                data={matrix}
                changeMonth={changeMonth}
                changeYear={changeYear}
                activeDate={activeDate}
                setToday={setToday}
                months={months}
                filtersActive={filtersActive}
                previousFilter={previousFilter}
                setFilterOptions={setFilterOptions}
              />
              {matrix.length && (
                <MonthView
                  weekDays={weekDays}
                  matrix={matrix}
                  activeDate={activeDate}
                  calendarRecords={calendarRecords}
                  selectDate={selectDate}
                />
              )}
            </>
          )}

          {selectView === 'week' && (
            <>
              <CalendarHeader
                name={name}
                selectView={selectView}
                setSelectView={setSelectView}
                setShowAddEvent={setShowAddEvent}
                showAddEvent={showAddEvent}
                addAction={addAction}
                filters={filters}
                data={matrix}
                changeWeek={changeWeek}
                changeMonth={changeMonth}
                changeYear={changeYear}
                activeDate={activeDate}
                setToday={setToday}
                months={months}
                filtersActive={filtersActive}
                previousFilter={previousFilter}
                setFilterOptions={setFilterOptions}
              />
              <Container
                style={{
                  height: 1000,
                  marginBottom: 10,
                }}
              >
                <WeekView
                  matrix={matrix}
                  activeDate={activeDate}
                  weekDays={weekDays}
                  calendarRecords={calendarRecords}
                />
              </Container>
            </>
          )}
          {selectView === 'list' && (
            <>
              <CalendarHeader
                name={name}
                selectView={selectView}
                setSelectView={setSelectView}
                setShowAddEvent={setShowAddEvent}
                showAddEvent={showAddEvent}
                addAction={addAction}
                filters={filters}
                data={matrix}
                filtersActive={filtersActive}
                previousFilter={previousFilter}
                setFilterOptions={setFilterOptions}
              />

              <Container
                style={{
                  flex: 1,
                  overflow: 'scroll',
                }}
              >
                <Selection
                  parentRecordId={parentRecordId}
                  parentrClass={parentRClass}
                  editAction={editAction}
                  addAction={addAction}
                  filters={filters}
                  rclass={rclass}
                  value={value}
                  selectedTab={selectedTab}
                  propertyName={name}
                  showHeadings={true}
                  required={required}
                  showBubble={false}
                  showFiltersIfAvailable={false}
                  showAddEvent={showAddEvent}
                />
              </Container>
            </>
          )}
        </Bubble>
      </BubbleRow>
    </Container>
  )
}

const DayEvents = ({ day, records }) => {
  const {
    typography: { Label },
  } = useContext(ThemeContext)
  const [truncate, setTruncate] = useState(true)

  const foundRecords = records.filter(record => {
    return record.date === day.toLocaleDateString('en-GB')
  })

  let hiddenRecords = []
  if (foundRecords.length > 2 && truncate) {
    hiddenRecords = foundRecords.splice(2)
  }

  return (
    <ScrollView style={{ width: '100%' }}>
      {foundRecords.map((record, idx) => {
        return <Event key={idx} record={record} />
      })}
      {hiddenRecords.length && truncate ? (
        <TouchableOpacity onPress={() => setTruncate(truncate => !truncate)}>
          <Label
            style={{ marginTop: 4 }}
            color="#0E1012"
          >{`+${hiddenRecords.length} more...`}</Label>
        </TouchableOpacity>
      ) : null}
    </ScrollView>
  )
}

const HourEvents = ({ hour, records }) => {
  const foundRecords = records[hour]

  let hiddenRecords = []
  if (foundRecords.length > 2 && truncate) {
    hiddenRecords = foundRecords.splice(2)
  }

  return (
    <ScrollView style={{ width: '100%' }}>
      {foundRecords.map((record, idx) => {
        return <Event key={idx} record={record} />
      })}
      {hiddenRecords.length && truncate ? (
        <TouchableOpacity onPress={() => setTruncate(truncate => !truncate)}>
          <Label
            style={{ marginTop: 4 }}
            color="#0E1012"
          >{`+${hiddenRecords.length} more...`}</Label>
        </TouchableOpacity>
      ) : null}
    </ScrollView>
  )
}

const HourEvent = ({ record, hour, dayHeight, hourHeight, key }) => {
  const navigation = useNavigation()
  const {
    typography: { Label, Caption },
  } = useContext(ThemeContext)
  const { rClass, recordId, Title, Status } = record
  const { background, border } = record.styles

  const minHeight = 48
  const fullHeight = dayHeight - 3 // There's 3 pixels of margin somewhere...
  const hourHeightMin = Math.max(hourHeight, minHeight)

  // Position and height calc
  let startdate = new Date(record['Start Date'])
  let enddate = new Date(record['End Date'])

  if (enddate.toString() == 'Invalid Date') {
    enddate = new Date(record['Start Date'])
    enddate.setHours(startdate.getHours() + 1)
  }

  const topPos =
    hourHeight * (hour - 8) + (hourHeight / 60) * startdate.getMinutes() - 3

  let eventHeight = Math.max(
    minHeight,
    (hourHeight / 60) * ((enddate - startdate) / 60000)
  )
  if (eventHeight > fullHeight) eventHeight = fullHeight - topPos

  // console.log((enddate - startdate) / 60000)

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.push('Record', {
          rClass,
          recordId,
        })
      }}
      style={{
        paddingVertical: 6,
        paddingHorizontal: 9,
        backgroundColor: background,
        borderRadius: 6,
        borderLeftWidth: 3,
        borderLeftColor: border,
        marginVertical: 2,
        position: 'relative',
        top: topPos,
        height: eventHeight,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <Label numberOfLines={2} primary>
            {Title}
          </Label>
          <Caption style={{ marginTop: 2 }} primary>
            {Status}
          </Caption>
        </View>
        <AlignedRow>
          <WorktribeIcon
            color="#757885"
            size={12}
            name="messages-bubble-alternate"
          />
          <Label style={{ marginLeft: 6 }}>1</Label>
        </AlignedRow>
      </View>
    </TouchableOpacity>
  )
}

const Event = ({ record }) => {
  const navigation = useNavigation()
  const {
    typography: { Label, Caption },
  } = useContext(ThemeContext)
  const { rClass, recordId, Title, Status } = record
  const { background, border } = record.styles

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.push('Record', {
          rClass,
          recordId,
        })
      }}
      style={{
        paddingVertical: 6,
        paddingHorizontal: 9,
        backgroundColor: background,
        borderRadius: 6,
        borderLeftWidth: 3,
        borderLeftColor: border,
        marginVertical: 2,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flex: 1 }}>
          <Label numberOfLines={2} primary>
            {Title}
          </Label>
          <Caption style={{ marginTop: 2 }} primary>
            {Status}
          </Caption>
        </View>
        <AlignedRow>
          <WorktribeIcon
            color="#757885"
            size={12}
            name="messages-bubble-alternate"
          />
          <Label style={{ marginLeft: 6 }}>1</Label>
        </AlignedRow>
      </View>
    </TouchableOpacity>
  )
}

export default CalendarView

const MonthView = ({
  weekDays,
  matrix,
  activeDate,
  calendarRecords,
  selectDate,
}) => {
  const {
    typography: { Label, H2, Button, Body },
    themeColors: { separator, bg, hover },
  } = useContext(ThemeContext)

  return (
    <>
      <AlignedRow style={{ marginHorizontal: 14 }}>
        {weekDays.map(weekDay => (
          <DayHeading key={weekDay}>
            <Label>{weekDay}</Label>
          </DayHeading>
        ))}
      </AlignedRow>
      <ScrollView
        style={{
          // flex: 1,
          marginBottom: 10,
          backgroundColor: bg.primary,
          borderColor: bg.primary,
          borderRadius: 16,
          borderTopWidth: 1,
          borderTopColor: separator.light,
          height: 600,
          overflow: 'hidden',
          marginHorizontal: 14,
        }}
      >
        {matrix
          .map((week, idx) => (
            <WeekRow key={idx}>
              {week
                .map(day => (
                  <Day key={day.getTime()} onPress={() => selectDate(day)}>
                    <View style={{ height: 27 }}>
                      {day.getFullYear() === activeDate.getFullYear() &&
                      day.getMonth() === activeDate.getMonth() &&
                      day.getDate() === activeDate.getDate() ? (
                        <SelectedDay>
                          <Label color="#FFF">{day.getDate()}</Label>
                        </SelectedDay>
                      ) : (
                        <Label
                          primary={day.getMonth() === activeDate.getMonth()}
                        >
                          {day.getDate()}
                        </Label>
                      )}
                    </View>

                    <DayEvents day={day} records={calendarRecords} />
                  </Day>
                ))
                .reduce((prev, curr) => [
                  prev,
                  <DayDivider
                    style={{ backgroundColor: separator.light }}
                    key={uuid()}
                  />,
                  curr,
                ])}
            </WeekRow>
          ))
          .reduce((prev, curr) => [
            prev,
            <WeekDivider
              style={{ backgroundColor: separator.light }}
              key={uuid()}
            />,
            curr,
          ])}
      </ScrollView>
    </>
  )
}

const WeekView = ({ weekDays, matrix, activeDate, calendarRecords }) => {
  const [weekMatrix, setWeekMatrix] = useState([])
  const [dayHeight, setDayHeight] = useState(0)

  useEffect(() => {
    const weekArray = getDatesForWeek(activeDate)

    const weeklyMatrixData = weekArray.reduce((acc, curr, index) => {
      curr.setHours(0)
      curr.setMinutes(0)
      curr.setSeconds(0)

      acc[index] = {
        label: format(curr, 'E d'),
        date: curr.toLocaleDateString(),
        hours: [],
        events: [],
      }

      // get calendarRecords for this date
      const calendarDayRecords = calendarRecords.filter(record => {
        return acc[index].date === record.date
      })

      for (let i = 8; i < 24; i++) {
        curr.setHours(i)

        // TODO: If there is data over multiple days, then relabel the records as 'Day 1/blah'
        // and have them appear over multiples

        const currDateHour = new Date(curr)
        acc[index].hours.push(currDateHour)

        // Find all the records that match this hour
        acc[index].events[i] = calendarDayRecords.filter(record => {
          return i === record.hour
        })
      }

      return acc
    }, [])
    setWeekMatrix(weeklyMatrixData)
  }, [activeDate, calendarRecords])

  const {
    typography: { Label },
    themeColors: { bg, accents, separator },
  } = useContext(ThemeContext)

  if (!weekMatrix.length) return null

  const RenderDayEvents = ({ hours, events, dayHeight }) => {
    const eventList = []

    // Calculate the hour height - there are 16 hours rendered a day, so divide the height by that
    const hourHeight = dayHeight / 16

    return (
      <>
        {events.map((hourEvents, hour) => (
          <View
            style={{
              position: 'absolute',
              zIndex: 1,
              width: '100%',
              top: 0,
            }}
          >
            <HourEvents
              hour={hour}
              records={events}
              hourHeight={hourHeight}
              dayHeight={dayHeight}
            />
          </View>
        ))}
      </>
    )
  }

  const HourEvents = ({ hour, records, hourHeight, dayHeight }) => {
    const foundRecords = records[hour]

    let hiddenRecords = []
    if (foundRecords.length > 2 && truncate) {
      hiddenRecords = foundRecords.splice(2)
    }

    return (
      <>
        {foundRecords.map((record, idx) => {
          return (
            <HourEvent
              key={idx}
              record={record}
              hour={hour}
              hourHeight={hourHeight}
              dayHeight={dayHeight}
            />
          )
        })}
        {hiddenRecords.length && truncate ? (
          <TouchableOpacity onPress={() => setTruncate(truncate => !truncate)}>
            <Label
              style={{ marginTop: 4 }}
              color="#0E1012"
            >{`+${hiddenRecords.length} more...`}</Label>
          </TouchableOpacity>
        ) : null}
      </>
    )
  }

  return (
    <Container>
      <ScrollView>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          {weekMatrix.map(({ label, hours, events }, dayIndex) => (
            <>
              {dayIndex == 0 && (
                <View>
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        paddingVertical: 4,
                        alignItems: 'center',
                      }}
                    >
                      <Label> </Label>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        backgroundColor: bg.secondary,
                        paddingRight: 5,
                      }}
                    >
                      {hours.map((hour, index) => (
                        <View
                          style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 40,
                          }}
                        >
                          <Label>
                            {hour.getHours().toString().padStart(2, '0')}:00
                          </Label>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              )}
              <View
                style={{
                  flex: 1,
                }}
              >
                <View
                  style={{
                    paddingVertical: 4,
                    alignItems: 'center',
                  }}
                >
                  <Label>{label}</Label>
                </View>
                <View
                  onLayout={({ nativeEvent: { layout } }) => {
                    const { height } = layout
                    setDayHeight(height)
                    console.log(height)
                  }}
                  style={{
                    flex: 1,
                    borderLeftWidth: 0.5,
                    borderTopWidth: 0.5,
                    borderBottomWidth: 0.5,
                    borderColor: accents.separator,
                    backgroundColor: bg.primary,
                    height: dayHeight,
                  }}
                >
                  <RenderDayEvents
                    hours={hours}
                    events={events}
                    dayHeight={dayHeight}
                  />
                  {hours
                    .map((hour, hourIndex) => (
                      // Blank view for nice hour blocks
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      ></View>
                    ))
                    .reduce((prev, curr) => [
                      prev,
                      <View
                        style={{
                          backgroundColor: accents.separator,
                          height: 0.5,
                        }}
                      />,
                      curr,
                    ])}
                </View>
              </View>
            </>
          ))}
        </View>
      </ScrollView>
    </Container>
  )
}

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days)
  return dat
}

function getDatesForWeek(activeDate) {
  const first = activeDate.getDate() - activeDate.getDay() + 1

  const startDate = new Date(activeDate.setDate(first))
  var currentDate = startDate

  const dates = []
  for (let i = 0; i < 7; i++) {
    dates.push(currentDate)
    currentDate = currentDate.addDays(1)
  }

  return dates
}

const WeekViewOld = ({ weekDays, matrix, activeDate, calendarRecords }) => {
  const [weekMatrix, setWeekMatrix] = useState([])

  useEffect(() => {
    const weekArray = getDatesForWeek(activeDate)

    const weeklyMatrixData = weekArray.reduce((acc, curr, index) => {
      curr.setHours(0)
      curr.setMinutes(0)
      curr.setSeconds(0)

      acc[index] = {
        label: format(curr, 'E d'),
        date: curr.toLocaleDateString(),
        hours: [],
        events: {},
      }

      // get calendarRecords for this date
      const calendarDayRecords = calendarRecords.filter(record => {
        return acc[index].date === record.date
      })

      for (let i = 8; i < 24; i++) {
        curr.setHours(i)

        const currDateHour = new Date(curr)
        acc[index].hours.push(currDateHour)

        // Find all the records that match this hour
        acc[index].events[i] = calendarDayRecords.filter(record => {
          return i === record.hour
        })
      }

      return acc
    }, [])
    setWeekMatrix(weeklyMatrixData)
  }, [activeDate, calendarRecords])

  const {
    typography: { Label },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  if (!weekMatrix.length) return null

  return (
    <View style={{ flexDirection: 'row', flex: 1 }}>
      {weekMatrix.map(({ label, hours, events }, dayIndex) => (
        <>
          {dayIndex == 0 && (
            <View>
              <View
                style={{
                  flex: 1,
                }}
              >
                <View
                  style={{
                    paddingVertical: 4,
                    alignItems: 'center',
                  }}
                >
                  <Label> </Label>
                </View>

                <View
                  style={{
                    flex: 1,
                    backgroundColor: bg.secondary,
                    paddingRight: 5,
                  }}
                >
                  {hours.map((hour, index) => (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Label>
                        {hour.getHours().toString().padStart(2, '0')}:00
                      </Label>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                paddingVertical: 4,
                alignItems: 'center',
              }}
            >
              <Label>{label}</Label>
            </View>

            <View
              style={{
                flex: 1,
                borderLeftWidth: 0.5,
                borderTopWidth: 0.5,
                borderBottomWidth: 0.5,
                borderColor: accents.separator,
                backgroundColor: bg.primary,
              }}
            >
              {hours
                .map((hour, hourIndex) => (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <HourEvents hour={hour.getHours()} records={events} />
                  </View>
                ))
                .reduce((prev, curr) => [
                  prev,
                  <View
                    style={{ backgroundColor: accents.separator, height: 0.5 }}
                  />,
                  curr,
                ])}
            </View>
          </View>
        </>
      ))}
    </View>
  )
}
