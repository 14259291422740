import React, { useContext, useEffect, useRef, useState } from 'react'
import { ActivityIndicator, StatusBar } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'

import RecordHeader from '../../components/shared/RecordHeader'

import Overview from '../../components/shared/Overview'
import Sections from '../../components/shared/Sections'
import Chat from '../../components/shared/Chat'
import { CenteredView } from '../../components/shared/Layout'
import { setSelectedRecord } from '../../store/actions/records'
import { APIContext } from '../../contexts/APIContext'
import { useDispatch } from 'react-redux'

const Container = styled.View`
  flex: 1;
  background-color: #ffffff;
`

const RecordScreen = ({ navigation, route }) => {
  const { selectedRecord } = useSelector(state => state.records)

  const { getRecord } = useContext(APIContext)

  const dispatch = useDispatch()

  useEffect(() => {
    const { recordId, rClass } = route.params

    async function fetchRecord() {
      try {
        const response = await getRecord(rClass, recordId)

        const { record } = response.data
        dispatch(setSelectedRecord(record))
      } catch (e) {
        console.warn(e)
      } finally {
        setSelectedTab('Chat')
      }
    }

    void fetchRecord()

    return () => dispatch(setSelectedRecord(null))
  }, [route.params])

  const [selectedTab, setSelectedTab] = useState('Chat')

  const openOptionsMenu = () => {
    modalizeRef.current?.open()
  }

  const modalizeRef = useRef(null)

  return (
    <Container>
      <StatusBar animated={true} barStyle="light-content" />
      {selectedRecord ? (
        <>
          <RecordHeader
            record={selectedRecord}
            navigation={navigation}
            selectedTab={selectedTab}
            handleTabChange={setSelectedTab}
            openOptionsMenu={openOptionsMenu}
          />

          {selectedTab === 'Overview' && <Overview />}
          {selectedTab === 'Sections' && (
            <Sections record={selectedRecord} navigation={navigation} />
          )}
          {selectedTab === 'Chat' && (
            <Chat
              record={selectedRecord}
              modalRef={modalizeRef}
              commentContainerStyle={{ paddingHorizontal: 12 }}
            />
          )}
        </>
      ) : (
        <CenteredView>
          <ActivityIndicator />
        </CenteredView>
      )}
    </Container>
  )
}

export default RecordScreen
