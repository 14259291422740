import React, { useContext } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'

const MessageReactions = props => {
  const {
    reactions_summary,
    authenticatedUser,
    sendReaction,
    marginLeft,
    isOwn,
  } = props

  const {
    typography: { Caption },
    themeColors: { text }
  } = useContext(ThemeContext)

  if (
    reactions_summary &&
    typeof reactions_summary === 'object' &&
    reactions_summary.length
  ) {
    return reactions_summary.map((reaction, index) => {
      if (reaction.reactors.length === 0) {
        return null
      }
      return (
        <View
          key={index}
          style={[styles.reactions, { marginLeft: marginLeft }]}
        >
          <TouchableOpacity
            onPress={
              reaction.reactors.find(
                reactor => reactor.id === authenticatedUser.id
              )
                ? // Sending a blank string as the emoji is how you delete your reaction
                  () => {
                    sendReaction('')
                  }
                : () => {
                    sendReaction(reaction.emoji)
                  }
            }
          >
            <Text style={styles.emoji}>{reaction.emoji}</Text>
          </TouchableOpacity>
          <Caption color={isOwn ? 'rgba(242,252,248, 0.6)' : text.secondary}>
            {reaction.reactors.map(reactor => reactor.name).join(', ')}
          </Caption>
        </View>
      )
    })
  }
  return null
}

export default MessageReactions

const styles = StyleSheet.create({
  reactions: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  emoji: {
    marginRight: 4,
  },
  reactors: {
    color: '#888',
  },
})
