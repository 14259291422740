import React, { useEffect, useRef } from 'react'
import { FlatList, StyleSheet, Text, Platform } from 'react-native'
import CommentRow from './CommentRow'

const Comments = React.memo(props => {
  const {
    record,
    comments,
    setComments,
    handleLoadMore,
    noMoreMessages,
    commentList,
    setScrollY,
    commentContainerStyle,
  } = props

  const _scrollNode = useRef(null)

  const _invertedWheelEvent = e => {
    _scrollNode.current.scrollTop -= e.deltaY
    e.preventDefault()
  }

  useEffect(() => {
    if (commentList.current && Platform.OS === 'web') {
      _scrollNode.current = commentList.current.getScrollableNode()
      _scrollNode.current.addEventListener('wheel', _invertedWheelEvent)

      commentList.current.setNativeProps({
        style: {
          transform: 'translate3d(0,0,0) scaleY(-1)',
        },
      })
    }

    return () => {
      if (_scrollNode.current) {
        _scrollNode.current.removeEventListener('wheel', _invertedWheelEvent)
      }
    }
  }, [_invertedWheelEvent])

  const renderItem = ({ item }) => (
    <CommentRow
      key={item.id}
      record={record}
      item={item}
      setComments={setComments}
    />
  )

  const handleScroll = ({ nativeEvent }) =>
    setScrollY(nativeEvent.contentOffset.y)

  const renderStartOfComments = () => {
    if (noMoreMessages) {
      return (
        <Text style={styles.startOfConversation}>Start of conversation</Text>
      )
    }

    return null
  }

  return (
    <FlatList
      contentContainerStyle={commentContainerStyle}
      removeClippedSubviews={true}
      ref={commentList}
      inverted
      data={comments}
      renderItem={renderItem}
      keyExtractor={(_, index) => index.toString()}
      onEndReachedThreshold={0.1}
      onEndReached={handleLoadMore}
      ListFooterComponent={renderStartOfComments}
      onScroll={handleScroll}
      scrollEventThrottle={16}
      style={styles.container}
      showsVerticalScrollIndicator={false}
    />
  )
})

export default Comments

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  startOfConversation: {
    textAlign: 'center',
    fontFamily: 'proxima-nova-light',
    color: '#DDD',
    padding: 10,
  },
})
