import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/native'
import { AntDesign } from '@expo/vector-icons'
import { SearchBar } from 'react-native-elements'
import {
  FlatList,
  View,
  ActivityIndicator,
  Image,
  Platform,
} from 'react-native'
import { APIContext } from '../../contexts/APIContext'
import Avatar from '../../components/shared/Avatar'
import { AuthContext } from '../../contexts/AuthContext'
import Constants from 'expo-constants/src/Constants'
import { useDispatch } from 'react-redux'
import useOrientation from '../../hooks/useOrientation'
import { updateRecords } from '../../store/actions/records'

// temporary job titles until data sorted
const JOB_TITLES = [
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
  'Rasputin Impersonator',
  'Car Behaviour Consultant',
  'Ex-Moonshiner',
  'Pork Rind Expert',
  'Bread Scientist',
  'Bear Biologist and Paperfolder',
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
]

const Container = styled.View`
  flex: 1;
  background-color: #ebecf2;
`

const Header = styled.View`
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: ${Constants.statusBarHeight}px;
`

const HeaderItem = styled.View`
  flex: ${props => props.flex ?? 1};
  align-items: ${props => props.align};
`

const HeaderTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: #0e1012;
`

const NextButton = styled.TouchableOpacity`
  padding: 4px 14px;
  border-radius: 30px;
  background-color: ${props => (props.disabled ? '#A0A4B8' : '#2AAD79')};
`

const NextButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const SubTitle = styled.Text`
  font-family: proxima-nova-semibold;
  color: #757885;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin: 10px 0;
  margin-left: 16px;
`

const UserCard = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${props => (props.selected ? '#F2FCF8' : 'transparent')};
  ${({ rounded }) => {
    if (rounded) {
      return `border-${rounded}-left-radius: 16px;border-${rounded}-right-radius: 16px;`
    }
    return ''
  }};
`

const UserDetails = styled.View`
  flex: 1;
  margin-left: 12px;
`

const UserName = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 24px;
  color: #0e1012;
`

const UserTitle = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const UserSelect = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: ${props => (props.selected ? 0 : '1px')} solid #a0a4b8;
  background-color: ${props => (props.selected ? '#2AAD79' : 'transparent')};
`

const SelectedUsers = styled.ScrollView`
  flex: 0;
  min-height: 110px;
  padding: 5px 16px 5px 5px;
`

const SelectedUser = styled.TouchableOpacity`
  width: 75px;
  margin-right: 10px;
  align-items: center;
`

const SelectedUserName = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #0e1012;
  text-align: center;
  margin-top: 4px;
`

const RemoveSelected = styled.View`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffffff;
  position: absolute;
  top: -2px;
  right: -5px;
`

const Loader = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const NewConversationScreen = ({ navigation, route }) => {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const [isCreating, setIsCreating] = useState(false)

  const { handleItemPress } = route.params ?? {}

  const filteredUsers = users?.filter(item => {
    return item.name.toLowerCase().match(search.toLowerCase())
  })

  const selectedUsers = users?.filter(user => user.selected)

  const dispatch = useDispatch()
  const { getUsers, createRecord } = useContext(APIContext)
  const isLandscape = useOrientation()

  const { user } = useAuth()

  useEffect(() => {
    async function getSuggestedUsers() {
      try {
        const response = await getUsers()
        const suggestedUsers = response.data
          .filter(user => user.id !== user.userid)
          .map(user => {
            return {
              ...user,
              selected: false,
            }
          })
        setUsers(suggestedUsers)
      } catch (e) {}
    }
    getSuggestedUsers()
  }, [getUsers])

  const toggleSelected = id => {
    const updatedUsers = [...users]
    const selectedUser = updatedUsers.find(user => user.id === id)
    selectedUser.selected = !selectedUser.selected
    setUsers(updatedUsers)
  }

  const next = () => {
    if (selectedUsers.length > 1) {
      navigation.navigate('GroupDetails', {
        members: selectedUsers,
        handleItemPress,
      })
    } else {
      void create()
    }
  }

  const create = async () => {
    try {
      setIsCreating(true)

      const response = await createRecord(
        'conversation',
        selectedUsers.map(user => user.id)
      )

      const { record } = response.data

      dispatch(
        updateRecords({
          ...record,
          unseen_by_me: 0,
          looked_at: new Date(),
        })
      )

      if (isLandscape && handleItemPress) {
        handleItemPress({ record: record.id })
        navigation.navigate('Home')
      } else {
        navigation.navigate('Messages')
        navigation.navigate('Message', { conversationId: record.id })
      }
    } catch (e) {
      setIsCreating(false)
    }
  }

  const renderItem = ({ item, index }) => {
    let rounded = ''
    if (index === 0) {
      rounded = 'top'
    } else if (index === users.length - 1) {
      rounded = 'bottom'
    }

    return (
      <UserCard
        rounded={rounded}
        selected={item.selected}
        onPress={() => toggleSelected(item.id)}
      >
        <Avatar
          userId={item.id}
          size={40}
          radius={14}
          onPress={() => toggleSelected(item.id)}
        />
        <UserDetails>
          <UserName>{item.name}</UserName>
          <UserTitle>{JOB_TITLES[item.id - 1]}</UserTitle>
        </UserDetails>
        {item.selected ? (
          <Image
            style={{ width: 20, height: 20 }}
            source={require('../../../assets/icons/check-circle.png')}
          />
        ) : (
          <UserSelect />
        )}
      </UserCard>
    )
  }

  return (
    <Container>
      <Header>
        <HeaderItem align="flex-start">
          <AntDesign
            onPress={() => navigation.pop()}
            name="close"
            size={24}
            color="#0E1012"
          />
        </HeaderItem>
        <HeaderItem flex={2} align="center">
          <HeaderTitle>New Conversation</HeaderTitle>
        </HeaderItem>
        <HeaderItem align="flex-end">
          <NextButton
            disabled={selectedUsers.length === 0 || isCreating}
            onPress={next}
          >
            <NextButtonText>
              {selectedUsers.length <= 1 ? 'Create' : 'Next'}
            </NextButtonText>
          </NextButton>
        </HeaderItem>
      </Header>
      <SearchBar
        platform="ios"
        placeholder="Search"
        onChangeText={setSearch}
        value={search}
        containerStyle={{
          backgroundColor: 'transparent',
          marginHorizontal: 10,
        }}
        inputContainerStyle={{
          borderRadius: 16,
          height: 40,
          backgroundColor: '#FFF',
        }}
        inputStyle={[
          {
            fontFamily: 'proxima-nova',
            backgroundColor: '#FFF',
            fontSize: 17,
            height: 40,
            color: '#757885',
          },
          Platform.OS === 'web' ? { outlineWidth: 0 } : {},
        ]}
        cancelButtonProps={{
          buttonTextStyle: {
            color: '#2AAD79',
            fontWeight: '600',
          },
          buttonStyle: {
            marginLeft: 5,
          },
        }}
        searchIcon={
          <AntDesign
            onPress={() => navigation.navigate('SearchResultsScreen')}
            name="search1"
            size={20}
            color="#757885"
          />
        }
        leftIconContainerStyle={{
          marginLeft: 15,
        }}
      />
      {selectedUsers.length ? (
        <SelectedUsers horizontal showsHorizontalScrollIndicator={false}>
          {selectedUsers.map(user => (
            <SelectedUser onPress={() => toggleSelected(user.id)} key={user.id}>
              <View style={{ position: 'relative' }}>
                <Avatar
                  onPress={() => toggleSelected(user.id)}
                  userId={user.id}
                  size={48}
                  radius={16}
                />
                <RemoveSelected>
                  <Image
                    style={{ width: 18, height: 18 }}
                    source={require('../../../assets/icons/remove-circle.png')}
                  />
                </RemoveSelected>
              </View>
              <SelectedUserName style={{ padding: 5 }}>
                {user.name}
              </SelectedUserName>
            </SelectedUser>
          ))}
        </SelectedUsers>
      ) : null}
      <Container>
        <SubTitle>Suggested</SubTitle>
        {users.length > 0 ? (
          <FlatList
            contentContainerStyle={{
              marginHorizontal: 16,
              backgroundColor: '#FFFFFF',
              borderRadius: 16,
            }}
            data={filteredUsers}
            renderItem={renderItem}
            keyExtractor={item => item.id.toString()}
            ItemSeparatorComponent={() => (
              <View style={{ backgroundColor: '#DDE1ED', height: 1 }} />
            )}
          />
        ) : (
          <Loader>
            <ActivityIndicator />
          </Loader>
        )}
      </Container>
    </Container>
  )
}

export default NewConversationScreen
