import React, { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import type { IProject } from './impact-journey'
import { SIDE_WIDTH } from './utils'

interface LaneBannerProps {
  project: IProject
}

const LaneBanner: React.FC<LaneBannerProps> = ({ project }) => {
  const {
    typography: { H4, Label },
  } = useContext(ThemeContext)
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        width: SIDE_WIDTH,
        bottom: 0,
        right: 0,
        top: 0,
        paddingVertical: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}
    >
      <View
        style={{
          width: 10,
          backgroundColor: project.color,
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
        }}
      />

      <View style={{ justifyContent: 'center', marginLeft: 20 }}>
        <H4 primary>{project.title}</H4>
        <Label>{project.description}</Label>
      </View>
    </View>
  )
}

export default LaneBanner
