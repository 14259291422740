import React, { useContext } from 'react'
import styled from 'styled-components/native'
import PercentageBar from '../shared/PercentageBar'
import { ThemeContext } from '../../contexts/ThemeContext'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 888px;
  width: 100%;
`

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -14.5px 32px -14.5px;
`

const Bubble = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f9;
  margin: 4px 14.5px;
  padding: 12px 0;
  border-radius: 16px;
  min-width: 111px;
`

const SectionContent = styled.View`
  background-color: #f4f5f9;
  padding: 24px;
  border-radius: 20px;
  margin: 16px 0 22px 0;
`

const Overview = ({ record }) => {
  const {
    typography: { Overline, Body, H3, H5 },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        <BubbleRow>
          <Bubble>
            <Overline color="#757885">Level</Overline>
            <H5 color="#0E1012">5</H5>
          </Bubble>

          <Bubble>
            <Overline color="#757885">Year</Overline>
            <H5 color="#0E1012">2019-20</H5>
          </Bubble>

          <Bubble>
            <Overline color="#757885">Credits</Overline>
            <H5 color="#0E1012">30</H5>
          </Bubble>

          <Bubble>
            <Overline color="#757885">Status</Overline>
            <H5 color="#0E1012">
              {capitalize(record.status.toLowerCase().replace('_', ' '))}
            </H5>
          </Bubble>

          <Bubble>
            <Overline color="#757885">Code</Overline>
            <H5 color="#0E1012">BS-CHEM20</H5>
          </Bubble>

          <Bubble>
            <Overline color="#757885">Org unit</Overline>
            <H5 color="#0E1012">Chemistry</H5>
          </Bubble>
        </BubbleRow>

        <H3 color="#293239">Introduction</H3>

        <SectionContent>
          <Body color="#293239">
            Graham has worked in a variety of mental health settings, including
            posts as a matron and service manager in acute inpatient care,
            community mental health, perinatal mental health and rehabilitation
            and recovery settings.
            {`\n\n`}
            Before joining the University of Hull as a lecturer in June 2016,
            Vickie worked as a serious incident investigator and learning
            lessons facilitator in Cumbria. Vickie became a Fellow of the Higher
            Education Academy in April 2019.
          </Body>
        </SectionContent>

        <H3 color="#293239">Assessment</H3>

        <SectionContent>
          <Body color="#293239">
            Trauma, narcissistic abuse, mental health nursing, mental health
            nursing education, serious incident processes.
          </Body>

          <PercentageBar
            style={{ marginTop: 32 }}
            items={[
              {
                percentage: '30%',
                color: '#35C88D',
              },
              {
                percentage: '70%',
                color: '#1A523C',
              },
            ]}
          />
        </SectionContent>

        <H3 color="#293239">Teaching and learning</H3>

        <SectionContent>
          <H5 color="#293239">BSc Mental Health Nursing programme:</H5>
          <Body color="#293239">
            • Module Leader for: 500807 - The Craft of Mental Health{`\n`}•
            Nursing Across the Lifespan (1){`\n`}• 400599 - Practice 3{`\n`}•
            Mental Health Lead for 400591 - Skills for Practice.
            {`\n\n`}
            Vickie also teaches on the following modules for this programme:
            {`\n`}• 500809 - The Craft of Mental Health Nursing Across the
            Lifespan (2)
            {`\n`}• 400598 - Values Based Mental Health Nursing{`\n`}• 601249 -
            Professional Role
            {`\n\n`}
            Vickie additionally teaches on the following programmes:{`\n`}•
            Paramedic Science (500607 - Clinical Practice Education 2)
            {`\n`}• BSc Midwifery (601505 - Becoming a Midwife){`\n`}• Associate
            Practitioner Programme (47090 - Effective Communication){`\n`}• BA
            Social Work (400850 - Becoming a research minded practitioner){`\n`}
          </Body>
        </SectionContent>
      </Content>
    </Container>
  )
}

export default Overview

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
