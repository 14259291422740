import React from 'react'
import { ImageBackground, Text, StyleSheet, View, Platform } from 'react-native'
import CachedImage from 'react-native-expo-cached-image'

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  '_1fVd1HodkA',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const CardHeader = ({ record }) => {
  const idString = record.id.toString()
  const imgString = imageFeed[idString[idString.length - 1]]

  const backgroundContent = () => (
    <View style={styles.cardBackgroundImage}>
      <Text style={styles.cardSubtitle}>{record.rclass.replace('_', ' ')}</Text>
      <Text style={styles.cardTitle}>{record.name}</Text>
    </View>
  )

  if (Platform.OS === 'web') {
    return (
      <ImageBackground
        style={{ flex: 1 }}
        imageStyle={styles.cardImage}
        source={{
          uri: `https://source.unsplash.com/${imgString}/`,
          cache: 'force-cache',
        }}
      >
        {backgroundContent()}
      </ImageBackground>
    )
  }

  return (
    <CachedImage
      isBackground={true}
      style={{ flex: 1 }}
      imageStyle={styles.cardImage}
      source={{ uri: `https://source.unsplash.com/${imgString}/200x200` }}
    >
      {backgroundContent()}
    </CachedImage>
  )
}

export default React.memo(CardHeader)

const styles = StyleSheet.create({
  cardImage: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  cardBackgroundImage: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  cardTitle: {
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 22,
    color: '#ffffff',
  },
  cardSubtitle: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 18,
    color: '#ffffff',
    textTransform: 'uppercase',
    opacity: 0.7,
  },
  detailsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
