import React, { useContext, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'
import { CenteredView } from '../../components/shared/Layout'
import Searchatron from '../../components/landscape/Searchatron'
import RecordHeader from '../../components/landscape/RecordHeader'

import { useAuth } from '../../contexts/AuthContext'
import useRecordHandler from '../../hooks/useRecordHandler'
import RecordView from '../../components/landscape/RecordView'

interface MineScreenProps {}

const MINE_CLASS = 'Person'
// const REQUIRED_SECTIONS = ['Portfolio', 'Tasks', 'Calendar']

const MineScreen: React.FC<MineScreenProps> = ({ route, navigation }) => {
  const [showSearch, setShowSearch] = useState(false)

  const { user } = useAuth()

  const { record, recordError, selectedTab, selectTab } = useRecordHandler(
    MINE_CLASS,
    user!.userid,
  )

  const {
    typography: { Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)

  if (recordError) {
    return <Subtitle style={{ margin: 40 }}>{recordError}</Subtitle>
  }

  if (!record) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      <Wrapper style={{ backgroundColor: bg.primary }}>
        <RecordHeader
          record={record}
          selectedTab={selectedTab}
          setSelectedTab={selectTab}
          setShowSearch={setShowSearch}
          allowBackNavigation={false}
        />

        <Container style={{ alignItems: 'center' }}>
          <Content
            style={
              ['cover', 'widegrey'].includes(record?.header.style) && {
                maxWidth: 1120,
                paddingHorizontal: 0,
              }
            }
          >
            <RecordView
              recordId={user.userid}
              rClass={MINE_CLASS}
              section={record?.sections.find(
                section => section.name === selectedTab
              )}
            />
          </Content>
        </Container>

      </Wrapper>
    </>
  )
}

export default withLayout(MineScreen)

const Wrapper = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`

const Container = styled.View`
  flex: 1;
`

const Content = styled.ScrollView`
  flex: 1;
  width: 100%;
  padding-bottom: 100px;
`
