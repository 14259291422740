import React, { useRef, useState } from 'react'
import styled from 'styled-components/native'
import {
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import * as Typography from '../../components/shared/Typography'
import Ellipsis from '../../components/shared/Ellipsis'
import Avatar from '../../components/shared/Avatar'
import AvatarGroup from '../../components/shared/AvatarGroup'
import { TinyButton } from '../../components/shared/Button'
import { AntDesign } from '@expo/vector-icons'
import Actions from '../../components/modals/Actions'
import WorktribeIcon from '../../components/shared/WorktribeIcon'

const Item = styled.TouchableOpacity`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px 12px;
  flex-direction: row;
  margin-bottom: 12px;
`

const ItemImage = styled.Image`
  height: 56px;
  width: 56px;
  border-radius: 16px;
  margin-right: 12px;
`

const HeaderRow = styled.View`
  flex-direction: row;
  margin-bottom: 12px;
`

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  margin-right: 12px;
`

const TitleRow = styled.View`
  flex-direction: row;
  width: 300px;
  align-items: center;
  justify-content: space-between;
`

const SummaryRow = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`

const Separator = styled.View`
  height: 1px;
  width: 98%
  background-color: #dde1ed;
  margin: 13px 0 16px 0;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const SearchRecordScreen = ({ navigation, route }) => {
  const { type, filterCount, records } = route.params
  const [selectedItem, setSelectedItem] = useState(null)

  const modalizeRef = useRef(null)

  const capitalize = s => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
  }

  const stripUnderscores = s => {
    if (typeof s !== 'string') return ''
    return s.replace('_', ' ')
  }

  const renderItem = ({ item }) => {
    const idString = item.id.toString()
    const imgString = imageFeed[idString[idString.length - 1]]

    switch (item.rclass) {
      case 'module': {
        return (
          <Item
            onPress={() =>
              navigation.navigate('Record', {
                recordId: item.id,
                rClass: item.rclass,
              })
            }
            onLongPress={() => setSelectedItem(item.id)}
          >
            <ItemImage
              source={{
                uri: `https://source.unsplash.com/${imgString}/`,
                cache: 'force-cache',
              }}
            />

            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <TitleRow style={{ paddingRight: 10 }}>
                <Typography.Subtitle color="#0E1012">
                  {item.name}
                </Typography.Subtitle>
                <Typography.Caption color="#757885">
                  '19 - '20
                </Typography.Caption>
              </TitleRow>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  {capitalize(stripUnderscores(item.status))}
                </Typography.Body>
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  30 credits
                </Typography.Body>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885">#UNITB30-4</Typography.Body>
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  Chemistry
                </Typography.Body>
              </View>
            </View>
          </Item>
        )
      }
      case 'programme': {
        return (
          <Item
            onPress={() =>
              navigation.navigate('Record', {
                recordId: item.id,
                rClass: item.rclass,
              })
            }
            onLongPress={() => setSelectedItem(item.id)}
          >
            <ItemImage
              source={{
                uri: `https://source.unsplash.com/${imgString}/`,
                cache: 'force-cache',
              }}
            />

            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <TitleRow>
                <Typography.Subtitle color="#0E1012">
                  {item.name}
                </Typography.Subtitle>
                <Typography.Caption
                  color="#757885"
                  style={{ paddingRight: 10 }}
                >
                  '19 - '20
                </Typography.Caption>
              </TitleRow>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885">
                  {capitalize(stripUnderscores(item.status))}
                </Typography.Body>
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  #UGCHEMFT
                </Typography.Body>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885">Chemistry</Typography.Body>
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  Ucas code: F100
                </Typography.Body>
              </View>
            </View>
          </Item>
        )
      }
      case 'project': {
        return (
          <Item
            onPress={() =>
              navigation.navigate('Record', {
                recordId: item.id,
                rClass: item.rclass,
              })
            }
            onLongPress={() => setSelectedItem(item.id)}
          >
            <ItemImage
              source={{
                uri: `https://source.unsplash.com/${imgString}/`,
                cache: 'force-cache',
              }}
            />

            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <TitleRow>
                <Typography.Subtitle color="#0E1012">
                  {item.name}
                </Typography.Subtitle>
              </TitleRow>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885">
                  {capitalize(stripUnderscores(item.status))}
                </Typography.Body>
                <Typography.Body color="#757885" style={{ paddingRight: 10 }}>
                  #438959
                </Typography.Body>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Body color="#757885">
                  Physics and Astronomy
                </Typography.Body>
              </View>
              <View style={{ marginTop: 5, flexDirection: 'row' }}>
                <Avatar userId={2} size={20} radius={100} />
                <View style={{ paddingLeft: 5 }}>
                  <Typography.Body color="#757885">
                    Bill & Melinda Gates foundation
                  </Typography.Body>
                </View>
              </View>
              <View style={{ marginTop: 5, flexDirection: 'row' }}>
                <Avatar userId={3} size={20} radius={100} />
                <View style={{ paddingLeft: 5 }}>
                  <Typography.Body color="#757885">
                    Phil Physics
                  </Typography.Body>
                </View>
              </View>
            </View>
          </Item>
        )
      }
      case 'output': {
        return (
          <Item
            onPress={() =>
              navigation.navigate('Record', {
                recordId: item.id,
                rClass: item.rclass,
              })
            }
            onLongPress={() => setSelectedItem(item.id)}
          >
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row' }}>
                <ItemImage
                  source={{
                    uri: `https://source.unsplash.com/${imgString}/`,
                    cache: 'force-cache',
                  }}
                />

                <View
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingRight: 10,
                    }}
                  >
                    <Typography.Overline color={'#757885'}>
                      Journal Article
                    </Typography.Overline>
                    <Typography.Body color="#757885">#438959</Typography.Body>
                  </View>
                  <TitleRow>
                    <Typography.Subtitle color="#0E1012">
                      {item.name}
                    </Typography.Subtitle>
                  </TitleRow>

                  <Typography.Body color="#757885">
                    {capitalize(stripUnderscores(item.status))}
                  </Typography.Body>

                  <View
                    style={{
                      marginTop: 5,
                      flexDirection: 'row',
                    }}
                  >
                    <AvatarGroup userId={2} size={28} radius={100} border />

                    <View style={{ paddingLeft: 32 }}>
                      <Typography.Body color="#757885">
                        18 authors
                      </Typography.Body>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                }}
              >
                <Separator />
                <SummaryRow>
                  <WorktribeIcon name="check-circle" />
                  <Typography.Body
                    style={{ flex: 1, marginLeft: 15 }}
                    color="#757885"
                  >
                    Published
                  </Typography.Body>
                </SummaryRow>
                <SummaryRow>
                  <WorktribeIcon name="check-circle" />
                  <Typography.Body
                    style={{ flex: 1, marginLeft: 15 }}
                    color="#757885"
                  >
                    Linked Authors: Ceri Chemistry
                  </Typography.Body>
                </SummaryRow>
                <SummaryRow>
                  <WorktribeIcon name="check-circle" />
                  <Typography.Body
                    style={{ flex: 1, marginLeft: 15 }}
                    color="#757885"
                  >
                    Linked Publisher: Royal Society of Chemis...
                  </Typography.Body>
                </SummaryRow>
              </View>
            </View>
          </Item>
        )
      }
      default: {
        return (
          <Item
            onPress={() =>
              navigation.navigate('Record', {
                recordId: item.id,
                rClass: item.rclass,
              })
            }
            onLongPress={() => setSelectedItem(item.id)}
          >
            <ItemImage
              source={{
                uri: `https://source.unsplash.com/${imgString}/`,
                cache: 'force-cache',
              }}
            />
            <View style={{ width: '80%' }}>
              <Typography.Subtitle color="#0E1012">
                {item.name}
              </Typography.Subtitle>
              <Typography.Body color="#757885">
                {capitalize(stripUnderscores(item.status))}
              </Typography.Body>
            </View>
          </Item>
        )
      }
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#EBECF2' }}>
      <View
        style={{
          height: 44,
          flexDirection: 'row',
          marginBottom: 12,
          paddingHorizontal: 16,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TouchableOpacity
          style={styles.arrowTouch}
          onPress={() => navigation.pop()}
        >
          <Image
            style={styles.arrow}
            source={require('../../../assets/icons/arrow-back.png')}
          />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TinyButton
            mr={20}
            primary
            style={{ backgroundColor: '#2AAD79', marginRight: 15 }}
          >
            <Typography.Button color="#FFFFFF">Add</Typography.Button>
          </TinyButton>
          <Ellipsis color="#0E1012" />
        </View>
      </View>
      <Text
        style={{
          fontFamily: 'proxima-nova-bold',
          fontSize: 26,
          lineHeight: 32,
          color: '#0E1012',
          paddingHorizontal: 16,
        }}
      >
        {`${capitalize(type.replace('_', ' '))}s`}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginVertical: 20,
          paddingHorizontal: 16,
        }}
      >
        <View
          style={{
            flex: 1,
            height: 48,
            backgroundColor: '#FFFFFF',
            borderRadius: 16,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <AntDesign
            style={{ marginLeft: 12 }}
            name="search1"
            size={20}
            color="#757885"
          />
          <Text
            style={{
              fontFamily: 'proxima-nova-semibold',
              fontSize: 17,
              color: '#0E1012',
              marginLeft: 10,
            }}
          >
            Search
          </Text>
        </View>
        <View style={{ width: 31 }}></View>
        <TouchableOpacity
          style={{
            flex: 1,
            height: 48,
            backgroundColor: '#FFFFFF',
            borderRadius: 16,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          onPress={() => navigation.navigate('FilterScreen')}
        >
          <Text
            style={{
              fontFamily: 'proxima-nova-semibold',
              fontSize: 17,
              color: '#0E1012',
              marginLeft: 22,
            }}
          >
            Filter
          </Text>
          {filterCount > 0 && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 27,
                height: 27,
                borderRadius: 14,
                backgroundColor: '#2AAD79',
                marginRight: 22,
              }}
            >
              <Text
                style={{
                  fontFamily: 'proxima-nova-semibold',
                  fontSize: 15,
                  color: '#FFFFFF',
                }}
              >
                {filterCount}
              </Text>
            </View>
          )}
        </TouchableOpacity>
      </View>

      <FlatList
        contentContainerStyle={{
          paddingHorizontal: 16,
        }}
        data={records}
        keyExtractor={(item, index) => item + index}
        renderItem={renderItem}
      />

      <Actions
        onClosed={() => setSelectedItem(null)}
        modalizeRef={modalizeRef}
      />
    </SafeAreaView>
  )
}

export default SearchRecordScreen

const styles = StyleSheet.create({
  arrowTouch: {
    paddingVertical: 10,
    marginLeft: -5,
    paddingLeft: 10,
  },
  arrow: {
    width: 18,
    height: 18,
    marginRight: 13,
  },
})

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`
