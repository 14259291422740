import AsyncStorage from '@react-native-async-storage/async-storage'

export const storeUserDetails = async value => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem('@token', jsonValue)
    return true
  } catch (e) {
    console.log('Error when saving the user to the local storage', e)
  }
}

export const getUserFromLocalStorage = async value => {
  try {
    const user = await AsyncStorage.getItem('@token')
    if (user) {
      return JSON.parse(user)
    }
    return null
  } catch (e) {
    console.log('Error when retrieving the user from the local storage', e)
  }
}

export const deleteUserDetails = async () => {
  try {
    await AsyncStorage.removeItem('@token')
    global.user = null
    return true
  } catch (e) {
    console.log('Error when deleting the user data from the local storage', e)
  }
}
