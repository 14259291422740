import { StyleSheet } from 'react-native'
import { ViewStyle } from 'react-native'
import {
  TILE_WIDTH,
  TILE_MARGIN,
  HEADING_OFFSET,
} from './utils'

interface KanbanViewStyle extends ViewStyle {
  gap?: number | undefined
}

interface KanbanStyle {
  column: KanbanViewStyle
  columnEmpty: KanbanViewStyle
  columnActions: KanbanViewStyle
  tileContainer: KanbanViewStyle
  tileContent: KanbanViewStyle
  tileImage: KanbanViewStyle
  tileBadge: KanbanViewStyle
  tilePriority: KanbanViewStyle
  margin: KanbanViewStyle
}

const styles = StyleSheet.create<KanbanStyle>({
  column: {
    position: 'absolute',
    top: 0,
    width: TILE_WIDTH,
    height: HEADING_OFFSET,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  columnEmpty: {
    position: 'absolute',
    top: HEADING_OFFSET,
    width: TILE_WIDTH,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#DDE1ED',
    borderRadius: 20,
    paddingTop: 23,
    alignItems: 'center',
  },
  columnActions: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingRight: TILE_MARGIN,
  },
  tileContainer: {
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#DDE1ED',
    padding: 16,
  },
  tileContent: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  tileImage: {
    height: 40,
    width: 40,
    borderRadius: 12,
    marginRight: 12,
  },
  tileBadge: {
    paddingHorizontal: 6,
    backgroundColor: '#2AAD79',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tilePriority: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 40,
    backgroundColor: 'rgba(209, 62, 62, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin: {
    position: 'absolute',
    top: HEADING_OFFSET,
    height: 1,
    width: TILE_MARGIN,
  },
})

export default styles
