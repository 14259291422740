import React, { useContext, useState } from 'react'
import { Pressable, View } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { MediumButton } from '../shared/Button'
import { AlignedRow } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'

import { useQuill } from 'react-quilljs'

const PLACEHOLDER_COMMENT =
  'Shouldn’t it be in “Active” state? or am I missing something? Let me know please'

const ACTION_ICONS = ['mention', 'smiley-smile', 'attachment', 'font-size']

const Annotation = () => {
  const {
    typography: { H3, Label, Button },
    themeColors: { bg },
    shadowStyles,
  } = useContext(ThemeContext)

  const [comment, setComment] = useState(PLACEHOLDER_COMMENT)

  const theme = 'bubble'
  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike']],
  }

  const { quill, quillRef } = useQuill({ theme, modules })

  return (
    <Wrapper style={[{ backgroundColor: bg.primary }, shadowStyles]}>
      <Triangle />

      <Header>
        <H3 primary>Comments</H3>
        <WorktribeIcon name="remove" />
      </Header>

      <CommentsInput
        style={{ backgroundColor: bg.card }}
        // value={comment}
        // onChangeText={setComment}
        // multiline
      >
        <View
          style={{
            fontFamily: 'proxima-nova',
            fontSize: 15,
            color: '#0e1012',
            marginHorizontal: -12
          }}
          ref={quillRef}
        />
      </CommentsInput>

      <ActionRow>
        <AlignedRow>
          {ACTION_ICONS.map(icon => (
            <Pressable style={{ marginRight: 12 }}>
              <WorktribeIcon name={icon} />
            </Pressable>
          ))}
        </AlignedRow>

        <AlignedRow>
          <Label>`pre-formatted`</Label>
          <Label style={{ marginLeft: 12 }}>#reference</Label>
        </AlignedRow>
      </ActionRow>

      <ButtonRow>
        <MediumButton primary style={{ marginRight: 16 }}>
          <Button color="#FFF">Comment</Button>
        </MediumButton>

        <MediumButton>
          <Button color="#1D7A55">Cancel</Button>
        </MediumButton>
      </ButtonRow>
    </Wrapper>
  )
}

export default Annotation

const Wrapper = styled.View`
  padding: 24px 20px;
  max-width: 508px;
  width: 100%;
  border-radius: 16px;
`

const Header = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const CommentsInput = styled.View`
  min-height: 58px;
  padding: 7px 16px;
  border-radius: 17px;
  border: 1px solid #dde1ed;
  font-family: proxima-nova;
  font-size: 15px;
  font-color: #0e1012;
  margin-bottom: 15px;
  z-index: 99;
`

const ActionRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ButtonRow = styled.View`
  flex-direction: row;
  align-items: center;
`

const Triangle = styled.View`
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-left-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 16px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  transform: rotate(-90deg);
`
