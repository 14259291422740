import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'

import AppLanding from '../../components/landscape/ContentPanel/AppLanding'

interface AppsScreenProps {}

const AppsScreen: React.FC<AppsScreenProps> = () => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Content style={{ backgroundColor: bg.primary }}>
      <AppLanding />
    </Content>
  )
}

export default withLayout(AppsScreen)

const Content = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`
