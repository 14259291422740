import React, { useContext } from 'react'
import { Pressable, View } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { format } from 'date-fns'
import type { IItem } from './impact-journey'

import { animationConfig } from './utils'
import Animated, {
  useAnimatedStyle,
  withTiming,
  useDerivedValue,
} from 'react-native-reanimated'

interface ImpactEventProps {
  event: IItem
}

const ImpactEvent: React.FC<ImpactEventProps> = ({ event }) => {
  const {
    typography: { Label, Subtitle },
  } = useContext(ThemeContext)

  const width = useDerivedValue(() => event.width, [event])
  const xoffset = useDerivedValue(() => event.xoffset, [event])
  const yoffset = useDerivedValue(() => event.yoffset, [event])

  const style = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      top: withTiming(yoffset.value!, animationConfig),
      height: 60,
      backgroundColor: event.color,
      borderRadius: 14,
      padding: 8,
      justifyContent: 'space-between',
      left: withTiming(xoffset.value!, animationConfig),
      width: withTiming(width.value!, animationConfig),
      flexDirection: 'row',
    }
  })

  return (
    <Pressable>
      <Animated.View key={event.id} style={style}>
        <View style={{ flex: 1 }}>
          <Subtitle numberOfLines={1} style={{ flex: 1 }} primary>
            {event.title}
          </Subtitle>
          <Label numberOfLines={1}>{event.assignee}</Label>
        </View>

        <View style={{ alignItems: 'flex-end' }}>
          <View style={{ flex: 1 }}>
            {event.tag && (
              <View
                style={{
                  paddingVertical: 2,
                  paddingHorizontal: 8,
                  borderRadius: 11,
                  backgroundColor: '#F5F2FF',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 5,
                    backgroundColor: event.tag.color,
                    marginRight: 7,
                  }}
                />
                <Label
                  style={{ alignSelf: 'flex-end' }}
                  color={event.tag.color}
                >
                  {event.tag.name}
                </Label>
              </View>
            )}
          </View>

          <Label>{format(event.start, 'd MMM, Y')}</Label>
        </View>
      </Animated.View>
    </Pressable>
  )
}

export default ImpactEvent
