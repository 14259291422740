import React from 'react'
import { ScrollView, StyleSheet, Text } from 'react-native'
import styled from 'styled-components/native'
import * as Typography from '../shared/Typography'
import { AlignedRow } from './Layout'
import WorktribeIcon from './WorktribeIcon'

const DATA = [
  {
    title: 'Details',
    icon: 'newspaper-fold',
    header: {
      title: 'Details',
      actions: [
        {
          type: 'edit',
          title: 'Edit',
          sectionTitle: 'details',
          fieldGroups: [
            {
              fields: [
                {
                  name: 'Module title',
                  type: 'text',
                  required: true,
                },
                {
                  name: 'Academic year',
                  type: 'selectone',
                  required: true,
                  options: [
                    {
                      title: '2020-21',
                      image: 'G02AR7pa_Lo',
                    },
                    {
                      title: '2019-20',
                      image: 'SLBjcE5IQxo',
                    },
                    {
                      title: '2018-19',
                      image: 'WXgiTeZRXGU',
                    },
                  ],
                },
                {
                  name: 'Module code',
                  type: 'text',
                  required: true,
                },
                {
                  name: 'Module type',
                  type: 'selectone',
                  required: true,
                  options: [
                    {
                      title: 'Masters dissertation',
                      image: 'G02AR7pa_Lo',
                    },
                    {
                      title: 'PhD',
                      image: 'SLBjcE5IQxo',
                    },
                  ],
                },
                {
                  name: 'Credits',
                  type: 'text',
                  required: false,
                },
                {
                  name: 'Worktribe',
                  type: 'selectone',
                  required: false,
                  options: [
                    {
                      title: 'University of Southampton',
                      image: 'G02AR7pa_Lo',
                    },
                    {
                      title: 'Imperial College London',
                      image: 'SLBjcE5IQxo',
                    },
                    {
                      title: 'London School of Economics',
                      image: 'WXgiTeZRXGU',
                    },
                    {
                      title: 'Brighton university',
                      image: 'DtkhxjzL_G4',
                    },
                    {
                      title: 'University of Edinburgh',
                      image: 'XL0Dzfkactw',
                    },
                    {
                      title: 'London City University',
                      image: 'S6k4vSWrIzA',
                    },
                    {
                      title: 'De Montfort University',
                      image: 'C1wAFPZFes8',
                    },
                    {
                      title: 'University of Leicester',
                      image: 'BwQ8ZA23W6g',
                    },
                  ],
                },
              ],
            },
            {
              textAbove: 'People',
              fields: [
                {
                  name: 'Module coordinator',
                  type: 'selectaro',
                  required: false,
                  options: [],
                },
                {
                  name: 'Other staff',
                  type: 'selectaro',
                  required: false,
                  options: [],
                },
                {
                  name: 'Assigned to',
                  type: 'selectaro',
                  required: false,
                  options: [],
                },
              ],
            },
            {
              fields: [
                {
                  name: 'Status',
                  type: 'selectone',
                  required: true,
                  options: [
                    {
                      title: 'Development',
                    },
                    {
                      title: 'In progress',
                    },
                    {
                      title: 'Withdrawn',
                    },
                    {
                      title: 'Discarded',
                    },
                  ],
                },
              ],
            },
            {
              textAbove: 'Empty fields',
              fields: [
                {
                  name: 'Add partner institutions',
                  type: 'text',
                  required: false,
                },
                {
                  name: 'Add Delivery locations',
                  type: 'text',
                  required: false,
                },
                {
                  name: 'Add Levels',
                  type: 'text',
                  required: false,
                },
                {
                  name: 'Add other levels',
                  type: 'text',
                  required: false,
                },
              ],
            },
          ],
        },
      ],
    },
    content: [
      {
        type: 'summary',
        rows: [
          [
            {
              field: 'Level',
              value: '5',
            },
            {
              field: 'Year',
              value: '2019-20',
            },
            {
              field: 'Credits',
              value: '30',
            },
          ],
          [
            {
              field: 'Status',
              value: 'In development',
            },
            {
              field: 'Code',
              value: 'BS-CHEM20',
            },
          ],
        ],
      },
      {
        type: 'details',
        fields: [
          {
            label: 'Module type',
            type: 'text',
            values: ['Masters Dissertation'],
          },
          {
            label: 'Worktribe',
            type: 'text',
            values: ['University of Leicester'],
          },
          {
            label: 'Coordinator',
            type: 'aro',
            values: ['Ceri Chemistry'],
          },
          {
            label: 'Other staff',
            type: 'aro',
            values: ['Jane Atkinson', 'John Goldman'],
          },
          {
            label: 'Assigned To',
            type: 'aro',
            values: ['Jane Atkinson', 'John Goldman'],
          },
        ],
      },
      {
        type: 'heading',
        title: 'Blurb',
      },
      {
        type: 'textarea',
        field: 'Overview',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
      {
        type: 'textarea',
        field: 'Features',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
      {
        type: 'textarea',
        field: 'Features',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
    ],
  },
  {
    title: 'Outcomes',
    icon: 'bookmarks-document',
    header: {
      title: 'Outcomes',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'heading',
        title: 'Real world skills',
      },
      {
        type: 'textarea',
        field: 'Overview',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
        footer: {
          code: '[R32]',
          buttons: [
            {
              type: 'secondary',
              title: 'Edit',
            },
            {
              type: 'secondary',
              title: 'Comment',
            },
          ],
        },
      },
      {
        type: 'textarea',
        field: 'Overview',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
        footer: {
          code: '[R32]',
          buttons: [
            {
              type: 'secondary',
              title: 'Edit',
            },
            {
              type: 'secondary',
              title: 'Comment',
            },
          ],
        },
      },
      {
        type: 'heading',
        title: 'Creative skills',
      },
      {
        type: 'textarea',
        field: '',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
        footer: {
          code: '[R32]',
          buttons: [
            {
              type: 'secondary',
              title: 'Edit',
            },
            {
              type: 'secondary',
              title: 'Comment',
            },
          ],
        },
      },
      {
        type: 'textarea',
        field: '',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
        footer: {
          code: '[R32]',
          buttons: [
            {
              type: 'secondary',
              title: 'Edit',
            },
            {
              type: 'secondary',
              title: 'Comment',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'Assessments',
    icon: 'check-badge',
    header: {
      title: 'Assessments',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'announcement',
        title: 'Must have a single final assessment for summative assessments',
      },
      {
        type: 'announcement',
        title:
          'Total summative assessment weighting % must be 100 (currently 20)',
      },
      {
        type: 'selection',
        format: 'groupby',
        records: [
          {
            title: 'Formative',
            data: [
              {
                record_class: 'assessment',
                type: 'Examination',
              },
            ],
          },
          {
            title: 'Summative',
            data: [
              {
                record_class: 'assessment',
                type: 'Dissertation',
              },
              {
                record_class: 'assessment',
                type: 'Dissertation',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Hours',
    icon: 'hourglass',
    header: {
      title: 'Hours',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'summary',
        rows: [
          [
            {
              field: 'Level',
              value: '5',
            },
            {
              field: 'Year',
              value: '2019-20',
            },
            {
              field: 'Credits',
              value: '30',
            },
          ],
          [
            {
              field: 'Status',
              value: 'In development',
            },
            {
              field: 'Code',
              value: 'BS-CHEM20',
            },
          ],
        ],
      },
      {
        type: 'details',
        fields: [
          {
            label: 'Module type',
            type: 'text',
            values: ['Masters Dissertation'],
          },
          {
            label: 'Worktribe',
            type: 'text',
            values: ['University of Leicester'],
          },
          {
            label: 'Coordinator',
            type: 'aro',
            values: ['Ceri Chemistry'],
          },
          {
            label: 'Other staff',
            type: 'aro',
            values: ['Jane Atkinson', 'John Goldman'],
          },
          {
            label: 'Assigned To',
            type: 'aro',
            values: ['Jane Atkinson', 'John Goldman'],
          },
        ],
      },
      {
        type: 'textarea',
        field: 'Overview',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
      {
        type: 'textarea',
        field: 'Features',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
      {
        type: 'textarea',
        field: 'Features',
        value:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore\n' +
          'in cillum occaecat in do ad. Et ullamco id do pariatur consequat\n' +
          'excepteur deserunt laboris velit laboris mollit.',
      },
    ],
  },
  {
    title: 'Subjects',
    icon: 'book-library-shelf',
    header: {
      title: 'Subjects',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'selection',
        format: '',
        records: [
          {
            record_class: 'subject',
            type: '',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: '',
          },
          {
            record_class: 'subject',
            type: 'JACS Subject',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: 'Department of Chemistry',
          },
          {
            record_class: 'subject',
            type: '',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: '',
          },
          {
            record_class: 'subject',
            type: 'JACS Subject',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: 'Department of Chemistry',
          },
          {
            record_class: 'subject',
            type: '',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: '',
          },
          {
            record_class: 'subject',
            type: 'JACS Subject',
            text1: '100035: Ceramics',
            text2: 'Proportion: 50%',
            text3: 'Department of Chemistry',
          },
        ],
      },
    ],
  },
  {
    title: 'Costs',
    icon: 'currency-pound-bill',
    header: {
      title: 'Costs',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'selection',
        format: '',
        records: [
          {
            record_class: 'cost',
            title: 'All the stationery needed',
            type: 'Stationary',
            cost: 'Exactly £250',
          },
          {
            record_class: 'cost',
            title: 'Main textbooks for course',
            type: 'Textbooks',
            cost: 'From £250 to £350',
          },
        ],
      },
    ],
  },
  {
    title: 'Deliveries',
    icon: 'cursor-target',
    header: {
      title: 'Costs',
      actions: [
        {
          title: 'Add',
          type: 'create',
          fieldGroups: [],
        },
      ],
    },
    content: [
      {
        type: 'selection',
        format: 'groupby',
        records: [
          {
            title: 'Main Campus - Semester 1',
            data: [
              {
                record_class: 'delivery',
                title: 'Bob Smith',
                location: 'Bristol',
                start: 'September start',
                type: 'Full Time',
              },
              {
                record_class: 'delivery',
                title: 'Philip Robertson',
                location: 'Bristol',
                start: 'September start',
                type: 'Full Time',
              },
              {
                record_class: 'delivery',
                title: 'Ralph Hawkins',
                location: 'Main Campus',
                start: 'January start',
                type: 'Full Time',
              },
              {
                record_class: 'delivery',
                title: 'Ronald Fox',
                location: 'Main Campus',
                start: 'January start',
                type: 'Full Time',
              },
            ],
          },
        ],
      },
    ],
  },
]

const SectionWrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 16px;
  margin-top: 16px;
`

const SectionIcon = styled.View`
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`

const BadgeContainer = styled.View`
  position: relative;
`

const Badge = styled.View`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  background-color: #e94545;
  opacity: 0.1;
  margin-right: 10px;
`

const Section = ({ record, section, navigation }) => (
  <SectionWrapper
    onPress={() => navigation.navigate('Section', { section, record })}
  >
    <SectionIcon>
      <WorktribeIcon name={section.icon} />
    </SectionIcon>
    <AlignedRow style={{ flex: 1 }} justifyContent="space-between">
      <Typography.Button color="#0E1012">{section.title}</Typography.Button>
      <AlignedRow>
        <BadgeContainer>
          <Badge />
          <Typography.Button
            color="#E94545"
            style={{
              position: 'absolute',
              top: 5,
              left: 13,
              zIndex: 100,
            }}
          >
            1
          </Typography.Button>
        </BadgeContainer>
        <WorktribeIcon name="arrow-right" />
      </AlignedRow>
    </AlignedRow>
  </SectionWrapper>
)

const Sections = ({ record, navigation }) => (
  <ScrollView
    contentContainerStyle={{ marginBottom: 20, paddingBottom: 20 }}
    style={styles.container}
  >
    <Text style={styles.headingText}>Sections</Text>

    {DATA.map((section, idx) => (
      <Section
        key={idx}
        record={record}
        section={section}
        navigation={navigation}
      />
    ))}
  </ScrollView>
)

export default Sections

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: '#EBECF1',
  },
  headingText: {
    fontWeight: '600',
    fontSize: 22,
    lineHeight: 27,
    color: '#0E1012',
    marginTop: 40,
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginTop: 16,
  },
  cardTitle: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: '#0E1012',
    marginLeft: 20,
  },
  image: {
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
