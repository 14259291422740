import React, { useContext } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import SingleSignOn from '../../components/shared/SingleSignOn'
import SingleSignOnWeb from '../../components/shared/SingleSignOnWeb'

import { useAuth } from '../../contexts/AuthContext'
import /* webpackChunkName: "worktribe-alert" */ WorktribeAlert from 'react-native-awesome-alerts'

import { Portal } from 'react-native-portalize'
import { ThemeContext } from '../../contexts/ThemeContext'

const Login = () => {
  const { ssoSignIn, error, dismissError } = useAuth()

  const ssoLogin = async userInfo => {
    ssoSignIn({
      provider: userInfo.provider,
      token: userInfo.idToken,
      useremail: userInfo?.email,
      usergivenname: userInfo?.givenName,
      userfamilyname: userInfo?.familyName,
    })

    /* Notice: when signing in with apple usernames and emails are only provided
      on first sign in. After that these fields return null.
      So we will need pass it on first sign in and not later on.
      To get the emails/names again while testing the apple sign in permission should 
      be removed from iOS: Settings -> Apple ID -> Passwords & Security -> Apps using Apple-ID
    */
  }

  const ssoError = error => console.warn(error)

  const {
    typography: { H3 },
  } = useContext(ThemeContext)

  return (
    <>
      <View style={styles.loginContainer}>
        <H3 color="#FFF">Sign in to continue</H3>

        {Platform.OS === 'web' ? (
          <SingleSignOnWeb onSignIn={ssoLogin} onError={ssoError} />
        ) : (
          <SingleSignOn onSignIn={ssoLogin} onError={ssoError} />
        )}
      </View>

      <Portal>
        <WorktribeAlert
          show={!!error?.length}
          title="We couldn't log you in."
          message={error}
          showConfirmButton={true}
          confirmText="Okay"
          onConfirmPressed={dismissError}
          confirmButtonStyle={styles.AlertButtonRed}
          confirmButtonTextStyle={styles.AlertButtonRed}
        />
      </Portal>
    </>
  )
}

export default Login

const styles = StyleSheet.create({
  loginContainer: {
    width: '100%',
    paddingHorizontal: 20,
    maxWidth: 360,
    alignItems: 'center'
  },
  loginButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
  },
  loginText: {
    color: '#ffffff',
    fontWeight: '700',
    fontSize: 25,
    lineHeight: 32,
    marginRight: 20,
  },
  loginButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2AAD79',
    borderRadius: 40,
    height: 64,
    width: 64,
  },
  input: {
    height: 56,
    backgroundColor: '#ffffff',
    marginVertical: 8,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 5,
    fontSize: 14,
    lineHeight: 18,
    color: '#0E1012',
  },
  loginToContinue: {
    fontSize: 14,
    lineHeight: 18,
    color: '#ffffff',
    marginBottom: 5,
  },
})
