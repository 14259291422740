import React from 'react'
import type { IProject, IPeriod } from './impact-journey'
import { MONTH_HEIGHT, animationConfig, CARD_MARGIN } from './utils'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import DateLine from './DateLine'
import ImpactEvent from './ImpactEvent'

interface ImpactEventsProps {
  data: IProject[]
  periods: IPeriod[]
  contentHeight: number
  monthWidth: Animated.SharedValue<number>
}

const ImpactEvents: React.FC<ImpactEventsProps> = ({
  data,
  periods,
  monthWidth,
}) => (
  <>
    {data.map(project => {
      const style = useAnimatedStyle(() => {
        return {
          top: MONTH_HEIGHT + project.offset!,
          width: withTiming(periods.length * monthWidth.value, animationConfig),
          position: 'absolute',
          height: project.height,
          left: CARD_MARGIN,
        }
      })

      return (
        <Animated.View key={project.id} style={style}>
          {project.items.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {group.map(event => (
                <ImpactEvent key={event.id} event={event} />
              ))}
            </React.Fragment>
          ))}
        </Animated.View>
      )
    })}

    <DateLine monthWidth={monthWidth} />
  </>
)

export default ImpactEvents
