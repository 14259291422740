import React, { useContext } from 'react'
import { StatusBar } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../contexts/ThemeContext'
import { documentRef } from '../services/Document'
import Menu from '../components/landscape/Menu'

export default (Component: React.ComponentType) => (props: any) => {
  const {
    themeColors: { menuBg, bg },
  } = useContext(ThemeContext)

  return (
    <Container style={{ backgroundColor: menuBg }} ref={documentRef}>
      <StatusBar barStyle="light-content" />
      <Menu />
      <Wrapper style={{ backgroundColor: bg.primary }}>
        <Component {...props} />
      </Wrapper>
    </Container>
  )
}

const Container = styled.SafeAreaView`
  flex: 1;
  flex-direction: row;
  min-width: 1024px;
`

const Wrapper = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`
