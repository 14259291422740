import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import { differenceInDays } from 'date-fns'

import { START_YEAR, MONTH_HEIGHT, animationConfig } from './utils'
import Animated, {
  useAnimatedStyle,
  withTiming,
  useDerivedValue,
} from 'react-native-reanimated'

const DateLine = ({
  monthWidth,
}: {
  monthWidth: Animated.SharedValue<number>
}) => {
  const start = new Date(`Jan 1 ${START_YEAR}`)
  const today = new Date()

  const diff = differenceInDays(start, today)

  const offset = useDerivedValue(() => {
    return ((12 * monthWidth.value) / 365) * Math.floor(Math.abs(diff))
  }, [monthWidth, diff])

  const { height } = useWindowDimensions()

  const style = useAnimatedStyle(() => {
    return {
      top: MONTH_HEIGHT,
      left: withTiming(offset.value, animationConfig),
      position: 'absolute',
      width: 1,
      height: height - MONTH_HEIGHT,
      backgroundColor: '#2AAD79',
      zIndex: 99,
      alignItems: 'center',
    }
  })

  return (
    <Animated.View style={style}>
      <View
        style={{
          width: 8,
          height: 8,
          borderRadius: 4,
          backgroundColor: '#2AAD79',
          top: -4,
        }}
      />
    </Animated.View>
  )
}

export default DateLine
