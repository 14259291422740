import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { TouchableOpacity, View } from 'react-native'
import { SmallButton } from '../shared/Button'
import Avatar from '../shared/Avatar'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const DELIVERIES = [
  {
    name: 'Bob Smith',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Darrell Steward',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Ralph Edwards',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Guy Hawkins',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Ronald Richards',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Darlene Robertson',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Kristin Watson',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Dianne Russell',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
  {
    name: 'Jacob Jones',
    location: 'Bristol',
    startTime: 'September',
    involvement: 'Full time',
    code: 'WKA 23',
  },
]

const DeliveryRow = ({ delivery }) => {
  const {
    typography: { Body, H4, Caption },
  } = useContext(ThemeContext)
  return (
    <Row
      style={{
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        marginBottom: 12,
        alignItems: 'center',
        paddingRight: 170,
      }}
    >
      <Col size={2}>
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 24,
            }}
            activeOpacity={1}
          >
            <Avatar userId={1} size={48} radius={16} />
          </TouchableOpacity>

          <View style={{ flex: 1 }}>
            <H4 color="#0E1012">{delivery.name}</H4>
            <Caption color="#757885">Department of Chemistry</Caption>
          </View>
        </AlignedRow>
      </Col>

      <Col>
        <Body color="#757885">{delivery.location}</Body>
      </Col>

      <Col>
        <Body color="#757885">{delivery.startTime}</Body>
      </Col>

      <Col>
        <Body color="#757885">{delivery.involvement}</Body>
      </Col>

      <Col>
        <Body style={{ textAlign: 'right' }} color="#757885">
          {delivery.code}
        </Body>
      </Col>
    </Row>
  )
}

const Deliveries = ({ record }) => {
  const {
    typography: { H3, H5, Button },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        <AlignedRow justifyContent="space-between">
          <AlignedRow>
            <H3>Main Campus - Semester 1</H3>
          </AlignedRow>

          <SmallButton>
            <Button color="#1D7A55">Add deliveries</Button>
          </SmallButton>
        </AlignedRow>
        <Grid
          style={{
            marginTop: 20,
            marginBottom: 36,
            flexDirection: 'column',
          }}
        >
          <Row
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
              paddingRight: 170,
            }}
          >
            <Col size={2}>
              <H5 style={{ marginLeft: 72 }} color="#0E1012">
                Name
              </H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Location</H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Start time</H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Involvement</H5>
            </Col>
            <Col>
              <H5 style={{ textAlign: 'right' }} color="#0E1012">
                Delivery Code
              </H5>
            </Col>
          </Row>
          {DELIVERIES.map((delivery, i) => (
            <DeliveryRow key={`${delivery.name}-${i}`} delivery={delivery} />
          ))}
        </Grid>
      </Content>
    </Container>
  )
}

export default Deliveries
