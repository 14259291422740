import { useEffect, useState } from 'react'
import getDaysInMonth from 'date-fns/getDaysInMonth'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

const useCalendar = () => {
  const [activeDate, setActiveDate] = useState(new Date())
  const [matrix, setMatrix] = useState([])

  useEffect(() => {
    const generateMatrix = () => {
      let matrix = []

      const year = activeDate.getFullYear()
      const month = activeDate.getMonth()

      const firstDay = new Date(year, month, 1).getDay()

      let maxDays = nDays[month]
      if (month === 1) {
        // February
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          maxDays += 1
        }
      }

      let counter = 1
      for (let row = 0; row < 6; row++) {
        matrix[row] = []
        for (let col = 0; col < 7; col++) {
          matrix[row][col] = -1
          if (row === 0 && col >= firstDay) {
            matrix[row][col] = new Date(year, month, counter++)
          } else if (row > 0 && counter <= maxDays) {
            matrix[row][col] = new Date(year, month, counter++)
          }
        }
      }

      const matrixWithAdjacentMonths = addAdjacentMonths(matrix, activeDate)
      setMatrix(matrixWithAdjacentMonths)
    }
    generateMatrix()
  }, [activeDate])

  const changeWeek = n => {
    setActiveDate(() => {
      const newDate = activeDate.getDate() + n * 7
      activeDate.setDate(newDate)
      return new Date(activeDate)
    })
  }

  const changeMonth = n => {
    setActiveDate(() => {
      activeDate.setMonth(activeDate.getMonth() + n)
      return new Date(activeDate)
    })
  }

  const changeYear = year => {
    setActiveDate(() => {
      activeDate.setFullYear(year)
      return new Date(activeDate)
    })
  }

  const selectDate = item => setActiveDate(item)

  const setToday = () => setActiveDate(new Date())

  return {
    matrix,
    activeDate,
    changeWeek,
    changeMonth,
    changeYear,
    selectDate,
    setToday,
    months,
    weekDays,
  }
}

export default useCalendar

const addAdjacentMonths = (matrix, date) => {
  const copiedDate = new Date(date)

  const previousDate = new Date(copiedDate.setMonth(copiedDate.getMonth() - 1))
  const previousMonthDays = [...Array(getDaysInMonth(previousDate))]
    .map((_, idx) => idx + 1)
    .reverse()

  const nextDate = new Date(copiedDate.setMonth(copiedDate.getMonth() + 2))
  const nextMonthDays = [...Array(getDaysInMonth(nextDate))].map(
    (_, idx) => idx + 1
  )

  for (let i = 0; i < matrix.length; i++) {
    if (i === 0) {
      for (let j = matrix[i].length - 1; j >= 0; j--) {
        if (matrix[i][j] < 0)
          matrix[i][j] = new Date(
            previousDate.getFullYear(),
            previousDate.getMonth(),
            previousMonthDays.shift()
          )
      }
    } else {
      for (let j = 0; j < matrix[i].length; j++) {
        if (matrix[i][j] < 0) {
          matrix[i][j] = new Date(
            nextDate.getFullYear(),
            nextDate.getMonth(),
            nextMonthDays.shift()
          )
        }
      }
    }
  }

  return matrix
}
