import { useEffect, useState } from 'react'
import type { IProject, IPeriod } from './impact-journey'
import { differenceInDays } from 'date-fns'
import { Easing } from 'react-native-reanimated'
import { format } from 'date-fns'
import uuid from 'uuid/v4'

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const START_YEAR = 2020
export const SIDE_WIDTH = 280
export const HEADER_HEIGHT = 56
export const MONTH_HEIGHT = 40
export const MONTH_WIDTH_MIN = 36
export const MONTH_WIDTH_MAX = 200
export const MONTH_START_WIDTH = (MONTH_WIDTH_MIN + MONTH_WIDTH_MAX) / 2
export const CARD_HEIGHT = 60
export const CARD_MARGIN = 8
export const ZOOM_INCREMENT = 40

const SCROLL_END_OFFSET = 200

export function getTimelineEventsFromProjects(
  projects: IProject[],
  monthWidth: number
) {
  const { results } = projects.reduce(
    (
      acc: {
        totalHeight: number
        results: IProject[]
      },
      project: IProject
    ) => {
      const cardsGroupedByTimeOverlaps = groupOverlappingIntervals(
        project.items.map(item => [item.start.getTime(), item.end.getTime()])
      )

      const cardsWithPositions = cardsGroupedByTimeOverlaps.map(card =>
        card.map(([start, end], index) => {
          // commence from our first available date
          const timelinebegin = new Date(`Jan 1 ${START_YEAR}`)

          const diff = differenceInDays(start, end)
          const width = ((12 * monthWidth) / 365) * Math.floor(Math.abs(diff))

          const offsetDiff = differenceInDays(timelinebegin, start)
          const offset =
            ((12 * monthWidth) / 365) * Math.floor(Math.abs(offsetDiff))

          const matchingEvent = project.items.find(
            project =>
              project.start.getTime() === start && project.end.getTime() === end
          )

          return {
            ...matchingEvent,
            xoffset: offset,
            yoffset: CARD_HEIGHT * index + CARD_MARGIN * index + CARD_MARGIN,
            width,
          }
        })
      )

      // get max number of grouped events
      const maxCardLength = cardsWithPositions.reduce((acc, curr) => {
        if (curr.length > acc) acc = curr.length
        return acc
      }, 0)

      const height =
        maxCardLength * CARD_HEIGHT + (maxCardLength + 1) * CARD_MARGIN
      const offset = acc.totalHeight

      acc.totalHeight += height

      acc.results.push({
        ...project,
        items: cardsWithPositions,
        height,
        offset,
      })

      return acc
    },
    {
      totalHeight: 0,
      results: [],
    }
  )

  return results
}

function groupOverlappingIntervals(intervals: [number, number][]) {
  intervals.sort(function (a, b) {
    return a[0] - b[0]
  })

  var groups = [[intervals[0]]]
  var j = 0
  var end = intervals[0][1]

  for (var i = 1; i < intervals.length; i++) {
    if (intervals[i][0] <= end) {
      if (intervals[i][1] > end) {
        end = intervals[i][1]
      }
      groups[j].push(intervals[i])
    } else {
      groups.push([intervals[i]])
      j++
      end = intervals[i][1]
    }
  }
  return groups
}

export const useImpactPeriods = (width: number) => {
  const [currentYear, setCurrentYear] = useState<number>(START_YEAR)
  const [scrollIndex, setScrollIndex] = useState<number>(0)
  const [initialScrollOffset, setInitialScrollOffset] = useState<number | null>(
    null
  )
  const [periods, setPeriods] = useState<IPeriod[]>([])

  useEffect(() => {
    const currentYear = format(new Date(), 'Y')
    const yearDiff = parseInt(currentYear) - START_YEAR

    let year = START_YEAR
    const periods = []
    for (let i = 0; i < yearDiff + 10; i++) {
      for (let j = 0; j < monthNames.length; j++) {
        periods.push({
          year,
          month: monthNames[j],
        })
      }

      year++
    }
    setPeriods(periods)
  }, [])

  const onScroll = ({
    nativeEvent: { contentOffset, contentSize, layoutMeasurement },
  }: any) => {
    if (
      layoutMeasurement.width + contentOffset.x >=
      contentSize.width - SCROLL_END_OFFSET
    ) {
      // get max year from periods
      const nextYear = periods.reduce((acc, curr) => {
        acc = curr.year + 1
        return acc
      }, START_YEAR)

      setPeriods(periods => [
        ...periods,
        ...[
          ...monthNames.map(month => ({
            year: nextYear,
            month,
          })),
        ],
      ])
    }

    setCurrentYear(START_YEAR + Math.floor(contentOffset.x / (width * 12)))
    setScrollIndex(Math.floor(contentOffset.x / width))
  }

  useEffect(() => {
    if (!initialScrollOffset) {
      const currentYear = format(new Date(), 'Y')
      const yearDiff = parseInt(currentYear) - START_YEAR
      const offset = yearDiff * (12 * width)

      setInitialScrollOffset(offset)
    }
  }, [periods, initialScrollOffset, width])

  return {
    periods,
    currentYear,
    onJourneyScroll: onScroll,
    scrollIndex,
    initialScrollOffset,
  }
}

export const animationConfig = {
  duration: 500,
  easing: Easing.inOut(Easing.ease),
}

export const projects: any[] = [
  {
    id: uuid(),
    title: 'UKRI Funding',
    description: 'Project one',
    color: '#6E56CF',
    items: [
      {
        id: uuid(),
        title: 'UKRI Funding start',
        assignee: 'R Salem',
        start: new Date('Jan 1 2022'),
        end: new Date('Dec 31 2022'),
        color: '#D7CFF9',
        tag: {
          name: 'Idea',
          color: '#5746AF',
        },
      },
      {
        id: uuid(),
        title: 'UKRI Funding start 2',
        assignee: 'R Salem',
        start: new Date('Jan 15 2022'),
        end: new Date('Jun 1 2022'),
        color: '#AADEE6',
      },
      {
        id: uuid(),
        title: 'UKRI Funding start 2',
        assignee: 'R Salem',
        start: new Date('May 15 2022'),
        end: new Date('Sep 1 2022'),
        color: '#F9E68C',
      },
    ],
  },
  {
    id: uuid(),
    title: 'MRC Funding',
    description: 'Project one',
    color: '#FFB224',
    items: [
      {
        id: uuid(),
        title: 'MRC Funding start',
        assignee: 'R Salem',
        start: new Date('Feb 1 2022'),
        end: new Date('May 31 2022'),
        color: '#D7CFF9',
        tag: {
          name: 'Idea',
          color: '#5746AF',
        },
      },
      {
        id: uuid(),
        title: 'Output 1',
        assignee: 'R Salem',
        start: new Date('Apr 1 2022'),
        end: new Date('May 31 2022'),
        color: '#AADEE6',
      },
      {
        id: uuid(),
        title: 'Consultancy (link to RO1)',
        assignee: 'R Salem',
        start: new Date('Jun 1 2022'),
        end: new Date('Sep 31 2022'),
        color: '#F9E68C',
      },
    ],
  },
  {
    id: uuid(),
    title: 'Pharma',
    description: 'Project one',
    color: '#2AAD79',
    items: [
      {
        id: uuid(),
        title: 'MRC Funding start',
        assignee: 'R Salem',
        start: new Date('Jan 1 2022'),
        end: new Date('May 31 2022'),
        color: '#AEE2CD',
        tag: {
          name: 'Idea',
          color: '#2AAD79',
        },
      },
      {
        id: uuid(),
        title: 'Output 1',
        assignee: 'R Salem',
        start: new Date('Apr 1 2022'),
        end: new Date('May 31 2022'),
        color: '#AADEE6',
      },
      {
        id: uuid(),
        title: 'Consultancy (link to RO1)',
        assignee: 'R Salem',
        start: new Date('Jun 1 2022'),
        end: new Date('Jun 1 2023'),
        color: '#F9E68C',
      },
      {
        id: uuid(),
        title: 'Consultancy (link to RO1)',
        assignee: 'R Salem',
        start: new Date('Jan 1 2022'),
        end: new Date('Apr 31 2022'),
        color: '#F9E68C',
      },
    ],
  },
  {
    id: uuid(),
    title: 'Unlinked',
    description: 'Project one',
    color: '#676C85',
    items: [
      {
        id: uuid(),
        title: 'News Article',
        assignee: '',
        start: new Date('Jan 30 2022'),
        end: new Date('Mar 01 2022'),
        color: '#F9C6C6',
      },
      {
        id: uuid(),
        title: 'Consultancy event here',
        assignee: '',
        start: new Date('Jan 30 2022'),
        end: new Date('Mar 15 2022'),
        color: '#CCDEEB',
      },
      {
        id: uuid(),
        title: 'News Article',
        assignee: '',
        start: new Date('Apr 1 2022'),
        end: new Date('Apr 31 2022'),
        color: '#F9C6C6',
      },
      {
        id: uuid(),
        title: 'Consultancy event here',
        assignee: '',
        start: new Date('Apr 1 2022'),
        end: new Date('May 15 2022'),
        color: '#CCDEEB',
      },
      {
        id: uuid(),
        title: 'News Article',
        assignee: '',
        start: new Date('Jun 1 2022'),
        end: new Date('Jun 31 2022'),
        color: '#F9C6C6',
      },
      {
        id: uuid(),
        title: 'Consultancy event here',
        assignee: '',
        start: new Date('Jun 1 2022'),
        end: new Date('Jul 15 2022'),
        color: '#CCDEEB',
      },
      {
        id: uuid(),
        title: 'News Article',
        assignee: '',
        start: new Date('Aug 1 2022'),
        end: new Date('Aug 31 2022'),
        color: '#F9C6C6',
      },
      {
        id: uuid(),
        title: 'Consultancy event here',
        assignee: '',
        start: new Date('Aug 1 2022'),
        end: new Date('Sep 15 2022'),
        color: '#CCDEEB',
      },
    ],
  },
]
