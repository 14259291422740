import React, { useContext, useCallback } from 'react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'

import { useNavigation, useFocusEffect } from '@react-navigation/native'

import Feed from '../../components/landscape/SidePanel/Feed'
import Record from '../../components/landscape/ContentPanel/Record'
import Landing from '../../components/landscape/ContentPanel/Landing'

import useSocketMethods from '../../hooks/useSocketMethods'

import Score from '../../components/landscape/Score'
import { CenteredView } from '../../components/shared/Layout'

interface HomeScreenProps {}

const HomeScreen: React.FC<HomeScreenProps> = ({ route }) => {
  const { params } = route

  const { homepage_get } = useSocketMethods()

  const {
    themeColors: { bg, separator },
  } = useContext(ThemeContext)

  return (
    <Content style={{ backgroundColor: bg.primary }}>
      {params ? <Record {...{ ...params }} /> : <Landing />}
    </Content>
  )
}

export default withLayout(HomeScreen)

const SideBar = styled.View`
  width: ${props => props.width}px;
`

const Content = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`
