export const UPDATE_RECORDS = 'UPDATE_RECORDS'
export const APPEND_RECORDS = 'APPEND_RECORDS'
export const UPDATE_USERS_TYPING = 'UPDATE_USERS_TYPING'
export const CLEAR_USERS_TYPING = 'CLEAR_USERS_TYPING'
export const SET_SELECTED_RECORD = 'SET_SELECTED_RECORD'
export const TOGGLE_PUSH = 'TOGGLE_PUSH'
export const ADD_TO_HISTORY = 'ADD_TO_HISTORY'

export const updateRecords = record => {
  return { type: UPDATE_RECORDS, record }
}

export const appendRecords = (records, screen, isRefresh) => {
  return { type: APPEND_RECORDS, payload: { records, screen, isRefresh } }
}

export const updateRecordUsersTyping = records => {
  return { type: UPDATE_USERS_TYPING, records }
}

export const clearRecordUsersTyping = () => {
  return { type: CLEAR_USERS_TYPING }
}

export const togglePush = data => {
  return { type: TOGGLE_PUSH, data }
}

export const setSelectedRecord = record => {
  return { type: SET_SELECTED_RECORD, record }
}

export const addToHistory = item => {
  return { type: ADD_TO_HISTORY, item }
}
