import styled from 'styled-components/native/dist/styled-components.native.esm'
import React from 'react'

const TopBarContainer = styled.View`
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 8px;
`

const TopBarItem = styled.View`
  flex: ${props => props.flex ?? 1};
  align-items: ${props => props.align};
`

const TopBarTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: #0e1012;
`

const TopBar = props => {
  const { title, titleColor, leftItem, rightItem, containerStyle } = props

  return (
    <TopBarContainer style={containerStyle}>
      <TopBarItem align="flex-start">{leftItem}</TopBarItem>
      <TopBarItem align="center" flex={3}>
        <TopBarTitle style={titleColor && { color: titleColor }}>
          {title}
        </TopBarTitle>
      </TopBarItem>
      <TopBarItem align="flex-end">{rightItem}</TopBarItem>
    </TopBarContainer>
  )
}

export default TopBar
