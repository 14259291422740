import React, { useContext, useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import RecordFeed from '../../components/shared/RecordFeed'
import { TinyButton } from '../../components/shared/Button'
import * as Typography from '../../components/shared/Typography'
import Avatar from '../../components/shared/Avatar'

import TabHeader from '../../components/shared/TabHeader'
import CreateNew from '../../components/modals/CreateNew'
import { useSelector, useDispatch } from 'react-redux'
import { showProfile } from '../../store/actions/layout'
import styled from 'styled-components/native/dist/styled-components.native.esm'
import { AntDesign } from '@expo/vector-icons'
import { AuthContext, useAuth } from '../../contexts/AuthContext'
import useRecordList from '../../hooks/useRecordList'

const Close = styled.TouchableOpacity`
  height: 36px;
  width: 36px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.5;
`

const HomeScreen = ({ navigation }) => {
  const { sectioned, fetchMore, refreshing, noMoreItems, onRefresh } =
    useRecordList('home')

  const {
    profile: { open },
  } = useSelector(state => state.layout)

  const { user } = useAuth()

  const modalizeRef = useRef(null)

  const onOpen = () => {
    modalizeRef.current?.open()
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      modalizeRef.current?.close()
      dispatch(showProfile(false))
    })

    return unsubscribe
  }, [navigation])

  const dispatch = useDispatch()
  const insets = useSafeAreaInsets()

  const data = sectioned.filter(section => section.data.length)

  return (
    <View style={[styles.container, { paddingTop: insets.top }]}>
      <TabHeader title="Home">
        {open ? (
          <Close onPress={() => dispatch(showProfile(false))}>
            <AntDesign name="close" size={24} color="#FFFFFF" />
          </Close>
        ) : (
          <>
            <TinyButton
              style={{ backgroundColor: '#2AAD79', marginRight: 15 }}
              primary
              onPress={onOpen}
            >
              <Typography.Button color="#FFFFFF">Add</Typography.Button>
            </TinyButton>

            <Avatar
              size={36}
              radius={12}
              userId={user?.userid}
              onPress={() => dispatch(showProfile(true))}
            />
          </>
        )}
      </TabHeader>

      <RecordFeed
        data={data}
        onEndReached={fetchMore}
        onRefresh={onRefresh}
        isRefreshing={refreshing}
        noMoreItems={noMoreItems}
        screen="home"
      />

      <CreateNew {...{ modalizeRef }} />
    </View>
  )
}

export default HomeScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EBECF2',
  },
})
