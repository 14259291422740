import React, { useContext, useRef } from 'react'
import {
  Alert,
  View,
  Linking,
  ToastAndroid,
  Vibration,
  Platform,
  Animated,
  TouchableOpacity
} from 'react-native'
import { AlignedRow } from './Layout'
import Avatar from './Avatar'
import Autolink from 'react-native-autolink'
import MessageReactions from './MessageReactions'
import useTimeSince from '../../hooks/useTimeSince'
import * as Clipboard from 'expo-clipboard'
import urlParser from 'url'
import { useAuth } from '../../contexts/AuthContext'
import { APIContext } from '../../contexts/APIContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import styled from 'styled-components/native'
import * as RootNavigation from '../../services/RootNavigation'

const NewDivider = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0;
  padding: 5px 20px;
`

const Divider = styled.View`
  flex: 1;
  height: 1px;
  width: 54px;
`

const doubleTapDelay = 800

// Set if running in Electron
const RunningInElectron =
  Platform.OS === 'web' &&
  'userAgent' in navigator &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

RunningInElectron ? window.require('electron').ipcRenderer : null

const Comment = ({
  comment,
  messageOnPress,
  messageOnTextLongPress,
  messageOnTextPress,
  isCommentOwner,
}) => {
  const {
    typography: { Body },
    themeColors: {
      fonts: { primary, secondary },
    },
  } = useContext(ThemeContext)

  const getBodyColor = () => {
    let style
    if (isCommentOwner) {
      style = { color: `#FFF` }
    } else {
      style = { color: comment.is_change ? secondary : primary }
    }

    return style
  }

  return (
    <Autolink
      email={true}
      onPress={messageOnPress}
      selectable={true}
      style={[getBodyColor(), { flexShrink: 1 }]}
      linkStyle={{ color: '#296DFF' }}
      text={comment.text}
      component={Body}
      textProps={{
        onPress: messageOnTextPress,
        onLongPress: messageOnTextLongPress,
      }}
      truncate={20}
    />
  )
}

const CommentBubble = ({ idx, item, comment, record, setComments }) => {
  const isGroupConversation =
    record.users_by_relationship?.PARTICIPANT?.length > 2

  const {
    typography: { Caption, Label },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const { user: authUser } = useAuth()

  const { getFormattedShortDate } = useTimeSince()

  const { reactToComment } = useContext(APIContext)

  const isCommentOwner = comment.user.id === authUser.id
  const firstOfGroup = idx === item.length - 1

  const { user } = comment

  let borderRadius
  if (item.length === 1) {
    borderRadius = {
      borderRadius: 20,
    }
  } else if (idx === 0) {
    borderRadius = {
      borderTopRightRadius: isCommentOwner ? 4 : 20,
      borderTopLeftRadius: isCommentOwner ? 20 : 4,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    }
  } else if (firstOfGroup) {
    borderRadius = {
      borderTopRightRadius: isCommentOwner ? 20 : 20,
      borderTopLeftRadius: isCommentOwner ? 20 : 20,
      borderBottomLeftRadius: isCommentOwner ? 20 : 4,
      borderBottomRightRadius: isCommentOwner ? 4 : 20,
    }
  } else {
    borderRadius = {
      borderTopRightRadius: isCommentOwner ? 4 : 20,
      borderTopLeftRadius: isCommentOwner ? 20 : 4,
      borderBottomLeftRadius: isCommentOwner ? 20 : 4,
      borderBottomRightRadius: isCommentOwner ? 4 : 20,
    }
  }

  const sendReaction = emoji => {
    // If the message is one that the device just sent in the last couple of
    // seconds it may not have an ID yet (the user is being very keen)
    if (!comment.id) {
      return
    }
    reactToMessage(comment.id, emoji)
  }

  let tapTimer,
    tapCount = 0

  const messageOnPress = (url, match) => {
    switch (match.getType()) {
      case 'url':
        // Don't respond to internal links - we'll fit in some redirection code once we have URL following in the system
        let urlObject = urlParser.parse(url)
        if (urlObject.host.endsWith('worktribe3.com')) {
          let alertText = 'In-app navigation coming soon...'
          if (Platform.OS === 'web') {
            alert(alertText)
          } else {
            Alert.alert(alertText)
          }
        } else {
          if (Platform.OS === 'web') {
            let webWindow = window.open(url, '_blank')
            if (webWindow) {
              window.focus()
            }
          } else {
            Linking.openURL(url)
          }
        }
        break
      default:
        // Do nothing.
        break
    }
    return false
  }

  const messageOnTextPress = () => {
    tapCount++
    if (tapCount < 2) {
      tapTimer = setTimeout(() => {
        tapCount = 0
      }, doubleTapDelay)
    } else {
      clearTimeout(tapTimer)
      sendReaction('👍')
      tapCount = 0
    }
  }

  const messageOnTextLongPress = () => {
    if (Platform.OS === 'android') {
      let message = 'Text copied to clipboard'
      Vibration.vibrate(25)
      ToastAndroid.show(message, ToastAndroid.SHORT)
      Clipboard.setString(item.text)
    }
  }

  const reactToMessage = (commentId, emoji) => {
    let originalMessageCopy

    // It's an update to a message so replace the item in messages array
    setComments(comments => {
      return comments.map(message => {
        if (message.id === commentId) {
          // This is the message we want to modify the reactions_summary on

          // Take a copy of the unmodified message for use if/when the request fails
          originalMessageCopy = { ...message }

          // This may be the most recent message, we don't want the whole
          // message to re-animate so set slideIn to false
          message.slideIn = false

          if (message.reactions_summary === null) {
            message.reactions_summary = []
          } else {
            // First, we always need to remove any existing reaction
            message.reactions_summary = message.reactions_summary.map(
              reaction => {
                // Remove myself from reactors
                reaction.reactors = reaction.reactors.filter(
                  reactor => reactor.id !== authUser.userid
                )
                return reaction
              }
            )
          }

          if (emoji !== '') {
            // Ensure the correct reaction is present

            if (message.reactions_summary.length === 0) {
              // It's blank so we can just populate the whole reactions_summary
              message.reactions_summary = [
                {
                  emoji: emoji,
                  reactors: [
                    {
                      id: authUser.userid,
                      name: authUser.name,
                    },
                  ],
                },
              ]
              return message
            }

            message.reactions_summary = message.reactions_summary.map(
              reaction => {
                if (
                  reaction.emoji === emoji &&
                  reaction.reactors.indexOf(
                    reactor => reactor.id === authUser.userid
                  ) === -1
                ) {
                  // Add myself to the array of reactors for this emoji reaction
                  reaction.reactors.push({
                    id: authUser.userid,
                    name: authUser.name,
                  })
                  return reaction
                }
                return reaction
              }
            )
          }

          return message
        }
        return message
      })
    })

    // Now do the network request
    reactToComment(record.rclass, record.id, commentId, emoji)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          // Successful request! No need to do anything :-)
          return
        }
        revertMessage()
      })
      .catch(error => {
        console.log(error)
        revertMessage()
      })

    /**
     * Called if the request fails, uses a locally scoped variable originalMessageCopy
     * to revert the message and it's reactions summary to what it was before request
     */
    const revertMessage = () => {
      setComments(comments => {
        return comments.map(message => {
          if (message.id === commentId) {
            return originalMessageCopy
          }
          return message
        })
      })
    }
  }

  let animated = new Animated.Value(0)
  const animatedStyles = useRef(null)

  if (idx === 0 && comment.slideIn) {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start(() => {
      animated = new Animated.Value(0)
      animatedStyles.current = null
    })

    animatedStyles.current = {
      opacity: animated,
      transform: [
        { scale: animated },
        {
          rotate: animated.interpolate({
            inputRange: [0, 1],
            outputRange: ['35deg', '0deg'],
            extrapolate: 'clamp',
          }),
        },
      ],
    }
  }

  return (
    <Animated.View style={animatedStyles.current}>
      <AlignedRow
        style={{
          alignSelf: isCommentOwner ? 'flex-end' : 'flex-start',
          alignItems: 'flex-end',
        }}
      >
        <TouchableOpacity style={{ marginRight: 8 }}>
          {isGroupConversation && !isCommentOwner && firstOfGroup ? (
            <Avatar
              size={36}
              radius={12}
              userId={user.id}
              onPress={() =>
                RootNavigation.navigate('Profile', { profile: comment.user })
              }
            />
          ) : null}
        </TouchableOpacity>

        <View style={{ flexShrink: 1 }}>
          <View
            style={[
              {
                flex: 1,
                backgroundColor: isCommentOwner ? '#1D7A55' : bg.card,
                paddingHorizontal: 12,
                paddingVertical: 8,
                marginVertical: 1,
              },
              borderRadius,
              isGroupConversation &&
                !isCommentOwner &&
                !firstOfGroup && { marginLeft: 36 },
            ]}
          >
            {isGroupConversation ? (
              <>
                {!isCommentOwner && firstOfGroup ? (
                  <>
                    <AlignedRow>
                      <Label style={{ marginBottom: 5 }}>
                        {comment.user.name}
                      </Label>

                      <Caption style={{ marginBottom: 5, marginLeft: 6 }}>
                        {getFormattedShortDate(new Date(comment.created_at))}
                      </Caption>
                    </AlignedRow>

                    <Comment
                      comment={comment}
                      isCommentOwner={isCommentOwner}
                      messageOnPress={messageOnPress}
                      messageOnTextPress={messageOnTextPress}
                      messageOnTextLongPress={messageOnTextLongPress}
                    />
                  </>
                ) : (
                  <AlignedRow>
                    <Comment
                      comment={comment}
                      isCommentOwner={isCommentOwner}
                      messageOnPress={messageOnPress}
                      messageOnTextPress={messageOnTextPress}
                      messageOnTextLongPress={messageOnTextLongPress}
                    />

                    {firstOfGroup && (
                      <Caption
                        color={
                          isCommentOwner ? 'rgba(242,252,248, 0.6)' : '#A0A4B8'
                        }
                        style={{ marginLeft: 6 }}
                      >
                        {getFormattedShortDate(new Date(comment.created_at))}
                      </Caption>
                    )}
                  </AlignedRow>
                )}
              </>
            ) : (
              <AlignedRow>
                <Comment
                  comment={comment}
                  isCommentOwner={isCommentOwner}
                  messageOnPress={messageOnPress}
                  messageOnTextPress={messageOnTextPress}
                  messageOnTextLongPress={messageOnTextLongPress}
                />

                {firstOfGroup && (
                  <Caption
                    color={
                      isCommentOwner ? 'rgba(242,252,248, 0.6)' : '#A0A4B8'
                    }
                    style={{ marginLeft: 6 }}
                  >
                    {getFormattedShortDate(new Date(comment.created_at))}
                  </Caption>
                )}
              </AlignedRow>
            )}
            <MessageReactions
              reactions_summary={comment.reactions_summary}
              authUser={authUser}
              sendReaction={sendReaction}
              isOwn={isCommentOwner}
            />
          </View>
        </View>
      </AlignedRow>
    </Animated.View>
  )
}

const CommentRow = props => {
  const { record, item, setComments } = props
  const {
    typography: { Overline },
    themeColors: { fonts, accents },
  } = useContext(ThemeContext)

  if (typeof item === 'string') {
    const isNew = item === 'new'
    const dividerColor = isNew ? `#2AAD79` : accents.separator
    return (
      <View style={{ justifyContent: 'center', flex: 1, flexDirection: 'row' }}>
        <NewDivider>
          <Divider style={{ backgroundColor: dividerColor }} new={isNew} />
          <Overline
            color={isNew ? '#2AAD79' : fonts.secondary}
            style={{ paddingHorizontal: 20 }}
          >
            {item}
          </Overline>
          <Divider style={{ backgroundColor: dividerColor }} new={isNew} />
        </NewDivider>
      </View>
    )
  }

  return item.map((comment, idx) => (
    <CommentBubble
      key={item.id}
      idx={idx}
      item={item}
      comment={comment}
      record={record}
      setComments={setComments}
    />
  ))
}

export default React.memo(CommentRow)
