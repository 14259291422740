import React, { useState } from 'react'
import {
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
} from 'react-native'
import * as ImagePicker from 'expo-image-picker'

const imgArr = [
  require('../../../assets/profiles/1.jpg'), //Derek di Giovanni
  require('../../../assets/profiles/2.jpg'), //Joe Hingston
  require('../../../assets/profiles/3.jpg'), //Martin Joiner
  require('../../../assets/profiles/4.jpg'), //Matt Eris
  require('../../../assets/profiles/5.jpg'), //Rich Jeffery
  require('../../../assets/profiles/6.jpg'), //Roland Treiber
  require('../../../assets/profiles/7.jpg'), //Sam Brown
  require('../../../assets/profiles/8.jpg'), //Tom Price
  require('../../../assets/profiles/9.jpg'), //Tester Rantzen
  require('../../../assets/profiles/10.jpg'), //Gloria Testerfan
  require('../../../assets/profiles/11.jpg'), //Fireman Sam
  require('../../../assets/profiles/default.png'), //??
  require('../../../assets/profiles/13.jpg'), //A-ha
]

const Active = ({ size }) => <View style={[styles.active, { top: size - 8 }]} />

const Avatar = ({
  userId,
  size = 36,
  group,
  radius,
  border,
  offset,
  isEditable,
  onPress,
}) => {
  const isActive = false
  const [imageData, setImageData] = useState(null)

  const source = group
    ? require('../../../assets/profiles/team.png')
    : imgArr[userId - 1]
    ? imgArr[userId - 1]
    : require('../../../assets/profiles/default.png')

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: false,
      allowsEditing: true,
      aspect: [4, 4],
      base64: true,
      quality: 1,
    })

    if (result.cancelled === false) {
      // TODO: send image to backend
      setImageData(result)
    }
  }

  const pressOpacity = isEditable ? 0.8 : 1
  onPress = isEditable ? pickImage : onPress

  return (
    <TouchableOpacity activeOpacity={pressOpacity} onPress={onPress}>
      <Image
        style={[
          offset && { marginLeft: -8 },
          border && { borderWidth: border.width, borderColor: border.color },
          { width: size, height: size },
          { borderRadius: radius ? radius : 24 },
          { backgroundColor: 'grey' }, // in case of transparent background on image
        ]}
        source={imageData ? { uri: imageData.uri } : source}
      />
      {isEditable ? (
        <Image
          style={{
            position: 'absolute',
            height: Platform.OS == 'web' ? size * 0.12 : size * 0.15,
            width: Platform.OS == 'web' ? size * 0.12 : size * 0.15,
            top: size * 0.08,
            right: size * 0.1,
          }}
          source={require('../../../assets/icons/camera.png')}
        />
      ) : null}
      {isActive && <Active size={size} />}
    </TouchableOpacity>
  )
}

export default Avatar

const styles = StyleSheet.create({
  active: {
    position: 'absolute',
    borderRadius: 50,
    height: 10,
    width: 10,
    backgroundColor: '#5fdba7',
    right: 0,
  },
})
