import styled from 'styled-components/native'

export const H1 = styled.Text`
  font-family: proxima-nova-bold;
  font-size: 34px;
  line-height: 32px;
  color: ${props => props.color ?? '#252633'};
`

export const H2 = styled.Text`
  font-family: proxima-nova-bold;
  font-size: 26px;
  line-height: 32px;
  color: ${props => props.color ?? '#252633'};
`

export const H3 = styled.Text`
  font-family: proxima-nova-bold;
  font-size: 22px;
  line-height: 27px;
  color: ${props => props.color ?? '#252633'};
`

export const H4 = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 20px;
  line-height: 22px;
  color: ${props => props.color ?? '#252633'};
`

export const H5 = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 15px;
  line-height: 22px;
  color: ${props => props.color ?? '#252633'};
`

export const Subtitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 24px;
  color: ${props => props.color ?? '#252633'};
`

export const Body = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  line-height: 24px;
  color: ${props => props.color ?? '#252633'};
`

export const Button = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 22px;
  color: ${props => props.color ?? '#252633'};
  letter-spacing: 0.25px;
`

export const Overline = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.color ?? '#252633'};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: ${props => (props.center ? 'center' : 'left')};
`

export const Label = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.color ?? '#252633'};
`

export const Caption = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.color ?? '#0e1012'};
`
