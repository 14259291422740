import React, { useContext, useState } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { Image, TouchableOpacity, View } from 'react-native'
import { SmallButton, TinyButton } from '../shared/Button'
import Avatar from '../shared/Avatar'
import WorktribeIcon from '../shared/WorktribeIcon'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const Separator = styled.View`
  height: 1px;
  background-color: #dde1ed;
`

const DOCUMENTS = [
  {
    name: 'Video file.mpeg4',
    type: 'video',
  },
  {
    name: 'PDF_file.pdf',
    type: 'pdf',
  },
  {
    name: 'Documented introduction.docx',
    type: 'docx',
  },
  {
    name: 'Module selection guidelines.xlsx',
    type: 'xls',
  },
  {
    name: 'Zip file.zip',
    type: 'zip',
  },
  {
    name: 'Other file format.pst',
    type: 'other',
  },
  {
    name: 'Folder',
    type: 'folder',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
  {
    name: 'Module selection image.jpg',
    type: 'module',
  },
]

const imgMap = {
  video: require('../../../assets/icons/files/video.png'),
  pdf: require('../../../assets/icons/files/pdf.png'),
  docx: require('../../../assets/icons/files/docx.png'),
  xls: require('../../../assets/icons/files/xls.png'),
  zip: require('../../../assets/icons/files/zip.png'),
  other: require('../../../assets/icons/files/other.png'),
  folder: require('../../../assets/icons/files/folder.png'),
  module: require('../../../assets/icons/files/module.png'),
}

const DocumentRow = ({ document }) => {
  const {
    typography: { Body },
  } = useContext(ThemeContext)

  const [folderSelected, setFolderSelected] = useState(false)

  let dimensions
  switch (document.type) {
    case 'folder': {
      dimensions = {
        width: 31.71,
        height: 26.95,
      }
      break
    }
    case 'module': {
      dimensions = {
        width: 24,
        height: 28,
      }
      break
    }
    default: {
      dimensions = {
        width: 26.67,
        height: 32,
      }
    }
  }

  return (
    <TouchableOpacity
      onPress={() => {
        if (document.type === 'folder') {
          setFolderSelected(selected => !selected)
        }
      }}
    >
      <Row
        style={[
          {
            paddingVertical: 16,
            paddingHorizontal: 20,
            alignItems: 'center',
          },
          folderSelected && { backgroundColor: 'rgba(224,137,56, 0.2)' },
        ]}
      >
        <Col size={3}>
          <AlignedRow>
            <View style={{ width: 32, marginRight: 20 }}>
              <Image style={dimensions} source={imgMap[document.type]} />
            </View>
            <Body color="#0E1012">{document.name}</Body>
          </AlignedRow>
        </Col>

        <Col>
          <Body color="#757885">11 Jan 2021, 6:19PM</Body>
        </Col>

        <Col>
          <AlignedRow>
            <Avatar userId={1} size={24} radius={8} />
            <Body style={{ marginLeft: 8 }} color="#757885">
              Jenna Clare
            </Body>
          </AlignedRow>
        </Col>
      </Row>
    </TouchableOpacity>
  )
}

const Documents = ({ record }) => {
  const {
    typography: { H3, H5, Button },
  } = useContext(ThemeContext)
  const [uploading, setUploading] = useState(false)
  return (
    <>
      <Container contentContainerStyle={{ alignItems: 'center' }}>
        <Content>
          <AlignedRow justifyContent="space-between">
            <AlignedRow>
              <H3 color="#0E1012">Documents</H3>
            </AlignedRow>

            <AlignedRow>
              <SmallButton
                primary
                onPress={() => setUploading(uploading => !uploading)}
              >
                <Button color="#FFF">Upload</Button>
              </SmallButton>

              <SmallButton style={{ marginLeft: 16 }}>
                <Button color="#1D7A55">Create Folder</Button>
              </SmallButton>
            </AlignedRow>
          </AlignedRow>

          <Grid
            style={{
              marginTop: 20,
              marginBottom: 36,
              flexDirection: 'column',
            }}
          >
            <Row
              style={{
                paddingVertical: 12,
                paddingHorizontal: 20,
              }}
            >
              <Col size={3}>
                <H5 style={{ marginLeft: 50 }} color="#0E1012">
                  Name
                </H5>
              </Col>
              <Col>
                <H5 color="#0E1012">Uploaded on</H5>
              </Col>
              <Col>
                <H5 color="#0E1012">Uploaded by</H5>
              </Col>
            </Row>
            <View
              style={[
                {
                  backgroundColor: '#F4F5F9',
                  borderRadius: 20,
                  borderWidth: 2,
                  borderColor: '#F4F5F9',
                },
                uploading && {
                  backgroundColor: 'rgba(224,137,56, 0.2)',
                  borderWidth: 2,
                  borderStyle: 'dashed',
                  borderColor: 'rgba(0,0,0, 0.2)',
                },
              ]}
            >
              {DOCUMENTS.map((document, i) => (
                <DocumentRow
                  key={`${document.name}-${i}`}
                  document={document}
                />
              )).reduce((prev, curr) => [
                prev,
                <Separator key={Math.random(1, 1000)} />,
                curr,
              ])}
            </View>
          </Grid>
        </Content>
      </Container>

      {uploading ? (
        <TouchableOpacity
          style={{
            minWidth: 330,
            position: 'absolute',
            left: '50%',
            marginLeft: -165,
            backgroundColor: '#E08938',
            bottom: 28,
            borderRadius: 50,
            paddingVertical: 12,
            paddingHorizontal: 24,
            shadowColor: 'rgba(0, 0, 0, 0.12)',
            shadowOpacity: 0.58,
            shadowRadius: 16.0,
            elevation: 24,
          }}
        >
          <AlignedRow>
            <WorktribeIcon name="upload-bottom" />
            <H5 style={{ marginLeft: 16 }} color="#0E1012">
              Drop your files to upload to Worktribe
            </H5>
          </AlignedRow>
        </TouchableOpacity>
      ) : null}
    </>
  )
}

export default Documents
