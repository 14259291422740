import React from 'react'
import { View, StyleSheet } from 'react-native'

const Ellipsis = ({ color = 'white', onPress }) => {
  return (
    <View onPress={onPress} style={styles.container}>
      {[...Array(3)].map((_, idx) => (
        <View key={idx} style={[styles.dot, { backgroundColor: color }]} />
      ))}
    </View>
  )
}

export default Ellipsis

const styles = StyleSheet.create({
  container: {
    height: 18,
    width: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: 40,
  },
})
