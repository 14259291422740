import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/native'
import { ActivityIndicator, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import TabHeader from '../../components/shared/TabHeader'
import SearchInput from '../../components/shared/SearchInput'
import { showProfile } from '../../store/actions/layout'
import { useDispatch } from 'react-redux'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import { AlignedRow, CenteredView } from '../../components/shared/Layout'

import useServices from '../../hooks/useServices'

const Container = styled.View`
  flex: 1;
`

const Body = styled.ScrollView`
  flex: 1;
  padding: 0 16px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  margin: 12px 0;
  padding: 24px;
  border-radius: 16px;
`

const LinkRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -9px 0 -9px;
`

const Link = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 20px;
  padding: 16px;
  margin: 6px 9px;
  min-width: 300px;
`

const LinkIcon = styled.View`
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  margin-right: 16px;
  background-color: ${props => props.color};
`


const colors = {
  turquoise: '#00C5A8',
  red: '#E94545',
  orange: '#E08938',
  purple: '#5F44C5',
  yellow: '#F2CE6A',
  green: '#78D8B1',
  pink: '#FFA9B3',
  violet: '#3A2449',
  teal: '#46C6D1',
}

const Service = ({ service, search, selectService, description = true }) => {
  const {
    typography: { Button, Caption },
    themeColors: { bg }
  } = useContext(ThemeContext)

  return (
    <Link
      key={service.name}
      onPress={() => selectService(service)}
      style={[
        search &&
        service.name.toLowerCase().match(search.toLowerCase()) && {
          backgroundColor: 'rgb(242, 252, 248)',
        }
      ], { backgroundColor: bg.primary }}
    >
      <AlignedRow style={[
        { flex: 1 }, 
        service.description && { alignItems: 'flex-start' }
      ]}>
        <LinkIcon color={colors[service.colorname]}>
          <WorktribeIcon name="bookmark" color="#FFF" />
        </LinkIcon>
        <View style={{ flex: 1 }}>
          <Button primary>{service.name}</Button>
          {(description && service.description) ? (
            <Caption>{service.description}</Caption>
          ) : null}
        </View>
      </AlignedRow>
    </Link>
  )
}

const SearchScreen = ({ navigation }) => {
  const [search, setSearch] = useState('')

  const {
    domains,
    isLoading,
    selectService
  } = useServices()

  const {
    typography: { H2, H4 },
    themeColors: { bg }
  } = useContext(ThemeContext)

  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      dispatch(showProfile(false))
    })

    return unsubscribe
  }, [navigation])

  return (
    <Container style={{ paddingTop: insets.top, backgroundColor: bg.primary }}>
      <TabHeader title="Services" />

      <SearchInput
        backgroundColor={bg.card}
        search={search}
        setSearch={setSearch}
        navigation={navigation}
        onFocus={() => navigation.navigate('SearchRecent')}
      />

      {isLoading ? (
        <CenteredView>
          <ActivityIndicator />
        </CenteredView>
      ) : (
        <Body>
          {domains.map((domain, idx) => (
            <View key={`${domain.name}_${idx}`}>
              <H2 primary style={{ marginVertical: 12 }}>
                {domain.name}
              </H2>

              <View>
                {domain.areas.map((area, idx) => (
                  <Bubble style={{ backgroundColor: bg.card}} key={`${area.name}_${idx}`}>
                    {area.name ? <H4 primary style={{ marginBottom: 10 }}>
                      {area.name}
                    </H4> : null}

                    <LinkRow>
                      {area.services.map(service => (
                        <Service
                          key={`${domain.name}-${service.name}`}
                          service={service}
                          search={search}
                          selectService={selectService}
                        />
                      ))}
                    </LinkRow>
                  </Bubble>
                ))}
              </View>
            </View>
          ))}
        </Body>
      )}
    </Container>
  )
}

export default SearchScreen