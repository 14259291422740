import { ScrollView, View } from 'react-native'
import { AlignedRow } from '../../shared/Layout'
import WorktribeIcon from '../../shared/WorktribeIcon'
import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import useServices from '../../../hooks/useServices'
import Searchatron from '../Searchatron'
import SearchButton from '../SearchButton'

const colors = {
  turquoise: '#00C5A8',
  red: '#E94545',
  orange: '#E08938',
  purple: '#5F44C5',
  yellow: '#F2CE6A',
  green: '#78D8B1',
  pink: '#FFA9B3',
  violet: '#3A2449',
  teal: '#46C6D1',
}

const ServiceItem = ({ service, selectService, description = true }) => {
  const {
    typography: { Button, Caption },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Link
      key={service.name}
      onPress={() => selectService(service)}
      style={{ backgroundColor: bg.primary }}
    >
      <AlignedRow
        style={[
          { flex: 1 },
          service.description && description && { alignItems: 'flex-start' },
        ]}
      >
        <LinkIcon color={colors[service.colorname]}>
          <WorktribeIcon name="bookmark" color="#FFF" />
        </LinkIcon>
        <View style={{ flex: 1 }}>
          <Button primary>{service.name}</Button>
          {description && <Caption>{service.description}</Caption>}
        </View>
      </AlignedRow>
    </Link>
  )
}

const Services = () => {
  const { domains, selectService, error } = useServices()

  const [showSearch, setShowSearch] = useState(false)

  const handleHideSearch = useCallback(() => {
    setShowSearch(false)
  }, [])

  const handleShowSearch = useCallback(() => {
    setShowSearch(true)
  }, [])

  const {
    typography: { H2, H4, Button, Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <>
      <View
        style={{
          paddingTop: 20,
          paddingBottom: 30,
          paddingHorizontal: 40,
        }}
      >
        <AlignedRow justifyContent="space-between">
          <View style={{ alignItems: 'flex-start', flex: 1 }}>
            <H2 primary>Services</H2>
          </View>

          {showSearch && <Searchatron setShowSearch={handleHideSearch} />}

          <SearchButton
            handleShowSearch={handleShowSearch}
            containerStyle={{ width: 589 }}
          />

          <View style={{ alignItems: 'flex-end', flex: 1 }}>
            {/* <AlignedRow>
              <SmallButton primary>
                <Button color="#FFF">Create record</Button>
              </SmallButton>

              <SmallButton style={{ marginLeft: 16 }}>
                <Ellipsis color="#2AAD79" />
              </SmallButton>
            </AlignedRow> */}
          </View>
        </AlignedRow>
      </View>

      {error ? (
        <Subtitle style={{ paddingHorizontal: 40 }}>{error}</Subtitle>
      ) : !domains ? null : ( // <ServicesLoading />
        <>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 40 }}
          >
            {domains?.map(domain => (
              <View key={domain.name}>
                <H2 primary style={{ marginBottom: 20 }}>
                  {domain.name}
                </H2>

                <BubbleRow>
                  {domain.areas.map(area => (
                    <Bubble
                      style={{ backgroundColor: bg.card }}
                      key={area.name}
                    >
                      <H4 primary style={{ marginBottom: 10 }}>
                        {area.name}
                      </H4>

                      <LinkRow>
                        {area.services.map((service, index) => (
                          <ServiceItem
                            key={`${domain.name}-${area.name}-${service.name}-${index}`}
                            service={service}
                            selectService={selectService}
                          />
                        ))}
                      </LinkRow>
                    </Bubble>
                  ))}
                </BubbleRow>
              </View>
            ))}
          </ScrollView>
        </>
      )}
    </>
  )
}

export default Services

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px 20px -12px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  margin: 12px;
  padding: 24px;
  border-radius: 16px;
  min-width: 748px;
`

const LinkRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -9px 0 -9px;
`

const Link = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 20px;
  padding: 16px;
  margin: 6px 9px;
  min-width: 300px;
`

const LinkIcon = styled.View`
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  margin-right: 16px;
  background-color: ${props => props.color};
`