import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { TouchableOpacity, View, Switch, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { MediumButton } from './Button'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from './WorktribeIcon'
import format from 'date-fns/format'
import { AlignedRow } from './Layout'
import HoverableOpacity from '../shared/HoverableOpacity'
import useOutsideModalAlerter from '../../hooks/useModalOutsideAlerter'

const DayWrapper = styled.TouchableOpacity`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => (props.selected ? '#2AAD79' : 'transparent')}
  justify-content: center;
  align-items: center;
`

const Rows = styled.View`
  flex: 1;
  flex-direction: row;
  padding: 2px 0;
  justify-content: space-around;
  align-items: center;
`

const CalendarActions = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px 0;
`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

const InlineCalendar = ({
  value,
  valueHandler,
  saveChanges,
  rejectChanges,
  calendarText,
  setCalendarText,
  time,
}) => {
  const [activeDate, setActiveDate] = useState(() => {
    if (value) {
      return new Date(value)
    }
    const d = new Date()
    d.setHours(0, 0, 0)
    return d
  })

  useEffect(() => {
    const timestamp = Date.parse(calendarText)

    if (isNaN(timestamp) === false) {
      valueHandler(() => {
        setActiveDate(new Date(timestamp))
        return new Date(timestamp)
      })
    }
  }, [calendarText])

  const {
    typography: { Button, Subtitle, Overline, Label },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const generateMatrix = () => {
    let matrix = []

    matrix[0] = weekDays

    const year = activeDate.getFullYear()
    const month = activeDate.getMonth()

    const firstDay = new Date(year, month, 1).getDay()

    let maxDays = nDays[month]
    if (month === 1) {
      // February
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1
      }
    }

    let counter = 1
    for (let row = 1; row < 7; row++) {
      matrix[row] = []
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = -1
        if (row === 1 && col >= firstDay) {
          matrix[row][col] = counter++
        } else if (row > 1 && counter <= maxDays) {
          matrix[row][col] = counter++
        }
      }
    }

    return matrix
  }

  const _onSelect = item => {
    if (!item.match && item !== -1) {
      valueHandler(() => new Date(activeDate.setDate(item)))
      if (setCalendarText)
        setCalendarText(() => format(activeDate, 'do MMM, yyy, h:mma'))
    }
  }

  const changeMonth = n => {
    setActiveDate(() => {
      valueHandler(
        () => new Date(activeDate.setMonth(activeDate.getMonth() + n))
      )

      if (setCalendarText)
        setCalendarText(() => format(activeDate, 'do MMM, yyy, h:mma'))
      return new Date(activeDate)
    })
  }

  const changeTime = (hours, minutes) => {
    valueHandler(() => new Date(activeDate.setHours(hours, minutes, 0)))
    if (setCalendarText)
      setCalendarText(() => format(activeDate, 'do MMM, yyy, h:mma'))
  }

  const rows = generateMatrix().map((row, rowIndex) => {
    const rowItems = row.map((item, colIndex) => {
      if (weekDays.includes(item)) {
        return (
          <Overline key={`${rowIndex}-${colIndex}`}>
            {item !== -1 ? item.substr(0, 2) : ''}
          </Overline>
        )
      }

      return (
        <DayWrapper
          key={`${rowIndex}-${colIndex}`}
          selected={item === activeDate.getDate()}
          onPress={() => _onSelect(item)}
        >
          {item !== -1 ? (
            <Label primary={item !== activeDate.getDate()} color="#FFF">
              {item}
            </Label>
          ) : null}
        </DayWrapper>
      )
    })

    return <Rows key={rowIndex}>{rowItems}</Rows>
  })

  return (
    <View>
      <CalendarActions>
        <TouchableOpacity onPress={() => changeMonth(-1)}>
          <WorktribeIcon color="#A0A4B8" name="arrow-up-left" />
        </TouchableOpacity>

        <Subtitle primary>
          {`${months[activeDate.getMonth()]} ${activeDate.getFullYear()}`}
        </Subtitle>

        <TouchableOpacity onPress={() => changeMonth(+1)}>
          <WorktribeIcon color="#A0A4B8" name="arrow-up-right" />
        </TouchableOpacity>
      </CalendarActions>

      <View style={{ height: 210, marginHorizontal: -10 }}>{rows}</View>

      {!!time && <Time activeDate={activeDate} changeTime={changeTime} />}

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <MediumButton onPress={rejectChanges}>
          <Button color="#2AAD79">Clear</Button>
        </MediumButton>

        <MediumButton style={{ marginLeft: 16 }} primary onPress={saveChanges}>
          <Button color="#FFF">Apply</Button>
        </MediumButton>
      </View>
    </View>
  )
}

export default InlineCalendar

const meridians = ['AM', 'PM']

const Time = ({ activeDate, changeTime }) => {
  const {
    typography: { Body },
    themeColors: { hover, bg, accents },
    shadowStyles,
  } = useContext(ThemeContext)
  const [showTime, setShowTime] = useState(false)
  const [activeOption, setActiveOption] = useState('')

  const [meridian, setMeridian] = useState(() => {
    const hours = activeDate.getHours()
    return hours > 11 ? 'PM' : 'AM'
  })

  const [selectedTime, setSelectedTime] = useState(() => {
    const hours = activeDate.getHours()
    const minutes = activeDate.getMinutes()

    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`
  })

  useEffect(() => {
    if (!showTime) setActiveOption('')
  }, [showTime])

  const [times, setTimes] = useState([])

  useEffect(() => {
    var x = 5 //minutes interval
    var times = [] // time array
    var tt = 0 // start time

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
      var mm = tt % 60 // getting minutes of the hour in 0-55 format
      times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x
    }

    let result
    switch (meridian) {
      case 'AM': {
        result = times.filter(time => {
          const [hours, minutes] = time.split(':')
          return hours < 12
        })
        break
      }
      default: {
        result = times.filter(time => {
          const [hours, minutes] = time.split(':')
          return hours > 11
        })
      }
    }

    setTimes(result)
  }, [meridian])

  useEffect(() => {
    if (!selectedTime) return

    const [hours, minutes] = selectedTime.split(':')
    changeTime(hours, minutes)
  }, [selectedTime])

  const outsideHandler = useCallback(() => setActiveOption(''), [])
  const wrapperRef = useRef(null)
  useOutsideModalAlerter(wrapperRef, outsideHandler)

  return (
    <View
      ref={wrapperRef}
      style={{
        marginVertical: 24,
        backgroundColor: bg.card,
        borderRadius: 16,
        zIndex: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 60,
          padding: 16,
        }}
      >
        <Body primary>Time</Body>

        <View
          style={{
            backgroundColor: showTime ? '#2AAD79' : '#A0A4B8',
            height: 28,
            width: 48,
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Switch
            activeThumbColor="#FFF"
            trackColor={{ 0: '#2AAD79', 1: '#2AAD79' }}
            onValueChange={setShowTime}
            value={showTime}
          />
        </View>
      </View>

      {showTime && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 60,
            borderTopColor: accents.separator,
            borderTopWidth: 1,
          }}
        >
          <View style={{ flex: 2, paddingVertical: 18, paddingHorizontal: 16 }}>
            <TouchableOpacity
              onPress={() =>
                setActiveOption(active => (active === 'times' ? '' : 'times'))
              }
            >
              <AlignedRow justifyContent="space-between">
                <Body primary>
                  {selectedTime ? selectedTime : `Select time`}
                </Body>
                <WorktribeIcon name="chevron-down" />
              </AlignedRow>
            </TouchableOpacity>

            {activeOption === 'times' && (
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 60,
                    left: 0,
                    height: 228,
                    width: 217,
                    backgroundColor: '#FFF',
                    borderRadius: 16,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView showsVerticalScrollIndicator={false}>
                  {times.map(time => (
                    <HoverableOpacity
                      onPress={() => {
                        setSelectedTime(time)
                        setActiveOption('')
                      }}
                      key={time}
                      style={{
                        justifyContent: 'center',
                        minHeight: 46,
                        paddingHorizontal: 16,
                        paddingVertical: 11,
                      }}
                      hoverStyle={{ backgroundColor: hover }}
                    >
                      <Body primary>{time}</Body>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
          </View>

          <View
            style={{
              flex: 1,
              borderLeftWidth: 1,
              borderLeftColor: bg.primary,
              paddingVertical: 18,
              paddingHorizontal: 16,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                setActiveOption(active =>
                  active === 'meridian' ? '' : 'meridian'
                )
              }
            >
              <AlignedRow justifyContent="space-between">
                <Body primary>{meridian}</Body>
                <WorktribeIcon name="chevron-down" />
              </AlignedRow>
            </TouchableOpacity>

            {activeOption === 'meridian' && (
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 60,
                    left: 0,
                    width: 217,
                    backgroundColor: '#FFF',
                    borderRadius: 16,
                  },
                  shadowStyles,
                ]}
              >
                {meridians.map(meridian => (
                  <HoverableOpacity
                    onPress={() => {
                      setMeridian(meridian)
                      setActiveOption('')
                    }}
                    key={meridian}
                    style={{
                      justifyContent: 'center',
                      minHeight: 46,
                      paddingHorizontal: 16,
                      paddingVertical: 11,
                    }}
                    hoverStyle={{ backgroundColor: hover }}
                  >
                    <Body primary>{meridian}</Body>
                  </HoverableOpacity>
                ))}
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  )
}
