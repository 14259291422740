import React, { useContext } from 'react'
import { View, ScrollView } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import { SmallButton } from '../shared/Button'

const ChangesPanel = ({ editedRowValues, handlePost, setResetValues }) => {
  const {
    typography: { H3, H5, Button, Body },
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 20,
        backgroundColor: '#E95454',
        height: 300,
      }}
    >
      <View style={{ flex: 1 }}>
        <H3 color="#FFF" style={{ marginBottom: 12 }}>
          The following field(s) have been changed:
        </H3>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {Object.entries(editedRowValues).map(([recordid, changes]) => (
            <View style={{ marginVertical: 5 }}>
              <H5 color="#FFF">{recordid}</H5>
              {Object.entries(changes).map(([field, value]) => {
                let { oldValue, newValue } = value

                if (oldValue instanceof Date) {
                  oldValue = oldValue.toDateString()
                } else if (Array.isArray(oldValue)) {
                  oldValue = oldValue
                    .map(value => {
                      return value.properties?.[0].value
                    })
                    .join(', ')
                }

                if (newValue instanceof Date) {
                  newValue = newValue.toDateString()
                } else if (Array.isArray(newValue)) {
                  newValue = newValue
                    .map(value => {
                      return value.properties?.[0].value
                    })
                    .join(', ')
                }

                return (
                  <AlignedRow>
                    <Body color="#FFF">{`${field}: `}</Body>

                    {oldValue && (
                      <Body
                        style={{
                          textDecorationLine: 'line-through',
                          textDecorationStyle: 'solid',
                        }}
                        color="#FFF"
                      >{`${oldValue} `}</Body>
                    )}

                    <Body color="#FFF">{` ${newValue}`}</Body>
                  </AlignedRow>
                )
              })}
            </View>
          ))}
        </ScrollView>
      </View>
      <AlignedRow justifyContent="flex-end" style={{ marginTop: 24 }}>
        <SmallButton onPress={() => setResetValues(true)}>
          <Button color="#1D7A55">Discard</Button>
        </SmallButton>

        <SmallButton style={{ marginLeft: 12 }} primary onPress={handlePost}>
          <Button color="#FFF">Save</Button>
        </SmallButton>
      </AlignedRow>
    </View>
  )
}

export default ChangesPanel
