import { useCallback, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'

import useSocketMethods from './useSocketMethods'
import useSocketListener from './useSocketListener'

const REFERENCE_CLASSES = ['Task']

const useRecordHandler = (
  rClass: string,
  recordId: string,
  requiredSections: string[] = [],
  requiredTab: null | string = null,
  ignoreInitialTab: boolean = false
) => {
  const [record, setRecord] = useState<any>(null)
  const [referenceRecord, setReferenceRecord] = useState(null)
  const [selectedTab, setSelectedTab] = useState<string | null>(null)
  const [notification, setNotification] = useState(null)
  const [error, setError] = useState<string | null>(null)

  const createNotification = notification => {
    setNotification(notification)
  }

  const { record_get } = useSocketMethods()

  const onMessage = useCallback(
    message => {
      const { data, method, status, privatedetail, updatedrecords } =
        JSON.parse(message.data)

      if (status !== 'OK') {
        let errorMessage = ''
        if (status) errorMessage += status
        if (privatedetail) errorMessage += `: ${privatedetail}`

        setError(errorMessage)
      }

      if (!data) return

      switch (method) {
        case 'record_get': {
          const {
            header: { recordid, rclassname },
          } = data

          if (REFERENCE_CLASSES.includes(rclassname)) {
            const { properties } = data

            if (!properties) {
              record_get({
                rclassname,
                recordid,
                includecards: true,
                includeactions: true,
                returnpropertynames: [
                  'Reference Rclassname',
                  'Reference Recordid',
                  'Status',
                ],
                returnsections: [],
              })
            } else {
              const { value: rclassname } = properties['Reference Rclassname']
              const { value: recordid } = properties['Reference Recordid']
              const { value: status } = properties['Status']

              if (!rclassname || !recordid || !status) {
                if (!selectedTab) {
                  const initialTab = data.sections[0].name
                  selectTab(initialTab)
                }

                setRecord(data)
              } else {
                setReferenceRecord({ ...data, status })

                record_get({
                  rclassname,
                  recordid,
                  includecards: true,
                  includeactions: true,
                  returnsections: requiredTab ? [requiredTab] : [selectedTab],
                })
              }
            }
          } else {
            let foundSections = data.sections
            if (requiredSections.length) {
              foundSections = data.sections.filter(section =>
                requiredSections.includes(section.name)
              )
            }

            if (!selectedTab) {
              const initialTab = foundSections[0].name
              selectTab(initialTab)
            }

            setRecord({
              ...data,
              sections: foundSections,
            })
          }
          break
        }
        case 'record_action':
          {
            const { successmessage } = data

            record_get({
              rclassname: rClass,
              recordid: recordId,
              includecards: true,
              includeactions: true,
              returnsections: requiredTab ? [requiredTab] : [selectedTab],
            })

            const shouldNotify = updatedrecords.some(
              ({ rclassname, recordid }) =>
                rclassname === rClass && recordId === recordid
            )

            if (shouldNotify) {
              createNotification({
                message: successmessage,
                color: 'green',
              })
            }
          }
          break
      }
    },
    [selectedTab, recordId, rClass]
  )

  useSocketListener(onMessage)

  // TODO: Maybe delete this if it's no longer required
  // useFocusEffect(
  //   useCallback(() => {
  //     // only fired on initial focus
  //     return

  //     const params: {
  //       rclassname: string
  //       recordid: string
  //       includecards: boolean
  //       includeactions: boolean
  //       returnsections?: string[]
  //     } = {
  //       rclassname: rClass,
  //       recordid: recordId,
  //       includecards: true,
  //       includeactions: true,
  //     }

  //     params['returnsections'] = requiredTab ? [requiredTab] : []

  //     record_get(params)
  //   }, [rClass, recordId, selectedTab, requiredTab])
  // )

  const selectTab = useCallback(
    (tab: string) => {
      const params: {
        rclassname: string
        recordid: string
        includecards: boolean
        includeactions: boolean
        returnsections?: string[]
      } = {
        rclassname: rClass,
        recordid: recordId,
        includecards: true,
        includeactions: true,
      }

      params['returnsections'] = [tab]

      record_get(params)

      setSelectedTab(tab)
    },
    [record_get]
  )

  useEffect(() => {
    async function setInitialTab() {
      let selectedTab = ''
      try {
        const selected = await AsyncStorage.getItem('@selectedTab')
        if (selected) {
          const tabData = JSON.parse(selected)
          if (tabData[recordId]) {
            selectedTab = tabData[recordId]
          }
        }
      } catch (e) {
        console.warn(e)
      }

      selectTab(selectedTab)
    }

    if (!ignoreInitialTab) setInitialTab()
    else selectTab('')
  }, [recordId, ignoreInitialTab])

  useEffect(() => {
    async function storeTab() {
      try {
        let parsedSelectedTab
        const selected = await AsyncStorage.getItem('@selectedTab')
        if (selected) {
          const parsed = JSON.parse(selected)

          parsedSelectedTab = {
            ...parsed,
            [recordId]: selectedTab,
          }
        } else {
          parsedSelectedTab = {
            [recordId]: selectedTab,
          }
        }

        const jsonValue = JSON.stringify(parsedSelectedTab)
        AsyncStorage.setItem('@selectedTab', jsonValue)
      } catch (e) {
        console.log(e)
      }
    }

    if (selectedTab) {
      storeTab()
    }
  }, [selectedTab, recordId])

  return {
    record,
    referenceRecord,
    selectedTab,
    selectTab,
    notification,
    recordError: error,
  }
}

export default useRecordHandler
