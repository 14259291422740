import React, { useContext, useEffect, useState } from 'react'
import {
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  Switch,
  ScrollView,
} from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components/native'
import HoverableOpacity from '../shared/HoverableOpacity'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow } from '../shared/Layout'
import useRecordAutoComplete from '../../hooks/useRecordAutoComplete'

export const Options = ({ field, value, options, setData, required }) => {
  const {
    typography: { Body, Subtitle },
    themeColors: { bg, hover },
  } = useContext(ThemeContext)

  const [selected, setSelected] = useState(value ?? '')

  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    setData(data => {
      return {
        ...data,
        [field]: {
          value: selected,
          valid: required ? !!selected : true,
        },
      }
    })
  }, [selected])

  return (
    <>
      {options.map((option, index) => (
        <TouchableOpacity
          key={option.name}
          onPress={() => {
            setSelected(option.name)
          }}
          style={[
            {
              width: 360,
              padding: 16,
              backgroundColor: bg.primary,
              marginVertical: 8,
              borderRadius: 20,
            },
            selected === index && {
              backgroundColor: hover,
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <WorktribeIcon
              name={
                selected === option.name
                  ? 'check-circle'
                  : 'sign-badge-circle-alternate'
              }
            />
            <View style={{ marginLeft: 16 }}>
              <Subtitle primary>{option.name}</Subtitle>
              <Body>{option.description}</Body>
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </>
  )
}

export const Input = ({
  field,
  value,
  required = false,
  multiline = false,
  setData,
  valid,
}) => {
  const {
    typography: { Label },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const [text, onChangeText] = React.useState(value)
  const [inputFocused, setInputFocused] = useState(false)

  useEffect(() => {
    if (!inputFocused) {
      Keyboard.dismiss()
    }
  }, [inputFocused])

  const [placeholderText, setPlaceholderText] = useState(field)

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : `${field}${required ? '*' : ''}`)
  }, [inputFocused])

  useEffect(() => {
    setData(data => {
      return {
        ...data,
        [field]: {
          value: text,
          valid: required ? !!text.length : true,
        },
      }
    })
  }, [text, field])

  return (
    <View
      style={[
        {
          marginVertical: 8,
          backgroundColor: bg.primary,
          borderRadius: 16,
          paddingHorizontal: 16,
          paddingVertical: 5,
          minHeight: 60,
          justifyContent: 'center',
        },
        multiline && { paddingTop: 15 },
        !valid && {
          backgroundColor: 'rgba(224,137,56, 0.1)',
        },
      ]}
    >
      <View style={multiline && { height: 100 }}>
        {inputFocused || text?.length ? (
          <Label style={multiline && { marginTop: -10 }}>{`${field}${
            required ? '*' : ''
          }`}</Label>
        ) : null}
        <TextInput
          multiline={multiline}
          onChangeText={onChangeText}
          value={text}
          style={[
            {
              fontFamily: 'proxima-nova',
              fontSize: 15,
              color: fonts.primary,
              width: 600,
              paddingVertical: 0,
              minHeight: 24,
            },
            multiline && { height: '100%' },
            Platform.OS === 'web' && { outline: 'none' },
          ]}
          placeholder={placeholderText}
          placeholderTextColor={valid ? '#757885' : '#E08938'}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
      </View>
    </View>
  )
}

export const Rating = ({ field, setData }) => {
  const {
    typography: { Subtitle },
    themeColors: { bg, hover, accents },
  } = useContext(ThemeContext)
  const [selected, setSelected] = useState(null)
  useEffect(() => {
    if (selected === null) return
    setData(data => {
      return {
        ...data,
        [field]: selected,
      }
    })
  }, [selected, field])
  return (
    <AlignedRow>
      {[...Array(10)].map((_, idx) => (
        <TouchableOpacity
          onPress={() => {
            setSelected(idx + 1)
          }}
          style={{
            height: 54,
            width: 46,
            backgroundColor: selected === idx + 1 ? hover : bg.primary,
            borderColor: accents.separator,
            borderWidth: 1,
            borderRadius: 20,
            marginHorizontal: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Subtitle primary>{idx + 1}</Subtitle>
        </TouchableOpacity>
      ))}
    </AlignedRow>
  )
}

export const Document = ({ field, setData }) => {
  const {
    typography: { Body, Label },
    themeColors: { bg },
  } = useContext(ThemeContext)
  return (
    <TouchableOpacity
      onPress={() => {
        setData(data => {
          return {
            ...data,
            [field]: 'test.jpg',
          }
        })
      }}
      style={{
        minWidth: 647,
        height: 358,
        backgroundColor: bg.primary,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        borderWidth: 2,
        borderColor: '#DDE1ED',
        borderStyle: 'dashed',
      }}
    >
      <WorktribeIcon name="upload" />
      <Body style={{ marginTop: 16 }} color="#1D7A55">
        Choose file or drag here
      </Body>
      <Label style={{ marginTop: 4 }}>Maximum file size is 20mb</Label>
    </TouchableOpacity>
  )
}

export const InlineSwitch = ({ value, setData }) => (
  <View
    style={{
      backgroundColor: value ? '#2AAD79' : '#A0A4B8',
      height: 28,
      width: 48,
      borderRadius: 15,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Switch
      activeThumbColor="#FFF"
      trackColor={{ 1: '#2AAD79', 0: '#2AAD79' }}
      onValueChange={setData}
      value={value}
    />
  </View>
)

export const Radio = ({ field, value, options, setData, required }) => {
  const {
    typography: { Button },
    themeColors: { bg, fonts, accents },
  } = useContext(ThemeContext)
  const [selected, setSelected] = useState(value ?? '')

  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    setData(data => {
      return {
        ...data,
        [field]: {
          value: selected,
          valid: required ? !!selected : true,
        },
      }
    })
  }, [selected])

  return (
    <View
      style={{
        // width: 343,
        width: 'max-content',
        maxWidth: '100%',
        backgroundColor: bg.primary,
        borderRadius: 34,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: accents.separator,
      }}
    >
      {options
        .map((option, i) => {
          const lastIndex = options.length - 1

          let borderRadius
          if (i === 0) {
            borderRadius = {
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
            }
          } else if (i === lastIndex) {
            borderRadius = {
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
            }
          }
          return (
            <TouchableOpacity
              key={option}
              onPress={() => setSelected(option)}
              style={[
                {
                  paddingHorizontal: 30,
                  paddingVertical: 9,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 1,
                },
                selected === option && { backgroundColor: '#2AAD79' },
                borderRadius && borderRadius,
              ]}
            >
              <Button color={selected === option ? `#FFF` : fonts.primary}>
                {option}
              </Button>
            </TouchableOpacity>
          )
        })
        .reduce((prev, curr) => [
          prev,
          <View
            key={uuidv4()}
            style={{ width: 1, backgroundColor: accents.separator }}
          />,
          curr,
        ])}
    </View>
  )
}

export const InlineRecord = ({
  field,
  value,
  required,
  rclasses,
  existingValue,
  onChange,
  multiple,
  setData,
}) => {
  const {
    typography: { Subtitle, Caption, H4 },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  const [selectedItems, setSelectedItems] = useState(() =>
    value?.length ? value : []
  )

  useEffect(() => {
    if (setData) {
      setData(data => {
        return {
          ...data,
          [field]: {
            value: selectedItems.map(item => item.recordid),
            valid: required ? !!selectedItems.length : true,
          },
        }
      })
    } else {
      const existingIds = existingValue.current.map(value => value.recordid)
      const newValues = selectedItems.map(value => value.recordid)

      const updated =
        JSON.stringify(existingIds.sort()) !== JSON.stringify(newValues.sort())

      if (updated) {
        onChange({
          property: field,
          method: 'set',
          recordids: newValues,
        })
      }
    }
  }, [selectedItems, required, setData])

  const data = useRecordAutoComplete(rclasses)

  const selectItem = record => {
    if (selectedItems.map(item => item.recordid).includes(record.recordid)) {
      setSelectedItems(items =>
        items.filter(item => item.recordid !== record.recordid)
      )
    } else {
      if (multiple) {
        setSelectedItems(items => [...items, record])
      } else {
        setSelectedItems([record])
      }
    }
  }

  const renderItem = ({ item }) => {
    const isSelected = selectedItems
      .map(record => record.recordid)
      .includes(item.recordid)

    const details = item.properties
      .filter(property => property.type !== 'title')
      .map(property => property.label)
      .filter(property => property)
      .join(', ')

    return (
      <TouchableOpacity
        onPress={() => selectItem(item)}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          paddingVertical: 10,
          backgroundColor: isSelected ? '#F2FCF8' : '',
        }}
      >
        <AlignedRow>
          <InlineSelectImage
            source={{
              uri: `https://zubanubi.com/resources/covers/cover${item.coverid}_thumb@2x.jpg`,
              cache: 'force-cache',
            }}
          />

          <View style={{ marginLeft: 12 }}>
            <Subtitle primary>{item.title}</Subtitle>
            <Caption>{details}</Caption>
          </View>
        </AlignedRow>

        {isSelected ? (
          <WorktribeIcon name="check-2" />
        ) : (
          <WorktribeIcon name="check-empty" />
        )}
      </TouchableOpacity>
    )
  }

  return (
    <FlatList
      style={{
        backgroundColor: bg.primary,
        borderRadius: 16,
        maxWidth: 528,
      }}
      contentContainerStyle={{
        maxHeight: 360,
      }}
      data={data}
      renderItem={renderItem}
      keyExtractor={item => item.recordid}
      ItemSeparatorComponent={() => (
        <View style={{ backgroundColor: accents.separator, height: 1 }} />
      )}
    />
  )
}

export const InlineStatus = ({
  field,
  value,
  handleChange,
  statuses,
  doPostRequest,
}) => {
  const {
    typography: { Body },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        width: 350,
        maxHeight: 228,
        backgroundColor: bg.primary,
        borderRadius: 16,
        paddingVertical: 8,
      }}
    >
      <ScrollView showsVerticalScrollIndicator={true} style={{ flex: 1 }}>
        {statuses.map(status => (
          <HoverableOpacity
            onPress={() => {
              handleChange(status.name)
            }}
            key={status.name}
            style={{
              justifyContent: 'center',
              minHeight: 46,
              paddingHorizontal: 16,
              paddingVertical: 11,
              backgroundColor: value === status.name ? hover : 'transparent',
            }}
            hoverStyle={{ backgroundColor: hover }}
          >
            <AlignedRow>
              <View
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  marginRight: 12,
                  backgroundColor: `#${status.color}`,
                }}
              />
              <Body primary>{status.name}</Body>
            </AlignedRow>
          </HoverableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

const InlineSelectImage = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 14px;
`
