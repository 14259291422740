import { useState, useCallback, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation, useFocusEffect } from '@react-navigation/core'
import useSocketMethods from './useSocketMethods'
import useSocketListener from './useSocketListener'

const useServices = () => {
  const [domains, setDomains] = useState(null)
  const [error, setError] = useState(null)

  const navigation = useNavigation()

  const onMessage = useCallback(message => {
    const { data, method, status } = JSON.parse(message.data)

    if (status !== 'OK') {
      setError(status)
    }

    if (!data) return

    if (method === 'service_list') {
      setDomains(data.domains)
    }
  }, [])

  useSocketListener(onMessage)

  const { service_list } = useSocketMethods()

  useFocusEffect(
    useCallback(() => {
      service_list({
        includedescriptions: true,
      })
    }, [service_list])
  )

  const selectService = useCallback(async service => {
    // clear local storage
    const savedStateString = await AsyncStorage.getItem('@selectedTab')
    const state = savedStateString ? JSON.parse(savedStateString) : undefined

    if (state) {
      delete state[service.recordid]
      AsyncStorage.setItem('@selectedTab', JSON.stringify(state))
    }

    if (service.rclassname !== '' && service.recordid !== '') {
      navigation.navigate('Record', {
        rClass: service.rclassname.toLowerCase().replaceAll(' ', '_'),
        recordId: service.recordid,
      })
    }
  }, [])

  return {
    domains,
    selectService,
    error,
  }
}

export default useServices
