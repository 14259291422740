import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import manifest from '../../../app.json'
import { ThemeContext } from '../../contexts/ThemeContext'

const { version } = manifest.expo

const Version = ({ textColor = '#fff' }) => {
  const {
    typography: { Label },
  } = useContext(ThemeContext)
  return (
    <View style={styles.versionContainer}>
      <Label color={textColor}>Version {version}</Label>
    </View>
  )
}

export default Version

const styles = StyleSheet.create({
  versionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5,
  },
})
