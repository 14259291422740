import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'

import Services from '../../components/landscape/ContentPanel/Services'

interface ServicesScreenProps {}

const ServicesScreen: React.FC<ServicesScreenProps> = ({}) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Content style={{ backgroundColor: bg.primary }}>
      <Services />
    </Content>
  )
}

export default withLayout(ServicesScreen)

const Content = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`
