import React, { useContext, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'
import { CenteredView } from '../../components/shared/Layout'
import Searchatron from '../../components/landscape/Searchatron'
import useRecordHandler from '../../hooks/useRecordHandler'

import Notification from '../../components/shared/Notification'
import Details from '../../components/landscape/Properties'
import RecordHeader from '../../components/landscape/RecordHeader'
import { useAuth } from '../../contexts/AuthContext'
import RecordView from '../../components/landscape/RecordView'

interface SettingsScreenProps {}

const SETTINGS_CLASS = 'Person'

const SettingsScreen: React.FC<SettingsScreenProps> = () => {
  const [showSearch, setShowSearch] = useState(false)

  const { user } = useAuth()

  const { record, notification, recordError, selectedTab, selectTab } =
    useRecordHandler(
      SETTINGS_CLASS,
      user!.userid,
      ['Settings'],
      'Settings',
      true
    )

  const {
    typography: { Subtitle },
  } = useContext(ThemeContext)

  if (!record) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      <RecordHeader
        setShowSearch={setShowSearch}
        record={record}
        selectedTab={selectedTab}
        setSelectedTab={selectTab}
      />

      <Container style={{ alignItems: 'center' }}>
        {!!recordError && <Subtitle color="red">{recordError}</Subtitle>}

        <Content
          style={
            ['cover', 'widegrey'].includes(record?.header.style) && {
              maxWidth: 1120,
              paddingHorizontal: 0,
            }
          }
        >
          <RecordView
            recordId={user!.userid}
            rClass={SETTINGS_CLASS}
            section={record.sections.find(
              section => section.name === selectedTab
            )}
            selectedTab={selectedTab}
          />
        </Content>
      </Container>

      {notification?.message && <Notification notification={notification} />}
    </>
  )
}

export default withLayout(SettingsScreen)

const Container = styled.View`
  flex: 1;
`

const Content = styled.View`
  flex: 1;
  width: 100%;
`
