import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import CommentInput from './CommentInput'
import UsersTyping from '../../components/shared/UsersTyping'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useComments from '../../hooks/useComments'
import Comments from './Comments'
import { StyleSheet, Switch, Text, View } from 'react-native'
import { AlignedRow } from './Layout'
import { Modalize } from 'react-native-modalize'

const CommentsContainer = styled.View`
  position: relative;
  flex: 1;
`

const Chat = ({ record, modalRef, commentContainerStyle }) => {
  const {
    comment,
    comments,
    setComments,
    noMoreComments,
    fetchMore,
    commentList,
    scrollY,
    setScrollY,
    sendComment,
    commentChanged,
    togglePushNotifications,
  } = useComments(record)

  const { bottom } = useSafeAreaInsets()

  const groupedComments = useMemo(() => groupBySender(comments), [comments])

  return (
    <>
      <CommentsContainer>
        <Comments
          record={record}
          comments={groupedComments}
          setComments={setComments}
          handleLoadMore={fetchMore}
          noMoreMessages={noMoreComments}
          commentList={commentList}
          setScrollY={setScrollY}
          commentContainerStyle={commentContainerStyle}
        />
        <UsersTyping recordId={record.id} scrollY={scrollY} />
      </CommentsContainer>

      <CommentInput
        containerStyle={
          record.rclass !== 'conversation' && { paddingBottom: bottom }
        }
        comment={comment}
        sendComment={sendComment}
        commentChanged={commentChanged}
        placeholder="Add Message"
      />

      <Modalize
        ref={modalRef}
        adjustToContentHeight={true}
        withHandle={true}
        handlePosition="inside"
        modalStyle={styles.modalContainer}
        childrenStyle={styles.modalChildren}
      >
        <View style={styles.modalChildren}>
          <AlignedRow justifyContent="space-between">
            <Text style={styles.menuItemText}>Push notifications</Text>
            <Switch
              onValueChange={togglePushNotifications}
              value={record.push_notifications_enabled}
            />
          </AlignedRow>
        </View>
      </Modalize>
    </>
  )
}

export default Chat

const groupBySender = comments => {
  const groupedComments = comments.reduce((grouped, comment) => {
    if (typeof comment === 'string') {
      grouped.unshift(comment)
    } else if (grouped.length === 0) {
      grouped.unshift([comment])
    } else if (grouped[0][0].user?.id === comment.user?.id) {
      grouped[0].push(comment)
    } else {
      grouped.unshift([comment])
    }
    return grouped
  }, [])

  return groupedComments.reverse()
}

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: '#EBECF2',
    paddingHorizontal: 20,
  },
  modalChildren: {
    marginTop: 20,
    marginBottom: 20,
  },
  menuItemText: {
    fontSize: 17,
    fontFamily: 'proxima-nova-semibold',
  },
})
