import React, { useCallback, useContext } from 'react'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import Badge from './Badge'
import Avatar from './Avatar'
import { ThemeContext } from '../../contexts/ThemeContext'
import useTimeSince from '../../hooks/useTimeSince'
import { AuthContext, useAuth } from '../../contexts/AuthContext'
import HoverableOpacity from './HoverableOpacity'
import { StyleSheet } from 'react-native'
import WorktribeIcon from './WorktribeIcon'
import * as RootNavigation from '../../services/RootNavigation'

const CardContent = styled.View`
  flex: 1;
  margin-left: 12px;
`

const CardHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CardBody = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const LandscapeConversationCard = ({ conversation }) => {
  const {
    typography: { H5, Caption, Body, Label },
    themeColors: { hover, bg },
  } = useContext(ThemeContext)
  const { selectedRecord } = useSelector(state => state.records)
  const { usersTyping } = useSelector(state => state.records)

  const isGroupConversation =
    conversation.users_by_relationship.PARTICIPANT.length > 2
  const { getFormattedShortDate } = useTimeSince()
  const { user } = useAuth()

  const participants = conversation?.users_by_relationship?.PARTICIPANT.filter(
    participant => {
      return participant.id !== user?.userid
    }
  )

  const getLatestMessage = useCallback(() => {
    if (!conversation.latest_comment) {
      return `No messages yet`
    }

    const {
      latest_comment: { user, is_change, text },
    } = conversation

    if (isGroupConversation && user && !is_change) {
      return `${user.name}: ${text}`
    }

    return text
  }, [conversation, isGroupConversation])

  return (
    <HoverableOpacity
      hoverStyle={{ backgroundColor: hover }}
      style={[
        selectedRecord?.id === conversation.id && {
          backgroundColor: bg.primary,
        },
        styles.card,
      ]}
      onPress={() =>
        RootNavigation.navigate('Messages', {
          recordId: conversation.id,
          rClass: conversation.rclass,
        })
      }
      activeOpacity={1}
    >
      {participants.length > 0 && (
        <Avatar
          size={48}
          radius={18}
          group={isGroupConversation}
          userId={participants[0].id}
        />
      )}
      <CardContent>
        <CardHeader>
          <H5 primary numberOfLines={1}>
            {conversation.fields.conversation_name
              ? conversation.fields.conversation_name
              : participants.map(participant => participant.name).join(', ')}
          </H5>
          <Caption>
            {conversation.latest_comment
              ? getFormattedShortDate(
                  new Date(conversation.latest_comment.created_at),
                  user?.preferred_lang
                )
              : getFormattedShortDate(
                  new Date(conversation.created_at),
                  user?.preferred_lang
                )}
          </Caption>
        </CardHeader>

        <CardBody>
          {usersTyping?.[conversation.id]?.filter(
            user => user !== authenticatedUser.name
          ).length ? (
            <Body color="#2aad79" numberOfLines={1}>
              {isGroupConversation
                ? `${usersTyping[conversation.id].join(', ')} ${
                    usersTyping[conversation.id].length > 1
                      ? 'are typing'
                      : 'is typing'
                  }`
                : 'Typing...'}
            </Body>
          ) : (
            <>
              {conversation.unseen_by_me > 0 &&
              conversation.push_notifications_enabled ? (
                <>
                  <H5 primary numberOfLines={1}>
                    {getLatestMessage()}
                  </H5>
                  {selectedRecord?.id !== conversation.id && (
                    <Badge count={conversation.unseen_by_me} />
                  )}
                </>
              ) : (
                <>
                  <Body numberOfLines={1}>{getLatestMessage()}</Body>
                  {!conversation.push_notifications_enabled && (
                    <WorktribeIcon name="muted" />
                  )}
                </>
              )}
            </>
          )}
        </CardBody>
        <Label>Department of Microbiology</Label>
      </CardContent>
    </HoverableOpacity>
  )
}

export default React.memo(LandscapeConversationCard)

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
})
