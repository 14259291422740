import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components/native'
import { APIContext } from '../../contexts/APIContext'
import Avatar from '../../components/shared/Avatar'
import { useDispatch } from 'react-redux'
import { TouchableOpacity, TextInput, Platform } from 'react-native'
import useOrientation from '../../hooks/useOrientation'
import { updateRecords } from '../../store/actions/records'

const Container = styled.SafeAreaView`
  flex: 1;
  background-color: #ebecf2;
`

const Body = styled.ScrollView`
  flex: 1;
  padding: 0 16px;
`

const Header = styled.View`
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`

const HeaderItem = styled.View`
  flex: ${props => props.flex ?? 1};
  align-items: ${props => props.align};
`

const HeaderTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: #0e1012;
`

const NextButton = styled.TouchableOpacity`
  padding: 4px 14px;
  border-radius: 30px;
  background-color: ${props => (props.disabled ? '#A0A4B8' : '#2AAD79')};
`

const NextButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const PhotoButton = styled.TouchableOpacity`
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #2aad79;
`

const PhotoButtonText = styled.Text`
  color: ${props => (props.primary ? '#FFFFFF' : '#2AAD79')};
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const SubTitle = styled.Text`
  font-family: proxima-nova-semibold;
  color: #757885;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`

const SelectedUsers = styled.View`
  flex: 1;
  flex-direction: row;
  min-height: 110px;
  padding: 5px 0;
  margin-top: 18px;
  flex-wrap: wrap;
`

const SelectedUser = styled.TouchableOpacity`
  width: 75px;
  margin-right: 10px;
  align-items: center;
  height: 100px;
`

const SelectedUserName = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #0e1012;
  text-align: center;
  margin-top: 4px;
`

const ConversationPhotoWrapper = styled.View`
  align-items: center;
`

const ConversationPhoto = styled.Image`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  margin: 20px 0;
`

const GroupNameWrapper = styled.View`
  margin-top: 30px;
`

const GroupNameInput = styled.View`
  height: 60px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 8px;
  padding: 18px 16px;
  font-family: proxima-nova;
  font-size: 17px;
  justify-content: center;
`

const InputHeading = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const MembersWrapper = styled.View`
  margin-top: 37px;
`

const BackArrowImage = styled.Image`
  width: 18px;
  height: 18px;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const GroupDetailsScreen = ({ navigation, route }) => {
  const [groupName, setGroupName] = useState('')
  const [inputFocused, setInputFocused] = useState(false)
  const [placeholderText, setPlaceholderText] = useState('Group name')
  const [image, setImage] = useState('G02AR7pa_Lo')
  const [isCreating, setIsCreating] = useState(false)

  const { handleItemPress } = route.params ?? {}

  const { members } = route.params
  const dispatch = useDispatch()
  const { createRecord } = useContext(APIContext)
  const isLandscape = useOrientation()

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : 'Group name')
  }, [inputFocused])

  const changeImage = () =>
    setImage(imageFeed[Math.floor(Math.random() * imageFeed.length)])

  const create = async () => {
    try {
      setIsCreating(true)

      const response = await createRecord(
        'conversation',
        members.map(user => user.id),
        groupName
      )

      const { record } = response.data

      dispatch(
        updateRecords({
          ...record,
          unseen_by_me: 0,
          looked_at: new Date(),
        })
      )

      if (isLandscape && handleItemPress) {
        handleItemPress({ record: record.id })
        navigation.navigate('Home')
      } else {
        navigation.navigate('Messages')
        navigation.navigate('Message', { conversationId: record.id })
      }
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <Container>
      <Header>
        <HeaderItem align="flex-start">
          <TouchableOpacity onPress={() => navigation.pop()}>
            <BackArrowImage
              source={require('../../../assets/icons/arrow-back.png')}
            />
          </TouchableOpacity>
        </HeaderItem>
        <HeaderItem flex={2} align="center">
          <HeaderTitle>Group details</HeaderTitle>
        </HeaderItem>
        <HeaderItem align="flex-end">
          <NextButton disabled={isCreating} onPress={create}>
            <NextButtonText>Create</NextButtonText>
          </NextButton>
        </HeaderItem>
      </Header>

      <Body>
        <ConversationPhotoWrapper>
          <ConversationPhoto
            source={{
              uri: `https://source.unsplash.com/${image}/`,
              cache: 'force-cache',
            }}
          />
          <PhotoButton onPress={changeImage}>
            <PhotoButtonText>Change photo</PhotoButtonText>
          </PhotoButton>
        </ConversationPhotoWrapper>

        <GroupNameWrapper>
          <SubTitle>Name your group</SubTitle>
          <GroupNameInput>
            {inputFocused && <InputHeading>Group name</InputHeading>}
            <TextInput
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              placeholder={placeholderText}
              placeholderTextColor="#757885"
              onChangeText={setGroupName}
              value={groupName}
              style={[
                {
                  fontFamily: 'proxima-nova',
                  fontSize: 17,
                  color: '#0E1012',
                  height: 24,
                },
                Platform.OS === 'web' ? { outlineWidth: 0 } : {},
              ]}
            />
          </GroupNameInput>
        </GroupNameWrapper>

        <MembersWrapper>
          <SubTitle>{`${members.length} ${
            members.length > 1 ? 'members' : 'member'
          }`}</SubTitle>

          {members.length ? (
            <SelectedUsers>
              {members.map(user => (
                <SelectedUser key={user.id}>
                  <Avatar userId={user.id} size={48} radius={16} />
                  <SelectedUserName>{user.name}</SelectedUserName>
                </SelectedUser>
              ))}
            </SelectedUsers>
          ) : null}
        </MembersWrapper>
      </Body>
    </Container>
  )
}

export default GroupDetailsScreen
