import React, { useContext, useEffect, useState } from 'react'
import { ScrollView, Text, View, StyleSheet } from 'react-native'
import ProfileCard from './ProfileCard'
import { APIContext } from '../../contexts/APIContext'
import styled from 'styled-components/native'

const Button = styled.TouchableOpacity`
  background: ${props => (props.primary ? '#2AAD79' : '#FFFFFF')};
  margin: 6px 0;
  padding: 10px 36px;
  border-radius: 30px;
  border-width: 1px;
  border-color: #2aad79;
`

const ButtonText = styled.Text`
  color: ${props => (props.primary ? '#FFFFFF' : '#2AAD79')};
  font-family: proxima-nova-semibold;
  font-size: 15px;
`

const Overview = () => {
  const [users, setUsers] = useState([])
  const { getUsers } = useContext(APIContext)

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await getUsers()
        setUsers(response.data)
      } catch (e) {
        console.log(e)
      }
    }
    loadUsers()
  }, [])

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.headingText}>Change status</Text>
      <View style={styles.card}>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button primary>
            <ButtonText primary>Submit for approval</ButtonText>
          </Button>

          <Button>
            <ButtonText>Request review</ButtonText>
          </Button>
        </View>
      </View>

      <Text style={styles.headingText}>Overview</Text>
      <View style={styles.card}>
        <Text style={styles.infoText}>
          Graham has worked in a variety of mental health settings, including
          posts as a matron and service manager in acute inpatient care,
          community mental health, perinatal mental health and rehabilitation
          and recovery settings.
        </Text>
      </View>

      <Text style={styles.headingText}>Assessments</Text>
      <View style={styles.card}>
        <Text style={styles.infoText}>
          Trauma, narcissistic abuse, mental health nursing, mental health
          nursing education, serious incident processes.
        </Text>

        <View
          style={{
            height: 48,
            borderRadius: 50,
            marginTop: 37,
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '30%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#E58B37',
              borderTopLeftRadius: 50,
              borderBottomLeftRadius: 50,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 16,
                lineHeight: 22,
                color: '#FFFFFF',
              }}
            >
              30%
            </Text>
          </View>
          <View
            style={{
              width: '70%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#35C88D',
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 16,
                lineHeight: 22,
                color: '#FFFFFF',
              }}
            >
              70%
            </Text>
          </View>
        </View>
        <Text
          style={{
            textAlign: 'right',
            fontWeight: '600',
            fontSize: 16,
            lineHeight: 22,
            color: '#2AAD79',
            marginTop: 17,
          }}
        >
          More...
        </Text>
      </View>

      <Text style={styles.headingText}>Teaching and Learning</Text>
      <View style={styles.card}>
        <Text style={[styles.listText, { fontWeight: '700' }]}>
          BSc Mental Health Nursing programme:
        </Text>
        <Text style={styles.listText}>
          • Module Leader for: 500807 - The Craft of Mental Health
        </Text>
        <Text style={styles.listText}>• Nursing Across the Lifespan (1)</Text>
        <Text style={styles.listText}>• 400599 - Practice 3</Text>
        <Text style={styles.listText}>
          • Mental Health Lead for 400591 - Skills for Practice.
        </Text>

        <Text style={[styles.listText, { fontWeight: '700', marginTop: 20 }]}>
          Vickie also teaches on the following modules for this programme:
        </Text>
        <Text style={styles.listText}>
          • 500809 - The Craft of Mental Health Nursing Across the Lifespan (2)
        </Text>
        <Text style={styles.listText}>
          • 400598 - Values Based Mental Health Nursing
        </Text>
        <Text style={styles.listText}>• 601249 - Professional Role</Text>

        <Text style={[styles.listText, { fontWeight: '700', marginTop: 20 }]}>
          Vickie additionally teaches on the following programmes:
        </Text>
        <Text style={styles.listText}>
          • Paramedic Science (500607 - Clinical Practice Education 2)
        </Text>
        <Text style={styles.listText}>
          • BSc Midwifery (601505 - Becoming a Midwife)
        </Text>
        <Text style={styles.listText}>
          • Associate Practitioner Programme (47090 - Effective Communication)
        </Text>
        <Text style={styles.listText}>
          • BA Social Work (400850 - Becoming a research minded practitioner)
        </Text>
      </View>

      <Text style={styles.headingText}>People</Text>
      {users.map((user, idx) => (
        <ProfileCard key={idx} {...{ user }} />
      ))}
    </ScrollView>
  )
}

export default Overview

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: '#EBECF1',
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 16,
    marginTop: 16,
  },
  headingText: {
    fontWeight: '600',
    fontSize: 22,
    lineHeight: 27,
    color: '#0E1012',
    marginTop: 40,
  },
  infoCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 16,
    marginTop: 20,
  },
  infoText: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 24,
    color: '#0E1012',
  },
  listText: {
    fontWeight: '400',
    fontSize: 17,
    lineHeight: 24,
    color: '#0E1012',
  },
})
