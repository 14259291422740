import React from 'react'
import { Image, SafeAreaView, StyleSheet } from 'react-native'

const SplashContainer = ({ children, justifyContent }) => {
  return (
    <SafeAreaView style={[styles.container, { justifyContent }]}>
      <Image
        style={styles.imageTopRight}
        source={require('../../../assets/group1.png')}
      />
      <Image
        style={styles.imageBottomLeft}
        source={require('../../../assets/group2.png')}
      />

      {children}
    </SafeAreaView>
  )
}

export default SplashContainer

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1B283F',
    alignItems: 'center',
  },
  imageTopRight: {
    position: 'absolute',
    right: 0,
    top: 11,
  },
  imageBottomLeft: {
    position: 'absolute',
    left: 0,
    bottom: 11,
  },
})
