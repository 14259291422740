import { useEffect, useState } from 'react'
import axios from 'axios'

import { useAuth } from '../contexts/AuthContext'
import useZubanubi from './useZubanubi'

const useProperties = (item: any, rclass: string, fields: string[]) => {
  const [properties, setProperties] = useState(null)

  const getZubanubiRepo = useZubanubi()
  const { user } = useAuth()

  useEffect(() => {
    ;(async function () {
      try {
        const zubanubiRepo = await getZubanubiRepo()

        const response = await axios.get(`https://www.zubanubi.com/zapi.php`, {
          params: {
            method: 'record_get',
            wtauth: true,
            wtenv: zubanubiRepo,
            debugtoken: `${user?.userid}.quirkafleeg`,
            recordid: item.recordid,
            rclassname: rclass,
            includecards: true,
            includeactions: true,
            includepropertyinfo: true,
            returnsections: ['Structure'],
            returnpropertynames: JSON.stringify(fields),
          },
        })

        const {
          data: {
            data: { properties },
          },
        } = response

        const result = Object.entries(properties).reduce(
          (acc, [name, { value, records }]) => {
            // console.log(value)
            acc[name] = value ?? records
            acc['type'] = rclass

            return acc
          },
          {}
        )

        setProperties(result)
      } catch (e) {
        console.warn(e)
      }
    })()
  }, [item, user?.userid])

  return properties
}

export default useProperties
