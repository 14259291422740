import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { SmallButton } from '../shared/Button'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const HOURS = [
  {
    type: 'Lectures',
    length: '25 hours',
  },
  {
    type: 'Seminars',
    length: '25 hours',
  },
  {
    type: 'Seminars',
    length: '25 hours',
  },
  {
    type: 'Final exam',
    length: '25 hours',
  },
]

const HourRow = ({ hour }) => {
  const {
    typography: { Body, H5 },
  } = useContext(ThemeContext)
  return (
    <Row
      style={{
        paddingVertical: 21,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        marginBottom: 16,
      }}
    >
      <Col size={3}>
        <H5 color="#0E1012">{hour.type}</H5>
      </Col>
      <Col size={1}>
        <Body color="#0E1012">{hour.length}</Body>
      </Col>
    </Row>
  )
}

const Hours = ({ record }) => {
  const {
    typography: { H3, H5, Button, Body },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        <AlignedRow justifyContent="space-between">
          <AlignedRow>
            <H3>Hours</H3>
          </AlignedRow>

          <SmallButton>
            <Button color="#1D7A55">Add hours</Button>
          </SmallButton>
        </AlignedRow>

        <Grid
          style={{
            marginTop: 20,
            marginBottom: 36,
            flexDirection: 'column',
          }}
        >
          <Row
            style={{
              paddingVertical: 14,
              paddingHorizontal: 24,
            }}
          >
            <Col size={3}>
              <H5 color="#0E1012">Type</H5>
            </Col>
            <Col size={1}>
              <H5 color="#0E1012">Length</H5>
            </Col>
          </Row>

          {HOURS.map((hour, i) => (
            <HourRow key={`${hour.type}-${i}`} hour={hour} />
          ))}
        </Grid>
      </Content>
    </Container>
  )
}

export default Hours
