import {
  differenceInDays,
  differenceInMinutes,
  format,
  isToday,
  isThisYear,
  isYesterday,
} from 'date-fns'
import { enGB, cy } from 'date-fns/locale'

const useTimeSince = () => {
  const getFormattedShortDate = (date, preferred_lang) => {
    let locale = enGB
    if (preferred_lang == 'cy') locale = cy

    // Check for last two minutes...
    const now = new Date()
    const howManyMinutes = differenceInMinutes(now, date)

    // In last 2 minutes
    if (howManyMinutes < 2)
      return preferred_lang == 'cy' ? 'Dim ond nawr' : 'Just now'
    // Last 60 minutes (Welsh and English for minute starts with m, so no need to do a translation here)
    else if (howManyMinutes < 60) return howManyMinutes.toString() + 'm'
    // Today, render '00:00 am' to '11:59 pm', localised
    else if (isToday(date)) return format(date, 'hh:mm a', { locale: locale })
    // Last 3 days, render Mon-Sun, localised
    else if (differenceInDays(new Date(), date) < 3)
      return format(date, 'EEE', { locale: locale })
    // if this year, return date without year, localised
    else if (isThisYear(date)) return format(date, 'd MMM', { locale: locale })
    // otherwise, return date with year, localised
    else return format(date, 'd MMM yy', { locale: locale })
  }

  const getFormattedTime = (date, preferred_lang) => {
    let locale = enGB
    if (preferred_lang == 'cy') locale = cy

    // Check for last two minutes...
    const now = new Date()

    if (differenceInMinutes(now, date) < 2)
      return preferred_lang == 'cy' ? 'Dim ond nawr' : 'Just now'
    return format(date, 'hh:mm a', { locale: locale })
  }

  const getFormattedLongDate = (date, preferred_lang) => {
    let locale = enGB
    if (preferred_lang == 'cy') locale = cy

    // Today
    if (isToday(date)) return preferred_lang == 'cy' ? 'Heddiw' : 'Today'
    // Yesterday
    else if (isYesterday(date))
      return preferred_lang == 'cy' ? 'Ddoe' : 'Yesterday'
    // Last 3 days
    else if (differenceInDays(new Date(), date) < 3)
      return format(date, 'EEEE', { locale: locale })
    // This year
    else if (isThisYear(date)) return format(date, 'd MMMM', { locale: locale })
    // Any other date
    return format(date, 'd MMMM yyyy', { locale: locale })
  }

  return {
    getFormattedShortDate,
    getFormattedLongDate,
    getFormattedTime,
  }
}

export default useTimeSince
