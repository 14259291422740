import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import styled from 'styled-components/native'
import {
  ActivityIndicator,
  Animated,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow, CenteredView } from '../shared/Layout'
import { BigButton } from '../shared/Button'
import * as Property from './PropertyEdit'
import InlineSelect from './controls/InlineSelect'
import InlineText from './controls/InlineText'
import InlineFile from './controls/InlineFile'

import { Col, Row, Grid } from 'react-native-easy-grid'

const stages = [
  {
    title: 'What type of contract do you need?',
    properties: [
      {
        type: 'select',
        renderer: 'radiolist',
        name: 'Contract Type',
        options: [
          {
            name: 'Collaboration Agreement',
            // description: 'Explanation',
          },
          {
            name: 'Commercially Led Clinical Trial',
            // description: 'Explanation',
          },
          {
            name: 'Confidentiality or Non-Disclosure Agreement (CDA or NDA)',
            // description: 'Explanation',
          },
          {
            name: 'Material Transfer Agreement (MTA)',
            // description: 'Explanation',
          },
          {
            name: 'Research Contract',
            // description: 'Explanation',
          },
        ],
      },
    ],
  },
  {
    title: 'Tell us a bit about your contract',
    properties: [
      {
        type: 'text',
        name: 'Title',
        size: '',
      },
      {
        type: 'text',
        name: 'Project Summary',
        size: 'medium',
      },
    ],
  },
  {
    title: 'Who is involved in this contract?',
    properties: [
      {
        type: 'select',
        renderer: 'radiolist',
        multiple: true,
        name: 'People Involved',
        options: [
          {
            name: 'Esther Howard',
            description: 'Professor',
          },
          {
            name: 'Albert Flores',
            description: 'Academic',
          },
          {
            name: 'Brooklyn Simmons',
            description: 'Academic',
          },
          {
            name: 'Robert Fox',
            description: 'Academic',
          },
        ],
      },
    ],
  },
  {
    title: 'Attach the necessary documents',
    properties: [
      {
        name: 'File',
        type: 'file',
      },
    ],
  },
]

const PANEL_WIDTH = 700

const Wizard = ({ config }) => {
  const [data, setData] = useState(() => {
    return {
      Title: '',
      'Project Summary': '',
      'Contract Type': '',
      'People Involved': '',
      File: '',
    }
  })

  const [stage, setStage] = useState(1)
  const [percentageComplete, setPercentageComplete] = useState(0)
  const [showSummary, setShowSummary] = useState(false)
  const [finished, setFinished] = useState(false)
  const loaderValue = useRef(new Animated.Value(0)).current
  const opacityValue = useRef(new Animated.Value(0)).current

  useEffect(() => {
    setPercentageComplete(((stage - 1) / stages.length) * 100)
    if (stage > 1) {
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(opacityValue, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
      }).start()
    }
  }, [stage])
  useEffect(() => {
    if (finished) {
      setPercentageComplete(100)
      setStage(stages.length)
      Animated.timing(loaderValue, {
        toValue: 100,
        duration: 250,
        useNativeDriver: true,
      }).start()
    }
  }, [finished])

  const scrollRef = useRef()

  const nextStage = () => {
    const percentage = (stage / stages.length) * 100

    Animated.timing(loaderValue, {
      toValue: percentage,
      duration: 250,
      useNativeDriver: false,
    }).start(() => {
      setStage(stage => stage + 1)
    })

    scrollRef.current?.scrollTo({
      x: stage * PANEL_WIDTH,
      animation: true,
    })
  }

  const previousStage = () => {
    const percentage = ((stage - 2) / stages.length) * 100
    Animated.timing(loaderValue, {
      toValue: percentage,
      duration: 250,
      useNativeDriver: false,
    }).start(() => {
      setStage(stage => stage - 1)
    })

    scrollRef.current?.scrollTo({
      x: (stage - 2) * PANEL_WIDTH,
      animation: true,
    })
  }

  const width = loaderValue.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp',
  })

  const opacity = opacityValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  })

  const {
    typography: { H1, H3, Body, Button, Subtitle },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  return (
    <WizardHero style={{ backgroundColor: bg.card }}>
      <WizardHeader style={{ borderBottomColor: accents.separator }}>
        <Animated.View
          style={{
            position: 'absolute',
            bottom: 0,
            height: 8,
            width,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: '#2AAD79',
          }}
        />

        <Animated.View style={{ alignItems: 'flex-start', flex: 1, opacity }}>
          <AlignedRow>
            <BackButton onPress={previousStage}>
              <WorktribeIcon name="keyboard-arrow-right" />
            </BackButton>

            <TouchableOpacity onPress={previousStage}>
              <Subtitle style={{ marginLeft: 12 }} primary>
                Back
              </Subtitle>
            </TouchableOpacity>
          </AlignedRow>
        </Animated.View>

        <View style={{ alignItems: 'center', flex: 3 }}>
          <H3 primary>Request a contract</H3>
        </View>

        <View style={{ alignItems: 'flex-end', flex: 1 }}>
          <AlignedRow>
            <Subtitle color="#757885">{`${stage}/${
              stages.length + 1
            }`}</Subtitle>
          </AlignedRow>
        </View>
      </WizardHeader>

      <CenteredView style={{ paddingVertical: 36 }}>
        <ScrollView
          scrollEnabled={true}
          ref={scrollRef}
          contentContainerStyle={{
            width: PANEL_WIDTH,
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={16}
        >
          {stages.map(({ title, properties }) => (
            <CenteredView style={{ width: PANEL_WIDTH }}>
              <H1 primary style={{ marginBottom: 32 }}>
                {title}
              </H1>

              {properties?.map(property => {
                const handleChange = value => {
                  const { name } = property

                  setData(data => ({
                    ...data,
                    [name]: value,
                  }))
                }

                let input
                switch (property.type) {
                  case 'select':
                  case 'record':
                    input = (
                      <InlineSelect
                        item={{
                          property,
                        }}
                        value={{
                          value: [],
                        }}
                        onChange={handleChange}
                        optionBackgroundColor={bg.primary}
                      />
                    )
                    break
                  case 'text': {
                    input = (
                      <View style={{ width: '100%' }}>
                        <InlineText
                          item={{
                            property,
                          }}
                          value={{
                            value: '',
                          }}
                          onChange={handleChange}
                          inputBackgroundColor={bg.primary}
                        />
                      </View>
                    )
                    break
                  }
                  case 'file': {
                    input = (
                      <InlineFile
                        onChange={handleChange}
                        value={data?.['File']}
                      />
                    )
                    break
                  }
                  default:
                    input = null
                }

                return (
                  <View
                    style={{
                      width: '100%',
                      marginVertical: 8,
                      alignItems: 'center',
                    }}
                  >
                    {input}
                  </View>
                )
              })}

              <BigButton
                onPress={nextStage}
                style={{ width: 335, marginTop: 36 }}
                primary
              >
                <AlignedRow>
                  <Button style={{ marginRight: 8 }} color="#FFF">
                    {stage === stages.length ? 'Request contract' : 'Continue'}
                  </Button>

                  <WorktribeIcon name="keyboard-arrow-right-white" />
                </AlignedRow>
              </BigButton>
            </CenteredView>
          ))}

          <CenteredView style={{ width: PANEL_WIDTH }}>
            <AlignedRow
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <WorktribeIcon name="celebration" />

              <H1 primary style={{ marginVertical: 24 }}>
                Contract has been requested
              </H1>

              <Body>
                Congratulations, your contract has been requested! The next step
                is to Return the contract for review. Would you like to do that
                now?
              </Body>

              {Object.entries(data).length > 0 && (
                <Grid
                  style={{
                    backgroundColor: '#FFF',
                    marginVertical: 48,
                    width: '100%',
                    borderWidth: 1,
                    borderColor: accents.separator,
                    borderRadius: 16,
                  }}
                >
                  {Object.entries(data)
                    .map(([name, value], index) => (
                      <Row>
                        <Col
                          style={{
                            paddingVertical: 14,
                            paddingHorizontal: 24,
                            borderRightWidth: 1,
                            borderRightColor: accents.separator,
                            flex: 1,
                          }}
                        >
                          <Subtitle primary>{name}</Subtitle>
                        </Col>
                        <Col
                          style={{
                            paddingVertical: 14,
                            paddingHorizontal: 24,
                            flex: 2,
                          }}
                        >
                          <Body numberOfLines={1} primary>
                            {Array.isArray(value)
                              ? value.map(v => v.name).join(', ')
                              : value}
                          </Body>
                        </Col>
                      </Row>
                    ))
                    .reduce((prev, curr, idx) => [
                      prev,
                      <View
                        key={idx}
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: accents.separator,
                        }}
                      />,
                      curr,
                    ])}
                </Grid>
              )}

              <View style={{ minWidth: 335 }}>
                <BigButton style={{ marginBottom: 24 }} primary>
                  <Button color="#FFF">Return Request</Button>
                </BigButton>

                <BigButton>
                  <Button color="#1D7A55">Skip for now</Button>
                </BigButton>
              </View>
            </AlignedRow>
          </CenteredView>
        </ScrollView>
      </CenteredView>
    </WizardHero>
  )
}

const WizardHero = styled.View`
  flex: 1;
  border-radius: 30px;
  margin: 0 12px;
`

const WizardHeader = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  padding: 24px 0;
  margin: 0 24px;
  border-bottom-width: 1px;
`

const BackButton = styled.TouchableOpacity`
  height: 36px;
  width: 52px;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  border-color: #dde1ed;
  border-width: 1px;
`

export default Wizard
