import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext, useAuth } from '../contexts/AuthContext'
import { APIContext } from '../contexts/APIContext'
import { compareAsc, formatDate, parseISO } from 'date-fns'
import useTimeSince from '../hooks/useTimeSince'

const usePagedComments = record => {
  const { user } = useAuth()

  const recordId = record.id

  const [lowestId, setLowestId] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [noMoreComments, setNoMoreComments] = useState(false)
  const [comments, setComments] = useState([])

  const { getRecordComments } = useContext(APIContext)

  const { getFormattedLongDate } = useTimeSince()

  const fetchMore = useCallback(() => setShouldFetch(true), [])

  useEffect(() => {
    if (!shouldFetch || noMoreComments) {
      return
    }

    const fetch = async () => {
      try {
        let urlParams = {
          limit: lowestId ? 20 : 50,
        }

        if (lowestId) {
          urlParams['below-id'] = lowestId
        }

        const response = await getRecordComments(
          record.rclass,
          record.id,
          urlParams
        )

        const { comments } = response.data

        if (comments.length === 0) {
          setNoMoreComments(true)
          setShouldFetch(false)
        } else {
          // determine how many comments havent been viewed
          // since the conversation looked_at (includes system events)
          const lastLookedAt = new Date(record.looked_at)
          const newCommentsCount = comments.filter(comment => {
            return compareAsc(new Date(comment.created_at), lastLookedAt) > 0
          }).length

          let newComments = insert(
            comments,
            newCommentsCount,
            user?.preferred_lang == 'cy' ? 'newydd' : 'new'
          )

          setShouldFetch(false)

          setComments(prev =>
            groupMessagesByDate([...prev, ...newComments], user?.preferred_lang)
          )

          setLowestId(comments.slice(-1)[0].id)
        }
      } catch (e) {
        setShouldFetch(false)
      }
    }

    void fetch()
  }, [lowestId, shouldFetch])

  useEffect(() => {
    return () => {
      setComments([])
      setNoMoreComments(false)
      setLowestId(null)
      setShouldFetch(true)
    }
  }, [recordId])

  return {
    comments,
    fetchMore,
    noMoreComments,
    setComments,
    setLowestId,
    setNoMoreComments,
  }
}

export default usePagedComments

const insert = (arr, index, text) => {
  if (index < 1) return arr
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    text,
    // part of the array after the specified index
    ...arr.slice(index),
  ]
}

const groupMessagesByDate = (messages, preferred_lang) => {
  const { getFormattedLongDate } = useTimeSince()

  let groupedMessages = []
  let dateHeader = ''

  // Take the list of messages, and add day breaks
  for (const [messageIndex, message] of messages.entries()) {
    // If the message is a string, unset it
    if (typeof message !== 'string') {
      let currentDateHeader = getFormattedLongDate(
        parseISO(message.created_at),
        preferred_lang
      )

      if (currentDateHeader != dateHeader) {
        if (messageIndex !== 0) groupedMessages.push(dateHeader)
        dateHeader = currentDateHeader
      }

      groupedMessages.push(message)
    } else {
      if (message === 'new') groupedMessages.push(message)
    }
  }

  // add the date header to the top
  groupedMessages.push(dateHeader)

  return groupedMessages
}
