import React, { useCallback, useContext, useState, useRef } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import { ScrollView, TouchableOpacity, View, Pressable } from 'react-native'
import WorktribeIcon from '../shared/WorktribeIcon'
import { SmallButton } from '../shared/Button'
import Ellipsis from '../shared/Ellipsis'
import ColumnHeader from './ContentPanel/ui/ColumnHeader'
import { Tab, TabContainer } from './ContentPanel/ui/Tabs'
import HoverableOpacity from '../shared/HoverableOpacity'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import useSocketMethods from '../../hooks/useSocketMethods'
import EditDialog from '../landscape/EditDialog'
import BackButton from './BackButton'

const Header = styled.View`
  height: 236px;
  border-radius: 30px;
`

const SearchIcon = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border-width: 1px;
`

const CardThumbnail = styled.Image`
  height: ${props => (props.size ? props.size : `249px`)};
  width: ${props => (props.size ? props.size : `249px`)};
  border-radius: ${props => (props.radius ? `${props.radius}px` : `50px`)};
  margin-right: 12px;
  border-width: ${props => (props.width ? props.width : `8px`)};
  border-color: #fff;
`

const NumberedBadge = styled.View`
  background-color: #e08938;
  height: 18px;
  padding: 0 6px;
  border-radius: 30px;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
`

const Content = styled.View`
  max-width: 100%;
`

const TabBadge = ({ badge }) => {
  const {
    typography: { Overline },
  } = useContext(ThemeContext)

  if (!badge) {
    return null
  }

  if (!isNaN(badge)) {
    return (
      <NumberedBadge>
        <Overline color="#FFF">{badge}</Overline>
      </NumberedBadge>
    )
  }

  if (badge === 'message') {
    return (
      <View style={{ marginLeft: 6 }}>
        <WorktribeIcon name="tab-message" size={20} color="#2AAD79" />
      </View>
    )
  }

  if (badge === 'complete') {
    return (
      <View style={{ marginLeft: 6 }}>
        <WorktribeIcon name="check-circle-2" size={20} color="#2AAD79" />
      </View>
    )
  }
}

export const Tabs = ({ record, selectedTab, setSelectedTab }) => {
  const {
    typography: { Button },
    themeColors,
  } = useContext(ThemeContext)

  if (record.sections.length < 2) {
    return null
  }

  // Get parent tab
  const selectedTabParent = selectedTab
    ? record.sections.filter(
        section => section.display === 'show' && section.name === selectedTab
      )?.[0]?.underneath
    : ''

  // if we have a selected tab, build a subtab
  const subTabs = selectedTab
    ? record.sections.filter(
        section =>
          section.display === 'show' &&
          (section.underneath === selectedTab ||
            (selectedTabParent && section.underneath === selectedTabParent))
      )
    : []

  return (
    <>
      {record.header.style === 'greyheader' ? (
        <View
          style={[
            { backgroundColor: '#EBEEF7', marginHorizontal: -40 },
            subTabs.length === 0 && { marginBottom: 20 },
          ]}
        >
          <TabContainer
            style={{
              marginHorizontal: 40,
              paddingBottom: subTabs.length > 0 ? 12 : 20,
            }}
          >
            {record.sections
              .filter(
                section =>
                  section.display === 'show' && section.underneath === ''
              )
              .map(({ name, badge }) => (
                <Tab
                  key={name}
                  onPress={() => setSelectedTab(name)}
                  active={selectedTab === name}
                  style={[
                    (selectedTab === name || selectedTabParent === name) && {
                      backgroundColor: themeColors.bg.primary,
                    },
                    (selectedTab === name || selectedTabParent === name) &&
                      subTabs.length && {
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                  ]}
                >
                  <AlignedRow>
                    <Button
                      color={
                        selectedTab === name || selectedTabParent === name
                          ? themeColors.tabs.activeFont
                          : themeColors.tabs.font
                      }
                    >
                      {name}
                    </Button>

                    <TabBadge badge={badge} />
                  </AlignedRow>
                </Tab>
              ))}
          </TabContainer>
        </View>
      ) : (
        <TabContainer
          style={{
            paddingBottom: subTabs.length > 0 ? 0 : 20,
          }}
        >
          {record.sections
            .filter(
              section => section.display === 'show' && section.underneath === ''
            )
            .map(({ name, badge }) => (
              <Tab
                key={name}
                onPress={() => setSelectedTab(name)}
                active={selectedTab === name}
                style={[
                  (selectedTab === name || selectedTabParent === name) && {
                    backgroundColor: themeColors.tabs.activeBg,
                  },
                  (selectedTab === name || selectedTabParent === name) &&
                    subTabs.length && {
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                ]}
              >
                <AlignedRow>
                  <AlignedRow gap={6}>
                    <Button
                      color={
                        selectedTab === name || selectedTabParent === name
                          ? themeColors.tabs.activeFont
                          : themeColors.tabs.font
                      }
                    >
                      {name}
                    </Button>

                    {!!(
                      (selectedTab === name || selectedTabParent === name) &&
                      subTabs.length
                    ) && <WorktribeIcon name="chevron" />}
                  </AlignedRow>

                  <TabBadge badge={badge} />
                </AlignedRow>
              </Tab>
            ))}
        </TabContainer>
      )}
      {subTabs.length > 0 && (
        <TabContainer
          style={{
            marginTop: record.header.style === 'greyheader' ? 12 : 0,
            marginBottom: 20,
            backgroundColor: themeColors.tabs.activeBg,
            borderRadius: 20,
            paddingHorizontal: 12,
            paddingVertical: 6,
          }}
        >
          {subTabs.map(({ name, badge }) => (
            <Tab
              key={name}
              onPress={() => setSelectedTab(name)}
              active={selectedTab === name}
              // style={
              //   selectedTab === name && {
              //     backgroundColor: themeColors.tabs.activeBg,
              //   }
              // }
            >
              <AlignedRow>
                <Button
                  color={
                    selectedTab === name
                      ? themeColors.tabs.activeFont
                      : themeColors.tabs.font
                  }
                >
                  {name}
                </Button>

                <TabBadge badge={badge} />
              </AlignedRow>
            </Tab>
          ))}
        </TabContainer>
      )}
    </>
  )
}

const RecordHeader = ({
  record,
  setShowSearch,
  selectedTab,
  setSelectedTab,
  allowBackNavigation = true,
}) => {
  const {
    typography: { H1, H2, Body, HeaderButton, Button, Overline, Subtitle },
    themeColors: { bg, accents, hover, fonts },
    shadowStyles,
  } = useContext(ThemeContext)

  const {
    header: {
      recordid,
      rclassname,
      style,
      actions,
      title,
      hiderecordid,
      hiderclassname,
    },
  } = record

  const [dialogProps, setDialogProps] = useState(null)
  const [showActionsMenu, setShowActionsMenu] = useState(false)

  const { record_action } = useSocketMethods()

  const doAction = useCallback(
    ({ dialog, name, validationmessage }) => {
      if (!dialog) {
        record_action({
          rclassname,
          recordid,
          actionname: name,
        })
      } else if (validationmessage) {
        setDialogProps({ validationmessage })
      } else if (dialog.length || dialog.items?.length) {
        setDialogProps({
          dialog,
          rclassname,
          actionname: name,
          recordid,
          name,
        })
      }
    },
    [rclassname, recordid]
  )

  const closeHandler = useCallback(() => {
    setShowActionsMenu(false)
  }, [])

  const outsideHandler = useCallback(closeHandler, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  switch (style) {
    case 'widegrey': {
      return (
        <>
          <Header style={{ backgroundColor: bg.card }}>
            <AlignedRow
              gap={12}
              justifyContent="space-between"
              style={{
                paddingTop: 18,
                paddingHorizontal: 40,
                zIndex: 10,
              }}
            >
              {allowBackNavigation && <BackButton />}

              <AlignedRow>
                <SearchIcon
                  style={{ borderColor: accents.separator }}
                  onPress={() => setShowSearch(show => !show)}
                >
                  <WorktribeIcon size={20} name="search-alternate" />
                </SearchIcon>

                {record.menuitems.length ? (
                  <View
                    style={{ position: 'relative', flexDirection: 'column' }}
                  >
                    <SmallButton
                      onPress={() => setShowActionsMenu(prev => !prev)}
                      transparent
                      style={{ marginLeft: 16, borderColor: accents.separator }}
                    >
                      <Ellipsis color="#1D7A55" />
                    </SmallButton>

                    {showActionsMenu && (
                      <View
                        ref={wrapperRef}
                        style={[
                          {
                            position: 'absolute',
                            top: 45,
                            right: 0,
                            backgroundColor: bg.primary,
                            borderRadius: 16,
                            paddingVertical: 8,
                            maxHeight: 345,
                            width: 343,
                          },
                          shadowStyles,
                        ]}
                      >
                        <ScrollView style={{ flex: 1 }}>
                          {record?.menuitems.map(({ name, label }) => (
                            <HoverableOpacity
                              key={name}
                              onPress={() => doAction(name)}
                              style={{
                                justifyContent: 'center',
                                minHeight: 46,
                                paddingHorizontal: 16,
                                paddingVertical: 11,
                              }}
                              hoverStyle={{ backgroundColor: grey }}
                            >
                              <Body primary>{label}</Body>
                            </HoverableOpacity>
                          ))}
                        </ScrollView>
                      </View>
                    )}
                  </View>
                ) : null}
              </AlignedRow>
            </AlignedRow>

            <View
              style={{
                alignItems: 'center',
                marginTop: -30,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <CardThumbnail
                  source={{
                    uri: `https://source.unsplash.com/zwh47C0McZ8`,
                    cache: 'force-cache',
                  }}
                />

                <View style={{ marginLeft: 32, marginTop: 16, width: 350 }}>
                  <H1 numberOfLines={2} style={{ marginBottom: 8 }} primary>
                    {title}
                  </H1>
                  <Body style={{ marginBottom: 16 }} primary>
                    {rclassname}
                  </Body>

                  {record.buttons?.map(button => (
                    <SmallButton
                      key={button.name}
                      onPress={() => doAction(button)}
                      primary
                      style={{ marginLeft: 16 }}
                    >
                      <Button color="#FFF">{button.label}</Button>
                    </SmallButton>
                  ))}
                </View>
              </View>
            </View>
          </Header>

          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 50,
              paddingHorizontal: 40,
            }}
          >
            <View style={{ maxWidth: 1120 }}>
              <Tabs
                record={record}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </View>
          </View>
        </>
      )
    }
    case 'cover': {
      return (
        <>
          <AlignedRow
            gap={12}
            justifyContent="space-between"
            style={{
              paddingTop: 18,
              paddingHorizontal: 40,
              zIndex: 10,
            }}
          >
            {allowBackNavigation && <BackButton />}

            <AlignedRow>
              <SearchIcon
                style={{ borderColor: accents.separator }}
                onPress={() => setShowSearch(show => !show)}
              >
                <WorktribeIcon size={20} name="search-alternate" />
              </SearchIcon>

              <View>
                <SmallButton
                  style={{ marginLeft: 16 }}
                  // onPress={() => setShowContext(true)}
                >
                  <Ellipsis color="#1D7A55" />
                </SmallButton>
              </View>
            </AlignedRow>
          </AlignedRow>

          <View style={{ alignItems: 'center', marginTop: -60 }}>
            <Content style={{ maxWidth: 1120, width: '100%' }}>
              <View
                style={{
                  height: 300,
                  justifyContent: 'flex-end',
                  background:
                    'linear-gradient(91.23deg, #1D7A55 14.73%, #1A523C 53.44%, #1D7A55 100%)',
                  marginTop: 20,
                  borderRadius: 20,
                }}
              >
                <View
                  style={{
                    backgroundColor: bg.card,
                    height: 148,
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20,
                    paddingHorizontal: 24,
                  }}
                >
                  <AlignedRow
                    style={{
                      marginTop: -34,
                    }}
                  >
                    <CardThumbnail
                      size={156}
                      radius={40}
                      width={4}
                      source={{
                        uri: `https://source.unsplash.com/zwh47C0McZ8`,
                        cache: 'force-cache',
                      }}
                    />
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        flex: 1,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <H2 primary>{title}</H2>
                        <Body primary>{record.rclassnamename}</Body>
                      </View>
                      {actions?.map(action => (
                        <SmallButton
                          key={action.name}
                          primary
                          style={{ marginLeft: 16 }}
                        >
                          <Button color="#FFF">{action.name}</Button>
                        </SmallButton>
                      ))}
                    </View>
                  </AlignedRow>
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <Tabs
                  record={record}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              </View>
            </Content>
          </View>
        </>
      )
    }
    case 'greyheader': {
      return (
        <>
          <ColumnHeader
            style={{ backgroundColor: '#EBEEF7', borderTopLeftRadius: 30 }}
          >
            <AlignedRow gap={12} style={{ flex: 1 }}>
              {allowBackNavigation && <BackButton />}

              <View style={{ flex: 1 }}>
                <H2 style={{ marginLeft: -0.5 }} numberOfLines={1} primary>
                  {title}
                </H2>
                {hiderclassname === 'false' || hiderecordid === 'false' ? (
                  <Overline style={{ textTransform: 'none' }}>{`${
                    hiderclassname === 'false' ? `${rclassname}` : ''
                  } ${
                    hiderecordid === 'false' ? `#${recordid}` : ''
                  }`}</Overline>
                ) : null}
              </View>
            </AlignedRow>

            <AlignedRow>
              <TouchableOpacity
                onPress={() => setShowSearch(show => !show)}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 36,
                  height: 36,
                  borderRadius: 18,
                  borderWidth: 1,
                  borderColor: accents.separator,
                }}
              >
                <WorktribeIcon size={20} name="search-alternate" />
              </TouchableOpacity>

              {record.buttons?.map(button => (
                <SmallButton
                  key={button.name}
                  onPress={() => doAction(button)}
                  primary
                  style={{ marginLeft: 16 }}
                >
                  <Button color="#FFF">{button.label}</Button>
                </SmallButton>
              ))}

              {record.menuitems?.length ? (
                <View style={{ position: 'relative', flexDirection: 'column' }}>
                  <SmallButton
                    onPress={() => setShowActionsMenu(prev => !prev)}
                    transparent
                    style={{ marginLeft: 16, borderColor: accents.separator }}
                  >
                    <Ellipsis color="#1D7A55" />
                  </SmallButton>

                  {showActionsMenu && (
                    <View
                      ref={wrapperRef}
                      style={[
                        {
                          position: 'absolute',
                          top: 45,
                          right: 0,
                          backgroundColor: bg.primary,
                          borderRadius: 16,
                          paddingVertical: 8,
                          maxHeight: 345,
                          width: 343,
                        },
                        shadowStyles,
                      ]}
                    >
                      <ScrollView style={{ flex: 1 }}>
                        {record?.menuitems.map(button => (
                          <HoverableOpacity
                            key={button.name}
                            onPress={() => doAction(button)}
                            style={{
                              justifyContent: 'center',
                              minHeight: 46,
                              paddingHorizontal: 16,
                              paddingVertical: 11,
                            }}
                            hoverStyle={{ backgroundColor: hover }}
                          >
                            <Body primary>{button.label}</Body>
                          </HoverableOpacity>
                        ))}
                      </ScrollView>
                    </View>
                  )}
                </View>
              ) : null}
            </AlignedRow>
          </ColumnHeader>

          <View
            style={{
              paddingHorizontal: 40,
            }}
          >
            <Tabs
              record={record}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </View>

          <EditDialog
            dialogProps={dialogProps}
            setDialogProps={setDialogProps}
          />
        </>
      )
    }
    default: {
      return (
        <>
          <ColumnHeader>
            <AlignedRow gap={12} style={{ flex: 1 }}>
              {allowBackNavigation && <BackButton />}

              <View style={{ flex: 1 }}>
                <H2 numberOfLines={1} primary style={{ lineHeight: 24 }}>
                  {title}
                </H2>

                {hiderclassname === 'false' || hiderecordid === 'false' ? (
                  <Subtitle>{`${
                    hiderclassname === 'false' ? `${rclassname}` : ''
                  } ${
                    hiderecordid === 'false' ? `#${recordid}` : ''
                  }`}</Subtitle>
                ) : null}
              </View>
            </AlignedRow>

            <AlignedRow>
              <TouchableOpacity
                onPress={() => setShowSearch(show => !show)}
                style={{
                  width: 38,
                  height: 38,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1.5,
                  borderColor: accents.button,
                  borderRadius: 70,
                }}
              >
                <WorktribeIcon
                  size={18}
                  color={fonts.secondary}
                  name="search-alternate"
                />
              </TouchableOpacity>

              {record.buttons?.map(button => (
                <SmallButton
                  key={button.name}
                  onPress={() => doAction(button)}
                  primary
                  style={{ marginLeft: 16 }}
                >
                  <HeaderButton color="#FFF">{button.label}</HeaderButton>
                </SmallButton>
              ))}

              {record.menuitems?.length ? (
                <View
                  ref={wrapperRef}
                  style={{ position: 'relative', flexDirection: 'column' }}
                >
                  <Pressable
                    onPress={() => setShowActionsMenu(show => !show)}
                    style={[
                      {
                        height: 38,
                        width: 54,
                        borderRadius: 70,
                        backgroundColor: showActionsMenu
                          ? '#F2FCF8'
                          : bg.primary,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 16,
                        borderWidth: 1.5,
                        borderColor: accents.button,
                        paddingHorizontal: 16,
                      },
                      showActionsMenu && {
                        borderColor: '#1D7A55',
                      },
                    ]}
                  >
                    <Ellipsis color={fonts.secondary} />
                  </Pressable>

                  {showActionsMenu && (
                    <View
                      style={[
                        {
                          position: 'absolute',
                          top: 45,
                          right: 0,
                          backgroundColor: bg.primary,
                          borderRadius: 16,
                          paddingVertical: 8,
                          maxHeight: 345,
                          width: 241,
                        },
                        shadowStyles,
                      ]}
                    >
                      <ScrollView style={{ flex: 1 }}>
                        {record?.menuitems.map(item => (
                          <HoverableOpacity
                            key={name}
                            onPress={() => doAction(item)}
                            style={{
                              justifyContent: 'center',
                              minHeight: 46,
                              paddingHorizontal: 16,
                              paddingVertical: 11,
                            }}
                            hoverStyle={{ backgroundColor: hover }}
                          >
                            <AlignedRow>
                              {item.iconname && (
                                <WorktribeIcon
                                  name={item.iconname}
                                  color={item.iconcolor}
                                  size={20}
                                />
                              )}

                              <Body
                                style={{ marginLeft: 12, marginTop: 2 }}
                                primary
                              >
                                {item.name}
                              </Body>
                            </AlignedRow>
                          </HoverableOpacity>
                        ))}
                      </ScrollView>
                    </View>
                  )}
                </View>
              ) : null}
            </AlignedRow>
          </ColumnHeader>

          <View
            style={{
              paddingHorizontal: 40,
            }}
          >
            <Tabs
              record={record}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </View>

          <EditDialog
            dialogProps={dialogProps}
            setDialogProps={setDialogProps}
          />
        </>
      )
    }
  }
}

export default RecordHeader
