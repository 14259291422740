import React, {
  useEffect,
  useReducer,
  useState,
  useContext,
  useCallback,
} from 'react'
import styled from 'styled-components/native'
import { APIContext } from '../../contexts/APIContext'
import { useDispatch } from 'react-redux'
import { togglePush } from '../../store/actions/records'
import { TouchableOpacity, View } from 'react-native'
import { updateRecords } from '../../store/actions/records'
import InputField from '../../components/shared/InputField'

const Container = styled.SafeAreaView`
  flex: 1;
  background-color: #ebecf2;
`

const Body = styled.ScrollView`
  flex: 1;
  padding: 0 16px;
`

const Header = styled.View`
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`

const HeaderItem = styled.View`
  flex: ${props => props.flex ?? 1};
  align-items: ${props => props.align};
`

const HeaderTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: #0e1012;
`

const NextButton = styled.TouchableOpacity`
  padding: 4px 14px;
  border-radius: 30px;
  background-color: ${props => (props.disabled ? '#A0A4B8' : '#2AAD79')};
`

const NextButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const PhotoButton = styled.TouchableOpacity`
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #2aad79;
`

const PhotoButtonText = styled.Text`
  color: ${props => (props.primary ? '#FFFFFF' : '#2AAD79')};
  font-family: proxima-nova-semibold;
  font-size: 17px;
`

const SubTitle = styled.Text`
  font-family: proxima-nova-semibold;
  color: #757885;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`

const ConversationPhotoWrapper = styled.View`
  align-items: center;
`

const ConversationPhoto = styled.Image`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  margin: 20px 0;
`

const GroupNameWrapper = styled.View`
  margin-top: 30px;
`

const GroupNameInput = styled.View`
  height: 60px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 8px;
  padding: 18px 16px;
  font-family: proxima-nova;
  font-size: 17px;
  justify-content: center;
`

const InputHeading = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const BackArrowImage = styled.Image`
  width: 18px;
  height: 18px;
`

const InputGroupContainer = styled.View`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
`

const InputSeperator = styled.View`
  height: 1px;
  background-color: #dde1ed;
`

const InputTextAbove = styled.Text`
  margin-bottom: 8px;
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

const InputTextBelow = styled.Text`
  margin-top: 8px;
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const SettingsWrapper = styled.View`
  margin-top: 30px;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  '8G5hlsomdBY',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const fieldGroups = [
  {
    textAbove: 'General',
    fields: [
      {
        name: 'Group name',
        type: 'text',
        required: true,
      },
      {
        name: 'Push notifications',
        type: 'boolean',
        required: false,
      },
    ],
  },
  {
    textAbove: 'Admin',
    fields: [
      {
        name: 'Admin',
        type: 'arostatic',
        required: false,
        options: [],
      },
    ],
  },
  {
    textAbove: 'Members',
    fields: [
      {
        name: 'Add members',
        type: 'button',
        required: false,
        icon: 'add-person',
        color: '#2AAD79',
      },
      {
        name: 'Add members',
        type: 'arostatic',
        required: true,
        options: [],
      },
    ],
  },
  {
    textAbove: 'Other',
    fields: [
      {
        name: 'Leave group',
        type: 'button',
        required: false,
        icon: 'logout',
      },
      {
        name: 'Delete group',
        type: 'button',
        required: false,
        icon: 'bin',
        color: '#D13E3E',
      },
    ],
  },
]

function createGroupSettingsReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.field]: action.payload.value,
        },
      }
    default:
      return state
  }
}

const GroupSettingsScreen = ({ navigation, route }) => {
  const { conversation } = route.params
  const [inputFocused, setInputFocused] = useState(false)
  const [placeholderText, setPlaceholderText] = useState('Group name')
  const [image, setImage] = useState('G02AR7pa_Lo')
  const [isCreating, setIsCreating] = useState(false)
  const [modalData, setModalData] = useState(null)

  const settingsDispatch = useDispatch()

  //const isLandscape = useOrientation()

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : 'Group name')
  }, [inputFocused])

  const [state, dispatch] = useReducer(createGroupSettingsReducer, {
    fields: {
      'Group name': conversation.fields.conversation_name,
      'Push notifications': conversation.push_notifications_enabled,
    },
  })

  useEffect(() => {
    togglePushNotifications()
  }, [state.fields['Push notifications']])

  const {
    enablePushNotifications,
    disablePushNotifications,
    updateConversationName,
  } = useContext(APIContext)

  const togglePushNotifications = useCallback(async () => {
    const action = conversation.push_notifications_enabled
      ? disablePushNotifications
      : enablePushNotifications

    try {
      const response = await action(conversation.rclass, conversation.id)
      if (response.status === 200) {
        const { data } = response
        settingsDispatch(togglePush(data))
      }
    } catch (e) {
      console.log(e)
    }
  }, [conversation, disablePushNotifications, enablePushNotifications])

  const changeImage = () =>
    setImage(imageFeed[Math.floor(Math.random() * imageFeed.length)])

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      modifyConversationName()
    })
    return unsubscribe
  }, [navigation])

  const modifyConversationName = useCallback(async () => {
    const groupName = state.fields['Group name']
    console.log('state2', state)
    try {
      const response = await updateConversationName(conversation.id, groupName)
      const { record } = response.data

      settingsDispatch(
        updateRecords({
          ...record,
          name: groupName,
          looked_at: new Date(),
        })
      )
    } catch (e) {
      console.warn(e)
    }
  }, [state, conversation])

  return (
    <Container>
      <Header>
        <HeaderItem align="flex-start">
          <TouchableOpacity onPress={() => navigation.pop()}>
            <BackArrowImage
              source={require('../../../assets/icons/arrow-back.png')}
            />
          </TouchableOpacity>
        </HeaderItem>
        <HeaderItem align="center">
          <HeaderTitle>Group settings</HeaderTitle>
        </HeaderItem>
        <HeaderItem align="flex-end "></HeaderItem>
      </Header>

      <Body>
        <ConversationPhotoWrapper>
          <ConversationPhoto
            source={{
              uri: `https://source.unsplash.com/${image}/`,
              cache: 'force-cache',
            }}
          />
          <PhotoButton onPress={changeImage}>
            <PhotoButtonText>Change photo</PhotoButtonText>
          </PhotoButton>
        </ConversationPhotoWrapper>
        <SettingsWrapper>
          {fieldGroups.map((group, idx) => (
            <View key={idx} style={{ marginBottom: 32 }}>
              {group.textAbove ? (
                <InputTextAbove>{group.textAbove}</InputTextAbove>
              ) : null}
              <InputGroupContainer>
                {group.fields
                  .map((field, idx) => (
                    <InputField
                      navigation={navigation}
                      key={`${field.name}-${field.type}`}
                      field={field}
                      state={state}
                      dispatch={dispatch}
                      setModalData={setModalData}
                    />
                  ))
                  .reduce((prev, curr) => [
                    prev,
                    <InputSeperator key={1} />,
                    curr,
                  ])}
              </InputGroupContainer>
              {group.textBelow ? (
                <InputTextBelow>{group.textBelow}</InputTextBelow>
              ) : null}
            </View>
          ))}
        </SettingsWrapper>
      </Body>
    </Container>
  )
}

export default GroupSettingsScreen
