import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'

const BarWapper = styled.View`
  height: 40px;
  max-width: 700px;
  width: 100%;
  border-radius: 50px;
  flex-direction: row;
  align-self: center;
`

const Bar = styled.View`
  width: ${props => props.percentage};
  background-color: ${props => props.color};
  justify-content: center;
  align-items: center;
`

const PercentageBar = ({ items, style }) => {
  const {
    typography: { Button },
  } = useContext(ThemeContext)
  return (
    <BarWapper {...{ style }}>
      {items.map((item, i) => {
        const lastIndex = items.length - 1

        let borderRadius
        if (i === 0) {
          borderRadius = {
            borderBottomLeftRadius: 50,
            borderTopLeftRadius: 50,
          }
        } else if (i === lastIndex) {
          borderRadius = {
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50,
          }
        }

        return (
          <Bar
            style={borderRadius && borderRadius}
            percentage={item.percentage}
            color={item.color}
            key={`${i}_${item.color}`}
          >
            <Button color="#FFF">{item.percentage}</Button>
          </Bar>
        )
      })}
    </BarWapper>
  )
}

export default PercentageBar
