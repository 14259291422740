import React, { useCallback, useContext } from 'react'
import { RefreshControl, Text, StyleSheet, SectionList } from 'react-native'
import useOrientation from '../../hooks/useOrientation'
import Card from './Card'
import LandscapeCard from './LandscapeCard'
import Announcement from './Announcement'
import { ThemeContext } from '../../contexts/ThemeContext'

const RecordFeed = props => {
  const {
    noMoreItems,
    data,
    isRefreshing,
    onEndReached,
    onRefresh,
    handleItemPress,
    screen,
  } = props

  const isLandscape = useOrientation()
  // console.log(isLandscape)
  const {
    typography: { Overline },
  } = useContext(ThemeContext)

  const renderItem = useCallback(
    ({ item }) => {
      switch (item.record_class) {
        case 'announcements': {
          return <Announcement message={item.title} screen={screen} />
        }
        default: {
          if (isLandscape) {
            return <LandscapeCard record={item} screen={screen} />
          }

          return <Card record={item} screen={screen} />
        }
      }
    },
    [screen, isLandscape]
  )

  const renderFooter = () => {
    if (noMoreItems) {
      return <Text style={styles.noMoreItems}>No more items</Text>
    }

    return null
  }

  return (
    <SectionList
      showsVerticalScrollIndicator={false}
      removeClippedSubviews={true}
      stickySectionHeadersEnabled={false}
      sections={data}
      contentContainerStyle={!isLandscape && styles.container}
      keyExtractor={item => item.id}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.1}
      ListFooterComponent={renderFooter}
      renderSectionHeader={({ section: { title } }) => (
        <Overline
          style={[
            { marginBottom: 4, marginTop: 20 },
            isLandscape && { marginLeft: 20 },
          ]}
        >
          {title}
        </Overline>
      )}
      refreshControl={
        <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
      }
    />
  )
}

export default RecordFeed

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  noMoreItems: {
    textAlign: 'center',
    fontFamily: 'proxima-nova-semibold',
    fontSize: 14,
    lineHeight: 18,
    color: '#757885',
    marginBottom: 5,
  },
  loader: {
    alignItems: 'center',
    marginBottom: 5,
  },
  sectionTitle: {
    fontFamily: 'proxima-nova-semibold',
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: 0.4,
    color: '#757885',
    marginBottom: 5,
    textTransform: 'uppercase',
  },
  noRecordsText: {
    fontFamily: 'proxima-nova-semibold',
    fontSize: 14,
    color: '#757885',
  },
})
