import { Platform } from 'react-native'
import * as navigation from '../services/RootNavigation'

export const back = () => {
  if (Platform.OS === 'web') {
    history?.back()
  } else {
    navigation.goBack()
  }
}
