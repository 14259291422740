import React, { useContext, useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../../components/shared/Avatar'
import ConversationFeed from '../../components/shared/ConversationFeed'
import TabHeader from '../../components/shared/TabHeader'
import { showProfile } from '../../store/actions/layout'
import { AntDesign } from '@expo/vector-icons'
import styled from 'styled-components/native/dist/styled-components.native.esm'
import { AuthContext, useAuth } from '../../contexts/AuthContext'
import useRecordList from '../../hooks/useRecordList'
import WorktribeIcon from '../../components/shared/WorktribeIcon'

const Close = styled.TouchableOpacity`
  height: 36px;
  width: 36px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.5;
`

const MessagesScreen = ({ navigation }) => {
  const { records, fetchMore, refreshing, onRefresh } =
    useRecordList('messages')

  const {
    profile: { open },
  } = useSelector(state => state.layout)

  const { user } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      dispatch(showProfile(false))
    })

    return unsubscribe
  }, [navigation])

  const insets = useSafeAreaInsets()

  return (
    <View style={[styles.container, { paddingTop: insets.top }]}>
      <TabHeader title="Messages">
        {open ? (
          <Close onPress={() => dispatch(showProfile(false))}>
            <AntDesign name="close" size={24} color="#FFFFFF" />
          </Close>
        ) : (
          <>
            <TouchableOpacity
              style={{ marginRight: 20 }}
              onPress={() => navigation.navigate('NewConversation')}
            >
              <WorktribeIcon name="pencil-write" />
            </TouchableOpacity>
            <Avatar
              size={36}
              radius={12}
              userId={user?.userid}
              onPress={() => dispatch(showProfile(true))}
            />
          </>
        )}
      </TabHeader>

      <ConversationFeed
        data={records}
        onRefresh={onRefresh}
        isFetching={refreshing}
        onEndReached={fetchMore}
      />
    </View>
  )
}

export default MessagesScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EBECF2',
  },
})
