import React from 'react'
import { Image, ImageBackground } from 'react-native'
import styled from 'styled-components/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import TopBar from './TopBar'
import Ellipsis from './Ellipsis'

const HeaderContainer = styled.View`
  height: 210px;
`

const HeaderTitleRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

const ImageBackgroundOpacity = styled.View`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.6);
`

const Back = styled.TouchableOpacity`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #000;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const AssessmentHeader = ({ children, navigation }) => {
  const idString = '10'
  const imgString = imageFeed[idString[idString.length - 1]]
  const { top } = useSafeAreaInsets()

  return (
    <HeaderContainer>
      <ImageBackground
        style={{
          flex: 1,
          resizeMode: 'cover',
        }}
        source={{
          uri: `https://source.unsplash.com/${imgString}/200x200`,
          cache: 'force-cache',
        }}
      >
        <ImageBackgroundOpacity>
          <TopBar
            title={'Assessments'}
            titleColor={'#FFF'}
            leftItem={
              <Back onPress={() => navigation.pop()}>
                <Image
                  style={{ height: 16, width: 8 }}
                  source={require('../../../assets/icons/arrow-left-white.png')}
                />
              </Back>
            }
            rightItem={<Ellipsis />}
            containerStyle={{
              marginTop: top,
            }}
          />
          <HeaderTitleRow>{children}</HeaderTitleRow>
        </ImageBackgroundOpacity>
      </ImageBackground>
    </HeaderContainer>
  )
}

export default AssessmentHeader
