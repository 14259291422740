import React, { useEffect, useState } from 'react'
import { Image } from 'react-native'
import SearchInput from '../shared/SearchInput'
import { Modalize } from 'react-native-modalize'
import styled from 'styled-components/native'
import WorktribeIcon from '../shared/WorktribeIcon'

const ModalItem = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  background-color: ${props => (props.selected ? '#F2FCF8' : 'transparent')};
`

const ModalItemThumbnail = styled.Image`
  height: 36px;
  width: 36px;
  border-radius: 12px;
  margin-right: 16px;
`

const ModalItemText = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  color: ${props => (props.selected ? '#2AAD79' : '#000000')};
`

const ModalOptionRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const UserSelect = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: ${props => (props.selected ? 0 : '1px')} solid #a0a4b8;
  background-color: ${props => (props.selected ? '#2AAD79' : 'transparent')};
`

const Footer = styled.View`
  padding: 10px 20px 20px 10px;
`

const SelectButton = styled.TouchableOpacity`
  background-color: #2aad79;
  padding: 16px 0;
  border-radius: 30px;
`

const SelectButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: center;
`

const Select = ({ modalizeRef, modalData, dispatch }) => {
  const [search, setSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState([])

  // we pass back selected items when dispatching
  // so we can use that info here
  useEffect(() => {
    if (modalData?.selected) {
      setSelectedItems(modalData.selected)
    }
  }, [modalData])

  const toggleSelected = option => {
    const newSelectedItems = [...selectedItems]
    const existingIndex = newSelectedItems.findIndex(
      selectedItem => selectedItem === option
    )
    if (existingIndex > -1) {
      newSelectedItems.splice(existingIndex, 1)
    } else {
      newSelectedItems.push(option)
    }
    setSelectedItems(Array.from(new Set(newSelectedItems)))
  }

  const modalContent = option => {
    switch (modalData.type) {
      case 'selectone':
        return (
          <ModalItem
            key={option.title}
            onPress={() => {
              modalizeRef.current?.close()
              dispatch({
                type: 'UPDATE_FIELD',
                payload: { field: modalData.field, value: option.title },
              })
            }}
          >
            <ModalItemThumbnail
              source={{
                uri: `https://source.unsplash.com/${option.image}/`,
                cache: 'force-cache',
              }}
            />
            <ModalItemText>{option.title}</ModalItemText>
          </ModalItem>
        )
      case 'selectmultiple':
        const selected = selectedItems.includes(option.title)
        return (
          <ModalItem
            key={option.title}
            onPress={() => toggleSelected(option.title)}
            selected={selected}
          >
            <ModalOptionRow>
              <ModalItemText selected={selected}>{option.title}</ModalItemText>
              {selected ? (
                <WorktribeIcon
                  size={20}
                  color="#2AAD79"
                  name="check-circle-2"
                />
              ) : (
                <UserSelect />
              )}
            </ModalOptionRow>
          </ModalItem>
        )
      default:
        return null
    }
  }

  const renderFooter = () => {
    if (selectedItems.length > 0) {
      return (
        <Footer>
          <SelectButton
            onPress={() => {
              modalizeRef.current?.close()

              dispatch({
                type: 'UPDATE_FIELD',
                payload: { field: modalData.field, value: selectedItems },
              })

              setSelectedItems([])
            }}
          >
            <SelectButtonText>
              Select {selectedItems.length}{' '}
              {selectedItems.length > 1 ? `items` : `item`}
            </SelectButtonText>
          </SelectButton>
        </Footer>
      )
    }
    return null
  }

  return (
    <Modalize
      ref={modalizeRef}
      handlePosition="inside"
      modalStyle={{ paddingVertical: 15, flex: 1 }}
      modalHeight={500}
      FooterComponent={renderFooter}
    >
      <SearchInput search={search} setSearch={setSearch} />
      {modalData?.options
        .filter(option => {
          return option.title.toLowerCase().match(search.toLowerCase())
        })
        .map(option => modalContent(option))}
    </Modalize>
  )
}

export default Select
