import styled from 'styled-components/native'

export const TabContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`

export const Tab = styled.TouchableOpacity`
  border-radius: 20px;
  padding: 10px 12px;
  margin-right: 6px;
`
