import React, { useContext } from 'react'
import { StyleSheet, Text } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import Badge from './Badge'
import HoverableOpacity from './HoverableOpacity'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from './WorktribeIcon'
import * as RootNavigation from '../../services/RootNavigation'

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const CardThumbnail = styled.Image`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  margin-right: 12px;
`

const CardContent = styled.View`
  flex: 1;
`

const CardHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const LandscapeCard = ({ record }) => {
  const idString = record.id.toString()
  const imgString = imageFeed[idString[idString.length - 1]]

  const {
    typography: { Label, H5 },
    themeColors: { separator, hover, bg }
  } = useContext(ThemeContext)

  const { selectedRecord } = useSelector(state => state.records)

  return (
    <HoverableOpacity
      activeOpacity={1}
      hoverStyle={{ backgroundColor: hover }}
      style={[
        selectedRecord?.id === record.id && { backgroundColor: bg.primary },
        styles.card,
        { borderBottomColor: separator.light }
      ]}
      onPress={() => {
        if (selectedRecord?.id === record.id) return
        
        RootNavigation.navigate('Home', {
          recordId: record.id,
          rClass: record.rclass,
        })
      }}
    >
      <CardThumbnail
        source={{
          uri: `https://source.unsplash.com/${imgString}/50x50`,
          cache: 'force-cache',
        }}
      />
      <CardContent>
        <CardHeader>
          <Label>{capitalize(record.rclass)}</Label>
          {record.unseen_by_me > 0 ? (
            <Badge count={record.unseen_by_me} />
          ) : !record.push_notifications_enabled ? (
            <WorktribeIcon name="muted" />
          ) : null}
        </CardHeader>
        <H5 primary numberOfLines={1}>
          {record.name}
        </H5>
      </CardContent>
    </HoverableOpacity>
  )
}

export default React.memo(LandscapeCard)

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
  },
})
