import React from 'react'
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native'
import styled from 'styled-components/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import * as Typography from '../shared/Typography'
import CachedImage from 'react-native-expo-cached-image'

import TopBar from './TopBar'
import Ellipsis from './Ellipsis'

const HeaderContainer = styled.View`
  height: 338px;
`

const ImageBackgroundOpacity = styled.View`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.6);
`

const HeaderContent = styled.View`
  padding: 7px 16px;
  justify-content: space-between;
  flex: 1;
`

const HeaderRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
`

const Tabs = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 25px 0 5px 0;
  padding: 0 30px;
`

const Tab = styled.TouchableOpacity`
  flex: 1;
  align-items: center;
`

const TabUnderline = styled.View`
  height: 4px;
  width: 40px;
  border-radius: 10px;
  background-color: transparent;
  margin-top: 10px;
`

const Back = styled.TouchableOpacity`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #000;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  '_1fVd1HodkA',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const TAB_TITLES = ['Overview', 'Sections', 'Chat']

const truncateTitle = (str, len) => {
  if (str.length > len) {
    if (len <= 3) {
      return str.slice(0, len - 3) + '...'
    } else {
      return str.slice(0, len) + '...'
    }
  } else {
    return str
  }
}

const RecordHeader = ({
  record,
  navigation,
  selectedTab,
  handleTabChange,
  openOptionsMenu,
}) => {
  const idString = record.id?.toString()
  const imgString = idString
    ? imageFeed[idString[idString.length - 1]]
    : imageFeed[1]

  const { top } = useSafeAreaInsets()

  return (
    <HeaderContainer style={selectedTab === 'Chat' && { height: 165 }}>
      <CachedImage
        isBackground={true}
        style={{
          flex: 1,
          resizeMode: 'cover',
        }}
        source={{ uri: `https://source.unsplash.com/${imgString}/200x200` }}
      >
        <ImageBackgroundOpacity>
          <TopBar
            leftItem={
              <Back onPress={() => navigation.pop()}>
                <Image
                  style={{ height: 16, width: 8 }}
                  source={require('../../../assets/icons/cross.png')}
                />
              </Back>
            }
            title={selectedTab === 'Chat' ? record.name : ''}
            titleColor="#FFF"
            rightItem={<Ellipsis onPress={openOptionsMenu} />}
            containerStyle={{ marginTop: top }}
            truncateTitle={truncateTitle}
          />

          {selectedTab !== 'Chat' && (
            <HeaderContent>
              <View>
                <View style={{ marginBottom: 10 }}>
                  <Typography.Overline color="#FFF">
                    {record.rclass.replace('_', ' ')}
                  </Typography.Overline>
                </View>
                <Typography.H1 color="#FFF">
                  {truncateTitle(record.name, 32)}
                </Typography.H1>
              </View>

              <View>
                <HeaderRow>
                  <Typography.Overline color="#FFF">
                    Chemistry Org Unit
                  </Typography.Overline>
                  <Typography.Overline color="#FFF">
                    30 Credits
                  </Typography.Overline>
                </HeaderRow>

                <HeaderRow>
                  <Typography.Overline color="#FFF">
                    Level 5
                  </Typography.Overline>
                  <Typography.Overline color="#FFF">
                    UNITE30-5 Code
                  </Typography.Overline>
                </HeaderRow>

                <HeaderRow>
                  <Typography.Overline color="#FFF" />
                  <Typography.Overline color="#FFF">Draft</Typography.Overline>
                </HeaderRow>
              </View>
            </HeaderContent>
          )}

          <Tabs>
            {TAB_TITLES.map(tab => (
              <Tab key={tab} onPress={() => handleTabChange(tab)}>
                <Text
                  style={[
                    styles.tabLabel,
                    selectedTab === tab && { opacity: 1 },
                  ]}
                >
                  {tab}
                </Text>
                <TabUnderline
                  style={selectedTab === tab && { backgroundColor: '#FFFFFF' }}
                />
              </Tab>
            ))}
          </Tabs>
        </ImageBackgroundOpacity>
      </CachedImage>
    </HeaderContainer>
  )
}

export default RecordHeader

const styles = StyleSheet.create({
  tabLabel: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 22,
    color: '#FFFFFF',
    marginHorizontal: 8,
    opacity: 0.7,
  },
})
