import React from 'react'
import styled from 'styled-components/native'
import { Modalize } from 'react-native-modalize'
import * as RootNavigation from '../../services/RootNavigation'
import { Portal } from 'react-native-portalize'
import WorktribeIcon from '../shared/WorktribeIcon'

const OptionContainer = styled.TouchableOpacity`
  background-color: #ffffff;
  border-radius: 16px;
  margin: 6px 0;
  height: 56px;
  justify-content: center;
  padding: 0 24px;
`
const Option = styled.View`
  flex-direction: row;
  align-items: center;
`

const OptionTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 16px;
  color: #0e1012;
  margin-left: 20px;
`

const menuItems = [
  {
    title: 'Create a project',
    color: '2AAD79',
  },
  {
    title: 'Request a contract',
    color: 'E94545',
  },
  {
    title: 'Deposit an output',
    color: '46C6D1',
  },
  {
    title: 'Create a programme',
    color: 'F2CE6A',
  },
  {
    title: 'Create a module',
    color: 'E08938',
  },
  {
    title: 'Add a news item',
    color: '2AAD79',
  },
  {
    title: 'Add an ethics application',
    color: 'E94545',
  },
  {
    title: 'Add a task',
    color: '46C6D1',
  },
]

const CreateNew = ({ modalizeRef }) => {
  const renderOption = ({ item }) => (
    <OptionContainer
      onPress={() => RootNavigation.navigate('Create', { title: item.title })}
    >
      <Option>
        <WorktribeIcon name="bookmark" color={`#${item.color}`} />
        <OptionTitle>{item.title}</OptionTitle>
      </Option>
    </OptionContainer>
  )

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        modalHeight={500}
        withHandle={true}
        handlePosition="inside"
        modalStyle={{
          backgroundColor: '#EBECF2',
          paddingHorizontal: 20,
        }}
        childrenStyle={{
          marginTop: 20,
          marginBottom: 10,
        }}
        flatListProps={{
          contentContainerStyle: {
            paddingBottom: 10,
          },
          data: menuItems,
          renderItem: renderOption,
          keyExtractor: item => item.title,
          showsVerticalScrollIndicator: false,
        }}
      />
    </Portal>
  )
}

export default React.memo(CreateNew)
