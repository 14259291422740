import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { TinyButton } from '../shared/Button'
import Actions from './Actions'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const OUTCOMES = [
  {
    title: 'Knowledge and understanding',
    rows: [
      {
        code: 'A1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
      {
        code: 'B1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
      {
        code: 'C1',
        description: 'Eu reprehenderit ex do magna incididunt cupidatat sint. ',
      },
    ],
  },
  {
    title: 'Subject-specific Skills',
    rows: [
      {
        code: 'A1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
      {
        code: 'B1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
    ],
  },
  {
    title: 'Real World Skills',
    rows: [
      {
        code: 'A1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
      {
        code: 'B1',
        description:
          'Eu reprehenderit ex do magna incididunt cupidatat sint. Pariatur dolore in cillum occaecat in do ad. Et ullamco id do pariatur consequat excepteur',
      },
    ],
  },
]

const OutcomeRow = ({ outcome }) => {
  const {
    typography: { Body, H5 },
  } = useContext(ThemeContext)
  const [hovered, setHovered] = useState(false)
  return (
    <Row
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        width: '100%',
        marginBottom: 12,
        paddingRight: 350,
        alignItems: 'center',
      }}
    >
      <Col style={{ alignSelf: 'flex-start' }}>
        <H5 color="#757885">{outcome.code}</H5>
      </Col>
      <Col size={10}>
        <Body color="#0E1012">{outcome.description}</Body>
      </Col>

      {hovered && <Actions />}
    </Row>
  )
}

const Outcomes = ({ record }) => {
  const {
    typography: { H3, Button },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        {OUTCOMES.map((outcome, i) => (
          <View key={`${outcome.title}`}>
            <AlignedRow justifyContent="space-between">
              <AlignedRow>
                <H3>{outcome.title}</H3>
                <WorktribeIcon name="chevron-bold" />
              </AlignedRow>

              {i === 0 ? (
                <TinyButton>
                  <Button color="#1D7A55">Add</Button>
                </TinyButton>
              ) : null}
            </AlignedRow>

            <Grid
              style={{
                marginTop: 20,
                marginBottom: 36,
                flexDirection: 'column',
              }}
            >
              {outcome.rows.map((row, i) => (
                <OutcomeRow key={`${row.code}-${i}`} outcome={row} />
              ))}
            </Grid>
          </View>
        ))}
      </Content>
    </Container>
  )
}

export default Outcomes
