import React, { useContext, useState } from 'react'
import { Platform, View } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'

const Container = styled.View`
  height: 24px;
  width: 80%;
  background-color: #dde1ed;
  border-radius: 50px;
  flex-direction: row;
`

const Tooltip = styled.View`
  position: absolute;
  bottom: 27px;
  padding: 3px 10px;
  background-color: #757885;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
`

const Proportion = ({ proportion }) => {
  const {
    typography: { Label },
  } = useContext(ThemeContext)
  return (
    <Container>
      {proportion.map((bar, i) => {
        const [width, setWidth] = useState(31)
        const lastIndex = proportion.length - 1

        let style
        if (i === 0) {
          style = {
            borderBottomLeftRadius: 50,
            borderTopLeftRadius: 50,
            borderRightWidth: 1,
            borderRightColor: '#FFF',
          }
        } else if (i === lastIndex) {
          style = {
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50,
            borderLeftWidth: 1,
            borderLeftColor: '#FFF',
          }
        } else {
          style = {
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderLeftColor: '#FFF',
            borderRightColor: '#FFF',
          }
        }

        const _handleLayout = ({ nativeEvent }) => {
          const { width } = nativeEvent.layout
          setWidth(width)
        }

        return (
          <View
            key={`${bar.percentage}-${i}`}
            onLayout={_handleLayout}
            style={[
              {
                width: `${bar.percentage}%`,
                justifyContent: 'center',
                alignItems: 'center',
              },
              style && style,
              bar.color && { backgroundColor: bar.color },
            ]}
          >
            {width <= 30 && bar.color ? (
              <Tooltip style={Platform.OS !== 'web' && { minWidth: 50 }}>
                <Label color="#FFFFFF">{bar.percentage}%</Label>
              </Tooltip>
            ) : bar.color ? (
              <Label color="#F2FCF8">{bar.percentage}%</Label>
            ) : null}
          </View>
        )
      })}
    </Container>
  )
}

export default Proportion
