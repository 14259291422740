import React, { useContext } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Messages from '../../components/landscape/Messages'
import CalendarView from './CalendarView'
import CustomView from '../../components/landscape/CustomView'
import Kanban from '../organisms/kanban/Kanban'
import {
  HeroHeading,
  Metric,
  RecentActivity,
  Chart,
  StatBox,
  Gauge,
  HorizontalBar,
  BostonGrid,
} from '../../components/landscape/ContentPanel/ServiceView'
import Selection from '../../components/landscape/Selection'
import Properties from './Properties'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { ProgrammeBuilder } from '../../screens/landscape/BuilderScreen'
import PlannerView from './PlannerView'

const RecordView: React.FC<{
  recordId: string
  rClass: string
  section: any
  selectedTab?: string
}> = ({ recordId, rClass, section, selectedTab }) => {
  if (!section?.blocks?.length) {
    return null
  }

  if (section.type === 'mainaside') {
    return <CustomView section={section} />
  }

  const {
    typography: { H4 },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return section.blocks.map((block, blockIndex) => {
    const { columns } = block

    // const items = columns.items
    const hasVisibleItems = columns.every(column =>
      column.items.some(item => item.display === 'show')
    )

    if (!hasVisibleItems) return null

    const isHero = columns.every(column =>
      column.items.every(item => item.property.type === 'hero')
    )

    const isMetricType = columns.every(column =>
      column.items.every(item => item.property.type === 'metric')
    )

    const isStatBox = columns.every(column =>
      column.items.every(item => item.property.type === 'statbox')
    )

    const isGauge = columns.every(column =>
      column.items.every(item => item.property.type === 'gauge')
    )

    const isChart = columns.every(column =>
      column.items.some(item =>
        ['piechart', 'recentactivity', 'horizontalbar', 'bostongrid'].includes(
          item.property.type
        )
      )
    )

    const isMessages = columns.every(column =>
      column.items.every(item => item.property.renderer === 'messages')
    )

    const isCalendar = columns.every(column =>
      column.items.every(item => item.property.renderer === 'calendar')
    )

    const isPlanner = columns.every(column =>
      column.items.every(item => item.property.renderer === 'planner')
    )

    const isKanban = columns.every(column =>
      column.items.every(item => item.property.renderer === 'kanban')
    )

    const isGrid = columns.every(column =>
      column.items.some(item =>
        ['grid', 'cards'].includes(item.property.renderer)
      )
    )

    const isProgrammeBuilder = columns.every(column =>
      column.items.every(item => item.property.renderer === 'programmebuilder')
    )

    if (isProgrammeBuilder) {
      const item = columns[0].items[0]

      const {
        property: { rclasses, name },
        editaction,
        addaction,
        value,
        filters,
      } = item

      let rclass
      ;[rclass] = rclasses?.split('.').map(piece => piece.replace(/[{}]/g, ''))

      return (
        <ProgrammeBuilder
          key={blockIndex}
          index={blockIndex}
          parentRecordId={recordId}
          parentrClass={rClass}
          propertyName={name}
          editAction={editaction}
          addAction={addaction}
          filters={filters}
          rclass={rclass}
          value={value}
          selectedTab={selectedTab}
        />
      )
    }

    if (isHero) {
      return (
        <BubbleRow key={blockIndex}>
          {columns.map(column =>
            column.items.map(item => <HeroHeading {...item.property} />)
          )}
        </BubbleRow>
      )
    }

    if (isMetricType) {
      return (
        <BubbleRow key={blockIndex}>
          {columns.map(column =>
            column.items.map(item => <Metric {...item.property} />)
          )}
        </BubbleRow>
      )
    }

    if (isStatBox) {
      return (
        <BubbleRow key={blockIndex}>
          {columns.map(column =>
            column.items.map(item => <StatBox {...item.property} />)
          )}
        </BubbleRow>
      )
    }

    if (isGauge) {
      return (
        <BubbleRow key={blockIndex}>
          <View
            style={{
              padding: 24,
              borderRadius: 20,
              backgroundColor: bg.card,
              margin: 12,
              flex: 1,
            }}
          >
            <H4 primary style={{ marginBottom: 12 }}>
              {block.name}
            </H4>

            <AlignedRow
              style={{ flexWrap: 'wrap', marginHorizontal: -12 }}
              justifyContent="space-between"
            >
              {columns.map(column =>
                column.items.map(item => <Gauge {...item.property} />)
              )}
            </AlignedRow>
          </View>
        </BubbleRow>
      )
    }

    if (isChart) {
      return (
        <BubbleRow key={blockIndex}>
          {columns.map(column =>
            column.items.map(item => {
              const { property } = item
              switch (property.type) {
                case 'recentactivity': {
                  return <RecentActivity key={blockIndex} />
                }
                case 'piechart': {
                  return <Chart key={blockIndex} />
                }
                case 'horizontalbar': {
                  return <HorizontalBar {...item.property} />
                }
                case 'bostongrid': {
                  return <BostonGrid {...item.property} />
                }
                default: {
                  return null
                }
              }
            })
          )}
        </BubbleRow>
      )
    }

    if (isMessages) {
      const item = columns[0].items[0]
      return (
        <Messages
          key={blockIndex}
          value={item?.value}
          name={item.property.name}
          editAction={item.editaction}
          recordId={recordId}
          rClass={rClass}
        />
      )
    }

    if (isCalendar) {
      const item = columns[0].items[0]

      let rclass
      ;[rclass] = item.property.rclasses
        ?.split('.')
        .map(piece => piece.replace(/[{}]/g, ''))

      return (
        <CalendarView
          key={blockIndex}
          columnproperty="Start Date"
          {...item.property}
          value={item.value}
          parentRecordId={recordId}
          parentRClass={rClass}
          editAction={item.editaction}
          addAction={item.addaction}
          filters={item.filters}
          selectedTab={selectedTab}
          rclass={rclass}
          required={block.required === 'true'}
        />
      )
    }

    if (isPlanner) {
      const item = columns[0].items[0]

      let rclass
      ;[rclass] = item.property.rclasses
        ?.split('.')
        .map(piece => piece.replace(/[{}]/g, ''))

      return (
        <PlannerView
          key={blockIndex}
          columnproperty="Start Date"
          {...item.property}
          value={item.value}
          parentRecordId={recordId}
          parentRClass={rClass}
          editAction={item.editaction}
          addAction={item.addaction}
          filters={item.filters}
          selectedTab={selectedTab}
          rclass={rclass}
          required={block.required === 'true'}
        />
      )
    }

    if (isKanban) {
      const {
        property: { columnproperty, name, rclasses },
        editaction,
        value,
      } = columns[0].items[0]

      const { editaction: columnpropertyaction } =
        value.records?.[0].properties.find(prop => prop.name === columnproperty)

      let rclass
      ;[rclass] = rclasses?.split('.').map(piece => piece.replace(/[{}]/g, ''))

      // create a list of possible categories, to populate later
      const CATEGORIES = value.properties[columnproperty].options.reduce(
        (
          categories: {
            title: string
            color: string
            data: []
          }[],
          option: {
            name: string
            color: string
          }
        ) => {
          categories.push({
            title: option.name,
            color: option.color,
            data: [],
          })

          return categories
        },
        []
      )

      // format as { title, data } for the kanban view using the category data
      const DATA = value.records.reduce((dataByCategory, record) => {
        const columnProp = record.properties.find(
          prop => prop.name === columnproperty
        )
        if (columnProp) {
          const category = dataByCategory.find(
            ({ title }) => title === columnProp.value
          )

          category.data.push(record)
        }

        return dataByCategory
      }, CATEGORIES)

      return (
        <View key={blockIndex} style={{ flex: 1 }}>
          <Kanban
            data={DATA}
            parentRecordId={recordId}
            parentRClass={rClass}
            rclass={rclass}
            name={name}
            editaction={editaction}
            columnproperty={columnproperty}
            columnpropertyaction={columnpropertyaction}
          />
        </View>
      )
    }

    if (isGrid) {
      const {
        property: { rclasses, name, groupby, hideheaders },
        editaction,
        addaction,
        value,
        filters,
      } = columns[0].items[0]

      let rclass
      ;[rclass] = rclasses?.split('.').map(piece => piece.replace(/[{}]/g, ''))

      return (
        <Selection
          key={blockIndex}
          index={blockIndex}
          parentRecordId={recordId}
          parentrClass={rClass}
          propertyName={name}
          editAction={editaction}
          addAction={addaction}
          filters={filters}
          rclass={rclass}
          value={value}
          selectedTab={selectedTab}
          required={block.required === 'true'}
          groupby={groupby}
          hideheaders={hideheaders}
        />
      )
    }

    return (
      <Properties
        key={blockIndex}
        block={block}
        blockIndex={blockIndex}
        recordId={recordId}
        rClass={rClass}
      />
    )
  })
}

export default RecordView

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px 10px -12px;
  padding: 0 40px;
`
