import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useMemo,
  useEffect,
  RefObject,
} from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { Portal } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'
import WorktribeIcon from '../../../components/shared/WorktribeIcon'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { differenceInDays } from 'date-fns'
import type { IProject } from './impact-journey'
import {
  SIDE_WIDTH,
  HEADER_HEIGHT,
  MONTH_HEIGHT,
  MONTH_WIDTH_MIN,
  MONTH_WIDTH_MAX,
  MONTH_START_WIDTH,
  ZOOM_INCREMENT,
  useImpactPeriods,
  getTimelineEventsFromProjects,
  projects,
  animationConfig,
  monthNames,
} from './utils'
import { MediumButton, SmallButton, SplitButton } from '../../shared/Button'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'

import LaneSeparators from './LaneSeparators'
import Lane from './Lane'
import PeriodControls from './PeriodControls'
import ImpactEvents from './ImpactEvents'
import { AlignedRow } from '../../shared/Layout'
import InlineText from '../../landscape/controls/InlineText'
import InlineSelect from '../../landscape/controls/InlineSelect'

const ImpactJourney: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [_, setForceRender] = useState(true)
  const monthWidth = useSharedValue(MONTH_START_WIDTH)
  const {
    periods,
    currentYear,
    onJourneyScroll,
    scrollIndex,
    initialScrollOffset,
  } = useImpactPeriods(monthWidth.value)

  const timelineData: IProject[] = getTimelineEventsFromProjects(
    projects,
    monthWidth.value
  )

  const scrollRef = useRef<RefObject<ScrollView>>()

  const onChangePeriod = useCallback(
    (num: number) => {
      scrollRef.current.scrollTo({
        x: Math.max((scrollIndex + num) * monthWidth.value, 0),
        animated: true,
      })
    },
    [scrollIndex, monthWidth.value]
  )

  const contentHeight = useMemo(
    () => timelineData.reduce((sum, project) => (sum += project.height!)),
    [timelineData]
  )

  useEffect(() => {
    if (initialScrollOffset) {
      scrollRef.current?.scrollTo({
        x: initialScrollOffset,
        animated: false,
      })
    }
  }, [initialScrollOffset])

  const scrollToCurrentDay = useCallback(() => {
    const { year, month } = periods[0]
    const monthIndex = monthNames.findIndex(name => name === month)
    const start = new Date(year, monthIndex, 1)
    const today = new Date()
    const diff = differenceInDays(start, today)
    const offset = ((12 * monthWidth.value) / 365) * Math.floor(Math.abs(diff))

    scrollRef.current.scrollTo({
      x: offset,
      animated: true,
    })
  }, [periods, monthWidth.value])

  const zoomActions = useMemo(
    () => [
      {
        icon: 'zoom-in',
        onPress: () => {
          let initialScrollIndex = scrollIndex

          monthWidth.value = Math.min(
            (monthWidth.value += ZOOM_INCREMENT),
            MONTH_WIDTH_MAX
          )

          setTimeout(() => {
            scrollRef.current.scrollTo({
              x: monthWidth.value * initialScrollIndex,
              animated: true,
            })
          }, animationConfig.duration)
        },
        disabled: monthWidth.value === MONTH_WIDTH_MAX,
      },
      {
        icon: 'zoom-out',
        onPress: () => {
          let initialScrollIndex = scrollIndex
          monthWidth.value = Math.max(
            (monthWidth.value -= ZOOM_INCREMENT),
            MONTH_WIDTH_MIN
          )

          setTimeout(() => {
            scrollRef.current.scrollTo({
              x: monthWidth.value * initialScrollIndex,
              animated: true,
            })
          }, animationConfig.duration)
        },
        disabled: monthWidth.value === MONTH_WIDTH_MIN,
      },
    ],
    [monthWidth.value, scrollIndex]
  )

  const periodStyles = useAnimatedStyle(() => {
    return {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      width: withTiming(monthWidth.value, animationConfig),
    }
  })

  const {
    typography: { Body, Label, Button },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  return (
    <>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View
          style={{
            paddingHorizontal: 20,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 16,
          }}
        >
          <SmallButton onPress={scrollToCurrentDay}>
            <Button color="#1D7A55">Today</Button>
          </SmallButton>

          <SplitButton>
            {zoomActions
              .map(({ icon, onPress, disabled }) => (
                <Pressable
                  disabled={disabled}
                  key={icon}
                  style={{
                    paddingVertical: 8,
                    paddingHorizontal: 16,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    onPress()
                    setForceRender(render => !render)
                  }}
                >
                  <WorktribeIcon
                    color={disabled ? '#676C85' : '#1D7A55'}
                    name={icon}
                  />
                </Pressable>
              ))
              .reduce((prev, curr, index) => [
                prev,
                <View
                  key={index}
                  style={{
                    width: 1,
                    height: '100%',
                    backgroundColor: accents.separator,
                  }}
                />,
                curr,
              ])}
          </SplitButton>

          <SmallButton onPress={() => setShowModal(true)}>
            <Button color="#1D7A55">Add note</Button>
          </SmallButton>
        </View>

        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            backgroundColor: bg.primary,
            minHeight: contentHeight + HEADER_HEIGHT,
          }}
        >
          <View
            style={[
              {
                width: SIDE_WIDTH,
              },
              {
                shadowColor: 'rgba(0, 0, 0, 0.16)',
                shadowOffset: {
                  width: 3,
                  height: 10,
                },
                shadowOpacity: 0.78,
                shadowRadius: 12.0,
                elevation: 24,
              },
            ]}
          >
            {timelineData.map(project => (
              <Lane
                key={project.id}
                project={project}
                periods={periods}
                monthWidth={monthWidth}
              />
            ))}
          </View>

          <LaneSeparators
            projects={timelineData}
            periods={periods}
            monthWidth={monthWidth}
          />

          <PeriodControls onPress={onChangePeriod} />

          <View style={{ flex: 1 }}>
            <Label style={{ marginLeft: 8 }}>{currentYear}</Label>

            <View style={{ flex: 1, zIndex: 99 }}>
              <ScrollView
                ref={scrollRef}
                horizontal
                onScroll={onJourneyScroll}
                scrollEventThrottle={16}
              >
                {periods.map((period, index) => (
                  <Animated.View
                    key={`${period.month}_${period.year}`}
                    style={[
                      periodStyles,
                      {
                        backgroundColor: index % 2 === 0 ? '' : '#F4F5F9',
                        zIndex: -index,
                      },
                    ]}
                  >
                    <View
                      style={{
                        height: MONTH_HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomWidth: 1,
                        borderColor: accents.separator,
                      }}
                    >
                      <Body>
                        {monthWidth.value < 100
                          ? period.month.slice(0, 3)
                          : period.month}
                      </Body>
                    </View>

                    {/* render events from the start of the grid */}
                    {index === 0 && (
                      <ImpactEvents
                        data={timelineData}
                        periods={periods}
                        contentHeight={contentHeight}
                        monthWidth={monthWidth}
                      />
                    )}
                  </Animated.View>
                ))}
              </ScrollView>
            </View>
          </View>
        </View>
      </ScrollView>

      <Portal>
        <WorktribeAlert
          useNativeDriver={true}
          contentContainerStyle={{
            backgroundColor: bg.primary,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 20,
            shadowColor: 'rgba(0, 0, 0, 0.06)',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0,
            shadowRadius: 32,
            padding: 0,
          }}
          contentStyle={{ padding: 24 }}
          overlayStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          alertContainerStyle={{ padding: 0, margin: 0 }}
          show={showModal}
          customView={<JourneyModal onClose={() => setShowModal(false)} />}
          onDismiss={() => setShowModal(false)}
        />
      </Portal>
    </>
  )
}

export default ImpactJourney

const fields = [
  {
    name: 'Title',
    value: 'Output',
    icon: 'pencil-1',
    type: 'text',
  },
  {
    name: 'Description',
    value: 'Output to measure UKRI Funding scheme',
    icon: 'task-checklist',
    type: 'text',
  },
]

const JourneyModal = ({ onClose }) => {
  const {
    typography: { Button },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [_, setData] = useState({})

  return (
    <View style={{ width: 492, flex: 1 }}>
      <View style={{ flex: 1, zIndex: 99 }}>
        {fields.map(({ name, value, icon, type }) => (
          <AlignedRow key={name} gap={16} style={{ marginBottom: 12 }}>
            <WorktribeIcon color="#676C85" name={icon} />

            <View style={{ flex: 1 }}>
              {type === 'text' && (
                <InlineText
                  item={{
                    property: {
                      name,
                    },
                  }}
                  value={{
                    value,
                  }}
                  onChange={value => {
                    setData(data => ({
                      ...data,
                      [name]: value,
                    }))
                  }}
                  inputBackgroundColor={bg.card}
                />
              )}
            </View>
          </AlignedRow>
        ))}

        <AlignedRow style={{ flex: 1, gap: 12 }}>
          <AlignedRow gap={16} style={{ marginBottom: 12, flex: 1 }}>
            <WorktribeIcon
              size={24}
              color="#676C85"
              name="bookmarks-document"
            />

            <View style={{ flex: 1 }}>
              <InlineSelect
                item={{
                  property: {
                    type: 'select',
                    name: 'Type',
                  },
                }}
                value={{
                  value: [],
                }}
                onChange={value => {
                  setData(data => ({
                    ...data,
                    ['Type']: value,
                  }))
                }}
                modal
                optionBackgroundColor={bg.card}
              />
            </View>
          </AlignedRow>

          <AlignedRow gap={16} style={{ marginBottom: 12, flex: 1 }}>
            <WorktribeIcon color="#676C85" name="book-library-shelf" />

            <View style={{ flex: 1 }}>
              <InlineSelect
                item={{
                  property: {
                    type: 'select',
                    name: 'Flag',
                  },
                }}
                value={{
                  value: ['UKRI Funding'],
                }}
                onChange={value => {
                  setData(data => ({
                    ...data,
                    ['Flag']: value,
                  }))
                }}
                modal
                optionBackgroundColor={bg.card}
              />
            </View>
          </AlignedRow>
        </AlignedRow>
      </View>

      <AlignedRow gap={16} justifyContent="flex-end" style={{ marginTop: 12 }}>
        <MediumButton onPress={onClose}>
          <Button>Cancel</Button>
        </MediumButton>

        <MediumButton primary onPress={onClose}>
          <Button color="#FFF">Add note</Button>
        </MediumButton>
      </AlignedRow>
    </View>
  )
}
