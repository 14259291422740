import React, { useEffect, useReducer, useRef, useState } from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'
import { BigButton } from '../../components/shared/Button'
import * as Typography from '../../components/shared/Typography'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AntDesign } from '@expo/vector-icons'
import TopBar from '../../components/shared/TopBar'
import styled from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'
import InputField from '../../components/shared/InputField'
import Select from '../../components/modals/Select'

const Body = styled.ScrollView`
  flex: 1;
  padding: 0 16px;
`

const Footer = styled.View`
  padding: 10px 20px;
`

const ImageText = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  letter-spacing: 0.25px;
  color: #ffffff;
  margin-bottom: 12px;
`

const InfoText = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  line-height: 24px;
  color: #757885;
  margin-bottom: 12px;
`

const InputGroupContainer = styled.View`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
`

const InputSeperator = styled.View`
  height: 1px;
  background-color: #dde1ed;
`

const InputTextAbove = styled.Text`
  margin-bottom: 8px;
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

const InputTextBelow = styled.Text`
  margin-top: 8px;
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

function createReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.field]: action.payload.value,
        },
      }
    default:
      return state
  }
}

const fieldGroups = [
  {
    fields: [
      {
        name: 'Module title',
        type: 'text',
        required: true,
      },
      {
        name: 'Module code',
        type: 'text',
        required: true,
      },
      {
        name: 'Credits',
        type: 'text',
        required: true,
      },
      {
        name: 'Worktribe',
        type: 'selectone',
        required: true,
        options: [
          {
            title: 'University of Southampton',
            image: 'G02AR7pa_Lo',
          },
          {
            title: 'Imperial College London',
            image: 'SLBjcE5IQxo',
          },
          {
            title: 'London School of Economics',
            image: 'WXgiTeZRXGU',
          },
          {
            title: 'Brighton university',
            image: 'DtkhxjzL_G4',
          },
          {
            title: 'University of Edinburgh',
            image: 'XL0Dzfkactw',
          },
          {
            title: 'London City University',
            image: 'S6k4vSWrIzA',
          },
          {
            title: 'De Montfort University',
            image: 'C1wAFPZFes8',
          },
          {
            title: 'University of Leicester',
            image: 'BwQ8ZA23W6g',
          },
        ],
      },
    ],
  },
  {
    textBelow: 'Select which teaching levels this module belongs to',
    fields: [
      {
        name: 'Levels',
        type: 'selectmultiple',
        required: true,
        options: [
          {
            title: 'Level 1',
            image: 'G02AR7pa_Lo',
          },
          {
            title: 'Level 2',
            image: 'SLBjcE5IQxo',
          },
          {
            title: 'Level 3',
            image: 'WXgiTeZRXGU',
          },
          {
            title: 'Level 4',
            image: 'DtkhxjzL_G4',
          },
          {
            title: 'Level 5',
            image: 'XL0Dzfkactw',
          },
          {
            title: 'Level 6',
            image: 'BwQ8ZA23W6g',
          },
          {
            title: 'Level 7',
            image: 'C1wAFPZFes8',
          },
        ],
      },
    ],
  },
  {
    textAbove: 'Assign People',
    fields: [
      {
        name: 'Leader',
        type: 'selectaro',
        required: true,
        options: [],
      },
      {
        name: 'Coordinator',
        type: 'selectaro',
        required: true,
        options: [],
      },
    ],
  },
]

// const fieldGroups = [
//   {
//     fields: [
//       {
//         name: 'Project',
//         type: 'selectone',
//         required: true,
//         options: [
//           { title: 'Big Bogger' },
//         ]
//       },
//       {
//         name: 'Title',
//         type: 'textarea',
//         maxLines: 4,
//         required: true
//       },
//       {
//         name: 'Long project summary',
//         type: 'textarea',
//         required: true
//       }
//     ]
//   },
//   {
//     textAbove: 'Dates',
//     fields: [
//       {
//         name: 'Date from',
//         type: 'date',
//       },
//       {
//         name: 'Date to',
//         type: 'date',
//       },
//     ]
//   }
// ]

const CreateScreen = ({ navigation, route }) => {
  const { title } = route.params

  const [state, dispatch] = useReducer(createReducer, {
    fields: {},
  })

  const [validated, setValidated] = useState(false)

  useEffect(() => {
    let validated = true
    fieldGroups.forEach(group => {
      group.fields.forEach(field => {
        if (field.required) {
          if (!state.fields[field.name] || !state.fields[field.name].length) {
            validated = false
          }
        }
      })
    })
    setValidated(validated)
  }, [state])

  const [modalData, setModalData] = useState(null)

  const modalizeRef = useRef(null)

  useEffect(() => {
    if (modalData?.options.length) {
      modalizeRef.current?.open()
    }
  }, [modalData])

  return (
    <SafeAreaView style={styles.container}>
      <TopBar
        leftItem={
          <AntDesign
            onPress={() => navigation.pop()}
            name="close"
            size={24}
            color="#0E1012"
          />
        }
        title={title}
      />

      <Body>
        <ImageBackground
          style={{
            height: 140,
            width: 140,
            marginBottom: 16,
            alignSelf: 'center',
          }}
          imageStyle={{
            borderRadius: 30,
          }}
          source={{ uri: `https://source.unsplash.com/G02AR7pa_Lo/` }}
        >
          <LinearGradient
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0.5 }}
            colors={['rgba(0,0,0,0.7)', 'rgba(0, 0, 0, 0)']}
            style={styles.gradient}
          >
            <ImageText>Edit</ImageText>
          </LinearGradient>
        </ImageBackground>
        <InfoText>
          Please enter all the necessary information to create a module
        </InfoText>

        {fieldGroups.map((group, idx) => (
          <View key={idx} style={{ marginBottom: 32 }}>
            {group.textAbove ? (
              <InputTextAbove>{group.textAbove}</InputTextAbove>
            ) : null}
            <InputGroupContainer>
              {group.fields
                .map((field, idx) => (
                  <InputField
                    navigation={navigation}
                    key={field.name}
                    field={field}
                    state={state}
                    dispatch={dispatch}
                    setModalData={setModalData}
                  />
                ))
                .reduce((prev, curr) => [
                  prev,
                  <InputSeperator key={1} />,
                  curr,
                ])}
            </InputGroupContainer>
            {group.textBelow ? (
              <InputTextBelow>{group.textBelow}</InputTextBelow>
            ) : null}
          </View>
        ))}
      </Body>

      <Footer>
        <BigButton
          primary
          disabled={!validated}
          enabled={validated}
          onPress={() =>
            navigation.navigate('Module', {
              fieldData: state.fields,
            })
          }
        >
          <Typography.Button color="#FFFFFF">Create module</Typography.Button>
        </BigButton>
      </Footer>

      <Select
        modalizeRef={modalizeRef}
        modalData={modalData}
        dispatch={dispatch}
      />
    </SafeAreaView>
  )
}

export default CreateScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EBECF2',
  },
  gradient: {
    borderRadius: 30,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
