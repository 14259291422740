import React, { useContext } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { AuthContext, useAuth } from '../../contexts/AuthContext'

import { AlignedRow } from './Layout'
import Avatar from './Avatar'
import useTimeSince from '../../hooks/useTimeSince'
import WorktribeIcon from './WorktribeIcon'

const CardMessage = ({
  message,
  style,
  messageColor,
  unseen,
  notifications_enabled,
}) => {
  const { user } = useAuth()

  const { getFormattedShortDate } = useTimeSince()

  return (
    <AlignedRow {...{ style }}>
      <Avatar radius={12} userId={message.user.id} />
      <View style={styles.messageContainer}>
        <AlignedRow justifyContent="space-between">
          <View style={{ flex: 1 }}>
            <Text style={styles.authorText}>
              {message.user.name}
              <Text> </Text>
              <Text style={styles.dateText}>
                {getFormattedShortDate(
                  new Date(message.created_at),
                  user?.preferred_lang
                )}
              </Text>
            </Text>
          </View>
          {unseen > 0 ? (
            <View
              style={{
                paddingVertical: 1,
                paddingHorizontal: 8,
                backgroundColor: '#2AAD79',
                borderRadius: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontFamily: 'proxima-nova-semibold',
                  fontSize: 14,
                  color: '#FFFFFF',
                }}
              >
                {unseen > 9 ? `9+` : `${unseen}`}
              </Text>
            </View>
          ) : !notifications_enabled ? (
            <WorktribeIcon name="muted" />
          ) : null}
        </AlignedRow>
        <Text
          numberOfLines={1}
          style={[styles.messageText, messageColor && { color: messageColor }]}
        >
          {message.text}
        </Text>
      </View>
    </AlignedRow>
  )
}

export default CardMessage

const styles = StyleSheet.create({
  messageContainer: {
    flex: 1,
    marginLeft: 12,
  },
  messageText: {
    fontFamily: 'proxima-nova',
    fontSize: 17,
    lineHeight: 24,
    color: '#757885',
  },
  authorText: {
    fontFamily: 'proxima-nova-semibold',
    fontSize: 17,
    lineHeight: 24,
    color: '#0E1012',
  },
  dateText: {
    fontFamily: 'proxima-nova',
    fontSize: 14,
    lineHeight: 18,
    color: '#A0A4B8',
    marginTop: 4,
  },
})
