import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from 'react'
import {
  Button,
  StyleSheet,
  TextInput,
  Text,
  ActivityIndicator,
  SafeAreaView,
} from 'react-native'
import { AlignedRow, CenteredView } from '../../components/shared/Layout'
import WorktribeAlert from 'react-native-awesome-alerts'
import ConversationHeader from '../../components/shared/ConversationHeader'
import { useDispatch, useSelector } from 'react-redux'
import Chat from '../../components/shared/Chat'
import { updateRecords } from '../../store/actions/records'
import { APIContext } from '../../contexts/APIContext'

const SCREEN_NAME = 'messages'

const ConversationScreen = ({ navigation, route }) => {
  const { conversationId } = route.params
  const { live } = useSelector(state => state.records)

  const [conversation, setConversation] = useState(null)

  useEffect(() => {
    const conversation = live[SCREEN_NAME]['records'].find(
      record => record.id === conversationId
    )

    setConversation(conversation)
  }, [live, conversationId])

  const [isModalVisible, setModalVisible] = useState(false)
  const [groupName, setGroupName] = useState('')

  const modalizeRef = useRef(null)

  const openOptionsMenu = () => {
    modalizeRef.current?.open()
  }

  const toggleModal = useCallback(
    () => setModalVisible(!isModalVisible),
    [isModalVisible]
  )

  const { updateConversationName } = useContext(APIContext)
  const dispatch = useDispatch()

  const modifyConversationName = useCallback(async () => {
    try {
      const response = await updateConversationName(conversationId, groupName)
      const { record } = response.data

      dispatch(
        updateRecords({
          ...record,
          name: groupName,
          looked_at: new Date(),
        })
      )
    } catch (e) {
      console.warn(e)
    } finally {
      setModalVisible(false)
    }
  }, [groupName, conversationId])

  const modalContent = () => (
    <>
      <Text
        style={{
          fontFamily: 'proxima-nova-semibold',
          fontWeight: '600',
          fontSize: 20,
          lineHeight: 24,
        }}
      >
        Conversation name
      </Text>
      <TextInput
        placeholder="Update the conversation name"
        style={[
          styles.groupInput,
          Platform.OS === 'web' ? { outlineWidth: 0 } : {},
        ]}
        onChangeText={text => setGroupName(text)}
        defaultValue={conversation?.name}
        maxLength={128}
      />
      <AlignedRow justifyContent="center">
        <Button
          title="Close"
          onPress={toggleModal}
          style={{
            marginHorizontal: 5,
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#2AAD79',
          }}
          titleStyle={{
            color: '#2AAD79',
          }}
        />
        <Button
          title="Update"
          onPress={modifyConversationName}
          style={{ marginHorizontal: 5 }}
        />
      </AlignedRow>
    </>
  )

  return (
    <>
      <SafeAreaView style={styles.container}>
        <WorktribeAlert
          contentContainerStyle={{
            backgroundColor: 'white',
            paddingVertical: 24,
            paddingHorizontal: 20,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
            shadowColor: 'rgba(0, 0, 0, 0.06)',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0,
            shadowRadius: 32,
          }}
          alertContainerStyle={{ zIndex: 1 }}
          show={isModalVisible}
          customView={modalContent()}
        />

        {conversation ? (
          <>
            <ConversationHeader
              navigation={navigation}
              conversation={conversation}
              toggleModal={toggleModal}
              openOptionsMenu={openOptionsMenu}
            />

            <Chat
              record={conversation}
              modalRef={modalizeRef}
              commentContainerStyle={{ marginHorizontal: 16 }}
            />
          </>
        ) : (
          <CenteredView>
            <ActivityIndicator />
          </CenteredView>
        )}
      </SafeAreaView>
    </>
  )
}

export default ConversationScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  menuItemText: {
    fontSize: 17,
    fontFamily: 'proxima-nova-semibold',
  },
  content: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    shadowColor: 'rgba(0, 0, 0, 0.06)',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0,
    shadowRadius: 32,
  },
  contentTitle: {
    fontSize: 20,
    marginBottom: 12,
  },
  groupInput: {
    height: 40,
    width: 250,
    borderColor: 'gray',
    borderBottomWidth: 1,
    marginVertical: 10,
    backgroundColor: 'white',
    color: 'black',
    paddingHorizontal: 5,
  },
  appButtonContainer: {
    elevation: 8,
    backgroundColor: '#009688',
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginVertical: 5,
    width: 250,
  },
  appButtonText: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
})
