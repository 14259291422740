import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'

const ActivityIndicatorSplash = () => (
  <View style={styles.container}>
    <ActivityIndicator color={'#fff'} size={'large'} />
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1B283F',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default ActivityIndicatorSplash
