import React, { useContext } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { TouchableOpacity, View } from 'react-native'
import { SmallButton, TinyButton } from '../shared/Button'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const COSTS = [
  {
    type: 'Stationery',
    title: 'All the stationery needed',
    value: 'From £240.00 to £560.00',
  },
  {
    type: 'Stationery',
    title: 'All the stationery needed',
    value: 'From £240.00 to £560.00',
  },
  {
    type: 'Stationery',
    title: 'All the stationery needed',
    value: 'From £240.00 to £560.00',
  },
  {
    type: 'Stationery',
    title: 'All the stationery needed',
    value: 'From £240.00 to £560.00',
  },
]

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
`

const CostRow = ({ cost }) => {
  const {
    typography: { Body, H5, Label },
  } = useContext(ThemeContext)
  return (
    <Row
      style={{
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        marginBottom: 12,
        alignItems: 'center',
      }}
    >
      <Col>
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 24,
            }}
            activeOpacity={1}
          >
            <Thumbnail
              source={{
                uri: `https://source.unsplash.com/WXgiTeZRXGU/50x50`,
                cache: 'force-cache',
              }}
            />
          </TouchableOpacity>

          <View style={{ flex: 1 }}>
            <Label color="#757885">{cost.type}</Label>
            <H5 color="#0E1012">{cost.title}</H5>
          </View>
        </AlignedRow>
      </Col>

      <Col>
        <Body color="#0E1012">{cost.value}</Body>
      </Col>
    </Row>
  )
}

const Costs = ({ record }) => {
  const {
    typography: { H3, H5, Button },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        <AlignedRow justifyContent="space-between">
          <AlignedRow>
            <H3>Costs</H3>
          </AlignedRow>

          <SmallButton>
            <Button color="#1D7A55">Add student costs</Button>
          </SmallButton>
        </AlignedRow>
        <Grid
          style={{
            marginTop: 20,
            marginBottom: 36,
            flexDirection: 'column',
          }}
        >
          <Row
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
            }}
          >
            <Col>
              <H5 style={{ marginLeft: 72 }} color="#0E1012">
                Description
              </H5>
            </Col>
            <Col>
              <H5 color="#0E1012">Cost</H5>
            </Col>
          </Row>
          {COSTS.map((cost, i) => (
            <CostRow key={`${cost.title}-${i}`} cost={cost} />
          ))}
        </Grid>
      </Content>
    </Container>
  )
}

export default Costs

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
