import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { SmallButton } from '../../shared/Button'
import ColumnHeader from './ui/ColumnHeader'
import { TabContainer, Tab } from './ui/Tabs'
import AvatarList from './ui/AvatarList'
import { AlignedRow, CenteredView } from '../../shared/Layout'
import Chat from '../../shared/Chat'
import { AuthContext } from '../../../contexts/AuthContext'
import { APIContext } from '../../../contexts/APIContext'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRecord, addToHistory } from '../../../store/actions/records'
import { ThemeContext } from '../../../contexts/ThemeContext'
import WorktribeIcon from '../../shared/WorktribeIcon'

import Overview from '../Overview'
import Details from '../Properties'
import Outcomes from '../Outcomes'
import Assessments from '../Assessments'
import Hours from '../Hours'
import Subjects from '../Subjects'
import Costs from '../Costs'
import Deliveries from '../Deliveries'
import Documents from '../Documents'
import Ellipsis from '../../shared/Ellipsis'
import Searchatron from '../Searchatron'
import GridRenderer from '../GridRenderer'

const Container = styled.View`
  flex: 1;
`

const staticTabs = ['Overview', 'Chat']

const Header = ({ selectedRecord, setShowSearch }) => {
  const {
    typography: { H2, Button },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)
  return (
    <>
      <AlignedRow style={{ flex: 1 }}>
        <H2 numberOfLines={1} primary>
          {selectedRecord.name}
        </H2>
      </AlignedRow>

      <AlignedRow>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => setShowSearch(show => !show)}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 36,
            height: 36,
            borderRadius: 18,
            borderWidth: 1,
            borderColor: accents.separator,
          }}
        >
          <WorktribeIcon size={20} name="search-alternate" />
        </TouchableOpacity>

        <SmallButton primary style={{ marginLeft: 16 }}>
          <Button color="#FFF">Submit for approval</Button>
        </SmallButton>

        <SmallButton style={{ marginLeft: 16 }}>
          <WorktribeIcon name="more" />
        </SmallButton>
      </AlignedRow>
    </>
  )
}

const Section = ({ record, section }) => {
  if (Array.isArray(section.block)) {
    return <Details record={record} blocks={section.block} />
  }

  if (section.property?.length) {
    const renderedProps = section.property.filter(s => 'renderer' in s)

    if (renderedProps.length) {
      return renderedProps.map(property => {
        switch (property.renderer) {
          case 'grid': {
            return <GridRenderer record={record} property={property} />
          }
          default:
            return null
        }
      })
    }

    return (
      <Details
        record={record}
        blocks={[
          {
            column: [{ property: section.property }],
          },
        ]}
      />
    )
  }
}

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => {
  const {
    typography: { Button },
  } = useContext(ThemeContext)
  return (
    <TabContainer style={{ paddingBottom: 20, paddingLeft: 30 }}>
      {tabs.map(tab => (
        <Tab
          key={tab}
          onPress={() => setSelectedTab(tab)}
          active={selectedTab === tab}
        >
          <Button color={selectedTab === tab ? `#1D7A55` : `#0E1012`}>
            {tab}
          </Button>
        </Tab>
      ))}
    </TabContainer>
  )
}

const Record = ({ recordId, rClass }) => {
  const { getRecord, getRClass } = useContext(APIContext)

  const [selectedTab, setSelectedTab] = useState('')
  const [showSearch, setShowSearch] = useState(false)

  // layout state
  const [properties, setProperties] = useState(null)
  const [tabs, setTabs] = useState([])

  const { selectedRecord } = useSelector(state => state.records)

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchRecord() {
      try {
        const response = await getRecord(rClass, recordId)

        const { record } = response.data

        dispatch(setSelectedRecord(record))
        dispatch(addToHistory(record))
      } catch (e) {
        console.warn(e)
      } finally {
        setSelectedTab('Chat')
      }
    }

    void fetchRecord()

    return () => dispatch(setSelectedRecord(null))
  }, [recordId, rClass])

  useEffect(() => {
    if (!rClass) return

    async function fetchRClass() {
      try {
        const response = await getRClass(rClass)

        const {
          spec: { properties },
        } = response.data

        setProperties(properties)
      } catch (e) {
        console.warn(e)
      }
    }

    void fetchRClass()

    return () => setProperties(null)
  }, [rClass])

  useEffect(() => {
    if (!properties) return

    const { section } = properties

    if (Array.isArray(section)) {
      setTabs([...staticTabs, ...section.map(s => s.name)])
    }

    return () => setTabs([])
  }, [properties])

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      <Container>
        {selectedRecord ? (
          <>
            <ColumnHeader>
              <Header
                selectedRecord={selectedRecord}
                setShowSearch={setShowSearch}
              />
            </ColumnHeader>

            <Chat
              record={selectedRecord}
              commentContainerStyle={{ paddingHorizontal: 28 }}
            />
          </>
        ) : (
          <CenteredView>
            <ActivityIndicator color="#0A406A" />
          </CenteredView>
        )}
      </Container>
    </>
  )
}

export default Record
