import { useRef, useEffect, useCallback } from 'react'
import { AppState } from 'react-native'
import usePagedRecords from './usePagedRecords'

const useRecordList = screen => {
  const state = usePagedRecords(screen)

  const appState = useRef(AppState.currentState)

  const _handleAppStateChange = useCallback(
    nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        state.onRefresh()
      }

      appState.current = nextAppState
    },
    [appState]
  )

  useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange)

    return () => {
      AppState.removeEventListener('change', _handleAppStateChange)
    }
  }, [_handleAppStateChange])

  return { ...state }
}

export default useRecordList
