import React, { useState, useEffect } from 'react'
import {
  Text,
  Image,
  View,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native'
import * as Google from 'expo-auth-session/providers/google'
import * as WebBrowser from 'expo-web-browser'
import * as AppleAuthentication from 'expo-apple-authentication'

import { AntDesign } from '@expo/vector-icons'
import { AlignedRow } from './Layout'

WebBrowser.maybeCompleteAuthSession()

const SingleSignOn = ({ onSignIn, onError }) => {
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    responseType: 'id_token',
    expoClientId:
      '407505106364-213iih2lk1qlejetul795s2vlnq2p5nf.apps.googleusercontent.com',
    iosClientId:
      '407505106364-c49f1paigvosblmneehp54gddeipc8d9.apps.googleusercontent.com',
    androidClientId:
      '407505106364-nonifbm1fvj72jigodt7efticvgfmt8n.apps.googleusercontent.com',
    webClientId:
      '407505106364-213iih2lk1qlejetul795s2vlnq2p5nf.apps.googleusercontent.com',
  })

  useEffect(() => {
    setInProgress(true)
    if (response?.type === 'success') {
      onSignIn({
        provider: 'google',
        idToken: response.params.id_token,
      })
    } else if (response?.type === 'error') {
      onError({ provider: 'google' })
    }
    setInProgress(false)
  }, [response])

  const [inProgress, setInProgress] = useState(false) // For disabling buttons

  const onAppleSignIn = async e => {
    try {
      setInProgress(true)

      const userInfo = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      })

      onSignIn({
        provider: 'apple',
        idToken: userInfo.identityToken,
        email: userInfo.email,
        givenName: userInfo.fullName.givenName,
        familyName: userInfo.fullName.familyName,
      })
    } catch (error) {
      if (error.code === 'ERR_CANCELED') {
      } else {
        error.provider = 'apple'
        onError(error)
      }
    } finally {
      setInProgress(false)
    }
  }

  return (
    <View style={styles.container}>
      {Platform.OS == 'ios' && (
        <TouchableOpacity
          style={styles.button}
          onPress={onAppleSignIn}
          disabled={inProgress}
        >
          <AlignedRow>
            <AntDesign name="apple1" size={20} color="black" />
            <Text style={[styles.text, { marginLeft: 4 }]}>
              Continue with Apple
            </Text>
          </AlignedRow>
        </TouchableOpacity>
      )}
      {Platform.OS != 'web' && (
        <View>
          <TouchableOpacity
            style={[styles.button]}
            onPress={() => {
              promptAsync()
            }}
            disabled={inProgress}
          >
            <Image
              style={styles.googleIcon}
              source={require('../../../assets/google-logo.png')}
            />
            <Text style={styles.text}>Continue with Google</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

export default SingleSignOn

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 13,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: 56,
    backgroundColor: '#ffffff',
    borderRadius: 12,
    marginTop: 15,
  },
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#292222',
  },
  googleIcon: {
    marginRight: 5,
    width: 23,
    height: 23,
  },
  appleIcon: {
    marginRight: 5,
    marginTop: -3,
    fontSize: 30,
    color: '#222222',
  },
})
