import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  ScrollView,
  TouchableOpacity,
  View,
  Animated,
  Easing,
  TextInput,
  Platform,
  Pressable,
} from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../../../contexts/ThemeContext'
import { AlignedRow } from '../../../shared/Layout'
import WorktribeIcon from '../../../shared/WorktribeIcon'
import HoverableOpacity from '../../../shared/HoverableOpacity'

import useOutsideAlerter from '../../../../hooks/useOutsideAlerter'
import InlineCalendar from '../../../shared/InlineCalendar'
import useRecordAutoComplete from '../../../../hooks/useRecordAutoComplete'

const GridFilter = ({ index, filter, show, filterHandler }) => {
  const [name, config] = filter

  const {
    typography: { Body },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [filterName, setFilterName] = useState(name)
  const [showFilter, setShowFilter] = useState(false)
  const [value, setValue] = useState(null)

  const toggleFilter = () => {
    setShowFilter(show => !show)
  }

  useEffect(() => {
    filterHandler(filters => {
      return {
        ...filters,
        [name]: {
          value,
          type: config.type,
        },
      }
    })
  }, [value])

  const animatedController = useRef(new Animated.Value(0)).current
  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  })

  useEffect(() => {
    if (showFilter) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    }
  }, [showFilter])

  useEffect(() => {
    if (!value || value.length === 0) {
      setFilterName(name)
    } else {
      if (Array.isArray(value)) {
        const titleArray = value.map(v => v.title ?? v.name)

        const firstTwo = titleArray.slice(0, 2)
        const difference = titleArray.length - firstTwo.length
        const suffix = difference > 0 ? `+${difference} more` : ``

        setFilterName(`${firstTwo.join(', ')} ${suffix}`)
      } else {
        if (value.recordid) {
          setFilterName(value.properties[0].value)
        } else if (config.type === 'date') {
          setFilterName(value.toDateString())
        } else {
          setFilterName(value)
        }
      }
    }
  }, [value])

  const outsideHandler = useCallback(() => setShowFilter(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <Filter
      ref={wrapperRef}
      style={{
        zIndex: -index,
        backgroundColor: value?.length ? `#F2FCF8` : bg.primary,
      }}
      selected={value?.length}
    >
      <Pressable onPress={toggleFilter}>
        <AlignedRow style={{ paddingVertical: 9, paddingHorizontal: 12 }}>
          <Body
            primary={!value?.length}
            color="#1D7A55"
            style={{ marginLeft: 5, marginRight: 16 }}
          >
            {filterName}
          </Body>
          <Animated.View
            style={{
              transform: [{ rotateZ: arrowAngle }],
            }}
          >
            <WorktribeIcon
              color={value ? `#1D7A55` : `#757885`}
              name="chevron-down"
            />
          </Animated.View>
        </AlignedRow>
      </Pressable>

      {showFilter && (
        <>
          {
            {
              status: (
                <StatusSelect
                  // ref={wrapperRef}
                  setValue={setValue}
                  value={value}
                  config={config}
                  closeHandler={() => setShowFilter(false)}
                />
              ),
              select: (
                <ItemSelect
                  // ref={wrapperRef}
                  setValue={setValue}
                  value={value}
                  config={config}
                  closeHandler={() => setShowFilter(false)}
                />
              ),
              date: (
                <Calendar
                  // ref={wrapperRef}
                  setValue={setValue}
                  value={value}
                  config={config}
                  closeHandler={() => setShowFilter(false)}
                />
              ),
              record: (
                <RecordFilterSelect
                  // ref={wrapperRef}
                  setValue={setValue}
                  value={value}
                  config={config}
                  closeHandler={() => setShowFilter(false)}
                />
              ),
            }[config.type]
          }
        </>
      )}
    </Filter>
  )
}

const GridFilters = ({ filters, filterHandler }) => {
  return (
    <AlignedRow style={{ flexWrap: 'wrap', zIndex: 1 }}>
      {Object.entries(filters).map((filter, index) => {
        return (
          <GridFilter
            key={filter}
            index={index}
            filterHandler={filterHandler}
            filter={filter}
          />
        )
      })}
    </AlignedRow>
  )
}

const ItemSelect = props => {
  const {
    typography: { Body },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  const {
    config: { options },
    setValue,
    value,
  } = props

  const [selectedItems, setSelectedItems] = useState(() => {
    if (value && value.length) return value
    return []
  })

  useEffect(() => {
    setValue(selectedItems)
  }, [selectedItems])

  return (
    <View
      style={[
        {
          position: 'absolute',
          paddingVertical: 4,
          top: 45,
          width: 350,
          maxHeight: 272,
          backgroundColor: bg.primary,
          borderRadius: 16,
        },
        shadowStyles,
      ]}
    >
      <ScrollView>
        {options
          .filter(option => option.name)
          .map(option => (
            <HoverableOpacity
              key={option.name}
              hoverStyle={{ backgroundColor: hover }}
              onPress={() => {
                setSelectedItems(selected => {
                  const existingOptionIndex = selected.findIndex(
                    item => item.name === option.name
                  )

                  if (existingOptionIndex > -1) {
                    return [...selected].filter(
                      item => item.name !== option.name
                    )
                  }

                  return [...selected, option]
                })
              }}
            >
              <AlignedRow
                style={{
                  paddingVertical: 14.5,
                  paddingHorizontal: 16.5,
                }}
              >
                {selectedItems.findIndex(item => item.name === option.name) >
                -1 ? (
                  <View style={{ marginRight: 12 }}>
                    <WorktribeIcon name="check-2" />
                  </View>
                ) : (
                  <View
                    style={{
                      height: 16,
                      width: 16,
                      borderWidth: 1.5,
                      borderColor: '#A0A4B8',
                      marginRight: 12,
                    }}
                  />
                )}

                <Body>{option.name}</Body>
              </AlignedRow>
            </HoverableOpacity>
          ))}
      </ScrollView>
    </View>
  )
}

const StatusSelect = props => {
  const {
    typography: { Body },
    themeColors: { bg, hover, status },
    shadowStyles,
  } = useContext(ThemeContext)

  const {
    config: { options },
    setValue,
    value,
    closeHandler,
  } = props

  return (
    <View
      style={[
        {
          position: 'absolute',
          paddingVertical: 4,
          top: 45,
          width: 350,
          maxHeight: 272,
          backgroundColor: bg.primary,
          borderRadius: 16,
        },
        shadowStyles,
      ]}
    >
      <ScrollView>
        {options.map(option => (
          <HoverableOpacity
            key={option.name}
            hoverStyle={{ backgroundColor: hover }}
            onPress={() => {
              setValue(value => {
                if (value === option.name) {
                  return null
                }
                return option.name
              })
              closeHandler()
            }}
          >
            <AlignedRow
              style={{
                paddingVertical: 14.5,
                paddingHorizontal: 16.5,
              }}
            >
              {value === option.name ? (
                <View style={{ marginRight: 12 }}>
                  <WorktribeIcon name="check-2" />
                </View>
              ) : (
                <View
                  style={{
                    height: 16,
                    width: 16,
                    borderWidth: 1.5,
                    borderColor: '#A0A4B8',
                    marginRight: 12,
                  }}
                />
              )}

              <View
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  backgroundColor: status.darker[option.color],
                  marginRight: 12,
                  marginBottom: 1,
                }}
              />
              <Body primary>{option.name}</Body>
            </AlignedRow>
          </HoverableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

const Calendar = props => {
  const { setValue, closeHandler } = props

  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [date, setDate] = useState(null)

  const saveChanges = () => {
    setValue(date)
    closeHandler()
  }
  const rejectChanges = () => {
    setValue(null)
    closeHandler()
  }

  return (
    <View
      style={[
        {
          position: 'absolute',
          zIndex: 10,
          backgroundColor: bg.primary,
          top: 45,
          borderRadius: 16,
          padding: 20,
          flex: 1,
          width: 364,
        },
        shadowStyles,
      ]}
    >
      <InlineCalendar
        valueHandler={setDate}
        saveChanges={saveChanges}
        rejectChanges={rejectChanges}
      />
    </View>
  )
}

const RecordFilterSelect = props => {
  const {
    setValue,
    value,
    config: { rclasses },
  } = props

  const {
    typography: { Overline },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const [search, onChangeSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState(() => {
    if (value?.length) return value
    return []
  })

  useEffect(() => {
    setValue(selectedItems)
  }, [selectedItems])

  const data = useRecordAutoComplete(rclasses, search)

  const selectItem = record => {
    setSelectedItems(items => [...items, record])
  }

  const removeItem = recordid => {
    setSelectedItems(items => items.filter(item => item.recordid !== recordid))
  }

  const inputRef = useRef()

  useEffect(() => {
    inputRef?.current.focus()
  }, [])

  return (
    <View
      style={[
        {
          position: 'absolute',
          zIndex: 10,
          backgroundColor: bg.primary,
          top: 45,
          borderRadius: 16,
          paddingVertical: 3,
          maxHeight: 345,
          width: 720,
        },
        shadowStyles,
      ]}
    >
      <View style={{ height: 44, paddingVertical: 12, paddingHorizontal: 16 }}>
        <AlignedRow>
          <WorktribeIcon size={20} color="#A0A4B8" name="search-alternate" />
          <TextInput
            ref={inputRef}
            value={search}
            onChangeText={onChangeSearch}
            style={[
              {
                fontFamily: 'proxima-nova',
                color: fonts.secondary,
                fontSize: 15,
                lineHeight: 24,
                marginLeft: 14,
                height: 24,
                flex: 1,
              },
              Platform.OS === 'web' && { outline: 'none' },
            ]}
            placeholder="Search"
            placeholderTextColor="#A0A4B8"
          />
        </AlignedRow>
      </View>

      <ScrollView style={{ flex: 1 }}>
        {!!selectedItems.length && (
          <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
            selected
          </Overline>
        )}

        {selectedItems.map(record => (
          <RecordFilterItem
            onRemove={removeItem}
            key={record.recordid}
            record={record}
          />
        ))}

        {!!selectedItems.length && (
          <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
            others
          </Overline>
        )}

        {data
          .filter(record => {
            const selectedIds = selectedItems.map(item => item.recordid)
            return !selectedIds.includes(record.recordid)
          })
          .map(record => (
            <RecordFilterItem
              key={record.recordid}
              record={record}
              onSelect={selectItem}
            />
          ))}
      </ScrollView>
    </View>
  )
}

const RecordFilterItem = ({ record, onSelect, onRemove }) => {
  const {
    typography: { Body },
    themeColors: { hover },
  } = useContext(ThemeContext)

  const name = record.title
  const details = record.properties
    .reduce((acc, curr) => {
      if (curr.label) acc.push(curr.label)
      return acc
    }, [])
    .join(', ')

  return (
    <HoverableOpacity
      onPress={() => {
        onSelect?.(record)
      }}
      hoverStyle={{ backgroundColor: hover }}
      style={{
        paddingVertical: 9,
        paddingHorizontal: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <AlignedRow style={{ flex: 1 }}>
        <AlignedRow style={{ marginRight: 6 }}>
          <CardThumbnail
            source={{
              uri: `https://zubanubi.com/resources/covers/cover${record.coverid}_thumb@2x.jpg`,
              cache: 'force-cache',
            }}
          />

          <Body style={{ marginLeft: 8 }} primary>
            {name}
          </Body>
        </AlignedRow>

        <Body numberOfLines={1}>{details}</Body>
      </AlignedRow>

      {onRemove && (
        <Pressable onPress={() => onRemove(record.recordid)}>
          <WorktribeIcon name="remove-circle" />
        </Pressable>
      )}
    </HoverableOpacity>
  )
}

export default GridFilters

const Filter = styled.View`
  position: relative;
  justify-content: center;
  border-width: 1px;
  border-color: ${props => (props.selected ? `#2AAD79` : `transparent`)};
  border-radius: 50px;
  margin-right: 20px;
  margin-bottom: 5px;
`

const CardThumbnail = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
`

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.12)',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 50,
  elevation: 24,
}
