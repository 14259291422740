import React, { useMemo, useRef, RefObject } from 'react'
import Animated, {
  useAnimatedStyle,
  withTiming,
  useDerivedValue,
} from 'react-native-reanimated'
import {
  getPositions,
  getOffsets,
  getPositionString,
  animationConfig,
  ROW_HEIGHT,
} from './utils'
import type { Record, Positions } from './section-list'

import Section from './Section'
import useSocketMethods from '../../../hooks/useSocketMethods'

interface SectionListProps {
  records: Record[]
  renderItem: (item: any, index: number, recordids: string[]) => React.ReactNode
  rclass: string
  parentRecordId: string
  parentrClass: string
  name: string
  editaction: string
  groupby: string
  editing: boolean
  handleAdd: (
    action: any,
    before: number,
    after: number,
    initialChanges: any
  ) => void
}

const SectionList: React.FC<SectionListProps> = ({
  records,
  renderItem,
  rclass,
  parentRecordId,
  parentrClass,
  name,
  editaction,
  groupby,
  editing,
  handleAdd,
}) => {
  const data = useMemo(() => {
    return records.map(category => ({
      ...category,
      data: category.data.map(record => ({
        ...record,
        id: `_${record.recordid}`,
      })),
    }))
  }, [records])

  const categories = useMemo(
    () => data.map(({ recordids }) => recordids),
    [data]
  )

  // get positional mappings
  const positions: Animated.SharedValue<Positions> = useDerivedValue(() =>
    getPositions(data)
  )

  const prevPositions: RefObject<Positions> = useRef(positions.value)

  const offsets = useDerivedValue(() => getOffsets(positions.value, data))

  const containerHeight = useDerivedValue(() => {
    // get max offset
    const lastSectionOffsets: number[] = [
      ...Object.values(offsets.value[offsets.value.length - 1]),
    ]
    return Math.max(...lastSectionOffsets) + ROW_HEIGHT
  })

  const style = useAnimatedStyle(() => ({
    height: withTiming(containerHeight.value, animationConfig),
  }))

  const { record_action } = useSocketMethods()

  return (
    <Animated.View style={style}>
      {data.map(({ title, data, recordids }, index) => (
        <Section
          key={title}
          sectionIndex={index}
          title={title}
          data={data}
          positions={positions}
          editing={editing}
          handleAdd={handleAdd}
          recordids={recordids}
          groupby={groupby}
          onDragEnd={({ positions, position }) => {
            const changed = Object.entries(positions).some(
              ([id, newPosition]) => {
                const {
                  sectionIndex: existingSectionIndex,
                  rowIndex: existingRowIndex,
                } = prevPositions.current[id]

                return (
                  existingSectionIndex !== newPosition.sectionIndex ||
                  existingRowIndex !== newPosition.rowIndex
                )
              }
            )

            if (!changed) return

            if (
              prevPositions.current[position.id].sectionIndex !==
              position.sectionIndex
            ) {
              let payload = Array.isArray(categories[position.sectionIndex])
                ? { recordids: categories[position.sectionIndex] }
                : { value: [categories[position.sectionIndex]] }

              record_action({
                recordid: position.recordid,
                rclassname: rclass,
                actionname: editaction,
                changes: [
                  {
                    property: groupby,
                    method: 'set',
                    ...payload,
                  },
                ],
              })
            }

            // handle order
            // update order as well
            record_action({
              recordid: parentRecordId,
              rclassname: parentrClass,
              actionname: editaction,
              changes: [
                {
                  property: name,
                  method: 'link',
                  recordids: [position.recordid],
                  position: getPositionString(positions, position, data),
                },
              ],
            })

            prevPositions.current = positions
          }}
          offsets={offsets}
          renderItem={renderItem}
        />
      ))}
    </Animated.View>
  )
}

export default SectionList
