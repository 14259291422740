import React, { useContext, useRef, useMemo, RefObject } from 'react'
import {
  View,
  StyleSheet,
  ScrollView,
  Pressable,
  Image,
  useWindowDimensions,
} from 'react-native'
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler'
import Animated, {
  useAnimatedGestureHandler,
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  runOnJS,
  useAnimatedReaction,
  useAnimatedRef,
  useAnimatedScrollHandler,
  useDerivedValue,
} from 'react-native-reanimated'
import {
  getPositions,
  getPosition,
  getColumnIndex,
  getRowIndex,
  getPositionString,
  TILE_WIDTH,
  TILE_HEIGHT,
  TILE_MARGIN,
  HEADING_OFFSET,
  animationConfig,
  handleDoubleTap,
} from './utils'
import type { Position, Positions } from './kanban.d'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { format } from 'date-fns'
import useSocketMethods from '../../../hooks/useSocketMethods'
import { AlignedRow } from '../../shared/Layout'
import WorktribeIcon from '../../shared/WorktribeIcon'
import Initials from '../../molecules/avatars/Initials'
import { useNavigation } from '@react-navigation/native'
import styles from './styles'

interface IItem {
  recordid: string
  title: string
  coverid: string
  badge: string
  properties: any
}

interface IData {
  title: string
  color: string
  data: IItem[]
}

interface IKanban {
  data: IData[]
  parentRecordId: string
  parentRClass: string
  name: string
  editaction: string
  columnproperty: string
  columnpropertyaction: string
  rclass: string
}

const useKanbanData = (data: IData[]) => {
  const columnCount: number = useMemo(() => Object.keys(data).length, [data])
  const categoryMap: any[] = useMemo(
    () =>
      Object.values(data).map(({ title, color }) => ({
        title,
        color,
      })),
    [data]
  )
  const minHeight: number = useMemo(() => {
    return (
      (TILE_HEIGHT + TILE_MARGIN) *
        Object.values(data).reduce((acc, { data }) => {
          if (acc < data.length) acc = data.length
          return acc
        }, 0) +
      HEADING_OFFSET +
      TILE_HEIGHT +
      TILE_MARGIN
    )
  }, [data])
  const contentWidth: number = useMemo(
    () => columnCount * (TILE_WIDTH + TILE_MARGIN * 2) + TILE_MARGIN,
    [columnCount]
  )
  return {
    columnCount,
    categoryMap,
    minHeight,
    contentWidth,
  }
}

export default function Kanban({
  data,
  parentRecordId,
  parentRClass,
  name,
  editaction,
  columnproperty,
  columnpropertyaction,
  rclass,
}: IKanban) {
  const { columnCount, categoryMap, minHeight, contentWidth } =
    useKanbanData(data)

  const positions = useDerivedValue(
    () => getPositions(data),
    [JSON.stringify(data)]
  )

  // track changes to positions
  const prevPositions = useRef(positions.value)

  const scrollX = useSharedValue(0)
  const scrollViewX = useAnimatedRef<Animated.ScrollView>()

  const onScrollX = useAnimatedScrollHandler({
    onScroll: ({ contentOffset: { x } }) => {
      scrollX.value = x
    },
  })

  const { record_action } = useSocketMethods()

  const handleUpdate = ({
    position,
    positions,
  }: {
    position: Position
    positions: Positions
  }) => {
    // determine if a change was made
    const changed = Object.entries(positions).some(([id, newPosition]) => {
      const { columnIndex: existingColIndex, rowIndex: existingRowIndex } =
        prevPositions.current[id]
      return (
        existingColIndex !== newPosition.columnIndex ||
        existingRowIndex !== newPosition.rowIndex
      )
    })

    if (!changed) return

    if (
      prevPositions.current[position.id].columnIndex !== position.columnIndex
    ) {
      // column has changed
      // need to check order and new category
      record_action({
        recordid: position.id,
        rclassname: rclass,
        actionname: columnpropertyaction,
        changes: [
          {
            property: columnproperty,
            method: 'set',
            value: categoryMap[position.columnIndex].title,
          },
        ],
      })
    }

    // update order as well
    record_action({
      recordid: parentRecordId,
      rclassname: parentRClass,
      actionname: editaction,
      changes: [
        {
          property: name,
          method: 'link',
          recordids: [position.id],
          position: getPositionString(positions, position),
        },
      ],
    })

    // update ref with latest positions
    prevPositions.current = positions
  }

  return (
    <ScrollView contentContainerStyle={{ flex: 1, minHeight }}>
      <Animated.ScrollView
        onScroll={onScrollX}
        ref={scrollViewX}
        style={{ flex: 1 }}
        contentContainerStyle={{ width: contentWidth, marginLeft: 40 }}
        directionalLockEnabled={false}
        horizontal={true}
        bounces={false}
        scrollEventThrottle={16}
      >
        {data.map(({ title, color, data }, index) => {
          return (
            <KanbanColumn
              key={title}
              columnIndex={index}
              title={title}
              color={color}
              data={data}
              positions={positions}
              columnCount={columnCount}
              onDragEnd={handleUpdate}
              rclass={rclass}
              contentWidth={contentWidth}
              scrollX={scrollX}
              scrollViewX={scrollViewX}
            />
          )
        })}
      </Animated.ScrollView>
    </ScrollView>
  )
}

interface ColumnProps {
  columnIndex: number
  title: string
  color: string
  data: any
  positions: Animated.SharedValue<Positions>
  columnCount: number
  onDragEnd: ({
    position,
    positions,
  }: {
    position: Position
    positions: Positions
  }) => void
  rclass: string
  contentWidth: number
  scrollX: Animated.SharedValue<number>
  scrollViewX: RefObject<Animated.ScrollView>
}

const KanbanColumn: React.FC<ColumnProps> = ({
  columnIndex,
  title,
  color,
  data,
  positions,
  columnCount,
  onDragEnd,
  rclass,
  contentWidth,
  scrollX,
  scrollViewX,
}) => {
  return (
    <>
      <ColumnHeader title={title} index={columnIndex} />

      <>
        {data.map((item: IItem, index: number) => (
          <KanbanItem
            key={item.recordid}
            item={item}
            columnIndex={columnIndex}
            rowIndex={index}
            positions={positions}
            columnCount={columnCount}
            onDragEnd={onDragEnd}
            rclass={rclass}
            contentWidth={contentWidth}
            scrollX={scrollX}
            scrollViewX={scrollViewX}
          />
        ))}

        <ColumnBackground
          color={color}
          columnIndex={columnIndex}
          numItems={data.length}
          positions={positions}
        />
      </>
    </>
  )
}

const ColumnBackground = ({
  columnIndex,
  color,
  numItems,
  positions,
}: {
  columnIndex: number
  color: string
  numItems: number
  positions: Animated.SharedValue<Positions>
}) => {
  const {
    typography: { Button },
    themeColors: {
      status: { light },
    },
  } = useContext(ThemeContext)

  const height = useSharedValue(
    numItems * (TILE_HEIGHT + TILE_MARGIN) + TILE_HEIGHT
  )

  useAnimatedReaction(
    () =>
      Object.values(positions.value).filter(
        position => position.columnIndex === columnIndex
      )!,
    cols => {
      height.value = cols.length * (TILE_HEIGHT + TILE_MARGIN) + TILE_HEIGHT
    }
  )

  const animatedStyle = useAnimatedStyle(() => ({
    left: columnIndex * (TILE_WIDTH + TILE_MARGIN * 2),
    background: light[color],
    zIndex: -1,
    height: withTiming(height.value, animationConfig),
  }))

  return (
    <Animated.View style={[styles.columnEmpty, animatedStyle]}>
      {numItems === 0 && (
        <Pressable>
          <AlignedRow gap={4}>
            <WorktribeIcon name="add" color="#A0A4B8" size={12} />
            <Button>Add new</Button>
          </AlignedRow>
        </Pressable>
      )}
    </Animated.View>
  )
}

const ColumnHeader = ({ title, index }: { title: string; index: number }) => {
  const {
    typography: { H3 },
  } = useContext(ThemeContext)
  return (
    <View
      style={[
        styles.column,
        {
          left: index * (TILE_WIDTH + TILE_MARGIN * 2),
        },
      ]}
    >
      <H3 primary style={{ flex: 1 }}>
        {title}
      </H3>

      <View style={styles.columnActions}>
        <Pressable>
          <WorktribeIcon name="add" color="#A0A4B8" size={18} />
        </Pressable>

        <Pressable>
          <WorktribeIcon size={16} name="more" color="#A0A4B8" />
        </Pressable>
      </View>
    </View>
  )
}

interface KanbanItemProps {
  item: IItem
  columnIndex: number
  rowIndex: number
  positions: Animated.SharedValue<Positions>
  columnCount: number
  onDragEnd: ({
    position,
    positions,
  }: {
    position: Position
    positions: Positions
  }) => void
  rclass: string
  contentWidth: number
  scrollX: Animated.SharedValue<number>
  scrollViewX: RefObject<Animated.ScrollView>
}

const KanbanItem: React.FC<KanbanItemProps> = ({
  item,
  columnIndex,
  rowIndex,
  positions,
  columnCount,
  onDragEnd,
  rclass,
  contentWidth,
  scrollX,
  scrollViewX,
}) => {
  const isGestureActive = useSharedValue(false)

  const position = getPosition(columnIndex, rowIndex)

  const translateX = useSharedValue(position.x)
  const translateY = useSharedValue(position.y)

  // screen width
  const { width } = useWindowDimensions()
  const containerWidth = width - 120

  useAnimatedReaction(
    () => positions.value[item.recordid]!,
    ({ columnIndex, rowIndex }) => {
      if (!isGestureActive.value) {
        const { x, y } = getPosition(columnIndex, rowIndex)
        translateX.value = withTiming(x, animationConfig)
        translateY.value = withTiming(y, animationConfig)
      }
    }
  )

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { x: number; y: number; id: string }
  >({
    onStart: (_, ctx) => {
      ctx.x = translateX.value
      ctx.y = translateY.value
      ctx.id = item.recordid
      isGestureActive.value = true
    },
    onActive: ({ translationX, translationY }, ctx) => {
      translateX.value = ctx.x + translationX
      translateY.value = ctx.y + translationY

      // determine column by x position
      const columnIndex = getColumnIndex(translateX.value, columnCount)

      if (columnIndex > -1) {
        // find vertical index
        const newOrder = getRowIndex(
          translateY.value,
          Object.values(positions.value).filter(
            ({ columnIndex: y }) => y === columnIndex
          ).length + 1
        )

        if (newOrder > -1) {
          const { columnIndex: initialColIndex, rowIndex: initialRowIndex } =
            positions.value[item.recordid]

          if (columnIndex !== initialColIndex || newOrder !== initialRowIndex) {
            const existingTile = Object.entries(positions.value).find(
              ([_, position]) =>
                position.columnIndex === columnIndex &&
                position.rowIndex === newOrder
            )

            const newPositions = { ...positions.value }

            if (existingTile) {
              const [idToSwap] = existingTile

              if (columnIndex === initialColIndex) {
                // this handles sorting on the vertical axis
                // which is a simple switch of positions
                newPositions[item.recordid] = {
                  columnIndex,
                  rowIndex: newOrder,
                }

                newPositions[idToSwap] = {
                  columnIndex: initialColIndex,
                  rowIndex: initialRowIndex,
                }
              } else {
                // get vertical position of idtoswap
                // we want to move everything down 1 position on this column
                const { rowIndex } = newPositions[idToSwap]

                // get all ids that are in this column but >= rowIndex
                Object.entries(newPositions)
                  .filter(
                    ([_, position]) =>
                      columnIndex === position.columnIndex &&
                      position.rowIndex >= rowIndex
                  )
                  .forEach(([id, position]) => {
                    newPositions[id] = {
                      columnIndex,
                      rowIndex: position.rowIndex + 1,
                    }
                  })
              }
            } else {
              newPositions[item.recordid] = {
                columnIndex,
                rowIndex: newOrder,
              }

              // handle initial column to make sure gaps are filled
              Object.entries(newPositions)
                .filter(
                  ([_, position]) =>
                    initialColIndex === position.columnIndex &&
                    position.rowIndex > initialRowIndex
                )
                .forEach(([id, position]) => {
                  newPositions[id] = {
                    columnIndex: initialColIndex,
                    rowIndex: position.rowIndex - 1,
                  }
                })
            }

            positions.value = newPositions
          }
        }
      }

      const lowerBound = scrollX.value
      const upperBound = lowerBound + containerWidth - TILE_WIDTH
      const maxScroll = contentWidth + 40 - containerWidth
      const leftToScrollRight = maxScroll - lowerBound

      if (translateX.value < lowerBound) {
        const diff = Math.min(lowerBound - translateX.value, lowerBound)
        scrollX.value -= diff
        scrollViewX?.current.scrollTo({
          x: scrollX.value,
          y: 0,
          animated: false,
        })
        ctx.x -= diff
        translateX.value = ctx.x + translationX
      }

      if (translateX.value > upperBound) {
        const diff = Math.min(translateX.value - upperBound, leftToScrollRight)
        scrollX.value += diff
        scrollViewX?.current.scrollTo({
          x: scrollX.value,
          y: 0,
          animated: false,
        })
        ctx.x += diff
        translateX.value = ctx.x + translationX
      }
    },
    onEnd: (_, ctx) => {
      const { columnIndex, rowIndex } = positions.value[item.recordid]
      const { x, y } = getPosition(columnIndex, rowIndex)

      translateX.value = withTiming(x, animationConfig, () => {
        isGestureActive.value = false
        runOnJS(onDragEnd)({
          position: {
            id: ctx.id,
            ...positions.value[ctx.id],
          },
          positions: positions.value,
        })
      })
      translateY.value = withTiming(y, animationConfig)
    },
  })

  const style = useAnimatedStyle(() => {
    const zIndex = isGestureActive.value ? 100 : 0
    const scale = withSpring(isGestureActive.value ? 1.05 : 1)
    return {
      position: 'absolute',
      top: 0,
      left: 0,
      width: TILE_WIDTH,
      height: TILE_HEIGHT,
      zIndex,
      transform: [
        { translateX: translateX.value },
        { translateY: translateY.value },
        { scale },
      ],
    }
  })

  return (
    <Animated.View style={style}>
      <PanGestureHandler onGestureEvent={onGestureEvent}>
        <Animated.View style={StyleSheet.absoluteFill}>
          <Tile item={item} rclass={rclass} />
        </Animated.View>
      </PanGestureHandler>
    </Animated.View>
  )
}

interface TileProps {
  item: IItem
  rclass: string
}

const Tile: React.FC<TileProps> = ({ item, rclass }) => {
  const navigation = useNavigation()

  const handleNavigation = () =>
    navigation.push('Record', {
      rClass: rclass,
      recordId: item.recordid,
    })

  // TEMP mapping of required fields
  const {
    ['Project Lead']: projectLead,
    Status,
    Title,
  } = item.properties.reduce((acc, curr) => {
    if (curr.value?.records) {
      acc[curr.name] = curr.value.records.map(record => record.title).join(', ')
    } else {
      acc[curr.name] = curr.value
    }

    return acc
  }, {})

  const {
    typography: { Label, Caption, Subtitle, Overline },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  return (
    <Pressable
      onPress={() => handleDoubleTap(handleNavigation)}
      style={[
        styles.tileContainer,
        {
          width: TILE_WIDTH,
          flex: 1,
          backgroundColor: bg.primary,
          borderColor: accents.separator,
        },
      ]}
    >
      <View style={{ flexDirection: 'row', marginBottom: 10 }}>
        <Label style={{ flex: 1 }}>{Status}</Label>

        <Caption>{format(new Date(), 'MMM Y')}</Caption>
      </View>

      <View style={styles.tileContent}>
        <Image
          source={{
            uri: `https://zubanubi.com/resources/covers/cover${item.coverid}_thumb@2x.jpg`,
            cache: 'force-cache',
          }}
          style={styles.tileImage}
        />

        <View style={{ flex: 1 }}>
          <Label>{rclass}</Label>
          <Subtitle primary numberOfLines={1}>
            {Title}
          </Subtitle>
        </View>

        {!!item.badge.length && (
          <View style={styles.tileBadge}>
            <Overline color="#FFF">{item.badge}</Overline>
          </View>
        )}
      </View>

      <AlignedRow>
        <AlignedRow gap={8} style={{ flex: 1 }}>
          <Initials name={projectLead} size={24} />
          <Caption>{projectLead}</Caption>
        </AlignedRow>

        <View style={styles.tilePriority}>
          <Caption color="#D13E3E">High</Caption>
        </View>
      </AlignedRow>
    </Pressable>
  )
}
