import React, { useContext } from 'react'
import { Pressable } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import WorktribeIcon from '../../shared/WorktribeIcon'

const InlineFile = ({ onChange, value = '' }) => {
  const {
    typography: { Body, Label },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Pressable
      onPress={() => onChange('document.pdf')}
      style={{
        minWidth: 647,
        height: 358,
        backgroundColor: bg.primary,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        borderWidth: 2,
        borderColor: '#DDE1ED',
        borderStyle: 'dashed',
      }}
    >
      <WorktribeIcon name="upload" />
      <Body style={{ marginTop: 16 }} color="#1D7A55">
        {value.length ? value : `Choose file or drag here`}
      </Body>
      <Label style={{ marginTop: 4 }}>Maximum file size is 20mb</Label>
    </Pressable>
  )
}

export default InlineFile
