import { useMemo, useCallback } from 'react'
import useSocket from './useSocket'
import useZubanubi from './useZubanubi'

type RequestMethod =
  | 'rclass_list'
  | 'record_action'
  | 'record_download'
  | 'record_fileupload'
  | 'record_find'
  | 'record_get'
  | 'record_search'
  | 'record_set'
  | 'service_get'
  | 'service_list'
  | 'wtauth'
  | 'homepage'

interface Request {
  method?: RequestMethod
}

interface RclassListRequest extends Request {
  rclasses?: string[]
  scope?: string[]
}

interface RecordRequest extends Request {
  recordid: string
  rclassname: string
}

interface Filter {
  property: string
  method:
    | 'matches'
    | 'contains'
    | 'startswith'
    | 'wordstartswith'
    | 'lessthanequals'
    | 'greaterthanequals'
  value: string
}

interface RecordFindRequest extends Request {
  rclassname: string
  returnpropertynames?: string[]
  includecards?: string
  maxresults?: number
  recordids?: string[]
  filters?: Filter[]
}

interface RecordGetRequest extends Request {
  recordid: string
  rclassname: string
  includecards?: boolean
  includeactions?: boolean
  returnpropertynames?: string[]
  returnsections?: string[] | null
}

interface RecordSearchRequest extends Request {
  q: string
  scope?: string
  rclasses?: string[]
}

interface Change {
  property: string
  method: 'set' | 'link' | 'unlink' | 'add' | 'delete'
  value?: string
  values?: string[]
  position?: string
  recordids?: string[]
}

interface RecordActionRequest extends Request {
  recordid: string
  rclassname: string
  actionname: string
  changes?: Change[]
}

interface ServiceGetRequest extends Request {
  servicename: string
  returnsections?: string[]
}

interface ServiceListRequest extends Request {
  includedescriptions?: boolean
}

interface WTAuthRequest extends Request {
  wtauth: boolean
  clientplatform: string
  clientname: string
  clientversion: string
  appletoken?: string
  googletoken?: string
  debugtoken?: string
  usergivenname?: string
  userfamilyname?: string
  userlocale?: string
  triggered_from: string
  wtenv: string
}

const useSocketMethods = () => {
  const socket = useSocket()
  const getZubanubiRepo = useZubanubi()

  const _makeRequest = useCallback(
    async (request: Request) => {
      try {
        const zubanubiRepo = await getZubanubiRepo()

        waitForSocketConnection(socket, () => {
          socket.send(JSON.stringify({ ...request, wtenv: zubanubiRepo }))
        })
      } catch ({ message }) {
        console.warn(message)
      }
    },
    [socket]
  )

  return useMemo(
    () => ({
      rclass_list: (request: RclassListRequest) => {
        _makeRequest({ ...request, method: 'rclass_list' })
      },
      record_action: (request: RecordActionRequest) => {
        _makeRequest({ ...request, method: 'record_action' })
      },
      record_download: (request: RecordRequest) => {
        _makeRequest({ ...request, method: 'record_download' })
      },
      record_fileupload: (request: RecordRequest) => {
        _makeRequest({ ...request, method: 'record_fileupload' })
      },
      record_find: (request: RecordFindRequest) => {
        _makeRequest({ ...request, method: 'record_find' })
      },
      record_get: (request: RecordGetRequest) => {
        _makeRequest({ ...request, method: 'record_get' })
      },
      record_search: (request: RecordSearchRequest) => {
        _makeRequest({ ...request, method: 'record_search' })
      },
      service_get: (request: ServiceGetRequest) => {
        _makeRequest({ ...request, method: 'service_get' })
      },
      service_list: (request: ServiceListRequest) => {
        _makeRequest({ ...request, method: 'service_list' })
      },
      wtauth: (request: WTAuthRequest) => {
        _makeRequest({ ...request, method: 'wtauth' })
      },
      homepage_get: () => {
        _makeRequest({ method: 'homepage' })
      },
    }),
    [_makeRequest]
  )
}

export default useSocketMethods

const waitForSocketConnection = (socket: WebSocket, callback: () => void) => {
  setTimeout(function () {
    if (socket?.readyState === 1) {
      callback()
    } else if (socket?.readyState !== 3) {
      console.log('Waiting for socket connection...')
      // If it's not closed, wait for the connection
      waitForSocketConnection(socket, callback)
    }
  }, 100)
}
