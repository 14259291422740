import { SHOW_PROFILE } from '../actions/layout'

const initialState = {
  profile: {
    open: false,
  },
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PROFILE:
      return {
        ...state,
        profile: {
          open: action.open,
        },
      }
  }
  return state
}

export default layoutReducer
