import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow, CenteredView } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import {
  TouchableOpacity,
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import styled from 'styled-components/native/dist/styled-components.native.esm'

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 16px;
`

const GridRow = ({ item }) => {
  const {
    typography: { Body, H5 },
    themeColors: { bg }
  } = useContext(ThemeContext)

  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('Record', {
          rClass: item.product,
          recordId: item.recordid,
        })
      }}
    >
      <Row
        style={{
          paddingVertical: 16,
          paddingHorizontal: 24,
          backgroundColor: bg.card,
          borderRadius: 20,
          marginBottom: 12,
          alignItems: 'center',
        }}
      >
        {item.properties.map((prop, i) => (
          <>
            {i === 0 ? (
              <Col size={2}>
                <AlignedRow>
                  <TouchableOpacity
                    style={{
                      marginRight: 24,
                    }}
                    activeOpacity={1}
                  >
                    <Thumbnail
                      source={{
                        uri: `https://zubanubi.com/resources/covers/cover${item.coverid}_thumb@2x.jpg`,
                        cache: 'force-cache',
                      }}
                    />
                  </TouchableOpacity>

                  <View style={{ flex: 1, paddingRight: 40 }}>
                    <H5 numberOfLines={2} primary>
                      {prop.value}
                    </H5>
                  </View>
                </AlignedRow>
              </Col>
            ) : (
              <Col>
                <Body
                  numberOfLines={2}
                  style={{ paddingRight: 40 }}
                >
                  {prop.value}
                </Body>
              </Col>
            )}
          </>
        ))}
      </Row>
    </TouchableOpacity>
  )
}

const GridRenderer = ({ property }) => {
  const {
    typography: { H5 },
  } = useContext(ThemeContext)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const [headings, setHeadings] = useState([])

  useEffect(() => {
    const { records } = property.columns[0].items[0].value
    const prop = property.columns[0].items[0].property

    if (!prop.rclasses) return

    const [foreign] = prop.rclasses.replace(/{|}/g, '').split('.')

    async function getChildren() {
      const rclassname = foreign.toLowerCase().replace(' ', '_')
      try {
        setData(
          records.map(record => {
            return {
              ...record,
              product: rclassname,
            }
          })
        )
      } catch (e) {
        console.warn(e)
      } finally {
        setLoading(false)
      }
    }

    void getChildren()

    return () => {
      setData(null)
      setHeadings([])
    }
  }, [property])

  useEffect(() => {
    if (!data || !data.length) return

    const properties = data[0].properties

    setHeadings(
      properties.map((prop, index) => {
        return {
          name: prop.name,
          style: index === 0 ? 'title' : '',
        }
      })
    )
  }, [data])

  // useEffect(() => {
  //   if (!data.length) return
  //
  //   const properties = data[0].properties
  //
  //   setHeadings(
  //     properties.map((prop, index) => {
  //       return {
  //         name: prop.property,
  //         style: index === 0 ? 'title' : '',
  //       }
  //     })
  //   )
  // }, [data])

  if (loading) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  if (headings.length) {
    return (
      <Grid style={[styles.grid, { marginTop: 40 }]}>
        <Row style={styles.row}>
          {headings.map(heading => (
            <>
              {heading.style === 'title' ? (
                <Col size={2}>
                  <H5 style={{ marginLeft: 72 }} primary>
                    {heading.name}
                  </H5>
                </Col>
              ) : (
                <Col>
                  <H5 primary>{heading.name}</H5>
                </Col>
              )}
            </>
          ))}
        </Row>

        {data.map(item => (
          <GridRow key={item.recordid} item={item} />
        ))}
      </Grid>
    )
  }

  return null
}

export default GridRenderer

const styles = StyleSheet.create({
  grid: {
    marginTop: 20,
    marginBottom: 36,
    flexDirection: 'column',
  },
  row: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
})

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
