import React, { useContext, useState, useEffect } from 'react'
import {
  StyleSheet,
  View,
  SafeAreaView,
  FlatList,
  Text,
  TouchableOpacity,
} from 'react-native'
import { APIContext } from '../../contexts/APIContext'
import { AuthContext } from '../../contexts/AuthContext'
import TabHeader from '../../components/shared/TabHeader'
import useOrientation from '../../hooks/useOrientation'

const SwitchUserScreen = ({ navigation }) => {
  return null

  const [users, setUsers] = useState([])
  const { getUsers, become } = useContext(APIContext)

  const isLandscape = useOrientation()

  useEffect(() => {
    getUsers()
      .then(response => setUsers(response.data))
      .catch(e => console.warn(e))
  }, [getUsers, setUsers])

  const onUserPress = user => {
    become(user.id).then(response => {
      const { user, access_token } = response.data
      switchUser(user, access_token)

      navigation.navigate(isLandscape ? 'Home' : 'Feed')
    })
  }

  return (
    <SafeAreaView style={styles.container}>
      <TabHeader title="Switch user" />
      <Text style={{ paddingHorizontal: 16, paddingBottom: 10 }}>
        This is a temporary dev feature. As an authenticated user, you may now
        switch to any other user.
      </Text>
      <FlatList
        data={users}
        keyExtractor={(item, index) => item.id.toString()}
        renderItem={({ item, index, separators }) => {
          if (item.id === authenticatedUser.id) {
            return (
              <View style={styles.disabledButton}>
                <Text style={styles.buttonText}>
                  {item.name} (Current user)
                </Text>
              </View>
            )
          } else {
            return (
              <TouchableOpacity
                key={item.key}
                onPress={() => onUserPress(item)}
                onShowUnderlay={separators.highlight}
                onHideUnderlay={separators.unhighlight}
              >
                <View style={styles.button}>
                  <Text style={styles.buttonText}>{item.name}</Text>
                </View>
              </TouchableOpacity>
            )
          }
        }}
      />
    </SafeAreaView>
  )
}

export default SwitchUserScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#EBECF2',
  },
  button: {
    backgroundColor: 'white',
    paddingHorizontal: 20,
    margin: 5,
    padding: 10,
    borderRadius: 8,
  },
  disabledButton: {
    backgroundColor: '#CCC',
    margin: 5,
    padding: 10,
    borderRadius: 8,
  },
  buttonText: {
    fontSize: 18,
  },
})
