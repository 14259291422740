import React from 'react'
import type { IProject, IPeriod } from './impact-journey'
import { SIDE_WIDTH, HEADER_HEIGHT, animationConfig } from './utils'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import LaneBanner from './LaneBanner'

interface ProjectProps {
  periods: IPeriod[]
  project: IProject
  monthWidth: Animated.SharedValue<number>
}

const Lane: React.FC<ProjectProps> = ({ periods, project, monthWidth }) => {
  const style = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      left: 0,
      top: project.offset! + HEADER_HEIGHT,
      width: withTiming(
        SIDE_WIDTH + (monthWidth.value * periods.length),
        animationConfig
      ),
      height: project.height,
      right: 0,
    }
  })
  return (
    <Animated.View style={style}>
      <LaneBanner project={project} />
    </Animated.View>
  )
}

export default Lane
