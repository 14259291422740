import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { TouchableOpacity, ScrollView, Pressable, View } from 'react-native'
import styled from 'styled-components/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import format from 'date-fns/format'

// contexts
import { ThemeContext } from '../../../../contexts/ThemeContext'

// hooks
import { useNavigation } from '@react-navigation/native'
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter'

// ui
import WorktribeIcon from '../../../shared/WorktribeIcon'
import { AlignedRow } from '../../../shared/Layout'
import { Col, Row } from 'react-native-easy-grid'
import HoverableOpacity from '../../../shared/HoverableOpacity'
import InlineCalendar from '../../../shared/InlineCalendar'
import Ellipsis from '../../../shared/Ellipsis'

import InformationDialog from '../../InformationDialog'
import useSocketListener from '../../../../hooks/useSocketListener'
import Score from '../../Score'

import RecordSelect from '../../controls/RecordSelect'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withTiming,
  Easing,
} from 'react-native-reanimated'

const ROW_HEIGHT = 80

const GridRow = ({
  item,
  selectedIds,
  setSelectedIds,
  editing,
  handleEditedRow,
  handleDelete,
  handlePost,
  handleDrag,
  handleAddProperty,
  editedRowValues,
  handleReset,
  handleShowDragHandles,
  showDragHandles,
  onDiscard,
  reset,
  disableLink,
  groupby,
  groupbyIds,
}) => {
  const {
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const navigation = useNavigation()

  const [rowHovered, setRowHovered] = useState(false)
  const [iconHovered, setIconHovered] = useState(false)
  const [selected, setSelected] = useState(false)
  const [singleEditRecordId, setSingleEditRecordId] = useState(null)
  const [invalidFields, setInvalidFields] = useState([])

  const [dialogProps, setDialogProps] = useState(null)

  const coverRefId = useRef(item.coverid)

  const hasEditableProperty = item.properties.some(
    ({ editaction }) => editaction?.length
  )

  useEffect(() => {
    if (item.pending) {
      setSingleEditRecordId(item.recordid)
    }
  }, [item])

  const updateInvalidFields = useCallback(fields => {
    setInvalidFields(fields)
  }, [])

  useEffect(
    () => setSelected(selectedIds.includes(item.recordid)),
    [selectedIds, item]
  )

  useEffect(() => {
    if (selected) {
      setSelectedIds(prev => Array.from(new Set([...prev, item.recordid])))
    } else {
      setSelectedIds(selectedIds =>
        selectedIds.filter(selectedId => selectedId !== item.recordid)
      )
    }
  }, [selected])

  const goToRecord = async () => {
    if (editing || singleEditRecordId === item.recordid || disableLink) return

    const { recordid, product } = item

    // if we navigating to a record then clear it's existing tab selection
    const savedStateString = await AsyncStorage.getItem('@selectedTab')
    const state = savedStateString ? JSON.parse(savedStateString) : undefined

    if (state) {
      delete state[recordid]
      AsyncStorage.setItem('@selectedTab', JSON.stringify(state))
    }

    navigation.push('Record', {
      rClass: product,
      recordId: recordid,
    })
  }

  const confirmChange = () => {
    if (invalidFields.length) {
      setDialogProps({
        message: 'Please complete the required fields.',
        actions: [
          {
            name: 'OK',
            primary: true,
            action: () => setDialogProps(null),
          },
        ],
      })
    } else {
      submitChanges()
    }
  }

  const rejectChange = () => {
    if (item.pending) {
      if (editedRowValues[item.recordid]) {
        setDialogProps({
          message: 'Would you like to discard your changes?',
          actions: [
            {
              name: 'Confirm',
              primary: true,
              action: () => onDiscard(item.recordid),
            },
            {
              name: 'Cancel',
              primary: false,
              action: () => setDialogProps(null),
            },
          ],
        })
      } else {
        onDiscard(item.recordid)
      }
    } else {
      handleReset(true)
      setSingleEditRecordId(null)
    }
  }

  useSocketListener(
    useCallback(message => {
      const {
        data: { recordid },
        method,
        status,
        privatedetail,
      } = JSON.parse(message.data)

      if (status !== 'OK') {
        let errorMessage = ''
        if (status) errorMessage += status
        if (privatedetail) errorMessage += `: ${privatedetail}`

        setError(errorMessage)
      }

      if (!recordid) return

      if (method === 'record_action' && recordid === item.recordid) {
        progress.value = withSequence(withTiming(1), withTiming(0))
      }
    }, [])
  )

  const submitChanges = () => {
    handlePost()
    setSingleEditRecordId(null)
  }

  const progress = useSharedValue(0)

  const gridRowStyle = useAnimatedStyle(() => ({
    flexDirection: 'row',
    flex: 1,
    backgroundColor: selected
      ? 'rgba(34, 165, 102, 0.2)'
      : interpolateColor(
          progress.value,
          [0, 1],
          [bg.primary, 'rgba(242,206,106,0.2)']
        ),
    borderWidth: 1,
    borderColor: selected ? '#2AAD79' : 'transparent',
    paddingVertical: 16,
    paddingHorizontal: 16,
    alignItems: 'center',
    borderRadius: 20,
    paddingRight: 125,
  }))

  return (
    <Pressable
      onMouseEnter={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
      onPress={goToRecord}
      onPressIn={showDragHandles && handleDrag}
      delayLongPress={0}
    >
      <Row
        style={{
          minHeight: ROW_HEIGHT,
        }}
      >
        <Animated.View style={gridRowStyle}>
          {showDragHandles && (
            <View style={{ marginRight: 8 }}>
              <WorktribeIcon name="reorder" color={fonts.secondary} />
            </View>
          )}
          <TouchableOpacity
            onMouseEnter={() => setIconHovered(true)}
            onMouseLeave={() => setIconHovered(false)}
            onPress={() => setSelected(selected => !selected)}
            style={{ marginRight: 24 }}
            activeOpacity={1}
          >
            {selected ? (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 48,
                  width: 48,
                  borderRadius: 16,
                  backgroundColor: '#2AAD79',
                }}
              >
                <WorktribeIcon color="#FFF" name="check-circle" />
              </View>
            ) : iconHovered || selectedIds.length ? (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 48,
                  width: 48,
                  borderRadius: 16,
                  backgroundColor: bg.card,
                }}
              >
                <WorktribeIcon name="sign-badge-circle-alternate" />
              </View>
            ) : (
              <Thumbnail
                source={{
                  uri: `https://zubanubi.com/resources/covers/cover${coverRefId.current}_thumb@2x.jpg`,
                  cache: 'force-cache',
                }}
              />
            )}
          </TouchableOpacity>
          
          {item.properties.map((prop, index) => (
            <GridRowValue
              key={prop.name}
              position={index}
              item={item}
              prop={prop}
              iconHovered={iconHovered}
              setIconHovered={setIconHovered}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              selected={selected}
              setSelected={setSelected}
              editing={
                (editing || singleEditRecordId === item.recordid) && prop.editaction
              }
              handleEditedRow={handleEditedRow}
              editedRowValues={editedRowValues}
              onValidate={updateInvalidFields}
              reset={reset}
              showDragHandles={showDragHandles}
            />
          ))}

          {singleEditRecordId === item.recordid ? (
            <AlignedRow style={{ position: 'absolute', right: 24 }}>
              <TouchableOpacity
                style={{ marginLeft: 16 }}
                onPress={confirmChange}
              >
                <WorktribeIcon
                  size={24}
                  color={invalidFields.length === 0 ? '#2AAD79' : '#A0A4B8'}
                  name="check-circle-2"
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{ marginLeft: 16 }}
                onPress={rejectChange}
              >
                <WorktribeIcon color="#757885" size={24} name="remove-circle" />
              </TouchableOpacity>
            </AlignedRow>
          ) : rowHovered ? (
            <GridButtons
              recordId={item.recordid}
              onSelectMultiple={setSelected}
              onSingleEdit={() => setSingleEditRecordId(item.recordid)}
              onDelete={handleDelete}
              onAdd={handleAddProperty}
              onReorder={() => {
                handleShowDragHandles()
                setRowHovered(false)
              }}
              showDragHandles={showDragHandles}
              showEdit={hasEditableProperty}
              groupby={groupby}
              groupbyIds={groupbyIds}
            />
          ) : null}
        </Animated.View>

        <InformationDialog
          dialogProps={dialogProps}
          setDialogProps={setDialogProps}
        />
      </Row>
    </Pressable>
  )
}

const GridButtons = ({
  recordId,
  onDelete,
  onAdd,
  onSelectMultiple,
  onSingleEdit,
  onReorder,
  showDragHandles,
  showEdit,
  groupby,
  groupbyIds,
}) => {
  const {
    typography: { Body },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  let initialChanges
  if (groupby && groupbyIds.length) {
    const payload = Array.isArray(groupbyIds)
      ? { recordids: groupbyIds }
      : { value: [groupbyIds] }

    initialChanges = {
      property: groupby,
      method: 'set',
      ...payload,
    }
  }

  const [showMoreMenu, setShowMoreMenu] = useState(false)
  const [menuItems] = useState(() => [
    {
      name: 'Select multiple',
      icon: 'task-list-edit',
      color: '#757885',
      action: () => onSelectMultiple(true),
    },
    {
      name: showDragHandles ? 'Finish reorder' : 'Reorder',
      icon: 'reorder-up',
      color: '#757885',
      action: onReorder,
    },
    {
      name: 'Insert above',
      icon: 'insert-image-top',
      color: '#757885',
      action: () => onAdd(recordId, 0, initialChanges),
    },
    {
      name: 'Insert below',
      icon: 'insert-image-bottom',
      color: '#757885',
      action: () => onAdd(0, recordId, initialChanges),
    },
    {
      name: 'Archive',
      icon: 'archive',
      color: '#757885',
    },
    {
      name: 'Delete',
      icon: 'bin',
      color: '#D13E3E',
      action: () => onDelete(recordId),
    },
  ])

  return (
    <AlignedRow style={{ position: 'absolute', right: 24, }}>
      {showEdit && (
        <Pressable
          onPress={onSingleEdit}
          style={[
            {
              height: 36,
              width: 36,
              borderRadius: 18,
              backgroundColor: bg.primary,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 6,
            },
            shadowStyles,
          ]}
        >
          <WorktribeIcon color="#1D7A55" size={20} name="pencil-1" />
        </Pressable>
      )}

      <Pressable
        // onPress={handleDelete && handleDelete(item.recordid)}
        style={[
          {
            height: 36,
            width: 36,
            borderRadius: 18,
            backgroundColor: bg.primary,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 6,
          },
          shadowStyles,
        ]}
      >
        <WorktribeIcon
          color="#0A406A"
          size={20}
          name="messages-bubble-alternate"
        />
      </Pressable>

      <View>
        <Pressable
          onPress={() => setShowMoreMenu(show => !show)}
          style={[
            {
              height: 36,
              width: 36,
              borderRadius: 18,
              backgroundColor: showMoreMenu ? '#F2FCF8' : bg.primary,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 6,
            },
            showMoreMenu && { borderWidth: 1, borderColor: '#1D7A55' },
            shadowStyles,
          ]}
        >
          <Ellipsis color="#1D7A55" />
        </Pressable>

        {showMoreMenu && (
          <View
            style={[
              {
                position: 'absolute',
                top: 45,
                right: 0,
                backgroundColor: bg.primary,
                borderRadius: 16,
                paddingVertical: 8,
                maxHeight: 345,
                width: 241,
              },
              shadowStyles,
            ]}
          >
            <ScrollView style={{ flex: 1 }}>
              {menuItems.map(({ name, icon, color, action }) => (
                <HoverableOpacity
                  key={name}
                  onPress={action}
                  style={{
                    justifyContent: 'center',
                    minHeight: 46,
                    paddingHorizontal: 16,
                    paddingVertical: 11,
                  }}
                  hoverStyle={{ backgroundColor: hover }}
                >
                  <AlignedRow>
                    <WorktribeIcon name={icon} color={color} />
                    <Body style={{ marginLeft: 12 }} primary>
                      {name}
                    </Body>
                  </AlignedRow>
                </HoverableOpacity>
              ))}
            </ScrollView>
          </View>
        )}
      </View>
    </AlignedRow>
  )
}

const GridRowValue = ({
  item,
  prop,
  editing,
  handleEditedRow,
  editedRowValues,
  reset,
  onValidate,
  position,
}) => {
  const {
    typography: { Subtitle, Body, Label, Overline },
    shadowStyles,
    themeColors: { bg, hover, fonts, status },
  } = useContext(ThemeContext)

  const formatRowValue = val => {
    if (val && typeof val === 'object' && 'properties' in val) {
      const { properties, records } = val

      const titleProperty = Object.entries(properties).find(
        ([_, config]) => config.style === 'title'
      )

      const recordsWithTitle = records.map(record => {
        if (titleProperty?.length) {
          const title = record.properties.find(
            prop => prop.name === titleProperty[0]
          )

          return {
            ...record,
            recordname: title.label ?? title.value,
          }
        } else {
          return {
            ...record,
            recordname: record.title,
          }
        }
      })

      return recordsWithTitle
    }
    return val
  }

  const [rowValue, setRowValue] = useState(() => formatRowValue(prop.value))

  useEffect(() => {
    const { name } = prop

    onValidate(invalid => {
      if ((!rowValue || !rowValue.length) && prop.required === 'true') {
        return [...invalid, name]
      }
      return invalid.filter(item => item !== name)
    })
  }, [rowValue, prop])

  // track the current value of this field
  // update this ref when we save a field
  const existingValue = useRef(rowValue)

  const [showDropdown, setShowDropdown] = useState(false)

  const handleRowValue = value => setRowValue(value)

  useEffect(() => {
    const cellProperty = item.properties.find(
      property => property.name === prop.name
    )

    if (cellProperty) {
      const { value } = cellProperty
      let formattedValue = formatRowValue(value)

      if (
        JSON.stringify(formattedValue) !== JSON.stringify(existingValue.current)
      ) {
        handleRowValue(formattedValue)
      }
    }
  }, [item])

  useEffect(() => {
    if (reset) {
      handleRowValue(existingValue.current)
    }
  }, [reset])

  const saveChanges = async () => {
    handleRowValue(rowValue)

    handleEditedRow(
      item.recordid,
      prop.editaction,
      prop.name,
      rowValue,
      existingValue.current
    )

    existingValue.current = rowValue
  }

  const rejectChanges = () => handleRowValue(existingValue.current)

  if (['type', 'code'].includes(prop.style)) return null

  let titleHeading = []
  switch (prop.type) {
    case 'title': {
      const headingProperties = item.properties.filter(property =>
        ['type', 'code'].includes(property.style)
      )

      if (headingProperties) {
        headingProperties.forEach(headingProperty => {
          const { value } = headingProperty
          if (value && typeof value === 'object' && 'properties' in value) {
            const { properties, records } = value
            const [titleProperty] = Object.entries(properties).find(
              ([_, config]) => config.type === 'title'
            )

            if (titleProperty && records.length) {
              const titleValue = records[0].properties.find(
                property => property.name === titleProperty
              )
              titleHeading.push(titleValue.value)
            }
          } else {
            titleHeading.push(Array.isArray(value) ? value[0] : value)
          }
        })
      }

      useEffect(() => {
        let timeout
        if (existingValue.current !== rowValue) {
          timeout = setTimeout(() => {
            void saveChanges()
          }, 500)
        }
        return () => clearTimeout(timeout)
      }, [rowValue])

      return (
        <Col size={2}>
          <AlignedRow style={{ paddingRight: 40 }}>
            {editing ? (
              <AlignedRow style={{ paddingRight: 40, flex: 1 }}>
                <EditInput
                  autoFocus={position === 0}
                  style={{
                    backgroundColor: bg.card,
                    color: fonts.primary,
                  }}
                  onChangeText={handleRowValue}
                  value={rowValue}
                />
              </AlignedRow>
            ) : (
              <AlignedRow style={{ flex: 1, paddingRight: 40 }}>
                <View style={{ flex: 1 }}>
                  {!!titleHeading.length && (
                    <Label>
                      {titleHeading.filter(heading => heading).join(', ')}
                    </Label>
                  )}

                  <Subtitle numberOfLines={1} primary>
                    {rowValue}
                  </Subtitle>
                </View>

                {!!item.badge && (
                  <MenuBadge>
                    <Overline color="#FFFFFF">{item.badge}</Overline>
                  </MenuBadge>
                )}
              </AlignedRow>
            )}
          </AlignedRow>
        </Col>
      )
    }
    case 'number':
    case 'currency':
    case 'code':
    case 'text': {
      useEffect(() => {
        let timeout
        if (existingValue.current !== rowValue) {
          timeout = setTimeout(() => {
            void saveChanges()
          }, 1000)
        }
        return () => clearTimeout(timeout)
      }, [rowValue])

      return (
        <Col>
          {editing ? (
            <AlignedRow style={{ paddingRight: 40, flex: 1 }}>
              <EditInput
                autoFocus={position === 0}
                style={[
                  { backgroundColor: bg.card, color: fonts.secondary },
                  prop.required &&
                    (!rowValue || !rowValue.length) && {
                      backgroundColor:
                        'linear-gradient(0deg, rgba(242, 163, 89, 0.08), rgba(242, 163, 89, 0.08)), #F4F5F9',
                    },
                ]}
                onChangeText={handleRowValue}
                value={rowValue}
              />
            </AlignedRow>
          ) : (
            <>
              {prop.type === 'currency' ? (
                <Body style={{ paddingRight: 40 }}>
                  {new Intl.NumberFormat(`en-GB`, {
                    currency: `GBP`,
                    style: 'currency',
                  }).format(rowValue)}
                </Body>
              ) : (
                <Body numberOfLines={2} style={{ paddingRight: 40 }}>
                  {rowValue}{' '}
                  {parseFloat(rowValue) && (rowValue > 1 || rowValue === 0)
                    ? prop.plural
                    : prop.singular}
                </Body>
              )}
            </>
          )}
        </Col>
      )
    }
    case 'date': {
      const formatString = prop.time === 'true' ? 'do MMM, yyy, h:mma' : 'do MMM, yyy'

      const [calendarText, setCalendarText] = useState(() => {
        if (rowValue) {
          const date = new Date(rowValue)
          if (date.toString() !== 'Invalid Date') {
            return format(new Date(date), formatString)
          }
        }
        return ''
      })

      const handleClose = () => setShowDropdown(false)

      const handleSave = async () => {
        await saveChanges()
        handleClose()
      }

      const handleReject = () => {
        handleRowValue('')
        setCalendarText('')
        handleClose()
      }

      const handleDismiss = () => {
        // only dismiss current changes if we
        // havent already confirmed changes
        if (existingValue.current !== rowValue) {
          rejectChanges()
          setCalendarText(() => {
            if (existingValue.current) {
              return format(
                new Date(existingValue.current),
                'do MMM, yyy, h:mma'
              )
            }
            return ''
          })
        }

        handleClose()
      }

      const outsideHandler = useCallback(handleDismiss, [])
      const wrapperRef = useRef(null)
      useOutsideAlerter(wrapperRef, outsideHandler)

      return (
        <Col>
          {editing ? (
            <View style={{ paddingRight: 40 }} ref={wrapperRef}>
              <AlignedRow justifyContent="space-between">
                <EditInput
                  onSubmitEditing={saveChanges}
                  onChangeText={setCalendarText}
                  value={calendarText}
                  onFocus={() => setShowDropdown(true)}
                  style={{ backgroundColor: bg.card, color: fonts.secondary }}
                />
              </AlignedRow>

              {showDropdown && (
                <CalendarSelect
                  value={rowValue}
                  handleChange={handleRowValue}
                  rejectChanges={handleReject}
                  saveChanges={handleSave}
                  dismissChanges={handleDismiss}
                  calendarText={calendarText}
                  setCalendarText={setCalendarText}
                  time={prop.time === 'true'}
                />
              )}
            </View>
          ) : (
            <Body numberOfLines={2} style={{ paddingRight: 40 }}>
              {new Date(rowValue).toString() !== 'Invalid Date'
                ? format(new Date(rowValue), formatString)
                : null}
            </Body>
          )}
        </Col>
      )
    }
    case 'record': {
      const handleClose = () => setShowDropdown(false)

      const handleChange = records => {
        handleRowValue(records)

        handleEditedRow(
          item.recordid,
          prop.editaction,
          prop.name,
          records,
          existingValue.current
        )
      }

      const outsideHandler = useCallback(handleClose, [])
      const wrapperRef = useRef(null)
      useOutsideAlerter(wrapperRef, outsideHandler)

      const [colHeight, setColHeight] = useState(0)

      return (
        <Col
          onLayout={({ nativeEvent }) => {
            const { height } = nativeEvent.layout
            setColHeight(height)
          }}
        >
          {editing ? (
            <>
              <TouchableOpacity
                style={{
                  marginRight: 40,
                  height: 40,
                  backgroundColor:
                    prop.required === 'true' && (!rowValue || !rowValue.length)
                      ? 'linear-gradient(0deg, rgba(242, 163, 89, 0.08), rgba(242, 163, 89, 0.08)), #F4F5F9'
                      : bg.card,
                  justifyContent: 'center',
                  borderRadius: 12,
                  paddingHorizontal: 16,
                }}
                onPress={() => setShowDropdown(show => !show)}
              >
                <AlignedRow justifyContent="space-between">
                  {rowValue.length ? (
                    <AlignedRow
                      style={{
                        flexWrap: 'wrap',
                        flex: 1,
                      }}
                    >
                      <Body numberOfLines={1} primary>
                        {rowValue.map(record => record.title).join(', ')}
                      </Body>
                    </AlignedRow>
                  ) : (
                    <Body
                      numberOfLines={1}
                    >{`Search ${prop.name.toLowerCase()}`}</Body>
                  )}

                  <WorktribeIcon name="chevron-down" color="#757885" />
                </AlignedRow>
              </TouchableOpacity>

              {showDropdown && (
                <RecordSelect
                  name={name}
                  handleChange={handleChange}
                  handleClose={handleClose}
                  // doPostRequest={doPostRequest}
                  rclass={prop.rclasses}
                  value={rowValue}
                  multiple={prop.multiple}
                  pick_conditions={prop.pick_conditions}
                  recordids={prop.recordids}
                  placeholder={`Search ${name.toLowerCase()}`}
                  left={0}
                  top={50}
                />
              )}
            </>
          ) : (
            <AlignedRow style={{ marginRight: 40, justifyContent: 'center' }}>
              {rowValue?.length ? (
                <AlignedRow
                  style={{
                    flexWrap: 'wrap',
                    // marginTop: -6,
                    // marginBottom: -14,
                    flex: 1,
                  }}
                >
                  {rowValue.map(record => {
                    const { recordid, title, coverid } = record

                    return (
                      <View
                        key={recordid}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          paddingVertical: 6,
                          // paddingHorizontal: 8,
                          borderRadius: 18,
                          // marginBottom: 8,
                          marginRight: 8,
                          flexShrink: 1,
                        }}
                      >
                        <AlignedRow style={{ flex: 1 }}>
                          <CardThumbnail
                            source={{
                              uri: `https://zubanubi.com/resources/covers/cover${coverid}_thumb@2x.jpg`,
                              cache: 'force-cache',
                            }}
                          />

                          <Body numberOfLines={2} style={{ marginLeft: 8 }}>
                            {title}
                          </Body>
                        </AlignedRow>
                      </View>
                    )
                  })}
                </AlignedRow>
              ) : null}
            </AlignedRow>
          )}
        </Col>
      )
    }
    case 'status': {
      // return null
      const statuses = prop.options

      const selectedStatus = statuses?.find(status => status.name === rowValue)

      const outsideHandler = useCallback(() => setShowDropdown(false), [])
      const wrapperRef = useRef(null)
      useOutsideAlerter(wrapperRef, outsideHandler)

      return (
        <Col>
          {editing ? (
            <>
              <TouchableOpacity
                style={{
                  backgroundColor:
                    prop.required === 'true' && (!rowValue || !rowValue.length)
                      ? 'linear-gradient(0deg, rgba(242, 163, 89, 0.08), rgba(242, 163, 89, 0.08)), #F4F5F9'
                      : bg.card,
                  paddingVertical: 7,
                  paddingHorizontal: 12,
                  borderRadius: 12,
                  justifyContent: 'center',
                  marginRight: 40,
                }}
                onPress={() => setShowDropdown(show => !show)}
              >
                <AlignedRow
                  // style={{ paddingRight: 40 }}
                  justifyContent="space-between"
                >
                  <AlignedRow style={{ flex: 1 }}>
                    <View
                      style={{
                        height: 12,
                        width: 12,
                        backgroundColor: status.darker[selectedStatus?.color],
                        borderRadius: 6,
                        marginRight: 8,
                        marginBottom: 2,
                      }}
                    />
                    {editedRowValues[item.recordid]?.[prop.name] ? (
                      <AlignedRow>
                        <Body
                          numberOfLines={2}
                          style={{
                            textDecorationLine: 'line-through',
                            textDecorationStyle: 'solid',
                            marginRight: 8,
                          }}
                        >
                          {existingValue.current}
                        </Body>

                        <Body numberOfLines={2} color="#2AAD79">
                          {rowValue}
                        </Body>
                      </AlignedRow>
                    ) : (
                      <Body numberOfLines={2}>{rowValue}</Body>
                    )}
                  </AlignedRow>
                  <WorktribeIcon name="chevron-down" color="#757885" />
                </AlignedRow>
              </TouchableOpacity>

              {showDropdown && (
                <View
                  ref={wrapperRef}
                  style={[
                    {
                      zIndex: 1,
                      position: 'absolute',
                      top: 45,
                      width: 350,
                      maxHeight: 228,
                      backgroundColor: bg.primary,
                      borderRadius: 16,
                      paddingVertical: 8,
                    },
                    shadowStyles,
                  ]}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={true}
                    style={{ flex: 1 }}
                  >
                    {statuses.map(item => (
                      <HoverableOpacity
                        key={item.name}
                        style={{
                          justifyContent: 'center',
                          minHeight: 46,
                          paddingHorizontal: 16,
                          paddingVertical: 11,
                        }}
                        hoverStyle={{ backgroundColor: hover }}
                        onPress={() => {
                          handleRowValue(item.name)
                          handleEditedRow(
                            item.recordid,
                            prop.editaction,
                            prop.name,
                            status.name,
                            existingValue.current
                          )
                          setShowDropdown(false)
                        }}
                      >
                        <AlignedRow>
                          <View
                            style={{
                              height: 12,
                              width: 12,
                              borderRadius: 6,
                              marginRight: 12,
                              marginBottom: 2,
                              backgroundColor: status.darker[item.color],
                            }}
                          />
                          <Body primary>{item.name}</Body>
                        </AlignedRow>
                      </HoverableOpacity>
                    ))}
                  </ScrollView>
                </View>
              )}
            </>
          ) : rowValue.length ? (
            <StatusBadge rowValue={rowValue} selectedStatus={selectedStatus} />
          ) : null}
        </Col>
      )
    }
    case 'select': {
      const outsideHandler = useCallback(() => setShowDropdown(false), [])
      const wrapperRef = useRef(null)
      useOutsideAlerter(wrapperRef, outsideHandler)

      if (prop.style === 'slideypill') {
        return (
          <Col>
            <Score
              value={rowValue}
              options={prop.options}
              onSelect={value => {
                handleRowValue(value)
                handleEditedRow(
                  item.recordid,
                  prop.editaction,
                  prop.name,
                  value,
                  existingValue.current,
                  true
                )
              }}
              editable={editing}
            />
          </Col>
        )
      }

      return (
        <Col>
          {editing ? (
            <>
              <TouchableOpacity
                style={{
                  backgroundColor:
                    prop.required === 'true' && (!rowValue || !rowValue.length)
                      ? 'linear-gradient(0deg, rgba(242, 163, 89, 0.08), rgba(242, 163, 89, 0.08)), #F4F5F9'
                      : bg.card,
                  paddingVertical: 7,
                  paddingHorizontal: 12,
                  borderRadius: 12,
                  justifyContent: 'center',
                  marginRight: 40,
                }}
                onPress={() => setShowDropdown(show => !show)}
              >
                <AlignedRow
                  // style={{ paddingRight: 40 }}
                  justifyContent="space-between"
                >
                  <Body numberOfLines={2}>{rowValue}</Body>
                  <WorktribeIcon name="chevron-down" color="#757885" />
                </AlignedRow>
              </TouchableOpacity>

              {showDropdown && (
                <View
                  ref={wrapperRef}
                  style={[
                    {
                      zIndex: 1,
                      position: 'absolute',
                      top: 45,
                      width: 350,
                      maxHeight: 228,
                      backgroundColor: bg.primary,
                      borderRadius: 16,
                      paddingVertical: 8,
                    },
                    shadowStyles,
                  ]}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={true}
                    style={{ flex: 1 }}
                  >
                    {prop.options.map(option => (
                      <HoverableOpacity
                        onPress={() => {
                          handleRowValue(option.name)
                          handleEditedRow(
                            item.recordid,
                            prop.editaction,
                            prop.name,
                            option.name,
                            existingValue.current,
                            true
                          )
                          setShowDropdown(false)
                        }}
                        key={option.name}
                        style={{
                          justifyContent: 'center',
                          minHeight: 46,
                          paddingHorizontal: 16,
                          paddingVertical: 11,
                        }}
                        hoverStyle={{ backgroundColor: hover }}
                      >
                        <Body primary>{option.name}</Body>
                      </HoverableOpacity>
                    ))}
                  </ScrollView>
                </View>
              )}
            </>
          ) : (
            <Body numberOfLines={2} style={{ paddingRight: 40 }}>
              {rowValue}
            </Body>
          )}
        </Col>
      )
    }
    default: {
      return (
        <Col>
          <Body numberOfLines={2} style={{ paddingRight: 40 }}>
            {prop.value}
          </Body>
        </Col>
      )
    }
  }
}

export default GridRow

export const StatusBadge = ({ rowValue, selectedStatus }) => {
  const {
    typography: { Body },
    themeColors: {
      status: { light, dark, darker },
    },
  } = useContext(ThemeContext)

  if (!selectedStatus) return null

  return (
    <View
      style={{
        backgroundColor: light[selectedStatus.color],
        paddingHorizontal: 11,
        paddingVertical: 2,
        borderRadius: 10,
        alignSelf: 'flex-start',
        paddingRight: 40,
      }}
    >
      <AlignedRow gap={7}>
        <View
          style={{
            height: 10,
            width: 10,
            backgroundColor: darker[selectedStatus.color],
            borderRadius: 5,
          }}
        />
        <Body color={dark[selectedStatus.color]} numberOfLines={2}>
          {rowValue}
        </Body>
      </AlignedRow>
    </View>
  )
}

const CalendarSelect = props => {
  const {
    value,
    handleChange,
    rejectChanges,
    saveChanges,
    dismissChanges,
    calendarText,
    setCalendarText,
    time,
  } = props

  const {
    shadowStyles,
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <View
      style={[
        {
          position: 'absolute',
          backgroundColor: bg.primary,
          top: 45,
          left: -12,
          borderRadius: 16,
          padding: 20,
          maxWidth: 364,
        },
        shadowStyles,
      ]}
    >
      <InlineCalendar
        value={value}
        valueHandler={handleChange}
        saveChanges={saveChanges}
        rejectChanges={rejectChanges}
        dismissChanges={dismissChanges}
        calendarText={calendarText}
        setCalendarText={setCalendarText}
        time={time}
      />
    </View>
  )
}

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
`

const EditInput = styled.TextInput`
  margin-left: -12px;
  flex: 1;
  padding: 7px 12px;
  border-radius: 12px;
  font-family: proxima-nova;
  font-size: 15px;
  line-height: 22px;
  height: 40px;
`

const CardThumbnail = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
`

const MenuBadge = styled.View`
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding: 0 6px;
  height: 18px;
  border-radius: 30px;
  background-color: #2aad79;
`
