import React from 'react'
import { Text, View, StyleSheet } from 'react-native'

const TitleCard = () => (
  <View style={styles.titleContainer}>
    <Text style={styles.title}>Worktribe.</Text>
    <Text style={styles.subTitle}>Better Higher-Ed Administration</Text>
  </View>
)

export default TitleCard

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
    paddingVertical: 30,
  },
  title: {
    color: '#ffffff',
    fontWeight: '700',
    fontSize: 48,
    lineHeight: 58,
  },
  subTitle: {
    textTransform: 'uppercase',
    color: '#ffffff',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 18,
    marginTop: 5,
  },
})
