import React, { useContext, useMemo, useState, useEffect } from 'react'
import { TouchableOpacity, TextInput, FlatList, View } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'

import { AlignedRow } from '../../components/shared/Layout'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import { useAuth } from '../../contexts/AuthContext'
import useSocketMethods from '../../hooks/useSocketMethods'

const MessageGroup: React.FC<{ messageGroup: [] }> = ({ messageGroup }) => {
  const {
    typography: { Body, Caption },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const { user } = useAuth()

  return (
    <View style={{ marginVertical: 3 }}>
      {messageGroup.map(({ Sender, Message, Date: date }, index) => {
        const isSender = user!.Name === Sender

        return (
          <View
            style={[
              {
                alignSelf: isSender ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                marginVertical: 1,
                maxWidth: '70%',
                flexDirection: isSender ? 'row-reverse' : 'row',
              },
            ]}
          >
            <TouchableOpacity
              style={[
                {
                  paddingVertical: 8,
                  paddingHorizontal: 12,
                  backgroundColor: isSender ? '#1D7A55' : bg.card,
                },
                getBorderStyles(index, messageGroup.length, isSender),
              ]}
            >
              <Body color={isSender ? '#FFF' : fonts.primary}>{Message}</Body>
            </TouchableOpacity>

            {index === 0 && (
              <Caption style={{ marginHorizontal: 8 }}>
                {date.toDateString()}
              </Caption>
            )}
          </View>
        )
      })}
    </View>
  )
}

const Messages: React.FC<{
  value: any
  rClass: string
  recordId: string
  name: string
  editAction: string
}> = ({ value, rClass, recordId, name, editAction }) => {
  const {
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  useEffect(() => {
    const { records } = value

    let messages = []
    records.forEach(record => {
      let message = []
      message['id'] = record.recordid
      record.properties.forEach(property => {
        if (property.name === 'Date') {
          message[property.name] = new Date(property.label)
        } else {
          message[property.name] = property.label
        }
      })

      messages.push(message)
    })

    if (messages.length) {
      setComments(messages)
    }
  }, [value])

  const { record_action } = useSocketMethods()

  const [comments, setComments] = useState([])
  const [commentText, setCommentText] = useState('')

  const sendComment = () => {
    // make request
    record_action({
      recordid: recordId,
      rclassname: rClass,
      actionname: editAction,
      changes: [
        {
          method: 'add',
          position: 'end',
          property: name,
          changes: [
            {
              property: 'Message',
              method: 'set',
              value: commentText,
            },
          ],
        },
      ],
    })

    setCommentText('')
  }

  const renderItem = ({ item }) => <MessageGroup messageGroup={item} />

  const groupedComments = useMemo(
    () => groupBySender(comments),
    [comments.length]
  )

  return (
    <>
      <FlatList
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flex: 1, paddingHorizontal: 40 }}
        data={groupedComments}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        inverted
      />

      <AlignedRow style={{ height: 56, paddingHorizontal: 40 }}>
        <AlignedRow>
          <TouchableOpacity style={{ marginRight: 17.25 }}>
            <WorktribeIcon name="mention" />
          </TouchableOpacity>

          <TouchableOpacity style={{ marginRight: 17.25 }}>
            <WorktribeIcon name="smiley-smile" />
          </TouchableOpacity>

          <TouchableOpacity style={{ marginRight: 17.25 }}>
            <WorktribeIcon name="attachment" />
          </TouchableOpacity>
        </AlignedRow>

        <TextInput
          value={commentText}
          onChangeText={setCommentText}
          style={{
            height: 40,
            paddingHorizontal: 20,
            backgroundColor: bg.card,
            flex: 1,
            borderRadius: 30,
            color: fonts.primary,
          }}
          placeholder="Add comment"
          placeholderTextColor={fonts.secondary}
          onSubmitEditing={sendComment}
        />

        <TouchableOpacity
          disabled={commentText.length < 2}
          onPress={sendComment}
          style={{ marginLeft: 17.25 }}
        >
          <WorktribeIcon
            color={commentText.length > 2 ? '#2AAD79' : '#A0A4B8'}
            name="send-email"
          />
        </TouchableOpacity>
      </AlignedRow>
    </>
  )
}

export default Messages

const groupBySender = comments => {
  const groupedComments = comments.reduce((grouped, comment) => {
    if (typeof comment === 'string') {
      grouped.unshift(comment)
    } else if (grouped.length === 0) {
      grouped.unshift([comment])
    } else if (grouped[0][0].author === comment.author) {
      grouped[0].push(comment)
    } else {
      grouped.unshift([comment])
    }
    return grouped
  }, [])

  return groupedComments
}

const getBorderStyles = (
  index: number,
  groupLength: number,
  owner: boolean
) => {
  if (groupLength === 1) {
    return { borderRadius: 20 }
  } else if (index === 0) {
    return {
      borderBottomLeftRadius: owner ? 20 : 4,
      borderBottomRightRadius: owner ? 4 : 20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    }
  } else if (index === groupLength - 1) {
    return {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopLeftRadius: owner ? 20 : 4,
      borderTopRightRadius: owner ? 4 : 20,
    }
  } else {
    return {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: owner ? 4 : 20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: owner ? 4 : 20,
    }
  }
}
