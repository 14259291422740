import AsyncStorage from '@react-native-async-storage/async-storage'

const SERVER_KEY = '@zubanubiRepo'
const useZubanubi = () => {
  const getZubanubiRepo = async () => {
    try {
      const value = await AsyncStorage.getItem(SERVER_KEY)
      if (value !== null) {
        return value
      } else {
        return 'main'
      }
    } catch (e) {
      return 'main?'
    }
  }

  return getZubanubiRepo
}

export default useZubanubi