import { useCallback } from 'react'
import { Platform, NativeModules } from 'react-native'
import useSocketListener from './useSocketListener'
import { useAuth } from '../contexts/AuthContext'
import env from '../../env'

/*
const PushNotificationIOS =
  env.use_push_notifications === true && Platform.OS === 'ios'
    ? require('@react-native-community/push-notification-ios').default
    : null
*/

const WebBadging =
  Platform.OS === 'web' &&
  ('setExperimentalAppBadge' in navigator ||
    'ExperimentalBadge' in window ||
    'setAppBadge' in navigator)
    ? true
    : false

// Set if running in Electron
const RunningInElectron =
  Platform.OS === 'web' &&
  'userAgent' in navigator &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1

const ipcRenderer = RunningInElectron
  ? window.require('electron').ipcRenderer
  : null

const useAppBadges = () => {
  const {
    user: { userid },
    updateUser,
  } = useAuth()

  const setAppIconBadge = useCallback(value => {
    //if (PushNotificationIOS) {
      //PushNotificationIOS.setApplicationIconBadgeNumber(value)
    if (Platform.OS == "ios") {
      NativeModules?.Device?.setBadgeCount(Number(value))
    } else if (WebBadging) {
      if (RunningInElectron) {
        // This count feeds directly into the 'direct count' notification, which in WT2 parlance is numbered badgeStyle
        // If we wanted to update the 'non-numbered' badge count, we'd send to 'new-general-notify' instead
        if (value > 0) {
          // update direct notifications count for MacOS
          ipcRenderer.send('new-direct-notify', value, true)
          // update direct notifications count for Windows
          // ipcRenderer.sendSync('update-badge', value)
        } else {
          // if we have neither a totalbadgecount or a generalCount, then clear notifications in the app
          // MacOS
          ipcRenderer.send('clear-notify')
          // Windows
          // ipcRenderer.sendSync('update-badge', null)
        }
      } else {
        if (value > 0) {
          if (navigator.setAppBadge) {
            navigator.setAppBadge(value)
          } else if (navigator.setExperimentalAppBadge) {
            navigator.setExperimentalAppBadge(value)
          } else if (window.ExperimentalBadge) {
            window.ExperimentalBadge.set(value)
          }
        } else {
          if (navigator.clearAppBadge) {
            navigator.clearAppBadge()
          } else if (navigator.clearExperimentalAppBadge) {
            navigator.clearExperimentalAppBadge()
          } else if (window.ExperimentalBadge) {
            window.ExperimentalBadge.clear()
          }
        }
      }
    }
  }, [])

  const onMessage = useCallback(
    (message: any) => {
      const { user } = JSON.parse(message.data)

      // is this user currently authenticated
      if (user.userid === userid) {
        updateUser(user)

        // convert to badge number from string
        const badgeNumber = Number(user['App Badge'].replace('+', ''))
        setAppIconBadge(badgeNumber)
      }
    },
    [setAppIconBadge, updateUser, userid]
  )

  useSocketListener(onMessage)
}

export default useAppBadges
