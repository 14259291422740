import React, { useContext, useState } from 'react'
import { FlatList } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../shared/WorktribeIcon'

const menuItems = [
  {
    title: 'Create a project',
    color: '2AAD79',
  },
  {
    title: 'Request a contract',
    color: 'E94545',
  },
  {
    title: 'Deposit an output',
    color: '46C6D1',
  },
  {
    title: 'Create a programme',
    color: 'F2CE6A',
  },
  {
    title: 'Create a module',
    color: 'E08938',
  },
  {
    title: 'Add a news item',
    color: '2AAD79',
  },
  {
    title: 'Add an ethics application',
    color: 'E94545',
  },
  {
    title: 'Add a task',
    color: '46C6D1',
  },
]

const Option = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  height: 48px;
  background-color: #f4f5f9;
  border-radius: 16px;
  margin-bottom: 12px;
  padding: 0 24px;
`

const MenuItem = ({ item, navigation }) => {
  const {
    typography: { H5 },
    themeColors: { bg }
  } = useContext(ThemeContext)
  // const [hovered, setHovered] = useState(false)
  return (
    <Option
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
      // onPress={() => navigation.navigate('Create', { rClass: item.title })}
      style={{ backgroundColor: bg.card }}
    >
      <WorktribeIcon name="bookmark" color={`#${item.color}`} />
      <H5 primary style={{ marginLeft: 20 }}>{item.title}</H5>
    </Option>
  )
}

const AddMenu = ({ navigation }) => {
  return (
    <FlatList
      contentContainerStyle={{ marginTop: 12 }}
      data={menuItems}
      renderItem={({ item }) => (
        <MenuItem item={item} navigation={navigation} />
      )}
      keyExtractor={item => item.title}
    />
  )
}

export default AddMenu
