import React, { useContext } from 'react'
import { AuthContext, useAuth } from '../../contexts/AuthContext'
import styled from 'styled-components/native'
import Avatar from './Avatar'
import Version from '../../components/shared/Version'
import { Image, View, ScrollView } from 'react-native'
import * as Typography from '../shared/Typography'
import { useDispatch } from 'react-redux'
import { showProfile } from '../../store/actions/layout'

const Container = styled.View`
  flex: 1;
  width: 280px;
  justify-content: space-between;
  background-color: #ffffff;
  border-left-width: 1px;
  border-color: #dde1ed;
`

const Header = styled.View`
  background-color: #1b283f;
  padding: 68px 24px 23px 24px;
`

const MenuItems = styled.View`
  padding: 23px;
`

const MenuItem = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0;
`

const Row = styled.View`
  flex-direction: row;
  align-items: center;
`

const PlaceHolderIcon = styled.View`
  width: 28px;
  height: 28px;
  background-color: #c4c4c4;
  margin-right: 16px;
`

const Badge = styled.View`
  background-color: #e94545;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
`

export const Profile = ({ navigation }) => {
  const { signOut, user } = useAuth()

  console.log(user)

  const dispatch = useDispatch()

  return (
    <>
      <Header>
        <Image
          style={{
            position: 'absolute',
            top: -60,
            right: 0,
          }}
          source={require('../../../assets/group1.png')}
        />
        {user ? (
          <>
            <View style={{ marginBottom: 16 }}>
              <Avatar userId={user.userid} size={46} radius={16} />
            </View>

            <Typography.H3 color="#FFF">{`${user['Given Name']} ${user['Family Name']}`}</Typography.H3>
            <Typography.Body color="#FFF">{user.email}</Typography.Body>
          </>
        ) : null}
      </Header>

      <ScrollView bounces={false}>
        <MenuItems>
          <MenuItem
          // onPress={() =>
          //   navigation.navigate('Profile', {
          //     profile: authenticatedUser,
          //   })
          // }
          >
            <Row>
              <PlaceHolderIcon />
              <Typography.Subtitle color="#0E1012">
                My Profile
              </Typography.Subtitle>
            </Row>
          </MenuItem>

          <MenuItem>
            <Row>
              <PlaceHolderIcon />
              <Typography.Subtitle color="#0E1012">
                Settings
              </Typography.Subtitle>
            </Row>
          </MenuItem>

          <MenuItem>
            <Row>
              <PlaceHolderIcon />
              <Typography.Subtitle color="#0E1012">
                What's new
              </Typography.Subtitle>
            </Row>
            <Badge>
              <Typography.Label color="#FFF">NEW!</Typography.Label>
            </Badge>
          </MenuItem>

          <MenuItem>
            <Row>
              <PlaceHolderIcon />
              <Typography.Subtitle color="#0E1012">
                Notifications
              </Typography.Subtitle>
            </Row>
          </MenuItem>

          <MenuItem>
            <Row>
              <PlaceHolderIcon />
              <Typography.Subtitle color="#0E1012">
                Audit Trail
              </Typography.Subtitle>
            </Row>
          </MenuItem>
        </MenuItems>
      </ScrollView>

      <MenuItems>
        <MenuItem
          onPress={() => {
            dispatch(showProfile(false))
            signOut()
          }}
        >
          <Row>
            <PlaceHolderIcon />
            <Typography.Subtitle color="#E94545">Logout</Typography.Subtitle>
          </Row>
        </MenuItem>
        {/* <MenuItem onPress={() => navigation.navigate('Switch')}>
          <Row>
            <PlaceHolderIcon />
            <Typography.Subtitle color="#E94545">
              Switch user
            </Typography.Subtitle>
          </Row>
        </MenuItem> */}
        <Version textColor="#c4c4c4" />
      </MenuItems>
    </>
  )
}

const ProfileSideBar = ({ navigation }) => (
  <Container>
    <Profile navigation={navigation} />
  </Container>
)

export default ProfileSideBar
