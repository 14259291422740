import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { ScrollView, View, TextInput, Platform, Pressable } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { AlignedRow } from '../../shared/Layout'
import WorktribeIcon from '../../shared/WorktribeIcon'
import HoverableOpacity from '../../shared/HoverableOpacity'

import useRecordAutoComplete from '../../../hooks/useRecordAutoComplete'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter'

const RecordSelect = ({
  name,
  handleClose,
  doPostRequest,
  rclass,
  value = [],
  multiple,
  pick_conditions,
  recordids,
  placeholder,
  handleChange,
  top = 6,
  left = 40,
}) => {
  const {
    typography: { Overline },
    themeColors: { bg, fonts },
    shadowStyles,
  } = useContext(ThemeContext)

  const [search, onChangeSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState(() => {
    if (!value) return []

    if ('value' in value) {
      return value.value
    }
    return value
  })

  const config = {
    pick_conditions,
    recordids,
  }

  const data = useRecordAutoComplete(rclass, search, config)

  const selectItem = record => {
    setSelectedItems(items => {
      let result
      if (multiple) {
        result = [...items, record]
      } else {
        result = [record]

        handleClose()
      }

      if (handleChange) {
        handleChange(result)
      } else {
        doPostRequest({
          property: name,
          method: 'set',
          recordids: result.map(res => res.recordid),
        })
      }

      return result
    })

    if (!multiple) {
      handleClose()
    }
  }

  const removeItem = recordid => {
    setSelectedItems(items => {
      const filtered = items.filter(item => item.recordid !== recordid)

      if (handleChange) {
        handleChange(filtered)
      } else {
        doPostRequest({
          property: name,
          method: 'set',
          recordids: filtered.map(res => res.recordid),
        })
      }

      return filtered
    })
  }

  const inputRef = useRef()

  useEffect(() => {
    inputRef?.current.focus()
  }, [])

  const outsideHandler = useCallback(handleClose, [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <View
      ref={wrapperRef}
      style={[
        {
          position: 'absolute',
          zIndex: 999,
          backgroundColor: bg.primary,
          top,
          left,
          borderRadius: 16,
          paddingVertical: 3,
          maxHeight: 345,
          width: 720,
        },
        shadowStyles,
      ]}
    >
      <View style={{ height: 44, paddingVertical: 12, paddingHorizontal: 16 }}>
        <AlignedRow>
          <WorktribeIcon size={20} color="#A0A4B8" name="search-alternate" />

          <TextInput
            ref={inputRef}
            value={search}
            onChangeText={onChangeSearch}
            style={[
              {
                fontFamily: 'proxima-nova',
                color: fonts.secondary,
                fontSize: 15,
                lineHeight: 24,
                marginLeft: 14,
                height: 24,
                flex: 1,
              },
              Platform.OS === 'web' && { outline: 'none' },
            ]}
            placeholder={placeholder}
            placeholderTextColor="#A0A4B8"
          />
        </AlignedRow>
      </View>

      <ScrollView style={{ flex: 1 }}>
        {multiple && (
          <>
            {!!selectedItems.length && (
              <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
                selected
              </Overline>
            )}

            {selectedItems.map(record => (
              <RecordItem
                onRemove={removeItem}
                key={record.recordid}
                record={record}
              />
            ))}

            {!!selectedItems.length && (
              <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
                others
              </Overline>
            )}
          </>
        )}

        {data
          .filter(record => {
            const selectedIds = selectedItems.map(item => item.recordid)
            return !selectedIds.includes(record.recordid)
          })
          .map(record => (
            <RecordItem
              key={record.recordid}
              record={record}
              onSelect={selectItem}
            />
          ))}
      </ScrollView>
    </View>
  )
}

const RecordItem = ({ record, onSelect, onRemove }) => {
  const {
    typography: { Body },
    themeColors: { hover },
  } = useContext(ThemeContext)

  let nameString = record.title

  const details = record.properties
    .filter(property => property.type !== 'title')
    .map(property => property.label)
    .filter(property => property)
    .join(', ')

  return (
    <HoverableOpacity
      onPress={() => {
        if (onSelect) {
          onSelect(record)
        }
      }}
      hoverStyle={{ backgroundColor: hover }}
      style={{
        paddingVertical: 9,
        paddingHorizontal: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <AlignedRow style={{ flex: 1 }}>
        <AlignedRow style={{ marginRight: 6 }}>
          <CardThumbnail
            source={{
              uri: `https://zubanubi.com/resources/covers/cover${record.coverid}_thumb@2x.jpg`,
              cache: 'force-cache',
            }}
          />

          <Body style={{ marginLeft: 8 }} primary>
            {nameString}
          </Body>
        </AlignedRow>

        <Body numberOfLines={1}>{details}</Body>
      </AlignedRow>

      {onRemove && (
        <Pressable onPress={() => onRemove(record.recordid)}>
          <WorktribeIcon name="remove-circle" />
        </Pressable>
      )}
    </HoverableOpacity>
  )
}

export default RecordSelect

const CardThumbnail = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
`
