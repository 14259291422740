import { useCallback, useEffect, useState } from 'react'
import useSocketListener from './useSocketListener'
import useSocketMethods from './useSocketMethods'

interface IUser {
  coverid: number
  properties: any
  recordid: number
  title: string
}

const useUsers = (): IUser[] => {
  const [users, setUsers] = useState<IUser[]>([])
  const { record_find } = useSocketMethods()
  const onMessage = useCallback(message => {
    const { data, method } = JSON.parse(message.data)
    if (method === 'record_find') {
      const { records } = data
      setUsers(Object.values(records))
    }
  }, [])
  useSocketListener(onMessage)
  useEffect(() => {
    record_find({
      rclassname: 'Person',
      returnpropertynames: ['Roles'],
      includecards: 'true',
    })
  }, [record_find])
  return users
}

export default useUsers
