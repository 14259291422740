import React from 'react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import * as Typography from '../shared/Typography'

const Container = styled.TouchableOpacity`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px 12px;
  margin-bottom: 16px;
`

const Row = styled.View`
  flex-direction: row;
`

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  margin-right: 12px;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const Cost = ({ cost }) => (
  <Container>
    <Row>
      <Thumbnail
        source={{
          uri: `https://source.unsplash.com/${
            imageFeed[Math.floor(Math.random() * imageFeed.length)]
          }/50x50`,
          cache: 'force-cache',
        }}
      />
      <View>
        <Typography.Overline color="#757885">{cost.type}</Typography.Overline>
        <Typography.Subtitle color="#0E1012">{cost.title}</Typography.Subtitle>
        <Typography.Body color="#757885">{cost.cost}</Typography.Body>
      </View>
    </Row>
  </Container>
)

export default Cost
