import React, { useContext } from 'react'
import styled from 'styled-components/native'
import * as Typography from './Typography'
import { ThemeContext } from '../../contexts/ThemeContext'

const Container = styled.View`
  padding: 0 6px;
  background-color: #2aad79;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
`

const Badge = ({ count }) => {
  const {
    typography: { Overline },
  } = useContext(ThemeContext)
  return (
    <Container>
      <Overline color="#FFF">{count > 9 ? '9+' : count}</Overline>
    </Container>
  )
}

export default Badge
