import React, { useContext, useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import {
  FlatList,
  View,
  ActivityIndicator,
  Image,
  StyleSheet,
} from 'react-native'
import { APIContext } from '../../contexts/APIContext'
import Avatar from '../../components/shared/Avatar'
import TopBar from '../../components/shared/TopBar'
import SearchInput from '../../components/shared/SearchInput'
import WorktribeIcon from '../../components/shared/WorktribeIcon'

// temporary job titles until data sorted
const JOB_TITLES = [
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
  'Rasputin Impersonator',
  'Car Behaviour Consultant',
  'Ex-Moonshiner',
  'Pork Rind Expert',
  'Bread Scientist',
  'Bear Biologist and Paperfolder',
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
]

const Container = styled.View`
  flex: 1;
  background-color: #ebecf2;
`

const UserCard = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${props => (props.selected ? '#F2FCF8' : 'transparent')};
  ${({ rounded }) => {
    if (rounded) {
      return `border-${rounded}-left-radius: 16px;border-${rounded}-right-radius: 16px;`
    }
    return ''
  }};
`

const UserDetails = styled.View`
  flex: 1;
  margin-left: 12px;
`

const UserName = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 24px;
  color: #0e1012;
`

const UserTitle = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const UserSelect = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: ${props => (props.selected ? 0 : '1px')} solid #a0a4b8;
  background-color: ${props => (props.selected ? '#2AAD79' : 'transparent')};
`

const SelectedUsers = styled.ScrollView`
  flex: 0;
  min-height: 110px;
  padding: 5px 16px;
`

const SelectedUser = styled.TouchableOpacity`
  width: 75px;
  margin-right: 10px;
  align-items: center;
`

const SelectedUserName = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #0e1012;
  text-align: center;
  margin-top: 4px;
`

const RemoveSelected = styled.View`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffffff;
  position: absolute;
  top: -2px;
  right: -5px;
`

const Loader = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Footer = styled.View`
  padding: 10px 20px;
`

const SelectButton = styled.TouchableOpacity`
  background-color: ${props => [props.enabled ? '#2aad79' : '#A0A4B8']};
  padding: 16px 0;
  border-radius: 30px;
`

const SelectButtonText = styled.Text`
  color: #ffffff;
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: center;
`

const AroSelectScreen = ({ navigation, route }) => {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])

  const { fieldName, dispatch } = route.params

  const filteredUsers = users?.filter(item => {
    return item.name.toLowerCase().match(search.toLowerCase())
  })

  const selectedUsers = users?.filter(user => user.selected)

  const { getUsers } = useContext(APIContext)

  useEffect(() => {
    ;(async function getSuggestedUsers() {
      try {
        const response = await getUsers()
        const suggestedUsers = response.data.map(user => {
          return {
            ...user,
            selected: false,
          }
        })
        setUsers(suggestedUsers)
      } catch (e) {}
    })()
  }, [getUsers])

  const toggleSelected = id => {
    const updatedUsers = [...users]
    const selectedUser = updatedUsers.find(user => user.id === id)
    selectedUser.selected = !selectedUser.selected
    setUsers(updatedUsers)
  }

  const renderItem = ({ item, index }) => {
    let rounded = ''
    if (index === 0) {
      rounded = 'top'
    } else if (index === users.length - 1) {
      rounded = 'bottom'
    }

    return (
      <UserCard
        rounded={rounded}
        selected={item.selected}
        onPress={() => toggleSelected(item.id)}
      >
        <Avatar userId={item.id} size={40} radius={14} />
        <UserDetails>
          <UserName>{item.name}</UserName>
          <UserTitle>{JOB_TITLES[item.id - 1]}</UserTitle>
        </UserDetails>
        {item.selected ? <WorktribeIcon name="check-circle" /> : <UserSelect />}
      </UserCard>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <TopBar
        leftItem={
          <MaterialCommunityIcons
            name="chevron-left"
            size={35}
            color="#0E1012"
            onPress={() => navigation.pop()}
          />
        }
        title={fieldName}
      />
      <SearchInput
        search={search}
        setSearch={setSearch}
        backgroundColor="#FFFFFF"
      />
      {selectedUsers.length ? (
        <SelectedUsers horizontal showsHorizontalScrollIndicator={false}>
          {selectedUsers.map(user => (
            <SelectedUser onPress={() => toggleSelected(user.id)} key={user.id}>
              <View style={{ position: 'relative' }}>
                <Avatar
                  onPress={() => toggleSelected(user.id)}
                  userId={user.id}
                  size={48}
                  radius={16}
                />
                <RemoveSelected>
                  <Image
                    style={{ width: 18, height: 18 }}
                    source={require('../../../assets/icons/remove-circle.png')}
                  />
                </RemoveSelected>
              </View>
              <SelectedUserName>{user.name}</SelectedUserName>
            </SelectedUser>
          ))}
        </SelectedUsers>
      ) : null}
      <Container>
        {users.length > 0 ? (
          <FlatList
            contentContainerStyle={{
              marginHorizontal: 16,
              backgroundColor: '#FFFFFF',
              borderRadius: 16,
            }}
            data={filteredUsers}
            renderItem={renderItem}
            keyExtractor={item => item.id.toString()}
            ItemSeparatorComponent={() => (
              <View style={{ backgroundColor: '#DDE1ED', height: 1 }} />
            )}
          />
        ) : (
          <Loader>
            <ActivityIndicator />
          </Loader>
        )}

        <Footer>
          <SelectButton
            enabled={selectedUsers.length}
            onPress={() => {
              dispatch({
                type: 'UPDATE_FIELD',
                payload: {
                  field: fieldName,
                  value: selectedUsers.map(user => user.name).join(', '),
                },
              })
              navigation.goBack()
            }}
          >
            <SelectButtonText>Assign</SelectButtonText>
          </SelectButton>
        </Footer>
      </Container>
    </SafeAreaView>
  )
}

export default AroSelectScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ebecf2',
  },
})
