import React from 'react'
import { Platform, Dimensions } from 'react-native'
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack'

import BottomTabNavigator from './BottomTabNavigator'
import RecordScreen from '../screens/portrait/RecordScreen'
import ConversationScreen from '../screens/portrait/ConversationScreen'
import InstallScreen from '../screens/portrait/InstallScreen'
import SwitchUserScreen from '../screens/portrait/SwitchUserScreen'
import SearchRecentScreen from '../screens/portrait/SearchRecentScreen'
import SearchResultsScreen from '../screens/portrait/SearchResultsScreen'
import SearchRecordScreen from '../screens/portrait/SearchRecordScreen'
import FilterScreen from '../screens/portrait/FilterScreen'
import NewConversationScreen from '../screens/portrait/NewConversationScreen'
import GroupDetailsScreen from '../screens/portrait/GroupDetailsScreen'
import GroupSettingsScreen from '../screens/portrait/GroupSettingsScreen'
import ProfileScreen from '../screens/portrait/ProfileScreen'
import CreateScreen from '../screens/portrait/CreateScreen'
import AroSelectScreen from '../screens/portrait/AroSelectScreen'
import SectionScreen from '../screens/portrait/SectionScreen'
import SectionEditScreen from '../screens/portrait/SectionEditScreen'
import AssessmentScreen from '../screens/portrait/AssessmentScreen'

const AppStack = createStackNavigator()

export default () => {
  const { width } = Dimensions.get('window')

  return (
    <AppStack.Navigator
      mode="card"
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        headerShown: false,
        gestureEnabled: Platform.OS === 'ios',
        gestureResponseDistance: {
          horizontal: width,
        },
        gestureDirection: 'horizontal',
      }}
    >
      <AppStack.Screen name="Feed" component={BottomTabNavigator} />
      <AppStack.Screen name="Record" component={RecordScreen} />
      <AppStack.Screen name="Message" component={ConversationScreen} />
      <AppStack.Screen name="Install" component={InstallScreen} />
      <AppStack.Screen name="Switch" component={SwitchUserScreen} />
      <AppStack.Screen name="Profile" component={ProfileScreen} />
      <AppStack.Screen name="Create" component={CreateScreen} />
      <AppStack.Screen name="AroSelect" component={AroSelectScreen} />
      <AppStack.Screen name="Section" component={SectionScreen} />
      <AppStack.Screen name="SectionEdit" component={SectionEditScreen} />
      <AppStack.Screen name="Assessment" component={AssessmentScreen} />
      <AppStack.Screen
        name="NewConversation"
        component={NewConversationScreen}
      />
      <AppStack.Screen name="GroupDetails" component={GroupDetailsScreen} />
      <AppStack.Screen name="GroupSettings" component={GroupSettingsScreen} />
      <AppStack.Screen name="SearchRecent" component={SearchRecentScreen} />
      <AppStack.Screen
        name="SearchResultsScreen"
        component={SearchResultsScreen}
      />
      <AppStack.Screen
        name="SearchRecordScreen"
        component={SearchRecordScreen}
      />
      <AppStack.Screen
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
        }}
        name="FilterScreen"
        component={FilterScreen}
      />
    </AppStack.Navigator>
  )
}
