import React, { useEffect, useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import Navigator from '../navigators/Navigator'
import useOrientation from '../hooks/useOrientation'

import { navigationRef } from '../services/RootNavigation'
import { useAuth } from '../contexts/AuthContext'

import { SafeAreaProvider } from 'react-native-safe-area-context'
import DisconnectedPopup from '../components/landscape/DisconnectedPopup'

// figma styles
import SplashScreen from '../screens/portrait/SplashScreen'
import LoginScreen from '../screens/portrait/LoginScreen'
import { Host, Portal } from 'react-native-portalize'

import HomeScreen from '../screens/landscape/HomeScreen'
import MineScreen from '../screens/landscape/MineScreen'
import MessagesScreen from '../screens/landscape/MessagesScreen'
import ServicesScreen from '../screens/landscape/ServicesScreen'
import ServiceScreen from '../screens/landscape/ServiceScreen'
import SearchScreen from '../screens/landscape/SearchScreen'
import AppsScreen from '../screens/landscape/AppsScreen'
import RecordScreen from '../screens/landscape/RecordScreen'
import BecomeScreen from '../screens/landscape/BecomeScreen'
import SettingsScreen from '../screens/landscape/SettingsScreen'
import TimetableScreen from '../screens/landscape/TimetableScreen'
// import BuilderScreen from '../screens/landscape/BuilderScreen'
import QuillScreen from '../screens/landscape/QuillScreen'
import LogScreen from '../screens/landscape/LogScreen'
import WizardScreen from '../screens/landscape/WizardScreen'
import ImpactScreen from '../screens/landscape/ImpactScreen'

const LandscapeStack = createStackNavigator()

const linking = {
  config: {
    initialRouteName: 'Home',
    screens: {
      Login: 'login',
      Home: 'home/:rClass?/:recordId?',
      Mine: 'mine',
      Messages: 'messages/:rClass?/:recordId?',
      Services: 'services',
      Service: {
        path: 'service/:title',
        stringify: {
          title: title => title.toLowerCase().replaceAll(' ', '_'),
        },
      },
      Profile: 'my-profile/:profile',
      Record: 'record/:rClass?/:recordId?',
      Search: 'search/:search/:rclass?',
      Apps: 'apps',
      Become: 'become',
      Settings: 'my-settings',
      Timetable: 'time',
      Log: 'log',
      Quill: 'quill',
      Wizard: 'wizard',
      Impact: 'impact',
    },
  },
}

const RootStack = createStackNavigator()

const Navigation = () => {
  const { loading, authenticated, connected } = useAuth()
  const isLandscape = useOrientation()

  const [isDisconnected, setIsDisconnected] = useState(false)

  useEffect(() => {
    setIsDisconnected(!connected)
  }, [connected])

  if (loading) {
    return <SplashScreen />
  }

  return (
    <Host>
      <SafeAreaProvider>
        <NavigationContainer
          linking={linking}
          ref={navigationRef}
          documentTitle={{
            formatter: (_, route) => {
              switch (route.name) {
                case 'Service': {
                  const { title } = route.params
                  return title.charAt(0).toUpperCase() + title.slice(1)
                }
                case 'Record': {
                  const { rClass, recordId } = route.params
                  return `${rClass}: ${recordId}`
                }
                default: {
                  return route.name
                }
              }
            },
          }}
        >
          <RootStack.Navigator
            headerMode="none"
            screenOptions={{
              animationEnabled: false,
            }}
          >
            {authenticated ? (
              <>
                {isLandscape ? (
                  <>
                    {/* <LandscapeStack.Screen
                      name="Wizard"
                      component={WizardScreen}
                    /> */}

                    <LandscapeStack.Screen name="Home" component={HomeScreen} />
                    <LandscapeStack.Screen name="Mine" component={MineScreen} />
                    <LandscapeStack.Screen
                      name="Messages"
                      component={MessagesScreen}
                    />
                    <LandscapeStack.Screen
                      name="Services"
                      component={ServicesScreen}
                    />
                    <LandscapeStack.Screen
                      name="Service"
                      component={ServiceScreen}
                    />
                    <LandscapeStack.Screen
                      name="Record"
                      component={RecordScreen}
                    />
                    <LandscapeStack.Screen name="Apps" component={AppsScreen} />
                    <LandscapeStack.Screen
                      name="Search"
                      component={SearchScreen}
                    />
                    <LandscapeStack.Screen
                      name="Become"
                      component={BecomeScreen}
                    />
                    <LandscapeStack.Screen
                      name="Settings"
                      component={SettingsScreen}
                    />
                    <LandscapeStack.Screen
                      name="Timetable"
                      component={TimetableScreen}
                    />
                    <LandscapeStack.Screen name="Log" component={LogScreen} />
                    <LandscapeStack.Screen
                      name="Quill"
                      component={QuillScreen}
                    />
                    <LandscapeStack.Screen
                      name="Impact"
                      component={ImpactScreen}
                    />
                  </>
                ) : (
                  <RootStack.Screen
                    name="Root"
                    component={Navigator}
                    options={{
                      animationEnabled: false,
                    }}
                  />
                )}
              </>
            ) : (
              <RootStack.Screen
                name="Login"
                component={LoginScreen}
                options={{
                  animationEnabled: false,
                }}
              />
            )}
          </RootStack.Navigator>
          <Portal>
            {isDisconnected && authenticated ? <DisconnectedPopup /> : null}
          </Portal>
        </NavigationContainer>
      </SafeAreaProvider>
    </Host>
  )
}

export default Navigation
