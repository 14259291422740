import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../shared/Layout'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import WorktribeIcon from '../shared/WorktribeIcon'
import { SmallButton } from '../shared/Button'
import { Portal } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'
import useSocketMethods from '../../hooks/useSocketMethods'
import InlineText from './controls/InlineText'
import InlineSelect from './controls/InlineSelect'
import InlineSwitch from './controls/InlineSwitch'

import Animated, {
  useSharedValue,
  useAnimatedRef,
  useAnimatedScrollHandler,
} from 'react-native-reanimated'

import { modalRef } from '../../services/Modal'
import InlineDate from './controls/InlineDate'

import { InlinePropertyWrapper } from './Properties'
import { IProperty } from '../../interfaces/record'

const EditDialog: React.FC<{
  dialogProps: any
  setDialogProps: (props: any) => void
}> = ({ dialogProps, setDialogProps }) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  const closeDialog = useCallback(() => {
    setDialogProps(null)
  }, [])

  return (
    <Portal>
      <WorktribeAlert
        useNativeDriver={true}
        contentStyle={{
          padding: 0,
          flex: 1,
          margin: 0,
        }}
        contentContainerStyle={{
          backgroundColor: bg.card,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 20,
          shadowColor: 'rgba(0, 0, 0, 0.06)',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0,
          shadowRadius: 32,
          maxHeight: '90%',
          padding: 0,
        }}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        show={!!dialogProps}
        customView={
          !!dialogProps && (
            <DialogContent {...dialogProps} onClose={closeDialog} />
          )
        }
        onDismiss={closeDialog}
      />
    </Portal>
  )
}

interface Dialog {
  items: IItemProperty[]
  settings: any
}

interface DialogContentProps {
  dialog: Dialog
  name: string
  rclassname: string
  recordid: string
  property: string
  method: string
  actionname: string
  validationmessage: string
  onClose: () => void
}

const DialogContent: React.FC<DialogContentProps> = ({
  dialog,
  name,
  rclassname,
  recordid,
  property,
  method,
  actionname,
  validationmessage,
  onClose,
}) => {
  const [fieldData, setFieldData] = useState({})
  const [formValid, setFormValid] = useState<boolean>(true)
  const [formMessage, setFormMessage] = useState<string | null>(null)
  const notifyBody = useRef()

  const { record_action } = useSocketMethods()

  const {
    items,
    settings: { dialogbuttonlabel },
  } = dialog

  // initialise fields
  useEffect(() => {
    const fields = items.reduce((acc, item) => {
      if (!item.property) return acc

      const {
        property: { name, type },
        required,
      } = item

      let initialValue
      switch (type) {
        case 'select':
        case 'record':
        case 'status': {
          initialValue = []

          break
        }
        default: {
          initialValue = ''
        }
      }

      if (name) {
        acc[name] = {
          value: initialValue,
          valid: required !== 'true',
          type,
        }
      }

      return acc
    }, {})

    setFieldData(fields)
  }, [items])

  useEffect(() => {
    // filter out notifybody
    const notify = Object.entries(fieldData).filter(
      ([name, _]) => name === 'notifybody'
    )[0]

    if (notify) {
      notifyBody.current = notify[1].value
    }

    // check valid
    setFormValid(() =>
      Object.entries(fieldData).every(([_, entry]) => entry.valid)
    )
  }, [fieldData])

  useEffect(() => {
    if (formValid) setFormMessage(null)
  }, [formValid])

  const save = async () => {
    if (!formValid) {
      setFormMessage('Please complete required fields')

      return
    }

    try {
      onClose()
    } finally {
      setTimeout(() => {
        const changes = Object.entries(fieldData)
          .filter(([field, data]) => field !== 'notifybody')
          .map(([field, data]) => {
            const { value, type } = data

            if (Array.isArray(value)) {
              if (['select', 'status'].includes(type)) {
                return {
                  property: field,
                  method: 'set',
                  value: value.map(v => v.name),
                }
              }

              return {
                property: field,
                method: 'set',
                recordids: value.map(v => v.recordid),
              }
            }

            return {
              property: field,
              method: 'set',
              value: value ?? '',
            }
          })

        const request = {
          recordid,
          rclassname,
          actionname,
          notifybody: notifyBody.current,
        }

        if (changes.length) {
          if (method === 'add') {
            request['changes'] = [
              {
                position: `start`,
                property,
                method: 'add',
                changes,
              },
            ]
          } else {
            request['changes'] = changes
          }
        }

        record_action(request)
      }, 200)
    }
  }

  const scrollView = useAnimatedRef<Animated.ScrollView>()
  const scrollY = useSharedValue(0)

  const onScroll = useAnimatedScrollHandler({
    onScroll: ({ contentOffset: { y } }) => {
      scrollY.value = y
    },
  })

  const {
    typography: { Button, H3, H5, Body },
    themeColors: { bg },
  } = useContext(ThemeContext)

  if (validationmessage) {
    return (
      <DialogValidation
        validationmessage={validationmessage}
        onClose={onClose}
      />
    )
  }

  return (
    <View ref={modalRef} style={{ width: 650, flex: 1, padding: 20 }}>
      <AlignedRow style={{ marginBottom: 20 }}>
        <H3 primary style={{ flex: 1 }}>
          {name}
        </H3>

        <TouchableOpacity onPress={onClose}>
          <WorktribeIcon name="remove" />
        </TouchableOpacity>
      </AlignedRow>

      <Animated.ScrollView
        ref={scrollView}
        onScroll={onScroll}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexShrink: 1,
          marginHorizontal: 2,
        }}
      >
        {items?.map((dialogitem, itemIndex) => {
          const handleChange = useCallback(
            value => {
              if (dialogitem.type === 'notifybody') {
                setFieldData(data => ({
                  ...data,
                  ['notifybody']: {
                    value,
                    valid: true,
                  },
                }))
              } else {
                const { required, property } = dialogitem
                setFieldData(data => ({
                  ...data,
                  [property.name]: {
                    value: value,
                    valid: required === 'true' ? !!value : true,
                    type: property.type,
                  },
                }))
              }
            },
            [dialogitem.type]
          )

          let control
          switch (dialogitem.type) {
            case 'guide': {
              control = <Body primary>{dialogitem.text}</Body>
              break
            }
            case 'property': {
              if (dialogitem.property === null) {
                control = <Body primary>INVALID</Body>
                break
              }

              const {
                property: { type, heading, textabove, textbelow },
                value,
              } = dialogitem

              switch (type) {
                case 'title':
                case 'text':
                case 'currency':
                case 'code':
                case 'number': {
                  control = (
                    <InlineText
                      item={dialogitem}
                      value={value}
                      onChange={handleChange}
                      onBlur={() => {}}
                      inputBackgroundColor={bg.primary}
                      first={itemIndex === 0}
                      modal={true}
                      formValid={formMessage === null}
                    />
                  )
                  break
                }
                case 'record':
                case 'select': {
                  control = (
                    <InlineSelect
                      item={dialogitem}
                      value={{
                        value: value.records,
                      }}
                      onChange={handleChange}
                      modal={true}
                      optionBackgroundColor={bg.primary}
                      formValid={formMessage === null}
                      scrollView={scrollView}
                      scrollY={scrollY}
                    />
                  )
                  break
                }
                case 'switch': {
                  const [switchValue, setSwitchValue] = useState(!!value.value)

                  const updateSwitch = value => {
                    setSwitchValue(value === 'Yes')
                    handleChange(value)
                  }

                  control = (
                    <>
                      <H5 style={{ marginBottom: 4 }} primary>
                        {dialogitem.property.name}
                      </H5>
                      <InlineSwitch
                        value={switchValue}
                        onChange={updateSwitch}
                      />
                    </>
                  )
                  break
                }
                case 'date': {
                  control = (
                    <InlineDate
                      item={dialogitem}
                      value={value}
                      onChange={handleChange}
                      modal={true}
                      inputBackgroundColor={bg.primary}
                      formValid={formMessage === null}
                    />
                  )
                  break
                }
                default: {
                  control = null
                  break
                }
              }
              break
            }
            case 'notifybody': {
              control = (
                <InlineText
                  item={{
                    ...dialogitem,
                    property: {
                      name: 'notifybody',
                      size: 'medium',
                    },
                  }}
                  value={{ value: dialogitem.value }}
                  onChange={handleChange}
                  onBlur={() => {}}
                  inputBackgroundColor={bg.primary}
                  modal={true}
                />
              )
              break
            }
          }

          if (!control) return null

          if (dialogitem.type === 'property') {
            const { property, required } = dialogitem

            return (
              <InlinePropertyWrapper
                key={property?.name}
                heading={property?.heading}
                textabove={property?.textabove}
                textbelow={property?.textbelow}
                wrapperStyle={{ padding: 0, marginBottom: 16 }}
                index={itemIndex}
                required={required === 'true'}
              >
                {control}
              </InlinePropertyWrapper>
            )
          } else {
            return (
              <View
                key={property?.name}
                style={{ marginBottom: 16, zIndex: -itemIndex }}
              >
                {control}
              </View>
            )
          }
        })}
      </Animated.ScrollView>

      <AlignedRow
        justifyContent="space-between"
        style={{ marginTop: 10, marginBottom: 1, zIndex: -1 }}
      >
        <View style={{ flex: 1 }}>
          {formMessage && <Body color="#E08938">{formMessage}</Body>}
        </View>

        <AlignedRow gap={16}>
          <SmallButton onPress={onClose} transparent>
            <Button primary>Cancel</Button>
          </SmallButton>

          <SmallButton onPress={save} primary>
            <Button color="#FFF">{dialogbuttonlabel}</Button>
          </SmallButton>
        </AlignedRow>
      </AlignedRow>
    </View>
  )
}

const DialogValidation = ({ validationmessage, onClose }) => {
  const {
    typography: { Button, Subtitle },
  } = useContext(ThemeContext)

  return (
    <View style={{ width: 500, maxHeight: 715 }}>
      <Subtitle primary>{validationmessage}</Subtitle>

      <AlignedRow justifyContent="flex-end">
        <SmallButton onPress={onClose} transparent>
          <Button primary>OK!</Button>
        </SmallButton>
      </AlignedRow>
    </View>
  )
}

export default EditDialog
