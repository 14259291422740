import React from 'react'
import { Image, TouchableOpacity, View, StyleSheet } from 'react-native'
import { ActiveUserContext } from '../../contexts/ActiveUserContext'

const imgArr = [
  require('../../../assets/profiles/1.jpg'), //Derek di Giovanni
  require('../../../assets/profiles/2.jpg'), //Joe Hingston
  require('../../../assets/profiles/3.jpg'), //Martin Joiner
  require('../../../assets/profiles/4.jpg'), //Matt Eris
  require('../../../assets/profiles/5.jpg'), //Rich Jeffery
  require('../../../assets/profiles/6.jpg'), //Roland Treiber
  require('../../../assets/profiles/7.jpg'), //Sam Brown
  require('../../../assets/profiles/8.jpg'), //Tom Price
  require('../../../assets/profiles/9.jpg'), //Tester Rantzen
  require('../../../assets/profiles/10.jpg'), //Gloria Testerfan
  require('../../../assets/profiles/11.jpg'), //Fireman Sam
  require('../../../assets/profiles/default.png'), //??
  require('../../../assets/profiles/13.jpg'), //Ali
]

const Active = ({ size }) => <View style={[styles.active, { top: size - 8 }]} />

const AvatarGroup = ({
  userId,
  size = 42,
  group,
  radius,
  border,
  offset,
  onPress,
}) => {
  const [state, setState] = React.useContext(ActiveUserContext)
  const isActive = state?.active.includes(userId)

  const source = group
    ? require('../../../assets/profiles/team.png')
    : imgArr[userId - 1]
    ? imgArr[userId - 1]
    : require('../../../assets/profiles/default.png')

  return (
    <View>
      <View>
        <Image
          style={[
            offset && { marginLeft: -8 },
            border && { borderWidth: 2, borderColor: '#FFF' },
            { width: size, height: size },
            { borderRadius: radius ? radius : 24 },
          ]}
          source={imgArr[1]}
        />
        <View style={{ position: 'absolute', left: 14 }}>
          <Image
            style={[
              offset && { marginLeft: -8 },
              border && {
                borderWidth: 2,
                borderColor: '#FFF',
              },
              { width: size, height: size },
              { borderRadius: radius ? radius : 24 },
            ]}
            source={imgArr[2]}
          />
          <View style={{ position: 'absolute', left: 14 }}>
            <Image
              style={[
                offset && { marginLeft: -8 },
                border && {
                  borderWidth: 2,
                  borderColor: '#FFF',
                },
                { width: size, height: size },
                { borderRadius: radius ? radius : 24 },
              ]}
              source={imgArr[3]}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default AvatarGroup

const styles = StyleSheet.create({
  active: {
    position: 'absolute',
    borderRadius: 50,
    height: 10,
    width: 10,
    backgroundColor: '#5fdba7',
    right: 0,
  },
})
