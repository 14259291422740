import React, { useEffect, useRef, useState } from 'react'
import {
  Image,
  View,
  Animated,
  Easing,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import styled from 'styled-components/native'

const WeekDay = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-align: center;
  color: #757885;
`

const DayWrapper = styled.TouchableOpacity`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => (props.selected ? '#2AAD79' : 'transparent')}
  justify-content: center;
  align-items: center;
`

const Day = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  color: ${props => (props.selected ? '#FFFFFF' : '#0E1012')};
`

const Rows = styled.View`
  flex: 1;
  flex-direction: row;
  padding: 2px 4px;
  justify-content: space-around;
  align-items: center;
`

const CalendarHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
`

const CalendarField = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: ${props => (props.open ? '#2aad79' : '#757885')};
`

const CalendarValue = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  line-height: 24px;
  color: #0e1012;
`

const CalendarActions = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
`

const CalendarTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #0e1012;
  text-transform: uppercase;
  letter-spacing: 0.4px;
`

const CalendarBody = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-width: 2px;
  border-color: #2aad79;
`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

const Calendar = ({ field, dispatch }) => {
  const { name, required } = field

  const [activeDate, setActiveDate] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [calendarBodyHeight, setCalendarBodyHeight] = useState(null)
  const animatedController = useRef(new Animated.Value(0)).current

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { field: name, value: activeDate.toDateString() },
    })
  }, [activeDate])

  const toggleCalendar = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start()
    }
    setOpen(!open)
  }

  const generateMatrix = () => {
    let matrix = []

    matrix[0] = weekDays

    const year = activeDate.getFullYear()
    const month = activeDate.getMonth()

    const firstDay = new Date(year, month, 1).getDay()

    let maxDays = nDays[month]
    if (month === 1) {
      // February
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1
      }
    }

    let counter = 1
    for (let row = 1; row < 7; row++) {
      matrix[row] = []
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = -1
        if (row == 1 && col >= firstDay) {
          matrix[row][col] = counter++
        } else if (row > 1 && counter <= maxDays) {
          matrix[row][col] = counter++
        }
      }
    }

    return matrix
  }

  const _onSelect = item => {
    if (!item.match && item !== -1) {
      setActiveDate(() => new Date(activeDate.setDate(item)))
    }
  }

  const changeMonth = n => {
    setActiveDate(() => {
      activeDate.setMonth(activeDate.getMonth() + n)
      return new Date(activeDate)
    })
  }

  const rows = generateMatrix().map((row, rowIndex) => {
    const rowItems = row.map((item, colIndex) => {
      if (weekDays.includes(item)) {
        return (
          <WeekDay key={`${rowIndex}-${colIndex}`}>
            {item !== -1 ? item.substr(0, 2) : ''}
          </WeekDay>
        )
      }

      return (
        <DayWrapper
          key={`${rowIndex}-${colIndex}`}
          selected={item === activeDate.getDate()}
          onPress={() => _onSelect(item)}
        >
          {item !== -1 ? (
            <Day selected={item === activeDate.getDate()}>{item}</Day>
          ) : null}
        </DayWrapper>
      )
    })

    return <Rows key={rowIndex}>{rowItems}</Rows>
  })

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, calendarBodyHeight],
  })

  return (
    <>
      <TouchableOpacity onPress={toggleCalendar}>
        <CalendarHeader>
          <View>
            <CalendarField open={open}>{`${name} ${
              required ? '*' : ''
            }`}</CalendarField>
            <CalendarValue>{activeDate.toDateString()}</CalendarValue>
          </View>
          <Image
            style={{ height: 20, width: 20 }}
            source={
              open
                ? require('../../../assets/icons/calendar-open.png')
                : require('../../../assets/icons/calendar-closed.png')
            }
          />
        </CalendarHeader>
      </TouchableOpacity>

      <Animated.View style={{ overflow: 'hidden', height: bodyHeight }}>
        <CalendarBody
          style={Platform.OS === 'web' && { minHeight: 350 }}
          onLayout={event =>
            setCalendarBodyHeight(event.nativeEvent.layout.height)
          }
        >
          <CalendarActions>
            <MaterialCommunityIcons
              name="chevron-left"
              size={35}
              color="#A0A4B8"
              onPress={() => changeMonth(-1)}
            />
            <CalendarTitle>
              {`${months[activeDate.getMonth()]} ${activeDate.getFullYear()}`}
            </CalendarTitle>
            <MaterialCommunityIcons
              name="chevron-right"
              size={35}
              color="#A0A4B8"
              onPress={() => changeMonth(+1)}
            />
          </CalendarActions>
          {rows}
        </CalendarBody>
      </Animated.View>
    </>
  )
}

export default Calendar
