import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  TextInput,
  View,
} from 'react-native'
import styled from 'styled-components/native'
import { AlignedRow, CenteredView } from '../../components/shared/Layout'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import uuid from 'uuid/v4'
import { SmallButton } from '../../components/shared/Button'
import { Portal } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'
import HoverableOpacity from '../../components/shared/HoverableOpacity'

import Selection from '../../components/landscape/Selection'
import useProperties from '../../hooks/useProperties'

import useSocketMethods from '../../hooks/useSocketMethods'
import InlineSelect from '../../components/landscape/controls/InlineSelect'
import { useNavigation } from '@react-navigation/native'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'

import InformationDialog from '../../components/landscape/InformationDialog'
import Ellipsis from '../../components/shared/Ellipsis'

import {
  DraxProvider,
  DraxView,
  DraxSnapbackTargetPreset,
  DraxScrollView,
} from 'react-native-drax'

const Container = styled.View`
  flex: 1;
`

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 40px;
  margin-bottom: 20px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  padding: 14px;
  border-radius: 16px;
  min-width: 748px;
`

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.12)',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 50,
  elevation: 24,
}

interface BuilderScreenProps {}

interface ModuleSearch {
  id: string
  title: string
  description: string
  credits: number
}

const StructureOptions = ({
  parentid,
  parentclass,
  addProperty,
  properties,
  setShowDialog,
}) => {
  const {
    typography: { Button },
  } = useContext(ThemeContext)

  return (
    <View style={{ width: '100%' }}>
      {properties?.map(({ label, property }) => (
        <GroupAddButton
          key={property}
          onPress={() => {
            if (label === 'Module') {
              setShowDialog({
                parentid,
                parentclass,
                primaryProperty: 'Modules',
                secondaryProperty: label,
              })
            } else if (label === 'Search') {
              setShowDialog({
                parentid,
                parentclass,
                primaryProperty: 'Selections',
                secondaryProperty: 'Module Selection',
              })
            } else {
              addProperty(property, parentid, parentclass, 'Edit')
            }
          }}
        >
          <AlignedRow>
            <WorktribeIcon color="#1D7A55" name="add-circle" />
            <Button color="#1D7A55" style={{ marginLeft: 6 }}>
              {label}
            </Button>
          </AlignedRow>
        </GroupAddButton>
      ))}
    </View>
  )
}

const Operator = ({ id, rclass, operator }) => {
  const [isHovering, setIsHovering] = useState(false)

  let readableOperator = operator?.[0].match(/\(([^)]+)\)/)?.[1]
  const nextOperator = readableOperator === 'OR' ? 'AND' : 'OR'

  const { record_action } = useSocketMethods()

  const toggleOperator = () => {
    record_action({
      recordid: id,
      rclassname: rclass,
      actionname: `Edit`,
      changes: [
        {
          property: 'Link Type',
          method: 'set',
          value: nextOperator === 'OR' ? ['Any (OR)'] : ['All (AND)'],
        },
      ],
    })
  }

  const {
    typography: { H4, Label },
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: isHovering ? 99 : -1,
      }}
    >
      <Pressable
        onPress={toggleOperator}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={[
          {
            paddingVertical: 4,
            paddingHorizontal: 8,
            borderRadius: 16,
            marginVertical: 16,
            alignItems: 'center',
            justifyContent: 'center',
          },
          isHovering && { backgroundColor: '#F4F5F9' },
        ]}
      >
        <H4 selectable={false}>{readableOperator}</H4>

        {isHovering && (
          <View
            style={{
              position: 'absolute',
              bottom: -30,
              paddingVertical: 4,
              paddingHorizontal: 10,
              backgroundColor: '#1E253B',
              borderRadius: 30,
              minWidth: 200,
            }}
          >
            <Label color="#FFF">{`Click to change to ${nextOperator} group`}</Label>
          </View>
        )}
      </Pressable>
    </View>
  )
}

const Loading = () => (
  <CenteredView>
    <ActivityIndicator size="large" />
  </CenteredView>
)

const builderMap = {
  'Programme Stage': {
    property: 'Stages',
    label: 'stage',
    parent: 'Programme',
    Stage: {
      rclass: 'Programme Stage',
      properties: [
        'Title',
        'Description',
        'Credit Rule',
        'Minimum Credits',
        'Maximum Credits',
        'Exact Credits',
        'Groups',
        'Modules',
        'Selections',
        'Link Type',
        'Stacking',
      ],
      children: [
        {
          label: 'Group',
          property: 'Groups',
        },
        {
          label: 'Module',
          property: 'Modules',
        },
        {
          label: 'Selection',
          property: 'Selections',
        },
      ],
      title: true,
      credits: true,
    },
    Group: {
      rclass: 'Programme Stage Group',
      properties: [
        'Title',
        'Description',
        'Credit Rule',
        'Minimum Credits',
        'Maximum Credits',
        'Exact Credits',
        'Subgroups',
        'Modules',
        'Selections',
        'Link Type',
        'Stacking',
      ],
      children: [
        {
          label: 'Subgroup',
          property: 'Subgroups',
        },
        {
          label: 'Module',
          property: 'Modules',
        },
        {
          label: 'Selection',
          property: 'Selections',
        },
      ],
      title: true,
      credits: true,
    },
    Subgroup: {
      rclass: 'Programme Stage Subgroup',
      properties: [
        'Title',
        'Description',
        'Credit Rule',
        'Minimum Credits',
        'Maximum Credits',
        'Exact Credits',
        'Modules',
        'Selections',
        'Link Type',
        'Stacking',
      ],
      children: [
        {
          label: 'Module',
          property: 'Modules',
        },
        {
          label: 'Selection',
          property: 'Selections',
        },
      ],
      title: true,
      credits: true,
    },
  },
  'Module Requirement': {
    property: 'Requirements',
    label: 'requirement',
    parent: 'Module',
    Stage: {
      rclass: 'Module Requirement',
      properties: [
        'Title',
        'Description',
        'Type',
        'Link Type',
        'Groups',
        'Modules',
        'Stacking',
      ],
      children: [
        {
          label: 'Group',
          property: 'Groups',
        },
        {
          label: 'Module',
          property: 'Modules',
        },
      ],
      title: false,
      credits: false,
    },
    Group: {
      rclass: 'Module Requirement Group',
      properties: ['Title', 'Description', 'Link Type', 'Subgroups', 'Modules'],
      children: [
        {
          label: 'Subgroup',
          property: 'Subgroups',
        },
        {
          label: 'Module',
          property: 'Modules',
        },
      ],
      title: false,
      credits: false,
    },
    Subgroup: {
      rclass: 'Module Requirement Subgroup',
      properties: ['Title', 'Description', 'Link Type', 'Modules'],
      children: [
        {
          label: 'Module',
          property: 'Modules',
        },
      ],
      title: false,
      credits: false,
    },
  },
}

export const ProgrammeBuilder: React.FC<BuilderScreenProps> = ({
  index,
  parentRecordId,
  parentrClass,
  propertyName,
  editAction,
  addAction,
  filters,
  rclass,
  value,
  selectedTab,
}) => {
  const { records } = value

  const { record_action } = useSocketMethods()

  const [dialogProps, setDialogProps] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [showDialog, setShowDialog] = useState(null)
  const [showCredits, setShowCredits] = useState<string | number>(0)

  const saveItems = () => {
    const { parentid, parentclass, primaryProperty, secondaryProperty } =
      showDialog

    selectedItems.forEach(selected => {
      record_action({
        recordid: parentid,
        rclassname: parentclass,
        actionname: `Edit`,
        changes: [
          {
            property: primaryProperty,
            method: `add`,
            position: `end`,
            changes: [
              {
                property: secondaryProperty,
                method: `set`,
                recordids: [selected.recordid],
              },
            ],
          },
        ],
      })
    })

    setSelectedItems([])
    setShowDialog(null)
  }

  const addProperty = (property, recordid, rclassname, actionname) => {
    record_action({
      recordid,
      rclassname,
      actionname,
      changes: [
        {
          property,
          method: 'add',
          position: `end`,
          changereference: property,
        },
      ],
    })
  }

  const deleteProperty = (
    parentid: string,
    parentclass: string,
    property: string,
    ids: string[]
  ) => {
    setDialogProps({
      message: `Are you sure you want to delete this?`,
      actions: [
        {
          name: 'Confirm',
          primary: true,
          action: () => {
            record_action({
              recordid: parentid,
              rclassname: parentclass,
              actionname: 'Edit',
              changes: [
                {
                  property,
                  method: 'delete',
                  recordids: ids,
                },
              ],
            })

            setDialogProps(null)
          },
        },
        {
          name: 'Cancel',
          primary: false,
          action: () => setDialogProps(null),
        },
      ],
    })
  }

  const {
    typography: { PropertyName, Button, H4, Body },
    themeColors: { bg, hover },
  } = useContext(ThemeContext)

  const { property, label } = builderMap[rclass]

  if (!records) {
    return <Loading />
  }

  const [showViewSwitcher, setShowViewSwitcher] = useState(false)
  const [selectView, setSelectView] = useState('builder')
  const [showAddEvent, setShowAddEvent] = useState(false)

  const showViewOptions = [
    {
      name: 'Builder View',
      icon: 'layout-content',
      color: '#757885',
      action: () => setSelectView('builder'),
    },
    {
      name: 'List View',
      icon: 'task-list-edit',
      color: '#757885',
      action: () => {
        setShowAddEvent(false)
        setSelectView('list')
      },
    },
  ]

  const handleAddEvent = () => {
    if (selectView === 'list') {
      setShowAddEvent(!showAddEvent)
    } else {
      setSelectView('list')
      setShowAddEvent(true)
    }
  }

  useEffect(() => {
    // Switch views
    // If you enable year, month, day and list, make sure to add all the options here!
    switch (selectView) {
      case 'builder':
        setSelectedViewOption(showViewOptions[0])
        break
      case 'list':
        setSelectedViewOption(showViewOptions[1])
        break
    }
    setShowViewSwitcher(false)
  }, [selectView])

  const [selectedViewOption, setSelectedViewOption] = useState(
    showViewOptions[0]
  )

  const outsideHandler = useCallback(() => setShowViewSwitcher(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <Container style={{ zIndex: 1 }}>
      <BubbleRow style={{ flex: 1 }}>
        <Bubble style={[{ backgroundColor: bg.card }]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: 0,
              zIndex: 100,
              margin: 10,
            }}
          >
            <PropertyName primary>{propertyName}</PropertyName>
            <AlignedRow>
              <SmallButton
                transparentHighlight
                secondary
                style={{ marginLeft: 16, maxHeight: 38 }}
                onPress={() => {
                  setShowViewSwitcher(!showViewSwitcher)
                }}
              >
                <WorktribeIcon
                  size={16}
                  color="#1D7A55"
                  name={selectedViewOption.icon}
                />
                <Button style={{ marginLeft: 8 }} color="#1D7A55">
                  {selectedViewOption.name}
                </Button>
                <WorktribeIcon name="chevron-down" />
              </SmallButton>
              {showViewSwitcher && (
                <View
                  ref={wrapperRef}
                  style={[
                    {
                      position: 'absolute',
                      top: 40,
                      left: 15,
                      backgroundColor: bg.primary,
                      borderRadius: 16,
                      paddingVertical: 8,
                      maxHeight: 345,
                      width: 160,
                    },
                    shadowStyles,
                  ]}
                >
                  <ScrollView style={{ flex: 1 }}>
                    {showViewOptions.map(({ name, icon, color, action }) => (
                      <HoverableOpacity
                        key={name}
                        onPress={action}
                        style={{
                          justifyContent: 'center',
                          minHeight: 46,
                          paddingHorizontal: 16,
                          paddingVertical: 11,
                        }}
                        hoverStyle={{ backgroundColor: hover }}
                      >
                        <AlignedRow>
                          <WorktribeIcon size={16} name={icon} color={color} />
                          <Body style={{ marginLeft: 12 }} primary>
                            {name}
                          </Body>
                        </AlignedRow>
                      </HoverableOpacity>
                    ))}
                  </ScrollView>
                </View>
              )}
              {!!addAction && (
                <>
                  <SmallButton
                    transparentHighlight
                    style={{ marginLeft: 12 }}
                    secondary
                    onPress={() => handleAddEvent()}
                  >
                    <Button color="#1D7A55">{addAction?.buttonlabel}</Button>
                  </SmallButton>
                </>
              )}
            </AlignedRow>
          </View>

          {selectView !== 'builder' ? (
            <Selection
              index={index}
              parentRecordId={parentRecordId}
              parentrClass={parentrClass}
              propertyName={propertyName}
              editAction={editAction}
              addAction={addAction}
              filters={filters}
              rclass={rclass}
              value={value}
              selectedTab={selectedTab}
              showBubble={false}
              showFiltersIfAvailable={true}
              showAddEvent={showAddEvent}
            />
          ) : (
            <DraxProvider>
              <ScrollView
                style={{
                  padding: 16,
                  backgroundColor: bg.secondary,
                  borderRadius: 16,
                }}
              >
                {records.map(item => {
                  return (
                    <Stage
                      key={item.recordid}
                      parentid={parentRecordId}
                      item={item}
                      showCredits={showCredits}
                      setShowCredits={setShowCredits}
                      setShowDialog={setShowDialog}
                      addProperty={addProperty}
                      deleteProperty={deleteProperty}
                      map={builderMap[rclass]}
                    />
                  )
                })}

                <Pressable
                  onPress={() => {
                    addProperty(property, parentRecordId, parentrClass, 'Edit')
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: '#DDE1ED',
                    borderStyle: 'dashed',
                    borderRadius: 20,
                    marginTop: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: 37,
                    backgroundColor: bg.primary,
                  }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#A0A4B8" name="add-circle" />
                    <Button
                      style={{ marginLeft: 6 }}
                    >{`Add a new ${label}`}</Button>
                  </AlignedRow>
                </Pressable>
              </ScrollView>

              <Portal>
                <WorktribeAlert
                  useNativeDriver={true}
                  contentContainerStyle={{
                    backgroundColor: bg.card,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 20,
                    shadowColor: 'rgba(0, 0, 0, 0.06)',
                    shadowOffset: { width: 0, height: 4 },
                    shadowOpacity: 0,
                    shadowRadius: 32,
                    minHeight: 615,
                  }}
                  overlayStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                  alertContainerStyle={{ padding: 0 }}
                  show={!!showDialog}
                  onDismiss={() => setShowDialog(null)}
                  customView={
                    <View style={{ width: 500 }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <H4 onPress primary>
                          Add a {showDialog?.secondaryProperty}
                        </H4>
                        <Pressable onPress={() => setShowDialog(null)}>
                          <WorktribeIcon name="remove" />
                        </Pressable>
                      </View>

                      <View style={{ marginVertical: 24 }}>
                        <InlineSelect
                          item={{
                            property: {
                              type: 'record',
                              renderer: 'checkboxsearchlist',
                              name: showDialog?.primaryProperty,
                              rclasses: showDialog?.secondaryProperty,
                              multiple: true,
                            },
                          }}
                          value={{
                            value: [],
                          }}
                          onChange={setSelectedItems}
                          modal
                          optionBackgroundColor={bg.primary}
                        />
                      </View>

                      <AlignedRow justifyContent="flex-end" gap={16}>
                        <SmallButton
                          transparent
                          onPress={() => setShowDialog(null)}
                        >
                          <Button color="#1D7A55">Cancel</Button>
                        </SmallButton>

                        <SmallButton onPress={saveItems} primary>
                          <Button color="#FFF">Save</Button>
                        </SmallButton>
                      </AlignedRow>
                    </View>
                  }
                />
              </Portal>
            </DraxProvider>
          )}

          <InformationDialog
            dialogProps={dialogProps}
            setDialogProps={setDialogProps}
          />
        </Bubble>
      </BubbleRow>
    </Container>
  )
}

const StageHeader = ({ stageId, stage, rclass, label }) => {
  const [title, onChangeTitle] = useState(stage['Title'])
  const existingTitle = useRef(title)

  const [description, onChangeDescription] = useState(stage['Description'])
  const [descriptionHeight, setDescriptionHeight] = useState(null)
  const existingDescription = useRef(description)

  const [titleHovered, setTitleHovered] = useState(false)
  const [fieldEdit, setFieldEdit] = useState('')

  const titleSelection = useMemo(
    () => ({
      start: title.length,
    }),
    [stageId]
  )

  const descriptionSelection = useMemo(
    () => ({
      start: description.length,
    }),
    [stageId]
  )

  const editField = field =>
    setFieldEdit(current => (current === field ? '' : field))

  useEffect(() => {
    if (description.length === 0) {
      setDescriptionHeight(null)
    }
  }, [description])

  const { record_action } = useSocketMethods()

  const updateTitle = () => {
    try {
      record_action({
        recordid: stageId,
        rclassname: rclass,
        actionname: 'Edit',
        changes: [
          {
            property: 'Title',
            method: 'set',
            value: title,
          },
        ],
      })
    } finally {
      existingDescription.current = title
      setDescriptionHeight(null)
      editField('')
    }
  }

  const updateDescription = () => {
    try {
      record_action({
        recordid: stageId,
        rclassname: rclass,
        actionname: 'Edit',
        changes: [
          {
            property: 'Description',
            method: 'set',
            value: description,
          },
        ],
      })
    } finally {
      existingTitle.current = title
      editField('')
    }
  }

  const {
    typography: { Body, H3 },
    themeColors: { bg, accents, fonts },
  } = useContext(ThemeContext)

  return (
    <>
      <View
        style={{
          alignSelf: 'center',
          marginTop: -100,
          position: 'absolute',
          alignItems: 'center',
        }}
      >
        <View
          onMouseEnter={() => setTitleHovered(true)}
          onMouseLeave={() => setTitleHovered(false)}
          style={{
            paddingVertical: 10,
            paddingHorizontal: 54,
            borderRadius: 40,
            borderWidth: 2,
            borderColor: accents.separator,
            backgroundColor: bg.primary,
            marginBottom: 8,
          }}
        >
          {fieldEdit === 'title' ? (
            <AlignedRow gap={8}>
              <TextInput
                selection={titleSelection}
                style={{
                  flex: 1,
                  fontFamily: 'proxima-nova-bold',
                  fontSize: 20,
                  outline: 'none',
                  padding: 4,
                  borderRadius: 8,
                  backgroundColor: bg.primary,
                  color: fonts.primary,
                }}
                onChangeText={onChangeTitle}
                value={title}
                autoFocus={true}
              />

              <Pressable onPress={updateTitle}>
                <WorktribeIcon
                  size={24}
                  color="#2AAD79"
                  name="check-circle-2"
                />
              </Pressable>

              <Pressable
                onPress={() => {
                  onChangeTitle(existingTitle.current)
                  editField('')
                }}
              >
                <WorktribeIcon color="#757885" size={24} name="remove-circle" />
              </Pressable>
            </AlignedRow>
          ) : (
            <Pressable onPress={() => editField('title')}>
              <AlignedRow gap={15}>
                <H3 color="#1D7A55">
                  {title.length ? title : `Add a ${label} title`}
                </H3>

                {titleHovered && (
                  <WorktribeIcon size={20} color="#676C85" name="pencil-1" />
                )}
              </AlignedRow>
            </Pressable>
          )}
        </View>
      </View>

      <View style={{ alignSelf: 'center', marginTop: -45 }}>
        {fieldEdit === 'description' ? (
          <AlignedRow style={{ alignItems: 'flex-start' }} gap={8}>
            <TextInput
              selection={descriptionSelection}
              onContentSizeChange={event => {
                const { contentSize } = event.nativeEvent
                setDescriptionHeight(contentSize.height)
              }}
              multiline={true}
              style={{
                flex: 1,
                fontFamily: 'proxima-nova',
                fontSize: 15,
                outline: 'none',
                padding: 4,
                borderRadius: 8,
                color: fonts.primary,
                backgroundColor: bg.card,
                height: descriptionHeight ? descriptionHeight : 'auto',
                maxHeight: 60,
                width: 350,
              }}
              onChangeText={onChangeDescription}
              value={description}
              autoFocus={true}
            />

            <Pressable onPress={updateDescription}>
              <WorktribeIcon size={24} color="#2AAD79" name="check-circle-2" />
            </Pressable>

            <Pressable
              onPress={() => {
                onChangeDescription(existingDescription.current)
                setDescriptionHeight(null)
                editField('')
              }}
            >
              <WorktribeIcon color="#757885" size={24} name="remove-circle" />
            </Pressable>
          </AlignedRow>
        ) : (
          <Pressable onPress={() => editField('description')}>
            <Body>
              {description.length ? description : `Add a description`}
            </Body>
          </Pressable>
        )}
      </View>
    </>
  )
}

const useGroupDirection = (properties, recordid, rclassname) => {
  const [horizontal, setHorizontal] = useState(true)

  const { record_action } = useSocketMethods()

  useEffect(() => {
    if (!properties) return

    const { Stacking } = properties

    if (Stacking?.length) {
      let direction = Stacking.shift()
      setHorizontal(direction === 'Horizontal')
    }
  }, [properties])

  const toggleDirection = useCallback(() => {
    try {
      record_action({
        recordid,
        rclassname,
        actionname: 'Edit',
        changes: [
          {
            property: 'Stacking',
            method: 'set',
            value: horizontal ? ['Vertical'] : ['Horizontal'],
          },
        ],
      })
    } catch (e) {
      console.warn(e)
    } finally {
      setHorizontal(horizontal => !horizontal)
    }
  }, [recordid, rclassname, horizontal])

  return { horizontal, toggleDirection }
}

const Stage = ({
  parentid,
  item,
  showCredits,
  setShowCredits,
  setShowDialog,
  addProperty,
  deleteProperty,
  map,
}) => {
  const {
    parent,
    property,
    label,
    Stage: { rclass, children, properties: props, credits },
  } = map

  const [isHovered, setIsHovered] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const properties = useProperties(item, rclass, props)

  const { horizontal, toggleDirection } = useGroupDirection(
    properties,
    item.recordid,
    rclass
  )

  const { items: childItems, setItems } = useChildItems(
    properties,
    children.map(child => child.property)
  )

  const deleteStage = () => {
    deleteProperty(parentid, parent, property, [item.recordid])
  }

  const {
    typography: { Button, Overline, Body, Subtitle },
    themeColors: { accents, bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  const outsideHandler = useCallback(() => setShowOptions(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  if (!properties || !childItems) {
    return <Loading />
  }

  return (
    <View
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: 'relative',
        borderWidth: 2,
        borderColor: accents.separator,
        padding: 24,
        paddingTop: 75,
        borderRadius: 20,
        marginTop: 20,
        position: 'relative',
        paddingBottom: childItems.length ? 55 : 0,
        marginBottom: 30,
        backgroundColor: bg.primary,
      }}
    >
      <StageHeader
        stageId={item.recordid}
        stage={properties}
        rclass={rclass}
        label={label}
      />

      {credits && (
        <Pressable
          onPress={() => {
            setShowCredits(current =>
              current === item.recordid ? 0 : item.recordid
            )
          }}
          style={{
            alignSelf: 'flex-end',
            marginTop: -95,
            position: 'absolute',
            alignItems: 'center',
            zIndex: 99,
          }}
        >
          <View
            style={{
              position: 'relative',
              paddingVertical: 7,
              paddingHorizontal: 12,
              borderRadius: 40,
              borderWidth: 2,
              borderColor: accents.separator,
              backgroundColor: bg.primary,
            }}
          >
            <Overline color="#1D7A55">
              {properties['Credit Rule']
                ? properties['Credit Rule'][0] === 'Between'
                  ? `Between ${properties['Minimum Credits']} and ${properties['Maximum Credits']} credits`
                  : properties['Credit Rule'][0] !== 'Exact'
                  ? `${properties['Credit Rule'][0]} ${properties['Minimum Credits']} credits`
                  : `${properties['Exact Credits']} credits`
                : 'None'}
            </Overline>

            {item.recordid === showCredits && (
              <CreditSelect
                parentid={item.recordid}
                parentclass={rclass}
                properties={properties}
                onClose={() => setShowCredits(0)}
              />
            )}
          </View>
        </Pressable>
      )}

      <View
        ref={wrapperRef}
        style={{ position: 'absolute', top: -20, right: -15, zIndex: 99 }}
      >
        <Pressable
          onPress={() => setShowOptions(show => !show)}
          style={[
            {
              height: 36,
              width: 36,
              borderRadius: 18,
              backgroundColor: showOptions ? '#F2FCF8' : bg.primary,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 6,
              borderWidth: 2,
              borderColor: accents.separator,
            },
            showOptions && { borderWidth: 1, borderColor: '#1D7A55' },
            // shadowStyles,
          ]}
        >
          <Ellipsis color="#1D7A55" />
        </Pressable>

        {showOptions && (
          <View
            style={[
              {
                position: 'absolute',
                top: 45,
                right: 0,
                backgroundColor: bg.primary,
                borderRadius: 16,
                paddingVertical: 8,
                maxHeight: 345,
                width: 241,
              },
              shadowStyles,
            ]}
          >
            <ScrollView style={{ flex: 1 }}>
              <HoverableOpacity
                onPress={() => {
                  toggleDirection()
                  setShowOptions(false)
                }}
                style={{
                  justifyContent: 'center',
                  minHeight: 46,
                  paddingHorizontal: 16,
                  paddingVertical: 11,
                }}
                hoverStyle={{ backgroundColor: hover }}
              >
                <AlignedRow>
                  <View
                    style={{
                      transform: [{ rotate: horizontal ? '-90deg' : '180deg' }],
                    }}
                  >
                    <WorktribeIcon
                      color="#2AAD79"
                      name="keyboard-arrow-right"
                    />
                  </View>

                  <Body style={{ marginLeft: 12 }} primary>
                    {`Display ${horizontal ? 'stacked' : 'in a row'}`}
                  </Body>
                </AlignedRow>
              </HoverableOpacity>

              <HoverableOpacity
                onPress={() => {
                  deleteStage()
                  setShowOptions(false)
                }}
                style={{
                  justifyContent: 'center',
                  minHeight: 46,
                  paddingHorizontal: 16,
                  paddingVertical: 11,
                }}
                hoverStyle={{ backgroundColor: hover }}
              >
                <AlignedRow>
                  <WorktribeIcon color="#D13E3E" name="bin" />
                  <Body style={{ marginLeft: 12 }} primary>
                    Remove
                  </Body>
                </AlignedRow>
              </HoverableOpacity>
            </ScrollView>
          </View>
        )}
      </View>

      {collapsed ? (
        <CenteredView style={{ height: 50 }}>
          <Pressable onPress={() => setCollapsed(false)}>
            <Body>{`${childItems.length} hidden ${
              childItems.length > 1 ? 'items' : 'item'
            }`}</Body>
          </Pressable>
        </CenteredView>
      ) : (
        <>
          {childItems.length > 0 && (
            <View
              style={{
                padding: 24,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                flexDirection: horizontal ? 'row' : 'column',
                flexWrap: 'wrap',
              }}
            >
              {childItems
                .map((child, index) => {
                  switch (child.type) {
                    case 'Groups': {
                      return (
                        <StageGroup
                          key={`${child?.recordid}_${parentid}_${index}_${
                            horizontal ? 'horizontal' : 'vertical'
                          }`}
                          parentid={item.recordid}
                          parentclass={rclass}
                          item={child}
                          addProperty={addProperty}
                          deleteProperty={deleteProperty}
                          setShowDialog={setShowDialog}
                          map={map}
                        />
                      )
                    }
                    case 'Modules': {
                      return (
                        <ModuleCard
                          key={child.recordid}
                          parentid={item.recordid}
                          parentclass={rclass}
                          deleteProperty={deleteProperty}
                          item={child}
                        />
                      )
                    }
                    case 'Selections': {
                      return (
                        <ModuleSearch
                          key={child.recordid}
                          parentid={item.recordid}
                          parentclass={rclass}
                          deleteProperty={deleteProperty}
                          item={child}
                        />
                      )
                    }
                    default: {
                      return null
                    }
                  }
                })
                .reduce((prev, curr) => [
                  prev,
                  <Operator
                    key={uuid()}
                    id={item.recordid}
                    rclass={rclass}
                    operator={properties['Link Type']}
                  />,
                  curr,
                ])}
            </View>
          )}

          {childItems.length ? (
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
            >
              <AlignedRow gap={8}>
                <GroupAddButton
                  onPress={() => {
                    addProperty('Groups', item.recordid, rclass, 'Edit')
                  }}
                  style={{ paddingHorizontal: 16 }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#1D7A55" name="add-circle" />
                    <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                      Group
                    </Button>
                  </AlignedRow>
                </GroupAddButton>

                <GroupAddButton
                  onPress={() => {
                    setShowDialog({
                      parentid: item.recordid,
                      parentclass: rclass,
                      primaryProperty: 'Modules',
                      secondaryProperty: 'Module',
                    })
                  }}
                  style={{ paddingHorizontal: 16 }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#1D7A55" name="add-circle" />
                    <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                      Module
                    </Button>
                  </AlignedRow>
                </GroupAddButton>

                {rclass === 'Programme Stage' && (
                  <GroupAddButton
                    onPress={() => {
                      setShowDialog({
                        parentid: item.recordid,
                        parentclass: rclass,
                        primaryProperty: 'Selections',
                        secondaryProperty: 'Module Selection',
                      })
                    }}
                    style={{ paddingHorizontal: 16 }}
                  >
                    <AlignedRow>
                      <WorktribeIcon color="#1D7A55" name="add-circle" />
                      <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                        Selection
                      </Button>
                    </AlignedRow>
                  </GroupAddButton>
                )}
              </AlignedRow>
            </View>
          ) : (
            <StructureOptions
              parentid={item.recordid}
              parentclass={rclass}
              setShowDialog={setShowDialog}
              properties={children}
              addProperty={addProperty}
            />
          )}
        </>
      )}

      {isHovered && (
        <Rotate collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
    </View>
  )
}

const useChildItems = (properties, array) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!properties) return

    let result = Object.entries(properties).reduce(
      (acc, [property, values]) => {
        if (array.includes(property)) {
          acc[property] = [
            ...values.map(value => {
              return {
                ...value,
                type: property,
              }
            }),
          ]
        }

        return acc
      },
      []
    )

    const sortedItems = Object.entries(result).reduce((acc, [_, array]) => {
      return [...acc, ...array].sort(
        (a, b) => parseFloat(a.recordid) - parseFloat(b.recordid)
      )
    }, [])

    setItems(sortedItems)
  }, [properties])

  return { items, setItems }
}

const StageGroup = ({
  parentid,
  parentclass,
  item,
  addProperty,
  deleteProperty,
  setShowDialog,
  map,
}) => {
  const {
    Group: { rclass, children, properties: props, credits },
  } = map

  const [isHovered, setIsHovered] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const [hasDroppable, setHasDroppable] = useState(false)

  const properties = useProperties(item, rclass, props)
  const { record_action } = useSocketMethods()

  const { horizontal, toggleDirection } = useGroupDirection(
    properties,
    item.recordid,
    rclass
  )

  const { items: childItems, setItems } = useChildItems(
    properties,
    children.map(child => child.property)
  )

  const deleteStageGroup = () => {
    deleteProperty(parentid, parentclass, 'Groups', [item.recordid])
  }

  const handleChildDrop = id =>
    setItems(items => items.filter(item => item.recordid !== id))

  const {
    typography: { Button, Body },
    themeColors: { accents },
  } = useContext(ThemeContext)

  if (!childItems || !properties) {
    return <Loading />
  }

  return (
    <DraxView
      onReceiveDragEnter={({ dragged: { payload } }) => {
        if (payload.parentid !== item.recordid) {
          setHasDroppable(true)
        }
      }}
      onReceiveDragExit={({ dragged: { payload } }) => {
        if (payload.parentid !== item.recordid) {
          setHasDroppable(false)
        }
      }}
      onReceiveDragDrop={({ dragged: { payload } }) => {
        if (payload.parentid !== item.recordid) {
          try {
            payload.handleDrop(payload.recordid)
            setItems(items => [
              ...items,
              {
                ...payload,
                pending: true,
              },
            ])
          } finally {
            record_action({
              recordid: payload.recordid,
              rclassname: payload.rclass,
              actionname: 'Drag and Drop Update',
              changes: [
                {
                  property: 'Programme Stage Group',
                  method: 'set',
                  recordids: [item.recordid],
                },
              ],
            })

            setHasDroppable(false)
          }

          return DraxSnapbackTargetPreset.None
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[
        {
          position: 'relative',
          borderWidth: 1,
          borderColor: accents.separator,
          borderRadius: 20,
          margin: 12,
          minWidth: 780,
          maxWidth: '100%',
        },
      ]}
    >
      <StageGroupHeader
        groupId={item.recordid}
        type={rclass}
        onDelete={deleteStageGroup}
        onDirectionChange={toggleDirection}
        group={properties}
        credits={credits}
        horizontal={horizontal}
      />

      {collapsed ? (
        <CenteredView style={{ height: 50 }}>
          <Pressable onPress={() => setCollapsed(false)}>
            <Body>{`${childItems.length} hidden ${
              childItems.length > 1 ? 'items' : 'item'
            }`}</Body>
          </Pressable>
        </CenteredView>
      ) : (
        <>
          {childItems.length > 0 && (
            <View
              style={{
                padding: 24,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                flexDirection: horizontal ? 'row' : 'column',
                flexWrap: 'wrap',
              }}
            >
              {childItems
                .map((child, index) => {
                  switch (child.type) {
                    case 'Subgroups': {
                      return (
                        <StageSubgroup
                          key={child.recordid}
                          parentid={item.recordid}
                          parentclass={rclass}
                          item={child}
                          addProperty={addProperty}
                          deleteProperty={deleteProperty}
                          setShowDialog={setShowDialog}
                          map={map}
                        />
                      )
                    }
                    case 'Modules': {
                      return (
                        <ModuleCard
                          key={`${child?.recordid}_${parentid}_${index}_${
                            horizontal ? 'horizontal' : 'vertical'
                          }`}
                          parentid={item.recordid}
                          parentclass={rclass}
                          deleteProperty={deleteProperty}
                          item={child}
                          contentContainerStyle={{ margin: 12 }}
                          handleDrop={handleChildDrop}
                        />
                      )
                    }
                    case 'Selections': {
                      return (
                        <ModuleSearch
                          key={child.recordid}
                          parentid={item.recordid}
                          parentclass={rclass}
                          deleteProperty={deleteProperty}
                          item={child}
                        />
                      )
                    }
                    default: {
                      return null
                    }
                  }
                })
                .reduce((prev, curr) => [
                  prev,
                  <Operator
                    key={uuid()}
                    id={item.recordid}
                    rclass={rclass}
                    operator={properties['Link Type']}
                  />,
                  curr,
                ])}

              {hasDroppable && (
                <View
                  style={{
                    minHeight: 134,
                    width: 281,
                    borderWidth: 1,
                    borderStyle: 'dashed',
                    borderColor: '#AEE2CD',
                    backgroundColor: 'rgba(174, 226, 205, 0.2)',
                    borderRadius: 16,
                    marginHorizontal: 16,
                  }}
                />
              )}
            </View>
          )}

          {childItems.length ? (
            <View
              style={{
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
            >
              <AlignedRow gap={8}>
                <GroupAddButton
                  onPress={() => {
                    addProperty('Subgroups', item.recordid, rclass, 'Edit')
                  }}
                  style={{ paddingHorizontal: 16 }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#1D7A55" name="add-circle" />
                    <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                      Group
                    </Button>
                  </AlignedRow>
                </GroupAddButton>

                <GroupAddButton
                  onPress={() =>
                    setShowDialog({
                      parentid: item.recordid,
                      parentclass: rclass,
                      primaryProperty: 'Modules',
                      secondaryProperty: 'Module',
                    })
                  }
                  style={{ paddingHorizontal: 16 }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#1D7A55" name="add-circle" />
                    <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                      Module
                    </Button>
                  </AlignedRow>
                </GroupAddButton>

                {rclass === 'Programme Stage Group' && (
                  <GroupAddButton
                    onPress={() => {
                      setShowDialog({
                        parentid: item.recordid,
                        parentclass: rclass,
                        primaryProperty: 'Selections',
                        secondaryProperty: 'Module Selection',
                      })
                    }}
                    style={{ paddingHorizontal: 16 }}
                  >
                    <AlignedRow>
                      <WorktribeIcon color="#1D7A55" name="add-circle" />
                      <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                        Selection
                      </Button>
                    </AlignedRow>
                  </GroupAddButton>
                )}
              </AlignedRow>
            </View>
          ) : (
            <>
              {hasDroppable ? (
                <CenteredView style={{ padding: 24 }}>
                  <View
                    style={{
                      minHeight: 134,
                      width: 281,
                      borderWidth: 1,
                      borderStyle: 'dashed',
                      borderColor: '#AEE2CD',
                      backgroundColor: 'rgba(174, 226, 205, 0.2)',
                      borderRadius: 16,
                      marginHorizontal: 16,
                    }}
                  />
                </CenteredView>
              ) : (
                <View style={{ padding: 24 }}>
                  <StructureOptions
                    parentid={item.recordid}
                    parentclass={rclass}
                    properties={children}
                    setShowDialog={setShowDialog}
                    addProperty={addProperty}
                  />
                </View>
              )}
            </>
          )}
        </>
      )}

      {isHovered && (
        <Rotate collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
    </DraxView>
  )
}

const StageSubgroup = ({
  parentid,
  parentclass,
  item,
  addProperty,
  deleteProperty,
  setShowDialog,
  map,
}) => {
  const {
    Subgroup: { rclass, children, properties: props, credits, title },
  } = map

  const [isHovered, setIsHovered] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const properties = useProperties(item, rclass, props)

  const { horizontal, toggleDirection } = useGroupDirection(
    properties,
    item.recordid,
    rclass
  )

  const { items: childItems, setItems } = useChildItems(
    properties,
    children.map(child => child.property)
  )

  const deleteStageSubgroup = () => {
    deleteProperty(parentid, parentclass, 'Subgroups', [item.recordid])
  }

  const {
    typography: { Button, Body },
    themeColors: { bg },
    shadowStyles,
  } = useContext(ThemeContext)

  if (!properties || !childItems) {
    return <Loading />
  }

  return (
    <View
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[
        {
          borderRadius: 20,
          // width: '100%',
          minWidth: 329,
          maxWidth: '100%',
          backgroundColor: '#EBEEF7',
          position: 'relative',
          margin: 12,
        },
      ]}
    >
      <StageGroupHeader
        groupId={item.recordid}
        type={rclass}
        group={properties}
        credits={credits}
        onDelete={deleteStageSubgroup}
        onDirectionChange={toggleDirection}
        horizontal={horizontal}
      />

      {collapsed ? (
        <CenteredView style={{ height: 50 }}>
          <Pressable onPress={() => setCollapsed(false)}>
            <Body>{`${childItems.length} hidden ${
              childItems.length > 1 ? 'items' : 'item'
            }`}</Body>
          </Pressable>
        </CenteredView>
      ) : (
        <>
          <View
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              flex: 1,
            }}
          >
            {childItems.length > 0 && (
              <View
                style={{
                  padding: 24,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  flexDirection: horizontal ? 'row' : 'column',
                  flexWrap: 'wrap',
                }}
              >
                {childItems
                  .map(child => {
                    switch (child.type) {
                      case 'Modules': {
                        return (
                          <ModuleCard
                            key={child.recordid}
                            parentid={item.recordid}
                            parentclass={rclass}
                            deleteProperty={deleteProperty}
                            item={child}
                            contentContainerStyle={{
                              backgroundColor: bg.primary,
                            }}
                          />
                        )
                      }
                      case 'Selections': {
                        return (
                          <ModuleSearch
                            key={child.recordid}
                            parentid={item.recordid}
                            parentclass={rclass}
                            deleteProperty={deleteProperty}
                            contentContainerStyle={{
                              backgroundColor: bg.primary,
                            }}
                            item={child}
                          />
                        )
                      }
                      default: {
                        return null
                      }
                    }
                  })
                  .reduce((prev, curr) => [
                    prev,
                    <Operator
                      key={uuid()}
                      id={item.recordid}
                      rclass={rclass}
                      operator={properties['Link Type']}
                    />,
                    curr,
                  ])}
              </View>
            )}
          </View>

          {childItems.length ? (
            <View
              style={{
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
            >
              <AlignedRow gap={8}>
                <GroupAddButton
                  onPress={() => {
                    setShowDialog({
                      parentid: item.recordid,
                      parentclass: rclass,
                      primaryProperty: 'Modules',
                      secondaryProperty: 'Module',
                    })
                  }}
                  style={{ paddingHorizontal: 16 }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#1D7A55" name="add-circle" />
                    <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                      Module
                    </Button>
                  </AlignedRow>
                </GroupAddButton>

                {rclass === 'Programme Stage Subgroup' && (
                  <GroupAddButton
                    onPress={() => {
                      setShowDialog({
                        parentid: item.recordid,
                        parentclass: rclass,
                        primaryProperty: 'Selections',
                        secondaryProperty: 'Module Selection',
                      })
                    }}
                    style={{ paddingHorizontal: 16 }}
                  >
                    <AlignedRow>
                      <WorktribeIcon color="#1D7A55" name="add-circle" />
                      <Button color="#1D7A55" style={{ marginLeft: 6 }}>
                        Selection
                      </Button>
                    </AlignedRow>
                  </GroupAddButton>
                )}
              </AlignedRow>
            </View>
          ) : (
            <View style={{ padding: 24 }}>
              <StructureOptions
                parentid={item.recordid}
                parentclass={rclass}
                properties={children}
                addProperty={addProperty}
                setShowDialog={setShowDialog}
              />
            </View>
          )}
        </>
      )}

      {isHovered && (
        <Rotate collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
    </View>
  )
}

const StageGroupHeader = ({
  groupId,
  type,
  group,
  credits,
  onDelete,
  onDirectionChange,
  horizontal,
}) => {
  const [title, onChangeTitle] = useState(group['Title'])
  const existingTitle = useRef(title)

  const [description, onChangeDescription] = useState(group['Description'])
  const [descriptionHeight, setDescriptionHeight] = useState(null)
  const existingDescription = useRef(description)

  const [fieldEdit, setFieldEdit] = useState('')
  const [showCredits, setShowCredits] = useState<string>('')

  const [showOptions, setShowOptions] = useState(false)

  const titleSelection = useMemo(
    () => ({
      start: title.length,
    }),
    [groupId]
  )

  const descriptionSelection = useMemo(
    () => ({
      start: description.length,
    }),
    [groupId]
  )

  const editField = field =>
    setFieldEdit(current => (current === field ? '' : field))

  const { record_action } = useSocketMethods()

  useEffect(() => {
    if (description.length === 0) {
      setDescriptionHeight(null)
    }
  }, [description])

  const updateTitle = () => {
    try {
      record_action({
        recordid: groupId,
        rclassname: type,
        actionname: 'Edit',
        changes: [
          {
            property: 'Title',
            method: 'set',
            value: title,
          },
        ],
      })
    } finally {
      existingDescription.current = title
      editField('')
    }
  }

  const updateDescription = () => {
    try {
      record_action({
        recordid: groupId,
        rclassname: type,
        actionname: 'Edit',
        changes: [
          {
            property: 'Description',
            method: 'set',
            value: description,
          },
        ],
      })
    } finally {
      existingTitle.current = title
      setDescriptionHeight(null)
      editField('')
    }
  }

  const {
    typography: { H4, Body, Overline },
    themeColors: { bg, fonts, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  const outsideHandler = useCallback(() => setShowOptions(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <Pressable
      style={{
        backgroundColor: '#EBEEF7',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        paddingVertical: 17,
        paddingHorizontal: 24,
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 99,
      }}
    >
      <View style={{ flex: 1 }}>
        {fieldEdit === 'title' ? (
          <AlignedRow gap={8}>
            <TextInput
              selection={titleSelection}
              style={{
                flex: 1,
                fontFamily: 'proxima-nova-bold',
                fontSize: 17,
                lineHeight: 24,
                outline: 'none',
                backgroundColor: bg.primary,
                color: fonts.primary,
                paddingHorizontal: 8,
                paddingVertical: 1,
                borderRadius: 8,
                marginLeft: -8,
              }}
              onChangeText={onChangeTitle}
              value={title}
              autoFocus={true}
            />

            <Pressable onPress={updateTitle}>
              <WorktribeIcon size={24} color="#2AAD79" name="check-circle-2" />
            </Pressable>

            <Pressable
              onPress={() => {
                onChangeTitle(existingTitle.current)
                editField('')
              }}
            >
              <WorktribeIcon color="#757885" size={24} name="remove-circle" />
            </Pressable>
          </AlignedRow>
        ) : (
          <Pressable onPress={() => editField('title')}>
            <H4 primary>{title.length ? title : `Add a title`}</H4>
          </Pressable>
        )}

        {fieldEdit === 'description' ? (
          <AlignedRow style={{ alignItems: 'flex-start' }} gap={8}>
            <TextInput
              selection={descriptionSelection}
              onContentSizeChange={event => {
                const { contentSize } = event.nativeEvent
                setDescriptionHeight(contentSize.height)
              }}
              style={{
                flex: 1,
                fontFamily: 'proxima-nova',
                fontSize: 15,
                lineHeight: 24,
                outline: 'none',
                backgroundColor: bg.primary,
                color: fonts.primary,
                paddingHorizontal: 8,
                paddingVertical: 1,
                borderRadius: 8,
                height: descriptionHeight ? descriptionHeight : 'auto',
                maxHeight: 200,
                width: 300,
              }}
              onChangeText={onChangeDescription}
              value={description}
              autoFocus={true}
              multiline
            />

            <Pressable onPress={updateDescription}>
              <WorktribeIcon size={24} color="#2AAD79" name="check-circle-2" />
            </Pressable>

            <Pressable
              onPress={() => {
                onChangeDescription(existingDescription.current)
                setDescriptionHeight(null)
                editField('')
              }}
            >
              <WorktribeIcon color="#757885" size={24} name="remove-circle" />
            </Pressable>
          </AlignedRow>
        ) : (
          <Pressable onPress={() => editField('description')}>
            <Body>
              {description.length ? description : `Add a description`}
            </Body>
          </Pressable>
        )}
      </View>

      <AlignedRow gap={8} style={{ marginLeft: 12 }}>
        {credits && (
          <Pressable
            style={{
              position: 'relative',
              paddingVertical: 4,
              paddingHorizontal: 12,
              borderRadius: 50,
              backgroundColor: '#F2FCF8',
              zIndex: 99,
            }}
            onPress={() =>
              setShowCredits(current => (current === groupId ? 0 : groupId))
            }
          >
            <Overline color="#1D7A55">
              {group['Credit Rule']
                ? group['Credit Rule'][0] === 'Between'
                  ? `Between ${group['Minimum Credits']} and ${group['Maximum Credits']} credits`
                  : group['Credit Rule'][0] === 'Maximum'
                  ? `${group['Credit Rule'][0]} ${group['Maximum Credits']} credits`
                  : group['Credit Rule'][0] === 'Minimum'
                  ? `${group['Credit Rule'][0]} ${group['Minimum Credits']} credits`
                  : `${group['Exact Credits']} credits`
                : 'None'}
            </Overline>

            {showCredits === groupId && (
              <CreditSelect
                parentid={groupId}
                parentclass={type}
                properties={group}
                onClose={() => setShowCredits(0)}
              />
            )}
          </Pressable>
        )}

        <View ref={wrapperRef} style={{ position: 'relative' }}>
          <Pressable
            onPress={() => setShowOptions(show => !show)}
            style={[
              {
                height: 36,
                width: 36,
                borderRadius: 18,
                backgroundColor: showOptions ? '#F2FCF8' : bg.primary,
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 6,
              },
              showOptions && { borderWidth: 1, borderColor: '#1D7A55' },
            ]}
          >
            <Ellipsis color="#1D7A55" />
          </Pressable>
          {showOptions && (
            <View
              // ref={wrapperRef}
              style={[
                {
                  position: 'absolute',
                  top: 45,
                  right: 0,
                  backgroundColor: bg.primary,
                  borderRadius: 16,
                  paddingVertical: 8,
                  maxHeight: 345,
                  width: 241,
                },
                shadowStyles,
              ]}
            >
              <ScrollView style={{ flex: 1 }}>
                <HoverableOpacity
                  onPress={() => {
                    onDirectionChange()
                    setShowOptions(false)
                  }}
                  style={{
                    justifyContent: 'center',
                    minHeight: 46,
                    paddingHorizontal: 16,
                    paddingVertical: 11,
                  }}
                  hoverStyle={{ backgroundColor: hover }}
                >
                  <AlignedRow>
                    <View
                      style={{
                        transform: [
                          { rotate: horizontal ? '-90deg' : '180deg' },
                        ],
                      }}
                    >
                      <WorktribeIcon
                        color="#2AAD79"
                        name="keyboard-arrow-right"
                      />
                    </View>

                    <Body style={{ marginLeft: 12 }} primary>
                      {`Display ${horizontal ? 'stacked' : 'in a row'}`}
                    </Body>
                  </AlignedRow>
                </HoverableOpacity>

                <HoverableOpacity
                  onPress={() => {
                    onDelete()
                    setShowOptions(false)
                  }}
                  style={{
                    justifyContent: 'center',
                    minHeight: 46,
                    paddingHorizontal: 16,
                    paddingVertical: 11,
                  }}
                  hoverStyle={{ backgroundColor: hover }}
                >
                  <AlignedRow>
                    <WorktribeIcon color="#D13E3E" name="bin" />
                    <Body style={{ marginLeft: 12 }} primary>
                      Remove
                    </Body>
                  </AlignedRow>
                </HoverableOpacity>
              </ScrollView>
            </View>
          )}
        </View>
      </AlignedRow>
    </Pressable>
  )
}

const CreditSelect = ({ parentid, parentclass, properties, onClose }) => {
  const [showCreditRule, setShowCreditRule] = useState(false)
  const [creditRule, setCreditRule] = useState(
    properties['Credit Rule'][0] ? properties['Credit Rule'][0] : 'None'
  )
  const existingCreditRule = useRef(creditRule)

  const [showExactCredits, setShowExactCredits] = useState(false)
  const [exactCredits, setExactCredits] = useState(properties['Exact Credits'])
  const existingExactCredits = useRef(exactCredits)

  const [showMaximumCredits, setShowMaximumCredits] = useState(false)
  const [maximumCredits, setMaximumCredits] = useState(
    properties['Maximum Credits']
  )
  const existingMaximumCredits = useRef(maximumCredits)

  const [showMinimumCredits, setShowMinimumCredits] = useState(false)
  const [minimumCredits, setMinimumCredits] = useState(
    properties['Minimum Credits']
  )
  const existingMinimumCredits = useRef(minimumCredits)

  const { record_action } = useSocketMethods()

  const save = () => {
    try {
      record_action({
        recordid: parentid,
        rclassname: parentclass,
        actionname: 'Edit',
        changes: [
          {
            property: 'Credit Rule',
            method: 'set',
            value: [creditRule],
          },
          {
            property: 'Exact Credits',
            method: 'set',
            value: exactCredits.toString(),
          },
          {
            property: 'Maximum Credits',
            method: 'set',
            value: maximumCredits.toString(),
          },
          {
            property: 'Minimum Credits',
            method: 'set',
            value: minimumCredits.toString(),
          },
        ],
      })
    } finally {
      onClose()
    }
  }

  const cancel = () => {
    setCreditRule(existingCreditRule.current)
    setExactCredits(existingExactCredits.current)
    setMaximumCredits(existingMaximumCredits.current)
    setMinimumCredits(existingMinimumCredits.current)

    onClose()
  }

  useEffect(() => {
    if (creditRule) setShowCreditRule(false)
  }, [creditRule])

  useEffect(() => {
    if (exactCredits) setShowExactCredits(false)
  }, [exactCredits])

  useEffect(() => {
    if (maximumCredits) setShowMaximumCredits(false)
  }, [maximumCredits])

  useEffect(() => {
    if (minimumCredits) setShowMinimumCredits(false)
  }, [minimumCredits])

  const {
    typography: { Body, Overline },
    themeColors: { bg, hover },
    shadowStyles,
  } = useContext(ThemeContext)

  return (
    <View
      style={[
        {
          position: 'absolute',
          maxHeight: 232,
          height: 82,
          backgroundColor: bg.primary,
          right: 0,
          top: 40,
          padding: 20,
          borderRadius: 16,
          zIndex: 99,
        },
        shadowStyles,
      ]}
    >
      <AlignedRow gap={8}>
        <Pressable
          onPress={() => setShowCreditRule(show => !show)}
          style={{
            position: 'relative',
            backgroundColor: bg.card,
            borderRadius: 50,
            paddingVertical: 9,
            paddingHorizontal: 16,
          }}
        >
          <AlignedRow gap={16}>
            <Body>{creditRule}</Body>

            <WorktribeIcon name="chevron-down" />
          </AlignedRow>

          {showCreditRule && (
            <View
              style={[
                {
                  position: 'absolute',
                  top: 40,
                  right: 0,
                  width: 220,

                  borderRadius: 16,
                  backgroundColor: bg.primary,
                },
                shadowStyles,
              ]}
            >
              <HoverableOpacity
                onPress={() => setCreditRule('None')}
                hoverStyle={{ backgroundColor: hover }}
                style={{ paddingVertical: 11, paddingHorizontal: 20 }}
              >
                <Body primary>None</Body>
              </HoverableOpacity>

              <HoverableOpacity
                onPress={() => setCreditRule('Minimum')}
                hoverStyle={{ backgroundColor: hover }}
                style={{ paddingVertical: 11, paddingHorizontal: 20 }}
              >
                <Body primary>Minimum</Body>
              </HoverableOpacity>

              <HoverableOpacity
                onPress={() => setCreditRule('Maximum')}
                hoverStyle={{ backgroundColor: hover }}
                style={{ paddingVertical: 11, paddingHorizontal: 20 }}
              >
                <Body primary>Maximum</Body>
              </HoverableOpacity>

              <HoverableOpacity
                onPress={() => setCreditRule('Between')}
                hoverStyle={{ backgroundColor: hover }}
                style={{ paddingVertical: 11, paddingHorizontal: 20 }}
              >
                <Body primary>Between</Body>
              </HoverableOpacity>

              <HoverableOpacity
                onPress={() => setCreditRule('Exact')}
                hoverStyle={{ backgroundColor: hover }}
                style={{ paddingVertical: 11, paddingHorizontal: 20 }}
              >
                <Body primary>Exact</Body>
              </HoverableOpacity>
            </View>
          )}
        </Pressable>

        {creditRule === 'Exact' && (
          <Pressable
            onPress={() => setShowExactCredits(show => !show)}
            style={{
              zIndex: -1,
              position: 'relative',
              backgroundColor: bg.card,
              borderRadius: 50,
              paddingVertical: 9,
              paddingHorizontal: 16,
            }}
          >
            <AlignedRow gap={16}>
              <Body>{exactCredits}</Body>

              <WorktribeIcon name="chevron-down" />
            </AlignedRow>

            {showExactCredits && (
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 40,
                    right: 0,
                    width: 220,
                    maxHeight: 232,
                    borderRadius: 16,
                    backgroundColor: bg.primary,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView>
                  {getCreditRange().map(credit => (
                    <HoverableOpacity
                      key={credit}
                      onPress={() => setExactCredits(credit)}
                      hoverStyle={{ backgroundColor: hover }}
                      style={{ paddingVertical: 11, paddingHorizontal: 20 }}
                    >
                      <Body primary>{credit}</Body>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
          </Pressable>
        )}

        {creditRule === 'Minimum' && (
          <Pressable
            onPress={() => setShowMinimumCredits(show => !show)}
            style={{
              zIndex: -1,
              position: 'relative',
              backgroundColor: bg.card,
              borderRadius: 50,
              paddingVertical: 9,
              paddingHorizontal: 16,
            }}
          >
            <AlignedRow gap={16}>
              <Body>{minimumCredits}</Body>

              <WorktribeIcon name="chevron-down" />
            </AlignedRow>

            {showMinimumCredits && (
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 40,
                    right: 0,
                    width: 220,
                    maxHeight: 232,
                    borderRadius: 16,
                    backgroundColor: bg.primary,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView>
                  {getCreditRange().map(credit => (
                    <HoverableOpacity
                      key={credit}
                      onPress={() => setMinimumCredits(credit)}
                      hoverStyle={{ backgroundColor: hover }}
                      style={{ paddingVertical: 11, paddingHorizontal: 20 }}
                    >
                      <Body primary>{credit}</Body>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
          </Pressable>
        )}

        {creditRule === 'Maximum' && (
          <Pressable
            onPress={() => setShowMaximumCredits(show => !show)}
            style={{
              zIndex: -1,
              position: 'relative',
              backgroundColor: bg.card,
              borderRadius: 50,
              paddingVertical: 9,
              paddingHorizontal: 16,
            }}
          >
            <AlignedRow gap={16}>
              <Body>{maximumCredits}</Body>

              <WorktribeIcon name="chevron-down" />
            </AlignedRow>

            {showMaximumCredits && (
              <View
                style={[
                  {
                    position: 'absolute',
                    top: 40,
                    right: 0,
                    width: 220,
                    maxHeight: 232,
                    borderRadius: 16,
                    backgroundColor: bg.primary,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView>
                  {getCreditRange().map(credit => (
                    <HoverableOpacity
                      key={credit}
                      onPress={() => setMaximumCredits(credit)}
                      hoverStyle={{ backgroundColor: hover }}
                      style={{ paddingVertical: 11, paddingHorizontal: 20 }}
                    >
                      <Body primary>{credit}</Body>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
          </Pressable>
        )}

        {creditRule === 'Between' && (
          <AlignedRow gap={8}>
            <Pressable
              onPress={() => setShowMinimumCredits(show => !show)}
              style={{
                zIndex: -1,
                position: 'relative',
                backgroundColor: bg.card,
                borderRadius: 50,
                paddingVertical: 9,
                paddingHorizontal: 16,
              }}
            >
              <AlignedRow gap={16}>
                <Body>{minimumCredits}</Body>

                <WorktribeIcon name="chevron-down" />
              </AlignedRow>

              {showMinimumCredits && (
                <View
                  style={[
                    {
                      position: 'absolute',
                      top: 40,
                      right: 0,
                      width: 220,
                      maxHeight: 232,
                      borderRadius: 16,
                      backgroundColor: bg.primary,
                    },
                    shadowStyles,
                  ]}
                >
                  <ScrollView>
                    {getCreditRange().map(credit => (
                      <HoverableOpacity
                        onPress={() => setMinimumCredits(credit)}
                        hoverStyle={{ backgroundColor: hover }}
                        style={{ paddingVertical: 11, paddingHorizontal: 20 }}
                      >
                        <Body primary>{credit}</Body>
                      </HoverableOpacity>
                    ))}
                  </ScrollView>
                </View>
              )}
            </Pressable>

            <Overline>AND</Overline>

            <Pressable
              onPress={() => setShowMaximumCredits(show => !show)}
              style={{
                zIndex: -1,
                position: 'relative',
                backgroundColor: bg.card,
                borderRadius: 50,
                paddingVertical: 9,
                paddingHorizontal: 16,
              }}
            >
              <AlignedRow gap={16}>
                <Body>{maximumCredits}</Body>

                <WorktribeIcon name="chevron-down" />
              </AlignedRow>

              {showMaximumCredits && (
                <View
                  style={[
                    {
                      position: 'absolute',
                      top: 40,
                      right: 0,
                      width: 220,
                      maxHeight: 232,
                      borderRadius: 16,
                      backgroundColor: bg.primary,
                    },
                    shadowStyles,
                  ]}
                >
                  <ScrollView>
                    {getCreditRange().map(credit => (
                      <HoverableOpacity
                        onPress={() => setMaximumCredits(credit)}
                        hoverStyle={{ backgroundColor: hover }}
                        style={{ paddingVertical: 11, paddingHorizontal: 20 }}
                      >
                        <Body primary>{credit}</Body>
                      </HoverableOpacity>
                    ))}
                  </ScrollView>
                </View>
              )}
            </Pressable>
          </AlignedRow>
        )}

        <Pressable onPress={save}>
          <WorktribeIcon size={24} color="#2AAD79" name="check-circle-2" />
        </Pressable>

        <Pressable onPress={cancel}>
          <WorktribeIcon color="#757885" size={24} name="remove-circle" />
        </Pressable>
      </AlignedRow>
    </View>
  )
}

const ModuleCard = ({
  parentid,
  parentclass,
  item,
  deleteProperty,
  contentContainerStyle,
  handleDrop,
}) => {
  const navigation = useNavigation()
  const [isHovered, setIsHovered] = useState(false)

  const deleteProgrammeModule = () => {
    deleteProperty(parentid, parentclass, 'Modules', [item.recordid])
  }

  let properties
  let moduleRecordId: string
  if (item.properties?.[0].value.records?.[0]) {
    const { recordid, properties: _properties } =
      item.properties[0].value.records[0]

    moduleRecordId = recordid

    properties = _properties.reduce((acc, curr) => {
      acc[curr.name] = curr.value
      acc['recordid'] = recordid

      return acc
    }, {})
  }

  const {
    typography: { Overline, Caption, Label, Subtitle },
    shadowStyles,
  } = useContext(ThemeContext)

  return (
    <Pressable
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => {
        navigation.push('Record', {
          rClass: 'Module',
          recordId: moduleRecordId,
        })
      }}
    >
      <DraxView
        // key={`${item.recordid}_${parentid}_${index}_${horizontal ? 'horizontal' : 'vertical'}`}
        draggable
        draggingStyle={{
          opacity: 0.2,
        }}
        hoverDraggingStyle={{
          borderWidth: 2,
          borderColor: '#2AAD79',
        }}
        payload={{
          ...item,
          parentid,
          rclass: 'Programme Stage Module',
          handleDrop,
        }}
        style={[
          {
            position: 'relative',
            minHeight: 134,
            width: 281,
            backgroundColor: '#EBEEF7',
            borderRadius: 16,
            padding: 16,
            margin: 12,
            alignItems: 'space-between',
          },
          contentContainerStyle,
          shadowStyles,
        ]}
      >
        {properties ? (
          <>
            <View style={{ flex: 1 }}>
              <Subtitle style={{ marginBottom: 2 }} primary>
                {properties['Title']}
              </Subtitle>
              <Caption>{properties['Status']}</Caption>
            </View>

            <AlignedRow justifyContent="space-between">
              <Overline>{properties['Code'] || '12345'}</Overline>
              <Label>{properties['Credits']} credits</Label>
            </AlignedRow>
          </>
        ) : null}

        {isHovered && <Delete onDelete={deleteProgrammeModule} />}
      </DraxView>
    </Pressable>
  )
}

const ModuleSearch = ({
  parentid,
  parentclass,
  item,
  contentContainerStyle,
  deleteProperty,
}) => {
  const navigation = useNavigation()

  const [isHovered, setIsHovered] = useState(false)

  const deleteSelection = () => {
    deleteProperty(parentid, parentclass, 'Selections', [item.recordid])
  }

  let properties
  let moduleSelectionRecordId
  if (item.properties?.[0].value.records?.[0]) {
    const { recordid, properties: _properties } =
      item.properties[0].value.records[0]

    moduleSelectionRecordId = recordid

    properties = _properties.reduce((acc, curr) => {
      acc[curr.name] = curr.value
      acc['recordid'] = recordid

      return acc
    }, {})
  }

  if (!properties) {
    return null
  }

  const {
    typography: { Subtitle, Caption, Label },
    themeColors: { bg },
    shadowStyles,
  } = useContext(ThemeContext)

  return (
    <Pressable
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => {
        navigation.push('Record', {
          rClass: 'Module Selection',
          recordId: moduleSelectionRecordId,
        })
      }}
      style={[
        {
          position: 'relative',
          minHeight: 134,
          width: 281,
          backgroundColor: '#EBEEF7',
          borderRadius: 16,
          padding: 16,
          margin: 12,
          alignItems: 'space-between',
        },
        contentContainerStyle,
        shadowStyles,
      ]}
    >
      <View style={{ flex: 1 }}>
        <Subtitle style={{ marginBottom: 8 }} primary>
          {properties['Title']}
        </Subtitle>
        <Caption primary style={{ marginBottom: 8 }}>
          {properties['Description']}
        </Caption>
      </View>

      {isHovered && <Delete onDelete={deleteSelection} />}
    </Pressable>
  )
}

const Delete = ({ onDelete }) => {
  const {
    shadowStyles,
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Pressable
      style={[
        {
          position: 'absolute',
          top: -15,
          right: -15,
          height: 36,
          width: 36,
          borderRadius: 18,
          backgroundColor: bg.primary,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
        },
        shadowStyles,
      ]}
      onPress={onDelete}
    >
      <WorktribeIcon color="#D13E3E" name="bin" />
    </Pressable>
  )
}

const Rotate = ({ collapsed, setCollapsed }) => {
  const {
    shadowStyles,
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Pressable
      style={[
        {
          position: 'absolute',
          left: -15,
          top: -15,
          height: 36,
          width: 36,
          borderRadius: 18,
          backgroundColor: bg.primary,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
        },
        shadowStyles,
        !collapsed && { transform: [{ rotate: '90deg' }] },
      ]}
      onPress={() => setCollapsed(collapsed => !collapsed)}
    >
      <WorktribeIcon name="arrow-right" />
    </Pressable>
  )
}

function getCreditRange() {
  const arr = []
  for (var i = 0; i <= 200; i += 5) arr.push(i)
  return arr
}

const GroupAddButton = styled.Pressable`
  height: 46px;
  border: 1px dashed rgba(148, 163, 184, 0.33);
  border-radius: 35px;
  padding: 12px 0;
  margin: 6px 0;
  align-items: center;
`
