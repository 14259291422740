import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform, ScrollView, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { AlignedRow } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'
import HoverableOpacity from '../shared/HoverableOpacity'
import { Portal } from 'react-native-portalize'
import { ThemeContext } from '../../contexts/ThemeContext'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import { useNavigation } from '@react-navigation/native'
import useZubanubi from '../../hooks/useZubanubi'
import { useAuth } from '../../contexts/AuthContext'

const Container = styled.View`
  max-width: 848px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -424px;
  border-radius: 15px;
  border-width: 1px;
`

const Overlay = styled.Pressable`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const SearchRow = styled.View`
  padding: 11px 24px;
  border-bottom-width: 1px;
`

const SearchImage = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
  margin-right: 12px;
`

const SearchInput = styled.TextInput`
  flex: 1;
  font-family: proxima-nova;
  font-size: 15px;
  line-height: 22px;
  margin-left: 16px;
  height: 22px;
`

const Searchatron = ({ setShowSearch, initialValue = '' }) => {
  const {
    typography: { Body, Caption },
    themeColors: { bg, accents, hover, fonts },
  } = useContext(ThemeContext)

  const [data, setData] = useState([])
  const [search, onChangeSearch] = useState(initialValue)

  const getZubanubiRepo = useZubanubi()

  const { user } = useAuth()

  useEffect(() => {
    let cancel
    ;(async function () {
      const zubanubiRepo = await getZubanubiRepo()
      axios
        .get(`https://www.zubanubi.com/zapi.php`, {
          params: {
            method: `record_search`,
            wtauth: true,
            wtenv: `${zubanubiRepo}`,
            debugtoken: `${user.userid}.quirkafleeg`,
            scope: 'recents',
            q: search,
          },
          cancelToken: new axios.CancelToken(c => (cancel = c)),
        })
        .then(response => {
          const { data } = response.data

          const results = data.map(payload => {
            const {
              properties,
              records,
              rclass: { name },
            } = payload

            const record = records[0]

            let value = ''
            const result = Object.entries(properties).find(
              ([_, property]) => property.type === 'title'
            )
            if (result) {
              const [titleProperty] = result
              value = record.properties.find(
                prop => prop.name === titleProperty
              ).value
            }

            return {
              recordname: value,
              recordid: record.recordid,
              coverid: record.coverid,
              rclass: name,
            }
          })

          // exclude results without a recordname
          const filteredResults = results.filter(result => result.recordname)

          setData(filteredResults)
        })
        .catch(e => {
          if (axios.isCancel(e)) return
        })
    })()

    return cancel
  }, [search])

  const outsideHandler = useCallback(() => setShowSearch(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  const navigation = useNavigation()

  const submitQuery = () => {
    if (search.length < 3) return
    navigation.navigate('Search', { search })
    setShowSearch(false)
  }

  const handleHide = useCallback(() => {
    setShowSearch(false)
  }, [])

  const handleSelect = async (recordid, rclass) => {
    const savedStateString = await AsyncStorage.getItem('@selectedTab')
    const state = savedStateString ? JSON.parse(savedStateString) : undefined

    if (state) {
      delete state[recordid]
      AsyncStorage.setItem('@selectedTab', JSON.stringify(state))
    }

    setShowSearch(false)

    navigation.push('Record', {
      rClass: rclass,
      recordId: recordid,
    })
  }

  return (
    <Portal>
      <Overlay onPress={handleHide} />

      <Container
        ref={wrapperRef}
        style={{
          shadowColor: 'rgba(0, 0, 0, 0.12)',
          shadowOpacity: 0.58,
          shadowRadius: 16.0,
          elevation: 24,
          backgroundColor: bg.primary,
          borderColor: accents.separator,
        }}
      >
        <SearchRow style={{ borderColor: accents.separator }}>
          <AlignedRow justifyContent="space-between">
            <AlignedRow style={{ flex: 1 }}>
              <WorktribeIcon name="search-alternate" size={20} />
              <SearchInput
                autoFocus={true}
                style={[
                  Platform.OS === 'web' && { outlineWidth: 0, outline: 'none' },
                  { color: fonts.primary },
                ]}
                placeholder="Search"
                placeholderTextColor="#A0A4B8"
                onChangeText={onChangeSearch}
                value={search}
                onSubmitEditing={submitQuery}
                blurOnSubmit={false}
              />
            </AlignedRow>
            <TouchableOpacity onPress={() => setShowSearch(false)}>
              <WorktribeIcon name="remove" />
            </TouchableOpacity>
          </AlignedRow>
        </SearchRow>
        <ScrollView style={{ flex: 1, paddingBottom: 8 }}>
          {search.length > 2 && (
            <>
              <HoverableOpacity
                hoverStyle={{ backgroundColor: hover }}
                onPress={submitQuery}
              >
                <AlignedRow
                  style={{ paddingVertical: 9, paddingHorizontal: 24 }}
                >
                  <WorktribeIcon name="search-alternate" size={20} />
                  <Body style={{ marginLeft: 12 }} primary>
                    Search all results for “{search}”
                  </Body>
                </AlignedRow>
              </HoverableOpacity>
            </>
          )}

          {data.length > 0 && (
            <Caption
              style={{
                paddingTop: 12,
                paddingBottom: 5,
                paddingHorizontal: 24,
              }}
            >
              Recent searches
            </Caption>
          )}

          {data.map(item => (
            <HoverableOpacity
              key={item.recordid}
              onPress={() => handleSelect(item.recordid, item.rclass)}
              hoverStyle={{ backgroundColor: hover }}
            >
              <AlignedRow
                justifyContent="space-between"
                style={{ paddingVertical: 9, paddingHorizontal: 24 }}
              >
                <AlignedRow style={{ flex: 1 }}>
                  <SearchImage
                    source={{
                      uri: `https://zubanubi.com/resources/covers/cover${
                        item.coverid
                      }_thumb@2x.jpg`,
                      cache: 'force-cache',
                    }}
                  />
                  <Body numberOfLines={1} primary>
                    {item.recordname}
                  </Body>
                </AlignedRow>
                <Caption>{item.rclass}</Caption>
              </AlignedRow>
            </HoverableOpacity>
          ))}
        </ScrollView>
      </Container>
    </Portal>
  )
}

export default Searchatron
