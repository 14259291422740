import React from 'react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import * as Typography from '../shared/Typography'

const Container = styled.TouchableOpacity`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px 12px;
  margin-bottom: 16px;
`

const Row = styled.View`
  flex-direction: row;
`

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  margin-right: 12px;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const Delivery = ({ delivery }) => (
  <Container>
    <Row>
      <Thumbnail
        source={{
          uri: `https://source.unsplash.com/${
            imageFeed[Math.floor(Math.random() * imageFeed.length)]
          }/50x50`,
          cache: 'force-cache',
        }}
      />

      <View style={{ flex: 1 }}>
        <Typography.Subtitle color="#0E1012">
          {delivery.title}
        </Typography.Subtitle>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Typography.Body style={{ flex: 1 }} color="#757885">
            {delivery.location}
          </Typography.Body>
          <Typography.Caption color="#757885">
            DELIVERY WKA-23
          </Typography.Caption>
        </View>
        <Typography.Body color="#757885">{delivery.start}</Typography.Body>
        <Typography.Body color="#757885">{delivery.type}</Typography.Body>
      </View>
    </Row>
  </Container>
)

export default Delivery
