import React, { useState } from 'react'
import {
  Image,
  SafeAreaView,
  SectionList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { SearchBar } from 'react-native-elements'
import Avatar from '../../components/shared/Avatar'

const DATA = [
  {
    title: 'Modules',
    data: [
      {
        name: 'Chemistry',
        status: 'In development',
        code: '#UNITB30-4',
        academicYear: '‘19 - ’20',
        credits: 30,
        org: 'Chemistry',
        image: 'PEDWYwNfUoI',
      },
      {
        name: 'Chemistry',
        status: 'In development',
        code: '#UNITB30-4',
        academicYear: '‘19 - ’20',
        credits: 30,
        org: 'Chemistry',
        image: 'G02AR7pa_Lo',
      },
    ],
  },
  {
    title: 'Profiles',
    data: [
      {
        name: 'Graham Pockley',
        jobTitle: 'Chemistry System Administrator',
        org: 'Clinical sciences',
        image: 'S6k4vSWrIzA',
      },
    ],
  },
  {
    title: 'Contracts',
    data: [
      {
        title: 'Commercially led clinical trial',
        status: 'In development',
        code: '#75361',
        org: 'Chemistry',
        image: 'WXgiTeZRXGU',
        people: [
          {
            name: 'Phil Physics',
          },
        ],
      },
    ],
  },
]

const SearchResultsScreen = () => {
  const [search, setSearch] = useState('')

  const renderItem = ({ item, section }) => {
    switch (section.title) {
      case 'Modules':
        return (
          <TouchableOpacity
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 20,
              paddingVertical: 16,
              paddingLeft: 12,
              paddingRight: 16,
              flexDirection: 'row',
              marginBottom: 12,
            }}
          >
            <Image
              style={{
                height: 56,
                width: 56,
                borderRadius: 16,
                marginRight: 12,
              }}
              source={{
                uri: `https://source.unsplash.com/${item.image}/`,
                cache: 'force-cache',
              }}
            />

            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova-semibold',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#0E1012',
                    marginBottom: 2,
                  }}
                >
                  {item.name}
                </Text>
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 14,
                    lineHeight: 18,
                    color: '#757885',
                  }}
                >
                  {item.academicYear}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.status}
                </Text>
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {`${item.credits} credits`}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.code}
                </Text>
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.org}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )
      case 'Profiles':
        return (
          <TouchableOpacity
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 20,
              paddingVertical: 16,
              paddingLeft: 12,
              paddingRight: 16,
              flexDirection: 'row',
              marginBottom: 12,
            }}
          >
            <Image
              style={{
                height: 56,
                width: 56,
                borderRadius: 16,
                marginRight: 12,
              }}
              source={{
                uri: `https://source.unsplash.com/${item.image}/`,
                cache: 'force-cache',
              }}
            />

            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova-semibold',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#0E1012',
                    marginBottom: 2,
                  }}
                >
                  {item.name}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.jobTitle}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.org}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )
      case 'Contracts':
        return (
          <TouchableOpacity
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 20,
              paddingVertical: 16,
              paddingLeft: 12,
              paddingRight: 16,
              flexDirection: 'row',
              marginBottom: 12,
            }}
          >
            <Image
              style={{
                height: 56,
                width: 56,
                borderRadius: 16,
                marginRight: 12,
              }}
              source={{
                uri: `https://source.unsplash.com/${item.image}/`,
                cache: 'force-cache',
              }}
            />

            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova-semibold',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#0E1012',
                    marginBottom: 2,
                  }}
                >
                  {item.title}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.status}
                </Text>
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.code}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'proxima-nova',
                    fontSize: 17,
                    lineHeight: 24,
                    color: '#757885',
                  }}
                >
                  {item.org}
                </Text>
              </View>

              <View
                style={{
                  marginTop: 8,
                }}
              >
                {item.people.map(person => (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar userId={1} radius={8} size={24} />
                    <Text
                      style={{
                        fontFamily: 'proxima-nova',
                        fontSize: 17,
                        lineHeight: 24,
                        color: '#757885',
                        marginLeft: 8,
                      }}
                    >
                      {person.name}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </TouchableOpacity>
        )
      default:
        return null
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#EBECF2' }}>
      <SearchBar
        platform="ios"
        placeholder="Search"
        onChangeText={setSearch}
        value={search}
        containerStyle={{
          backgroundColor: 'transparent',
          marginHorizontal: 10,
        }}
        inputContainerStyle={{
          borderRadius: 16,
          height: 40,
          backgroundColor: '#FFF',
        }}
        inputStyle={{
          backgroundColor: '#FFF',
          fontSize: 17,
          height: 40,
          color: '#757885',
        }}
        cancelButtonProps={{
          buttonTextStyle: {
            color: '#2AAD79',
            fontWeight: '600',
          },
          buttonStyle: {
            marginLeft: 5,
          },
        }}
        searchIcon={<AntDesign name="search1" size={20} color="#757885" />}
        leftIconContainerStyle={{
          marginLeft: 15,
        }}
      />

      <SectionList
        contentContainerStyle={{
          paddingHorizontal: 16,
        }}
        sections={DATA}
        keyExtractor={(item, index) => item + index}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <Text
            style={{
              color: '#757885',
              fontWeight: '600',
              fontSize: 14,
              textTransform: 'uppercase',
              marginVertical: 20,
            }}
          >
            {title}
          </Text>
        )}
        stickySectionHeadersEnabled={false}
      />
    </SafeAreaView>
  )
}

export default SearchResultsScreen
