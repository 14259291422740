import { Platform } from 'react-native'
import { getDeviceModel } from '../utils/deviceModels'
// import DeviceInfo from 'react-native-device-info'
import * as Device from 'expo-device'
import manifest from '../../app.json'
import { osName, browserName, browserVersion } from 'react-device-detect'

export const getClientInfo = (obj = false) => {
  const info = {}

  const platform = Platform.OS
  info.clientversion = manifest.expo.version

  if (platform == 'web') {
    info.clientplatform = osName
    info.clientname = browserName
    info.clientmodel = browserVersion
  } else {
    info.clientname = 'App'
    info.clientmodel = Device.modelName + ' (' + Device.modelId + ')'
  }

  if (obj) {
    return info
  }

  return new URLSearchParams(info).toString()
}
