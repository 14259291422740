import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { APIContext } from '../contexts/APIContext'
import { appendRecords } from '../store/actions/records'
import { AuthContext, useAuth } from '../contexts/AuthContext'

const PAGINATION_LIMIT = 20

const usePagedRecords = screen => {
  const [page, setPage] = useState(1)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [noMoreItems, setNoMoreItems] = useState(false)

  const { live } = useSelector(state => state.records)
  const { records, sectioned } = live[screen]

  const { getFeed, getMine, getRecordsOfRClass } = useContext(APIContext)

  const { user } = useAuth()

  useEffect(() => {
    onRefresh()
  }, [user.userid])

  const fetchMore = useCallback(() => setShouldFetch(true), [])

  const onRefresh = useCallback(() => setRefreshing(true), [])

  const dispatch = useDispatch()

  useEffect(() => {
    if (refreshing) {
      setPage(1)
      setShouldFetch(true)
      setRefreshing(false)
      setNoMoreItems(false)
    }
  }, [refreshing])

  useEffect(
    () => {
      // prevent fetching for other state changes
      if (!shouldFetch || noMoreItems) {
        return
      }

      const fetch = async () => {
        try {
          let response
          switch (screen) {
            case 'home': {
              response = await getFeed({ page, limit: PAGINATION_LIMIT })
              break
            }
            case 'mine': {
              response = await getMine({ page, limit: PAGINATION_LIMIT })
              break
            }
            case 'messages': {
              response = await getRecordsOfRClass('conversation', {
                page,
                limit: PAGINATION_LIMIT,
              })
              break
            }
            default: {
              break
            }
          }

          if (response) {
            const { records } = response.data

            // grab some fields from the spec
            const specRecords = records.map(record => {
              const { spec } = record

              return {
                ...record,
                displayRclass: spec.rclass,
                properties: spec.properties,
              }
            })

            // if we get back less than the limit
            // then we have no more items
            setNoMoreItems(records.length < PAGINATION_LIMIT)

            // set the should fetch call to false to prevent fetching
            // on page number update
            setShouldFetch(false)
            dispatch(appendRecords(specRecords, screen, page === 1))

            //increment page for the next call
            setPage(prevPage => prevPage + 1)
          }
        } catch (e) {
          console.warn(e)
        }
      }

      void fetch()
    },
    // prevent fetching for other state changes
    [page, shouldFetch]
  )

  return { records, sectioned, fetchMore, refreshing, noMoreItems, onRefresh }
}

export default usePagedRecords
