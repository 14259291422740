import React, { useEffect, useState } from 'react'
import {
  StyleSheet,
  TextInput,
  Image,
  View,
  Switch,
  Platform,
  Keyboard,
} from 'react-native'
import styled from 'styled-components/native'
import Calendar from './Calendar'
import WorktribeIcon from './WorktribeIcon'
import Avatar from './Avatar'

const Input = styled.View`
  min-height: 60px;
  padding: 5px 16px;
  font-family: proxima-nova;
  font-size: 17px;
  justify-content: center;
`
const InputHeading = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const InputSelect = styled.View`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: #ebecf2;
  align-items: center;
  justify-content: center;
`
const InputSelectImage = styled.Image`
  width: 14px;
  height: 14px;
`

const Row = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: flex-start;
`

const ButtonText = styled.Text`
  font-family: proxima-nova-semibold;
  margin-left: 20px;
  font-size: 17px;
  font-weight: 600;
  color: ${props => (props.color ? props.color : '#757885')};
`

const SelectMultiple = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`
const SelectMultipleLabel = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  color: ${props => (props.selected ? '#0E1012' : '#757885')};
`

const ChipCards = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 20px;
`
const ChipCard = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  border-width: 1px;
  border-color: #dde1ed;
  padding: 6px 8px;
  margin: 4px;
`
const ChipCardText = styled.Text`
  font-family: proxima-nova;
  font-size: 17px;
  line-height: 24px;
  color: #0e1012;
`

const UserCard = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${props => (props.selected ? '#F2FCF8' : 'transparent')};
  ${({ rounded }) => {
    if (rounded) {
      return `border-${rounded}-left-radius: 16px;border-${rounded}-right-radius: 16px;`
    }
    return ''
  }};
`

const UserDetails = styled.View`
  flex: 1;
  margin-left: 12px;
`

const UserName = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 17px;
  line-height: 24px;
  color: #0e1012;
`

const UserTitle = styled.Text`
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const TEXT_FIELD = 'text'
const TEXTAREA = 'textarea'
const SELECT_ONE = 'selectone'
const SELECT_MULTIPLE = 'selectmultiple'
const SELECT_ARO = 'selectaro'
const DATE = 'date'
const BOOLEAN = 'boolean'
const BUTTON = 'button'
const ARO_STATIC = 'arostatic'

const InputField = props => {
  const { name, required, type, options, maxLines, icon, color } = props.field
  const { navigation, state, dispatch, setModalData } = props

  const [inputFocused, setInputFocused] = useState(false)

  useEffect(() => {
    if (!inputFocused) {
      Keyboard.dismiss()
    }
  }, [inputFocused])

  const [placeholderText, setPlaceholderText] = useState(name)

  useEffect(() => {
    setPlaceholderText(inputFocused ? '' : `${name}${required ? '*' : ''}`)
  }, [inputFocused])

  let inputContent
  switch (type) {
    case TEXT_FIELD:
    case TEXTAREA: {
      inputContent = (
        <Input>
          {inputFocused || state.fields[name] ? (
            <InputHeading>{`${name}${required ? '*' : ''}`}</InputHeading>
          ) : null}
          <TextInput
            multiline={type === TEXTAREA}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            placeholder={placeholderText}
            placeholderTextColor="#757885"
            onChangeText={value =>
              dispatch({
                type: 'UPDATE_FIELD',
                payload: { field: name, value },
              })
            }
            value={state.fields[`${name}`]}
            style={[
              styles.input,
              Platform.OS === 'web' ? { outlineWidth: 0 } : {},
            ]}
          />
        </Input>
      )
      break
    }
    case SELECT_ONE: {
      inputContent = (
        <Input>
          <Row
            onPress={() =>
              setModalData({
                field: name,
                type: type,
                options: [...options],
              })
            }
          >
            <View>
              {inputFocused || state.fields[name] ? (
                <InputHeading>{`${name}${required ? '*' : ''}`}</InputHeading>
              ) : null}
              <TextInput
                editable={false}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                placeholder={placeholderText}
                placeholderTextColor="#757885"
                value={state.fields[name]}
                style={[
                  styles.input,
                  { flex: 1 },
                  Platform.OS === 'web' ? { outlineWidth: 0 } : {},
                ]}
              />
            </View>

            <InputSelect>
              <InputSelectImage
                source={require('../../../assets/icons/arrow-right-1.png')}
              />
            </InputSelect>
          </Row>
        </Input>
      )
      break
    }
    case SELECT_ARO: {
      inputContent = (
        <Input>
          <Row
            onPress={() =>
              navigation.navigate('AroSelect', {
                fieldName: name,
                dispatch,
              })
            }
          >
            <View>
              {inputFocused || state.fields[name] ? (
                <InputHeading>{`${name}${required ? '*' : ''}`}</InputHeading>
              ) : null}
              <TextInput
                multiline={true}
                editable={false}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                placeholder={placeholderText}
                placeholderTextColor="#757885"
                value={state.fields[name]}
                style={[
                  styles.input,
                  Platform.OS === 'web' ? { outlineWidth: 0 } : {},
                ]}
              />
            </View>

            <InputSelect>
              <InputSelectImage
                source={require('../../../assets/icons/arrow-right-1.png')}
              />
            </InputSelect>
          </Row>
        </Input>
      )
      break
    }
    case SELECT_MULTIPLE: {
      inputContent = (
        <Input>
          <SelectMultiple
            onPress={() => {
              setModalData({
                field: name,
                type: type,
                options: [...options],
                selected: state.fields[name] || [],
              })
            }}
          >
            <SelectMultipleLabel selected={state.fields[name]?.length}>
              {`${name}${required ? '*' : ''}`}
            </SelectMultipleLabel>

            <ChipCards>
              {state.fields[name]?.length
                ? state.fields[name].map((value, index) => (
                    <ChipCard
                      key={value}
                      onPress={() => {
                        const values = [...state.fields[name]]
                        values.splice(index, 1)

                        dispatch({
                          type: 'UPDATE_FIELD',
                          payload: { field: name, value: values },
                        })
                      }}
                    >
                      <ChipCardText>{value}</ChipCardText>
                      <Image
                        style={{ width: 18, height: 18, marginLeft: 8 }}
                        source={require('../../../assets/icons/Shape2x.png')}
                      />
                    </ChipCard>
                  ))
                : null}
            </ChipCards>
          </SelectMultiple>
        </Input>
      )
      break
    }
    case DATE: {
      inputContent = <Calendar field={props.field} dispatch={dispatch} />
      break
    }
    case BOOLEAN: {
      inputContent = (
        <Input>
          <Row>
            <SelectMultipleLabel selected>{name}</SelectMultipleLabel>
            <Switch
              trackColor={{ true: '#2AAD79', false: 'rgb(235, 236, 242)' }}
              onValueChange={() => {
                dispatch({
                  type: 'UPDATE_FIELD',
                  payload: { field: name, value: !state.fields[name] },
                })
              }}
              value={state.fields[name]}
            />
          </Row>
        </Input>
      )
      break
    }
    case BUTTON: {
      inputContent = (
        <Input>
          <Button>
            <WorktribeIcon name={icon} color={color} />
            <ButtonText color={color}>{name}</ButtonText>
          </Button>
        </Input>
      )
      break
    }
    case ARO_STATIC: {
      inputContent = (
        <Input>
          <UserCard rounded="top" selected={false}>
            <Avatar userId={4} size={40} radius={14} />
            <UserDetails>
              <UserName>Richard Clarkson</UserName>
              <UserTitle>Systems Administrator, Demo University</UserTitle>
            </UserDetails>
          </UserCard>
        </Input>
      )
      break
    }
    default: {
      inputContent = null
      break
    }
  }

  return inputContent
}

export default InputField

const styles = StyleSheet.create({
  input: {
    fontFamily: 'proxima-nova',
    fontSize: 17,
    color: '#0E1012',
    paddingVertical: 0,
    height: 24,
  },
})
