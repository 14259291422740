import React, { useContext } from 'react'
import { StyleSheet, StatusBar, Platform } from 'react-native'

import * as WebBrowser from 'expo-web-browser'
import SplashContainer from '../../components/shared/SplashContainer'
import TitleCard from '../../components/shared/TitleCard'
import Login from '../../components/shared/Login'
import KeyboardSpacer from '../../components/shared/KeyboardSpacer'
import { ThemeContext } from '../../contexts/ThemeContext'

WebBrowser.maybeCompleteAuthSession()

const LoginScreen = () => {
  const { typography: { Label }} = useContext(ThemeContext)
  return (
    <SplashContainer justifyContent="space-between">
      <StatusBar animated={true} barStyle="light-content" />

      <TitleCard />

      <Login />

      {Platform.OS === 'ios' && <KeyboardSpacer />}

      <Label color='#FFF' style={styles.forgotPassword}></Label>
    </SplashContainer>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  forgotPassword: {
    marginBottom: 60,
  },
})
