import React, { useContext } from 'react'
import { Pressable, View } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import type { Positions, Offset } from './section-list'
import { animationConfig, HEADING_OFFSET, ROW_HEIGHT } from './utils'
import { ThemeContext } from '../../../contexts/ThemeContext'

import { TeenyTinyButton } from '../../shared/Button'
import WorktribeIcon from '../../shared/WorktribeIcon'
import SectionItem from './SectionItem'
import { AlignedRow } from '../../shared/Layout'

interface SectionProps {
  title: string
  data: any
  positions: Animated.SharedValue<Positions>
  onDragEnd: (positions: Positions) => void
  sectionIndex: number
  offsets: Animated.SharedValue<Offset[]>
  renderItem: (item: any, index: number, recordids: string[]) => React.ReactNode
  editing: boolean
  handleAdd: (
    action: any,
    before: number,
    after: number,
    initialChanges: any
  ) => void
  recordids: string[]
  groupby: string
}

const Section: React.FC<SectionProps> = ({
  title,
  data,
  positions,
  onDragEnd,
  sectionIndex,
  offsets,
  renderItem,
  editing,
  handleAdd,
  recordids,
  groupby,
}) => {
  const style = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      top: withTiming(offsets.value[sectionIndex].yoffset!, animationConfig),
      height: HEADING_OFFSET,
      width: '100%',
      justifyContent: 'center',
    }
  })

  const {
    typography: { PropertyName, Button },
    designSystem: { colors },
  } = useContext(ThemeContext)

  const addNewItem = () => {
    const payload = Array.isArray(recordids)
      ? { recordids }
      : { value: [recordids] }

    handleAdd({ style: 'initial' }, 0, 0, {
      property: groupby,
      method: 'set',
      ...payload,
    })
  }

  return (
    <View style={{ zIndex: -sectionIndex }}>
      <Animated.View style={style}>
        <AlignedRow gap={8}>
          <PropertyName primary>{title}</PropertyName>

          <TeenyTinyButton transparentHighlight onPress={addNewItem}>
            <WorktribeIcon size={14} name="add" />
          </TeenyTinyButton>
        </AlignedRow>

        {data.length === 0 && (
          <Pressable
            onPress={addNewItem}
            style={{
              height: ROW_HEIGHT,
              width: '100%',
              position: 'absolute',
              top: HEADING_OFFSET,
              borderRadius: 20,
              backgroundColor: colors.primary[50],
              borderWidth: 2,
              borderColor: colors.primary[400],
              borderStyle: 'dashed',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AlignedRow gap={4}>
              <WorktribeIcon name="add" color="#A0A4B8" size={12} />
              <Button>Add new</Button>
            </AlignedRow>
          </Pressable>
        )}
      </Animated.View>

      {data.map((item, rowIndex) => (
        <SectionItem
          key={item.id}
          item={item}
          positions={positions}
          onDragEnd={onDragEnd}
          sectionIndex={sectionIndex}
          rowIndex={rowIndex}
          offsets={offsets}
          renderItem={renderItem}
          editing={editing}
          recordids={recordids}
        />
      ))}
    </View>
  )
}

export default Section
