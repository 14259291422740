import React, { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import type { IProject, IPeriod } from './impact-journey'
import { SIDE_WIDTH, HEADER_HEIGHT, animationConfig } from './utils'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'

interface LaneSeparatorProps {
  projects: IProject[]
  periods: IPeriod[]
  monthWidth: Animated.SharedValue<number>
}

const LaneSeparators: React.FC<LaneSeparatorProps> = ({
  projects,
  periods,
  monthWidth,
}) => {
  const {
    themeColors: { accents },
  } = useContext(ThemeContext)
  return (
    <>
      <View
        style={{
          position: 'absolute',
          width: SIDE_WIDTH,
          top: HEADER_HEIGHT + 1,
          height: 1,
          backgroundColor: accents.separator,
          zIndex: 99,
        }}
      />
      {projects.map(project => {
        const style = useAnimatedStyle(() => {
          return {
            position: 'absolute',
            width: withTiming(
              SIDE_WIDTH + monthWidth.value * periods.length,
              animationConfig
            ),
            top: project.offset! + project.height! + HEADER_HEIGHT,
            height: 1,
            backgroundColor: accents.separator,
            zIndex: 99,
          }
        })
        return <Animated.View key={project.id} style={style} />
      })}
    </>
  )
}

export default LaneSeparators
