import React, { useContext, useState } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow } from '../shared/Layout'

import { Col, Row, Grid } from 'react-native-easy-grid'
import { TouchableOpacity, View } from 'react-native'
import { TinyButton } from '../shared/Button'
import Actions from './Actions'

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 1300px;
  width: 100%;
`

const OUTCOMES = [
  {
    title: 'Formative',
    rows: [
      {
        title: 'Examination description',
        weighting: '20%',
        outcomesTested: 'R23',
        groupWork: 'Yes',
        finalAssessment: 'No',
        releaseDate: '1st May, 2019',
        deadline: '1st May, 2019',
      },
      {
        title: 'Examination description',
        weighting: '20%',
        outcomesTested: 'R23',
        groupWork: 'Yes',
        finalAssessment: 'No',
        releaseDate: '1st May, 2019',
        deadline: '1st May, 2019',
      },
      {
        title: 'Examination description',
        weighting: '20%',
        outcomesTested: 'R23',
        groupWork: 'Yes',
        finalAssessment: 'No',
        releaseDate: '1st May, 2019',
        deadline: '1st May, 2019',
      },
    ],
  },
  {
    title: 'Summative',
    rows: [
      {
        title: 'Examination description',
        weighting: '20%',
        outcomesTested: 'R23',
        groupWork: 'Yes',
        finalAssessment: 'No',
        releaseDate: '1st May, 2019',
        deadline: '1st May, 2019',
      },
      {
        title: 'Examination description',
        weighting: '20%',
        outcomesTested: 'R23',
        groupWork: 'Yes',
        finalAssessment: 'No',
        releaseDate: '1st May, 2019',
        deadline: '1st May, 2019',
      },
    ],
  },
]

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
`

const AssessmentRow = ({ assessment }) => {
  const {
    typography: { Body, H5, Label },
  } = useContext(ThemeContext)
  const [hovered, setHovered] = useState(false)
  return (
    <Row
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        paddingVertical: 16,
        paddingHorizontal: 24,
        backgroundColor: '#F4F5F9',
        borderRadius: 20,
        marginBottom: 12,
        paddingRight: 75,
        alignItems: 'center',
      }}
    >
      <Col size={2}>
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 24,
            }}
            activeOpacity={1}
          >
            <Thumbnail
              source={{
                uri: `https://source.unsplash.com/PEDWYwNfUoI/50x50`,
                cache: 'force-cache',
              }}
            />
          </TouchableOpacity>

          <View style={{ flex: 1 }}>
            <Label color="#757885">{assessment.title}</Label>
            <H5 color="#0E1012">Dissertation</H5>
          </View>
        </AlignedRow>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.weighting}</Body>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.outcomesTested}</Body>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.groupWork}</Body>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.finalAssessment}</Body>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.releaseDate}</Body>
      </Col>

      <Col>
        <Body color="#0E1012">{assessment.deadline}</Body>
      </Col>

      {hovered && <Actions />}
    </Row>
  )
}

const Assessments = ({ record }) => {
  const {
    typography: { H3, H5, Button },
  } = useContext(ThemeContext)
  return (
    <Container contentContainerStyle={{ alignItems: 'center' }}>
      <Content>
        {OUTCOMES.map(outcome => (
          <>
            <AlignedRow justifyContent="space-between">
              <AlignedRow>
                <H3>{outcome.title}</H3>
                <WorktribeIcon name="chevron-bold" />
              </AlignedRow>

              <AlignedRow>
                <WorktribeIcon size={18} color="#757885" name="filter" />
                <TinyButton style={{ marginLeft: 24 }}>
                  <Button color="#1D7A55">Add</Button>
                </TinyButton>
              </AlignedRow>
            </AlignedRow>

            <Grid
              style={{
                marginTop: 20,
                marginBottom: 36,
                flexDirection: 'column',
              }}
            >
              <Row
                style={{
                  paddingVertical: 12,
                  paddingHorizontal: 24,
                  paddingRight: 75,
                }}
              >
                <Col size={2}>
                  <H5 style={{ marginLeft: 72 }} color="#0E1012">
                    Title
                  </H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Weighting</H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Outcomes tested</H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Group work</H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Final assessment</H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Release Date</H5>
                </Col>
                <Col>
                  <H5 color="#0E1012">Deadline</H5>
                </Col>
              </Row>
              {outcome.rows.map((row, i) => (
                <AssessmentRow key={`${row.title}-${i}`} assessment={row} />
              ))}
            </Grid>
          </>
        ))}
      </Content>
    </Container>
  )
}

export default Assessments

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
