import React from 'react'
import styled from 'styled-components/native'
import WorktribeIcon from '../shared/WorktribeIcon'
import { AlignedRow } from '../shared/Layout'
import { Col } from 'react-native-easy-grid'

const Icon = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 40px;
  background-color: #fff;
  margin: 0 6px;
`

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.12)',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 16.0,
  elevation: 24,
}

const Actions = () => (
  <Col
    style={{
      position: 'absolute',
      right: 20,
    }}
  >
    <AlignedRow>
      <Icon style={shadowStyles}>
        <WorktribeIcon name="pencil-1" />
      </Icon>

      <Icon style={shadowStyles}>
        <WorktribeIcon name="messages-bubble-alternate" />
      </Icon>

      <Icon style={shadowStyles}>
        <WorktribeIcon name="more" />
      </Icon>
    </AlignedRow>
  </Col>
)

export default Actions
