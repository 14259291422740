import React, { useContext } from 'react'
import { FlatList, RefreshControl, View, StyleSheet } from 'react-native'

import ConversationItem from './ConversationItem'
import useOrientation from '../../hooks/useOrientation'
import LandscapeConversationCard from './LandscapeConversationCard'
import { ThemeContext } from '../../contexts/ThemeContext'

const ConversationFeed = props => {
  const isLandscape = useOrientation()
  const {
    themeColors: { separator },
  } = useContext(ThemeContext)

  const renderItem = ({ item, index }) => {
    if (isLandscape) {
      return <LandscapeConversationCard conversation={item} />
    }

    return (
      <ConversationItem
        index={index}
        data={props.data}
        conversation={item}
        handleItemPress={props.handleItemPress}
      />
    )
  }

  const renderSeparator = () => (
    <View style={[styles.separator, { backgroundColor: separator.light }]} />
  )

  return (
    <FlatList
      contentContainerStyle={
        !isLandscape && { paddingHorizontal: 16, paddingBottom: 15 }
      }
      data={props.data}
      renderItem={renderItem}
      onEndReached={props.onEndReached}
      keyExtractor={(item, index) => index.toString()}
      refreshControl={
        <RefreshControl
          refreshing={props.isFetching}
          onRefresh={props.onRefresh}
        />
      }
      ItemSeparatorComponent={renderSeparator}
      showsVerticalScrollIndicator={false}
    />
  )
}

export default ConversationFeed

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
  loader: {
    alignItems: 'center',
    marginBottom: 5,
  },
})
