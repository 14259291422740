import { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'

const DisconnectedPopup = () => {
  const {
    typography: { H5 },
  } = useContext(ThemeContext)

  return null

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 80,
        backgroundColor: '#ff0000',
      }}
    >
      <H5 color="#FFF" style={{ marginLeft: 10 }}>
        Reconnecting to server...
      </H5>
    </View>
  )
}

export default DisconnectedPopup
