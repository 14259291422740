import React, { useContext, useEffect, useRef, useState } from 'react'
import { Text, StyleSheet, Animated } from 'react-native'
import { useSelector } from 'react-redux'
import { AuthContext, useAuth } from '../../contexts/AuthContext'

const LABEL_HEIGHT = 30

const UsersTyping = ({ recordId, scrollY }) => {
  const { usersTyping } = useSelector(state => state.records)
  const { user } = useAuth()

  const heightAnim = useRef(new Animated.Value(0)).current

  const [label, setLabel] = useState('')

  useEffect(() => {
    if (!usersTyping?.[recordId]) return

    const usersList = usersTyping[recordId].filter(user => user !== user?.name)

    if (usersList.length) {
      if (heightAnim._value === 0) {
        transitionTo(LABEL_HEIGHT)
      }
    } else {
      transitionTo(0)
    }

    setLabel(constructLabel(usersList))
  }, [usersTyping, recordId])

  const transitionTo = to => {
    Animated.timing(heightAnim, {
      toValue: to,
      duration: 150,
      useNativeDriver: false,
    }).start()
  }

  return (
    <Animated.View
      style={[
        styles.container,
        { height: heightAnim },
        scrollY > 100 && styles.scrolledStyles,
      ]}
    >
      <Text style={styles.typing}>{label}</Text>
    </Animated.View>
  )
}

// Make a comma separated list of names
const csvOfNames = usersList => {
  let csv = ''
  for (let i = 0; i < usersList.length; i++) {
    if (i === usersList.length - 1 && usersList.length !== 1) {
      csv += ' and '
    } else if (i !== usersList.length && i !== 0) {
      csv += ', '
    }
    csv += usersList[i]
  }
  return csv
}

// Make a grammatically immaculate description for humans
const constructLabel = usersList => {
  let label = ''

  if (usersList.length === 0) {
    return label
  } else {
    if (usersList.length > 3) {
      label = usersList.length + ' people'
    } else {
      label = csvOfNames(usersList)
    }

    usersList.length > 1
      ? (label += ' are typing...')
      : (label += ' is typing...')
  }

  return label
}

export default UsersTyping

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    overflow: 'hidden',
  },
  typing: {
    fontFamily: 'proxima-nova-light',
    paddingHorizontal: 15,
    paddingTop: 5,
    fontSize: 14,
    color: '#000',
  },
  scrolledStyles: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    bottom: 0,
  },
})
