import React, { useContext, useState, useRef } from 'react'
import { View, Linking, TouchableOpacity, Image } from 'react-native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import styled from 'styled-components/native'
import WorktribeIcon from '../../shared/WorktribeIcon'
import { AlignedRow, CenteredView } from '../../shared/Layout'
import { useNavigation } from '@react-navigation/native'
import { BigButton } from '../../shared/Button'
import { useDialog, Dialog } from '../../../hooks/useDialog'
import { back } from '../../../utils/back'

const DOWNLOAD_TIMER = 5

const AppLanding = () => {
  const {
    typography: { H1, H4, Button, H2, Label },
    themeColors: { bg, accents, fonts },
  } = useContext(ThemeContext)

  const { dialogProps, createDialog, closeDialog } = useDialog()
  const [timer, setTimer] = useState(DOWNLOAD_TIMER)

  const navigation = useNavigation()

  const downloadTimer = useRef()

  const startDownload = url => {
    createDialog({
      message: `Your download will begin in ${timer}`,
      onMount: () => {
        downloadTimer.current = setInterval(() => {
          setTimer(timer => {
            if (timer === 0) {
              clearInterval(downloadTimer.current)

              Linking.openURL(url)

              closeDialog(null)

              return DOWNLOAD_TIMER
            }

            createDialog(props => {
              return {
                ...props,
                message: `Your download will begin in ${timer - 1}`,
              }
            })

            return timer - 1
          })
        }, 1000)
      },
      onUnmount: () => clearInterval(downloadTimer.current),
      actions: [
        {
          name: 'Cancel',
          primary: true,
          action: () => {
            clearTimeout(downloadTimer.current)
            closeDialog()
            setTimer(DOWNLOAD_TIMER)
          },
        },
      ],
    })
  }

  return (
    <>
      <View
        style={{
          paddingVertical: 18,
          paddingHorizontal: 34,
        }}
      >
        <AlignedRow style={{ flex: 1 }}>
          <TouchableOpacity
            style={{
              marginRight: 12,
              borderWidth: 1,
              borderColor: accents.separator,
              borderRadius: 70,
              paddingVertical: 13,
              paddingHorizontal: 15,
            }}
            onPress={back}
          >
            <WorktribeIcon color={fonts.primary} name="keyboard-arrow-right" />
          </TouchableOpacity>

          <H2 numberOfLines={1} primary>
            Downloads
          </H2>
        </AlignedRow>
      </View>

      <Container
        contentContainerStyle={{ alignItems: 'center', marginTop: 12 }}
      >
        <Content>
          <H1 style={{ marginBottom: 12 }} primary>
            Download Worktribe for Windows & Mac
          </H1>
          <H4 style={{ marginBottom: 32 }}>
            Collaborate more easily with our apps for Windows and Mac
          </H4>

          <Hero style={{ backgroundColor: bg.card }}>
            <AlignedRow>
              <CenteredView>
                <WorktribeIcon name="windows" />

                <BigButton
                  onPress={() => {
                    startDownload(
                      'https://zubanubi.com/resources/apps/Worktribe3-Dev-1.0.6%20Setup.exe'
                    )
                  }}
                  style={{ width: 335, marginTop: 32 }}
                  primary
                >
                  <AlignedRow>
                    <WorktribeIcon name="windows-sm" />
                    <Button style={{ marginLeft: 8 }} color="#FFF">
                      Download for Windows
                    </Button>
                  </AlignedRow>
                </BigButton>

                <Label style={{ marginTop: 8 }}>Windows 7, 8, 10, 11</Label>
              </CenteredView>

              <CenteredView>
                <WorktribeIcon name="apple" />

                <BigButton
                  onPress={() => {
                    startDownload(
                      'https://zubanubi.com/resources/apps/Worktribe3-Dev-1.0.6-osx-x64.dmg'
                    )
                  }}
                  style={{ width: 335, marginTop: 32 }}
                  primary
                >
                  <AlignedRow>
                    <WorktribeIcon name="apple-sm" />
                    <Button style={{ marginLeft: 8 }} color="#FFF">
                      Download for Mac
                    </Button>
                  </AlignedRow>
                </BigButton>

                <Label style={{ marginTop: 8 }}>macOS 10.11+</Label>
              </CenteredView>
            </AlignedRow>
          </Hero>

          <H1 style={{ marginBottom: 12 }} primary>
            Worktribe for Android & iOS
          </H1>
          <H4 style={{ marginBottom: 32 }}>
            Collaborate more easily with our apps for Windows and Mac
          </H4>

          <Hero style={{ backgroundColor: bg.card }}>
            <AlignedRow>
              <CenteredView>
                <TouchableOpacity
                  onPress={() =>
                    Linking.openURL(
                      'https://play.google.com/store/apps/details?id=com.worktribe.app.dev'
                    )
                  }
                >
                  <Image
                    style={{ width: 180, height: 52 }}
                    source={require('../../../../assets/googleplay.png')}
                  />
                </TouchableOpacity>

                <Label style={{ marginTop: 12 }}>Android 12.2 +</Label>
              </CenteredView>

              <CenteredView>
                <TouchableOpacity
                  onPress={() =>
                    Linking.openURL(
                      'https://apps.apple.com/us/app/worktribedev/id1548268400'
                    )
                  }
                >
                  <Image
                    style={{ width: 180, height: 52 }}
                    source={require('../../../../assets/appstore.png')}
                  />
                </TouchableOpacity>

                <Label style={{ marginTop: 12 }}>iOS 7+</Label>
              </CenteredView>
            </AlignedRow>
          </Hero>
        </Content>
      </Container>

      <Dialog dialogProps={dialogProps} close={closeDialog} />
    </>
  )
}

export default AppLanding

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 940px;
  width: 100%;
  align-items: center;
`

const Hero = styled.View`
  border-radius: 20px;
  padding: 64px 85px;
  width: 100%;
  margin-bottom: 80px;
`
