import React, { useContext, useState } from 'react'
import styled from 'styled-components/native'
import uuid from 'uuid/v4'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { TouchableOpacity, View, Platform } from 'react-native'
import { AlignedRow } from '../../shared/Layout'
import { SmallButton } from '../../shared/Button'
import WorktribeIcon from '../../shared/WorktribeIcon'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Badge from '../../shared/Badge'
import Avatar from '../../shared/Avatar'
import { bg } from 'date-fns/locale'

const exampleData = count =>
  [...Array(count)].map((d, index) => {
    return {
      id: uuid(),
      content: 'Task',
    }
  })

const columnsFromBackend = {
  [uuid()]: {
    name: 'Requested',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
  [uuid()]: {
    name: 'Accepted',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
  [uuid()]: {
    name: 'Estimated',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
  [uuid()]: {
    name: 'In Progress',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
  [uuid()]: {
    name: 'Review',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
  [uuid()]: {
    name: 'Completed',
    items: exampleData(Math.floor(Math.random() * 6)),
  },
}

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}

const Thumbnail = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-right: 12px;
`

const Kanban = ({ invert }) => {
  const {
    typography: { H3, Button, H2, Label, Caption, H5 },
    themeColors: { bg, hover },
  } = useContext(ThemeContext)

  const [columns, setColumns] = useState(columnsFromBackend)

  if (Platform.OS !== 'web') {
    return null
  }

  return (
    <View
      style={{
        backgroundColor: bg.primary,
        flex: 1,
        borderTopLeftRadius: 30,
      }}
    >
      {!invert ? (
        <View
          style={{
            paddingHorizontal: 16,
            paddingVertical: 20,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <H2>Mine</H2>

          <AlignedRow>
            <SmallButton transparent>
              <AlignedRow>
                <WorktribeIcon name="board" />
                <Button style={{ marginLeft: 8 }} color="#1D7A55">
                  Board
                </Button>
              </AlignedRow>
            </SmallButton>

            <SmallButton transparent style={{ marginLeft: 16 }}>
              <AlignedRow>
                <WorktribeIcon size={16} name="filter" />
                <Button style={{ marginLeft: 8 }} color="#757885">
                  Filter
                </Button>
              </AlignedRow>
            </SmallButton>

            <TouchableOpacity
              activeOpacity={1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 36,
                height: 36,
                borderRadius: 18,
                borderWidth: 1,
                borderColor: '#DDE1ED',
                marginLeft: 16,
              }}
            >
              <WorktribeIcon size={20} name="search-alternate" />
            </TouchableOpacity>

            <SmallButton style={{ marginLeft: 16 }} primary>
              <Button color="#FFF">Add new</Button>
            </SmallButton>

            <SmallButton style={{ marginLeft: 16 }}>
              <WorktribeIcon name="more" />
            </SmallButton>
          </AlignedRow>
        </View>
      ) : null}

      <View style={{ flexDirection: 'row', flex: 1, paddingHorizontal: 16 }}>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <View style={{ minWidth: 280, marginRight: 20 }}>
                <AlignedRow
                  style={{ marginBottom: 16 }}
                  justifyContent="space-between"
                >
                  <H3 primary>{column.name}</H3>

                  <AlignedRow>
                    <TouchableOpacity>
                      <WorktribeIcon name="add" />
                    </TouchableOpacity>

                    <TouchableOpacity style={{ marginLeft: 16 }}>
                      <WorktribeIcon name="more" />
                    </TouchableOpacity>
                  </AlignedRow>
                </AlignedRow>

                <View>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <View
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        padding: 16,
                                        borderRadius: 16,
                                        borderWidth: 1,
                                        borderColor: '#DDE1ED',
                                        marginBottom: 12,
                                        backgroundColor: snapshot.isDragging
                                          ? hover
                                          : bg.card,
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <AlignedRow
                                        justifyContent="space-between"
                                        style={{ marginBottom: 8 }}
                                      >
                                        <Label>Review request</Label>
                                        <Caption>Jan 16</Caption>
                                      </AlignedRow>

                                      <View
                                        style={{
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'flex-start',
                                          marginBottom: 8,
                                        }}
                                      >
                                        <AlignedRow>
                                          <Thumbnail
                                            source={{
                                              uri: `https://source.unsplash.com/$PEDWYwNfUoI/50x50`,
                                              cache: 'force-cache',
                                            }}
                                          />
                                          <View>
                                            <Label>{item.content}</Label>
                                            <H5 primary>BSc Chemistry</H5>
                                          </View>
                                        </AlignedRow>

                                        <Badge count={10} />
                                      </View>

                                      <AlignedRow justifyContent="space-between">
                                        <AlignedRow>
                                          <Avatar
                                            userId={2}
                                            radius={8}
                                            size={24}
                                          />
                                          <Caption style={{ marginLeft: 8 }}>
                                            Richard Clarke
                                          </Caption>
                                        </AlignedRow>

                                        <AlignedRow>
                                          <View
                                            style={{
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              paddingHorizontal: 8,
                                              paddingVertical: 3,
                                              backgroundColor:
                                                'rgba(209, 62, 62, 0.12)',
                                              borderRadius: 40,
                                            }}
                                          >
                                            <Caption color="#D13E3E">
                                              High
                                            </Caption>
                                          </View>
                                        </AlignedRow>
                                      </AlignedRow>
                                    </div>
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </View>
                      )
                    }}
                  </Droppable>
                </View>
              </View>
            )
          })}
        </DragDropContext>
      </View>
    </View>
  )
}

export default Kanban
