import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import axios from 'axios'
import { ThemeContext } from '../../contexts/ThemeContext'
import { StyleSheet, View, Platform, Text } from 'react-native'
import GridFilters from './ContentPanel/ui/GridFilters'
import { Col, Row } from 'react-native-easy-grid'
import GridRow from './ContentPanel/ui/GridRow'
import { SmallButton } from '../shared/Button'
import { Portal } from 'react-native-portalize'
import ChangesPanel from './ChangesPanel'
import Toolbar from './Toolbar'
import useSocketMethods from '../../hooks/useSocketMethods'
import EditDialog from './EditDialog'
import { AlignedRow } from '../shared/Layout'
import useSocketListener from '../../hooks/useSocketListener'
import DraggableFlatList from 'react-native-draggable-flatlist'
import styled from 'styled-components/native'
import SectionList from '../organisms/section-list/SectionList'
import useZubanubi from '../../hooks/useZubanubi'
import { useAuth } from '../../contexts/AuthContext'

const METHOD = {
  RECORD_ACTION: 'record_action',
}

const Selection = ({
  parentRecordId,
  parentrClass,
  propertyName,
  editAction,
  addAction,
  filters,
  rclass,
  controls = true,
  showHeadings = true,
  value,
  selectedTab,
  index,
  footer,
  required,
  showBubble = true,
  showFiltersIfAvailable = true,
  showAddEvent,
  groupby,
  hideheaders,
}) => {
  const {
    typography: { H5, Button, PropertyName },
    themeColors: { bg },
    designSystem: { colors },
  } = useContext(ThemeContext)

  const getZubanubiRepo = useZubanubi()
  const { user } = useAuth()

  const [data, setData] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [tableHeadings, setTableHeadings] = useState([])
  const [filterOptions, setFilterOptions] = useState({})

  const [bulkEditFields, setBulkEditFields] = useState([])
  const [bulkEdit, setBulkEdit] = useState(false)

  const [editedRowValues, setEditedRowValues] = useState({})

  const [editHistory, setEditHistory] = useState({})
  const [resetValues, setResetValues] = useState(false)

  const [dialogProps, setDialogProps] = useState(null)

  const [pendingId, setPendingId] = useState(null)

  const [showDragHandles, setShowDragHandles] = useState(false)

  useEffect(() => {
    if (resetValues) setEditedRowValues({})
  }, [resetValues])

  useEffect(() => {
    return () => setResetValues(false)
  }, [editedRowValues])

  const { record_action, record_get } = useSocketMethods()

  const currentIds = useRef([])

  const filtersActive = Object.entries(filterOptions)
    .map(([property, value]) => {
      if (Array.isArray(value.value)) {
        return value.value.map(v => {
          return {
            property,
            method: 'matches',
            value: v.recordid ?? v.name,
            type: value.type,
          }
        })
      }

      return {
        property,
        method: 'matches',
        value: value.value,
        type: value.type,
      }
    })
    .filter(item => {
      if (Array.isArray(item)) {
        return item.every(i => i.value) && item.length
      }
      return item.value
    })
    .map(item => {
      if (Array.isArray(item) && item.length > 0) {
        return item
      } else if (item.value.recordid) {
        item.value = item.value.recordid
      } else if (item.type === 'date') {
        item.value = new Date(
          item.value.getTime() - item.value.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[0]
        return item
      }

      return item
    })

  const [handleAddEvent, setHandleAddEvent] = useState(false)

  useEffect(() => {
    if (!showBubble && handleAddEvent !== showAddEvent) {
      setHandleAddEvent(showAddEvent)
      handleAdd(addAction)
    }
  }),
    [showAddEvent]

  useEffect(() => {
    ;(async function () {
      const { properties, records } = value

      if (records?.length && properties && rclass) {
        const rclassName =
          Platform.OS === 'android'
            ? rclass.toLowerCase().replace(/:space:/g, '_')
            : rclass.toLowerCase().replaceAll(' ', '_')

        setBulkEditFields(properties)

        if (groupby) {
          const groupbyfield = groupby.replace(/[{}]/g, '')

          // get all of the records or select options
          const groupbyproperty = properties[groupbyfield]

          let template
          if (groupbyproperty.type === 'record') {
            const zubanubiRepo = await getZubanubiRepo()

            const {
              data: { data },
            } = await axios.get(`https://www.zubanubi.com/zapi.php`, {
              params: {
                method: 'record_find',
                wtauth: true,
                wtenv: zubanubiRepo,
                debugtoken: `${user?.userid}.quirkafleeg`,
                rclassname: properties[groupbyfield].rclasses,
              },
            })

            template = data.records
              .filter(({ title }) => title)
              .map(record => ({
                title: record.title,
                recordids: [record.recordid],
                data: [],
              }))
          } else {
            template = groupbyproperty.options.map(option => ({
              title: option.name,
              recordids: option.name,
              data: [],
            }))
          }

          const groupedRecords = records.reduce((acc, curr) => {
            // find the groupbyfield
            const foundProperty = curr.properties.find(
              property => property.name === groupbyfield
            )

            let heading = 'Other'
            let ids = []
            if (foundProperty?.value) {
              if (foundProperty.value.records?.length) {
                // grouped by records
                heading = foundProperty.value.records
                  .map(record => record.title)
                  .join(', ')

                ids = foundProperty.value.records.map(record => record.recordid)
              } else {
                // grouped by select value
                heading = foundProperty.value[0]
                ids = foundProperty.value[0]
              }
            }

            // format record
            const record = {
              ...curr,
              properties: curr.properties
                .filter(prop => prop.name !== groupbyfield)
                .map(prop => ({
                  ...prop,
                  ...properties[prop.name],
                })),
              product: rclassName,
            }

            const templateSection = acc.find(group => group.title === heading)

            if (templateSection) {
              templateSection.recordids = ids
              templateSection.data.push(record)
            }

            return acc
          }, template)

          setData(groupedRecords)

          createHeadings(properties, ([groupby]) => groupby !== groupbyfield)
        } else {
          setData(
            records.map(record => {
              return {
                ...record,
                properties: record.properties.map(prop => ({
                  ...prop,
                  ...properties[prop.name],
                })),
                product: rclassName,
              }
            })
          )

          createHeadings(properties)
        }

        currentIds.current = records.map(record => record.recordid)
      } else {
        setData([])
      }
    })()
  }, [rclass, value, groupby])

  const createHeadings = (properties, filter = () => true) => {
    setTableHeadings(() => {
      return Object.entries(properties)
        .filter(filter)
        .map(([_, { name, type, style }]) => ({
          name,
          type,
          style,
        }))
    })
  }

  const previousFilter = useRef()

  useEffect(() => {
    async function filterRecords(filters) {
      const filtersWithoutType = filters
        .map(filter => {
          delete filter.type
          return filter
        })
        .flat()

      try {
        record_get({
          rclassname: parentrClass,
          recordid: parentRecordId,
          includecards: true,
          includeactions: true,
          returnsections: [selectedTab],
          filters: {
            [propertyName]: filtersWithoutType,
          },
        })
      } catch (e) {
        console.warn(e)
      } finally {
        previousFilter.current = filtersWithoutType
      }
    }

    if (previousFilter.current || Object.keys(filtersActive).length > 0) {
      filterRecords(filtersActive)
    }
  }, [filterOptions, parentrClass, parentRecordId, propertyName, selectedTab])

  const handleEditedRow = (
    recordid,
    editaction,
    field,
    newValue,
    oldValue,
    toArray
  ) => {
    setEditedRowValues(values => {
      return {
        ...values,
        [recordid]: {
          ...values[recordid],
          [field]: {
            newValue,
            oldValue,
            editaction,
            toArray,
          },
        },
      }
    })
  }

  const selectAll = useCallback(() => {
    let ids
    if (groupby) {
      ids = Object.entries(data)
        .map(([_, { data }]) => data.map(d => d.recordid))
        .flat()
    } else {
      ids = data.map(record => record.recordid)
    }

    setSelectedIds(ids)
  }, [data, groupby])

  const clearSelection = useCallback(() => {
    setSelectedIds([])
  }, [])

  const handlePost = () => {
    Object.entries(editedRowValues).forEach(([recordid, changes]) => {
      let result = []
      Object.entries(changes).forEach(
        ([property, { editaction, newValue, toArray }]) => {
          if (result[editaction]) {
            result[editaction] = [
              ...result[editaction],
              { property, newValue, toArray },
            ]
          } else {
            result[editaction] = [{ property, newValue, toArray }]
          }
        }
      )

      Object.entries(result).forEach(([editaction, details]) => {
        try {
          record_action({
            recordid: recordid,
            rclassname: rclass,
            actionname: editaction,
            changes: Object.entries(details).map(
              ([_, { property, newValue, toArray }]) => {
                if (Array.isArray(newValue)) {
                  return {
                    property,
                    method: `set`,
                    recordids: newValue.map(v => v.recordid),
                  }
                }

                return {
                  property,
                  method: `set`,
                  value: toArray ? [newValue] : newValue,
                }
              }
            ),
          })
        } catch (e) {
          console.warn(e)
        } finally {
          setBulkEdit(false)
        }
      })
    })

    setEditHistory(previousHistory => {
      return {
        [new Date()]: editedRowValues,
        ...previousHistory,
      }
    })

    setEditedRowValues({})
  }

  const handleDelete = ids => {
    if (!editAction) return

    const idsToDelete = Array.isArray(ids) ? ids : [ids]

    record_action({
      recordid: parentRecordId,
      rclassname: parentrClass,
      actionname: editAction,
      changes: [
        {
          property: propertyName,
          method: `delete`,
          recordids: idsToDelete,
        },
      ],
    })
  }

  const handleDragDropped = (from, to, dragData, data) => {
    if (from === to) return

    let position = ''

    // get what was picked up
    const pickedRecordId = data[from].recordid

    position =
      from < to
        ? 'after ' + data[to].recordid.toString()
        : 'before ' + data[to].recordid.toString()

    record_action({
      recordid: parentRecordId,
      rclassname: parentrClass,
      actionname: 'Edit',
      changes: [
        {
          property: propertyName,
          method: 'link',
          recordids: [pickedRecordId],
          position,
        },
      ],
    })
  }

  const handleAdd = (action, before = 0, after = 0, initialChanges) => {
    const { addproperties2, style, actionname, buttonlabel } = action

    switch (style) {
      case 'inline':
      case 'initial': {
        record_action({
          recordid: parentRecordId,
          rclassname: parentrClass,
          actionname: addAction.actionname,
          changes: [
            {
              property: propertyName,
              method: 'add',
              position: before
                ? `before ${before}`
                : after
                ? `after ${after}`
                : `start`,
              changereference: propertyName,
              changes: initialChanges ? [initialChanges] : [],
            },
          ],
        })

        break
      }
      default: {
        setDialogProps({
          recordid: parentRecordId,
          rclassname: parentrClass,
          name: buttonlabel,
          actionname,
          property: propertyName,
          method: 'add',
          dialog: {
            items: addproperties2,
            settings: {
              dialogbuttonlabel: buttonlabel,
            },
          },
        })

        break
      }
    }
  }

  const discardPending = recordid => {
    handleDelete([recordid])
    setEditedRowValues({})
  }

  useSocketListener(
    useCallback(
      message => {
        const {
          data: { newrecordids },
          method,
        } = JSON.parse(message.data)

        if (method === METHOD.RECORD_ACTION) {
          setPendingId(newrecordids?.[propertyName])
        }
      },
      [setPendingId, propertyName]
    )
  )

  useEffect(() => {
    if (!pendingId) return

    const updatedData = [...data]

    if (groupby) {
      let groupByIndex,
        pendingIndex = 0

      for (let i = 0; i < updatedData.length; i++) {
        const { data } = updatedData[i]
        pendingIndex = data.findIndex(item => item.recordid === pendingId)
        if (pendingIndex > -1) {
          groupByIndex = i
          break
        }
      }

      if (groupByIndex > -1 && pendingIndex > -1) {
        updatedData[groupByIndex]['data'][pendingIndex] = {
          ...updatedData[groupByIndex]['data'][pendingIndex],
          pending: true,
        }

        setData(updatedData)
        setPendingId(null)
      }
    } else {
      const pendingIndex = updatedData.findIndex(
        item => item.recordid === pendingId
      )

      if (pendingIndex > -1) {
        updatedData[pendingIndex] = {
          ...updatedData[pendingIndex],
          pending: true,
        }

        setData(updatedData)
        setPendingId(null)
      }
    }
  }, [data, pendingId, groupby])

  const addProperty = (before = 0, after = 0, initialChanges) => {
    record_action({
      recordid: parentRecordId,
      rclassname: parentrClass,
      actionname: addAction.actionname,
      changes: [
        {
          property: propertyName,
          method: 'add',
          position: before
            ? `before ${before}`
            : after
            ? `after ${after}`
            : `start`,
          changereference: propertyName,
          changes: initialChanges ? [initialChanges] : [],
        },
      ],
    })
  }

  const renderItem = ({ item, drag, isActive, index }) => (
    <GridRow
      disabled={isActive}
      index={index}
      item={item}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      editing={bulkEdit || item.pending}
      handleEditedRow={handleEditedRow}
      handleDelete={parentRecordId && handleDelete}
      handleDrag={drag}
      handlePost={handlePost}
      handleAdd={handleAdd}
      handleAddProperty={addProperty}
      editedRowValues={editedRowValues}
      handleReset={setResetValues}
      onDiscard={discardPending}
      reset={resetValues}
      isActive={isActive}
      showDragHandles={showDragHandles}
      handleShowDragHandles={() => setShowDragHandles(!showDragHandles)}
    />
  )

  const renderSectionItem = (item, index, recordids) => (
    <GridRow
      index={index}
      item={item}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      editing={bulkEdit || item.pending}
      handleEditedRow={handleEditedRow}
      handleDelete={parentRecordId && handleDelete}
      handlePost={handlePost}
      handleAdd={handleAdd}
      handleAddProperty={addProperty}
      editedRowValues={editedRowValues}
      handleReset={setResetValues}
      onDiscard={discardPending}
      reset={resetValues}
      showDragHandles={showDragHandles}
      handleShowDragHandles={() => setShowDragHandles(!showDragHandles)}
      disableLink={showDragHandles}
      groupby={groupby.replace(/[{}]/g, '')}
      groupbyIds={recordids}
    />
  )

  const renderDragPlaceholder = () => (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        backgroundColor: colors.primary[50],
        borderWidth: 2,
        borderColor: colors.primary[400],
        paddingVertical: 16,
        paddingHorizontal: 12,
        alignItems: 'center',
        borderRadius: 20,
        borderStyle: 'dashed',
        paddingRight: 125,
        minHeight: 80,
        marginBottom: 10,
      }}
    />
  )

  // Whether filters are currently visible
  const showFilters =
    data?.length > 0 ||
    Object.keys(filtersActive).length > 0 ||
    previousFilter.current

  return (
    <View
      style={{
        flexShrink: 0,
        paddingHorizontal: !showBubble ? 12 : 40,
        zIndex: -index,
      }}
    >
      {!showBubble ? (
        <View>
          {!!(
            showHeadings &&
            data?.length &&
            tableHeadings.length > 1 &&
            !hideheaders
          ) && (
            <Row style={[styles.row, { paddingRight: 125, flexShrink: 0 }]}>
              <>
                <Col style={{ maxWidth: 60 }}>
                  <H5 primary> </H5>
                </Col>
              </>
              {tableHeadings.map(heading => (
                <React.Fragment key={heading.name}>
                  {heading.type === 'title' ? (
                    <Col size={2}>
                      <H5 primary>{heading.name}</H5>
                    </Col>
                  ) : ['type', 'code'].includes(heading.style) ? null : (
                    <Col>
                      <H5 primary>{heading.name}</H5>
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          )}
          {(!showHeadings || tableHeadings.length < 2) && data?.length > 0 && (
            <View style={{ marginBottom: 10 }} />
          )}
          {!!data?.length && (
            <DraggableFlatList
              autoscrollThreshold={2}
              data={data}
              onDragEnd={({ data: dragData, from, to }) => {
                // setShowDragHandles(false)
                handleDragDropped(from, to, dragData, data)
                setData(dragData)
              }}
              dragItemOverflow={true}
              keyExtractor={item => item.recordid.toString()}
              renderItem={renderItem}
              renderPlaceholder={renderDragPlaceholder}
              ItemSeparatorComponent={() => (
                <View style={{ height: 12, zIndex: -1 }} />
              )}
            />
          )}
          {selectedIds.length > 0 && (
            <Portal>
              <Toolbar
                selectedIds={selectedIds}
                selectAll={selectAll}
                fields={bulkEditFields}
                onClose={clearSelection}
                handleDelete={parentRecordId && handleDelete}
              />
            </Portal>
          )}
          {Object.keys(editedRowValues).length > 0 && bulkEdit && (
            <Portal>
              <ChangesPanel
                editedRowValues={editedRowValues}
                handlePost={handlePost}
                setResetValues={setResetValues}
              />
            </Portal>
          )}
          <EditDialog
            dialogProps={dialogProps}
            setDialogProps={setDialogProps}
          />
          {footer}
        </View>
      ) : (
        <BubbleRow>
          <Bubble
            style={[
              { backgroundColor: bg.card },
              required && {
                shadowOffset: {
                  width: -2,
                  height: 0,
                },
                shadowColor: '#F19549',
              },
            ]}
          >
            <View>
              <AlignedRow
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginBottom: showFilters ? 10 : 0,
                  zIndex: 999,
                }}
              >
                {!!propertyName && (
                  <PropertyName style={{ marginVertical: 5 }} primary>
                    {propertyName}
                  </PropertyName>
                )}
                {!propertyName && !!filters && showFilters && (
                  <FilterView
                    filters={filters}
                    setFilterOptions={setFilterOptions}
                  />
                )}

                {controls && (
                  <AlignedRow>
                    {data?.filter(item => !item.pending).length > 0 &&
                      !!editAction && (
                        <SmallButton
                          transparentHighlight
                          onPress={() => {
                            setBulkEdit(edit => {
                              if (edit) {
                                setResetValues(true)
                              }
                              return !edit
                            })
                          }}
                        >
                          <Button color="#1D7A55">
                            {bulkEdit ? `Finish bulk edit` : `Bulk edit`}
                          </Button>
                        </SmallButton>
                      )}

                    {!!addAction && (
                      <>
                        <SmallButton
                          transparentHighlight
                          style={{ marginLeft: 12 }}
                          onPress={() => handleAdd(addAction)}
                        >
                          <Button color="#1D7A55">
                            {addAction?.buttonlabel}
                          </Button>
                        </SmallButton>
                      </>
                    )}
                  </AlignedRow>
                )}
              </AlignedRow>

              {!!propertyName && !!filters && showFilters && (
                <FilterView
                  filters={filters}
                  setFilterOptions={setFilterOptions}
                />
              )}

              {groupby ? (
                <>
                  {!!data?.length && (
                    <>
                      {!!(
                        showHeadings &&
                        data?.length &&
                        tableHeadings.length > 1 &&
                        !hideheaders
                      ) && (
                        <Row style={[styles.row, { paddingRight: 125 }]}>
                          <>
                            <Col style={{ maxWidth: 60 }}>
                              <H5 primary> </H5>
                            </Col>
                          </>
                          {tableHeadings.map(heading => (
                            <React.Fragment key={heading.name}>
                              {heading.type === 'title' ? (
                                <Col size={2}>
                                  <H5 primary>{heading.name}</H5>
                                </Col>
                              ) : ['type', 'code'].includes(
                                  heading.style
                                ) ? null : (
                                <Col>
                                  <H5 primary>{heading.name}</H5>
                                </Col>
                              )}
                            </React.Fragment>
                          ))}
                        </Row>
                      )}

                      {(!showHeadings || tableHeadings.length < 2) &&
                        data?.length > 0 && (
                          <View style={{ marginBottom: 10 }} />
                        )}

                      <SectionList
                        records={data}
                        renderItem={renderSectionItem}
                        groupby={groupby.replace(/[{}]/g, '')}
                        rclass={rclass}
                        parentRecordId={parentRecordId}
                        parentrClass={parentrClass}
                        name={propertyName}
                        editaction={editAction}
                        editing={showDragHandles}
                        handleAdd={handleAdd}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {!!(
                    showHeadings &&
                    data?.length &&
                    tableHeadings.length > 1
                  ) && (
                    <Row style={[styles.row, { paddingRight: 125 }]}>
                      <>
                        <Col style={{ maxWidth: 60 }}>
                          <H5 primary> </H5>
                        </Col>
                      </>
                      {tableHeadings.map(heading => (
                        <React.Fragment key={heading.name}>
                          {heading.type === 'title' ? (
                            <Col size={2}>
                              <H5 primary>{heading.name}</H5>
                            </Col>
                          ) : ['type', 'code'].includes(
                              heading.style
                            ) ? null : (
                            <Col>
                              <H5 primary>{heading.name}</H5>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                  )}

                  {(!showHeadings || tableHeadings.length < 2) &&
                    data?.length > 0 && <View style={{ marginBottom: 10 }} />}

                  {!!data?.length && (
                    <DraggableFlatList
                      data={data}
                      onDragEnd={({ data: dragData, from, to }) => {
                        // setShowDragHandles(false)
                        handleDragDropped(from, to, dragData, data)
                        setData(dragData)
                      }}
                      dragItemOverflow={true}
                      keyExtractor={item => item.recordid.toString()}
                      renderPlaceholder={renderDragPlaceholder}
                      renderItem={renderItem}
                      style={{ overflow: 'visible' }}
                      ItemSeparatorComponent={() => (
                        <View style={{ height: 12, zIndex: -1 }} />
                      )}
                    />
                  )}
                </>
              )}

              {selectedIds.length > 0 && (
                <Portal>
                  <Toolbar
                    selectedIds={selectedIds}
                    selectAll={selectAll}
                    fields={bulkEditFields}
                    onClose={clearSelection}
                    handleDelete={parentRecordId && handleDelete}
                  />
                </Portal>
              )}

              {Object.keys(editedRowValues).length > 0 && bulkEdit && (
                <Portal>
                  <ChangesPanel
                    editedRowValues={editedRowValues}
                    handlePost={handlePost}
                    setResetValues={setResetValues}
                  />
                </Portal>
              )}

              <EditDialog
                dialogProps={dialogProps}
                setDialogProps={setDialogProps}
              />
              {footer}
            </View>
          </Bubble>
        </BubbleRow>
      )}
    </View>
  )
}

const FilterView = ({ filters, setFilterOptions }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        zIndex: 999,
      }}
    >
      <GridFilters filterHandler={setFilterOptions} filters={filters} />
    </View>
  )
}

export default Selection

const styles = StyleSheet.create({
  row: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
})

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  padding: 24px;
  border-radius: 16px;
  min-width: 748px;
`
