import React, { useContext, useRef } from "react"
import { Animated, Easing } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from "./Layout"
import WorktribeIcon from "./WorktribeIcon"
import { Portal } from "react-native-portalize"

const colorMap = {
  green: '#2AAD79',
  red: '#E94545'
}

const Container = styled(Animated.View)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 31px;
  border-radius: 50px;
`

const Notification = ({ notification }) => {
  const { message, color } = notification

  const value = useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    Animated.sequence([
      Animated.timing(value, {
        toValue: 1,
        duration: 0,
        easing: Easing.ease,
      }),
      Animated.timing(value, {
        toValue: 1,
        duration: 2000,
        easing: Easing.ease,
      }),
      Animated.timing(value, {
        toValue: 0,
        duration: 500,
        easing: Easing.ease,
      })
    ]).start()
  }, [notification]);

  const opacity = value

  const { typography: { Subtitle }} = useContext(ThemeContext)
  const iconName = color === 'green' ? 'notification-check' : 'remove-circle'
  
  return (
    <Portal>
      <Container style={{ backgroundColor: colorMap[color], opacity }}>
        <AlignedRow>
          <WorktribeIcon name={iconName} />

          <Subtitle color='#FFF' style={{ marginLeft: 8 }}>
            {message}
          </Subtitle>
        </AlignedRow>
      </Container>
    </Portal>
  )
}

export default React.memo(Notification)