import React, { useEffect } from 'react'
import { Text, Image, View, StyleSheet, Pressable } from 'react-native'

import * as Google from 'expo-auth-session/providers/google'
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth'
import { AntDesign } from '@expo/vector-icons'
import { MaterialCommunityIcons } from '@expo/vector-icons'
// import jwt_decode from 'jwt-decode'
import env from '../../../env'

const SingleSignOnWeb = ({ onSignIn, onError }) => {
  // Google
  const [_, response, promptAsync] = Google.useAuthRequest({
    webClientId:
      '407505106364-213iih2lk1qlejetul795s2vlnq2p5nf.apps.googleusercontent.com',
    responseType: 'id_token',
  })

  useEffect(() => {
    if (response?.type === 'success') {
      const { params } = response
      // const userInfo = jwt_decode(params.id_token)
      // console.log(userInfo)

      onSignIn({
        provider: 'google',
        idToken: params.id_token,
      })
    } else if (response?.type === 'error') {
      onError({ provider: 'google' })
    }
  }, [response])

  // Apple
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC)

  const onPressAppleButton = () => {
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: 'com.worktribe.app-dev.service',
        scope: 'email name',
        redirectURI: env.base_url,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      },
      onSuccess: userInfo => {
        const { authorization, user } = userInfo
        onSignIn({
          provider: 'apple',
          idToken: authorization.id_token,
          email: user?.email,
          givenName: user?.name.firstName,
          familyName: user?.name.lastName,
        })
      },
      onError: error => {
        error.provider = 'apple'
        onError(error)
      },
    })
  }

  return (
    <View style={styles.container}>
      <AppleSignInButton onPress={onPressAppleButton} />
      <FacebookSignInButton />
      <GoogleSignInButton onPress={promptAsync} />
      <TwitterSignInButton />
    </View>
  )
}

export default SingleSignOnWeb

const SignInButton = ({ children, onPress, containerStyle }) => {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        styles.button,
        containerStyle,
        { opacity: pressed ? 0.8 : 1 },
      ]}
    >
      {children}
    </Pressable>
  )
}

const GoogleSignInButton = ({ onPress }) => (
  <SignInButton onPress={onPress} containerStyle={{ backgroundColor: '#FFF' }}>
    <Image
      style={styles.googleIcon}
      source={require('../../../assets/google-logo.png')}
    />
    <Text style={[styles.text, { color: '#344054' }]}>Sign in with Google</Text>
  </SignInButton>
)

const AppleSignInButton = ({ onPress }) => (
  <SignInButton
    onPress={onPress}
    containerStyle={{ backgroundColor: '#000000' }}
  >
    <AntDesign name="apple1" size={24} color="white" />
    <Text style={[styles.text, { color: '#FFF', marginLeft: 12 }]}>
      Sign in with Apple
    </Text>
  </SignInButton>
)

const FacebookSignInButton = () => (
  <SignInButton
    onPress={() => {}}
    containerStyle={{ backgroundColor: '#1877F2' }}
  >
    <MaterialCommunityIcons name="facebook" size={24} color="white" />
    <Text style={[styles.text, { color: '#FFF', marginLeft: 12 }]}>
      Sign in with Facebook
    </Text>
  </SignInButton>
)

const TwitterSignInButton = () => (
  <SignInButton
    onPress={() => {}}
    containerStyle={{ backgroundColor: '#1DA1F2' }}
  >
    <AntDesign name="twitter" size={24} color="white" />
    <Text style={[styles.text, { color: '#FFF', marginLeft: 12 }]}>
      Sign in with Twitter
    </Text>
  </SignInButton>
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 13,
  },
  button: {
    flexDirection: 'row',
    height: 52,
    borderRadius: 36,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 8,
  },
  text: {
    fontFamily: 'proxima-nova',
    fontWeight: 500,
    fontSize: 16,
  },
  googleIcon: {
    marginRight: 12,
    width: 24,
    height: 24,
  },
  appleIcon: {
    marginRight: 4,
    marginTop: -5,
    fontSize: 28,
    color: '#222222',
  },
})
