import React, { useContext, useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'
import styled from 'styled-components/native'
import Avatar from '../../components/shared/Avatar'
import { AlignedRow } from '../../components/shared/Layout'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import { ThemeContext } from '../../contexts/ThemeContext'
import withLayout from '../../HOC/withLayout'
import useUsers from '../../hooks/useUsers'

const DATA = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    title: 'First Item',
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    title: 'Second Item',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    title: 'Third Item',
  },
]

interface BecomeScreenProps {}

const UserWithRoles = ({ coverid, recordid, properties, title, index }) => {
  const [roles, setRoles] = useState(null)

  const {
    typography: { Body },
  } = useContext(ThemeContext)

  useEffect(() => {
    const { value } = properties[0]

    if (value) {
      const { records } = value

      const roles = records.reduce((acc, curr) => {
        const properties = curr.properties
          .filter(prop => prop.name === 'Role')
          .map(prop => prop.label)

        if (properties) acc.push(properties[0])
        return acc
      }, [])

      if (roles) {
        setRoles(roles.join(', '))
      }
    }
  }, [properties])

  return (
    <UserContainer>
      <AlignedRow>
        <Avatar userId={index + 1} size={36} />

        <Body style={{ marginLeft: 8 }} primary>
          {title} ({recordid})
        </Body>
      </AlignedRow>

      <AlignedRow>
        <Body style={{ marginRight: 8 }}>{roles}</Body>
        <WorktribeIcon name="arrow-right" />
      </AlignedRow>
    </UserContainer>
  )
}

const BecomeScreen: React.FC<BecomeScreenProps> = ({}) => {
  const {
    typography: { H2 },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  const users = useUsers()

  const renderItem = ({ item, index }) => (
    <UserWithRoles {...item} index={index} />
  )

  return (
    <Container contentContainerStyle={{ alignItems: 'center', marginTop: 12 }}>
      <Content>
        <H2 style={{ marginBottom: 12 }} primary>
          Become
        </H2>

        <FlatList
          contentContainerStyle={{
            borderRadius: 16,
            backgroundColor: bg.card,
            marginBottom: 30
          }}
          data={users}
          renderItem={renderItem}
          keyExtractor={user => user.userid}
          ItemSeparatorComponent={() => (
            <View style={{ height: 1, backgroundColor: accents.separator }} />
          )}
        />
      </Content>
    </Container>
  )
}

export default withLayout(BecomeScreen)

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 694px;
  width: 100%;
`

const UserContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`
