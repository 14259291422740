import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AppState } from 'react-native'
import { AuthContext, useAuth } from '../contexts/AuthContext'
import { APIContext } from '../contexts/APIContext'
import { BadgeContext } from '../contexts/BadgeContext'
import { togglePush } from '../store/actions/records'
import usePagedComments from './usePagedComments'
import { updateRecords } from '../store/actions/records'
import { useDispatch } from 'react-redux'
import useSocket from './useSocket'

const commentList = React.createRef()

const useComments = record => {
  const recordId = record?.id

  const dispatch = useDispatch()

  const { comments, fetchMore, noMoreComments, setComments } =
    usePagedComments(record)

  const [comment, setComment] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [timeOfLastUserTypingEmission, setTimeOfLastUserTypingEmission] =
    useState(null)
  const [typingTimer, setTypingTimer] = useState(null)
  const [scrollY, setScrollY] = useState(0)

  const appState = useRef(AppState.currentState)

  const socket = useSocket()
  const { user } = useAuth()

  const {
    lookAtRecord,
    getRecordComments,
    addCommentToRecord,
    enablePushNotifications,
    disablePushNotifications,
  } = useContext(APIContext)

  const { reduceMessagesBadgeNumber } = useContext(BadgeContext)[1]

  useEffect(() => {
    const eventName = `user:${user?.userid}|record:${recordId}|comment`

    if (socket && recordId) {
      try {
        socket.on(eventName, handleComment)
      } catch (e) {
        console.warn(e)
      }
    }

    return () => {
      if (socket) {
        socket.off(eventName)
      }
    }
  }, [recordId, comments])

  useEffect(() => {
    setComment('')
  }, [recordId])

  useEffect(() => {
    async function lookAt() {
      try {
        const { rclass, id } = record
        await lookAtRecord(rclass, id)
      } catch (e) {
        console.warn(e)
      }
    }

    if (record?.unseen_by_me > 0 || record?.looked_at === null) {
      reduceMessagesBadgeNumber()
      dispatch(
        updateRecords({
          ...record,
          unseen_by_me: 0,
          looked_at: new Date(),
        })
      )
      void lookAt()
    }
  }, [record])

  const _handleAppStateChange = useCallback(
    async nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        const allComments = [...comments]
        const latestComment = allComments.shift()

        if (!latestComment) {
          return
        }

        try {
          const response = await getRecordComments(record.rclass, record.id, {
            'above-id': latestComment.id,
          })

          if (response.data.comments.length) {
            setComments([...response.data.comments, ...comments])
          }
        } catch (e) {
          console.warn(e)
        }
      }

      appState.current = nextAppState
    },
    [comments]
  )

  useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange)
    return () => AppState.removeEventListener('change', _handleAppStateChange)
  }, [_handleAppStateChange])

  const messageIdExistsInMessages = message_id => {
    return comments.findIndex(message => message.id === message_id) !== -1
  }

  const handleComment = data => {
    const { comment } = data

    // Build a message object with just the keys we want
    const socketComment = {
      id: comment.id, // The database ID of the message
      user_id: comment.user_id,
      user: comment.user,
      record_id: data.record_id,
      is_change: comment.is_change,
      reactions_summary: comment.reactions_summary,
      text: comment.text,
      created_at: comment.created_at,
      slideIn: false, // Set to false as we do not want updates to re-trigger slide in
    }

    if (
      data.uuid &&
      comments.findIndex(comment => comment.uuid === data.uuid) !== -1
    ) {
      // It's a message that we sent from this device

      // Use the UUID to update the database ID on the message
      // (this is crucial for later interactions with the message)
      const newComments = comments.map(comment => {
        if (comment.uuid && comment.uuid === data.uuid) {
          comment.id = socketComment.id
          comment.slideIn = false
        }
        return comment
      })

      setComments(newComments)
    } else if (messageIdExistsInMessages(socketComment.id)) {
      // It's an update to a message so replace the item in messages array
      const newComments = comments.map(comment => {
        if (comment.id === socketComment.id) {
          // This is the item we are replacing
          return socketComment
        }
        return comment
      })

      setComments(newComments)
    } else {
      // Is new message that we do not have in the interface
      socketComment.slideIn = true
      setComments(prevState => [socketComment, ...prevState])

      dispatch(
        updateRecords({
          ...record,
          latest_comment: socketComment,
        })
      )
    }
  }

  const userStoppedTyping = () => {
    clearTimeout(typingTimer)
    setIsTyping(false)
    socket?.emit('user_stopped_typing', {
      user_id: user.userid,
      user_name: user?.name,
      record_id: record.id,
    })
  }

  const commentChanged = message => {
    clearTimeout(typingTimer)
    setTypingTimer(setTimeout(userStoppedTyping, 4000))

    let needToEmit = false

    if (!isTyping) {
      needToEmit = true
    } else if (isTyping && timeOfLastUserTypingEmission) {
      const timeSinceLastEmission = new Date() - timeOfLastUserTypingEmission
      if (timeSinceLastEmission > 8000) {
        // The user has been continuously typing for a while now so just to stop
        // the socket server assuming we're dead, let's send another ping
        needToEmit = true
      }
    }

    if (needToEmit) {
      let userIds = []
      Object.values(record.users_by_relationship).forEach(relations => {
        relations.map(user => userIds.push(user.id))
      })

      if (socket) {
        socket.emit('user_is_typing', {
          user_id: user.userid,
          user_name: user?.name,
          record_id: record.id,
          record_user_ids: Array.from(new Set(userIds)), // The other users in the convo (not me)
        })
        setTimeOfLastUserTypingEmission(new Date())
      }
    }
    setIsTyping(true)
    setComment(message)
  }

  const sendComment = () => {
    if (!comment) return

    const uuid = `m-${user.userid}-${record.id}-${Date.now()}`

    const newComment = {
      id: undefined, // This will get defined when we receive the socket message containing the database ID
      uuid: uuid,
      user_id: user.userid,
      user: {
        id: user.userid,
        name: user?.name,
      },
      record_id: record.id,
      is_change: false,
      text: comment,
      reactions_summary: [],
      created_at: new Date(),
      slideIn: true,
    }

    setComment('')

    const latestMessages = [newComment, ...comments]

    dispatch(
      updateRecords({
        ...record,
        latest_comment: newComment,
      })
    )

    setComments(latestMessages)
    userStoppedTyping()

    commentList.current.scrollToOffset({ animated: false, y: 0 })

    // Posting a Message to a Conversation
    addCommentToRecord(record.rclass, record.id, comment, uuid)
      .then(response => {
        if (response.status !== 201) {
          throw new Error('Send message response was not 201')
        }
      })
      .catch(error => {
        console.warn(error)
        const failedMessage = latestMessages.find(element => element)
        failedMessage.error = true
      })
  }

  const togglePushNotifications = useCallback(async () => {
    const action = record.push_notifications_enabled
      ? disablePushNotifications
      : enablePushNotifications

    try {
      const response = await action(record.rclass, record.id)
      if (response.status === 200) {
        const { data } = response
        dispatch(togglePush(data))
      }
    } catch (e) {
      console.log(e)
    }
  }, [record, disablePushNotifications, enablePushNotifications])

  return {
    comment,
    setComment,
    comments,
    setComments,
    noMoreComments,
    fetchMore,
    commentList,
    scrollY,
    setScrollY,
    sendComment,
    commentChanged,
    togglePushNotifications,
  }
}

export default useComments
