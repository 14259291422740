import React from 'react'
import { Image, Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import Avatar from './Avatar'
import { AlignedRow } from './Layout'
import * as RootNavigation from '../../../src/services/RootNavigation'

// ummmmmmmm
const JOB_TITLES = [
  'Chocolate Beer Specialist',
  'Shredded Cheese Authority',
  'Chief of Unicorn Division',
  'Smarties Export',
  'Rasputin Impersonator',
  'Car Behaviour Consultant',
  'Ex-Moonshiner',
  'Pork Rind Expert',
  'Bread Scientist',
  'Bear Biologist and Paperfolder',
]

const ProfileCard = ({ user }) => (
  <View style={styles.container}>
    <View style={styles.profileWrap}>
      <Avatar userId={user.id} size={56} radius={18} />
      <View style={styles.profileBody}>
        <Text style={styles.name}>{user.name}</Text>
        <Text style={styles.job}>{JOB_TITLES[user.id - 1]}</Text>
        <AlignedRow style={styles.actions}>
          <TouchableOpacity
            style={styles.button}
            onPress={() =>
              RootNavigation.navigate('Profile', { profile: user })
            }
          >
            <Text style={styles.buttonText}>Profile</Text>
          </TouchableOpacity>
          <AlignedRow style={styles.icons}>
            <Image
              style={styles.icon}
              source={require('../../../assets/icons/phone.png')}
            />
            <Image
              style={styles.icon}
              source={require('../../../assets/icons/messenger.png')}
            />
          </AlignedRow>
        </AlignedRow>
      </View>
    </View>
  </View>
)

export default ProfileCard

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 16,
    marginVertical: 10,
    flexDirection: 'row',
  },
  profileWrap: {
    flexDirection: 'row',
  },
  profileBody: {
    marginLeft: 12,
  },
  name: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 22,
    color: '#000000',
  },
  job: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
    color: '#757885',
  },
  actions: {
    marginTop: 13,
  },
  button: {
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#757885',
    paddingHorizontal: 16,
    paddingVertical: 7,
  },
  buttonText: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 22,
    color: '#757885',
  },
  icons: {
    marginLeft: 13,
  },
  icon: {
    marginHorizontal: 16,
  },
})
