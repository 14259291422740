import {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  RefObject,
} from 'react'
import {
  View,
  TextInput,
  Pressable,
  Platform,
  ScrollView,
  FlatList,
} from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components/native'
import WorktribeIcon from '../../shared/WorktribeIcon'

import type { IItem, IItemProperty } from '../../../interfaces/record'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { AlignedRow } from '../../shared/Layout'
import HoverableOpacity from '../../shared/HoverableOpacity'

import useRecordAutoComplete from '../../../hooks/useRecordAutoComplete'
import useModalOutsideAlerter from '../../../hooks/useModalOutsideAlerter'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter'
import Animated from 'react-native-reanimated'

const RECORD_TYPE = 'record'
const SELECT_TYPE = 'select'
const STATUS_TYPE = 'status'

interface InlineSelectProps {
  item: IItem
  value: any
  onChange: Function
  modal?: boolean
  optionBackgroundColor?: string
  formValid?: boolean
  scrollView: RefObject<Animated.ScrollView>
  scrollY: Animated.SharedValue<number>
}

const InlineSelect: React.FC<InlineSelectProps> = ({
  item,
  value,
  onChange,
  modal,
  optionBackgroundColor,
  formValid,
  scrollView,
  scrollY,
}) => {
  const { property, required }: { property: IItemProperty; required: string } =
    item

  const {
    type,
    renderer,
    name,
    rclasses,
    multiple,
    pick_conditions,
    recordids,
    options = [],
  } = property

  const [focused, setFocused] = useState<boolean>(false)
  const [search, onChangeSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState(() => {
    if (!value.value) return []

    switch (type) {
      case SELECT_TYPE: {
        // get index from options
        if (Array.isArray(value.value)) {
          return value?.value?.map(option => ({
            recordid: options.findIndex(opt => opt?.name === option),
            name: option,
          }))
        } else {
          return [
            {
              recordid: options.findIndex(opt => opt?.name === value.value),
              name: value.value,
            },
          ]
        }
      }
      case STATUS_TYPE: {
        // find the status in the options
        const selectedStatus = options.find(
          status => status.name === value.value
        )

        const statusId = options.findIndex(
          status => status.name === selectedStatus.name
        )

        return [
          {
            recordid: statusId,
            ...selectedStatus,
          },
        ]
      }
      case RECORD_TYPE: {
        return value.value
      }
      default: {
        return []
      }
    }
  })

  const [showSelectList, setShowSelectList] = useState<boolean>(false)

  const data = options?.length
    ? useMemo(
        () =>
          options.map((option, index) => ({
            recordid: index,
            ...option,
          })),
        [name]
      )
    : useRecordAutoComplete(rclasses, search, {
        pick_conditions,
        recordids,
      })

  const valid = useMemo(
    () => !(required === 'true' && !selectedItems.length),
    [selectedItems, required]
  )

  const closeSelectList = useCallback(() => {
    setShowSelectList(false)
  }, [])

  const selectItem = useCallback(
    record => {
      setSelectedItems(items => {
        let result

        if (items.map(item => item.recordid).includes(record.recordid)) {
          result = items.filter(item => item.recordid !== record.recordid)
        } else {
          if (multiple) {
            result = [...items, record]
          } else {
            result = [record]

            closeSelectList()
          }
        }

        onChange(result)

        return result
      })
    },
    [closeSelectList]
  )

  const removeItem = useCallback(recordid => {
    setSelectedItems(items => {
      const filteredItems = items.filter(item => item.recordid !== recordid)

      onChange(filteredItems)

      return filteredItems
    })
  }, [])

  const removeAll = useCallback(() => {
    setSelectedItems(() => {
      onChange([])
      closeSelectList()
      return []
    })
  }, [])

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const displayLabel = useMemo(() => {
    if (type === RECORD_TYPE) {
      return selectedItems.map(({ title }) => title).join(', ')
    } else if (type === SELECT_TYPE) {
      return selectedItems.map(({ name }) => name).join(', ')
    } else if (type === STATUS_TYPE) {
      const status = selectedItems.map(({ name }) => name)
      return status[0]
    } else {
      return ''
    }
  }, [selectedItems, type])

  const {
    typography: { Body, Label, Caption },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const outsideHandler = closeSelectList
  const wrapperRef = useRef(null)
  modal
    ? useModalOutsideAlerter(wrapperRef, outsideHandler)
    : useOutsideAlerter(wrapperRef, outsideHandler)

  switch (renderer) {
    case 'radiorow': {
      return (
        <RadioRow
          options={data}
          onSelect={selectItem}
          selectedItems={selectedItems}
          valid={valid}
          optionBackgroundColor={optionBackgroundColor}
        />
      )
    }
    case 'radiolist':
    case 'checkboxlist':
    case 'checkboxrow': {
      return (
        <CheckBoxList
          options={data}
          onSelect={selectItem}
          selectedItems={selectedItems}
          horizontal={renderer === 'checkboxrow'}
          multiple={multiple}
          valid={valid}
          optionBackgroundColor={optionBackgroundColor}
        />
      )
    }
    case 'checkboxsearchlist': {
      return (
        <>
          <View
            ref={wrapperRef}
            style={{
              backgroundColor: optionBackgroundColor ?? bg.card,
              color: fonts.primary,
              height: 40,
              alignItems: 'center',
              flexDirection: 'row',
              paddingHorizontal: 20,
              borderRadius: 46,
              marginBottom: 24,
            }}
          >
            <WorktribeIcon name="search-alternate" />

            <TextInput
              autoFocus={true}
              value={search}
              onChangeText={onChangeSearch}
              style={[
                {
                  flex: 1,
                  marginLeft: 16,
                  fontFamily: 'proxima-nova',
                  color: '#0E1012',
                  fontSize: 15,
                },
                Platform.OS === 'web' && { outline: 'none' },
              ]}
              placeholder="Search"
              placeholderTextColor="#757885"
            />
          </View>

          {!!selectedItems.length && (
            <ScrollView
              horizontal
              style={{ flexDirection: 'row', marginBottom: 20 }}
            >
              {selectedItems.map(selectedItem => (
                <Pressable
                  key={selectedItem.recordid}
                  style={{
                    marginRight: 24,
                    maxWidth: 60,
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Participant
                    source={{
                      uri: `https://zubanubi.com/resources/covers/cover${selectedItem.coverid}_thumb@2x.jpg`,
                      cache: 'force-cache',
                    }}
                  />
                  <Caption
                    primary
                    style={{ textAlign: 'center', marginTop: 2 }}
                  >
                    {selectedItem.title}
                  </Caption>

                  <RemoveSelected
                    onPress={() => removeItem(selectedItem.recordid)}
                  >
                    <WorktribeIcon
                      size={18}
                      color="#1D7A55"
                      name="remove-circle"
                    />
                  </RemoveSelected>
                </Pressable>
              ))}
            </ScrollView>
          )}

          <View style={{ height: 400 }}>
            <CheckBoxSearchList
              options={data}
              onSelect={selectItem}
              selectedItems={selectedItems}
              valid={valid}
              optionBackgroundColor={optionBackgroundColor}
            />
          </View>
        </>
      )
    }
    default: {
      return (
        <View ref={wrapperRef} style={{ position: 'relative' }}>
          <Pressable
            onPress={() => setShowSelectList(show => !show)}
            style={[
              {
                height: 60,
                paddingHorizontal: 16,
                backgroundColor: optionBackgroundColor ?? bg.card,
                justifyContent: 'center',
                borderRadius: 16,
              },
              !formValid &&
                modal &&
                !valid && {
                  shadowOffset: {
                    width: -2,
                    height: 0,
                  },
                  shadowColor: '#F19549',
                },
            ]}
          >
            <View>
              {!!selectedItems.length && (
                <Label color={focused ? '#2AAD79' : ''}>{name}</Label>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  flexGrow: 1,
                  justifyContent: 'space-between',
                }}
              >
                {selectedItems.length ? (
                  <Body
                    numberOfLines={1}
                    color={!formValid && !valid ? '#B15500' : fonts.secondary}
                  >
                    {valid ? displayLabel : `${displayLabel}*`}
                  </Body>
                ) : (
                  <AlignedRow
                    style={{ flex: 1 }}
                    justifyContent="space-between"
                  >
                    <Body
                      color={!formValid && !valid ? '#B15500' : fonts.secondary}
                    >
                      {valid ? name : `${name} *`}
                    </Body>

                    <WorktribeIcon name="chevron-down" />
                  </AlignedRow>
                )}

                {!!selectedItems.length && (
                  <Pressable onPress={removeAll}>
                    <WorktribeIcon size={16} name="remove-circle" />
                  </Pressable>
                )}
              </View>
            </View>
          </Pressable>

          {showSelectList && (
            <InlineSelectList
              data={data}
              type={type}
              search={search}
              onSearch={onChangeSearch}
              selectedItems={selectedItems}
              onSelect={selectItem}
              onRemove={removeItem}
              onClose={closeSelectList}
              onFocus={onFocus}
              onBlur={onBlur}
              multiple={multiple}
              modal={modal}
              scrollView={scrollView}
              scrollY={scrollY}
            />
          )}
        </View>
      )
    }
  }
}

export default InlineSelect

interface ISelectList {
  data: any
  type: string
  search: string
  onSearch: (search: string) => void
  selectedItems: []
  onSelect: (record: any) => void
  onRemove: (recordid: string) => void
  onClose: () => void
  onFocus: () => void
  onBlur: () => void
  multiple: boolean
  scrollView: RefObject<Animated.ScrollView>
  scrollY: Animated.SharedValue<number>
}

const InlineSelectList: React.FC<ISelectList> = ({
  data,
  type,
  search,
  onSearch,
  selectedItems,
  onSelect,
  onRemove,
  onFocus,
  onBlur,
  multiple,
  scrollView,
  scrollY,
}) => {
  const {
    typography: { Overline, Body },
    themeColors: { bg, fonts },
    shadowStyles,
  } = useContext(ThemeContext)

  const inputRef = useRef()

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <View
      onLayout={({ nativeEvent: { layout } }) => {
        scrollView.current.scrollTo({
          y: layout.top + scrollY.value - layout.height,
          animated: true,
        })
      }}
      style={[
        {
          position: 'absolute',
          top: 65,
          backgroundColor: bg.primary,
          borderRadius: 16,
          paddingVertical: 3,
          maxHeight: 220,
          width: '100%',
        },
        shadowStyles,
      ]}
    >
      {type === RECORD_TYPE && (
        <View
          style={{ height: 44, paddingVertical: 12, paddingHorizontal: 16 }}
        >
          <AlignedRow>
            <WorktribeIcon size={20} color="#A0A4B8" name="search-alternate" />

            <TextInput
              ref={inputRef}
              value={search}
              onChangeText={onSearch}
              style={[
                {
                  fontFamily: 'proxima-nova',
                  color: fonts.secondary,
                  fontSize: 15,
                  lineHeight: 24,
                  marginLeft: 14,
                  height: 24,
                  flex: 1,
                },
                Platform.OS === 'web' && { outline: 'none' },
              ]}
              placeholderTextColor="#A0A4B8"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </AlignedRow>
        </View>
      )}

      {data?.length ? (
        <ScrollView style={{ flex: 1 }}>
          {multiple && (
            <>
              {!!selectedItems.length && (
                <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
                  selected
                </Overline>
              )}

              {selectedItems.map(record => (
                <SelectItem
                  onRemove={onRemove}
                  key={record.recordid}
                  record={record}
                  type={type}
                />
              ))}

              {!!selectedItems.length && (
                <Overline style={{ paddingVertical: 4, paddingHorizontal: 16 }}>
                  others
                </Overline>
              )}
            </>
          )}

          {data
            .filter(record => {
              const selectedIds = selectedItems.map(item => item.recordid)
              return !selectedIds.includes(record.recordid)
            })
            .map(record => (
              <SelectItem
                key={record.recordid}
                record={record}
                onSelect={onSelect}
                type={type}
              />
            ))}
        </ScrollView>
      ) : (
        <Body style={{ padding: 16 }}>No records found</Body>
      )}
    </View>
  )
}

const SelectItem = ({
  record,
  onSelect,
  onRemove,
  type,
}: {
  record: any
  onSelect?: (record: any) => void
  onRemove?: (recordid: string) => void
  type: string
}) => {
  const {
    typography: { Body },
    themeColors: { hover },
  } = useContext(ThemeContext)

  let nameString = ''
  let details = ''
  if (type === 'record') {
    const result = record.properties.find(property => property.type === 'title')

    if (result) {
      nameString = result.value
    }

    details = record.properties
      .filter(property => property.type !== 'title')
      .map(property => property.label)
      .filter(property => property)
      .join(', ')
  } else {
    nameString = record.label ?? record.name
  }

  return (
    <HoverableOpacity
      onPress={() => {
        if (onSelect) {
          onSelect(record)
        }
      }}
      hoverStyle={{ backgroundColor: hover }}
      style={{
        paddingVertical: 9,
        paddingHorizontal: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <AlignedRow style={{ flex: 1 }}>
        <AlignedRow style={{ marginRight: 6 }}>
          {type === 'record' && (
            <CardThumbnail
              source={{
                uri: `https://zubanubi.com/resources/covers/cover${record.coverid}_thumb@2x.jpg`,
                cache: 'force-cache',
              }}
            />
          )}

          {type === 'status' && (
            <View
              style={{
                height: 12,
                width: 12,
                borderRadius: 6,
                marginRight: 12,
                backgroundColor: `#${record.color}`,
              }}
            />
          )}

          <View>
            <Body style={{ marginLeft: 8 }} primary>
              {nameString}
            </Body>

            {record.description && (
              <Body style={{ marginLeft: 8 }}>{record.description}</Body>
            )}
          </View>
        </AlignedRow>

        <Body numberOfLines={1}>{details}</Body>
      </AlignedRow>

      {onRemove && (
        <Pressable onPress={() => onRemove(record.recordid)}>
          <WorktribeIcon size={16} name="remove-circle" />
        </Pressable>
      )}
    </HoverableOpacity>
  )
}

interface ISelectOption {
  recordid: string
  name?: string
  title?: string
  label?: string
  description?: string
}

interface ISelectRenderer {
  options: ISelectOption[]
  onSelect: (value: any) => void
  selectedItems: ISelectOption[]
  valid: boolean
  optionBackgroundColor?: string
}

const RadioRow: React.FC<ISelectRenderer> = ({
  options,
  onSelect,
  selectedItems,
  valid,
  optionBackgroundColor,
}) => {
  if (!options.length) return null

  const {
    typography: { Button },
    themeColors: { bg, fonts, accents },
  } = useContext(ThemeContext)

  return (
    <View
      style={{
        width: 'max-content',
        maxWidth: '100%',
        backgroundColor: optionBackgroundColor ?? bg.primary,
        borderRadius: 34,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: accents.separator,
      }}
    >
      {options
        .map((option, i) => {
          const lastIndex = options.length - 1

          let borderRadius
          if (i === 0) {
            borderRadius = {
              borderBottomLeftRadius: 50,
              borderTopLeftRadius: 50,
            }
          } else if (i === lastIndex) {
            borderRadius = {
              borderBottomRightRadius: 50,
              borderTopRightRadius: 50,
            }
          }

          const isSelected =
            selectedItems.findIndex(item => item.recordid === option.recordid) >
            -1

          return (
            <Pressable
              key={option.recordid}
              onPress={() => onSelect(option)}
              style={[
                {
                  paddingHorizontal: 30,
                  paddingVertical: 9,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 1,
                },
                isSelected && {
                  backgroundColor: '#2AAD79',
                },
                borderRadius && borderRadius,
              ]}
            >
              <Button
                selectable={false}
                color={isSelected ? `#FFF` : fonts.primary}
              >
                {option.label ?? option.name ?? option.title}
              </Button>
            </Pressable>
          )
        })
        .reduce((prev, curr) => [
          prev,
          <View
            key={uuidv4()}
            style={{ width: 1, backgroundColor: accents.separator }}
          />,
          curr,
        ])}
    </View>
  )
}

interface ISelectCheckBoxRender extends ISelectRenderer {
  horizontal: boolean
  multiple: boolean
}

const CheckBoxList: React.FC<ISelectCheckBoxRender> = ({
  options,
  onSelect,
  selectedItems,
  horizontal,
  multiple,
  optionBackgroundColor,
}) => {
  return (
    <View style={horizontal && { flexDirection: 'row', flexWrap: 'wrap' }}>
      {options.map(option => (
        <CheckBoxOption
          key={option.recordid}
          option={option}
          selectedItems={selectedItems}
          multiple={multiple}
          horizontal={horizontal}
          onSelect={onSelect}
          optionBackgroundColor={optionBackgroundColor}
        />
      ))}
    </View>
  )
}

interface CheckBoxOptionProps {
  option: ISelectOption
  selectedItems: ISelectOption[]
  multiple: boolean
  horizontal: boolean
  onSelect: Function
  optionBackgroundColor: string
}

const CheckBoxOption: React.FC<CheckBoxOptionProps> = ({
  option,
  selectedItems,
  multiple,
  horizontal,
  onSelect,
  optionBackgroundColor,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [icon, setIcon] = useState('')

  const isSelected =
    selectedItems.findIndex(item => item.recordid === option.recordid) > -1

  useEffect(() => {
    let icon
    if (isSelected) {
      icon = multiple ? 'check-2' : 'check-circle-1'
    } else if (isHovered) {
      icon = multiple ? 'check-empty-hover' : 'sign-badge-circle-hover'
    } else {
      icon = multiple ? 'check-empty' : 'sign-badge-circle-alternate'
    }
    setIcon(icon)
  }, [isHovered, isSelected, multiple])

  let details = ''
  if ('properties' in option) {
    details = option.properties
      .filter(property => property.type !== 'title')
      .map(property => property.label)
      .filter(property => property)
      .join(', ')
  }

  const {
    typography: { Subtitle, Body },
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  return (
    <Pressable
      onPressIn={() => setIsHovered(true)}
      onPressOut={() => setIsHovered(false)}
      key={option.name}
      onPress={() => {
        onSelect(option)
      }}
      style={[
        {
          maxWidth: horizontal ? '' : 520,
          padding: 16,
          backgroundColor: optionBackgroundColor ?? bg.card,
          marginVertical: 8,
          borderRadius: 20,
          borderWidth: 2,
          borderColor: 'transparent',
          minWidth: 360,
        },
        isSelected && {
          borderWidth: 2,
          backgroundColor: '#F2FCF8',
          borderColor: '#2AAD79',
        },
        horizontal && { marginRight: 8 },
      ]}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: option.description || details ? 'flex-start' : 'center',
        }}
      >
        <WorktribeIcon name={icon} />
        <View style={[{ marginLeft: 16, flex: 1 }]}>
          <Subtitle
            selectable={false}
            color={isSelected ? '#1D7A55' : fonts.primary}
          >
            {option.label ?? option.name ?? option.title}
          </Subtitle>
          <Body selectable={false}>{option.description ?? details}</Body>
        </View>
      </View>
    </Pressable>
  )
}

const CheckBoxSearchList: React.FC<ISelectRenderer> = ({
  options,
  onSelect,
  selectedItems,
  valid,
  optionBackgroundColor,
}) => {
  const {
    typography: { Subtitle, Caption },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  const renderItem = ({ item }) => {
    let details = ''
    if ('properties' in item) {
      details = item.properties
        .filter(property => property.type !== 'title')
        .map(property => property.label)
        .filter(property => property)
        .join(', ')
    }

    const isSelected =
      selectedItems.findIndex(option => option.recordid === item.recordid) > -1

    return (
      <Pressable
        onPress={() => onSelect(item)}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 16,
          paddingVertical: 10,
          backgroundColor: isSelected ? '#F2FCF8' : '',
        }}
      >
        <AlignedRow style={{ flex: 1 }}>
          <InlineSelectImage
            source={{
              uri: `https://zubanubi.com/resources/covers/cover${
                item.coverid ?? `1`
              }_thumb@2x.jpg`,
              cache: 'force-cache',
            }}
          />

          <View style={{ marginLeft: 12, flex: 1 }}>
            <Subtitle selectable={false} primary>
              {item.label ?? item.name ?? item.title}
            </Subtitle>
            <Caption selectable={false}>{details}</Caption>
          </View>
        </AlignedRow>

        {isSelected ? (
          <WorktribeIcon name="check-2" />
        ) : (
          <WorktribeIcon name="check-empty" />
        )}
      </Pressable>
    )
  }

  if (options.length === 0) {
    return <Caption>Nothing found</Caption>
  }

  return (
    <FlatList
      contentContainerStyle={{
        borderRadius: 16,
        backgroundColor: optionBackgroundColor ?? bg.card,
      }}
      data={options}
      renderItem={renderItem}
      keyExtractor={item => item.recordid}
      ItemSeparatorComponent={() => (
        <View style={{ backgroundColor: accents.separator, height: 1 }} />
      )}
    />
  )
}

const CardThumbnail = styled.Image`
  height: 24px;
  width: 24px;
  border-radius: 8px;
`

const Participant = styled.Image`
  height: 48px;
  width: 48px;
  border-radius: 16px;
`

const InlineSelectImage = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 14px;
`

const RemoveSelected = styled.Pressable`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
`
