import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  ScrollView,
  View,
  TouchableOpacity,
  TextInput,
  Platform,
} from 'react-native'
import styled from 'styled-components/native'
import axios from 'axios'

// contexts
import { ThemeContext } from '../../contexts/ThemeContext'
import { useAuth } from '../../contexts/AuthContext'
import { AlignedRow } from '../../components/shared/Layout'

// components
import Searchatron from '../../components/landscape/Searchatron'
import WorktribeIcon from '../../components/shared/WorktribeIcon'
import Selection from '../../components/landscape/Selection'
import { SmallButton } from '../../components/shared/Button'

// HOC
import withLayout from '../../HOC/withLayout'

import useZubanubi from '../../hooks/useZubanubi'

import { back } from '../../utils/back'

interface SearchQuery {
  method: string
  debugtoken: string
  q: string
  rclasses?: string[]
}

interface SearchError {
  status: string
  privatedetail: string
}

interface SearchScreenProps {}

const SearchScreen: React.FC<SearchScreenProps> = ({ route, navigation }) => {
  const { params } = route

  const {
    typography: { Body, H4 },
    themeColors: { bg, accents, fonts },
  } = useContext(ThemeContext)

  const getZubanubiRepo = useZubanubi()
  const { user } = useAuth()

  const [search, onChangeSearch] = useState<string>('')
  const [showSearchatron, setShowSearchatron] = useState<boolean>(false)
  const [data, setData] = useState<null | []>(null)
  const [error, setError] = useState<null | string>(null)

  useEffect(() => {
    onChangeSearch(params.search)
  }, [params.search])

  useEffect(() => {
    async function searchRecords({ search, rclass }) {
      try {
        const zubanubiRepo = await getZubanubiRepo()

        const params: SearchQuery = {
          method: `record_search`,
          wtauth: true,
          wtenv: `${zubanubiRepo}`,
          debugtoken: `${user?.userid}.quirkafleeg`,
          q: search,
        }

        if (rclass) {
          params['rclasses'] = [rclass]
        }

        const response = await axios.get(`https://www.zubanubi.com/zapi.php`, {
          params,
        })

        // console.log(response)

        if (response.data.status !== 'OK') {
          throw {
            status: response.data.status,
            privatedetail: response.data.privatedetail,
          }
        }

        const { data } = response.data
        if (data) setData(data)
      } catch (e: SearchError | any) {
        if (e.privatedetail) {
          setError(`${e.status}: ${e.privatedetail}`)
        } else {
          setError(e.message)
        }
      }
    }

    searchRecords(params)
  }, [params])

  const resetSearch = () => {
    onChangeSearch('')
    back()
  }

  const handleShowSearch = useCallback(() => {
    setShowSearchatron(true)
  }, [])

  const handleHideSearch = useCallback(() => {
    setShowSearchatron(false)
  }, [])

  return (
    <Content style={{ backgroundColor: bg.primary }}>
      <View style={{ paddingVertical: 20, paddingHorizontal: 40 }}>
        <AlignedRow>
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderColor: accents.separator,
              borderRadius: 70,
              paddingVertical: 13,
              paddingHorizontal: 15,
            }}
            onPress={back}
          >
            <WorktribeIcon color={fonts.primary} name="keyboard-arrow-right" />
          </TouchableOpacity>
        </AlignedRow>

        {showSearchatron ? (
          <Searchatron setShowSearch={handleHideSearch} initialValue={search} />
        ) : (
          <View
            style={{
              justifyContent: 'center',
              maxWidth: 848,
              width: '100%',
              position: 'absolute',
              left: '50%',
              marginLeft: -424,
              height: 36,
              backgroundColor: bg.card,
              top: 15,
              borderRadius: 16,
              borderWidth: 1,
              borderColor: accents.separator,
              paddingVertical: 8,
              paddingHorizontal: 24,
            }}
          >
            <AlignedRow justifyContent="space-between">
              <AlignedRow style={{ flex: 1 }}>
                <WorktribeIcon size={20} name="search-alternate" />

                <TextInput
                  onFocus={handleShowSearch}
                  style={{
                    flex: 1,
                    fontFamily: 'proxima-nova',
                    fontSize: 15,
                    lineHeight: 22,
                    marginLeft: 20,
                    outlineWidth: 0,
                    height: 22,
                    color: fonts.primary,
                    marginRight: 20,
                  }}
                  placeholder="Search"
                  placeholderTextColor="#A0A4B8"
                  onChangeText={onChangeSearch}
                  value={search}
                  onSubmitEditing={() =>
                    navigation.navigate('Search', { search })
                  }
                />
              </AlignedRow>

              <AlignedRow>
                <TouchableOpacity onPress={() => onChangeSearch('')}>
                  <Body>Clear</Body>
                </TouchableOpacity>

                <View
                  style={{
                    height: 20,
                    width: 1,
                    backgroundColor: accents.separator,
                    marginHorizontal: 16,
                  }}
                />
                <TouchableOpacity onPress={resetSearch}>
                  <WorktribeIcon name="remove" />
                </TouchableOpacity>
              </AlignedRow>
            </AlignedRow>
          </View>
        )}
      </View>

      {!data ? null : data.length ? (
        <ScrollView style={{ flex: 1 }}>
          {data.map(payload => {
            const {
              properties,
              rclass: { name, plural },
              records,
            } = payload

            const limitedRecords = params.rclass ? records : records.slice(0, 3)
            const showViewAll = records.length > limitedRecords.length

            return (
              <React.Fragment key={name}>
                <View style={{ marginBottom: 20 }}>
                  {/* <H4
                    style={{ marginBottom: 12, paddingHorizontal: 40 }}
                    primary
                  >
                    {plural}
                  </H4> */}

                  <Selection
                    value={{
                      properties,
                      records: limitedRecords,
                    }}
                    rclass={name}
                    propertyName={plural}
                    controls={!!params.rclass}
                    showHeadings={!!params.rclass}
                    footer={
                      showViewAll && !params.rclass ? (
                        <View
                          style={{
                            flex: 1,
                            alignItems: 'flex-end',
                            marginTop: 16,
                          }}
                        >
                          <SmallButton
                            onPress={() =>
                              navigation.navigate('Search', {
                                search,
                                rclass: name,
                              })
                            }
                          >
                            <Body color="#1D7A55">View all</Body>
                          </SmallButton>
                        </View>
                      ) : null
                    }
                  />
                </View>
              </React.Fragment>
            )
          })}
        </ScrollView>
      ) : error ? (
        <Body color="#E94545" style={{ marginLeft: 40, marginTop: 40 }}>
          {error}
        </Body>
      ) : (
        <Body style={{ marginLeft: 40, marginTop: 40 }}>No results</Body>
      )}
    </Content>
  )
}

export default withLayout(SearchScreen)

const Content = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`
