import { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import useSocket from './useSocket'

const useSocketListener = (callback: (message: string) => void): void => {
  const socket = useSocket()

  useFocusEffect(
    useCallback(() => {
      socket?.addEventListener('message', callback)

      return () => {
        socket?.removeEventListener('message', callback)
      }
    }, [socket, callback])
  )
}

export default useSocketListener