import React, { useContext } from 'react'
import { Pressable } from 'react-native'

import { ThemeContext } from '../../contexts/ThemeContext'

import WorktribeIcon from '../shared/WorktribeIcon'

import { back } from '../../utils/back'

const BackButton: React.FC<{ color?: string }> = ({ color }) => {
  const {
    themeColors: { accents, fonts },
  } = useContext(ThemeContext)

  return (
    <Pressable
      onPress={back}
      style={{
        width: 54,
        height: 38,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1.5,
        borderColor: color || accents.button,
        borderRadius: 70,
      }}
    >
      <WorktribeIcon
        color={color || fonts.primary}
        name="keyboard-arrow-right"
      />
    </Pressable>
  )
}

export default BackButton
