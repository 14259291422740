import React from 'react'
import styled from 'styled-components/native'
import { Modalize } from 'react-native-modalize'
import * as RootNavigation from '../../services/RootNavigation'
import { Image } from 'react-native'

const OptionContainer = styled.TouchableOpacity`
  background-color: #ffffff;
  border-radius: 16px;
  margin: 6px 0;
  height: 56px;
  justify-content: center;
  padding: 0 24px;
`
const Option = styled.View`
  flex-direction: row;
  align-items: center;
`
const OptionBookmark = styled.View`
  background-color: #${props => props.color};
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 22px;
  margin-right: 20px;
  border-radius: 3px;
`
const OptionTitle = styled.Text`
  font-family: proxima-nova-semibold;
  font-size: 16px;
  color: #0e1012;
`

const menuItems = [
  {
    title: 'Edit record',
    color: '2AAD79',
  },
  {
    title: 'Export',
    color: '2AAD79',
  },
  {
    title: 'reorder',
    color: '2AAD79',
  },
  {
    title: 'Archive',
    color: '2AAD79',
  },
  {
    title: 'Delete',
    color: '2AAD79',
  },
]

const Actions = ({ modalizeRef, onClosed }) => {
  const renderOption = ({ item }) => (
    <OptionContainer
      onPress={() => RootNavigation.navigate('Create', { title: item.title })}
    >
      <Option>
        <OptionBookmark color={item.color}>
          <Image
            style={{ width: 24, height: 22 }}
            source={require('../../../assets/icons/bookmark.png')}
          />
        </OptionBookmark>
        <OptionTitle>{item.title}</OptionTitle>
      </Option>
    </OptionContainer>
  )

  return (
    <Modalize
      ref={modalizeRef}
      adjustToContentHeight={true}
      withHandle={true}
      handlePosition="inside"
      onClosed={onClosed}
      modalStyle={{
        backgroundColor: '#EBECF2',
        paddingHorizontal: 20,
      }}
      childrenStyle={{
        marginTop: 20,
        marginBottom: 10,
      }}
      flatListProps={{
        contentContainerStyle: {
          paddingBottom: 10,
        },
        data: menuItems,
        renderItem: renderOption,
        keyExtractor: item => item.title,
        showsVerticalScrollIndicator: false,
      }}
    />
  )
}

export default Actions
