import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  useReducer,
} from 'react'
import { AppState } from 'react-native'
import NetInfo from '@react-native-community/netinfo'
import { useAuth } from './AuthContext'

const WEBSOCKET_BASE_URL = `wss://www.zubanubi.com/zapisock?wtauth=true`

export const SocketContext = createContext(null)

const initialState = {
  ws: undefined,
}

function SocketReducer(
  state: any,
  action: { type: string; websocket: WebSocket | undefined }
) {
  switch (action.type) {
    case 'SETWS':
      return {
        ...state,
        ws: action.websocket,
      }
    default:
      throw new Error()
  }
}

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(SocketReducer, initialState)

  const appState = useRef(AppState.currentState)
  const [hasAuthenticated, setHasAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)

  const { provider, token, signIn, signOut, setIsConnected } = useAuth()

  const connectionCheckTimer = useRef<ReturnType<typeof setInterval>>()
  const stateCheckTimer = useRef<ReturnType<typeof setInterval>>()

  const currentConnectionStatus = useRef<boolean|null>(true)

  const connectToWebSocket = (provider: any, token: any) => {
    // If we are closed, or the websocket doesn't exist yet, get it
    if (provider !== null && token !== null) {
      if (!state.ws || state.ws?.readyState === WebSocket.CLOSED) {
        dispatch({
          type: 'SETWS',
          websocket: new WebSocket(
            `${WEBSOCKET_BASE_URL}&${provider}token=${token}`
          ),
        })
      } else if (
        [WebSocket.CONNECTING, WebSocket.CLOSING].includes(state.ws?.readyState)
      ) {
        // If we are connecting or closing, check in a second whether we are connected
        setTimeout(() => {
          console.log('waiting for connection, retrying in one second')
          // connectToWebSocket(provider, token)
        }, 1000)
      }
    }
  }

  // AUTH HANDLING CODE
  useEffect(() => {
    // If there is a provider and token change, and they are set, log us in
    if (provider !== null && token !== null) {
      connectToWebSocket(provider, token)
    } else {
      // Close if the provider or token are unset, and mark us as not connected and not authed
      // NOTE: NEVER SIGN OUT HERE, OTHERWISE YOU'LL ALWAYS HAVE TO LOGIN ON
      if (state.ws) state.ws.close()
      setCurrentUser(null)
      setIsConnected(false)
      setHasAuthenticated(false)
    }

    // WEBSOCKET HANDLING CODE

    // If we don't have a WebSocket, do nothing
    if (!state.ws) return

    // WEBSOCKET OPEN
    state.ws.onopen = () => {
      // When we first connect, do a 'whoami' request to get our user creds
      state.ws.send(JSON.stringify({ method: 'whoami' }))
      // Mark us as authenticated and connected
      setHasAuthenticated(true)
      setIsConnected(true)
    }

    // WEBSOCKET MESSAGES
    // Message responses that are low-level, such as user autentication (i.e.: sniffing the stuff as it goes through)
    state.ws.onmessage = (event: any) => {
      // Well, we're definitely authed and connected, then!
      setHasAuthenticated(true)
      setIsConnected(true)

      // If we don't already have a current user, sign in
      if (!currentUser) {
        // Parse the incoming data for user info
        const { user } = JSON.parse(event.data)
        setCurrentUser(user)
        signIn(user, provider, token)
      }
    }

    // WEBSOCKET CLOSE HANDLING
    state.ws.onclose = (event: any) => {
      if (!hasAuthenticated) {
        signOut()
        setCurrentUser(null)
      }
    }

    // WEBSOCKET ERROR HANDLING
    state.ws.onerror = (event: any) => {
      // if (provider && token) {
      //   setIsConnected(false)
      //   console.log(
      //     "An eror occurred when talking to socket: '" +
      //       event.message +
      //       "', reconnecting in 5 sec..."
      //   )
      //   setTimeout(function () {
      //     connectToWebSocket(provider, token)
      //   }, 5000)
      // }
    }

    // INTERNET DISCONNECTION CODE
    if (hasAuthenticated) {
      // If we are disconnected from the internet, pop up the 'disconnected' banner - check every five seconds
      connectionCheckTimer.current = setInterval(async () => {
        // NOTE: NetInfo.fetch always returns true on Safari - so bear that in mind!
        const { isConnected } = await NetInfo.fetch()

        if (currentConnectionStatus.current !== isConnected) {
          setIsConnected(isConnected)
        }

        currentConnectionStatus.current = isConnected
      }, 5000)

      // Every time we focus on the page, send a ping to the websocket to let them know we're here
      const subscription = AppState.addEventListener('change', nextAppState => {
        if (
          appState.current.match(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          if (!state.ws) return

          console.log('Window gained focus')

          if (state.ws.readyState !== WebSocket.CLOSED) {
            state.ws?.send(JSON.stringify({ method: 'ping' }))
          }
        }

        appState.current = nextAppState
      })

      return () => {
        clearInterval(connectionCheckTimer.current)
        subscription?.remove()
      }
    }
  }, [
    state.ws,
    token,
    provider,
    signIn,
    signOut,
    currentUser,
    hasAuthenticated,
  ])

  useEffect(() => {
    if (!state.ws) return

    // Every second, check for readyState
    stateCheckTimer.current = setInterval(() => {
      if (hasAuthenticated) {
        switch (state.ws?.readyState) {
          case WebSocket.CLOSED:
            connectToWebSocket(provider, token)
            setIsConnected(false)
            break
          case WebSocket.OPEN:
            // setIsConnected(true)
            break
        }
      }
    }, 1000)

    return () => {
      clearInterval(stateCheckTimer.current)
    }
  }, [state.ws, hasAuthenticated])

  return (
    <SocketContext.Provider value={state.ws}>{children}</SocketContext.Provider>
  )
}
