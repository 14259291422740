import React from 'react'
import styled from 'styled-components/native'
import { AlignedRow } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'
import { ThemeContext } from '../../contexts/ThemeContext'

const Container = styled.TouchableOpacity`
  padding: 10px 20px;
  border-radius: 46px;
`

const SearchButton = ({ handleShowSearch, containerStyle }) => {
  const { typography: { Body }, themeColors: { bg }} = React.useContext(ThemeContext)
  return (
    <Container
      onPress={handleShowSearch}
      activeOpacity={1}
      style={[
        { backgroundColor: bg.card }, 
        { ...containerStyle }
      ]}
    >
      <AlignedRow justifyContent="space-between">
        <AlignedRow style={{ flex: 1 }}>
          <WorktribeIcon size={20} name="search-alternate" />
          <Body style={{ marginHorizontal: 16 }}>Search</Body>
        </AlignedRow>
        {/* <WorktribeIcon size={20} name="filter" /> */}
      </AlignedRow>
    </Container>
  )
}

export default SearchButton