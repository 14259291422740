import React, { useContext, useEffect, useRef } from 'react'
import { ScrollView, Dimensions, Animated } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Host } from 'react-native-portalize'
import { useDispatch, useSelector } from 'react-redux'
import { showProfile } from '../store/actions/layout'
import { BadgeContext } from '../contexts/BadgeContext'

import HomeScreen from '../screens/portrait/HomeScreen'
import MineScreen from '../screens/portrait/MineScreen'
import MessagesScreen from '../screens/portrait/MessagesScreen'
import SearchScreen from '../screens/portrait/SearchScreen'

import ProfileSideBar from '../components/shared/ProfileSideBar'
import WorktribeIcon from '../components/shared/WorktribeIcon'

const SIDEBAR_WIDTH = 280

const AppTabs = createBottomTabNavigator()

const profileWrapper = React.createRef()

const BottomTabNavigator = ({ navigation, route }) => {
  const { home, mine, conversations } = useContext(BadgeContext)[0]

  const screenWidth = Dimensions.get('window').width

  const previousScrollX = useRef(null)
  const {
    profile: { open },
  } = useSelector(state => state.layout)

  useEffect(() => {
    if (open) {
      profileWrapper.current.scrollToEnd({ animated: true })
    } else {
      profileWrapper.current.scrollTo({ animated: true, x: 0 })
    }
  }, [open])

  const dispatch = useDispatch()

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  }, [navigation, route])

  const { bottom } = useSafeAreaInsets()

  const scrollX = useRef(new Animated.Value(0)).current

  const opacity = scrollX.interpolate({
    inputRange: [0, SIDEBAR_WIDTH],
    outputRange: [1, 0.6],
    extrapolate: 'clamp',
  })

  return (
    <ScrollView
      ref={profileWrapper}
      bounces={false}
      horizontal
      pagingEnabled
      showsHorizontalScrollIndicator={false}
      onScroll={Animated.event(
        [{ nativeEvent: { contentOffset: { x: scrollX } } }],
        { useNativeDriver: false }
      )}
      scrollEventThrottle={16}
      onMomentumScrollEnd={() => {
        if (previousScrollX.current === scrollX._value) return
        dispatch(showProfile(scrollX._value === SIDEBAR_WIDTH))
        previousScrollX.current = scrollX._value
      }}
    >
      <Animated.View style={{ width: screenWidth, opacity }}>
        <Host>
          <AppTabs.Navigator
            tabBarOptions={{
              tabStyle: {
                height: 60,
                paddingBottom: 6,
              },
              style: {
                borderTopWidth: 1,
                height: 60 + bottom,
              },
              activeTintColor: '#2AAD79',
              inactiveTintColor: '#A0A4B8',
              labelStyle: {
                fontWeight: '600',
                fontSize: 11,
                lineHeight: 13.4,
                marginTop: -6,
                paddingTop: 3,
              },
              labelPosition: 'below-icon',
            }}
          >
            <AppTabs.Screen
              name="Home"
              component={HomeScreen}
              options={{
                tabBarIcon: ({ focused }) => (
                  <WorktribeIcon
                    name="bird-house"
                    color={focused ? '#2AAD79' : '#A0A4B8'}
                  />
                ),
                tabBarBadge: home > 0 ? home : null,
                tabBarBadgeStyle: { backgroundColor: '#296DFF' },
              }}
            />
            {/* <AppTabs.Screen
              name="Mine"
              component={MineScreen}
              options={{
                tabBarIcon: ({ focused }) => (
                  <WorktribeIcon
                    name="single-neutral-phone-book"
                    color={focused ? '#2AAD79' : '#A0A4B8'}
                  />
                ),
                tabBarBadge: mine > 0 ? mine : null,
                tabBarBadgeStyle: { backgroundColor: '#296DFF' },
              }}
            />
            <AppTabs.Screen
              name="Messages"
              component={MessagesScreen}
              options={{
                tabBarIcon: ({ focused }) => (
                  <WorktribeIcon
                    name="message-bubble"
                    color={focused ? '#2AAD79' : '#A0A4B8'}
                  />
                ),
                tabBarBadge: conversations > 0 ? conversations : null,
                tabBarBadgeStyle: { backgroundColor: '#296DFF' },
              }}
            />
            <AppTabs.Screen
              name="Records"
              component={SearchScreen}
              options={{
                tabBarIcon: ({ focused }) => (
                  <WorktribeIcon
                    name="layout-content"
                    color={focused ? '#2AAD79' : '#A0A4B8'}
                  />
                ),
              }}
            /> */}
          </AppTabs.Navigator>
        </Host>
      </Animated.View>

      <ProfileSideBar navigation={navigation} />
    </ScrollView>
  )
}

export default BottomTabNavigator
