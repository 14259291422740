import React, { useContext, useState } from 'react'
import { Platform, StyleSheet, View, FlatList } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AlignedRow } from '../../components/shared/Layout'
import withLayout from '../../HOC/withLayout'
import * as Property from '../../components/landscape/QuillField'

interface QuillScreenProps {}

const QuillScreen: React.FC<QuillScreenProps> = ({}) => {
  const {
    themeColors: { bg },
  } = useContext(ThemeContext)

  const QuillVariants = [
    {
      id: 0,
      name: 'Snow Style',
      theme: 'snow',
    },
    {
      id: 1,
      name: 'Bubble Style (click on a typed word to show tools)',
      theme: 'bubble',
    },
    {
      id: 2,
      name: 'No embellishment',
      theme: 'none',
    },
  ]

  const [fieldData, setFieldData] = useState({})

  const value = {
    value: 'Well, this is rich (text) coming from <b><i>you</i></b>.',
  }

  const renderItem = ({ item }) => (
    <View>
      <InlinePropertyWrapper
        heading={item.name}
        required={false}
        fieldValue={'Well, this is rich (text) coming from <b><i>you</i></b>.'}
        index={item.id}
      >
        <Property.QuillInput
          required={false}
          value={value}
          field={item.theme}
          valid={true}
          theme={item.theme}
        />
      </InlinePropertyWrapper>
    </View>
  )

  return (
    <Content style={{ backgroundColor: bg.primary }}>
      <FlatList
        data={QuillVariants}
        renderItem={renderItem}
        keyExtractor={item => item.name}
      />
    </Content>
  )
}

const InlinePropertyWrapper = ({
  children,
  heading,
  textabove,
  textbelow,
  required,
  fieldValue,
  index,
}) => {
  const {
    typography: { H3, Subtitle },
  } = useContext(ThemeContext)

  return (
    <View style={{ margin: 24, zIndex: index }}>
      {heading && (
        <AlignedRow style={{ marginBottom: 16 }}>
          <H3 primary>{heading}</H3>

          {required && !fieldValue && (
            <View
              style={{
                height: 10,
                width: 10,
                backgroundColor: '#e08938',
                borderRadius: 5,
                marginLeft: 6,
              }}
            />
          )}
        </AlignedRow>
      )}

      {textabove && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: 16,
          }}
        >
          <Subtitle>{textabove}</Subtitle>

          {required && !fieldValue && !heading && (
            <View
              style={{
                height: 10,
                width: 10,
                backgroundColor: '#e08938',
                borderRadius: 5,
                marginLeft: 6,
                marginTop: 5,
              }}
            />
          )}
        </View>
      )}

      {children}

      {textbelow && <Subtitle style={{ marginTop: 16 }}>{textbelow}</Subtitle>}
    </View>
  )
}

export default withLayout(QuillScreen)

const Content = styled.View`
  flex: 1;
  background-color: #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  sc
`
