import React from 'react'
import { Pressable } from 'react-native'
import WorktribeIcon from '../../../components/shared/WorktribeIcon'
import { SIDE_WIDTH } from './utils'

interface PeriodControlsProps {
  onPress: (num: number) => void
}

const YEAR_INCREMENT = 12

const PeriodControls: React.FC<PeriodControlsProps> = ({ onPress }) => (
  <>
    <Pressable
      onPress={() => onPress(-YEAR_INCREMENT)}
      style={{
        position: 'absolute',
        top: 25,
        left: SIDE_WIDTH + 8,
        zIndex: 99,
      }}
    >
      <WorktribeIcon name="arrow-up-left" />
    </Pressable>

    <Pressable
      onPress={() => onPress(YEAR_INCREMENT)}
      style={{ position: 'absolute', top: 25, right: 8, zIndex: 99 }}
    >
      <WorktribeIcon name="arrow-up-right" />
    </Pressable>
  </>
)

export default PeriodControls
