import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { documentRef } from '../services/Document'

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, handler) => {
  useEffect(() => {
    if (Platform.OS !== 'web') return

    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    // Bind the event listener
    documentRef.current?.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up, if it still exists
      if (documentRef.current !== null)
        documentRef.current.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useOutsideAlerter
