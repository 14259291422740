import React, { useContext } from 'react'
import styled from 'styled-components/native'
import useOrientation from '../../hooks/useOrientation'
import { ThemeContext } from '../../contexts/ThemeContext'
import { AntDesign } from '@expo/vector-icons'

const Wrapper = styled.TouchableOpacity`
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 16px;
  margin: 10px 0;
  background-color: #fdf8f3;
  shadow-opacity: 0;
  shadow-radius: 32px;
  shadow-color: rgba(0, 0, 0, 0.06);
  shadow-offset: 0px 4px;
  border-radius: 24px;
`

const Message = styled.View`
  flex: 1;
  margin-right: 20px;
`

const Announcement = ({ message }) => {
  const isLandscape = useOrientation()
  const {
    typography: { Body },
  } = useContext(ThemeContext)
  return (
    <Wrapper
      key={message}
      activeOpacity={0.8}
      style={isLandscape && { marginHorizontal: 16 }}
    >
      <Message>
        <Body color="#bd6f2f">{message}</Body>
      </Message>
      <AntDesign name="close" size={20} color="#bd6f2f" />
    </Wrapper>
  )
}

export default Announcement
