export const getRecordNamefromProperties = value => {
  if (typeof value === 'string') return value

  if (typeof value === 'object' && !('properties' in value)) {
    return value[0]
  }

  const { properties, records } = value
  const titleProperty = Object.entries(properties).find(([_, config]) => {
    return config.type === 'title'
  })

  if (titleProperty) {
    const { value } = records[0].properties.find(
      record => record.name === titleProperty[0]
    )

    if (typeof value === 'string') {
      return value
    } else {
      getRecordNamefromProperties(properties)
    }
    return value
  }

  return ''
}
