import React, { useContext, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import WorktribeIcon from '../../shared/WorktribeIcon'
import { useAuth } from '../../../contexts/AuthContext'
import Avatar from '../../shared/Avatar'
import { useNavigation } from '@react-navigation/native'
import Searchatron from '../Searchatron'
import SearchButton from '../SearchButton'

const LinkIcon = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  margin-right: 16px;
  background-color: ${props => props.color};
`

const RecentList = styled.View`
  padding: 20px 0;
`

const Recent = styled.TouchableOpacity`
  flex-direction: row;
  margin-bottom: 16px;
`

const RecentRow = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  padding: 12px;
`

const RecentThumbnail = styled.Image`
  width: 36px;
  height: 36px;
  border-radius: 7px;
  margin-right: 8px;
`

const Container = styled.ScrollView`
  flex: 1;
  padding: 0 40px;
`

const Content = styled.View`
  max-width: 694px;
  width: 100%;
`

const LinkRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px 46px -12px;
`

const Link = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  padding: 10px 16px;
  margin: 8px 12px;
  min-width: 300px;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const Landing = () => {
  const {
    typography: { Overline, Label, H5 },
    themeColors: { bg },
  } = useContext(ThemeContext)
  const { recordHistory } = useSelector(state => state.records)
  const [showMore, setShowMore] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const handleHideSearch = useCallback(() => {
    setShowSearch(false)
  }, [])
  const handleShowSearch = useCallback(() => {
    setShowSearch(true)
  }, [])
  const navigation = useNavigation()
  return (
    <Container contentContainerStyle={{ alignItems: 'center', marginTop: 12 }}>
      <Content>
        {showSearch ? (
          <Searchatron setShowSearch={handleHideSearch} />
        ) : (
          <SearchButton
            handleShowSearch={handleShowSearch}
            containerStyle={{ marginBottom: 32 }}
          />
        )}

        <Overline style={{ marginBottom: 20 }}>Shortcuts</Overline>

        <LinkRow>
          <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
            <LinkIcon color="#2AAD79">
              <WorktribeIcon name="bookmark" color="#FFF" />
            </LinkIcon>
            <H5 primary>Create a project</H5>
          </Link>

          <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
            <LinkIcon color="#46C6D1">
              <WorktribeIcon name="bookmark" color="#FFF" />
            </LinkIcon>
            <H5 primary>Deposit an output</H5>
          </Link>

          <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
            <LinkIcon color="#E94545">
              <WorktribeIcon name="bookmark" color="#FFF" />
            </LinkIcon>
            <H5 primary>Request a contract</H5>
          </Link>

          <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
            <LinkIcon color="#F2CE6A">
              <WorktribeIcon name="bookmark" color="#FFF" />
            </LinkIcon>
            <H5 primary>Create a programme</H5>
          </Link>

          <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
            <LinkIcon color="#E08938">
              <WorktribeIcon name="bookmark" color="#FFF" />
            </LinkIcon>
            <H5 primary>Create a module</H5>
          </Link>

          {showMore ? (
            <>
              <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
                <LinkIcon color="#2AAD79">
                  <WorktribeIcon name="bookmark" color="#FFF" />
                </LinkIcon>
                <H5 primary>Add a news item</H5>
              </Link>

              <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
                <LinkIcon color="#E94545">
                  <WorktribeIcon name="bookmark" color="#FFF" />
                </LinkIcon>
                <H5 primary>Add an ethics application</H5>
              </Link>

              <Link style={{ backgroundColor: bg.card }} activeOpacity={1}>
                <LinkIcon color="#46C6D1">
                  <WorktribeIcon name="bookmark" color="#FFF" />
                </LinkIcon>
                <H5 primary>Add a task</H5>
              </Link>
            </>
          ) : (
            <Link
              style={{ backgroundColor: bg.card }}
              activeOpacity={1}
              onPress={() => setShowMore(true)}
            >
              <LinkIcon color="transparent" />
              <H5 primary>More...</H5>
            </Link>
          )}
        </LinkRow>

        {recordHistory.length ? (
          <>
            <Overline>Recent Searches</Overline>
            <RecentList>
              {recordHistory.map(recent => {
                const idString = recent.id.toString()
                const imgString = imageFeed[idString[idString.length - 1]]

                const [participants, setParticipants] = useState([])

                const { user } = useAuth()

                useEffect(() => {
                  if (recent?.rclass !== 'conversation') return

                  setParticipants(
                    recent.users_by_relationship.PARTICIPANT.filter(
                      participant => {
                        return participant.id !== user.userid
                      }
                    )
                  )
                }, [recent])

                return (
                  <Recent
                    activeOpacity={1}
                    key={recent.id}
                    onPress={() => {
                      navigation.navigate('Home', {
                        rClass: recent.rclass,
                        recordId: recent.id,
                      })
                    }}
                  >
                    <RecentRow style={{ backgroundColor: bg.card }}>
                      {recent.rclass === 'conversation' &&
                      participants.length ? (
                        <View style={{ marginRight: 8 }}>
                          <Avatar
                            size={36}
                            radius={7}
                            group={participants.length > 2}
                            userId={participants[0].id}
                          />
                        </View>
                      ) : (
                        <RecentThumbnail
                          source={{
                            uri: `https://source.unsplash.com/${imgString}/50x50`,
                            cache: 'force-cache',
                          }}
                        />
                      )}
                      <View style={{ flex: 1 }}>
                        <Label>{capitalize(recent.spec.rclass?.name)}</Label>
                        <H5 primary numberOfLines={1}>
                          {recent.rclass === 'conversation'
                            ? recent.fields.conversation_name
                              ? recent.fields.conversation_name
                              : participants
                                  .map(participant => participant.name)
                                  .join(', ')
                            : recent.name}
                        </H5>
                      </View>
                    </RecentRow>
                  </Recent>
                )
              })}
            </RecentList>
          </>
        ) : null}
      </Content>
    </Container>
  )
}

export default Landing

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
