import React from 'react'
import { Pressable, Switch } from 'react-native'

const InlineSwitch = ({ value, onChange }) => {
  return (
    <Pressable
      style={{
        backgroundColor: value ? '#2AAD79' : '#A0A4B8',
        height: 28,
        width: 48,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Switch
        activeThumbColor="#FFF"
        trackColor={{ 1: '#2AAD79', 0: '#2AAD79' }}
        onValueChange={value => onChange(value ? 'Yes' : 'No')}
        value={value}
      />
    </Pressable>
  )
}

export default InlineSwitch
