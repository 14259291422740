import { useState, useEffect } from 'react'
import * as ScreenOrientation from 'expo-screen-orientation'
import * as Device from 'expo-device'
import { Platform } from 'react-native'

const landscapeOrientations = [
  ScreenOrientation.Orientation.LANDSCAPE_LEFT,
  ScreenOrientation.Orientation.LANDSCAPE_RIGHT,
  ScreenOrientation.Orientation.UNKNOWN,
]

const useOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(true)

  useEffect(() => {
    const bootstrapAsync = async () => {
      const initialOrientation = await ScreenOrientation.getOrientationAsync()
      const isLandscape =
        landscapeOrientations.includes(initialOrientation) ||
        Platform.OS === 'web'

      setIsLandscape(isLandscape)

      const DeviceType = await Device.getDeviceTypeAsync()
      // We have forced portrait in policy, but we can unlock this on tablet devices
      if (Device.DeviceType[DeviceType] === 'TABLET') {
        await ScreenOrientation.unlockAsync()

        // Add event listener specifically for tablet devices
        ScreenOrientation.addOrientationChangeListener(event => {
          const { orientation } = event.orientationInfo
          const isLandscape = landscapeOrientations.includes(orientation)

          setIsLandscape(isLandscape)
        })
      }
    }

    bootstrapAsync()

    return () => ScreenOrientation.removeOrientationChangeListeners()
  }, [])

  return isLandscape
}

export default useOrientation
