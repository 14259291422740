import React from 'react'
import styled from 'styled-components/native'
import * as Typography from '../../components/shared/Typography'
import { View } from 'react-native'
import { SmallButton } from '../../components/shared/Button'
import Avatar from '../../components/shared/Avatar'
import SectionHeader from '../../components/shared/SectionHeader'
import Announcement from '../../components/shared/Announcement'
import Assessment from '../../components/cards/Assessment'
import Subject from '../../components/cards/Subject'
import Cost from '../../components/cards/Cost'
import Delivery from '../../components/cards/Delivery'

const Container = styled.View`
  flex: 1;
`

const Content = styled.ScrollView`
  flex: 1;
  padding: 24px 16px;
  background-color: #ebecf2;
`

const BubbleRow = styled.View`
  flex-direction: row;
  margin: 0 -4px 16px -4px;
`

const Bubble = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0 4px;
  padding: 12px 0;
  border-radius: 16px;
`

const TextBubble = styled.View`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 22px;
`

const Details = styled.View`
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 42px;
`

const DetailsRow = styled.View`
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 16px;
`

const AroRow = styled.View`
  flex-direction: row;
  align-items: center;
`

const Label = styled.View`
  width: 135px;
`

const Value = styled.View`
  flex: 1;
`

const SectionScreen = ({ navigation, route }) => {
  const {
    section: { header, content },
    record,
  } = route.params

  return (
    <Container>
      <SectionHeader record={record} navigation={navigation}>
        <Typography.H1 color="#FFF">{header.title}</Typography.H1>

        {header.actions.map(action => (
          <SmallButton
            primary
            style={{ backgroundColor: '#2AAD79', marginRight: 15 }}
            onPress={() => {
              if (action.fieldGroups.length === 0) {
                alert('Coming soon!')

                return
              }
              navigation.navigate('SectionEdit', { record, action })
            }}
          >
            <Typography.Button color="#FFF">{action.title}</Typography.Button>
          </SmallButton>
        ))}
      </SectionHeader>

      <Content>
        {content.map(item => {
          switch (item.type) {
            case 'summary': {
              return item.rows.map(row => (
                <BubbleRow>
                  {row.map(field => (
                    <Bubble>
                      <Typography.Overline color="#757885">
                        {field.field}
                      </Typography.Overline>
                      <Typography.Subtitle color="#0E1012">
                        {field.value}
                      </Typography.Subtitle>
                    </Bubble>
                  ))}
                </BubbleRow>
              ))
            }
            case 'details': {
              return (
                <Details>
                  {item.fields
                    .map((field, index) => (
                      <DetailsRow key={index}>
                        <Label>
                          <Typography.Subtitle color="#0E1012">
                            {field.label}
                          </Typography.Subtitle>
                        </Label>
                        <Value>
                          {field.type === 'aro' ? (
                            field.values.map((value, index) => (
                              <AroRow
                                style={
                                  index === 0 &&
                                  field.values.length > 1 && { marginBottom: 8 }
                                }
                                key={value}
                              >
                                <View style={{ marginRight: 8 }}>
                                  <Avatar
                                    size={32}
                                    radius={16}
                                    userId={Math.floor(Math.random() * 10) + 1}
                                  />
                                </View>
                                <Typography.Body color="#757885">
                                  {value}
                                </Typography.Body>
                              </AroRow>
                            ))
                          ) : (
                            <Typography.Body color="#757885">
                              {field.values.join(', ')}
                            </Typography.Body>
                          )}
                        </Value>
                      </DetailsRow>
                    ))
                    .reduce((prev, curr) => [
                      prev,
                      <View
                        key={curr}
                        style={{ height: 1, backgroundColor: '#dde1ed' }}
                      />,
                      curr,
                    ])}
                  <DetailsRow
                    style={{
                      alignItems: 'center',
                      borderTopWidth: 1,
                      borderColor: '#dde1ed',
                    }}
                  >
                    <Label>
                      <Typography.Overline color="#757885">
                        10 empty fields
                      </Typography.Overline>
                    </Label>
                    <Value>
                      <Typography.Button color="#2AAD79">
                        Show
                      </Typography.Button>
                    </Value>
                  </DetailsRow>
                </Details>
              )
            }
            case 'textarea': {
              return (
                <TextBubble>
                  <View style={{ marginBottom: 8 }}>
                    {item.field ? (
                      <Typography.Subtitle color="#0E1012">
                        {item.field}
                      </Typography.Subtitle>
                    ) : null}
                  </View>

                  <Typography.Body color="#757885">
                    {item.value}
                  </Typography.Body>

                  {item.footer ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {item.footer.code && (
                        <Typography.Overline color="#757885">
                          {item.footer.code}
                        </Typography.Overline>
                      )}
                      {item.footer.buttons.length ? (
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          {item.footer.buttons.map(button => (
                            <SmallButton style={{ marginLeft: 20 }}>
                              <Typography.Button color="#2AAD79">
                                {button.title}
                              </Typography.Button>
                            </SmallButton>
                          ))}
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                </TextBubble>
              )
            }
            case 'heading': {
              return (
                <Typography.H3 style={{ marginBottom: 17 }}>
                  {item.title}
                </Typography.H3>
              )
            }
            case 'announcement': {
              return <Announcement message={item.title} />
            }
            case 'selection': {
              switch (item.format) {
                case 'groupby': {
                  return item.records.map((record, index) => (
                    <View key={index}>
                      <Typography.H3 style={{ marginBottom: 17 }}>
                        {record.title}
                      </Typography.H3>
                      {record.data.map((r, index) => {
                        switch (r.record_class) {
                          case 'assessment': {
                            return (
                              <Assessment
                                key={index}
                                assessment={r}
                                navigation={navigation}
                              />
                            )
                          }
                          case 'delivery': {
                            return <Delivery key={index} delivery={r} />
                          }
                          default: {
                            return null
                          }
                        }
                      })}
                    </View>
                  ))
                }
                default: {
                  return item.records.map((record, index) => {
                    switch (record.record_class) {
                      case 'subject': {
                        return <Subject key={index} subject={record} />
                      }
                      case 'cost': {
                        return <Cost key={index} cost={record} />
                      }
                      default: {
                        return null
                      }
                    }
                  })
                }
              }
            }
          }
        })}
      </Content>
    </Container>
  )
}

export default SectionScreen
