import React from 'react'
import { Text, View, StyleSheet } from 'react-native'

import CardMessage from './CardMessage'

const CardBody = ({
  record: { typing, latest_comment, unseen_by_me, push_notifications_enabled },
}) => {
  return (
    <View style={styles.cardBody}>
      {typing?.length > 0 ? (
        <Text style={[styles.messageText, { color: '#5fdba7' }]}>
          Someone is typing...
        </Text>
      ) : latest_comment ? (
        <CardMessage
          style={{ alignItems: 'flex-start' }}
          message={latest_comment}
          unseen={unseen_by_me}
          notifications_enabled={push_notifications_enabled}
        />
      ) : (
        <Text style={styles.messageText}>No comments...</Text>
      )}
    </View>
  )
}

export default React.memo(CardBody)

const styles = StyleSheet.create({
  cardBody: {
    padding: 16,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
  messageText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
    color: '#757885',
  },
  newCommentsText: {
    textAlign: 'right',
    marginTop: 8,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 18,
    color: '#A0A4B8',
  },
})
