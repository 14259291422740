import React, { useCallback, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import styled from 'styled-components/native'
import useOrientation from '../hooks/useOrientation'
import { useColorScheme } from 'react-native'

const ThemeContext = React.createContext([{}, () => {}])

const themeColors = {
  light: {
    status: {
      dark: {
        amber: '#B45309',
        yellow: '#946800',
        violet: '#5746AF',
        pink: '#CD1D8D',
        lime: '#5D770D',
        green: '#1D7A55',
        cyan: '#0C7792',
        blue: '#006ADC',
        grey: '#333955',
        red: '#CD2B31',
        brown: '#886349',
      },
      light: {
        amber: '#FFF4D5',
        yellow: '#FFFBD1',
        violet: '#F5F2FF',
        pink: '#FEEEF8',
        lime: '#EEFADC',
        green: '#E9F9F3',
        cyan: '#E7F9FB',
        blue: '#EDF6FF',
        grey: '#EBEEF7',
        red: '#FFEFEF',
        brown: '#F8F1EA',
      },
      darker: {
        amber: '#FFB224',
        yellow: '#F5D90A',
        violet: '#6E56CF',
        pink: '#D6409F',
        lime: '#99D52A',
        green: '#2AAD79',
        cyan: '#05A2C2',
        blue: '#0091FF',
        grey: '#676C85',
        red: '#E5484D',
        brown: '#AD7F58',
      },
    },
    primary: {
      darkest: '#334AC0',
      dark: '#334AC0',
      regular: '#544BF0',
      lighter: '#4B73DA',
      light: '#59B5FB',
      washed: '#E0E6F8',
    },
    secondary: {
      indigo: '#832BF7',
      grey: '#6548C3',
      blue: '#453885',
    },
    alerts: {
      green: '#00C5A8',
      yellow: '#FFD663',
      orange: '#FFD663',
      red: '#FFD663',
    },
    text: {
      primary: '#293239',
      secondary: '#787888',
      disabled: '#A0AAB8',
    },
    accents: {
      turquoise: '#00C5A8',
      aqua: '#46C6D1',
      pink: '#FFA9B3',
      babyPink: '#FFCCC5',
      deepPurple: '#00C5A8',
      russianViolet: '#00C5A8',
      separator: '#DDE1ED',
      button: '#DDE1ED',
      background: '#F4F5F9',
    },
    bg: {
      primary: '#FFFFFF',
      card: '#F4F5F9',
    },
    menuBg: '#0A406A',
    fonts: {
      primary: '#0E1012',
      secondary: '#757885',
    },
    separator: {
      light: '#DDE1ED',
    },
    hover: '#EBEEF7',
    tabs: {
      activeBg: '#F2FCF8',
      activeFont: '#1D7A55',
      bg: 'transparent',
      font: '#293239',
    },
  },
  dark: {
    status: {
      dark: {
        amber: '#B45309',
        yellow: '#946800',
        violet: '#5746AF',
        pink: '#CD1D8D',
        lime: '#5D770D',
        green: '#1D7A55',
        cyan: '#0C7792',
        blue: '#006ADC',
        grey: '#333955',
        red: '#CD2B31',
        brown: '#886349',
      },
      light: {
        amber: '#FFF4D5',
        yellow: '#FFFBD1',
        violet: '#F5F2FF',
        pink: '#FEEEF8',
        lime: '#EEFADC',
        green: '#E9F9F3',
        cyan: '#E7F9FB',
        blue: '#EDF6FF',
        grey: '#EBEEF7',
        red: '#FFEFEF',
        brown: '#F8F1EA',
      },
      darker: {
        amber: '#FFB224',
        yellow: '#F5D90A',
        violet: '#6E56CF',
        pink: '#D6409F',
        lime: '#99D52A',
        green: '#2AAD79',
        cyan: '#05A2C2',
        blue: '#0091FF',
        grey: '#676C85',
        red: '#E5484D',
        brown: '#AD7F58',
      },
    },
    primary: {
      darkest: '#334AC0',
      dark: '#334AC0',
      regular: '#544BF0',
      lighter: '#4B73DA',
      light: '#59B5FB',
      washed: '#E0E6F8',
    },
    secondary: {
      indigo: '#832BF7',
      grey: '#6548C3',
      blue: '#453885',
    },
    alerts: {
      green: '#00C5A8',
      yellow: '#FFD663',
      orange: '#FFD663',
      red: '#FFD663',
    },
    text: {
      primary: '#293239',
      secondary: '#787888',
      disabled: '#A0AAB8',
    },
    accents: {
      turquoise: '#00C5A8',
      aqua: '#46C6D1',
      pink: '#FFA9B3',
      babyPink: '#FFCCC5',
      deepPurple: '#00C5A8',
      russianViolet: '#00C5A8',
      separator: '#51515B',
      background: '#F4F5F9',
    },
    bg: {
      primary: '#16161A',
      card: '#2A2F3B',
    },
    menuBg: '#2A2F3B',
    fonts: {
      primary: '#FFF',
      secondary: '#B3B6BD',
    },
    separator: {
      light: '#51515B',
    },
    hover: '#3E485E',
    tabs: {
      activeBg:
        'linear-gradient(0deg, rgba(29, 122, 85, 0.2), rgba(29, 122, 85, 0.2))',
      activeFont: '#35C88D',
      bg: 'transparent',
      font: '#FFF',
    },
  },
}

const designSystem = {
  light: {
    colors: {
      gray: {
        50: '#F4F5F9',
        100: '#EBECF2',
        200: '#EBEEF7',
        300: '#DDE1ED',
        400: '#A0A4B8',
        500: '#676C85',
        600: '#474D69',
        700: '#333955',
        800: '#1E253B',
        900: '#0E1012',
      },

      primary: {
        50: '#F2FCF8',
        100: '#AEE2CD',
        200: '#78D8B1',
        300: '#53D29F',
        400: '#35C88D',
        500: '#2AAD79',
        600: '#249467',
        700: '#1D7A55',
        800: '#1A523C',
        900: '#082C1D',
      },

      secondary: {
        50: '#E9F2F9',
        100: '#CCDEEB',
        200: '#B4CCDF',
        300: '#92B4CE',
        400: '#5B90B9',
        500: '#3072A4',
        600: '#296797',
        700: '#184F79',
        800: '#0A406A',
        900: '#1B283F',
      },

      amber: {
        50: '#FFFBEB',
        100: '#FFF4D5',
        200: '#FFD386',
        300: '#FCD34D',
        400: '#FCB63D',
        500: '#FFB224',
        600: '#FFA01C',
        700: '#B45309',
        800: '#92400E',
        900: '#78350F',
      },

      red: {
        50: '#FFEFEF',
        100: '#FEE2E2',
        200: '#F9C6C6',
        300: '#FCA5A5',
        400: '#F87171',
        500: '#E5484D',
        600: '#DC3D43',
        700: '#CD2B31',
        800: '#991B1B',
        900: '#7F1D1D',
      },

      accent: {
        brown: {
          50: '#F8F1EA',
          100: '#E8CDB5',
          500: '#AD7F58',
          600: '#A07653',
          700: '#886349',
          900: '#3F2C22',
        },
        yellow: {
          50: '#FFFBD1',
          100: '#F9E68C',
          500: '#F5D90A',
          600: '#F7CE00',
          700: '#946800',
          900: '#35290F',
        },
        lime: {
          50: '#EEFADC',
          100: '#C9E894',
          500: '#99D52A',
          600: '#93C926',
          700: '#5D770D',
          900: '#263209',
        },
        cyan: {
          50: '#E7F9FB',
          100: '#AADEE6',
          500: '#05A2C2',
          600: '#0894B3',
          700: '#0C7792',
          900: '#04313C',
        },
        indigo: {
          50: '#F0F4FF',
          100: '#C6D4F9',
          500: '#3E63DD',
          600: '#3A5CCC',
          700: '#3451B2',
          900: '#101D46',
        },
        blue: {
          50: '#EDF6FF',
          100: '#B7D9F8',
          500: '#0091FF',
          600: '#0582F1',
          700: '#006ADC',
          900: '#00254D',
        },
        violet: {
          50: '#F5F2FF',
          100: '#D7CFF9',
          500: '#6E56CF',
          600: '#644FC1',
          700: '#5746AF',
          900: '#20134B',
        },
        pink: {
          50: '#FEEEF8',
          100: '#F3C6E2',
          500: '#D6409F',
          600: '#D23197',
          700: '#CD1D8D',
          900: '#3B0A2A',
        },
      },

      shades: {
        0: '#FFFFFF',
        100: '#000000',
      },
    },
    dark: {
      colors: {
        gray: {
          50: '#F4F5F9',
          100: '#EBECF2',
          200: '#EBEEF7',
          300: '#DDE1ED',
          400: '#A0A4B8',
          500: '#676C85',
          600: '#474D69',
          700: '#333955',
          800: '#1E253B',
          900: '#0E1012',
        },

        primary: {
          50: '#F2FCF8',
          100: '#AEE2CD',
          200: '#78D8B1',
          300: '#53D29F',
          400: '#35C88D',
          500: '#2AAD79',
          600: '#249467',
          700: '#1D7A55',
          800: '#1A523C',
          900: '#082C1D',
        },

        secondary: {
          50: '#E9F2F9',
          100: '#CCDEEB',
          200: '#B4CCDF',
          300: '#92B4CE',
          400: '#5B90B9',
          500: '#3072A4',
          600: '#296797',
          700: '#184F79',
          800: '#0A406A',
          900: '#1B283F',
        },

        amber: {
          50: '#FFFBEB',
          100: '#FFF4D5',
          200: '#FFD386',
          300: '#FCD34D',
          400: '#FCB63D',
          500: '#FFB224',
          600: '#FFA01C',
          700: '#B45309',
          800: '#92400E',
          900: '#78350F',
        },

        red: {
          50: '#FFEFEF',
          100: '#FEE2E2',
          200: '#F9C6C6',
          300: '#FCA5A5',
          400: '#F87171',
          500: '#E5484D',
          600: '#DC3D43',
          700: '#CD2B31',
          800: '#991B1B',
          900: '#7F1D1D',
        },

        accent: {
          brown: {
            50: '#2E201A',
            100: '#493528',
            500: '#AD7F58',
            600: '#BD8B60',
            700: '#DBA16E',
            900: '#FAF0E5',
          },
          yellow: {
            50: '#2C2100',
            100: '#493C00',
            500: '#F5D90A',
            600: '#FFEF5C',
            700: '#F0C000',
            900: '#FFFAD1',
          },
          lime: {
            50: '#1E260D',
            100: '#344213',
            500: '#99D52A',
            600: '#C4F042',
            700: '#87BE22',
            900: '#EFFBDD',
          },
          cyan: {
            50: '#072830',
            100: '#064150',
            500: '#05A2C2',
            600: '#00B1CC',
            700: '#00C2D7',
            900: '#E1F8FA',
          },
          indigo: {
            50: '#192140',
            100: '#22346E',
            500: '#3E63DD',
            600: '#5373E7',
            700: '#849DFF',
            900: '#EEF1FD',
          },
          blue: {
            50: '#10243E',
            100: '#0D3868',
            500: '#0091FF',
            600: '#369EFF',
            700: '#52A9FF',
            900: '#EAF6FF',
          },
          violet: {
            50: '#251E40',
            100: '#392C72',
            500: '#6E56CF',
            600: '#7C66DC',
            700: '#9E8CFC',
            900: '#F1EEFE',
          },
          pink: {
            50: '#3A182F',
            100: '#601D48',
            500: '#D6409F',
            600: '#E34BA9',
            700: '#F65CB6',
            900: '#FEEBF7',
          },
        },

        shades: {
          0: '#FFFFFF',
          100: '#000000',
        },
      },
    },
  },

  // Horizontal (X), Vertical (Y), Blur, Spread (optional), Color
  dropShadows: {
    light: {
      none: {
        // Blur: 0, Y:0, A: 0%
        boxShadow: '0, 0, 0, rgba(0,0,0,0)',
      },
      xs: {
        // Blur: 8, Y:4, A: 8%
        boxShadow: '0, 4, 8, rgba(0,0,0,0.8)',
      },
      sm: {
        // Blur: 12, Y:6, A: 11%
        boxShadow: '0, 6, 12, rgba(0,0,0,0.11)',
      },
      md: {
        // Blur:18, Y:9, A: 15%
        // Used for dropdowns
        boxShadow: '0, 9, 18, rgba(0,0,0,0.15)',
      },
      lg: {
        // Blur: 37, Y:13, A: 21%
        // Used for modal dialogs
        boxShadow: '0, 13, 37, rgba(0,0,0,0.21)',
      },
      xl: {
        // Blur: 56, Y:20, A: 29%
        boxShadow: '0, 20, 56, rgba(0,0,0,0.29)',
      },
    },

    dark: {
      none: {
        // Blur: 0, Y:0, A: 0%
        boxShadow: '0, 0, 0, rgba(0,0,0,0)',
      },
      xs: {
        // Blur: 8, Y:4, A: 8%
        boxShadow: '0, 4, 8, rgba(0,0,0,0.8)',
      },
      sm: {
        // Blur: 12, Y:6, A: 11%
        boxShadow: '0, 6, 12, rgba(0,0,0,0.11)',
      },
      md: {
        // Blur:18, Y:9, A: 15%
        // Used for dropdowns
        boxShadow: '0, 9, 18, rgba(0,0,0,0.15)',
      },
      lg: {
        // Blur: 37, Y:13, A: 21%
        // Used for modal dialogs
        boxShadow: '0, 13, 37, rgba(0,0,0,0.21)',
      },
      xl: {
        // Blur: 56, Y:20, A: 29%
        boxShadow: '0, 20, 56, rgba(0,0,0,0.29)',
      },
    },
  },
}

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.16)',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.78,
  shadowRadius: 12.0,
  elevation: 24,
}

const DEFAULT_THEME = 'light'

const ThemeProvider = props => {
  const [state, setState] = useState({
    theme: DEFAULT_THEME,
  })

  const isLandscape = useOrientation()

  const fontColor = props => {
    const { fonts } = themeColors[state.theme]

    if (props.primary) return fonts.primary
    if (props.color) return props.color
    return fonts.secondary
  }

  const typographyContext = React.useMemo(
    () => ({
      Display: styled.Text`
        font-family: proxima-nova-bold;
        font-size: ${isLandscape ? `40px` : `34px`};
        line-height: 48px;
        color: ${props => fontColor(props)};
      `,
      Dashboard: styled.Text`
        font-family: proxima-nova-bold;
        font-size: 34px;
        line-height: 46px;
        color: ${props => fontColor(props)};
      `,
      H1: styled.Text`
        font-family: proxima-nova-bold;
        font-size: ${isLandscape ? `28px` : `34px`};
        line-height: 32px;
        color: ${props => fontColor(props)};
      `,
      H2: styled.Text`
        font-family: proxima-nova-bold;
        font-size: ${isLandscape ? `24px` : `26px`};
        line-height: 32px;
        color: ${props => fontColor(props)};
      `,
      H3: styled.Text`
        font-family: proxima-nova-bold;
        font-size: ${isLandscape ? `20px` : `22px`};
        line-height: ${isLandscape ? `24px` : `27px`};
        color: ${props => fontColor(props)};
      `,
      H4: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `17px` : `20px`};
        line-height: 22px;
        color: ${props => fontColor(props)};
      `,
      H5: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `15px` : `17px`};
        line-height: 22px;
        color: ${props => fontColor(props)};
      `,
      PropertyName: styled.Text`
        font-family: proxima-nova-bold;
        font-size: ${isLandscape ? `17px` : `20px`};
        line-height: 22px;
        color: ${props => fontColor(props)};
      `,
      Subtitle: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `15px` : `17px`};
        line-height: ${isLandscape ? `20px` : `20px`};
        color: ${props => fontColor(props)};
      `,
      Body: styled.Text`
        font-family: proxima-nova;
        font-size: ${isLandscape ? `15px` : `17px`};
        line-height: ${isLandscape ? `22px` : `24px`};
        color: ${props => fontColor(props)};
      `,
      Button: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `15px` : `17px`};
        color: ${props => fontColor(props)};
        letter-spacing: 0.2px;
      `,
      HeaderButton: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `14px` : `17px`};
        color: ${props => fontColor(props)};
        letter-spacing: 0.2px;
      `,
      Overline: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `13px` : `14px`};
        line-height: 18px;
        color: ${props => fontColor(props)};
        letter-spacing: 0.2px;
        text-transform: uppercase;
        text-align: ${props => (props.center ? 'center' : 'left')};
      `,
      Label: styled.Text`
        font-family: proxima-nova-semibold;
        font-size: ${isLandscape ? `13px` : `14px`};
        line-height: 18px;
        color: ${props => fontColor(props)};
      `,
      Caption: styled.Text`
        font-family: proxima-nova;
        font-size: ${isLandscape ? `13px` : `14px`};
        line-height: 18px;
        color: ${props => fontColor(props)};
      `,
      Initial: styled.Text`
        font-family: proxima-nova;
        font-size: 14px;
        line-height: 18px;
        color: ${props => fontColor(props)};
      `,
    }),
    [isLandscape, state.theme]
  )

  const getThemeFromLocalStorage = useCallback(async () => {
    try {
      const value = await AsyncStorage.getItem('@theme')
      if (value !== null) {
        return value
      }
    } catch (e) {
      return null
    }
  }, [])

  const saveThemeToLocalStorage = useCallback(async value => {
    try {
      await AsyncStorage.setItem('@theme', value)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const appearance = useColorScheme()

  useEffect(() => {
    ;(async () => {
      // check async storage first
      const savedTheme = await getThemeFromLocalStorage()

      if (savedTheme) {
        setState(state => ({ ...state, theme: savedTheme }))
      } else {
        if (appearance !== 'no-preference') {
          setState(state => ({ ...state, theme: appearance }))
        }
      }
    })()
  }, [getThemeFromLocalStorage])

  const toggleTheme = theme => {
    const newTheme = { light: 'dark', dark: 'light' }[theme]

    saveThemeToLocalStorage(newTheme)
    setState(state => ({ ...state, theme: newTheme }))
  }

  return (
    <ThemeContext.Provider
      value={{
        theme: state.theme,
        themeColors: themeColors[state.theme],
        designSystem: designSystem['light'],
        typography: typographyContext,
        shadowStyles,
        toggleTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider }
