import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'

// contexts
import { ThemeContext } from '../../contexts/ThemeContext'

import { AlignedRow } from '../shared/Layout'
import { MediumButton } from '../shared/Button'

import { Portal } from 'react-native-portalize'
import WorktribeAlert from 'react-native-awesome-alerts'

interface DialogAction {
  name: string
  primary: boolean
  action: Function
}

interface DialogProps {
  message: string
  onMount: Function
  onUnmount: Function
  actions: DialogAction[]
}

interface InformationDialogProps {
  dialogProps: DialogProps
  close: () => void
}

const InformationDialog: React.FC<InformationDialogProps> = ({
  dialogProps,
  close,
}) => {
  const {
    typography: { Button, Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const content = () => {
    useEffect(() => {
      dialogProps?.onMount?.()
      return () => dialogProps?.onUnmount?.()
    }, [dialogProps])

    if (!dialogProps) return null

    return (
      <View style={{ width: 500, maxHeight: 715 }}>
        <Subtitle primary>{dialogProps.message}</Subtitle>

        <AlignedRow justifyContent="flex-end" style={{ marginTop: 20 }}>
          {dialogProps.actions?.map(({ name, action, primary }) => (
            <MediumButton
              primary={primary}
              transparent={!primary}
              onPress={action}
              style={{ marginLeft: 16 }}
            >
              <Button color={primary ? 'white' : '#2AAD79'}>{name}</Button>
            </MediumButton>
          ))}
        </AlignedRow>
      </View>
    )
  }

  return (
    <Portal>
      <WorktribeAlert
        useNativeDriver={true}
        contentContainerStyle={{
          backgroundColor: bg.card,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 20,
          shadowColor: 'rgba(0, 0, 0, 0.06)',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0,
          shadowRadius: 32,
        }}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        alertContainerStyle={{ padding: 0 }}
        show={!!dialogProps}
        customView={content()}
        onDismiss={close}
      />
    </Portal>
  )
}

export default InformationDialog
