import React, { useCallback, useContext, useState, useMemo } from 'react'
import styled from 'styled-components/native'
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  Text,
  Image,
} from 'react-native'
import ColumnHeader from './ui/ColumnHeader'
import { BigButton, SmallButton, TinyButton } from '../../shared/Button'
import { AlignedRow, CenteredView } from '../../shared/Layout'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import WorktribeIcon from '../../shared/WorktribeIcon'
import { Tab, TabContainer } from './ui/Tabs'
import { PieChart } from 'react-native-chart-kit'
import Avatar from '../../shared/Avatar'
import Wizard from '../Wizard'
import Kanban from './Kanban'
import CalendarView from '../CalendarView'
import Selection from '../Selection'
import Searchatron from '../Searchatron'
import useSocketMethods from '../../../hooks/useSocketMethods'
import useSocketListener from '../../../hooks/useSocketListener'
import { back } from '../../../utils/back'

const ServiceTabs = ({ tabs, selectedTab, handleTabSelect }) => {
  const {
    typography: { Button },
    themeColors,
  } = useContext(ThemeContext)
  return (
    <TabContainer style={{ paddingBottom: 20, marginHorizontal: 40 }}>
      {tabs.map(tab => (
        <Tab
          key={tab}
          onPress={() => handleTabSelect(tab)}
          style={
            selectedTab === tab && {
              backgroundColor: themeColors.tabs.activeBg,
            }
          }
        >
          <Button
            color={
              selectedTab === tab
                ? themeColors.tabs.activeFont
                : themeColors.tabs.font
            }
          >
            {tab}
          </Button>
        </Tab>
      ))}
    </TabContainer>
  )
}

export const RecentActivity = () => {
  const {
    typography: { H4, Button },
    themeColors: { bg },
  } = useContext(ThemeContext)
  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <AlignedRow justifyContent="space-between" style={{ marginBottom: 11 }}>
        <H4 primary>Recent activity</H4>
        <TinyButton transparent>
          <Button color="#1D7A55">View all</Button>
        </TinyButton>
      </AlignedRow>

      <Activity
        title="Commercially led clinical trial"
        subtitle="Moved “Commercially led ..” contract to Published"
        userId={1}
      />
      <Activity
        title="Commercially led clinical trial"
        subtitle="Moved “Commercially led ..” contract to Published"
        userId={2}
      />
      <Activity
        title="Commercially led clinical trial"
        subtitle="Moved “Commercially led ..” contract to Published"
        userId={3}
      />
      <Activity
        title="Commercially led clinical trial"
        subtitle="Moved “Commercially led ..” contract to Published"
        userId={4}
      />
    </Bubble>
  )
}

const Activity = ({ title, subtitle, userId }) => {
  const {
    typography: { Caption, Subtitle, Label },
  } = useContext(ThemeContext)
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginVertical: 9,
      }}
    >
      <AlignedRow style={{ flex: 1 }}>
        <TouchableOpacity style={{ marginRight: 12 }}>
          <Avatar size={36} radius={12} userId={userId} />
        </TouchableOpacity>
        <View>
          <Label>{title}</Label>
          <Subtitle primary>{subtitle}</Subtitle>
        </View>
      </AlignedRow>
      <Caption>4:59PM</Caption>
    </View>
  )
}

export const Metric = ({ name, value, color }) => {
  const {
    typography: { H4, Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)
  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <H4 primary>{name}</H4>
      <AlignedRow style={{ marginTop: 16 }}>
        <Text
          style={{
            fontFamily: 'proxima-nova-bold',
            fontSize: 34,
            color,
            lineHeight: 46,
            marginRight: 8,
          }}
        >
          {value}
        </Text>
        <AlignedRow style={{ marginTop: 7 }}>
          <View style={{ marginRight: 8 }}>
            <WorktribeIcon name="graph-stats-ascend" />
          </View>
          <Subtitle color="#22A566">8%</Subtitle>
        </AlignedRow>
      </AlignedRow>
      <View style={{ alignItems: 'flex-start' }}>
        <WorktribeIcon name="line" color={color} />
        <View style={{ marginTop: -10 }}>
          <WorktribeIcon name="diagram" />
        </View>
      </View>
    </Bubble>
  )
}

export const StatBox = ({ name }) => {
  const {
    typography: { H4, Dashboard, Button },
    themeColors: { bg },
  } = useContext(ThemeContext)

  let description =
    name === 'Avg time since last review'
      ? '6 weeks'
      : name === 'Past their review dates'
      ? '12 Risks'
      : name === 'Longest time with no review'
      ? '15 weeks'
      : '2 Risks'

  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <View style={{ flex: 1, alignItems: 'flex-start' }}>
        <H4 primary>{name}</H4>

        <Dashboard
          color={
            name === 'Due a review in the next quarter' ? '#DC3D43' : '#333955'
          }
          style={{ marginVertical: 8 }}
        >
          {description}
        </Dashboard>

        <SmallButton transparent>
          <Button color="#1D7A55">View</Button>
        </SmallButton>
      </View>
    </Bubble>
  )
}

const max = 90
const min = -90
const steps = [-70, -49, -25, 0, 22.5, 47, 68]
export const Gauge = ({ name }) => {
  const {
    typography: { Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)

  let rotation = Math.floor(Math.random() * (max - min + 1) + min)

  return (
    // <Bubble style={{ backgroundColor: bg.card }}>
    <View
      style={{
        alignItems: 'center',
        paddingVertical: 25,
        paddingHorizontal: 65,
        borderRadius: 24,
        backgroundColor: bg.primary,
        margin: 12,
        minWidth: 350,
        flex: 1,
      }}
    >
      <View
        style={{
          position: 'relative',
          width: 220,
          height: 110,
          borderTopLeftRadius: 110,
          borderTopRightRadius: 110,
          background:
            'conic-gradient(from -24.38deg at 50% 100%, #FBBF24 0deg, #F59E0B 0.41deg, #F59E0B 23.76deg, #E08938 24.16deg, #E08938 47.74deg, #F87171 48.5deg, #F87171 71.92deg, #DC2626 72.5deg, #DC2626 93.46deg, #B91C1C 93.89deg, #AB2B25 114.63deg, #2AAD79 269.99deg, #2AAD79 289.91deg, #35C88D 290.61deg, #35C88D 314.16deg, #53D29F 315.16deg, #53D29F 336.38deg, #FBBF24 337.19deg, #FBBF24 360deg)',
        }}
      >
        {steps.map(step => (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 110,
              width: 2,
              height: 120,
              backgroundColor: bg.primary,
              transformOrigin: 'bottom',
              transform: `rotate(${step}deg)`,
            }}
          />
        ))}

        <View
          style={{
            position: 'absolute',
            top: 30,
            left: 30,
            width: 160,
            height: 80,
            borderTopLeftRadius: 110,
            borderTopRightRadius: 110,
            backgroundColor: bg.primary,
          }}
        />

        <WorktribeIcon
          style={{
            position: 'absolute',
            bottom: 0,
            left: 102,
            transformOrigin: 'bottom',
            transform: `rotate(${rotation}deg)`,
          }}
          name="needle"
        />
      </View>

      <View style={{ marginTop: 8, alignItems: 'center' }}>
        <Subtitle primary>{name}</Subtitle>

        <AlignedRow>
          <WorktribeIcon name="h-stats-ascend" />
          <Subtitle style={{ marginLeft: 8 }} color="#B91C1C">
            1 point
          </Subtitle>
        </AlignedRow>
      </View>
    </View>
    // </Bubble>
  )
}

const pieChartData = [
  { name: 'Seoul', population: 21500000, color: '#22A566' },
  { name: 'Toronto', population: 2800000, color: '#FFA9B3' },
  { name: 'Beijing', population: 527612, color: '#F2CE6A' },
  { name: 'New York', population: 8538000, color: '#46C6D1' },
  { name: 'Moscow', population: 11920000, color: '#5F44C4' },
]
const statuses = [
  {
    name: 'Requested',
    color: '#FFA9B3',
  },
  {
    name: 'Accepted',
    color: '#F2CE6A',
  },
  {
    name: 'Estimated',
    color: '#46C6D1',
  },
  {
    name: 'In progress',
    color: '#5F44C4',
  },
  {
    name: 'Published',
    color: '#3A2449',
  },
  {
    name: 'Published',
    color: '#22A566',
  },
]

export const Chart = () => {
  const {
    typography: { H4, Subtitle },
    themeColors: { bg },
  } = useContext(ThemeContext)
  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <H4 primary>Contracts by type</H4>
      <AlignedRow justifyContent="space-between" style={{ marginTop: 24 }}>
        <View style={{ flex: 1 }}>
          <PieChart
            data={pieChartData}
            height={220}
            width={220}
            chartConfig={{
              backgroundColor: '#b90602',
              backgroundGradientFrom: '#e53935',
              backgroundGradientTo: '#ef5350',
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              style: {
                borderRadius: 16,
              },
            }}
            accessor={'population'}
            backgroundColor={'transparent'}
            paddingLeft={'35'}
            hasLegend={false}
          />
        </View>
        <View style={{ flex: 1 }}>
          {statuses.map(status => (
            <AlignedRow
              key={status.color}
              justifyContent="space-between"
              style={{ marginVertical: 7 }}
            >
              <AlignedRow>
                <View
                  style={{
                    backgroundColor: status.color,
                    height: 14,
                    width: 14,
                    borderRadius: 7,
                    marginRight: 12,
                    marginBottom: 3,
                  }}
                />
                <Subtitle>{status.name}</Subtitle>
              </AlignedRow>
              <Subtitle primary>23</Subtitle>
            </AlignedRow>
          ))}
        </View>
      </AlignedRow>
    </Bubble>
  )
}

const horizontalBarHeadings = [
  'Financial',
  'Reputational',
  'Compliance',
  'Operational',
  'Strategic',
]

export const HorizontalBar = ({ name }) => {
  const [barGrid] = useState(() => {
    let arr = []
    for (let i = 0; i < 5; i++) {
      arr[horizontalBarHeadings[i]] = []
      for (let j = 0; j < 5; j++) {
        arr[horizontalBarHeadings[i]][j] = null
      }
    }
    return arr
  })

  const {
    typography: { H4, Caption, Label },
    themeColors: { bg, accents },
  } = useContext(ThemeContext)

  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <AlignedRow justifyContent="space-between" style={{ marginBottom: 24 }}>
        <H4 primary>{name}</H4>

        <AlignedRow>
          <AlignedRow>
            <View
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: '#2AAD79',
                marginRight: 8,
              }}
            />
            <Caption>Low</Caption>
          </AlignedRow>

          <AlignedRow style={{ marginLeft: 13 }}>
            <View
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: '#F59E0B',
                marginRight: 8,
              }}
            />
            <Caption>Medium</Caption>
          </AlignedRow>

          <AlignedRow style={{ marginLeft: 13 }}>
            <View
              style={{
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: '#DC2626',
                marginRight: 8,
              }}
            />
            <Caption>High</Caption>
          </AlignedRow>
        </AlignedRow>
      </AlignedRow>

      {Object.entries(barGrid).map(([column, rows]) => {
        const [labelWidth, setLabelWidth] = useState(0)

        const greenBarPercent = useMemo(
          () => Math.floor(Math.random() * (40 - 20 + 1) + 20),
          [column]
        )

        const yellowBarPercent = useMemo(
          () => Math.floor(Math.random() * (60 - 40 + 1) + 40),
          [column]
        )

        const redBarPercent = useMemo(
          () => Math.floor(Math.random() * (80 - 60 + 1) + 60),
          [column]
        )

        return (
          <AlignedRow
            style={{
              position: 'relative',
              flex: 1,
              alignItems: 'flex-start',
            }}
          >
            <View
              onLayout={({ nativeEvent: { layout } }) =>
                setLabelWidth(layout.width)
              }
              style={{
                flex: 1,
                height: '100%',
                borderRightWidth: 1,
                borderColor: accents.separator,
                alignItems: 'flex-end',
                paddingRight: 8,
              }}
            >
              <Label>{column}</Label>
            </View>

            {rows.map((row, rowIdx) => (
              <>
                <View
                  style={{
                    height: '100%',
                    flex: 1,
                    borderRightWidth: 1,
                    borderColor: accents.separator,
                    zIndex: -rowIdx,
                  }}
                />

                <View
                  style={{
                    position: 'absolute',
                    left: labelWidth,
                    height: 20,
                    width: `${redBarPercent}%`,
                    backgroundColor: '#DC2626',
                    borderTopEndRadius: 16,
                    borderBottomEndRadius: 16,
                  }}
                />

                <View
                  style={{
                    position: 'absolute',
                    left: labelWidth,
                    height: 20,
                    width: `${yellowBarPercent}%`,
                    backgroundColor: '#F59E0B',
                    borderTopEndRadius: 16,
                    borderBottomEndRadius: 16,
                  }}
                />

                <View
                  style={{
                    position: 'absolute',
                    left: labelWidth,
                    height: 20,
                    width: `${greenBarPercent}%`,
                    backgroundColor: '#2AAD79',
                    borderTopEndRadius: 16,
                    borderBottomEndRadius: 16,
                  }}
                />
              </>
            ))}
          </AlignedRow>
        )
      })}
    </Bubble>
  )
}

export const BostonGrid = ({ name }) => {
  const [grid] = useState(() => {
    const arr = []
    for (let i = 0; i < 5; i++) {
      arr[i] = []
      for (let j = 0; j < 5; j++) {
        arr[i][j] =
          i === j
            ? '#FCB63D'
            : i === j + 1
            ? '#78D8B1'
            : i === j - 1
            ? '#FFA01C'
            : j < 2 || (i == 4 && j === 2)
            ? '#1D7A55'
            : '#DC3D43'
      }
    }
    return arr
  })

  const {
    typography: { H4 },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <Bubble style={{ backgroundColor: bg.card }}>
      <H4 primary>{name}</H4>

      <View style={{ alignItems: 'center', padding: 24 }}>
        <AlignedRow style={{ flexWrap: 'wrap' }}>
          <Grid grid={grid} />

          <View style={{ marginHorizontal: 32 }}>
            <WorktribeIcon name="heatmap" />
          </View>

          <Grid grid={grid} reduced={true} />
        </AlignedRow>
      </View>
    </Bubble>
  )
}

const Grid = ({ grid, reduced }) => {
  const {
    typography: { Label },
  } = useContext(ThemeContext)

  const riskMap = {
    '#1D7A55': 0.2,
    '#78D8B1': 0.4,
    '#FCB63D': 0.6,
    '#FFA01C': 0.7,
    '#DC3D43': 0.8,
  }

  let showReduced = color => {
    return reduced
      ? Math.random() > riskMap[color]
      : Math.random() < riskMap[color]
  }

  return (
    <View
      style={{
        position: 'relative',
        paddingLeft: 8,
        paddingBottom: 8,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#A0A4B8',
      }}
    >
      {grid?.map((row, rowIndex) => (
        <AlignedRow>
          {row?.map((color, squareIndex) => (
            <View
              style={{
                width: 34,
                height: 34,
                backgroundColor: color,
                margin: 1,
                borderRadius: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {showReduced(color) && (
                <View
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                    backgroundColor: 'black',
                    borderColor: 'white',
                  }}
                />
              )}
            </View>
          ))}
        </AlignedRow>
      ))}

      <View
        style={{
          position: 'absolute',
          backgroundColor: '#A0A4B8',
          height: 8,
          width: 8,
          borderRadius: 4,
          bottom: -4,
          left: -4,
        }}
      />

      <Label
        style={{
          position: 'absolute',
          left: -35,
          top: 80,
          transform: `rotate(-90deg)`,
        }}
      >
        Impact
      </Label>

      <Label style={{ position: 'absolute', bottom: -25, left: 65 }}>
        Likelihood
      </Label>
    </View>
  )
}

const colors = {
  turquoise: '#00C5A8',
  red: '#E94545',
  orange: '#E08938',
  purple: '#5F44C5',
  yellow: '#F2CE6A',
  green: '#78D8B1',
  pink: '#FFA9B3',
  violet: '#3A2449',
  teal: '#46C6D1',
}

export const HeroHeading = props => {
  const {
    typography: { H1, Button, Body },
    themeColors: { bg },
  } = useContext(ThemeContext)
  const [wizard, setWizard] = useState(false)

  const {
    headline,
    description,
    primaryactionlabel,
    primaryactionname,
    secondaryactionlabel,
    secondaryactionname,
  } = props

  if (wizard) return <Wizard />

  return (
    <Hero style={{ backgroundColor: bg.card }}>
      <H1 style={{ marginBottom: 25 }} primary>
        {headline}
      </H1>
      <Body primary>{description}</Body>
      <View style={{ marginTop: 70 }}>
        {primaryactionlabel && (
          <BigButton
            primary
            style={{ marginBottom: 20, width: 335 }}
            onPress={() => setWizard(true)}
          >
            <Button color="#FFF">{primaryactionlabel}</Button>
          </BigButton>
        )}

        {secondaryactionlabel && (
          <BigButton style={{ width: 335 }}>
            <Button color="#2AAD79">{secondaryactionlabel}</Button>
          </BigButton>
        )}
      </View>
    </Hero>
  )
}

export const ServiceHeader = ({ header, setShowSearch }) => {
  const { actions, title } = header

  const {
    typography: { H2, Button },
    themeColors: { accents, fonts },
  } = useContext(ThemeContext)

  return (
    <ColumnHeader>
      <AlignedRow style={{ flex: 1 }}>
        <TouchableOpacity
          style={{
            marginRight: 12,
            borderWidth: 1,
            borderColor: accents.separator,
            borderRadius: 70,
            paddingVertical: 13,
            paddingHorizontal: 15,
          }}
          onPress={back}
        >
          <WorktribeIcon color={fonts.primary} name="keyboard-arrow-right" />
        </TouchableOpacity>

        <H2 primary numberOfLines={1}>
          {capitalize(decodeURI(title))}
        </H2>
      </AlignedRow>

      <ButtonRow>
        <SearchIcon
          style={{ borderColor: accents.separator }}
          onPress={() => setShowSearch(show => !show)}
        >
          <WorktribeIcon size={20} name="search-alternate" />
        </SearchIcon>

        {actions?.length
          ? actions.map(action => (
              <SmallButton
                key={action.name}
                onPress={() => alert(action.action)}
                primary
                style={{ marginLeft: 20 }}
              >
                <Button color="#FFF">{action.name}</Button>
              </SmallButton>
            ))
          : null}

        {/* <SmallButton
          transparent
          style={{ marginLeft: 20, borderColor: accents.separator }}
        >
          <Ellipsis color="#1D7A55" />
        </SmallButton> */}
      </ButtonRow>
    </ColumnHeader>
  )
}

const ServiceSection = ({ section }) => {
  if (section.name === 'Calendar') {
    return (
      <CalendarView
        title={section.name}
        renderer={section.blocks[0].columns[0].items[0]}
      />
    )
  }

  return section.blocks.map(block => (
    <BubbleRow>
      {block.columns.map(column =>
        column.items.map(item => {
          switch (item.type) {
            case 'metric': {
              return <Metric {...{ ...item }} color={colors[item.color]} />
            }
            case 'piechart': {
              return <Chart />
            }
            case 'recentactivity': {
              return <RecentActivity />
            }
            case 'hero': {
              return <HeroHeading {...{ ...item }} />
            }
            case 'grid': {
              return (
                <View style={{ paddingHorizontal: 12, flex: 1 }}>
                  <Selection {...{ ...item }} />
                </View>
              )
            }
            case 'kanban': {
              return <Kanban invert />
            }
            default: {
              return null
            }
          }
        })
      )}
    </BubbleRow>
  ))
}

const ServiceView = ({ title }) => {
  const {
    typography: { Subtitle },
  } = useContext(ThemeContext)

  const navigation = useNavigation()

  const [service, setService] = useState(null)
  const [tabs, setTabs] = useState([])
  const [serviceError, setServiceError] = useState(null)

  const [selectedTab, setSelectedTab] = useState('')
  const [showSearch, setShowSearch] = useState(false)

  const { service_get } = useSocketMethods()

  const onMessage = useCallback(
    message => {
      const { data, method, status, privatedetail } = JSON.parse(message.data)

      if (status !== 'OK') {
        let errorMessage = ''
        if (status) errorMessage += status
        if (privatedetail) errorMessage += `: ${privatedetail}`

        setServiceError(errorMessage)
      }

      if (!data) return

      if (method === 'service_get') {
        ;(async function () {
          if (tabs.length) {
            const { sections } = data

            const activeSection = sections.find(section => section.blocks)
            if (activeSection) {
              setService(service => {
                return {
                  ...service,
                  section: sections.find(
                    section => section.name === activeSection.name
                  ),
                }
              })
            }
          } else {
            const { header, sections } = data

            // determine array of tabs from loaded sections
            const tabs = sections.map(section => section.name)
            setTabs(tabs)

            // determine first tab from either the defaultTab
            // or the first available
            const tab = (await getInitialTab()) ?? tabs[0]
            setSelectedTab(tab)

            const section = sections.find(section => section.name === tab)

            setService({
              section,
              header,
            })
          }
        })()
      }
    },
    [tabs]
  )

  useSocketListener(onMessage)

  useFocusEffect(
    useCallback(() => {
      async function loadService() {
        const params = {
          servicename: title,
        }

        // try and load a saved tab location for this service
        const defaultTab = await getInitialTab()

        // if found then include in the parameters to return that specific block
        if (defaultTab) {
          params['returnsections'] = [defaultTab]
        }

        service_get(params)
      }

      loadService()
    }, [title, service_get])
  )

  const getInitialTab = async () => {
    const savedStateString = await AsyncStorage.getItem('@serviceTab')
    const state = savedStateString ? JSON.parse(savedStateString) : undefined

    if (state !== undefined && state[title]) {
      return state[title]
    } else {
      return null
    }
  }

  const updateServiceTab = async tab => {
    try {
      let parsedServiceTab
      const selected = await AsyncStorage.getItem('@serviceTab')
      if (selected) {
        const parsed = JSON.parse(selected)

        parsedServiceTab = {
          ...parsed,
          [title]: tab,
        }
      } else {
        parsedServiceTab = {
          [title]: tab,
        }
      }

      const jsonValue = JSON.stringify(parsedServiceTab)
      AsyncStorage.setItem('@serviceTab', jsonValue)
    } catch (e) {
      setError(e.message)
    }
  }

  const handleTabSelect = async tab => {
    await updateServiceTab(tab)

    setService(service => {
      return {
        ...service,
        section: null,
      }
    })

    service_get({
      servicename: title,
      returnsections: [tab],
    })

    setSelectedTab(tab)
  }

  if (serviceError) {
    return (
      <CenteredView>
        <Image
          style={{ height: 200, width: 200 }}
          source={require('../../../../assets/icons/robot.png')}
        />
        <AlignedRow>
          <TouchableOpacity
            style={{
              marginRight: 12,
              borderWidth: 1,
              borderColor: '#DDE1ED',
              borderRadius: 70,
              paddingVertical: 13,
              paddingHorizontal: 15,
            }}
            onPress={() => navigation.goBack()}
          >
            <WorktribeIcon name="keyboard-arrow-right" />
          </TouchableOpacity>
          <Subtitle color="#757885" style={{ marginVertical: 20 }}>
            {serviceError}
          </Subtitle>
        </AlignedRow>
      </CenteredView>
    )
  }

  if (!service) {
    return (
      <CenteredView>
        <ActivityIndicator size="large" />
      </CenteredView>
    )
  }

  return (
    <>
      {showSearch && <Searchatron setShowSearch={setShowSearch} />}

      <ServiceHeader header={service.header} setShowSearch={setShowSearch} />

      {tabs.length > 1 && (
        <ServiceTabs
          selectedTab={selectedTab}
          handleTabSelect={handleTabSelect}
          tabs={tabs}
        />
      )}

      <Container contentContainerStyle={{ flex: 1 }}>
        {service?.section ? (
          <ServiceSection title={title} section={service.section} />
        ) : (
          <ActivityIndicator />
        )}
      </Container>
    </>
  )
}

export default ServiceView

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const Container = styled.ScrollView`
  flex: 1;
`

const ButtonRow = styled.View`
  flex-direction: row;
  align-items: center;
`

const Hero = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 56px 40px;
  margin-bottom: 40px;
  margin: 0 12px;
`

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  margin: 12px;
  padding: 24px;
  border-radius: 20px;
  min-width: 357px;
`

const SearchIcon = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border-width: 1px;
  border-color: #dde1ed;
`
