import React from 'react'
import styled from 'styled-components/native'
import * as Typography from '../../components/shared/Typography'
import { View, Text } from 'react-native'
import { SmallButton } from '../../components/shared/Button'
import Avatar from '../../components/shared/Avatar'
import AssessmentHeader from '../../components/shared/AssessmentHeader'
import Announcement from '../../components/shared/Announcement'
import InputField from '../../components/shared/InputField'
import Calendar from '../../components/shared/Calendar'
import Assessment from '../../components/cards/Assessment'
import Subject from '../../components/cards/Subject'
import Cost from '../../components/cards/Cost'
import Delivery from '../../components/cards/Delivery'

const DATA = [
  {
    type: 'heading',
    title: 'General',
  },
  {
    type: 'summary',
    rows: [
      [
        {
          field: 'Length',
          value: '1h 30min',
        },
        {
          field: 'Outcomes tested',
          value: 'C4879 and R53',
        },
      ],
      [
        {
          field: 'Assessment period',
          value: '29',
        },
        {
          field: 'Code',
          value: '3248',
        },
      ],
      [
        {
          field: '',
          value: 'Must pass',
        },
        {
          field: '',
          value: 'Repeat assessment',
        },
      ],
      [
        {
          field: '',
          value: 'Group work',
        },
        {
          field: '',
          value: 'Cover sheet required',
        },
      ],
    ],
  },
  {
    type: 'textarea',
    field: 'Description',
    value:
      'Description of this examination assessment that is very long most likely. Description of this examinatio...',
  },
  {
    type: 'textarea',
    field: 'How you will receive feedback',
    value:
      'Description of this examination assessment that is very long most likely. Description of this examinatio...',
  },
  {
    type: 'heading',
    title: 'Marking',
  },
  {
    type: 'summary',
    rows: [
      [
        {
          field: 'Type',
          value: 'Final assessment',
        },
        {
          field: 'Weighting',
          value: '10%',
        },
      ],
      [
        {
          field: 'Marked out of',
          value: '100',
        },
        {
          field: 'Pass mark',
          value: '40',
        },
      ],
    ],
  },
  {
    type: 'heading',
    title: 'Dates',
  },
  {
    type: 'dates',
    rows: [
      {
        field: 'Submission deadline',
        value: '12th April, 2020',
      },

      {
        field: 'Release date',
        value: '12th April, 2020',
      },

      {
        field: 'Feedback date',
        value: '12th April, 2020',
      },
    ],
  },
]

const Container = styled.View`
  flex: 1;
`

const H1 = styled.Text`
  width: 65%;
  font-family: proxima-nova-bold;
  font-size: 34px;
  line-height: 32px;
  color: ${props => props.color ?? '#252633'};
`

const Content = styled.ScrollView`
  flex: 1;
  padding: 24px 16px;
  background-color: #ebecf2;
`

const BubbleRow = styled.View`
  flex-direction: row;
  margin: 0 -4px 16px -4px;
  height: 70px;
`

const Bubble = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0 4px 4px 4px;
  padding: 12px 0;
  border-radius: 16px;
`

const TextBubble = styled.View`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 22px;
`

const Details = styled.View`
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 42px;
`

const DetailsRow = styled.View`
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 16px;
`

const AroRow = styled.View`
  flex-direction: row;
  align-items: center;
`

const Label = styled.View`
  width: 135px;
`

const Value = styled.View`
  flex: 1;
`

const MultiHeader = styled.View`
  flex-direction: column;
`

const InputGroupContainer = styled.View`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
`

const InputSeperator = styled.View`
  height: 1px;
  background-color: #dde1ed;
`

const InputTextAbove = styled.Text`
  margin-bottom: 8px;
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

const InputTextBelow = styled.Text`
  margin-top: 8px;
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

const AssessmentScreen = ({ assessment, navigation }) => {
  return (
    <Container>
      <MultiHeader>
        <AssessmentHeader navigation={navigation}>
          <H1 color="#FFF">Examination description or name</H1>

          <SmallButton
            primary
            onPress={() => {
              navigation.navigate('AssessmentEdit')
            }}
          >
            <Typography.Button color="#FFF">Edit</Typography.Button>
          </SmallButton>
        </AssessmentHeader>
      </MultiHeader>
      <Content>
        {DATA.map(item => {
          switch (item.type) {
            case 'summary': {
              return item.rows.map(row => (
                <BubbleRow>
                  {row.map(field => (
                    <Bubble>
                      {field.field ? (
                        <Typography.Overline color="#757885">
                          {field.field}
                        </Typography.Overline>
                      ) : null}
                      <Typography.Subtitle color="#0E1012">
                        {field.value}
                      </Typography.Subtitle>
                    </Bubble>
                  ))}
                </BubbleRow>
              ))
            }
            case 'dates': {
              return (
                <InputGroupContainer>
                  {item.rows.map(row => (
                    <>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View>
                          <View style={{ flexDirection: 'column' }}>
                            <Text>{row.field}</Text>
                            <Text>{row.value}</Text>
                          </View>
                        </View>
                        <View>
                          <Text>icon</Text>
                        </View>
                      </View>
                      <InputSeperator key={1} />
                    </>
                  ))}
                </InputGroupContainer>
              )
            }
            case 'details': {
              return (
                <Details>
                  {item.fields
                    .map((field, index) => (
                      <DetailsRow key={index}>
                        <Label>
                          <Typography.Subtitle color="#0E1012">
                            {field.label}
                          </Typography.Subtitle>
                        </Label>
                        <Value>
                          {field.type === 'aro' ? (
                            field.values.map((value, index) => (
                              <AroRow
                                style={
                                  index === 0 &&
                                  field.values.length > 1 && { marginBottom: 8 }
                                }
                                key={value}
                              >
                                <View style={{ marginRight: 8 }}>
                                  <Avatar
                                    size={32}
                                    radius={16}
                                    userId={Math.floor(Math.random() * 10) + 1}
                                  />
                                </View>
                                <Typography.Body color="#757885">
                                  {value}
                                </Typography.Body>
                              </AroRow>
                            ))
                          ) : (
                            <Typography.Body color="#757885">
                              {field.values.join(', ')}
                            </Typography.Body>
                          )}
                        </Value>
                      </DetailsRow>
                    ))
                    .reduce((prev, curr) => [
                      prev,
                      <View
                        key={curr}
                        style={{ height: 1, backgroundColor: '#dde1ed' }}
                      />,
                      curr,
                    ])}
                  <DetailsRow
                    style={{
                      alignItems: 'center',
                      borderTopWidth: 1,
                      borderColor: '#dde1ed',
                    }}
                  >
                    <Label>
                      <Typography.Overline color="#757885">
                        10 empty fields
                      </Typography.Overline>
                    </Label>
                    <Value>
                      <Typography.Button color="#2AAD79">
                        Show
                      </Typography.Button>
                    </Value>
                  </DetailsRow>
                </Details>
              )
            }
            case 'textarea': {
              return (
                <TextBubble>
                  <View style={{ marginBottom: 8 }}>
                    {item.field ? (
                      <Typography.Subtitle color="#0E1012">
                        {item.field}
                      </Typography.Subtitle>
                    ) : null}
                  </View>

                  <Typography.Body color="#757885">
                    {item.value}
                  </Typography.Body>

                  {item.footer ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {item.footer.code && (
                        <Typography.Overline color="#757885">
                          {item.footer.code}
                        </Typography.Overline>
                      )}
                      {item.footer.buttons.length ? (
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          {item.footer.buttons.map(button => (
                            <SmallButton style={{ marginLeft: 20 }}>
                              <Typography.Button color="#2AAD79">
                                {button.title}
                              </Typography.Button>
                            </SmallButton>
                          ))}
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                </TextBubble>
              )
            }
            case 'heading': {
              return (
                <Typography.H3 style={{ marginBottom: 17 }}>
                  {item.title}
                </Typography.H3>
              )
            }
            case 'announcement': {
              return <Announcement message={item.title} />
            }
            case 'selection': {
              switch (item.format) {
                case 'groupby': {
                  return item.records.map((record, index) => (
                    <View key={index}>
                      <Typography.H3 style={{ marginBottom: 17 }}>
                        {record.title}
                      </Typography.H3>
                      {record.data.map((r, index) => {
                        switch (r.record_class) {
                          case 'assessment': {
                            return (
                              <Assessment
                                key={index}
                                assessment={r}
                                navigation={navigation}
                              />
                            )
                          }
                          case 'delivery': {
                            return <Delivery key={index} delivery={r} />
                          }
                          default: {
                            return null
                          }
                        }
                      })}
                    </View>
                  ))
                }
                default: {
                  return item.records.map((record, index) => {
                    switch (record.record_class) {
                      case 'subject': {
                        return <Subject key={index} subject={record} />
                      }
                      case 'cost': {
                        return <Cost key={index} cost={record} />
                      }
                      default: {
                        return null
                      }
                    }
                  })
                }
              }
            }
          }
        })}
      </Content>
    </Container>
  )
}

export default AssessmentScreen
