import React from 'react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import * as Typography from '../shared/Typography'
import WorktribeIcon from '../shared/WorktribeIcon'

const Container = styled.TouchableOpacity`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px 12px;
  margin-bottom: 30px;
`

const HeaderRow = styled.View`
  flex-direction: row;
  margin-bottom: 12px;
`

const Thumbnail = styled.Image`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  margin-right: 12px;
`

const TitleRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SummaryRow = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`

const Separator = styled.View`
  height: 1px;
  background-color: #dde1ed;
  margin: 13px 0 16px 0;
`

const imageFeed = [
  'G02AR7pa_Lo',
  'SLBjcE5IQxo',
  'WXgiTeZRXGU',
  'DtkhxjzL_G4',
  'XL0Dzfkactw',
  'S6k4vSWrIzA',
  'C1wAFPZFes8',
  'BwQ8ZA23W6g',
  'zwh47C0McZ8',
  'PEDWYwNfUoI',
]

const Assessment = ({ assessment, navigation }) => (
  <Container onPress={() => navigation.navigate('Assessment', { assessment })}>
    <HeaderRow>
      <Thumbnail
        source={{
          uri: `https://source.unsplash.com/${
            imageFeed[Math.floor(Math.random() * imageFeed.length)]
          }/50x50`,
          cache: 'force-cache',
        }}
      />
      <View>
        <TitleRow>
          <Typography.Overline color="#757885">
            {assessment.type}
          </Typography.Overline>
          <Typography.Caption color="#757885">31 dec, 2019</Typography.Caption>
        </TitleRow>

        <Typography.Subtitle color="#0E1012">
          {assessment.type} description or name
        </Typography.Subtitle>
      </View>
    </HeaderRow>

    {assessment.type == 'Dissertation' ? (
      <>
        <SummaryRow>
          <WorktribeIcon name="check-circle" />
          <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
            Summative assessment
          </Typography.Body>
          <Typography.Body color="#757885">#438599</Typography.Body>
        </SummaryRow>

        <SummaryRow>
          <WorktribeIcon name="check-circle" />
          <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
            Must pass
          </Typography.Body>
        </SummaryRow>
      </>
    ) : (
      <>
        <SummaryRow>
          <WorktribeIcon name="check-circle" />
          <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
            Formative assessment
          </Typography.Body>
          <Typography.Body color="#757885">#438599</Typography.Body>
        </SummaryRow>

        <SummaryRow>
          <WorktribeIcon name="check-circle" />
          <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
            20% of final score
          </Typography.Body>
        </SummaryRow>

        <SummaryRow>
          <WorktribeIcon name="check-circle" />
          <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
            Group work
          </Typography.Body>
        </SummaryRow>
      </>
    )}

    <Separator />

    <SummaryRow>
      <WorktribeIcon size={22} name="calendar" />
      <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
        Release date: 1st May, 2020
      </Typography.Body>
    </SummaryRow>

    <SummaryRow>
      <WorktribeIcon size={22} name="calendar" />
      <Typography.Body style={{ flex: 1, marginLeft: 15 }} color="#757885">
        Submission deadline: 8th May, 2020
      </Typography.Body>
    </SummaryRow>
  </Container>
)

export default Assessment
