import React, { useEffect, useState, useRef } from 'react'
import {
  Image,
  SafeAreaView,
  SectionList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { SearchBar } from 'react-native-elements'

const DATA = [
  {
    title: 'Recent Searches',
    data: [
      {
        type: 'Module',
        title: 'BSc Chemistry',
        image: 'WXgiTeZRXGU',
      },
      {
        type: 'Contract',
        title: 'Commercially led clinical trial',
        image: 'S6k4vSWrIzA',
      },
      {
        type: 'Project',
        title: 'Real fires for the safe design of ...',
        image: 'PEDWYwNfUoI',
      },
      {
        type: 'Journal Article',
        title: 'BSc Chemistry',
        image: 'G02AR7pa_Lo',
      },
      {
        type: 'Module',
        title: 'BSc Chemistry',
        image: 'WXgiTeZRXGU',
      },
      {
        type: 'Contract',
        title: 'Commercially led clinical trial',
        image: 'S6k4vSWrIzA',
      },
      {
        type: 'Project',
        title: 'Real fires for the safe design of ...',
        image: 'PEDWYwNfUoI',
      },
      {
        type: 'Journal Article',
        title: 'BSc Chemistry',
        image: 'G02AR7pa_Lo',
      },
      {
        type: 'Module',
        title: 'BSc Chemistry',
        image: 'WXgiTeZRXGU',
      },
      {
        type: 'Contract',
        title: 'Commercially led clinical trial',
        image: 'S6k4vSWrIzA',
      },
      {
        type: 'Project',
        title: 'Real fires for the safe design of ...',
        image: 'PEDWYwNfUoI',
      },
      {
        type: 'Journal Article',
        title: 'BSc Chemistry',
        image: 'G02AR7pa_Lo',
      },
      {
        type: 'Project',
        title: 'Real fires for the safe design of ...',
        image: 'PEDWYwNfUoI',
      },
      {
        type: 'Journal Article',
        title: 'BSc Chemistry',
        image: 'G02AR7pa_Lo',
      },
      {
        type: 'Module',
        title: 'BSc Chemistry',
        image: 'WXgiTeZRXGU',
      },
      {
        type: 'Contract',
        title: 'Commercially led clinical trial',
        image: 'S6k4vSWrIzA',
      },
      {
        type: 'Project',
        title: 'Real fires for the safe design of ...',
        image: 'PEDWYwNfUoI',
      },
      {
        type: 'Journal Article',
        title: 'BSc Chemistry',
        image: 'G02AR7pa_Lo',
      },
    ],
  },
]

const SearchRecentScreen = ({ navigation }) => {
  const [search, setSearch] = useState('')
  const inputRef = useRef()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      inputRef.current.focus()
    })

    return unsubscribe
  }, [navigation])

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}
    >
      <Image
        style={{
          height: 36,
          width: 36,
          borderRadius: 12,
          marginRight: 12,
        }}
        source={{
          uri: `https://source.unsplash.com/${item.image}/`,
          cache: 'force-cache',
        }}
      />
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontFamily: 'proxima-nova-semibold',
            color: '#757885',
            fontSize: 14,
            lineHeight: 18,
            textTransform: 'uppercase',
            letterSpacing: 0.4,
          }}
        >
          {item.type}
        </Text>
        <Text
          numberOfLines={1}
          style={{
            fontFamily: 'proxima-nova-semibold',
            color: '#0E1012',
            fontSize: 17,
            lineHeight: 24,
          }}
        >
          {item.title}
        </Text>
      </View>
      <AntDesign name="close" size={24} color="#A0A4B8" />
    </TouchableOpacity>
  )

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#EBECF2' }}>
      <SearchBar
        ref={inputRef}
        platform="ios"
        placeholder="Search"
        onChangeText={setSearch}
        value={search}
        containerStyle={{
          backgroundColor: 'transparent',
          marginHorizontal: 10,
        }}
        inputContainerStyle={{
          borderRadius: 16,
          height: 40,
          backgroundColor: '#FFF',
        }}
        inputStyle={{
          backgroundColor: '#FFF',
          fontSize: 17,
          height: 40,
          color: '#757885',
        }}
        cancelButtonProps={{
          buttonTextStyle: {
            color: '#2AAD79',
            fontWeight: '600',
          },
          buttonStyle: {
            marginLeft: 5,
          },
        }}
        searchIcon={
          <AntDesign
            onPress={() => navigation.navigate('SearchResultsScreen')}
            name="search1"
            size={20}
            color="#757885"
          />
        }
        leftIconContainerStyle={{
          marginLeft: 15,
        }}
      />
      <SectionList
        contentContainerStyle={{
          paddingHorizontal: 16,
        }}
        sections={DATA}
        keyExtractor={(item, index) => item + index}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <Text
            style={{
              color: '#757885',
              fontWeight: '600',
              fontSize: 14,
              textTransform: 'uppercase',
              marginVertical: 20,
            }}
          >
            {title}
          </Text>
        )}
        stickySectionHeadersEnabled={false}
      />
    </SafeAreaView>
  )
}

export default SearchRecentScreen
