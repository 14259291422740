import React from 'react'
import { AntDesign } from '@expo/vector-icons'
import { SearchBar } from 'react-native-elements'
import { Platform } from 'react-native'

const SearchInput = ({
  backgroundColor = '#EBECF2',
  search,
  setSearch,
  navigation,
  onFocus,
}) => (
  <SearchBar
    platform="ios"
    placeholder="Search"
    onChangeText={setSearch}
    onFocus={onFocus}
    value={search}
    containerStyle={{
      backgroundColor: 'transparent',
      marginHorizontal: 10,
    }}
    inputContainerStyle={{
      borderRadius: 16,
      height: 40,
      backgroundColor: backgroundColor,
    }}
    inputStyle={[
      {
        backgroundColor: backgroundColor,
        fontSize: 17,
        height: 40,
        color: '#757885',
      },
      Platform.OS === 'web' ? { outlineWidth: 0 } : {},
    ]}
    cancelButtonProps={{
      buttonTextStyle: {
        color: '#2AAD79',
        fontWeight: '600',
      },
      buttonStyle: {
        marginLeft: 5,
      },
    }}
    searchIcon={
      <AntDesign
        onPress={() => navigation.navigate('SearchResultsScreen')}
        name="search1"
        size={20}
        color="#757885"
      />
    }
    leftIconContainerStyle={{
      marginLeft: 15,
    }}
  />
)

export default SearchInput
