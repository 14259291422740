import React, { useContext } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native'
import { AlignedRow } from './Layout'
import useOrientation from '../../hooks/useOrientation'
import { ThemeContext } from '../../contexts/ThemeContext'

const TabHeader = ({ title, children }) => {
  const isLandscape = useOrientation()
  const {
    typography: { H1 },
    themeColors: { bg },
  } = useContext(ThemeContext)

  if (isLandscape) {
    return (
      <AlignedRow style={{ padding: 20 }}>
        <Text
          style={{
            fontFamily: 'proxima-nova-bold',
            fontSize: 24,
            lineHeight: 32,
            color: '#293239',
          }}
        >
          {title}
        </Text>
        <TextInput
          style={{
            height: 40,
            flex: 1,
            marginLeft: 20,
            backgroundColor: '#FFFFFF',
            borderRadius: 46,
          }}
        />
      </AlignedRow>
    )
  }

  return (
    <View style={[styles.headerContainer]}>
      <H1 primary>{title}</H1>
      <AlignedRow>{children}</AlignedRow>
    </View>
  )
}

export default TabHeader

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    height: 80,
  },
  headerTitle: {
    fontFamily: 'proxima-nova-bold',
    color: '#0E1012',
    fontWeight: '700',
    fontSize: 34,
  },
})
