import { useEffect, useState } from 'react'
import * as RootNavigation from '../../src/services/RootNavigation.js'
import env from '../../env'
import { Platform } from 'react-native'
import useSocketMethods from './useSocketMethods'
import { getClientInfo } from '../helpers/clientInfo'

const useFcm = userid => {
  const messaging =
    env.use_push_notifications === true &&
    Platform.OS !== 'web' &&
    require('@react-native-firebase/messaging').default
  const [deviceToken, setDeviceToken] = useState()

  const { record_action } = useSocketMethods()

  const getDeviceToken = async () => {
    if (messaging) {
      setDeviceToken(await messaging().getToken())
    } else {
      return null
    }
  }

  useEffect(() => {
    getDeviceToken()
  }, [messaging])

  const doAsyncDeviceRegistration = async callback => {
    if (deviceToken && userid) {
      const { clientplatform } = getClientInfo()

      setTimeout(() => {
        record_action({
          recordid: userid,
          rclassname: 'Person',
          actionname: 'Edit',
          changes: [
            {
              property: 'Devices',
              method: 'add',
              changeid: 'newdevice',
              changes: [
                { property: 'Platform', method: 'set', value: clientplatform },
                { property: 'Token', method: 'set', value: deviceToken },
              ],
            },
          ],
        })
      }, 1000)

      // new WorktribeAPI(RootNavigation)
      //   .registerDeviceForUser(deviceToken)
      //   .then(response => {
      //     if (callback) {
      //       callback(response.data)
      //     } else {
      //       console.log(response.data)
      //     }
      //     return true
      //   })
      //   .catch(e => {
      //     console.log(e)
      //     return false
      //   })
    }
  }

  /**
   * @param callback - optional callback function that is executed upon successful device registration
   */

  const registerDeviceForUser = (callback = null) => {
    doAsyncDeviceRegistration(callback).then(r => r)
  }

  const requestNotificationUserPermission = async () => {
    if (messaging) {
      const authStatus = await messaging().requestPermission()
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL

      const name = Object.keys(messaging.AuthorizationStatus).find(
        item => authStatus === messaging.AuthorizationStatus[item]
      )

      return {
        status: authStatus,
        name: name,
        enabled: enabled,
      }
    }
  }

  const doAsyncDeviceDeregistration = async callback => {
    if (deviceToken) {
      new WorktribeAPI(RootNavigation)
        .unregisterDevice(deviceToken)
        .then(response => {
          if (callback) {
            callback(response.data)
          } else {
            console.log(response.data)
          }
          return true
        })
        .catch(e => {
          console.log(e)
          return false
        })
    }
  }

  const unregisterDevice = async (callback = null) => {
    doAsyncDeviceDeregistration(callback).then(r => r)
  }

  const handleNotification = async payload => {
    console.log(payload)
    // if (!payload.data) {
    //   RootNavigation.navigate('Feed')
    //   return
    // }

    // const {
    //   data: { rclass, record_id },
    // } = payload

    // // FCM requires all payload items to be strings, so convert the ID to an int
    // const recordID = parseInt(record_id)

    // try {
    //   await fetchRecord(rclass, recordID)
    // } catch (e) {
    //   console.warn(e)
    // } finally {
    //   if (rclass === 'conversation') {
    //     RootNavigation.navigate('Messages')
    //     RootNavigation.navigate('Message', {
    //       screen: 'messages',
    //       conversationId: recordID,
    //     })
    //   } else {
    //     RootNavigation.navigate('Record', {
    //       recordId: recordID,
    //       rClass: rclass,
    //     })
    //   }
    // }
  }

  const setPushNotificationListener = () => {
    if (messaging) {
      messaging().setBackgroundMessageHandler(() => {
        // We're not using this because of the way the notification handling is set.
        // Our notification handling contains the redirect logic that should not be executed until the app starts.
        // On Android it would cause undesired background redirect when a notification is received.
        // React Native would issue a warning when not implemented, so let an empty function be here to silence it.
      })
      messaging().onNotificationOpenedApp(remoteMessage => {
        console.log('The notification opened the app.')
        handleNotification(remoteMessage)
      })

      // Check whether an initial notification is available
      messaging()
        .getInitialNotification()
        .then(remoteMessage => {
          if (remoteMessage) {
            console.log('Started the app')
            handleNotification(remoteMessage)
          }
        })
      return messaging().onMessage(async remoteMessage => {
        const notification = remoteMessage.notification
      })
    }
  }

  return {
    requestNotificationUserPermission,
    registerDeviceForUser,
    unregisterDevice,
    setPushNotificationListener,
  }
}

export default useFcm
