import React, { useContext } from 'react'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'

const CustomView = ({ section }) => {
  const {
    typography: { H3, Body },
    themeColors: { bg },
  } = useContext(ThemeContext)

  return (
    <ScrollView style={{ paddingHorizontal: 40 }}>
      <BubbleRow>
        {section.blocks?.map(block => {
          switch (block.type) {
            case 'main': {
              return (
                <Overview>
                  {block.columns.map(column => {
                    return column.items.map(item => (
                      <View
                        style={{
                          backgroundColor: bg.card,
                          padding: 24,
                          borderRadius: 20,
                          marginBottom: 20,
                        }}
                      >
                        <H3 primary style={{ marginBottom: 16 }}>
                          {item.property}
                        </H3>
                        <Body primary>{item.value}</Body>
                      </View>
                    ))
                  })}
                </Overview>
              )
            }
            case 'aside': {
              return (
                <Aside>
                  {block.columns.map(column => {
                    return column.items.map(item => {
                      return (
                        <View
                          style={{
                            backgroundColor: bg.card,
                            padding: 24,
                            borderRadius: 20,
                            marginBottom: 20,
                          }}
                        >
                          <H3 style={{ marginBottom: 16 }} primary>
                            {item.property}
                          </H3>
                          <Body primary>{item.value}</Body>
                        </View>
                      )
                    })
                  })}
                </Aside>
              )
            }
            default: {
              return null
            }
          }
        })}
      </BubbleRow>
    </ScrollView>
  )
}

export default CustomView

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px 20px -15px;
`

const Overview = styled.View`
  flex: 1;
  margin: 15px;
  padding: 12px 0;
  min-width: 700px;
`

const Aside = styled.View`
  flex: 1;
  margin: 15px;
  padding: 12px 0;
  min-width: 300px;
`
