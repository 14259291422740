import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components/native'
import * as Typography from '../../components/shared/Typography'
import { View } from 'react-native'
import { SmallButton } from '../../components/shared/Button'
import SectionHeader from '../../components/shared/SectionHeader'
import InputField from '../../components/shared/InputField'
import Select from '../../components/modals/Select'

const Container = styled.View`
  flex: 1;
`

const Content = styled.ScrollView`
  flex: 1;
  padding: 24px 16px;
  background-color: #ebecf2;
`

const InputGroupContainer = styled.View`
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
`

const InputSeperator = styled.View`
  height: 1px;
  background-color: #dde1ed;
`

const InputTextAbove = styled.Text`
  margin-bottom: 8px;
  font-family: proxima-nova-semibold;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

const InputTextBelow = styled.Text`
  margin-top: 8px;
  font-family: proxima-nova;
  font-size: 14px;
  line-height: 18px;
  color: #757885;
`

function createReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.field]: action.payload.value,
        },
      }
    default:
      return state
  }
}

const SectionEditScreen = ({ navigation, route }) => {
  const { record, action } = route.params

  const [state, dispatch] = useReducer(createReducer, {
    fields: {},
  })

  const [modalData, setModalData] = useState(null)

  const modalizeRef = useRef(null)

  useEffect(() => {
    if (modalData?.options.length) {
      modalizeRef.current?.open()
    }
  }, [modalData])

  return (
    <Container>
      <SectionHeader record={record} navigation={navigation}>
        <Typography.H1 color="#FFF">{`Edit ${action.sectionTitle}`}</Typography.H1>

        <SmallButton primary>
          <Typography.Button color="#FFF">Done</Typography.Button>
        </SmallButton>
      </SectionHeader>

      <Content>
        {action.fieldGroups.map((group, idx) => (
          <View key={idx} style={{ marginBottom: 32 }}>
            {group.textAbove ? (
              <InputTextAbove>{group.textAbove}</InputTextAbove>
            ) : null}
            <InputGroupContainer>
              {group.fields
                .map((field, idx) => (
                  <InputField
                    navigation={navigation}
                    key={field.name}
                    field={field}
                    state={state}
                    dispatch={dispatch}
                    setModalData={setModalData}
                  />
                ))
                .reduce((prev, curr) => [
                  prev,
                  <InputSeperator key={1} />,
                  curr,
                ])}
            </InputGroupContainer>
            {group.textBelow ? (
              <InputTextBelow>{group.textBelow}</InputTextBelow>
            ) : null}
          </View>
        ))}
      </Content>

      <Select
        modalizeRef={modalizeRef}
        modalData={modalData}
        dispatch={dispatch}
      />
    </Container>
  )
}

export default SectionEditScreen
