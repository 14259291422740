import React, { useContext, useEffect, useState } from 'react'
import { Platform, TextInput, TouchableOpacity, StyleSheet } from 'react-native'
import { AlignedRow } from './Layout'
import KeyboardSpacer from '../../components/shared/KeyboardSpacer'
import WorktribeIcon from './WorktribeIcon'
import useOrientation from '../../hooks/useOrientation'
import { ThemeContext } from '../../contexts/ThemeContext'

const COMMENTS_HEIGHT = 36

const CommentInput = props => {
  const {
    comment,
    sendComment,
    commentChanged,
    placeholder,
    inputStyles,
    containerStyle,
  } = props

  const {
    themeColors: { bg, fonts },
  } = useContext(ThemeContext)

  const isLandscape = useOrientation()

  const [height, setHeight] = useState(COMMENTS_HEIGHT)
  const [enterPressed, setEnterPressed] = useState(false)

  useEffect(() => {
    if (comment && enterPressed && Platform.OS === 'web') {
      const cleanString = comment.replace(/\n$/, '')
      commentChanged(cleanString)
      setEnterPressed(false)
    }

    if (!comment && height !== COMMENTS_HEIGHT) {
      setHeight(COMMENTS_HEIGHT)
    }
  }, [comment])

  return (
    <>
      <AlignedRow
        style={[
          styles.footer,
          containerStyle,
          { paddingHorizontal: isLandscape ? 40 : 12 },
          !isLandscape && {
            borderColor: '#DDE1ED',
            borderTopWidth: StyleSheet.hairlineWidth,
            paddingVertical: 5,
          },
        ]}
      >
        {isLandscape && (
          <AlignedRow>
            <TouchableOpacity style={{ marginRight: 17.25 }}>
              <WorktribeIcon name="mention" />
            </TouchableOpacity>

            <TouchableOpacity style={{ marginRight: 17.25 }}>
              <WorktribeIcon name="smiley-smile" />
            </TouchableOpacity>

            <TouchableOpacity style={{ marginRight: 17.25 }}>
              <WorktribeIcon name="attachment" />
            </TouchableOpacity>
          </AlignedRow>
        )}

        <TextInput
          multiline
          maxHeight={120}
          style={[
            styles.input,
            inputStyles && inputStyles,
            { backgroundColor: bg.card, color: fonts.primary },
            Platform.OS === 'web' && {
              maxHeight: 100,
              height: height,
              outlineWidth: 0,
            },
          ]}
          onChange={event => {
            if (!enterPressed && Platform.OS === 'web') {
              setHeight(event.nativeEvent.srcElement.scrollHeight)
            }
          }}
          onChangeText={commentChanged}
          value={comment}
          placeholder={placeholder}
          placeholderTextColor={fonts.secondary}
          onKeyPress={event => {
            if (Platform.OS === 'web') {
              const { nativeEvent } = event

              if (
                (nativeEvent.altKey ||
                  nativeEvent.ctrlKey ||
                  nativeEvent.shiftKey ||
                  nativeEvent.metaKey) &&
                nativeEvent.key === 'Enter'
              ) {
                if (!nativeEvent.shiftKey) {
                  commentChanged(comment.concat('\n'))
                  setHeight(height + (COMMENTS_HEIGHT - 11))
                }
              } else if (nativeEvent.key === 'Enter') {
                setEnterPressed(true)

                sendComment()
              }
            }
          }}
        />
        <AlignedRow>
          {isLandscape ? (
            <>
              <TouchableOpacity style={{ marginLeft: 17.25 }}>
                <WorktribeIcon name="format" />
              </TouchableOpacity>

              <TouchableOpacity
                onPress={sendComment}
                disabled={!comment.length}
                style={{ marginLeft: 17.25 }}
              >
                <WorktribeIcon
                  color={comment.length ? `#2AAD79` : `#A0A4B8`}
                  name="send-email"
                />
              </TouchableOpacity>
            </>
          ) : (
            <TouchableOpacity
              onPress={sendComment}
              disabled={!comment.length}
              style={{ marginLeft: 3 }}
            >
              <WorktribeIcon
                color={comment.length ? `#2AAD79` : `#A0A4B8`}
                name="send-email"
              />
            </TouchableOpacity>
          )}
        </AlignedRow>
      </AlignedRow>
      {Platform.OS === 'ios' ? <KeyboardSpacer /> : null}
    </>
  )
}

export default CommentInput

const styles = StyleSheet.create({
  footer: {
    marginVertical: 8,
  },
  input: {
    paddingVertical: 9,
    paddingHorizontal: 20,
    flex: 1,
    borderRadius: 17,
    fontFamily: 'proxima-nova',
    fontSize: 15,
  },
  image: {
    marginLeft: 17,
    width: 20,
    height: 20,
  },
})
