import React, { useContext } from 'react'
import { View, Text, StyleSheet, Button, Linking } from 'react-native'
import { ThemeContext } from '../../contexts/ThemeContext'
import { Ionicons } from '@expo/vector-icons'

export default ({ route }) => {
  const { version } = route.params
  const { themeColors } = useContext(ThemeContext)

  const iOSUrl = 'https://worktribe.com'

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: themeColors.primaryBackgroundColor },
      ]}
    >
      <Ionicons name="ios-appstore" size={100} color="#286AFF" />
      <Text style={{ color: themeColors.primaryTextColor, marginTop: 10 }}>
        Please install version {version} of the app
      </Text>
      <Button
        title={'Go to App Store'}
        onPress={() => Linking.openURL(iOSUrl)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
