import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components/native'
import { ThemeContext } from '../../contexts/ThemeContext'
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  ScrollView,
  Pressable,
  FlatList,
} from 'react-native'
import { AlignedRow, CenteredView } from '../shared/Layout'
import WorktribeIcon from '../shared/WorktribeIcon'
import { SmallButton } from '../shared/Button'
import HoverableOpacity from '../shared/HoverableOpacity'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import { useNavigation } from '@react-navigation/native'
import Selection from '../../components/landscape/Selection'
import { format } from 'date-fns'
import GridFilters from './ContentPanel/ui/GridFilters'
import useSocketMethods from '../../hooks/useSocketMethods'

const Container = styled.View`
  flex: 1;
`

const shadowStyles = {
  shadowColor: 'rgba(0, 0, 0, 0.12)',
  shadowOffset: {
    width: 0,
    height: 12,
  },
  shadowOpacity: 0.58,
  shadowRadius: 50,
  elevation: 24,
}

const alerts = [
  {
    background: 'rgba(224, 137, 56, 0.2)',
    border: '#E08938',
  },
  {
    background: 'rgba(95, 68, 196, 0.2)',
    border: '#5F44C4',
  },
  {
    background: 'rgba(42, 173, 121, 0.2)',
    border: '#2AAD79',
  },
  {
    background: 'rgba(209, 62, 62, 0.2)',
    border: '#D13E3E',
  },
]

const BubbleRow = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 40px;
  margin-bottom: 20px;
`

const Bubble = styled.View`
  flex: 1;
  background-color: #f4f5f9;
  padding: 14px;
  border-radius: 16px;
  min-width: 748px;
`

const PlannerTitle = ({ visibleDate, activeDate = new Date(), changeYear }) => {
  const {
    typography: { H4, Overline },
    themeColors: { bg },
  } = useContext(ThemeContext)

  const [showYears, setShowYears] = useState(false)

  const [start, setStart] = useState(activeDate.getFullYear())
  const [end, setEnd] = useState(activeDate.getFullYear() + 24)

  const setRange = n => {
    setStart(start => start + n)
    setEnd(end => end + n)
  }

  const outsideHandler = useCallback(() => setShowYears(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  return (
    <AlignedRow>
      <TouchableOpacity onPress={() => setShowYears(show => !show)}>
        <AlignedRow>
          <H4 primary>{format(visibleDate, 'MMMM yyyy')}</H4>

          <WorktribeIcon name="chevron-down" />
        </AlignedRow>
      </TouchableOpacity>

      {showYears && (
        <View
          ref={wrapperRef}
          style={[
            {
              position: 'absolute',
              zIndex: 10,
              backgroundColor: bg.card,
              right: 0,
              top: 25,
              borderRadius: 16,
              padding: 16,
            },
            shadowStyles,
          ]}
        >
          <AlignedRow style={{ marginBottom: 20 }}>
            <TouchableOpacity
              onPress={() => setRange(-24)}
              style={{ alignItems: 'flex-start', flex: 1 }}
            >
              <WorktribeIcon color="#757885" name="arrow-up-left" />
            </TouchableOpacity>
            <View style={{ alignItems: 'center', flex: 3 }}>
              <Overline primary>{`${start} - ${end}`}</Overline>
            </View>
            <TouchableOpacity
              onPress={() => setRange(24)}
              style={{ alignItems: 'flex-end', flex: 1 }}
            >
              <WorktribeIcon color="#757885" name="arrow-up-right" />
            </TouchableOpacity>
          </AlignedRow>

          <AlignedRow
            justifyContent="space-between"
            style={{ flexWrap: 'wrap' }}
          >
            {range(start, end).map((row, idx) => (
              <AlignedRow key={idx}>
                {row.map(year => {
                  const isCurrentYear = activeDate.getFullYear() === year
                  return (
                    <TouchableOpacity
                      key={year}
                      onPress={() => changeYear(year)}
                      style={[
                        {
                          borderRadius: 10,
                          width: 54,
                          height: 34,
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 3,
                        },
                        isCurrentYear && {
                          backgroundColor: '#2AAD79',
                        },
                      ]}
                    >
                      <Overline primary={!isCurrentYear} color="#FFF">
                        {year}
                      </Overline>
                    </TouchableOpacity>
                  )
                })}
              </AlignedRow>
            ))}
          </AlignedRow>
        </View>
      )}
    </AlignedRow>
  )
}

function range(start, end) {
  const perChunk = 5

  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
}

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const PlannerHeader = ({
  name,
  selectView,
  setSelectView,
  setShowAddEvent,
  addAction,
  filters,
  data,
  showAddEvent,
  changeMonth,
  changeYear,
  activeDate,
  setToday,
  visibleDate,
  filtersActive,
  previousFilter,
  setFilterOptions,
}) => {
  const {
    typography: { PropertyName, Button, Body },
    themeColors: { bg, hover },
  } = useContext(ThemeContext)

  const [showViewSwitcher, setShowViewSwitcher] = useState(false)

  const outsideHandler = useCallback(() => setShowViewSwitcher(false), [])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, outsideHandler)

  const showViewOptions = [
    {
      name: 'Planner View',
      icon: 'calendar',
      color: '#757885',
      action: () => setSelectView('planner'),
    },
    {
      name: 'List View',
      icon: 'task-list-edit',
      color: '#757885',
      action: () => {
        setShowAddEvent(false)
        setSelectView('list')
      },
    },
  ]

  useEffect(() => {
    switch (selectView) {
      case 'planner':
        setSelectedViewOption(showViewOptions[0])
        break
      case 'list':
        setSelectedViewOption(showViewOptions[1])
        break
    }
    setShowViewSwitcher(false)
  }, [selectView])

  const [selectedViewOption, setSelectedViewOption] = useState(
    showViewOptions[0]
  )

  // Whether filters are currently visible
  const showFilters =
    data?.length > 0 ||
    Object.keys(filtersActive).length > 0 ||
    previousFilter.current

  const handleAddEvent = () => {
    if (selectView === 'list') {
      setShowAddEvent(!showAddEvent)
    } else {
      setSelectView('list')
      setShowAddEvent(true)
    }
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0,
          zIndex: 100,
          margin: 10,
        }}
      >
        <PropertyName primary>{name}</PropertyName>

        <AlignedRow>
          {selectView !== 'list' && (
            <>
              <PlannerTitle
                visibleDate={visibleDate}
                activeDate={activeDate}
                changeYear={changeYear}
              />

              <AlignedRow style={{ marginLeft: 16 }}>
                <TouchableOpacity
                  onPressIn={() => changeMonth(-1)}
                  style={{ marginRight: 5 }}
                >
                  <WorktribeIcon color="#757885" name="arrow-up-left" />
                </TouchableOpacity>

                <TouchableOpacity
                  onPressIn={() => changeMonth(+1)}
                  style={{ marginLeft: 5 }}
                >
                  <WorktribeIcon color="#757885" name="arrow-up-right" />
                </TouchableOpacity>
              </AlignedRow>
            </>
          )}

          {selectView !== 'list' && (
            <SmallButton
              transparentHighlight
              secondary
              style={{ marginLeft: 16 }}
              onPress={setToday}
            >
              <Button color="#1D7A55">Today</Button>
            </SmallButton>
          )}

          <AlignedRow>
            <SmallButton
              transparentHighlight
              secondary
              style={{ marginLeft: 16, maxHeight: 38 }}
              onPress={() => {
                setShowViewSwitcher(!showViewSwitcher)
              }}
            >
              <WorktribeIcon
                size={16}
                color="#1D7A55"
                name={selectedViewOption.icon}
              />
              <Button style={{ marginLeft: 8 }} color="#1D7A55">
                {selectedViewOption.name}
              </Button>
              <WorktribeIcon name="chevron-down" />
            </SmallButton>
            {showViewSwitcher && (
              <View
                ref={wrapperRef}
                style={[
                  {
                    position: 'absolute',
                    top: 40,
                    left: 15,
                    backgroundColor: bg.primary,
                    borderRadius: 16,
                    paddingVertical: 8,
                    maxHeight: 345,
                    width: 160,
                    zIndex: 1,
                  },
                  shadowStyles,
                ]}
              >
                <ScrollView style={{ flex: 1 }}>
                  {showViewOptions.map(({ name, icon, color, action }) => (
                    <HoverableOpacity
                      key={name}
                      onPress={action}
                      style={{
                        justifyContent: 'center',
                        minHeight: 46,
                        paddingHorizontal: 16,
                        paddingVertical: 11,
                      }}
                      hoverStyle={{ backgroundColor: hover }}
                    >
                      <AlignedRow>
                        <WorktribeIcon size={16} name={icon} color={color} />
                        <Body style={{ marginLeft: 12 }} primary>
                          {name}
                        </Body>
                      </AlignedRow>
                    </HoverableOpacity>
                  ))}
                </ScrollView>
              </View>
            )}
            {!!addAction && (
              <>
                <SmallButton
                  transparentHighlight
                  style={{ marginLeft: 12 }}
                  secondary
                  onPress={() => handleAddEvent()}
                >
                  <Button color="#1D7A55">{addAction?.buttonlabel}</Button>
                </SmallButton>
              </>
            )}
          </AlignedRow>
        </AlignedRow>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0,
          zIndex: 99,
          margin: 10,
        }}
      >
        {!!name && !!filters && showFilters && (
          <FilterView filters={filters} setFilterOptions={setFilterOptions} />
        )}
      </View>
    </>
  )
}

const FilterView = ({ filters, setFilterOptions }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        zIndex: 999,
      }}
    >
      <GridFilters filterHandler={setFilterOptions} filters={filters} />
    </View>
  )
}

const PlannerView = ({
  columnproperty,
  rclasses,
  value,
  parentRecordId,
  parentRClass,
  propertyName,
  editAction,
  addAction,
  filters,
  rclass,
  selectedTab,
  name,
  required,
}) => {
  const {
    typography: { Label, H2, H3, Button, Body },
    themeColors: { accents, bg, hover, separator },
  } = useContext(ThemeContext)

  const [visibleDate, setVisibleDate] = useState(new Date())
  const [activeDate, setActiveDate] = useState(new Date())
  const [weekWidth, setWeekWidth] = useState(0)
  const [weeks, setWeeks] = useState([])
  const [numWeeks, setNumWeeks] = useState(52)

  const [calendarRecords, setCalendarRecords] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [showViewSwitcher, setShowViewSwitcher] = useState(false)
  const [selectView, setSelectView] = useState('planner')

  const [showAddEvent, setShowAddEvent] = useState(false)

  const previousFilter = useRef()

  const [filterOptions, setFilterOptions] = useState({})

  const filtersActive = Object.entries(filterOptions)
    .map(([property, value]) => {
      if (Array.isArray(value.value)) {
        return value.value.map(v => {
          return {
            property,
            method: 'matches',
            value: v.recordid ?? v.name,
            type: value.type,
          }
        })
      }

      return {
        property,
        method: 'matches',
        value: value.value,
        type: value.type,
      }
    })
    .filter(item => {
      if (Array.isArray(item)) {
        return item.every(i => i.value) && item.length
      }
      return item.value
    })
    .map(item => {
      if (Array.isArray(item) && item.length > 0) {
        return item
      } else if (item.value.recordid) {
        item.value = item.value.recordid
      } else if (item.type === 'date') {
        item.value = new Date(
          item.value.getTime() - item.value.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split('T')[0]
        return item
      }

      return item
    })

  const [showViewOptions] = useState(() => [
    {
      name: 'List View',
      icon: 'task-list-edit',
      color: '#757885',
      action: () => setSelectView('list'),
    },
    {
      name: 'Planner',
      icon: 'calendar',
      color: '#757885',
      action: () => setSelectView('planner'),
    },
  ])
  const [selectedViewOption, setSelectedViewOption] = useState(
    showViewOptions[0]
  )

  const scrollRef = useRef(null)

  useEffect(() => {
    // Switch views
    // If you enable year, month, day and list, make sure to add all the options here!
    switch (selectView) {
      case 'list':
        setSelectedViewOption(showViewOptions[0])
        break
      case 'planner':
        setSelectedViewOption(showViewOptions[1])
        break
    }
    setShowViewSwitcher(false)
  }, [selectView])

  const { record_get } = useSocketMethods()

  useEffect(() => {
    async function filterRecords(filters) {
      const filtersWithoutType = filters
        .map(filter => {
          delete filter.type
          return filter
        })
        .flat()

      try {
        record_get({
          rclassname: parentRClass,
          recordid: parentRecordId,
          includecards: true,
          includeactions: true,
          returnsections: [selectedTab],
          filters: {
            [name]: filtersWithoutType,
          },
        })
      } catch (e) {
        console.warn(e)
      } finally {
        previousFilter.current = filtersWithoutType
      }
    }

    if (previousFilter.current || Object.keys(filtersActive).length > 0) {
      filterRecords(filtersActive)
    }
  }, [filterOptions, parentRClass, parentRecordId, name, selectedTab])

  useEffect(() => {
    const weeks = getDatesForYear(activeDate, numWeeks)

    setWeeks(weeks)
  }, [activeDate, numWeeks])

  useEffect(() => {
    async function getCalendarRecords() {
      try {
        setIsLoading(true)

        const { records } = value

        setCalendarRecords(
          records.map(record => {
            return record.properties.reduce((acc, curr) => {
              acc[curr.name] = curr.value
              acc['recordId'] = record.recordid

              return acc
            }, [])
          })
        )
      } catch (e) {
        console.warn(e)
      } finally {
        setIsLoading(false)
      }
    }

    getCalendarRecords()
  }, [value, columnproperty, rclasses])

  useEffect(() => {
    if (calendarRecords.length) {
      let startFrom = calendarRecords[0]
      let endAt = calendarRecords[calendarRecords.length - 1]

      let startDate = new Date(startFrom?.['Start Date'])
      let endDate = new Date(endAt?.['End Date']).addDays(365)

      setActiveDate(startDate.addDays(-7))
      setNumWeeks(getWeeksDiff(startDate, endDate))
    }
  }, [calendarRecords])

  const onViewableItemsChanged = useCallback(
    ({ viewableItems }) => {
      if (viewableItems.length) {
        let { item } = viewableItems[0]

        setVisibleDate(item)
      }
    },
    [setVisibleDate]
  )

  const changeMonth = useCallback(
    diff => {
      const copiedDate = new Date(visibleDate.valueOf())
      copiedDate.setMonth(copiedDate.getMonth() + diff)

      const weekIndex = weeks.findIndex(week => {
        let currentIdentifier = format(copiedDate, 'M/yyyy')
        let weekIdentifier = format(week, 'M/yyyy')

        return currentIdentifier === weekIdentifier
      })

      if (weekIndex > -1) {
        scrollRef.current.scrollToIndex({
          animated: true,
          index: weekIndex,
        })
      }
    },
    [visibleDate, weeks]
  )

  const setToday = n => {
    const weekIndex = weeks.findIndex(week => {
      let today = new Date()
      let endOfWeek = week.addDays(7)

      return week < today && today < endOfWeek
    })

    if (weekIndex > -1) {
      scrollRef.current.scrollToIndex({
        animated: true,
        index: weekIndex,
      })
    } else {
      scrollRef.current.scrollToEnd()
    }
  }

  const renderItem = ({ item: week }) => (
    <AlignedRow
      key={week.toLocaleDateString()}
      style={{
        minHeight: 50,
      }}
    >
      <View
        style={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'flex-end',
          minWidth: 80,
          marginRight: 16,
        }}
      >
        <Label>{format(week, 'd LLL Y')}</Label>
      </View>
      <View
        onLayout={({ nativeEvent: { layout } }) => {
          const { width } = layout
          setWeekWidth(width)
        }}
        style={{
          flex: 1,
          height: '100%',
          borderLeftWidth: 0.5,
          borderTopWidth: 0.5,
          borderColor: accents.separator,
          flexDirection: 'row',
          padding: 10,
          position: 'relative',
        }}
      >
        <Bar
          week={week}
          calendarRecords={calendarRecords}
          weekWidth={weekWidth}
        />
        {days
          .map(_ => (
            <View
              style={{
                flex: 1,
              }}
            />
          ))
          .reduce((prev, curr, idx) => [
            prev,
            <View
              key={idx}
              style={{
                backgroundColor: accents.separator,
                width: 0.5,
                marginVertical: -10,
              }}
            />,
            curr,
          ])}
      </View>
    </AlignedRow>
  )

  if (isLoading) {
    return (
      <CenteredView>
        <ActivityIndicator size="large" />
      </CenteredView>
    )
  }

  return (
    <Container style={{ overflow: 'hidden' }}>
      <BubbleRow style={{ flex: 1 }}>
        <Bubble
          style={[
            { backgroundColor: bg.card },
            required && {
              shadowOffset: {
                width: -2,
                height: 0,
              },
              shadowColor: '#F19549',
            },
          ]}
        >
          {selectView === 'planner' && (
            <>
              <PlannerHeader
                name={name}
                selectView={selectView}
                setSelectView={setSelectView}
                setShowAddEvent={setShowAddEvent}
                showAddEvent={showAddEvent}
                addAction={addAction}
                filters={filters}
                data={weeks}
                visibleDate={visibleDate}
                changeMonth={changeMonth}
                activeDate={activeDate}
                setToday={setToday}
                filtersActive={filtersActive}
                previousFilter={previousFilter}
                setFilterOptions={setFilterOptions}
              />

              <Container>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <AlignedRow>
                    <View style={{ minWidth: 80, marginRight: 16 }} />
                    {days.map(day => (
                      <View
                        key={day}
                        style={{
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingBottom: 8,
                        }}
                      >
                        <Label>{day}</Label>
                      </View>
                    ))}
                  </AlignedRow>

                  {weeks.length && (
                    <FlatList
                      ref={scrollRef}
                      removeClippedSubviews={true}
                      onViewableItemsChanged={onViewableItemsChanged}
                      viewabilityConfig={{
                        itemVisiblePercentThreshold: 50,
                      }}
                      scrollEventThrottle={16}
                      data={weeks}
                      renderItem={renderItem}
                      keyExtractor={item => item.toDateString()}
                      style={{
                        backgroundColor: bg.primary,
                        borderColor: '#fff',
                        borderRadius: 16,
                        borderTopWidth: 1,
                        borderTopColor: separator.light,
                        marginHorizontal: 10,
                      }}
                    />
                  )}
                </View>
              </Container>
            </>
          )}

          {selectView === 'list' && (
            <>
              <PlannerHeader
                name={name}
                selectView={selectView}
                setSelectView={setSelectView}
                setShowAddEvent={setShowAddEvent}
                showAddEvent={showAddEvent}
                addAction={addAction}
                filters={filters}
                data={weeks}
                filtersActive={filtersActive}
                previousFilter={previousFilter}
                setFilterOptions={setFilterOptions}
              />

              <Container
                style={{
                  flex: 1,
                  overflow: 'scroll',
                }}
              >
                <View>
                  <Selection
                    parentRecordId={parentRecordId}
                    parentrClass={parentRClass}
                    editAction={editAction}
                    addAction={addAction}
                    filters={filters}
                    rclass={rclass}
                    value={value}
                    selectedTab={selectedTab}
                    propertyName={name}
                    showHeadings={true}
                    showBubble={false}
                    showFiltersIfAvailable={false}
                    required={required}
                    showAddEvent={showAddEvent}
                  />
                </View>
              </Container>
            </>
          )}
        </Bubble>
      </BubbleRow>
    </Container>
  )
}

const Bar = ({ week, calendarRecords, weekWidth }) => {
  const [events, setEvents] = useState([])
  const [weekDays, setWeekDays] = useState([])

  useEffect(() => {
    let result = calendarRecords.reduce((acc, curr) => {
      let start = new Date(curr['Start Date'])
      let end = new Date(curr['End Date'])

      let days = []
      var currentDate = week
      for (let i = 0; i < 7; i++) {
        days.push(currentDate)
        currentDate = currentDate.addDays(1)
      }

      setWeekDays(days.map(day => day.toDateString()))

      let endOfWeek = week.addDays(7)

      // map relevant events for the week period
      if (
        (start >= week && start <= endOfWeek) ||
        (end >= week && end <= endOfWeek) ||
        (week > start && endOfWeek < end)
      ) {
        acc.push(curr)

        return acc
      }

      return acc
    }, [])

    setEvents(result)
  }, [calendarRecords, week])

  const navigation = useNavigation()

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        zIndex: 99,
      }}
    >
      {events.map(event => (
        <Event event={event} weekDays={weekDays} weekWidth={weekWidth} />
      ))}
    </View>
  )
}

const Event = ({ event, weekDays, weekWidth }) => {
  let { offset, range } = getEventStyles(event, weekDays)
  let dayWidth = weekWidth / 5

  let isStart = weekDays.includes(new Date(event['Start Date']).toDateString())
  let isEnd = weekDays.includes(new Date(event['End Date']).toDateString())
  let color = event['Calendar'].records[0].properties[1].value[0]?.toLowerCase() ?? 'red'

  // const widthAnim = useRef(new Animated.Value(0)).current
  // const opacityAnim = useRef(new Animated.Value(0)).current

  // useEffect(() => {
  //   Animated.timing(widthAnim, {
  //     toValue: dayWidth * range - 20,
  //     duration: 500,
  //   }).start()
  // }, [widthAnim, dayWidth, range])

  // useEffect(() => {
  //   Animated.timing(opacityAnim, {
  //     toValue: 1,
  //     duration: 500,
  //   }).start()
  // }, [opacityAnim])

  const {
    typography: { Label },
    themeColors: {
      status: { light, darker },
    },
  } = useContext(ThemeContext)

  const navigation = useNavigation()

  return (
    <Pressable
      onPress={() => {
        navigation.push('Record', {
          rClass: 'calendar_period',
          recordId: event.recordId,
        })
      }}
      style={[
        {
          left: 10 + dayWidth * offset,
          borderRadius: 6,
          backgroundColor: light[color],
          width: dayWidth * range - 20,
          flex: 1,
          justifyContent: 'center',
          paddingLeft: 6,
          marginVertical: 2,
          maxHeight: 30,
          borderColor: darker[color],
        },
        isStart && {
          borderLeftWidth: 3,
        },
        isEnd && {
          borderRightWidth: 3,
        },
      ]}
    >
      {isStart && <Label color="#0E1012">{event.Title}</Label>}
    </Pressable>
  )
}

export default PlannerView

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days)
  return dat
}

function getEventStyles(event, days) {
  let startDate = new Date(event['Start Date'])
  let endDate = new Date(event['End Date'])

  let offset
  let range

  if (days.includes(startDate.toDateString())) {
    // calculate offset from the sunday
    offset = startDate.getDay() - 1
  } else {
    // started the previous week so offset is 0
    offset = 0
  }

  if (days.includes(endDate.toDateString())) {
    // it ends this week, so calculate range from start offset
    let endOffset = endDate.getDay()

    // difference between start and end in days
    range = endOffset - offset
  } else {
    // this event ends another week
    range = 5 - offset
  }

  return { offset, range }
}

function getDatesForYear(today, numWeeks) {
  const first = today.getDate() - today.getDay() + 2
  const last = first + 6

  const startDate = new Date(today.setDate(last))
  var currentDate = startDate

  const dates = []
  for (let i = 0; i < numWeeks; i++) {
    dates.push(currentDate)
    currentDate = currentDate.addDays(7)
  }

  return dates
}

function getWeeksDiff(startDate, endDate) {
  const msInWeek = 1000 * 60 * 60 * 24 * 7

  return Math.round(Math.abs(endDate - startDate) / msInWeek)
}
